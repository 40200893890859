require("./bootstrap");

window.Vue = require("vue").default;

import axios from "axios";
import VueAxios from "vue-axios";
import Vuex from "vuex";
import storeData from "./index";
import router from "./router";

import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";

import VueRepeater from 'vue-repeater';
import 'vue-repeater/dist/lib/vue-repeater.css';



//  import VueApexCharts from 'vue-apexcharts';
import VueCharts from "vue-chartjs";
import { Bar, Line } from "vue-chartjs";

import Swal from "sweetalert2";
window.Swal = Swal;

import Gate from "./Gate";
Vue.prototype.$gate = new Gate(window.user);

//Import v-from
import Form from "vform";
window.Form = Form;

// import Select2Component

// vue loading
import loading from "vuejs-loading-screen";
// ;

Vue.use(VueTelInput);
Vue.use(VueRepeater);


Vue.use(loading, {
    bg: "rgba(0, 0, 0, 0.68)",
    icon: "spinner",
    size: 3,
    icon_color: "white",
});

Vue.use(VueAxios, axios, VueCharts, Bar, Line);
Vue.use(Vuex);

const store = new Vuex.Store(storeData);

//   global methods
Vue.mixin({
    methods: {
        dalasisFormatter: function (val) {
            let formatter = new Intl.NumberFormat("en-GM", {
                style: "currency",
                currency: "GMD",
            });

            return formatter.format(val);
        },
    },
});

Vue.component("two-factor-auth", () =>
    import("./components/TwoFactorAuth.vue")
);
Vue.component("not-found", () => import("./components/includes/NotFound"));
Vue.component("Footer", () => import("./components/general/Footer.vue"));
Vue.component("AgencyFooter", () =>
    import("./components/general/AgencyFooter.vue")
);
Vue.component("Send", () => import("./components/general/Send.vue"));
Vue.component("pagination", () => import("laravel-vue-pagination"));

Vue.component("pay-page", () => import("./components/payment/PayPage.vue"));
Vue.component("pay-page-app", () => import("./components/payment/PayPageApp.vue"));

Vue.component("deposit-page", () => import("./components/third_party_deposit/DepositPage.vue"));
Vue.component("deposit-page-app", () => import("./components/third_party_deposit/DepositPageApp.vue"));

Vue.component("success-failed-page", () =>
    import("./components/payment/SuccessFailedPage.vue")
);

Vue.component("beneficiaries-fields", () =>
    import("./components/distributor/BeneficiariesFields.vue")
);
Vue.component("individuals-fields", () =>
    import("./components/collector/IndividualsFields.vue")
);
const app = new Vue({
    el: "#app",
    router,
    store,
    mounted() {
        // console.log("mounted", this.getUserProfile());
    },

    created() {
        if(this.$gate.isAgent() || this.$gate.isAgentAdmin()){
        companyType: null,
        
            axios
                .get("/api/agency")
                .then(({ data }) => {
                    this.companyType = data;
                })
                .catch((err) => {
                    console.log(err);

                });
            
        setInterval(() => {
            // console.log(new Date().toLocaleString());
            const date = new Date();
            const currentHour = date.getHours();
            const currentMinute = date.getMinutes();
            const currentSecond = date.getSeconds();
            if (this.companyType == undefined) {
                return;
            }
            if (this.companyType.company_type == 3) {
                if (currentHour === 16 && currentMinute === 0 && currentSecond == 0) {

                    axios.post('logout').then(response => {
                        if (response.status === 302 || 401) {
                            let timerInterval
                            Swal.fire({
                                title: 'Auto close alert!',
                                html: 'I will close in <b></b> milliseconds. Good bye till tomorrow',
                                timer: 3000,
                                timerProgressBar: true,
                                didOpen: () => {
                                    Swal.showLoading()
                                    const b = Swal.getHtmlContainer().querySelector('b')
                                    timerInterval = setInterval(() => {
                                        b.textContent = Swal.getTimerLeft()
                                    }, 100)
                                },
                                willClose: () => {
                                    clearInterval(timerInterval)
                                }
                            }).then((result) => {
                                /* Read more about handling dismissals below */
                                if (result.dismiss === Swal.DismissReason.timer) {
                                    window.location.href = '/login'
                                    console.log('I was closed by the timer')
                                }
                            })

                        }
                        else {
                            // throw error and go to catch block
                        }
                    }).catch(error => {

                    });

                    console.log("good");
                }
            }
        }, 1000)
    }
    }
});

// if (app._route.path == "/support") {
//     console.log("Start", app._route);
// }
