<template>
    <div>
        <div v-if="$gate.isCashcollector()" class="card  custom-card-1 m-0">
            <fieldset class="mt-5">
                            <legend class="d-flex justify-content-between flex-wrap">
                                <div class="d-flex align-items-center">
                                <span>Withdrawals</span>

                                </div>

                            <button class="btn btn-outline-info" data-toggle="modal" data-target="#sendRequestModal">Send Withdrawal Request <i class="fa fa-plus"></i></button>
                             
                            </legend>
                            
                                <SearchFilter
                                    :data_length="withdraws.length"
                                    :filters="filters"
                                    data_type="all"
                                    @search-form="makeSearch"
                                    :isFound="isFound"
                                />
                          


                        <div>



                        </div>




                        <div  class="card-body mt-2 table-responsive">
                            <table  id="example2" class="table table-hover border-top">
                                <thead >
                                <tr class="text-center">
                                <th>Request ID</th>
                                <th>Current Balance</th>
                                <th>Request Amount</th>
                                <th>Time Sent</th>
                                <th>Time Completed</th>
                                <th>Status</th>
                               
                                <th class="border-left">Action</th>
                                </tr>
                                </thead>
                                <tbody v-if="withdraws.length != 0">
                                <tr class='text-center' v-for='(row, index) in withdraws' :key='index'>
                                    <td>{{row.unique_id}}</td>
                                    <td>{{row.collector_current_balance}}</td>
                                    <td>{{dalasisFormatter(row.request_amount)}}</td>
                                    <td>{{row.time_sent}}</td>
                                    <td>{{row.time_updated}}</td>
                                    <td v-if="row.request_status==0"><span class="badge badge-warning badge-pill">Pending</span></td>
                                    <td v-else-if="row.request_status==2"><span class="badge badge-danger badge-pill">Cancelled</span></td>
                                    <td v-else><span class="badge badge-success badge-pill">Approved</span></td>


                                    <td class="d-flex  border-left justify-content-around" style="width:165px;">
                                      <div>
                                        <button v-if="row.request_status == 0" @click="viewToken(row.Cid)" class="ml-1 btn btn-sm btn-outline-info custom-button">
                                            <i class="far fa-eye"></i>

                                            Token</button></div>
                                            <div>
                                        <button v-if="row.request_status == 0"  @click="cancel(row.Cid)" class="btn btn-sm custom-button-red1">Cancel</button>
                                                </div>
                                       
                                    </td>



                                </tr>
                                <tfoot>


                                </tfoot>
                                </tbody>
                                <tbody  v-else>
                                    <tr class='text-center'>
                                        <td></td>
                                        <td></td>
                                        <td><span><i>You have no withdrawals.</i></span></td>
                                       
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                    
                                </tbody>
                            </table>
                            <div class="pt-3">
                                <pagination align="center" :data="pageData" @pagination-change-page="getResults"></pagination>

                            </div>

                        </div>
                    


            </fieldset>
        </div>


        
            <!-- modals -->


            <div class="modal fade" id="sendRequestModal" tabindex="-1" role="dialog" aria-labelledby="sendRequestModalTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <form @submit.prevent='sendRequest' method="post">

                            <div class="modal-header border-0">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="d-flex justify-content-center">
                                    <h2 class="swal2-title" id="exampleModalLongTitle">Send Withdrawal Request</h2>
                                </div>
                                   
                                        <div class="card-body">
                                        
                                        <div class="form-group">
                                            <label for="inputCut" >Request Amount</label>
                                            <input type="number" id="inputCut" v-model="form.request_amount"
                                            maxlength="100" placeholder="Enter Request Amount" class="form-control">
                                        </div>
                                   


                                        </div>
                                        <!-- /.card-body -->

                                 






                            </div>
                            <div class="modal-footer border-0 d-flex justify-content-center">
                                <button type="submit" class="btn custom-button-2 ">Submit</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
    </div>

</template>

    <script>

    export default {
        name: 'Withdraws',

        components: {
            SearchFilter: () =>
            import(
                "../includes/SearchFilter.vue" /* webpackChunkName: "js/search-filter" */
            ),

        },
      data() {
        return {
            isFound: false,
            searchForm: null,
            withdraws: [],
            pageData: {},
            form: new Form({
            request_amount: 0,
            }),
            filters: [
                
                {
                    id: 1,
                    text: "Amount",
                    value: "amount",
                },
                {
                    id: 2,
                    text: "Date",
                    value: "date",
                },
                
            ],
            sort_by: {
                column: "Time Sent",
                order: "descending",
            },
            sort_column_names: [
                "Amount",
                "Time Sent",
            ],
            emptyDataText: "",
        };

      },
      created(){

          // fetch campaign table
          if(this.$gate.isCashcollector()){
                this.getResults()

            }


      },
        watch: {



        },
      methods:{


        getResults(page){
                if (typeof page === 'undefined') {
                page = 1;
                }
                this.emptyDataText = "";
                this.$isLoading(true)
                if(this.searchForm == null || (this.searchForm.search_text == '' && (this.searchForm.fromdate == null || this.searchForm.fromdate == ''))){
                let temp_form = new Form({
                    page : page,
            })
            temp_form.post('/api/collector/withdraws')
                    .then(({data}) => {
                      console.log("withdraws", data)
                      this.withdraws = data.data;
                      this.pageData = data
                        this.$isLoading(false)


                    }).catch((err)=>{
                      this.withdraws = []
                      this.pageData = {}
                    this.$isLoading(false)


                })

            } else {
                this.$isLoading(true);
                this.emptyDataText = "";

                this.searchForm
                    .post("api/broker/collector/withdrawal/makeSearch?page=", {
                        headers: { "Content-Type": "application/json" },
                    })
                    .then(({ data }) => {
                        this.isFound = true;

                        this.withdraws = data.data;
                        this.pageData = data;
                        this.emptyDataText = "No search results found...";

                        this.$isLoading(false);
                    })
                    .catch((err) => {
                        this.withdraws = [];
                        this.pageData = {};
                        this.emptyDataText = "Something went wrong!";

                        this.$isLoading(false);
                    });
            }

            },

            makeSearch(data){
                console.log('dfgd',data);
            this.searchForm = data
            this.getResults()
            // emit up
        },

            sendRequest(){
            $('#sendRequestModal').modal('toggle')

            // prompt for pin
            const box1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button bg-danger  mr-2',
                cancelButton: 'btn custom-button-2 ml-2'
            },
            buttonsStyling: false
            })

            box1.fire({
                title: 'Enter your pin',
                text: 'To confirm your changes',
                input: 'password',
                inputAttributes: {
                    required: true,
                },
                inputValidator: (value) => {
                    if (!value) {
                    return 'You need to enter your 4 digit pin!'
                    }

                },
                showCancelButton: true,
                confirmButtonText: 'Submit',
                showLoaderOnConfirm: true
                }).then((result) => {
                if (result.isConfirmed) {
                    this.form.pin = result.value
                  this.$isLoading(true)

                this.form.post('/api/collector/withdraws/send', {headers:{"Content-Type":"application/json"}})
                    .then((data) => {


                  this.$isLoading(false)

                  const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button-2',

                        },
                        buttonsStyling: false
                        })

                        confirmBox2.fire({
                        icon: 'success',
                        title: 'Withdrawal Request Sent!',
                        html: '<span>Use your token to withdraw your money</span>'
                            + '<br><h3>Token: <b>' + data.data.token + '</b></h3>'


                        }).then((res)=>{

                        // ask them to pick the transaction type. Give em option to exit/not choose.

                        window.location.reload()


                        })

                      



                    })
                    .catch((err) => {
                        this.$isLoading(false)

                        // show them if that city name is taken
                        let errorText = 'Withdrawl Request sent Unsuccessful'
                        const confirmBox2 = Swal.mixin({
                                            customClass: {
                                                confirmButton: 'btn custom-button bg-danger',

                                            },
                                buttonsStyling: true
                             })
                             if(err.response.data.errors['password']){
                                errorText = err.response.data.errors['password']
                            }else if(err.response.data.errors['general']){
                                errorText = err.response.data.errors['general']
                            }

                             confirmBox2.fire({
                                    icon: 'error',
                                    title: 'Failed!',
                                    text: errorText,

                               })
                       
                        $('#sendRequestModal').modal('toggle')



                    });






                }
                })

           
        },

        cancel(id){
        //   get the info from id
            const confirmBox1 = Swal.mixin({
            customClass: {
                cancelButton: 'btn custom-button-2 mx-2',
                confirmButton: 'btn custom-button bg-danger mx-2'
            },
            buttonsStyling: false
            })

            confirmBox1.fire({
            title: 'Are you sure?',
            html: "You'll be cancelling your withdrawal request</b> <br>"+
            "This action is irreversible",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, cancel it!',
            cancelButtonText: "No, don't!"
            }).then((result1) => {
            if (result1.isConfirmed) {

                // prompt for pin
            const box1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button-2 mr-2',
                cancelButton: 'btn custom-button bg-danger ml-2'
            },
            buttonsStyling: false
            })

            box1.fire({
                title: 'Enter your pin',
                input: 'password',
                inputAttributes: {
                    maxlength: 4,
                    minlength: 4,
                    pattern: "[0-9]+",
                    required: true,
                },
                inputValidator: (value) => {
                    if (!value) {
                    return 'You need to enter your 4 digit pin!'
                    }

                },
                showCancelButton: true,
                confirmButtonText: 'Submit',
                showLoaderOnConfirm: true
                }).then((result) => {
                if (result.isConfirmed) {

                let formData = new Form({
                    withdraw_id: id,
                    pin: result.value
                });

                    this.$isLoading(true)

                formData.post('api/collector/withdraw/cancel',{headers:{"Content-Type":"application/json"}})
                    .then((data) => {
                    this.$isLoading(false)

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button-2',

                        },
                        buttonsStyling: false
                        })

                        confirmBox2.fire({
                        icon: 'success',
                        title: 'Cancelled!',
                        // text: 'Your new balance is ' + new_balance

                        }).then((res)=>{
                            // reloading the page
                        window.location.reload()

                        })



                    })
                    .catch((err) => {
                    this.$isLoading(false)


                        let errorText = 'Operation Unsuccessful'

                        if(err.response.data.errors['pin']){
                            errorText = err.response.data.errors['pin']
                        }
                        else if(err.response.data.errors['general']){
                            errorText = err.response.data.errors['general']
                        }

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button bg-danger',

                        },
                        buttonsStyling: true
                        })

                        confirmBox2.fire({
                        icon: 'error',
                        title: 'Failed!',
                        text: errorText,

                        })
                    });



                }
                })




            }
            })
      },

        viewToken(id){

                // prompt for pin
                const box1 = Swal.mixin({
                customClass: {
                confirmButton: 'btn custom-button-2 mx-2',
                cancelButton: 'btn custom-button bg-danger mx-2'
                },
                buttonsStyling: false
                })

                box1.fire({
                title: 'Enter your pin',
                input: 'password',
                inputAttributes: {
                    maxlength: 4,
                    minlength: 4,
                    pattern: "[0-9]+",
                    required: true,
                },
                inputValidator: (value) => {
                    if (!value) {
                    return 'You need to enter your 4 digit pin!'
                    }

                },
                showCancelButton: true,
                confirmButtonText: 'Submit',
                showLoaderOnConfirm: true
                }).then((result) => {
                if (result.isConfirmed) {
                this.$isLoading(true)

                let formData = new FormData();
                formData.append('withdraw_id', id);

                formData.append('pin', result.value);


                axios.post('api/collector/withdraw/viewToken',formData,{headers:{"Content-Type":"application/json"}})
                    .then((data) => {
                    this.$isLoading(false)

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button-2',

                        },
                        buttonsStyling: false
                        })

                        confirmBox2.fire({
                        icon: 'success',
                        title: 'Fetched!',
                        html:  '<br><h3>Token: <b>' + data.data.success + '</b></h3>'

                        }).then((res)=>{


                        })



                    })
                    .catch((err) => {

                    this.$isLoading(false)


                        let errorText = 'Operation Unsuccessful'

                        if(err.response.data.errors['pin']){
                            errorText = err.response.data.errors['pin']
                        }
                        else if(err.response.data.errors['general']){
                            errorText = err.response.data.errors['general']
                        }

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button bg-danger',

                        },
                        buttonsStyling: true
                        })

                        confirmBox2.fire({
                        icon: 'error',
                        title: 'Failed!',
                        text: errorText,

                        })
                    });



                }
                })



                },

      },

    }
    </script>
