<template>
    <div>
        <div v-if="$gate.isBroker()" class="card  custom-card-1 m-0">
            <fieldset>
                <legend class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center justify-content-between w-100">
                     <span>All Collectors Requests</span>
                     <div class="d-flex align-items-center justify-content-end">
                                            <button class="btn btn-success"  @click="downloadFile"><i class="fas fa-file-export"></i> Export Report</button>
                       </div>
                    </div>
                   
                </legend>

                <div class="card-header">
                    <div class="d-flex">
                    <select @change="getCampaigns()" required v-model="form.collector" class="form-control form-control-lg ">
                        <option value=""  selected>All Collectors</option>
                        <option v-for="row in collectors" :key="row.id" :value="row.user_id">{{row.institution_name}}</option>

                    </select>
                    <select  @change="makeSearch()" required v-model="form.campaign" class="form-control form-control-lg ">
                        <option value=""  selected>All Campaigns</option>
                        <option v-for="row in campaigns" :key="row.id" :value="row.id">{{row.campaign_name}}</option>

                    </select>

                    <select  @change="makeSearch()"  required v-model="form.status" class="form-control form-control-lg ">
                        <option value=""  selected>All Status</option>
                        <option v-for="row in status" :key="row.id" :value="row.value">{{row.text}}</option>

                    </select>
                    

                    </div>
                    <div class="d-flex mt-2">
                        <Datepicker  @input="makeSearch()" input-class="form-control form-control-lg" wrapper-class="inline" placeholder="From date" format="dd/MM/yyyy" :clear-button="true" v-model='form.fromdate'></Datepicker>
			            <Datepicker   @input="makeSearch()" input-class="form-control form-control-lg" wrapper-class="inline" placeholder="To date" format="dd/MM/yyyy" :clear-button="true" v-model='form.todate'  ></Datepicker>
                    </div>

                    <!-- <SearchFilter :data_length='requests.length' :isFound='isFound'
                        :filters='filters' :status='status'   data_type="requests" @search-form='makeSearch'
                        /> -->

                </div>


            <div>


            </div>




            <div class="card-body mt-2 table-responsive">
                <table  id="example2" class="table table-hover border-top">
                    <thead >
                    <tr class="text-center">
                    <th>ID</th>
                    <th>Collector </th>
                    <th>Campaign </th>
                    <th>Customer</th>
                    <th>User Type</th>
                    <th>Amount</th>
                    <th>Fee Paid</th> 
                    <th>Fee Paid By</th> 
                    <th>Time Sent</th>
                    <th>Time Expire</th>
                    <th >Status</th>
                    </tr>
                    </thead>
                    <tbody v-if="requests.length != 0">
                    <tr class='text-center' v-for='(row, index) in requests' :key='index'>
                    <td>{{row.unique_id}}</td>
                     <td>{{row.institution_name}}</td> 
                    <td>

                        {{row.campaign_from}}
                    </td>
                    <td>
                    <router-link v-if="row.user_type === 'Customer'" v-bind:to="'/view-user-profile/' + row.customer_id">
                                {{row.customer_fullname}} <br>
                            <span>
                                username: {{row.customer_username}}
                            </span>
                    </router-link>
                    </td>
                    <td>

                        <i v-if="row.non_register_status" class="text-danger"><i title="not registered" class="fa fa-user-slash"></i></i>
                        <i v-else  class="text-success"> <i title="registered" class="fa fa-user"></i></i>
                    </td>

                    <td>
                        {{ dalasisFormatter(row.amount)}}
                    </td>

                     <td>
                        {{ dalasisFormatter(row.fee_amount)}}

                    </td>
                    <td>
                        <span v-if="row.fee_pay_by==0" class="badge badge-success badge-pill">Customer</span>
                        <span v-else class="badge badge-info badge-pill">Collector</span>
                        <span v-if="row.fee_pay_by==0" class="text-success font-weight-bold text-nowrap">
                            Deposited on collector account :   {{ dalasisFormatter(row.amount) }}
                        </span>
                        <span v-else class="text-info font-weight-bold text-nowrap">
                            Deposited on collector account :   {{ dalasisFormatter(row.amount - row.fee_amount) }}
                        </span>
                    </td>

                    <td>{{row.time_sent}}
                    </td>
                    <td>{{row.request_expiry_date}}
                    </td>
                    <td >
                      <span v-if="row.request_status==1" class="badge badge-success badge-pill">Completed</span>
                      <span v-else-if="row.is_cancelled==0 && row.request_status==0" class="badge badge-info badge-pill">Pending</span>
                      <span v-else-if="row.is_cancelled==0 && row.request_status==2" class="badge badge-warning badge-pill">Pay-Auto</span>
                      <span v-else-if="row.is_cancelled==0 && row.request_status==3" class="badge badge-danger badge-pill">Unpaid</span>
                      <span v-else-if="row.is_cancelled==0 && row.request_status==4" class="badge badge-danger badge-pill">Unpaid- Due to insufficient balance</span>
                      <span v-else-if="row.is_cancelled==1" class="badge badge-danger badge-pill">Cancelled</span>
                    </td>

                    </tr>
                </tbody>
                    <tbody  v-else>
                                    <tr class='text-center'>
                                    
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td><span><i>{{ emptyDataText }}</i></span></td>
                                        <td></td>
                                        <td></td>
                                        
                                    </tr>
                    
                                </tbody>
                </table>
                <div class="pt-3">
                    <pagination align="center" :limit="10"   :data="pageData" @pagination-change-page="getResults"></pagination>

                </div>

            </div>


            </fieldset>








        </div>
        <div v-else>
            <not-found></not-found>

        </div>
    </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
export default {
    name: 'Requests',
    props:{

    },
    components: {
        Datepicker,
        SearchFilter: () => import('../../includes/SearchFilter.vue' /* webpackChunkName: "js/search-filter" */),

    },
 
  data() {
    return {
        
        searchBy : '',
        searchForm: null,
        isFound: false,
        collectors:[],
        campaigns:[],

        form: new Form ({
            collector: '',
            campaign: '',
            status: '',
            fromdate: '',
            todate: '',
        }),

        requests: [],
        pageData: {},
        emptyDataText: '',
        status: [
            {
                'id': 1,
               'text': 'Pending',
               'value': '0'
            },
            {
                'id': 2,
                'text': 'Completed',
                'value': 1
            },
            {
                'id': 3,
                'text': 'Pay-Auto',
                'value': 2
            },
            {
                'id': 4,
                'text': 'Unpaid',
                'value': 3
            },
            {
                'id': 5,
                'text': 'Cancelled',
                'value': 5
            },
           
        ],
        filters: [
            {
                'id': 1,
               'text': 'Collector',
               'value': 'collector' 
            },
            {
                'id': 2,
                'text': 'Campaign',
                'value': 'campaign'
            },
            {
                'id': 3,
                'text': 'Amount',
                'value': 'amount'
            },
            {
                'id': 4,
                'text': 'Fee Paid',
                'value': 'fee_paid'
            },
            {
                'id': 5,
                'text': 'Status',
                'value': 'status'
            },
            {
                'id': 6,
                'text': 'Date',
                'value': 'date'
            },
        ],
    }
  },
  created(){
      // fetch fee table
      if(this.$gate.isBroker()){
            this.getResults()
            this.getCollectors()

        }

  },
    watch: {


    },
  methods:{

    getCollectors(){
        axios.get('api/collector/getCollectors')
                .then(({data}) => {
                  console.log("Collectors", data)
                  
                    this.$isLoading(false)
                    if(data.length != 0){
                        this.collectors = data;
                       
                    }else{
                        this.collectors = [];
                       
                    }


                }).catch((err)=>{
                  this.collectors = []
                 
                this.$isLoading(false)


            })
    },

    getCampaigns(){
        this.makeSearch()
        let data={'collector_id': this.form.collector}
        axios.post('api/collector/getCampaigns',data)
                .then(({data}) => {
                  console.log("Campaigns", data)
                  
                    this.$isLoading(false)
                    if(data.length != 0){
                        this.campaigns = data;
                       
                    }else{
                        this.campaigns = [];
                       
                    }


                }).catch((err)=>{
                  this.campaigns = []
               
                this.$isLoading(false)


            })
    },

    downloadFile(){
        this.form.post('/api/collector/requests/export_requests', {
            validateStatus: (s) => s <= 500,
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            }
            }).then((response) => {

                    this.$isLoading(false);
                    console.log('res', response);
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    console.log(url);
                    const link = document.createElement('a');
                    link.href = url;

                    link.setAttribute('download', 'requests-report.xlsx'); //or any other extension
                    document.body.appendChild(link);
                    link.click();


                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button-2',

                        },
                        buttonsStyling: false
                        })

                        confirmBox2.fire({
                        icon: 'success',
                        title: 'Requests report exported Sucessfully!',

                        }).then((res)=>{

                         window.location.reload()


                        })



                    })
                    .catch((err) => {
                        this.$isLoading(false)

                        // show them if that city name is taken
                        let errorText = 'Requests report exported Unsuccessful'
                     });
        },
        makeSearch(){
           this.getResults()
            // emit up
        },



        getResults(page){
            if (typeof page === 'undefined') {
            page = 1;
            }
            console.log(this.form.status);
           
            if(this.form == null || (this.form.collector == '' && 
            this.form.campaign == ''  && this.form.status == ''
             && this.form.fromdate == '')){
                console.log(this.form.status);
            axios.get('api/collector/requests/all?page=' + page)
                .then(({data}) => {
                  console.log("Requests", data)
                  
                    this.$isLoading(false)
                    if(data.data.length != 0){
                        this.requests = data.data;
                        console.log(this.requests.length);
                        this.pageData = data
                    }else{
                        this.requests = [];
                        this.pageData = {}
                        this.emptyDataText = 'You have no Requests.'
                    }


                }).catch((err)=>{
                  this.requests = []
                  this.pageData = {}
                this.$isLoading(false)


            })

            }else{
       
                this.form.post('api/collector/requests/filterRequests?page='+ page,{headers:{"Content-Type":"application/json"}})
                .then(({data}) => {
                  console.log("Requests", data)
                  this.isFound=  true;
                  this.requests = data.data;
                  console.log(this.requests.length);
                  this.pageData = data
                    this.$isLoading(false)
                    this.emptyDataText = 'No search results found...'

                }).catch((err)=>{
                  this.requests = []
                  this.pageData = {}
                this.$isLoading(false)
                this.emptyDataText = 'Something went wrong!'


            })
            }

        },



    },

        



}
</script>
<style scoped>
.w-80{
    width: 100%;
}

</style>