<template>
    <div v-if="$gate.isAgent() || $gate.isAgentAdmin()">

        <div class="card  custom-card-1 m-0">
            <fieldset>
                <legend class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center">
                     <span>Agent Management</span>

                    </div>
                   <router-link v-if="$gate.isRegistrationComplete()" to="/create-agent" class="nav-link" active-class="active" exact>
                       <button class="btn btn-outline-info">Create new Agent <i class="fa fa-plus"></i></button>
                    </router-link>
                </legend>

                <div class="card-header">
                </div>


            <div>

                <TabNav :tabs="['Agents Pending Approval', 'Approved Agents', 'Blocked Agents', 'Admins']" :selected="selected" @selected="setSelected">
                    <Tab :isSelected="selected === 'Agents Pending Approval' ">
                    </Tab>

                    <Tab :isSelected="selected === 'Approved Agents' ">
                    </Tab>

                    <Tab :isSelected="selected === 'Blocked Agents' ">
                    </Tab>

                    <Tab :isSelected="selected === 'Admin' ">
                    </Tab>
                </TabNav>

            </div>




            <div class="card-body mt-2 table-responsive">
                <table  id="example2" class="table table-hover">
                    <thead >
                    <tr class="text-center">
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Username</th>
                    <th>Email</th>

                    <th>Agent Address</th>
                    <th v-if="selected != 'Agents Pending Approval'">Branch</th>


                    <th class="border-left">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class='text-center' v-for='(row, index) in agents' :key='index'>
                        <td>{{row.first_name}}</td>
                        <td>{{row.last_name}}</td>
                        <td>{{row.username}}</td>
                        <td>{{row.email}}</td>
                        <td>{{row.town_name}}</td>
                        <td v-if="selected != 'Agents Pending Approval'">
                            {{row.branch}} -
                            <button
                                type="button"
                                @click="changeAgentBranchModal(row)"
                                class="btn btn-sm btn-info" >
                                 <i class="fa fa-edit"></i>
                          </button>
                        </td>
                        <td class="d-flex flex-wrap  border-left justify-content-between" v-if="selected == 'Agents Pending Approval'">
                            <button @click="openModal(row, 1)" class="btn mb-4 custom-button-ogreen1" >
                                <i class="fas fa-check"></i>

                            </button>
                            <router-link v-bind:to="'/view-user-profile/' + row.id"
                            active-class="active" exact class="btn mb-4 custom-button-purple1" >
                                <i class="fas fa-eye"></i>
                            </router-link>
                            <button @click="blockAgent(row.id)" class="btn custom-button mb-4 btn-danger">
                                <i class="fas fa-user-slash"></i>

                            </button>
                        </td>
                        <td class="d-flex flex-wrap border-left justify-content-between" v-else-if="selected == 'Approved Agents'">
                            <router-link v-bind:to="'/view-user-profile/' + row.id"
                            active-class="active" exact class="btn mb-4 custom-button-purple1" >
                                <i class="fas fa-eye"></i>
                            </router-link>

                            <button @click="blockAgent(row.id)" class="btn custom-button mb-4 btn-danger">
                                <i class="fas fa-user-slash"></i>

                            </button>
                        </td>

                        <td class="d-flex flex-wrap border-left justify-content-between" v-else-if="selected == 'Blocked Agents'">
                            <router-link v-bind:to="'/view-user-profile/' + row.id"
                            active-class="active" exact class="btn mb-4 custom-button-purple1" >
                                <i class="fas fa-eye"></i>
                            </router-link>
                            <button @click="unblockAgent(row.id)"  class="btn custom-button mb-4 btn-danger">
                                <i class="fas fa-user"></i>

                            </button>
                        </td>

                        <td class="d-flex flex-wrap border-left justify-content-between" v-else-if="selected == 'Admins'">
                            <router-link v-bind:to="'/view-user-profile/' + row.id"
                            active-class="active" exact class="btn mb-4 custom-button-purple1" >
                                <i class="fas fa-eye"></i>
                            </router-link>
                            <button @click="blockAgent(row.id)"  class="btn custom-button mb-4 btn-danger">
                                <i class="fas fa-user-slash"></i>
                            </button>
                        </td>
                    </tr>
                    <tfoot>


                    </tfoot>
                    </tbody>
                </table>
                <div class="pt-3">
                    <pagination align="center" :data="pageData" @pagination-change-page="getResults"></pagination>

                </div>

            </div>


            </fieldset>

        </div>
        <!-- /.card -->
            <!-- Modal -->
            <div class="modal fade" id="modal1" tabindex="-1" role="dialog" aria-labelledby="modal1Title" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <form  @submit.prevent='approveAgent' method="post">

                            <div class="modal-header border-0">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">

                                <div class="">
                                    <h2 class="swal2-title text-center" id="exampleModalLongTitle">Approve "{{selectedAgent.first_name}}" </h2>
                                    <div class="py-2 font-weight-bold">What branch? </div>
                                    <select v-model="form.branch_id" name="" id="location" required class="form-control">
                                        <option value="" selected disabled>Select a Branch</option>
                                        <option v-for="row in company.branches" :key="row.id" :value="row.id" required >
                                               {{row.town_name}}
                                        </option>
                                    </select>


                                </div>



                            </div>
                            <div class="modal-footer border-0 d-flex justify-content-center">
                                <button type="submit" class="btn custom-button-2 ">Approve</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>


        <AgencyFooter :getUserProfile='getUserProfile' />

        <div
            class="modal fade"
            id="changeAgentBranchModal"
            role="dialog"
            aria-labelledby="changeAgentBranchTitle"
            aria-hidden="true"
        >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <form method="post" @submit.prevent="changeAgentBranch">
              <div class="modal-header border-0 p-0 m-0">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h3 class="swal2-title">Change Agent's Branch</h3>
                <h6>Current Branch: <b>{{ change_agent_branch_form.current_branch }}</b></h6>
                <h3 class="pt-3">Pick Branch</h3>
                <div class="input-group pt-1">
                  <select
                    v-model="change_agent_branch_form.branch_id"
                    class="form-control"
                    name=""
                    id=""
                  >
                    <option value="" selected disabled>---</option>
                    <option
                      v-for='(ag_br) in this.company.branches'
                      :key='ag_br.id'
                      :value='ag_br.id'
                    >
                      {{ ag_br.town_name }} ({{ ag_br.city_name }})
                    </option>
                  </select>
                </div>
                <div
                  class="modal-footer border-0 d-flex justify-content-center"
                >
                  <button
                    type="submit"
                    class="btn custom-button-2"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

    </div>

    <div v-else>
        <not-found></not-found>
    </div>
</template>


<script>
import TabNav from './../includes/tabNav'
import Tab from './../includes/tabs'

export default {
    name: 'Agents',
    props:{

    },
  components: {
        TabNav,
        Tab,
  },
  data() {
    return {
        selected: 'Approved Agents',
        selectedAgent: {},

        searchBy : '',
        searchText: '',

        form: new Form({

            agent_id: 0,
            pin: '',
            branch_id: '',

        }),

        agents: [],
        company: [],
        pageData: {},
        change_agent_branch_form: new Form({
            agent_id: "",
            agent_name: "",
            branch_id: "",
            current_branch: "",
            current_branch_id: "",
            // branches: "",
        }),
    }
  },
  created(){
      // fetch fee table
    this.$isLoading(true)

    this.getResults()
            axios.get('api/company/userCompany')
                .then(({data}) => {

                  this.company = data
                    this.$isLoading(false)

                }).catch((err)=>{
                  this.agents = []
                  this.pageData = {}
                    this.$isLoading(false)

            })
  },
    watch: {

        selected: function(){

           this.getResults();


        },

    },
  methods:{
        setSelected(tab){
            this.selected = tab;

        },

        getResults(page){
            if (typeof page === 'undefined') {
            page = 1;
            }
            this.agents = []
            this.pageData = {}
            let query = '';
            if(this.selected == 'Agents Pending Approval'){
                query = 'agentsPendingApproval'
            }
            else if(this.selected == 'Approved Agents'){
                query = 'approvedAgents'
            }

            else if(this.selected == 'Blocked Agents'){
                query = 'blockedAgents'
            }
            else if(this.selected == 'Admins'){
                query = 'admins'
            }

            axios.get('api/agent/' + query + '?page=' + page)
                .then(({data}) => {

                  this.agents = data.data;
                  this.pageData = data

                }).catch((err)=>{
                  this.agents = []
                  this.pageData = {}

            })
        },

        approveAgent(){


            $('#modal1').modal('hide')

            const confirmBox1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button-2 mx-2',
                cancelButton: 'btn custom-button bg-danger mx-2'
            },
            buttonsStyling: false
            })

            confirmBox1.fire({
            title: 'Are you sure?',
            text: "You'll be Approving the Agent ",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, I approve this agent!'
            }).then((result1) => {
            if (result1.isConfirmed) {



            const box1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button-2 mx-2',
                denyButton: 'btn custom-button bg-secondary mx-2'
            },
            buttonsStyling: false
            })

            box1.fire({
                title: 'Enter your pin',
                input: 'password',
                inputAttributes: {
                    maxlength: 4,
                    minlength: 4,
                    pattern: "[0-9]+",
                    required: true,
                },
                inputValidator: (value) => {
                    if (!value) {
                    return 'You need to enter your 4 digit pin!'
                    }

                },
                showDenyButton: true,
                denyButtonText: `Back`,
                confirmButtonText: 'Submit',
                }).then((result) => {
                if (result.isConfirmed) {

                this.$isLoading(true)
                this.form.pin = result.value

                this.form.post('api/agent/approve',{headers:{"Content-Type":"application/json"}})
                    .then((data) => {
                        this.$isLoading(false)

                        this.form.pin = null


                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button-2',

                        },
                        buttonsStyling: false
                        })

                        confirmBox2.fire({
                        icon: 'success',
                        title: 'Approved!',
                        text: 'Agent Approved! ... '  ,

                        }).then((res)=>{
                            // reloading the page
                        // window.location.href = '/agents';
                        this.getResults()
                        })



                    })
                    .catch((err) => {
                        this.$isLoading(false)

                        this.form.pin = null
                        // come back later
                        let errorText = typeof err.response.data.errors['pin'] !== 'undefined' ? err.response.data.errors['pin'][0] : '';

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button bg-danger',

                        },
                        buttonsStyling: true
                        })

                        confirmBox2.fire({
                        icon: 'error',
                        title: 'Failed!',
                        text: errorText, // take care later

                        })
                    });




                }
                else if (result.isDenied) {

                }
                })


            }
            })



        },


        blockAgent(id){

            const confirmBox1 = Swal.mixin({
            customClass: {
                cancelButton: 'btn custom-button-2 mx-2',
                confirmButton: 'btn custom-button bg-danger mx-2'
            },
            buttonsStyling: false
            })

            confirmBox1.fire({
            title: 'Are you sure?',
            text: "You'll be blocking the Agent ",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, block this agent!'
            }).then((result1) => {
            if (result1.isConfirmed) {



            const box1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button-2 mx-2',
                denyButton: 'btn custom-button bg-secondary mx-2'
            },
            buttonsStyling: false
            })

            box1.fire({
                title: 'Enter your pin',
                input: 'password',
                inputAttributes: {
                    maxlength: 4,
                    minlength: 4,
                    pattern: "[0-9]+",
                    required: true,
                },
                inputValidator: (value) => {
                    if (!value) {
                    return 'You need to enter your 4 digit pin!'
                    }

                },
                showDenyButton: true,
                denyButtonText: `Back`,
                confirmButtonText: 'Submit',
                }).then((result) => {
                if (result.isConfirmed) {

                this.$isLoading(true)
                let del_form =  new FormData();
                del_form.append('agent_id', id)
                del_form.append('pin', result.value)

                axios.post('api/agent/block', del_form,{headers:{"Content-Type":"application/json"}})
                    .then((data) => {
                        this.$isLoading(false)

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button-2',

                        },
                        buttonsStyling: false
                        })

                        confirmBox2.fire({
                        icon: 'success',
                        title: 'Blocked!',
                        text: 'Agent Blocked! ... '  ,

                        }).then((res)=>{
                            // reloading the page
                        // window.location.href = '/agents';
                        this.getResults()
                        })



                    })
                    .catch((err) => {
                        this.$isLoading(false)

                        // come back later
                        let errorText = typeof err.response.data.errors['pin'] !== 'undefined' ? err.response.data.errors['pin'][0] : '';

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button bg-danger',

                        },
                        buttonsStyling: true
                        })

                        confirmBox2.fire({
                        icon: 'error',
                        title: 'Failed!',
                        text: errorText, // take care later

                        })
                    });


                }
                else if (result.isDenied) {

                }
                })
            }
            })
        },


        unblockAgent(id){

            const confirmBox1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button-2 mx-2',
                cancelButton: 'btn custom-button bg-danger mx-2'
            },
            buttonsStyling: false
            })

            confirmBox1.fire({
            title: 'Are you sure?',
            text: "You'll be unblocking the Agent",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, unblock this agent!'
            }).then((result1) => {
            if (result1.isConfirmed) {



            const box1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button-2 mx-2',
                denyButton: 'btn custom-button bg-secondary mx-2'
            },
            buttonsStyling: false
            })

            box1.fire({
                title: 'Enter your pin',
                input: 'password',
                inputAttributes: {
                    maxlength: 4,
                    minlength: 4,
                    pattern: "[0-9]+",
                    required: true,
                },
                inputValidator: (value) => {
                    if (!value) {
                    return 'You need to enter your 4 digit pin!'
                    }

                },
                showDenyButton: true,
                denyButtonText: `Back`,
                confirmButtonText: 'Submit',
                }).then((result) => {
                if (result.isConfirmed) {

                this.$isLoading(true)

                let del_form =  new FormData();
                del_form.append('agent_id', id)
                del_form.append('pin', result.value)

                axios.post('api/agent/unblock', del_form,{headers:{"Content-Type":"application/json"}})
                    .then((data) => {

                        this.$isLoading(false)

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button-2',

                        },
                        buttonsStyling: false
                        })

                        confirmBox2.fire({
                        icon: 'success',
                        title: 'Unblocked!',
                        text: 'Agent Unblocked! ... '  ,

                        }).then((res)=>{
                            // reloading the page
                        // window.location.href = '/agents';
                        this.getResults()
                        })



                    })
                    .catch((err) => {
                        this.$isLoading(false)

                        // come back later
                        let errorText = typeof err.response.data.errors['pin'] !== 'undefined' ? err.response.data.errors['pin'][0] : '';

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button bg-danger',

                        },
                        buttonsStyling: true
                        })

                        confirmBox2.fire({
                        icon: 'error',
                        title: 'Failed!',
                        text: errorText, // take care later

                        })
                    });

                }
                else if (result.isDenied) {

                }
                })


            }
            })



        },




        openModal(row, modal_id){
            this.selectedAgent = row
            this.form.agent_id = row.id
            $('#modal' + modal_id).modal('show')

        },

        changeAgentBranchModal(row) {
            // console.log(row);
            // console.log(this.company.branches);
            // this.change_agent_branch_form.branches = this.company.branches;
            this.change_agent_branch_form.agent_id = row.id;
            this.change_agent_branch_form.agent_name = row.first_name +" "+ row.last_name;
            this.change_agent_branch_form.branch_id = row.branch_id;
            this.change_agent_branch_form.current_branch_id = row.branch_id;
            this.change_agent_branch_form.current_branch = row.branch;
            $('#changeAgentBranchModal').modal('show');
        },

        changeAgentBranch() {
            // console.log(this.change_agent_branch_form);
            let selected_branch = this.company.branches.filter(
                (d) => d.id == this.change_agent_branch_form.branch_id
            )[0];
            let branch_name = selected_branch["town_name"] +" ("+ selected_branch["city_name"]+")";

            const confirmSubmit = Swal.mixin({
                customClass: {
                confirmButton: "btn custom-button bg-danger mr-2",
                cancelButton: "btn custom-button-2 ml-2",
                },
                buttonsStyling: false,
            });
            confirmSubmit.fire({
                title: "Are you sure?",
                text:
                    'You are changing "' +
                    this.change_agent_branch_form.agent_name +
                    '\'s" branch to "' +
                    branch_name,
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes, change!",
            })
            .then((resp) => {
                if (resp.isConfirmed) {
                    // prompt for password
                    const confirmPwd = Swal.mixin({
                        customClass: {
                            confirmButton: "btn custom-button bg-danger  mr-2",
                            cancelButton: "btn custom-button-2 ml-2",
                        },
                        buttonsStyling: false,
                    });
                    confirmPwd.fire({
                        title: "Enter your password",
                        text: 'To confirm that you want to change "' +
                            this.change_agent_branch_form.agent_name +
                            '\'s" branch to "' +
                            branch_name,
                        input: "password",
                        inputPlaceholder: 'Enter your password',
                        inputAttributes: {
                            required: true,
                        },
                        inputValidator: (value) => {
                            if (!value) {
                                return "You need to enter your password!";
                            }
                        },
                        showCancelButton: true,
                        confirmButtonText: "Submit",
                        showLoaderOnConfirm: true,
                    })
                    .then(((res) => {
                        if (res.isConfirmed) {
                            this.change_agent_branch_form.admin_password = res.value;
                            this.$isLoading(true);

                            this.change_agent_branch_form
                            .post("/api/agent/changeAgentBranch", {
                                headers: { "Content-Type": "application/json" },
                            })
                            .then((data) => {
                                this.$isLoading(false);

                                const confirmBox2 = Swal.mixin({
                                    customClass: {
                                    confirmButton: "btn custom-button-2",
                                    },
                                    buttonsStyling: false,
                                });
                                confirmBox2.fire({
                                    icon: "success",
                                    title: "Agent Branch Changed!",
                                })
                                .then((res) => {
                                    window.location.reload();
                                });
                            })
                            .catch((err) => {
                                this.$isLoading(false);

                                // show them if that city name is taken
                                let errorText = "Agent Branch Change Unsuccessful";

                                if (err.response.data.errors["password"]) {
                                    errorText = err.response.data.errors["password"];
                                } else if (err.response.data.errors["general"]) {
                                    errorText = err.response.data.errors["general"];
                                }

                                const confirmBox2 = Swal.mixin({
                                    customClass: {
                                    confirmButton: "btn custom-button bg-danger",
                                    },
                                    buttonsStyling: true,
                                });

                                confirmBox2.fire({
                                    icon: "error",
                                    title: "Failed!",
                                    text: errorText,
                                });
                            });
                        }
                    }));
                }
            });
        }
  },

  mounted() {
        this.$store.dispatch("userProfile")

  },
  computed: {
        getUserProfile(){ //final output from here
            return this.$store.getters.getUserProfile
        },
  }
}
</script>
