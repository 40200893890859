

<template>
  <div>
    <div
      v-if="$gate.isBroker() && typeof companyInfo == 'object'"
      class="card custom-card-1 m-0"
    >
      <form
        class="form-horizontal"
        action=""
        @submit.prevent="createBranch"
        method="post"
      >
        <fieldset>
          <legend class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center">
              <span>Update Branch</span>
            </div>
            <a
              href="#"
              class="nav-link"
              active-class="active"
              onclick="history.go(-1);return false;"
            >
              <button class="btn bg-nyanza font-weight-bold text-gray">
                Go back
              </button>
            </a>
          </legend>
          <div class="card-header"></div>
          <legend>
            <span style="" class="font-weight-bold text-muted">{{
              companyInfo.company_name
            }}</span>
          </legend>

          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="form-group d-flex justify-content-between">
                  <div>
                    <div
                      class="text-danger"
                      v-if="form.errors.has('branches')"
                      v-html="form.errors.get('branches')"
                    />

                    <div
                      class="text-danger"
                      v-if="form.errors.has('phone_numbers')"
                      v-html="form.errors.get('phone_numbers')"
                    />
                  </div>
                </div>
              </div>

              <div
                v-for="row in form.branches"
                :key="row.id"
                class="col-12 pb-5"
              >
                <div class="row pb-3 mb-2 border-bottom">
                  <div class="col-6">
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <button type="button" class="btn">Branch</button>
                      </div>
                      <!-- /btn-group -->
                      <Select2
                        class="form-control border-0"
                        required
                        v-model="row.address"
                        :options="locations.towns"
                      />
                    </div>
                  </div>

                  <div class="col-12 pb-2 pt-3 pl-5">
                    <button
                      @click="addPhoneNumber(row.id)"
                      type="button"
                      class="float-right btn btn-sm btn-outline-secondary"
                    >
                      <i class="fa fa-plus"></i> Phone Numbers
                    </button>
                  </div>

                  <!-- <div v-for="ph in row.phone_numbers" :key="ph.id" class="col-6 pl-5 d-flex justify-content-end">
                                        <div class="input-group ml-5 mb-3">
                                            <div class="input-group-prepend">
                                                <span  class="input-group-text"><i class="fas fa-phone-alt"></i></span>
                                            </div>
                                            <input required type="number"  v-model="ph.number" class="form-control">
                                            <div class="input-group-append">
                                                <button v-if="row.phone_numbers.length > 1" type="button"
                                                    @click="deletePhoneNumber(row.id, ph.id)"
                                                     class="input-group-text border-0 text-danger bg-white">
                                                    <i class="fa fa-times"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div> -->

                  <div
                    v-for="ph in row.phone_numbers"
                    :key="ph.id"
                    class="col-6 pl-5 d-flex justify-content-end"
                  >
                    <div class="input-group ml-5 mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text"
                          ><i class="fas fa-phone-alt"></i
                        ></span>
                      </div>
                      <input
                        required
                        type="number"
                        v-model="ph.number"
                        class="form-control"
                      />
                      <div class="input-group-append">
                        <button
                          v-if="row.phone_numbers.length > 1"
                          type="button"
                          @click="deletePhoneNumber(row.id, ph.id)"
                          class="input-group-text border-0 text-danger bg-white"
                        >
                          <i class="fa fa-times"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 mt-3 d-flex justify-content-center">
                <button
                  type="submit"
                  :disabled="form.busy"
                  class="btn custom-button-1"
                >
                  UPDATE
                </button>
              </div>
            </div>
          </div>
        </fieldset>
      </form>
    </div>

    <div v-else-if="!$gate.isBroker() || companyInfo == 'error'">
      <not-found></not-found>
    </div>
  </div>
</template>


<script>
import Select2 from "v-select2-component";

export default {
  name: "EditBranchAgent",
  props: {},
  components: {
    Select2,
  },
  data() {
    return {
      branch: null, // added by baba to store the branchf
      form: null,

      companyInfo: "",

      b_index: 1,
      pn_index: 1,

      locations: [],
      phoneNumbers: [],
    };
  },
  //   created() {
  //     if (this.$gate.isBroker()) {
  //       this.getBranch();
  //       this.$isLoading(true);

  //       let temp_form = new Form({
  //         id: this.$route.params.agencyId, // edit-branch-agent/38/3 note the branchId and the agencyId
  //       });

  //       console.log("agencyId", this.$route.params.agencyId);
  //       temp_form
  //         .post("/api/broker/getAgency")
  //         .then(({ data }) => {
  //           console.log("Error ", data);
  //           // console.log("in the agency", this.branch.phoneNumbers[0].id)
  //           this.companyInfo = data;
  //           console.log("Form ", this.form);

  //           this.form = new Form({
  //             branch_id: this.$route.params.id, // an id that i added to use it to delete the display_phone numbers
  //             company_id: this.companyInfo.id,
  //             password: null,
  //             branches: [
  //               {
  //                 id: 1,
  //                 address: this.branch.town_id,
  //                 phone_numbers: this.branch.phoneNumbers.map((elem) => ({
  //                   id: elem.id,
  //                   branch_id: elem.branch_id,
  //                   number: elem.phone_number,
  //                 })),
  //               },
  //             ],
  //           });
  //           console.log("Form end ", this.form);
  //           this.$isLoading(false);
  //         })
  //         .catch((err) => {
  //           console.log("COMPANY INFO ERROR");
  //           this.companyInfo = "error";
  //           this.$isLoading(false);
  //         });
  //     }
  //   },
  created() {
    if (this.$gate.isBroker()) {
      //   this.getBranch();
      this.$isLoading(true);

      // Fetch branch data
      axios
        .get("/api/broker/branch/" + this.$route.params.id)
        .then(({ data }) => {
          console.log("Branch data:", data);
          this.branch = data;
          console.log("Params:", this.$route.params.id);

          // Fetch agency data
          let temp_form = new Form({
            id: this.$route.params.agencyId,
          });

          return temp_form.post("/api/broker/getAgency");
        })
        .then(({ data }) => {
          console.log("Agency data:", data);
          this.companyInfo = data;

          // Create the this.form object
          this.form = new Form({
            branch_id: this.$route.params.id,
            company_id: this.companyInfo.id,
            password: null,
            branches: [
              {
                id: 1,
                address: this.branch.town_id,
                phone_numbers: this.branch.phoneNumbers.map((elem) => ({
                  id: elem.id,
                  branch_id: elem.branch_id,
                  number: elem.phone_number,
                })),
              },
            ],
          });

          console.log("Form:", this.form);
          this.$isLoading(false);
        })
        .catch((err) => {
          console.error("Error fetching data:", err);

          // Handle errors here
          this.companyInfo = "error";
          this.$isLoading(false);
        });
    }
  },
  mounted() {
    if (this.$gate.isBroker()) this.$store.dispatch("locations");
  },
  computed: {
    getLocations() {
      //final output from here
      return this.$store.getters.getLocations;
    },
  },
  watch: {
    getLocations: function () {
      this.locations = this.getLocations;
    },
  },
  methods: {
    // getBranch() {
    //   axios
    //     .get("/api/broker/branch/" + this.$route.params.id)
    //     .then(({ data }) => {
    //       console.log("this branch ", data);
    //       this.branch = data;
    //       //   this.phoneNumbers = data.phoneNumbers;
    //       //   console.log("data", this.branch.phoneNumbers.forEach);
    //       console.log("params, ", this.$route.params.id);
    //     })
    //     .catch((err) => {});
    // },
    createBranch() {
      console.log("this is the form ", this.form);
      if (this.$gate.isBroker()) {
        const box1 = Swal.mixin({
          customClass: {
            confirmButton: "btn custom-button-2 mr-2",
            denyButton: "btn custom-button bg-secondary ml-2",
          },
          buttonsStyling: false,
        });

        box1
          .fire({
            title: "Enter your password",
            text: "To confirm the branches you're edit",
            input: "password",
            inputAttributes: {
              required: true,
            },
            inputValidator: (value) => {
              if (!value) {
                return "You need to enter your password!";
              }
            },
            showDenyButton: true,
            denyButtonText: `Back`,
            confirmButtonText: "Submit",
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.form.password = result.value;

              this.$isLoading(true);
              this.form
                .post("/api/broker/editBranch", {
                  headers: { "Content-Type": "application/json" },
                })
                .then((data) => {
                  this.$isLoading(false);

                  const confirmBox2 = Swal.mixin({
                    customClass: {
                      confirmButton: "btn custom-button-2",
                    },
                    buttonsStyling: false,
                  });

                  confirmBox2
                    .fire({
                      icon: "success",
                      title: "Edit as Branch(es)!",
                      text: "Branch(es) updation successful",
                    })
                    .then((res) => {
                      // redirect.. change later!
                      window.location.href =
                        "/view-agency/" + this.companyInfo.id;
                    });
                })
                .catch((err) => {
                  this.$isLoading(false);
                  let errorText = "The branch updation didn't work!";
                  if (err.response.data.errors["password"]) {
                    errorText = err.response.data.errors["password"];
                  }

                  const confirmBox2 = Swal.mixin({
                    customClass: {
                      confirmButton: "btn custom-button bg-danger",
                    },
                    buttonsStyling: true,
                  });

                  confirmBox2.fire({
                    icon: "error",
                    title: "Failed!",
                    text: errorText,
                  });
                });
            }
          });
      }
    },

    addPhoneNumber(b_id) {
      let i = this.form.branches.findIndex((b) => b.id == b_id);
      this.form.branches[i].phone_numbers.push({
        id: ++this.pn_index,
        branch_id: b_id,
        number: "",
      });
    },

    deletePhoneNumber(b_id, pn_id) {
      let i = this.form.branches.findIndex((b) => b.id == b_id);
      this.form.branches[i].phone_numbers = [
        ...this.form.branches[i].phone_numbers.filter((pn) => pn.id != pn_id),
      ];
    },
  },
};
</script>
