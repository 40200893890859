<template>
  <div>
    <div v-if="$gate.isBroker()" class="card custom-card-1">
      <div class="side-by-side-container" style="margin-bottom: 30px">
        <!-- Show this section for non-brokers -->

        <!-- Create the select (Search By) -->
        <div class="form-group">
          <label for="searchBy">Search By:</label>
          <select class="form-control" v-model="selectedSearchBy">
            <option value="today">Today</option>
            <option value="yesterday">Yesterday</option>
            <option value="dateRange">Date Range</option>
            <!-- Add other options as needed -->
          </select>
          <!-- v-if="selectedSearchBy === 'dateRange'" -->
          <!-- Show date inputs for Date Range -->
          <div v-if="selectedSearchBy === 'dateRange'" class="date-range">
            <div class="row">
              <div class="col-md-6">
                <label for="startDate">Start Date:</label>
                <input type="date" class="form-control" v-model="startDate" />
              </div>
              <div class="col-md-6">
                <label for="endDate">End Date:</label>
                <input type="date" class="form-control" v-model="endDate" />
              </div>
            </div>
          </div>
        </div>

        <!-- Create the dropdown with checkboxes for "Select" options -->
        <div
          class="form-group"
          :style="selectedSearchBy === 'dateRange' ? 'margin-top: -69px' : ''"
        >
          <label>Select:</label>
          <div class="dropdown" ref="dropdown">
            <button
              class="btn btn-secondary dropdown-toggle"
              style="width: 100%"
              type="button"
              id="selectOptionsDropdown"
              @click="toggleDropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Transaction Types
            </button>
            <div
              class="dropdown-menu"
              :class="{ show: dropdownOpen }"
              aria-labelledby="selectOptionsDropdown"
            >
              <div style="max-height: 200px; overflow-y: auto">
                <label
                  class="dropdown-item"
                  v-for="(option, index) in options"
                  :key="index"
                >
                  <input
                    type="checkbox"
                    :value="option.value"
                    v-model="selectedOptions"
                  />
                  {{ option.label }}
                </label>
              </div>
            </div>
          </div>
        </div>

        <div
          :style="selectedSearchBy === 'dateRange' ? 'margin-top: -69px' : ''"
        >
          <!-- Create the view button -->
          <button
            class="btn btn-primary"
            style="margin-top: 15px"
            @click="onViewClicked"
          >
            View
          </button>
        </div>
      </div>

      <!-- the tables shown here -->
      <fieldset v-if="viewDeposits">
        <legend
          class="d-flex justify-content-center flex-wrap"
          style="color: green; font-weight: bold; font-style: italic"
        >
          <span v-if="selectedSearchTitle == 'dateRange'"
            >Deposits Btw {{ startDate }} and {{ endDate }}</span
          >
          <span v-else>{{ selectedSearchTitle }}'s Completed Deposits</span>
        </legend>
        <!-- /.card-header -->
        <div v-if="deposits.length != 0" class="card-body table-responsive">
          <table
            id="example2"
            class="table table-bordered table-hover border-top"
          >
            <thead>
              <tr class="text-center">
                <th>Agency from</th>
                <th>Customer / Merchant</th>
                <th>User Type</th>
                <th>Amount</th>
                <th>Fee Paid</th>
                <th>Time Sent</th>
                <th>Time Completed</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="text-center"
                v-for="(row, index) in deposits"
                :key="index"
              >
                <td>
                  <span>
                    {{ row.agency_from }} -
                    {{ row.agency_from_branch }}
                  </span>
                  <br />

                  <small>from: {{ row.agent_from_fullname }}</small>
                </td>

                <td>
                  <span>
                    <router-link
                      v-if="row.other_user_type === 'Customer'"
                      class="cursor"
                      :to="'/view-user-profile/' + row.other_id"
                    >
                      {{ row.other_name }}
                      <img
                        v-if="row['other_is_mrz_verified']"
                        src="/images/verified-badge.png"
                        alt="image"
                        style="width: 16px; height: 16px"
                      />
                    </router-link>
                    <router-link
                      v-else-if="row.other_user_type === 'Merchant'"
                      class="cursor"
                      :to="'/view-merchant-store/' + row.other_id"
                    >
                      {{ row.other_name }}
                    </router-link>
                  </span>
                </td>
                <td>
                  {{ row.other_user_type }}
                </td>
                <td>
                  {{ dalasisFormatter(row.amount) }}
                </td>

                <td>
                  {{ dalasisFormatter(row.fee_paid) }}
                </td>

                <td>{{ row.time_sent }}</td>
                <td>
                  {{ row.time_updated }}
                </td>
              </tr>
            </tbody>
          </table>
          <div class="pt-3">
            <pagination
              align="center"
              :data="depositPageData"
              @pagination-change-page="getCompletedDeposits"
            ></pagination>
          </div>
        </div>

        <div class="text-center" v-else>
          <span
            ><i>{{ emptyDataText }}</i></span
          >
        </div>
        <!-- /.card-body -->
      </fieldset>

      <!-- completed withdrawals -->
      <fieldset v-if="viewWithdrawals">
        <legend
          class="d-flex justify-content-center flex-wrap"
          style="color: green; font-weight: bold; font-style: italic"
        >
          <span v-if="selectedSearchTitle == 'dateRange'"
            >Withdrawals Btw {{ startDate }} and {{ endDate }}</span
          >
          <span v-else>{{ selectedSearchTitle }}'s Completed Withdrawals</span>
        </legend>
        <!-- /.card-header -->
        <div v-if="withdrawals.length != 0" class="card-body table-responsive">
          <table
            id="example2"
            class="table table-bordered table-hover border-top"
          >
            <thead>
              <tr class="text-center">
                <th>Agency from</th>
                <th>Customer / Merchant</th>
                <th>User Type</th>
                <th>Amount</th>
                <th>Fee Paid</th>
                <th>Time Sent</th>
                <th>Time Completed</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="text-center"
                v-for="(row, index) in withdrawals"
                :key="index"
              >
                <td>
                  <span>
                    {{ row.agency_from }} -
                    {{ row.agency_from_branch }}
                  </span>
                  <br />

                  <small>from: {{ row.agent_from_fullname }}</small>
                </td>

                <td>
                  <span>
                    <router-link
                      v-if="row.other_user_type === 'Customer'"
                      class="cursor"
                      :to="'/view-user-profile/' + row.other_id"
                    >
                      {{ row.other_name }}
                      <img
                        v-if="row['other_is_mrz_verified']"
                        src="/images/verified-badge.png"
                        alt="image"
                        style="width: 16px; height: 16px"
                      />
                    </router-link>
                    <router-link
                      v-else-if="row.other_user_type === 'Merchant'"
                      class="cursor"
                      :to="'/view-merchant-store/' + row.other_id"
                    >
                      {{ row.other_name }}
                    </router-link>
                  </span>
                </td>
                <td>
                  {{ row.other_user_type }}
                </td>
                <td>
                  {{ dalasisFormatter(row.amount) }}
                </td>

                <td>
                  {{ dalasisFormatter(row.fee_paid) }}
                </td>

                <td>{{ row.time_sent }}</td>
                <td>
                  {{ row.time_updated }}
                </td>
              </tr>
            </tbody>
          </table>
          <div class="pt-3">
            <pagination
              align="center"
              :data="withdrawPageData"
              @pagination-change-page="getCompletedWithdrawals"
            ></pagination>
          </div>
        </div>

        <div class="text-center" v-else>
          <span
            ><i>{{ emptyDataText }}</i></span
          >
        </div>
        <!-- /.card-body -->
      </fieldset>

      <!-- transfers  -->
      <fieldset v-if="viewTransfers">
        <legend
          class="d-flex justify-content-center flex-wrap"
          style="color: green; font-weight: bold; font-style: italic"
        >
          <span v-if="selectedSearchTitle == 'dateRange'"
            >Transfers Btw {{ startDate }} and {{ endDate }}</span
          >
          <span v-else>{{ selectedSearchTitle }}'s Transfers</span>
        </legend>

        <!-- /.card-header -->
        <div v-if="transfers.length != 0" class="card-body table-responsive">
          <table
            id="example2"
            class="table table-bordered table-hover border-top"
          >
            <thead>
              <tr class="text-center">
                <th>TransactionsID</th>
                <th>From</th>
                <th>To</th>
                <th>Amount</th>
                <th>Time Sent</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="text-center"
                v-for="(row, index) in transfers"
                :key="index"
              >
                <td>
                  {{ row.unique_id }}
                </td>
                <td>
                  <router-link
                    v-if="row.from_user_type === 'Customer'"
                    class="cursor"
                    :to="'/view-user-profile/' + row.from_id"
                  >
                    {{ row.from_name }}
                  </router-link>

                  <router-link
                    v-else-if="row.from_user_type === 'Merchant'"
                    class="cursor"
                    :to="'/view-merchant-store/' + row.from_id"
                  >
                    {{ row.from_name }}
                  </router-link>
                </td>

                <td>
                  <router-link
                    v-if="row.to_user_type === 'Customer'"
                    class="cursor"
                    :to="'/view-user-profile/' + row.to_id"
                  >
                    {{ row.to_name }}
                  </router-link>
                  <router-link
                    v-else-if="row.to_user_type === 'Merchant'"
                    class="cursor"
                    :to="'/view-merchant-store/' + row.to_id"
                  >
                    {{ row.to_name }}
                  </router-link>
                </td>
                <td>
                  {{ dalasisFormatter(row.amount) }}
                </td>

                <td>{{ row.time_sent }}</td>
              </tr>
            </tbody>
          </table>
          <div class="pt-3">
            <pagination
              align="center"
              :data="transfersPageData"
              @pagination-change-page="getTransfers"
            ></pagination>
          </div>
        </div>

        <div class="text-center" v-else>
          <span
            ><i>{{ emptyDataText }}</i></span
          >
        </div>
        <!-- /.card-body -->
      </fieldset>
      <!-- cashPowerTransactions -->
      <fieldset v-if="viewAirtimeTx">
        <legend
          class="d-flex justify-content-center flex-wrap"
          style="color: green; font-weight: bold; font-style: italic"
        >
          <span v-if="selectedSearchTitle == 'dateRange'"
            >Airtime Tx Btw {{ startDate }} and {{ endDate }}</span
          >
          <span v-else>{{ selectedSearchTitle }}'s Airtime Tx</span>
        </legend>

        <!-- /.card-header -->
        <div
          v-if="airtimeTransactions.length != 0"
          class="card-body table-responsive"
        >
          <table
            id="example2"
            class="table table-bordered table-hover border-top"
          >
            <thead>
              <tr class="text-center">
                <th>From</th>
                <th>PhoneNumber</th>
                <th>Network</th>
                <th>Amount</th>
                <th>Time Sent</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="text-center"
                v-for="(row, index) in airtimeTransactions"
                :key="index"
              >
                <td>
                  {{ row.first_name }}
                </td>
                <td>
                  {{ row.phone_number }}
                </td>

                <td>
                  {{ row.network }}
                </td>
                <td>
                  {{ dalasisFormatter(row.amount) }}
                </td>

                <td>{{ row.created_at }}</td>
              </tr>
            </tbody>
          </table>
          <div class="pt-3">
            <pagination
              align="center"
              :data="transfersPageData"
              @pagination-change-page="getTransfers"
            ></pagination>
          </div>
        </div>

        <div class="text-center" v-else>
          <span
            ><i>{{ emptyDataText }}</i></span
          >
        </div>
        <!-- /.card-body -->
      </fieldset>

      <fieldset v-if="viewCashPowerTx">
        <legend
          class="d-flex justify-content-center flex-wrap"
          style="color: green; font-weight: bold; font-style: italic"
        >
          <span v-if="selectedSearchTitle == 'dateRange'"
            >CashPower Tx Btw {{ startDate }} and {{ endDate }}</span
          >
          <span v-else>{{ selectedSearchTitle }}'s CashPower Tx</span>
        </legend>

        <!-- /.card-header -->
        <div
          v-if="cashPowerTransactions.length != 0"
          class="card-body table-responsive"
        >
          <table
            id="example2"
            class="table table-bordered table-hover border-top"
          >
            <thead>
              <tr class="text-center">
                <th>From</th>
                <th>Meter owner</th>
                <th>Amount</th>
                <th>Time Sent</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="text-center"
                v-for="(row, index) in cashPowerTransactions"
                :key="index"
              >
                <td>
                  {{ row.first_name }}
                </td>

                <td>
                  {{ row.meter_owner }}
                </td>
                <td>
                  {{ dalasisFormatter(row.amount) }}
                </td>

                <td>{{ row.created_at }}</td>
              </tr>
            </tbody>
          </table>
          <div class="pt-3">
            <pagination
              align="center"
              :data="transfersPageData"
              @pagination-change-page="getTransfers"
            ></pagination>
          </div>
        </div>

        <div class="text-center" v-else>
          <span
            ><i>{{ emptyDataText }}</i></span
          >
        </div>
        <!-- /.card-body -->
      </fieldset>
    </div>
    <!-- /.card -->
    <div v-else>
      <not-found></not-found>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchTransactions",
  components: {},
  data() {
    return {
      selectedSearchBy: "today", // Default value for Search By
      selectedOptions: [], // Array to hold selected options
      startDate: "",
      endDate: "",
      options: [
        { value: "cashpower", label: "Cashpower" },
        { value: "deposit", label: "Deposit" },
        { value: "transfers", label: "Transfers" },
        { value: "airtime", label: "Airtime" },
        { value: "withdraw", label: "Withdraw" },
        // Add other options as needed
      ],
      dropdownOpen: false,
      // this is the deposits data
      airtimeTransactions: [],
      cashPowerTransactions: [],
      deposits: [],
      withdrawals: [],
      transfers: [],
      depositPageData: {},
      withdrawPageData: {},
      transfersPageData: {},
      airtimeTxPageData: {},
      cashPowerTxPageData: {},

      emptyDataText: "",
      viewDeposits: false,
      viewWithdrawals: false,
      viewTransfers: false,
      viewAirtimeTx: false,
      viewCashPowerTx: false,
      selectedSearchTitle: "",
    };
  },
  created() {},
  //   broker/cashpower-transactions
  methods: {
    // Function to handle the "View" button click
    onViewClicked() {
      this.selectedSearchTitle = this.selectedSearchBy;
      console.log("selected options ", this.selectedOptions);
      this.getCompletedDeposits();
      this.getCompletedWithdrawals();
      this.getTransfers();
      this.getAirtimeTransactions();
      this.getCashPowerTransactions();

      if (this.selectedOptions.includes("deposit")) {
        this.viewDeposits = true;
      } else {
        this.viewDeposits = false;
      }
      if (this.selectedOptions.includes("withdraw")) {
        this.viewWithdrawals = true;
      } else {
        this.viewWithdrawals = false;
      }
      if (this.selectedOptions.includes("transfers")) {
        this.viewTransfers = true;
      } else {
        this.viewTransfers = false;
      }
      if (this.selectedOptions.includes("airtime")) {
        this.viewAirtimeTx = true;
      } else {
        this.viewAirtimeTx = false;
      }
      if (this.selectedOptions.includes("cashpower")) {
        this.viewCashPowerTx = true;
      } else {
        this.viewCashPowerTx = false;
      }
    },
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },

    getCompletedDeposits(page) {
      if (typeof page === "undefined") {
        page = 1;
      }
      this.emptyDataText = "";

      axios
        .get("api/broker/deposit/allCompleted?page=" + page, {
          params: {
            filter:
              this.selectedSearchBy == "dateRange"
                ? { startDate: this.startDate, endDate: this.endDate }
                : this.selectedSearchBy,
          },
        })
        .then(({ data }) => {
          if (data.data.length != 0) {
            this.deposits = data.data;
            this.depositPageData = data;
          } else {
            this.deposits = [];
            this.depositPageData = {};
            this.emptyDataText = "You have no completed deposits";
          }

          this.$isLoading(false);
        })
        .catch((err) => {
          this.$isLoading(false);
        });
    },

    getCompletedWithdrawals(page) {
      if (typeof page === "undefined") {
        page = 1;
      }
      this.emptyDataText = "";

      // seconds withrawals
      axios
        .get("api/broker/withdrawal/allCompleted?page=" + page, {
          params: {
            filter:
              this.selectedSearchBy === "dateRange"
                ? { startDate: this.startDate, endDate: this.endDate }
                : this.selectedSearchBy,
          },
        })
        .then(({ data }) => {
          if (data.data.length != 0) {
            this.withdrawals = data.data;
            this.withdrawPageData = data;
          } else {
            this.withdrawals = [];
            this.withdrawPageData = {};
            this.emptyDataText = "You have no completed withdrawals";
          }

          this.$isLoading(false);
        })
        .catch((err) => {
          this.$isLoading(false);
        });
    },

    getTransfers(page) {
      if (typeof page === "undefined") {
        page = 1;
      }
      this.emptyDataText = "";
      axios
        .post("api/broker/customers/allTransfers?page=" + page, {
          params: {
            filter:
              this.selectedSearchBy == "dateRange"
                ? { startDate: this.startDate, endDate: this.endDate }
                : this.selectedSearchBy,
          },
        })
        .then(({ data }) => {
          if (data.data.length != 0) {
            this.transfers = data.data;
            this.transfersPageData = data;
          } else {
            this.transfers = [];
            this.transfersPageData = {};
            this.emptyDataText = "There are no transfers";
          }

          this.$isLoading(false);
        })
        .catch((err) => {
          this.transfers = [];
          this.transfersPageData = {};
          this.$isLoading(false);
        });
    },

    getAirtimeTransactions(page) {
      if (typeof page === "undefined") {
        page = 1;
      }
      this.emptyDataText = "";

      axios
        .get("api/broker/airtime-transactions?page=" + page, {
          params: {
            filter:
              this.selectedSearchBy == "dateRange"
                ? { startDate: this.startDate, endDate: this.endDate }
                : this.selectedSearchBy,
          },
        })
        .then(({ data }) => {
          console.log("data ", data);
          if (data.data.length != 0) {
            this.airtimeTransactions = data.data;
            console.log("airtime tx", this.airtimeTransactions);
            this.airtimeTxPageData = data;
          } else {
            console.log("airtime tx empt", this.airtimeTransactions);

            this.airtimeTransactions = [];
            this.airtimeTxPageData = {};
            this.emptyDataText = "You have no airtime transactions";
          }

          this.$isLoading(false);
        })
        .catch((err) => {
          this.$isLoading(false);
        });
    },

    getCashPowerTransactions(page) {
      if (typeof page === "undefined") {
        page = 1;
      }
      this.emptyDataText = "";

      axios
        .get("api/broker/cashpower-transactions?page=" + page, {
          params: {
            filter:
              this.selectedSearchBy == "dateRange"
                ? { startDate: this.startDate, endDate: this.endDate }
                : this.selectedSearchBy,
          },
        })
        .then(({ data }) => {
          console.log("data ", data);
          if (data.data.length != 0) {
            this.cashPowerTransactions = data.data;
            console.log("airtime tx", this.cashPowerTransactions);
            this.cashPowerTxPageData = data;
          } else {
            console.log("airtime tx empt", this.cashPowerTransactions);

            this.cashPowerTransactions = [];
            this.cashPowerTxPageData = {};
            this.emptyDataText = "You have no cashpower transactions";
          }

          this.$isLoading(false);
        })
        .catch((err) => {
          this.$isLoading(false);
        });
    },
  },
};
</script>

<style>
.side-by-side-container {
  display: flex;
  gap: 10px; /* Adjust the gap as needed */
  align-items: center; /* Vertically align the elements in the container */
}

/* Increase the width of the elements */
.form-group {
  flex: 1; /* Let the elements grow and take available space */
}

/* Optionally, you can style the button to have a minimum width */
.btn-primary {
  min-width: 120px; /* Adjust the width as needed */
}
</style>





