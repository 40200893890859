<template>
    <div v-if="$gate.isRegistrationComplete() && $gate.isCustomer()">
        <div
            v-if="
                getUserProfile.phone_number !== null &&
                getUserProfile.verified_at === null
            "
        >
            <div class="card px-4 py-2 border-0" style="box-shadow: none">
                <div class="card-body text-center">
                    <a
                        href="sms-verification"
                        style="text-decoration: underline"
                        class="card-body border-bottom"
                    >
                        <div class="in">
                            <div>Click here to Verify Phone Number</div>
                        </div>
                    </a>
                </div>
            </div>
        </div>

        <div class="customer general"
          v-else-if="$gate.isRegistrationComplete() && $gate.canTransact()">
                <a href="#" active-class="active" onclick="history.go(-1);return false;" class="nav-link text-right"><button class="btn bg-nyanza font-weight-bold text-gray"><i class="fa fa-arrow-left"></i> Go back</button></a>
            <div class="card w-100 custom-card-1 m-0 bg-white">
            <fieldset>

                <div class="card-body">
                
                    <form  @submit.prevent="send"  method="post">

                        <div class="row px-md-5">

                            <div class=" col-12 pt d-flex justify-content-center">
                                <h2 class="swal2-title" id="exampleModalLongTitle">Add New Bank Account</h2>
                            </div>
                            <div class="row py-4">
                            <div class="col">
                                <label >Bank</label>
                                <select class="form-control" v-model="form.bank_name" required>
                                    <option>Select Bank</option>
                                    <option value="ICIC Bank">ICIC Bank</option>
                                    <option value="SBI Bank">SBI Bank</option>
                                </select>
                            </div>
                            <div class="col">
                                <label >Account Holder Name</label>
                            <input type="text" name="account_holder_name" v-model="form.account_holder_name" class="form-control" placeholder="Account Holder Name" required>
                            </div>
                            <div class="col">
                                <label >Account Number</label>
                            <input type="number" name="account_no" v-model="form.account_no" class="form-control" placeholder="Account Number" required>
                            </div>
                            <div class="col">
                                <label >TIN</label>
                            <input type="number" name="tin" v-model="form.tin" class="form-control" placeholder="TIN" required>
                            </div>
                            </div>



                        </div>
                        <div v-if="!loading" class="modal-footer mt-3 border-0 d-flex justify-content-center">
                            <button
                            :disabled='!noErrors'
                            type="submit" class="btn custom-button-2 ">Save</button>
                        </div>
                    </form>


                </div>



            </fieldset>

            </div>

            <Footer :getUserProfile='getUserProfile' />
            <Send  :getUserProfile='getUserProfile' />
        </div>
    </div>
    <div v-else>
       <not-found></not-found>

    </div>
</template>



<script>


export default {
  name: 'BankDeposite',

  data() {
    return {
      form: new Form({
        bank_name: '',
        account_holder_name: '',
        account_no: '',
        tin: '',
      }),
       
 


    }
  },
  mounted() {
        this.$store.dispatch("userProfile");
    },
  computed: {
      noErrors(){
            return this.eChecks()
        },
        getUserProfile() {
            //final output from here
            return this.$store.getters.getUserProfile;
        },
},
 methods:{
    eChecks(){
      
          return true
     },

    send() {

        const box1 = Swal.mixin({
    customClass: {
        confirmButton: 'btn custom-button-2 mr-2',
        denyButton: 'btn custom-button bg-secondary ml-2'
    },
    buttonsStyling: false
    })

    box1.fire({
        title: 'Enter your pin',
        text: 'To confirm that you want to save bank details',
        input: 'password',
        inputAttributes: {
            required: true,

        },
        inputValidator: (value) => {
            if (!value) {
            return 'You need to enter your pin!'
            }

        },
        showDenyButton: true,
        denyButtonText: `Back`,
        confirmButtonText: 'Submit',
        }).then((result) => {
        if (result.isConfirmed) {

         this.form.pin = result.value


          this.$isLoading(true)
          this.form.post('/api/sendOTP',{headers:{"Content-Type":"application/json"}})
                .then((data) => {
                this.$isLoading(false)
                const box1 = Swal.mixin({
                    customClass: {
                        confirmButton: 'btn custom-button-2 mr-2',
                        denyButton: 'btn custom-button bg-secondary ml-2'
                    },
                    buttonsStyling: false
                    })

                    box1.fire({
                        title: 'Enter OTP which sent on your phone number.',
                        text: 'To confirm that you want to save bank details',
                        input: 'text',
                        inputAttributes: {
                            required: true,

                        },
                        inputValidator: (value) => {
                            if (!value) {
                            return 'You need to enter your OTP!'
                            }

                        },
                        showDenyButton: true,
                        denyButtonText: `Back`,
                        confirmButtonText: 'Submit',
                        }).then((result) => {
                        if (result.isConfirmed) {
                            this.form.otp = result.value
                            this.form.post('/api/verifyOTP',{headers:{"Content-Type":"application/json"}})
                                .then((data) => {
                                this.$isLoading(false)
                                this.form.post('/api/customer-save-bank-accounts',{headers:{"Content-Type":"application/json"}})
                                    .then((data) => {
                                    this.$isLoading(false)

                                        const confirmBox2 = Swal.mixin({
                                        customClass: {
                                            confirmButton: 'btn custom-button-2',

                                        },
                                        buttonsStyling: false
                                        })


                                        confirmBox2.fire({
                                        icon: 'success',
                                        title: 'Bank Details!',
                                        text: 'Bank Details saved successful!'

                                        }).then((res)=>{
                                            // redirect user to dashboard
                                        window.location.href = '/bank-deposite';
                                    // location.reload();

                                        })



                                    })
                                    .catch((err) => {
                                    this.$isLoading(false)

                                    let errorText = 'Bank Details saved Unsuccessful'

                                    if(err.response.data.errors['password']){
                                        errorText = err.response.data.errors['password']
                                    }
                                    else if(err.response.data.errors['general']){
                                        errorText = err.response.data.errors['general']
                                    }


                                        const confirmBox2 = Swal.mixin({
                                        customClass: {
                                            confirmButton: 'btn custom-button bg-danger',

                                        },
                                        buttonsStyling: true
                                        })

                                        confirmBox2.fire({
                                        icon: 'error',
                                        title: 'Failed!',
                                        text: errorText,

                                        })

                                    });


                                })
                                .catch((err) => {
                                this.$isLoading(false)

                                let errorText = 'OTP verification failed'

                                if(err.response.data.errors['password']){
                                    errorText = err.response.data.errors['password']
                                }
                                else if(err.response.data.errors['general']){
                                    errorText = err.response.data.errors['general']
                                }


                                    const confirmBox2 = Swal.mixin({
                                    customClass: {
                                        confirmButton: 'btn custom-button bg-danger',

                                    },
                                    buttonsStyling: true
                                    })

                                    confirmBox2.fire({
                                    icon: 'error',
                                    title: 'Failed!',
                                    text: errorText,

                                    })

                                });

                        }
                        })
                   



                }).catch((err) => {
                this.$isLoading(false)

                let errorText = 'OTP process failed!'

                if(err.response.data.errors['password']){
                    errorText = err.response.data.errors['password']
                }
                else if(err.response.data.errors['general']){
                    errorText = err.response.data.errors['general']
                }


                    const confirmBox2 = Swal.mixin({
                    customClass: {
                        confirmButton: 'btn custom-button bg-danger',

                    },
                    buttonsStyling: true
                    })

                    confirmBox2.fire({
                    icon: 'error',
                    title: 'Failed!',
                    text: errorText,

                    })

                });






            


        }
        })

    },
 } 

 

}
</script>

<style scoped>

.transaction_type a:hover{

    background: aliceblue;

}

/* .withdraw > span{
    width: 100% !important;
} */

.select2-container {
    width: 100% !important;
}

</style>
