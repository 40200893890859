<template>
    <div v-if="$gate.isCashdistributor()">



        <div class="card  custom-card-1 m-0">
          <form class="form-horizontal" action="" @submit.prevent='sendDeposite' method="post">

            <fieldset>
                <legend class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center">
                     <span>Add Beneficiaries</span>

                    </div>

                     <a @click="$router.go(-1)" class="nav-link" active-class="active"><button type="button" class="btn bg-nyanza font-weight-bold text-gray">Go back</button></a>

                </legend>
                    <div class="card-header">
                        <div class="row">
                          <div class="col-12">
                        <div class="d-flex justify-content-end">
                            <a class="mr-4" href="\sample_import\sample_beneficiaries.csv" downlaod ><i class="fa fa-download"></i> Sample file</a>
                        <div class="form-group ">
                        <input class="form-control" type="file" ref="file"  v-on:change="checkFile"  />
                        </div>
                        <div class="form-group ml-3">
                        <button type="button" class="btn btn-primary" style="white-space: nowrap;" @click="importExcelShow()">Import File</button>
                        </div>
                        </div>
                        </div>
                    </div>
                </div>

                    <div v-if="(rows.length != 0)" class="card-body borderedcard">
                        <table  id="example2" class="table table-hover border-top mb-3">
                        <thead >
                        <tr class="text-center">
                        <th>Customer</th>
                        <th>User Type</th>
                        <th>Phone Number</th>
                        
                        <th>Amount</th>
                        <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr class='text-center' :class="(!row.isValid) ? 'text-light bg-red' : ''" v-for='(row, index) in rows' :key='index'>
                            <td>
                            <h6>{{row.first_name}} {{row.last_name}}</h6>
                            <p>Username: <div v-html="row.username"></div></p>
                            </td>
                            <td>
                                <i v-if="row.register_status==0" class="text-danger"><i title="not registered" class="fa fa-user-slash"></i></i>
                                <i v-else  class="text-success"> <i title="registered" class="fa fa-user"></i></i>
                            </td>
                            <td>{{row.phone_number}}</td>
                            
                            <td>{{dalasisFormatter(row.amount)}}</td>
                            <td> <button type="button"   @click="removeRow(index)" class="btn btn-sm custom-button btn-danger">
                                {{!row.isValid ? 'Cancelled' : 'Cancel'}}
                            </button></td>
                        </tr>
                        <tfoot>
                        </tfoot>
                        </tbody>
                    </table>
                    <button  type="button"   @click="importExcel()" class="btn custom-button-1">
                        SUBMIT
                     </button>
                     <button type="button"   @click="CancelImport()" class="btn custom-button btn-danger">
                        Cancel
                     </button>
                    </div>

                    <div v-else class="card-body">
             

                        <div class="row">
                            <div class="col-12 mt-3 ">
                            <vue-repeater v-model="fields"></vue-repeater>
                            </div>
                         
                           

                            

                            <div class="col-12 mt-3 d-flex justify-content-center">
                                <button type="submit" :disabled="form.busy" class="btn custom-button-1">
                                    SUBMIT
                                </button>
                            </div>
                           
                        </div>

                    </div>




            </fieldset>

          </form>







        </div>

    </div>
    <div v-else>
        <not-found></not-found>
    </div>
</template>


<script>

import VueRepeater from 'vue-repeater';

import Select2 from 'v-select2-component';
import { VueTelInput } from 'vue-tel-input';

export default {
    name: 'AddBeneficiaries',
    props:{

    },
  components: {
    Select2,
    VueTelInput,
    VueRepeater,
   
  },
  data() {
    return {
        rows: [],
        bindProps: {
        mode:'international',
        disabledFetchingCountry: false,
        enabledCountryCode: true,
        disabledFormatting: true,
        enabledFlags: true,
        maxLen:25,
        dropdownOptions: {
          disabledDialCode: true
        },
        inputOptions: {
          showDialCode: true
        }
      },
   

        form: new Form({
            amount:'',
            names:'',
            campaign_id: this.$route.params.id

        }),
        locations: [],
        fields: [
        {
          name: 'beneficiaries-fields',
          value: {withdrawal_status:true}
        }
      ]

    }
  },
  created(){



  },
  mounted() {

    this.$store.dispatch("locations")

  },
    computed: {

        getLocations(){ //final output from here
            return this.$store.getters.getLocations
        },
    },
    watch:{

        getLocations: function () {
                this.locations = this.getLocations

            },
    },
  methods:{


    sendDeposite(){
        
      
      var names=[];
       this.fields.forEach(async element =>  {
        
        await axios.get('/api/beneficiaries/checkphone/'+element.value.phone_number,{headers:{"Content-Type":"application/json"}})
                   .then((data) => {
                     
                       if(data.data.data.username != undefined){
                           console.log(data.data.data.username);  
                           names.push(data.data.data.username)
                           
                       }else{
                        names.push('<i class="text-danger">(Username not Found)</i>')
                        
                          
                       }
                       this.form.names = names.join(',')
                       console.log(this.form.names);
             });
         
            
       });
       setTimeout(()=>{
        this.sendDeposite1();
       },700)
      
     
    },

      sendDeposite1(){

        if(!this.$gate.isCashdistributor())
            return;

            const confirmBox1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button bg-danger mr-2',
                cancelButton: 'btn custom-button-2 ml-2'
            },
            buttonsStyling: false
            })
           var total_amount=0;
      
           
            this.fields.forEach(async element =>  {
                total_amount += parseInt(element.value.amount);
           
              
                 
            });
          
           
            this.form.amount = total_amount
            console.log(this.form.names);
            confirmBox1.fire({
            title: 'Are you sure?',
            html: 'You will be depositing <b>' + this.dalasisFormatter(this.form.amount) + "</b> to <b>"+
                this.form.names+"</b>",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, deposit it!'
            }).then((result1) => {
            if (result1.isConfirmed) {


                // prompt for pin
            const box1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button bg-danger  mr-2',
                cancelButton: 'btn custom-button-2 ml-2'
            },
            buttonsStyling: false
            })

            box1.fire({
                title: 'Enter your pin',
                text: 'To confirm that you want to deposit ' + this.dalasisFormatter(this.form.amount) + " to Beneficiaries"
           ,
                input: 'password',
                inputAttributes: {
                    required: true,
                },
                inputValidator: (value) => {
                    if (!value) {
                    return 'You need to enter your pin!'
                    }

                },
                showDenyButton: true,
                denyButtonText: `Back`,
                confirmButtonText: 'Submit',
                }).then((result) => {
                if (result.isConfirmed) {

                this.form.pin = result.value
                this.form.fields = this.fields
                


                  this.$isLoading(true)

                    this.form.post('/api/distributor/deposit/depositForCustomer',{headers:{"Content-Type":"application/json"}})
                        .then((data) => {
                        this.$isLoading(false)

                            const confirmBox2 = Swal.mixin({
                            customClass: {
                                confirmButton: 'btn custom-button-2',

                            },
                            buttonsStyling: false
                            })


                            confirmBox2.fire({
                            icon: 'success',
                            title: 'Money deposited!',

                            }).then((res)=>{
                                // redirect user to dashboard
                            //window.location.href = '/broker-cash-distributors';
                            location.reload();

                            })



                        })
                        .catch((err) => {
                        this.$isLoading(false)

                        let errorText = 'Deposit Unsuccessful'

                        if(err.response.data.errors['pin']){
                            errorText = err.response.data.errors['pin']
                        }
                        else if(err.response.data.errors['general']){
                            errorText = err.response.data.errors['general']
                        }


                            const confirmBox2 = Swal.mixin({
                            customClass: {
                                confirmButton: 'btn custom-button bg-danger',

                            },
                            buttonsStyling: true
                            })

                            confirmBox2.fire({
                            icon: 'error',
                            title: 'Failed!',
                            text: errorText,

                            })

                        });


                }
                })





             }
            })
        },
        checkFile(e){
            console.log(e.target.files[0]);
            this.filename = "Selected File: " + e.target.files[0].name;
            var file = (e.target.files[0].name);
            var ext = file.substr((file.lastIndexOf('.') + 1));
        if (ext !== 'csv' && ext !== 'CSV') {
            const confirmBox2 = Swal.mixin({
                            customClass: {
                                confirmButton: 'btn custom-button bg-danger',

                            },
                            buttonsStyling: true
                            })
        confirmBox2.fire({
             icon: 'error',
             title: 'Failed!',
            text: 'Error! -Please upload file csv.',
            })
         e.target.value= '';
        }else{
            this.file = e.target.files[0];
        }



        },
        CancelImport: function() {
            this.rows=[];
        },
        importExcelShow: function () {
                        this.form.file = this.file

                        this.form.post('/api/beneficiaries/import-show',{headers:{"Content-Type":"application/json"}})
                            .then((data) => {
                            this.$isLoading(false)
                                console.log(data.data.data);
                                this.rows=data.data.data;



                            })
                            .catch((err) => {
                            this.$isLoading(false)

                            let errorText = 'Deposit Unsuccessful'

                            if(err.response.data.errors['pin']){
                                errorText = err.response.data.errors['pin']
                            }
                            else if(err.response.data.errors['general']){
                                errorText = err.response.data.errors['general']
                            }


                                const confirmBox2 = Swal.mixin({
                                customClass: {
                                    confirmButton: 'btn custom-button bg-danger',

                                },
                                buttonsStyling: true
                                })

                                confirmBox2.fire({
                                icon: 'error',
                                title: 'Failed!',
                                text: errorText,

                                })

                            });





                    },
                

        removeRow(index){
            const confirmBox1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button bg-danger mr-2',
                cancelButton: 'btn custom-button-2 ml-2'
            },
            buttonsStyling: false
            })

            confirmBox1.fire({
            title: 'Are you sure?',
            html: 'You want to remove this.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, remove it!'
            }).then((result1) => {
            if (result1.isConfirmed) {
                console.log('keyy',index);

                this.rows.splice(index,1);
                console.log(this.rows);
            }

             });
        },

        importExcel: function () {

            const confirmBox1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button bg-danger mr-2',
                cancelButton: 'btn custom-button-2 ml-2'
            },
            buttonsStyling: false
            })

            confirmBox1.fire({
            title: 'Are you sure?',
            html: 'You will be depositing to csv file users.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, deposit it!'
            }).then((result1) => {
            if (result1.isConfirmed) {

                     // prompt for pin
                        const box1 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button bg-danger  mr-2',
                            cancelButton: 'btn custom-button-2 ml-2'
                        },
                        buttonsStyling: false
                        })

                        box1.fire({
                            title: 'Enter your pin',
                            text: 'To confirm that you want to deposit to csv file users.' ,
                            input: 'password',
                            inputAttributes: {
                                required: true,
                            },
                            inputValidator: (value) => {
                                if (!value) {
                                return 'You need to enter your pin!'
                                }

                            },
                            showDenyButton: true,
                            denyButtonText: `Back`,
                            confirmButtonText: 'Submit',
                            }).then((result) => {
                            if (result.isConfirmed) {

                            this.form.pin = result.value


                            this.$isLoading(true)



                  //  this.form.file = this.file
                    this.form.rows = JSON.stringify(this.rows);

                    this.form.post('/api/beneficiaries/import',{headers:{"Content-Type":"application/json"}})
                        .then((data) => {
                        this.$isLoading(false)

                            const confirmBox2 = Swal.mixin({
                            customClass: {
                                confirmButton: 'btn custom-button-2',

                            },
                            buttonsStyling: false
                            })


                            confirmBox2.fire({
                            icon: 'success',
                            title: 'Money deposited!',

                            }).then((res)=>{
                                // redirect user to dashboard
                            //window.location.href = '/broker-cash-distributors';
                            location.reload();

                            })



                        })
                        .catch((err) => {
                        this.$isLoading(false)

                        let errorText = 'Deposit Unsuccessful'

                        if(err.response.data.errors['pin']){
                            errorText = err.response.data.errors['pin']
                        }
                        else if(err.response.data.errors['general']){
                            errorText = err.response.data.errors['general']
                        }


                            const confirmBox2 = Swal.mixin({
                            customClass: {
                                confirmButton: 'btn custom-button bg-danger',

                            },
                            buttonsStyling: true
                            })

                            confirmBox2.fire({
                            icon: 'error',
                            title: 'Failed!',
                            text: errorText,

                            }).then((res)=>{
                              
                                location.reload();

                                })

                        });





                }
            });
            }
            });

        }
  },



}
</script>
