<template>
  <div>
    <div v-if="!$gate.isRegistrationComplete()">
      <div class="card px-4 py-2 border-0" style="box-shadow: none">
        <div class="card-body text-center">
          <h3>Congratulations for joining!</h3>
          <div style="font-size: 1.2rem">
            To be a certified agent, you must
            <router-link
              to="/set-pin"
              class="text-info"
              style="text-decoration: underline"
            >
              set your pin</router-link
            >
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="$gate.isRegistrationComplete() && $gate.canTransact()">
      <div class="row general">
        <div class="col-12">
          <div class="card row" style="box-shadow: none">
            <div
              class="px-5 border-bottom pt-5 pb-4 d-flex justify-content-between"
            >
              <!-- cash on hands -->
              <div class="p-0">
                <span
                  v-if="userInfo.kashma_balance == null"
                  class="font-weight-bold font-size-1"
                  >Cash on Hand</span
                >
                <br />
                <span
                  v-if="userInfo.cash_at_bank != null"
                  class="font-weight-bold font-size-1"
                  >Cash At Bank</span
                >
                <br />
                <div class="d-flex align-items-end">
                  <span
                    v-if="userInfo.kashma_balance == null"
                    class="text-bgreen font-size-4 font-weight-bold"
                  >
                    {{ cash_on_hand }}
                  </span>
                  <span v-else class="text-bgreen font-size-4 font-weight-bold">
                    D {{ userInfo.cash_at_bank }}
                  </span>
                </div>
              </div>

              <div class="p-0" v-if="userInfo.kashma_balance != null">
                <span class="font-weight-bold font-size-1"
                  >Bank Fee Earnings (Money Transfer)</span
                >
                <br />
                <div class="d-flex align-items-end">
                  <span class="text-bgreen font-size-4 font-weight-bold">
                    D {{ userInfo.fee_earnings }}
                  </span>
                </div>
              </div>
              <div v-if="userInfo.kashma_balance == null">
                <div class="d-flex font-weight-bold align-items-center">
                  <span class="font-size-1">My Commission</span>
                </div>
                <br />
                <div class="d-flex align-items-end">
                  <span class="text-brown1 font-size-4 font-weight-bold">
                    {{ commission }}
                  </span>
                </div>
              </div>
            </div>

            <!-- <div
          v-if="companyInfo.company_type == 3"
          class="d-flex justify-content-between"
        >
          <div class="d-flex font-weight-bold align-items-center">
            <span class="font-size-1">Cash At Bank</span>
          </div>
          <div class="d-flex align-items-end">
            <span class="text-color-2 font-size-4 font-weight-bold">
              {{ cash_at_bank }}
            </span>
          </div>
        </div> -->

            <div class="px-5 pb-2 wallet-footer flex-wrap">
              <div class="text-center">
                <a
                  href="#"
                  class=""
                  data-toggle="modal"
                  data-target="#depositForCustomerModal"
                >
                  <div class="icon-wrapper bg-ogreen1">
                    <i class="fas fa-money-check-alt text-white"></i>
                  </div>
                  <strong>Deposit</strong>
                </a>
              </div>

              <div class="text-center">
                <a href="#" class="">
                  <div class="icon-wrapper bg-yellow">
                    <i class="fas fa-history text-white"></i>
                  </div>
                  <strong>Logs</strong>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div v-if="userInfo.can_transact != 0" class="col-12">
          <div class="card px-5 border-0" style="box-shadow: none">
            <div class="d-flex pt-3 justify-content-between flex-wrap">
              <div class="d-flex font-weight-bold align-items-center">
                <span class="font-size-1">Agency</span>
              </div>
              <div class="d-flex align-items-end">
                <span class="text-bwgreen font-size-3 font-weight-bold">
                  {{ userInfo.agency_name }}
                </span>
              </div>
            </div>
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center">
                <span>Agent Admin</span>
              </div>
              <div class="d-flex align-items-end">
                <span class="text-bwgreen font-weight-bold">
                  {{ userInfo.agent_admin_name }}
                </span>
              </div>
            </div>
            <div class="d-flex pb-2 justify-content-between flex-wrap">
              <div class="d-flex align-items-center">
                <span>Branch</span>
              </div>
              <div class="d-flex align-items-end">
                <span class="text-bwgreen font-weight-bold">
                  {{ userInfo.branch }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-12">
          <div class="card" style="box-shadow: none">
            <div class="row">
              <div class="col-12">
                <h3 class="p-3 card-title">
                  My Transactions (Collected & Outgoing)
                </h3>
              </div>
              <div class="col-12 text-muted">
                <TabNav
                  :tabs="[
                    'Last Year',
                    'This Year',
                    'Monthly',
                    'Weekly',
                    'Daily',
                  ]"
                  :selected="selected"
                  @selected="setSelected"
                >
                </TabNav>
              </div>

              <div class="col-12 pt-2">
                <div class="text-center py-3 font-weight-bold">
                  {{ chart1Title }}
                </div>
                <div class="mb-4 ml-3">
                  <div>
                    <span class="text-muted">Collected Total: </span>
                    <span
                      class="font-weight-bold"
                      style="color: rgba(81, 139, 201, 1)"
                    >
                      {{
                        dalasisFormatter(
                          chart1Selection["incoming"].reduce(
                            (total, num) => total + num,
                            0
                          )
                        )
                      }}
                    </span>
                  </div>
                  <div>
                    <span class="text-muted">Outgoing Total: </span>
                    <span
                      class="font-weight-bold"
                      style="color: rgba(148, 82, 165, 1)"
                    >
                      {{
                        dalasisFormatter(
                          chart1Selection["outgoing"].reduce(
                            (total, num) => total + num,
                            0
                          )
                        )
                      }}
                    </span>
                  </div>
                </div>
                <Chart1
                  :chartData="chart1Selection"
                  :barLabels="['Collected', 'Outgoing']"
                  :barColors="[
                    'rgba(81,139,201, 0.6)',
                    'rgba(148,82,165, 0.6)',
                  ]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <AgencyDepositButton
        :broker_balance="userInfo.broker_balance"
        :branch_id="userInfo.branch_id"
        :branch_town="userInfo.branch"
      />

      <AgencyFooter :getUserProfile="getUserProfile" />
    </div>

    <div v-else-if="$gate.isRegistrationComplete() && !$gate.canTransact()">
      <div class="card px-4 py-2 border-0" style="box-shadow: none">
        <div class="card-body text-center">
          <h3>Congratulations for completing your registration!</h3>
          <div style="font-size: 1.2rem">
            Your details have been sent to your agency for approval
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Chart1 from "./../charts/general/Chart1.vue";
import TabNav from "./../includes/tabNav";
import Tab from "./../includes/tabs";
import AgencyDepositButton from "./../includes/AgencyDepositButton.vue";

export default {
  name: "Dashboard",
  components: {
    Chart1,
    AgencyDepositButton,
    TabNav,
    Tab,
  },
  data() {
    return {
      selected: "Daily",
      commission: 0,
      userInfo: "",
      cash_on_hand: 0,

      chart1Title: "Daily Collected & Outgoing Transactions",
      chart1Selection: {
        incoming: [0],
        outgoing: [0],
      },
    };
  },
  created() {
    // fetch available locations (will use coordinate system one day) or user addresses
  },

  watch: {
    getUserProfile: function () {
      this.userInfo = this.getUserProfile;
      this.cash_on_hand = this.dalasisFormatter(this.userInfo.cash_on_hand);
      this.commission = this.dalasisFormatter(this.userInfo.commission);
      console.log("availability", this.userInfo.kashma_balance == null);
    },
    getAgentChart1: function () {
      this.chartData = this.getAgentChart1;
      this.chart1Selection = this.getAgentChart1["daily"];
    },
    selected: function () {
      if (this.selected == "Daily") {
        //
        this.chart1Selection = this.getAgentChart1["daily"];
        this.chart1Title = "Daily Collected & Outgoing Transactions";
      } else if (this.selected == "Weekly") {
        this.chart1Selection = this.getAgentChart1["weekly"];
        this.chart1Title = "Weekly Collected & Outgoing Transactions";
      } else if (this.selected == "Monthly") {
        this.chart1Selection = this.getAgentChart1["monthly"];
        this.chart1Title = "Month Collected & Outgoing Transactions";
      } else if (this.selected == "This Year") {
        this.chart1Selection = this.getAgentChart1["yearly"];
        this.chart1Title = "This Year's Collected & Outgoing Transactions";
      } else if (this.selected == "Last Year") {
        this.chart1Selection = this.getAgentChart1["last year"];
        this.chart1Title = "Last Year's & Outgoing Transactions";
      }
    },
  },

  mounted() {
    this.$store.dispatch("userProfile");
    this.$store.dispatch("agentChart1");
  },
  computed: {
    getUserProfile() {
      //final output from here
      return this.$store.getters.getUserProfile;
    },
    getAgentChart1() {
      return this.$store.getters.getAgentChart1;
    },
  },
  methods: {
    setSelected(tab) {
      this.selected = tab;
    },
  },
};
</script>

<style scoped>
.add-cash {
  font-size: 2.4rem;
  border-radius: 10px;
}

.sub-cash {
  font-size: 1.4rem;
  border-radius: 10px;
}

.add-cash:hover {
  background-color: #ccd673;
  background-image: linear-gradient(135deg, #ccd673 10%, #a3d673 100%);
}

.icon-wrapper {
  background: #6236ff;
  width: 48px;
  height: 48px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  color: #fff;
  font-size: 24px;
  margin-bottom: 14px;
}
.wallet-footer {
  border-top: 1px solid #dcdce9;
  padding-top: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.wallet-footer strong {
  display: block;
  color: #27173e;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.2em;
}
</style>
