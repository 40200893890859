<template>
    <div v-if="$gate.isCustomer()">



        <div class="card  custom-card-1 m-0">
          <form class="form-horizontal" action="" @submit.prevent='createKashlock' method="post">

            <fieldset>
                <legend class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center">
                     <span>Create Kashlock </span>

                    </div>

                     <a @click="$router.go(-1)" class="nav-link" active-class="active"><button class="btn bg-nyanza font-weight-bold text-gray">Go back</button></a>

                </legend>
                    <div class="card-header">
                    </div>


                    <div class="card-body">

                        <div class="row">
                            <!-- errors -->
                            <div class="col-6">
                                <div class="text-danger" v-if="form.errors.has('amount')" v-html="form.errors.get('amount')" />
                            </div>
                            <div class="col-6">
                                    <div class="text-danger" v-if="form.errors.has('period')" v-html="form.errors.get('period')" />
                            </div>

                            <!-- enderrors -->

                            <div class="col-6">
                                <div class="form-group pb-4">
                                    <label for="">Amount <i>(Minimum {{ this.min }})</i><span class="text-danger">*</span> </label>

                                    <input type="number" :min="this.min" v-model="form.amount" required  placeholder="Enter Kashlock Amount" class="form-control"><br>


                                </div>

                            </div>
                            <div class="col-6">
                                <div class="form-group pb-4">

                                    <label for="">Kashlock Period <span class="text-danger">*</span>
                                    </label>
                                   <select class="form-control" v-model="form.period" required >
                                        <option value="">Select Days</option>
                                        <option value="1">1 Days</option>
                                        <option value="60">60 Days</option>
                                        <option value="90">90 Days</option>
                                        <option value="180">180 Days</option>
                                   </select>


                                </div>

                            </div>
                           
                            <div class="col-12">
                                <div class="form-group pb-4">
                                    <label for="">Terms & Conditions</label>
                                    <div class="terms-box mb-2">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                    </div>

                                    <label><input type="checkbox" v-model="form.term_status" value="1" required> I agree with the Terms of service <span class="text-danger">*</span> </label>
                                    


                                </div>

                            </div>




                            <div class="col-12 mt-3 d-flex justify-content-center">
                                <button type="submit" :disabled="form.busy" class="btn custom-button-1">
                                    SUBMIT
                                </button>
                            </div>


                        </div>

                    </div>




            </fieldset>

          </form>







        </div>

    </div>
    <div v-else>
        <not-found></not-found>
    </div>
</template>


<script>



export default {
    name: 'createKashlock',
    props:{

    },
  components: {

  },
  data() {
    return {
        min:1,
        form: new Form({


            amount: '',
            period: '',
            term_status: '',

        }),

    }
  },
  created(){
    axios.get('/api/customer/getKaslockSettings')
            .then(({data}) => {
                this.min = data.minimum_amount;
             


            }).catch((err)=>{

        })


  },
  mounted() {



  },
    computed: {


    },
    watch:{


    },
  methods:{

      createKashlock(){

        if(!this.$gate.isCustomer())
            return;

            const box1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button-2 mr-2',
                denyButton: 'btn custom-button bg-secondary ml-2'
            },
            buttonsStyling: false
            })

            box1.fire({
                title: 'Enter your pin',
                text: 'To confirm that you want to create this Kashlock',
                input: 'password',
                inputAttributes: {
                    required: true,

                },
                inputValidator: (value) => {
                    if (!value) {
                    return 'You need to enter your pin!'
                    }

                },
                showDenyButton: true,
                denyButtonText: `Back`,
                confirmButtonText: 'Submit',
                }).then((result) => {
                if (result.isConfirmed) {

                 this.form.pin = result.value


                  this.$isLoading(true)

                    this.form.post('/api/customer/createKashlock',{headers:{"Content-Type":"application/json"}})
                        .then((data) => {
                        this.$isLoading(false)

                            const confirmBox2 = Swal.mixin({
                            customClass: {
                                confirmButton: 'btn custom-button-2',

                            },
                            buttonsStyling: false
                            })


                            confirmBox2.fire({
                            icon: 'success',
                            title: 'Kashlock Creation!',
                            text: 'Kashlock created successful!'

                            }).then((res)=>{
                                // redirect user to dashboard
                            window.location.href = '/kashlocks';
                           // location.reload();

                            })



                        })
                        .catch((err) => {
                        this.$isLoading(false)

                        let errorText = 'Kashlock Creation Unsuccessful'

                        if(err.response.data.errors['mypassword']){
                            errorText = err.response.data.errors['mypassword']
                        }
                        else if(err.response.data.errors['general']){
                            errorText = err.response.data.errors['general']
                        }


                            const confirmBox2 = Swal.mixin({
                            customClass: {
                                confirmButton: 'btn custom-button bg-danger',

                            },
                            buttonsStyling: true
                            })

                            confirmBox2.fire({
                            icon: 'error',
                            title: 'Failed!',
                            text: errorText,

                            })

                        });


                }
                })



      }

  },

}
</script>
<style>
.terms-box {
    padding: 11px;
    overflow-y: auto;
    max-height: 200px;
    border-radius: 10px;
    border: 1px solid #d3cece;
    box-shadow: inset 0 0 10px #d5d5d5;
}
</style>