<template>
    <div v-if="$gate.isCustomer()" >
            <legend class="text-center">
                <div class="">
                    <span>Buy GSM Credit</span>
                </div>

            </legend>
        <div class="card  custom-card-1 m-0">
            <fieldset>
<!-- Coming Soon Feature -->
                <div class="text-right">
                   <a href='#' class="nav-link" data-toggle="modal" data-target="#addNumberModal" >
                       <button class="btn btn-outline-info ">Add new {{data.gsm}} number <i class="fa fa-plus"></i></button>
                    </a>
                </div>

<!-- Coming Soon feature -->
            <div class="table-responsive card-body mt-2">
                <table  id="example2" class="table table-hover">
                    <thead >
                    <tr class="text-center">
                    <th>Logo</th>
                    <th>Number</th>
                    <th>Date Created</th>
                    <th class="border-left">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class='text-center center-vertical' v-for='(row, index) in phone_numbers' :key='index'>
                        <td><img :src="data.img" alt="img" class="gsm-logo">
                        </td>

                        <td>{{row.phone_number}}</td>
                        <td>{{row.date_created}}</td>
                        <td class="d-flex flex-wrap border-left justify-content-between">
                            <button @click="deleteNumber(row.id, row.user_id)" class="btn mb-4 btn-sm  btn-danger">
                                    Delete Number

                            </button>
                            <button
                            data-toggle="modal" @click="buyCredit(row)" data-target="#buyAirtimeModal"
                            class="btn mb-4  btn-sm text-white bg-ogreen1">
                                   Buy Airtime
                            </button>
                        </td>



                    </tr>
                    <tfoot>


                    </tfoot>
                    </tbody>
                </table>
                <div class="pt-3">
                    <pagination align="center" :data="pageData" @pagination-change-page="getResults"></pagination>

                </div>

            </div>


            </fieldset>






            <!-- Modals -->
            <div class="modal fade" id="addNumberModal" tabindex="-1" role="dialog" aria-labelledby="addNumberModalTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <form  @submit.prevent='addNumber' method="post">

                            <div class="modal-header border-0">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">

                                <div class="d-flex justify-content-center">
                                    <h2 class="swal2-title" id="exampleModalLongTitle">Add Number</h2>
                                </div>
                                <div class="card-body">
                                    <div class="form-group">

                                        <label for="meter_no" >Phone Number</label>
                                        <input type="text" required id='meter_no' v-model="add_phone_number"  placeholder="Enter phonenumber" class="form-control ">

                                    </div>
                                </div>

                            </div>
                            <div class="modal-footer border-0 d-flex justify-content-center">
                                <button  type="submit" class="btn custom-button-2 ">Save</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>

             <!-- Buy Cash power meter -->
            <div  class="modal fade" id="buyAirtimeModal" tabindex="-1" role="dialog" aria-labelledby="addNumberModalTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <form  @submit.prevent='buyAirtime' method="post">

                            <div class="modal-header border-0">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">

                                <div class="d-flex justify-content-center">
                                    <h2 class="swal2-title" id="exampleModalLongTitle">Buy Airtime</h2>
                                </div>
                                <div class="card-body">
                                    <div class="form-group">

                                        <label for="meter_no">Amount</label>
                                        <input type="text" required id='meter_no' v-model="amount"  placeholder="Enter amount here" class="form-control ">

                                    </div>
                                </div>

                                <div class="form-group py-0 my-0">
                                    <label v-if="amount_error.length > 0"
                                        class="text-danger">
                                        {{amount_error}}
                                    </label>
                                </div>

                            </div>
                            <div class="modal-footer border-0 d-flex justify-content-center">
                                <button  type="submit" class="btn custom-button-2 ">Save</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>



            <Send :getUserProfile='getUserProfile' />
            <Footer :getUserProfile='getUserProfile' />

        </div>

    </div>
    <div v-else>
        <not-found></not-found>

    </div>

</template>


<script>
import $ from 'jquery'
export default {
  name: 'Airtime',
  components: {

  },
  data() {
    return {
        data: '',
        balance: 0,
        phone_numbers: [],
        pageData: {},
        amount_error: '',
        // values to buy the airtime
        phone: '', // the number that the airtime is going to
        amount: '',
        add_phone_number: '',
        cus_phone: '',
        name: '',
        email: '',
        gsm: '',
        id: '',

        // provider' => ['required', 'numeric'],
        //     'phone' => ['required', 'numeric'],
        //     'amount' => ['required', 'numeric'],
        //     'cus_phone' => ['required'],
        //     'name' => ['required'],
        //     'email
        //     'id'
    }
  },

  created(){
    this.data = JSON.parse(localStorage.getItem("data"))
    console.log('gsm', this.data)

    if(this.$gate.isCustomer()){
            this.getResults()
        }
  },

  beforeRouteUpdate(to, from, next) {
    // Update the route params in the component data
    this.gsm = to.params.gsm;
    // Save the updated route params to local storage
    localStorage.setItem('routeParams', JSON.stringify(to.params));
    next();
  },

  // from the api, providers
  // africell => 2
  // gamcel => 1
  // comium => 3
  // qcell => 4

  methods:{
    buyCredit(row){
        this.cus_phone = row.phone_number;
        this.name = row.first_name;
        this.email = row.email;
        this.id = row.user_id;
        this.phone = row.phone_number; // this should be the phone to send the tokent
        this.gsm = row.gsm;
        console.log('row', row);
    },

    addNumber(){
        //   if(this.add_meter_no.length === 0)
        //   return
        //     this.$isLoading(true)

            let add_form =  new FormData();
            add_form.append('phone_number', this.add_phone_number)
            if (this.data.gsm == 'africell') {
                add_form.append('gsm', 1)
            } else if(this.data.gsm == 'qcell'){
                add_form.append('gsm', 2)
            } else if(this.data.gsm == 'gamcel'){
                add_form.append('gsm', 3)
            } else if(this.data.gsm == 'comium'){
                add_form.append('gsm', 4)
            }


        //   make an api call to check for meter details
            axios.post('api/airtimes/add-number',add_form,{headers:{"Content-Type":"application/json"}})
                .then(({data}) => {
                    console.log("data" +data)
                    this.$isLoading(false)

                    const confirmBox2 = Swal.mixin({
                    customClass: {
                        confirmButton: 'btn custom-button-2',

                    },
                    buttonsStyling: false
                    })


                    confirmBox2.fire({
                    icon: 'success',
                    title: 'Number Registered!',
                    text: 'Number added successful'

                    }).then((res)=>{
                        // redirect user to dashboard

                    window.location.href = '/buy-airtime';

                    })


                }).catch((err)=>{
                    this.$isLoading(false)
                    console.log("error ", err.response.data.errors)
                    let errorText = 'Unsuccessful'

                        errorText = err.response.data.errors


                    const confirmBox2 = Swal.mixin({
                    customClass: {
                        confirmButton: 'btn custom-button bg-danger',

                    },
                    buttonsStyling: true
                    })

                    confirmBox2.fire({
                    icon: 'error',
                    title: 'Failed!',
                    text: errorText,

                    })

                })

      },

      getResults(page){
            if (typeof page === 'undefined') {
            page = 1;
            }

            this.$isLoading(true)

            let formData = new FormData();
            if (this.data.gsm == 'africell') {
                formData.append('gsm', 1);
            } else if(this.data.gsm == 'qcell'){
                formData.append('gsm', 2);
            } else if(this.data.gsm == 'gamcel'){
                formData.append('gsm', 3);
            } else if(this.data.gsm == 'comium'){
                formData.append('gsm', 4);
            }

        axios.post('api/airtimes/savedNumbers?page=' + page, formData, {headers:{"Content-Type":"application/json"}})
                    .then((data) => {
                        console.log("phone_numbers", data.data.message.data)
                        this.phone_numbers = data.data.message.data;
                        this.pageData = data
                        this.$isLoading(false)
                    }).catch((err) => {
                        console.log(err)
                    })

        },

        deleteNumber(id, userId){

            const confirmBox1 = Swal.mixin({
            customClass: {
                cancelButton: 'btn custom-button-2 mx-2',
                confirmButton: 'btn custom-button bg-danger mx-2'
            },
            buttonsStyling: false
            })

            confirmBox1.fire({
            title: 'Are you sure?',
            text: "You'll be deleting the Number ",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete this number!'
            }).then((result1) => {
            if (result1.isConfirmed) {



            const box1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button-2 mx-2',
                denyButton: 'btn custom-button bg-secondary mx-2'
            },
            buttonsStyling: false
            })

            box1.fire({
                title: 'Enter your pin',
                input: 'password',
                inputAttributes: {
                    maxlength: 4,
                    minlength: 4,
                    pattern: "[0-9]+",
                    required: true,
                },
                inputValidator: (value) => {
                    if (!value) {
                    return 'You need to enter your 4 digit pin!'
                    }

                },
                showDenyButton: true,
                denyButtonText: `Back`,
                confirmButtonText: 'Submit',
                }).then((result) => {
                if (result.isConfirmed) {

                this.$isLoading(true)
                let del_form =  new FormData();
                del_form.append('id', id)
                del_form.append('user_id', userId)
                del_form.append('pin', result.value)

                axios.post('api/airtimes/delete-number', del_form,{headers:{"Content-Type":"application/json"}})
                    .then((data) => {
                        this.$isLoading(false)

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button-2',

                        },
                        buttonsStyling: false
                        })

                        confirmBox2.fire({
                        icon: 'success',
                        title: 'Deleted!',
                        text: 'Number Deleted! ... '  ,

                        }).then((res)=>{
                            // reloading the page
                        // window.location.href = '/agents';
                        this.getResults()
                        })



                    })
                    .catch((err) => {
                        this.$isLoading(false)

                        // come back later
                        let errorText = typeof err.response.data.errors['pin'] !== 'undefined' ? err.response.data.errors['pin'][0] : '';

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button bg-danger',

                        },
                        buttonsStyling: true
                        })

                        confirmBox2.fire({
                        icon: 'error',
                        title: 'Failed!',
                        text: errorText, // take care later

                        })
                    });


                }
                else if (result.isDenied) {

                }
                })
            }
            })
            },

            //// buy cashpower
            buyAirtime(){

            const confirmBox1 = Swal.mixin({
            customClass: {
                cancelButton: 'btn custom-button bg-danger mx-2',
                confirmButton: 'btn custom-button-2 mx-2'
            },
            buttonsStyling: false
            })

            confirmBox1.fire({
            title: 'Are you sure?',
            html: 'You will be purchasing an airtime of ' + this.dalasisFormatter(this.amount) + '<b>',

             icon: 'warning',
             showCancelButton: true,
             confirmButtonText: 'Yes, purchase it!'
            }).then((result1) => {
                if (result1.isConfirmed) {
                // close please
                $('#buyAirtimeModal').modal('hide')
                // prompt for pin
            const box1 = Swal.mixin({
            customClass: {
                cancelButton: 'btn custom-button bg-danger  mx-2',
                confirmButton: 'btn custom-button-2 mx-2'
            },
            buttonsStyling: false
            })

            box1.fire({
                title: 'Enter your pin',
                text: 'To confirm that you want to withdraw ' + this.dalasisFormatter(this.amount) + " from  ",
                input: 'password',
                inputAttributes: {
                    required: true,
                },
                inputValidator: (value) => {
                    if (!value) {
                    return 'You need to enter your pin!'
                    }

                },
                showCancelButton: true,
                confirmButtonText: 'Submit',
                showLoaderOnConfirm: true
                }).then((result) => {
                if (result.isConfirmed) {
                this.$isLoading(true)

                let formData = new FormData();

                let provider = 0;
                switch (this.gsm) {
                    case 1:
                        provider = 2;
                        break;
                    case 2:
                        provider = 4;
                        break;
                    case 3:
                        provider = 1;
                        break;
                    case 4:
                        provider = 3;
                    break;

                    default:
                        break;
                }
                provider = provider;
                formData.append('provider', provider)
                formData.append('pin', result.value);
                formData.append('amount', this.amount);
                formData.append('name', this.name);
                formData.append('phone', this.phone);
                formData.append('cus_phone', this.phone);
                formData.append('email', this.email);
                formData.append('id', this.id);

                axios.post('/api/airtimes/buy-airtime', formData, {headers:{"Content-Type":"application/json"}})
                    .then((res) => {
                  this.$isLoading(false)

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button-2',

                        },
                        buttonsStyling: false
                        })

                        confirmBox2.fire({
                        icon: 'success',
                        title: 'Purchase Successful!',
                        // html: '<span>Your Cashpower token is: </span>'
                        //     + '<br><h3>Token: <b>' + res.data.data.nawec.token + '</b></h3>'


                        }).then((res)=>{

                        // ask them to pick the transaction type. Give em option to exit/not choose.

                        window.location.href = '/buy-airtime'

                        })



                    })
                    .catch((err) => {
                        console.log("error", err);
                        this.$isLoading(false)

                        // show them if that city name is taken
                        let errorText = 'Transaction Unsuccessful'

                        if(err.response.data.errors['pin']){
                            errorText = err.response.data.errors['pin']
                        }

                        else if(err.response.data.errors['highamount']){
                            errorText = err.response.data.errors['highamount']
                        }
                        else if(err.response.data.errors['lowamount']){
                            errorText = err.response.data.errors['lowamount']
                        }


                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button bg-danger',

                        },
                        buttonsStyling: true
                        })

                        confirmBox2.fire({
                        icon: 'error',
                        title: 'Failed!',
                        text: errorText,

                        })
                    });

                }
                })

            } else{
                // window.location.href = '/nawec-phone_numbers';
            }
            })

      },

         },

         watch:{
        getUserProfile: function () {

            if(this.$gate.isStore()){
                document.getElementById('bladeCompanyName').innerHTML = this.getUserProfile.company_name

            }
            document.getElementById('bladeAmount').innerHTML =  this.dalasisFormatter(this.getUserProfile.balance)


        },

        amount: function(){

          this.amount_error = ""

          if(this.amount.length >= 1){


            if(this.amount > this.getUserProfile.balance){

                this.amount_error = "You can't withdraw more than your current balance"
            }

            else{
                this.amount_error = ""

            }
          }
          else{
                this.amount_error = ""

          }


        }
  },

  mounted() {
    console.log("mounted ", this.$route.params.gsm);
        this.$store.dispatch("userProfile")
  },
    computed: {

        getUserProfile(){ //final output from here
            return this.$store.getters.getUserProfile
        },

    },
}
</script>

<style scoped>
    .gsm-logo{
        width: 20%;
        border-radius: 100%;
    }

</style>

