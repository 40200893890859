<template>
    <div class="agency-management">
        <legend class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center">
                <h2 class="title">Advert Management</h2>
            </div>
            <router-link to="/create-adverts" class="nav-link" active-class="active" exact>
                <button class="btn btn-primary">
                    Create new Advert <i class="fa fa-plus"></i>
                </button>
            </router-link>
        </legend>

        <table class="agency-table table table-striped table-hover">
            <thead class="thead-dark">
                <tr>
                    <th class="title-col">Ad Title</th>
                    <th class="content-col">Ad Content</th>
                    <th class="logo-col">Ad Logo</th>
                    <th class="title-col">Active</th>
                    <th class="actions-col">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr v-if="ads.length === 0">
                    <td colspan="4" class="text-center">No ads found.</td>
                </tr>
                <tr v-for="ad in ads" :key="ad.id">
                    <td class="title-col">{{ ad.title }}</td>
                    <td class="content-col">{{ ad.content }}</td>
                    <td class="logo-cell">
                        <div class="logo-container">
                            <img :src="'/storage/'+ ad.logo" :alt="ad.logo" class="logo-img" />
                        </div>
                    </td>
                    <td class="active-col">{{ ad.active ? 'true' : 'false' }}</td>
                    <td class="actions-col">
                        <!-- <router-link :to="'/edit-adverts/' + ad.id" class="nav-link" active-class="active" exact>
                            <button class="btn btn-sm btn-outline-primary action-btn mr-2">
                                <i class="fa fa-pencil"></i>
                            </button>
                        </router-link> -->
                        <button class="btn btn-sm btn-outline-info action-btn" @click="toggleAdStatus(ad.id, ad.active)">
                            {{ ad.active ? 'D.A' : 'A' }}
                        </button>
                        <button class="btn btn-sm btn-outline-danger action-btn" @click="deleteAd(ad.id)">
                            <i class="fa fa-trash"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: "Adverts",
    data() {
        return {
            ads: [],
        };
    },
    created() {
        this.fetchAds();
    },
    methods: {
        fetchAds() {
            axios
                .get("api/broker/adsTable")
                .then((response) => {
                    this.ads = response.data;
                })
                .catch((error) => {
                    console.error("Error fetching ads:", error);
                });
        },
        toggleAdStatus(adId, currentStatus) {
        axios.post(`/api/broker/adsTable/${adId}/activate`, { active: !currentStatus })
            .then(() => {
                // Update the ad status in the local data
                const adIndex = this.ads.findIndex(ad => ad.id === adId);
                if (adIndex !== -1) {
                    this.$set(this.ads, adIndex, {
                        ...this.ads[adIndex],
                        active: !currentStatus
                    });
                }
                // Show a success message if needed
            })
            .catch(error => {
                console.error('Error toggling ad status:', error);
                // Show an error message if needed
            });
    },
        // fetchSpAds(adId) {
        //     axios
        //         .get("api/broker/adsTable/edit/" + adId)
        //         .then((response) => {
        //             this.ads = response.data;
        //         })
        //         .catch((error) => {
        //             console.error(error);
        //         });
        // },
        deleteAd(adId) {
            axios
                .delete("api/broker/adsTable/" + adId)
                .then(() => {
                    this.ads = this.ads.filter((ad) => ad.id !== adId);
                })
                .catch((error) => {
                    console.error("Error deleting ad:", error);
                });
        },
    },
};
</script>

<style>
.agency-management {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Roboto', sans-serif;
}

.title {
    color: #333;
    font-weight: 600;
}

.agency-table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    table-layout: fixed;
}

.agency-table th,
.agency-table td {
    padding: 12px 15px;
    text-align: center;
    border-bottom: 1px solid #ddd;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.agency-table th {
    background-color: #333;
    color: #fff;
    font-weight: 600;
}

.agency-table tr:hover {
    background-color: #f5f5f5;
}

.title-col {
    width: 30%;
}

.content-col {
    width: 50%;
}

.logo-col {
    width: 20%;
}

.actions-col {
    width: 30%;
    position: relative;
}

.logo-cell {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 60px;
}

.logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.logo-img {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
}

.action-btn {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    transition: all 0.2s ease-in-out;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.action-btn:hover {
    transform: scale(1.1) translateY(-50%);
}

.action-btn:first-child {
    left: 12px /* Adjust as needed */
}


.btn-outline-primary {
    color: #007bff;
    border-color: #007bff;
}

.btn-outline-primary:hover {
    background-color: #007bff;
    color: #fff;
}

.btn-outline-danger {
    color: #dc3545;
    border-color: #dc3545;
}

.btn-outline-danger:hover {
    background-color: #dc3545;
    color: #fff;
}
</style>