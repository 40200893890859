<template>
    <div class="gig-form-container">
        <legend class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center">
                <span></span>
            </div>
            <a
                href="#"
                class="nav-link"
                active-class="active"
                onclick="history.go(-1);return false;"
            >
                <button class="btn bg-nyanza font-weight-bold text-gray">
                    Go back
                </button>
            </a>
        </legend>
        <h2 class="heading">POST AN AD</h2>
        <p class="subheading">Post an add to the KashMa app</p>
        <form @submit.prevent="submitForm" class="gig-form">
            <div class="form-group">
                <label for="companyName">Ad Title</label>
                <input
                    type="text"
                    id="title"
                    v-model="adsField.title"
                    required
                    class="form-control"
                />
                <span v-if="!adsField.title" class="error-text"
                    >Ad Title is required.</span
                >
            </div>
            <div class="form-group">
                <label for="jobDescription">Ad Content</label>
                <textarea
                    id="content"
                    v-model="adsField.content"
                    class="form-control"
                ></textarea>
                <span v-if="!adsField.content" class="error-text"
                    >Ad content is required.</span
                >
            </div>
            <div class="form-group">
                <label for="companyLogo">Company Logo</label>
                <div class="file-input">
                    <input
                        type="file"
                        id="logo"
                        @change="handleFileUpload"
                        class="form-control-file"
                    />
                    <span v-if="adsField.logo"></span>
                    <span v-else class="no-file">No file selected.</span>
                </div>
                <span v-if="!adsField.logo" class="error-text"
                    >Ad logo is required.</span
                >
            </div>
            <button
                type="submit"
                :disabled="isFormEmpty"
                class="btn btn-primary"
            >
                Post Ad
            </button>
        </form>
        <!-- Snackbar -->
        <div v-if="showSnackbar" class="snackbar">
            {{ snackbarMessage }}
            <button @click="hideSnackbar">Close</button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            adsField: {
                title: "",
                content: "",
                logo: null,
            },
            showSnackbar: false,
            snackbarMessage: "",
        };
    },
    computed: {
        isFormEmpty() {
            return (
                this.adsField.title === "" ||
                this.adsField.content === "" ||
                this.adsField.logo === null
            );
        },

        truncatedContent() {
            // Check if content exists and its length is greater than 25
            if (this.adsField.content && this.adsField.content.length > 25) {
                // Return truncated content
                return this.adsField.content.substring(0, 40) + "...";
            } else {
                // Return original content if it's within 25 characters
                return this.adsField.content;
            }
        },
    },

    methods: {
        handleFileUpload(event) {
            this.adsField.logo = event.target.files[0];
        },
        submitForm() {
            console.log("Title:", this.adsField.title);
            console.log("Content:", this.adsField.content);
            console.log("Logo:", this.adsField.logo);
            // Prepare form data
            let formData = new FormData();
            formData.append("title", this.adsField.title);
            formData.append("content", this.adsField.content);
            formData.append("logo", this.adsField.logo);

            // Send form data to the specified route
            axios
                .post("api/broker/adsPost", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data", // Set content type for form data
                    },
                })
                .then((response) => {
                    // Handle response if needed

                    this.showSnackbarMessage(
                        "Ad posted successfully",
                        "success"
                    );
                    // Clear form fields
                    this.adsField.title = "";
                    this.adsField.content = "";
                    this.adsField.logo = null;
                })
                .catch((error) => {
                    // Handle error if needed
                    console.error(error);
                });
        },
        showSnackbarMessage(message, variant) {
            this.snackbarMessage = message;
            this.showSnackbar = true;
            setTimeout(() => {
                this.hideSnackbar();
            }, 3000); // Adjust duration as needed
        },
        hideSnackbar() {
            this.showSnackbar = false;
        },
    },
};
</script>

<style scoped>
.gig-form-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.heading {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.subheading {
    color: #666;
    margin-bottom: 20px;
}

.gig-form {
    display: grid;
    grid-gap: 20px;
}

.form-group {
    display: grid;
    grid-gap: 5px;
}

.form-control {
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.form-control-file {
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
}

.file-input {
    display: flex;
    align-items: center;
}

.no-file {
    color: #999;
    margin-left: 10px;
}

.btn {
    display: inline-block;
    padding: 8px 16px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s;
}

.btn-primary {
    color: #fff;
    background-color: #20c997;
    border: 1px solid #20c997;
}

.btn-primary:hover {
    background-color: #20c997;
    border-color: #055c5c;
}

.error-text {
    color: red;
    font-size: 12px;
    margin-top: 5px;
}

.snackbar {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%); /* Center horizontally */
    background-color: #333;
    color: #fff;
    padding: 16px;
    border-radius: 4px;
    z-index: 999; /* Ensure it appears above other elements */
}

.snackbar button {
    margin-left: 10px;
}
</style>
