<template>
    <div>
        <div v-if="$gate.isCustomer()" class="card  p-2 m-0">
            <fieldset class="mt-5">
                            <legend class="d-flex justify-content-between flex-wrap">
                                <div class="d-flex align-items-center">
                                <span>Kashlocks</span>

                                </div>

                           
                            <router-link v-bind:to="'/kashlock-create'" class="nav-link" active-class="active" exact>
                                <button class="btn btn-outline-info">Create new KashLock <i class="fa fa-plus"></i></button>
                                </router-link>
                            </legend>
                            <div class="card-header">
                            </div>


                        <div>



                        </div>




                        <div  class="card-body p-0 mt-2 table-responsive">
                            <table  id="example2" class="table table-hover border-top">
                                <thead >
                                <tr class="text-center">
                                <th>Customer Name</th>
                                <th>Period</th>
                                <th>Amount</th>
                                <th>Credit</th>
                                <th>Created Date</th>
                                <th>Maturity Date</th>
                                <th>Status</th>
                                <th class="border-left">Action</th>
                                </tr>
                                </thead>
                                <tbody v-if="kashlocks.length != 0">
                                <tr class='text-center' v-for='(row, index) in kashlocks' :key='index'>
                                    <td>{{row.first_name+' '+row.last_name}}
                                     </td>
                                    <td>{{row.period}} Days</td>
                                    <td>{{dalasisFormatter(row.amount)}}</td>
                                    <td><span class="text-danger">-{{dalasisFormatter(row.credit_amount)}}</span></td>
                                    <td>{{row.time}}</td>
                                    <td>{{row.maturity_date}}</td>
                                   
                                    <td v-if="row.Kstatus==0"><span class="badge badge-success badge-pill">Locked</span></td>
                                    <td v-else><span class="badge badge-danger badge-pill">Unlocked</span></td>


                                    <td class="d-flex flex-wrap border-left justify-content-around">


                                        <router-link v-bind:to="'/kashlock-view/' + row.Cid"
                                        active-class="active" exact class="btn mb-4 custom-button-purple1" >
                                            <i class="fas fa-eye"></i>
                                        </router-link>
                                        
                                        <a href="javascript::void();"  v-if="row.Kstatus==0" active-class="active" class="btn mb-4 custom-button-1" @click="unlock(row.Cid,row.amount,row.credit_amount)">
                                            <i class="fas fa-unlock"></i> Unlock 
                                        </a>
                                        <router-link v-if="row.Ustatus==null && row.Kstatus==0" v-bind:to="'/kashlock-credit/' + row.Cid"
                                        active-class="active" exact class="btn mb-4 custom-button-red1" >
                                            <i class="fas fa-plus"></i> Credit
                                        </router-link>


                                    </td>



                                </tr>
                                <tfoot>


                                </tfoot>
                                </tbody>
                                <tbody  v-else>
                                    <tr class='text-center'>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td><span><i>You have no kashlocks.</i></span></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                    
                                </tbody>
                            </table>
                            <div class="pt-3">
                                <pagination align="center" :data="pageData" @pagination-change-page="getResults"></pagination>

                            </div>

                        </div>
                    


            </fieldset>
        </div>
    </div>

</template>

    <script>

    export default {
        name: 'kashlocks',

        components: {


        },
      data() {
        return {
            balance: 0,
            kashlocks: null,
            pageData: {},
            fee:'',
            form: new Form({
            amount:'',
            id: ''

        }),




        }
      },
      created(){

          // fetch campaign table
          if(this.$gate.isCustomer()){
                this.getResults()
                axios.get('/api/customer/getKaslockSettings')
                .then(({data}) => {
                    this.fee= data.cancellation_fee_percentage;
                }).catch((err)=>{

                })
              

            }


      },
      watch:{
        getUserProfile: function () {
            if((this.getUserProfile.balance ==0 || this.getUserProfile.balance ==0.00) && this.getUserProfile.used_kashlock_credit > 0){
                this.getUserProfile.balance= -this.getUserProfile.used_kashlock_credit;
            }
            this.balance = this.dalasisFormatter(this.getUserProfile.balance)
            document.getElementById('bladeAmount').innerHTML = this.balance
            document.getElementById('bladeCreditAmount').innerHTML = this.dalasisFormatter(this.getUserProfile.kashlock_credit)
            if(this.getUserProfile.kashlock_credit > 0){
                document.getElementById('bladeCreditBlock').style.display = 'block';
            }

        },

  },

  mounted() {
        this.$store.dispatch("userProfile")


  },
  computed: {



getUserProfile(){ //final output from here
    return this.$store.getters.getUserProfile
},

},
      methods:{

        getResults(page){
                if (typeof page === 'undefined') {
                page = 1;
                }

                this.$isLoading(true)

                let temp_form = new Form({
                    page : page,
            })
            temp_form.post('/api/customer/kashlocks')
                    .then(({data}) => {
                      console.log("kashlocks", data)
                      this.kashlocks = data.data;
                      this.pageData = data
                        this.$isLoading(false)


                    }).catch((err)=>{
                      this.kashlocks = []
                      this.pageData = {}
                    this.$isLoading(false)


                })

            },

        unlock(id,amount,credit_amount){

        if(!this.$gate.isCustomer())
            return;

            const confirmBox1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button bg-danger mr-2',
                cancelButton: 'btn custom-button-2 ml-2'
            },
            buttonsStyling: false
            })
            const fee_amount=(this.fee*amount)/100;
            let total=amount-fee_amount;

            if(credit_amount !=null){
                total=total-credit_amount;
            var text='YOU WILL BE UNLOCKING YOUR KASHLOCK OF <b>' + this.dalasisFormatter(amount) + "</b> BEFORE THE MATURITY DATE. A FEE OF"+
            "<b>" + this.dalasisFormatter(fee_amount.toFixed(2)) +"</b> WILL BE DEDUCTED FROM IT. AND CREDIT AMOUNT  <b>"+ this.dalasisFormatter(credit_amount.toFixed(2))+"</b>" +
            " WILL BE DEDUCTED. THEREFORE THE AMOUNT YOU WILL RECEIVE IS <b>"+ this.dalasisFormatter(total.toFixed(2))+" </b>";
            }else{
                var text='YOU WILL BE UNLOCKING YOUR KASHLOCK OF <b>' + this.dalasisFormatter(amount) + "</b> BEFORE THE MATURITY DATE. A FEE OF"+
            " <b>" + this.dalasisFormatter(fee_amount.toFixed(2)) +"</b> WILL BE DEDUCTED FROM IT. THEREFORE THE AMOUNT YOU WILL RECEIVE IS <b>"+ this.dalasisFormatter(total.toFixed(2))+"</b>";
            }
          
            confirmBox1.fire({
            title: 'Are you sure?',
            html: text,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, unlock it!'
            }).then((result1) => {
            if (result1.isConfirmed) {


                // prompt for pin
            const box1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button bg-danger  mr-2',
                denyButton: 'btn custom-button-2 ml-2'
            },
            buttonsStyling: false
            })

            box1.fire({
                title: 'Enter your pin',
                text: 'To confirm that you want to unlock kashlock '
        ,
                input: 'password',
                inputAttributes: {
                    required: true,
                },
                inputValidator: (value) => {
                    if (!value) {
                    return 'You need to enter your pin!'
                    }

                },
                showDenyButton: true,
                denyButtonText: `Back`,
                confirmButtonText: 'Submit',
                }).then((result) => {
                if (result.isConfirmed) {

                this.form.pin = result.value
                this.form.id = id
                


                this.$isLoading(true)

                    this.form.post('/api/customer/unlockKashlock',{headers:{"Content-Type":"application/json"}})
                        .then((data) => {
                        this.$isLoading(false)

                            const confirmBox2 = Swal.mixin({
                            customClass: {
                                confirmButton: 'btn custom-button-2',

                            },
                            buttonsStyling: false
                            })


                            confirmBox2.fire({
                            icon: 'success',
                            title: 'Money deposited!',

                            }).then((res)=>{
                                // redirect user to dashboard
                            //window.location.href = '/broker-cash-distributors';
                            location.reload();

                            })



                        })
                        .catch((err) => {
                        this.$isLoading(false)

                        let errorText = 'Deposit Unsuccessful'

                        if(err.response.data.errors['pin']){
                            errorText = err.response.data.errors['pin']
                        }
                        else if(err.response.data.errors['general']){
                            errorText = err.response.data.errors['general']
                        }


                            const confirmBox2 = Swal.mixin({
                            customClass: {
                                confirmButton: 'btn custom-button bg-danger',

                            },
                            buttonsStyling: true
                            })

                            confirmBox2.fire({
                            icon: 'error',
                            title: 'Failed!',
                            text: errorText,

                            })

                        });


                }
                })





            }
            })
        },


      },

    }
    </script>
