<template>
  <div
    v-if="
      $gate.isRegistrationComplete() &&
      ($gate.isCustomer() || $gate.isMerchantAdmin())
    "
  >
    <div class="card w-100 custom-card-1 m-0 bg-white">
      <fieldset>
        <div class="card-body">
          <form @submit.prevent="withdraw" method="post">
            <div class="row px-md-5">
              <div class="col-12 pt d-flex justify-content-center">
                <h2 class="swal2-title" id="exampleModalLongTitle">
                  Send Withdrawal Request
                </h2>
              </div>

              <div class="col-12 card-header"></div>

              <div class="col-12 pt-4">
                <div class="form-group mb-0">
                  <label for="amount" class="text-md">
                    How much?
                    <span v-if="this.type == 1 && this.campaign_amount > 0"
                      ><i class="text-danger"
                        >( Your total campaign amount is
                        {{ dalasisFormatter(this.campaign_amount) }} )</i
                      ></span
                    ></label
                  >

                  <div class="input-group mb-2">
                    <div class="input-group-prepend">
                      <span
                        class="input-group-text font-weight-bold text-navy bg-olive"
                        >D</span
                      >
                    </div>
                    <input
                      type="number"
                      required
                      id="amount5"
                      v-model="amount"
                      placeholder="Enter amount in dalasis"
                      class="form-control"
                    />
                  </div>
                </div>

                <div class="form-group py-0 my-0">
                  <label v-if="amount_error.length > 0" class="text-danger">
                    {{ amount_error }}
                  </label>
                  <label
                    v-else-if="amount_fee.length > 0"
                    for=""
                    class="text-success"
                  >
                    {{ amount_fee }}
                  </label>
                </div>
              </div>
            </div>
            <div
              v-if="!loading"
              class="modal-footer mt-3 border-0 d-flex justify-content-center"
            >
              <button
                :disabled="!noErrors"
                type="submit"
                class="btn custom-button-2"
              >
                Send
              </button>
            </div>
          </form>
        </div>
      </fieldset>
    </div>

    <Footer :getUserProfile="getUserProfile" />
    <Send :getUserProfile="getUserProfile" />
  </div>
  <div v-else>
    <not-found></not-found>
  </div>
</template>



<script>
export default {
  name: "Withdraw",
  components: {
    Select2: () =>
      import("v-select2-component" /* webpackChunkName: "js/select2" */),
  },
  data() {
    return {
      type: this.$route.params.type,
      campaign_amount_withdrawal: 0,
      campaign_amount_send: 0,
      campaign_amount_both: 0,
      campaign_amount: 0,
      balance: 0,
      withdraw_town: "",
      form: new Form({
        town: "",
        amount: 0,
      }),
      amount: "",
      feeTable: [],
      customerExists: true,
      transaction_types: [],
      transaction_id: [],
      loading: false,
      amount_error: "",
      amount_fee: "",
      fee: 0,
      company_type: "",
    };
  },
  created() {
    this.$isLoading(true);

    axios
      .get("/api/withdraw-feetable")
      .then(({ data }) => {
        this.feeTable = data;
        console.log(this.feeTable);
        this.$isLoading(false);
      })
      .catch((err) => {
        this.$isLoading(false);
      });
  },
  watch: {
    getUserProfile: function () {
      this.balance = this.dalasisFormatter(this.getUserProfile.balance);
      console.log(this.getUserProfile);
      this.campaign_amount_withdrawal =
        this.getUserProfile.campaign_amount_withdrawal;
      this.campaign_amount_send = this.getUserProfile.campaign_amount_send;
      this.campaign_amount_both = this.getUserProfile.campaign_amount_both;
      this.campaign_amount =
        this.campaign_amount_withdrawal + this.campaign_amount_both;
      document.getElementById("bladeAmount").innerHTML = this.balance;
    },
    amount: function () {
      // this.noerrors.amount = false
      this.amount_fee = "";
      this.amount_error = "";

      if (this.amount.length >= 1) {
        if (
          this.amount >= this.feeTable[0].range_from &&
          this.amount <= this.feeTable[this.feeTable.length - 1].range_to
        ) {
          // get the fees from the fee table
          this.amount_fee = "";

          this.feeTable.forEach((element) => {
            if (
              this.amount >= element.range_from &&
              this.amount <= element.range_to
            ) {
              this.amount_fee = "The transaction fee is D" + element.fee;
              this.fee = element.fee;
            } else if (this.amount > this.getUserProfile.balance) {
              this.amount_fee = "";
              this.fee = 0;
              this.amount_error =
                "You can't withdraw more than your current balance";
            }
          });
        } else if (this.amount > this.getUserProfile.balance) {
          this.amount_error =
            "You can't withdraw more than your current balance";
        } else if (this.amount < this.feeTable[0].range_from) {
          this.amount_error =
            "You can't withdraw an amount less than D" +
            this.feeTable[0].range_from;
        } else if (
          this.amount > this.feeTable[this.feeTable.length - 1].range_to
        ) {
          this.amount_error =
            "You can't withdraw an amount greater than D" +
            this.feeTable[this.feeTable.length - 1].range_to;
        } else {
          this.amount_error = "";
        }

        // }
      } else {
        this.amount_error = "";
      }
    },
  },

  mounted() {
    this.$store.dispatch("userProfile");
  },
  computed: {
    getUserProfile() {
      //final output from
      return this.$store.getters.getUserProfile;
    },
    noErrors() {
      return this.eChecks();
    },
  },
  methods: {
    companyType(companyType) {
      this.company_type = companyType;
    },
    withdraw() {
      const confirmBox1 = Swal.mixin({
        customClass: {
          cancelButton: "btn custom-button bg-danger mx-2",
          confirmButton: "btn custom-button-2 mx-2",
        },
        buttonsStyling: false,
      });

      confirmBox1
        .fire({
          title: "Are you sure?",
          html:
            "You will be sending a withdraw request for " +
            this.dalasisFormatter(this.amount) +
            "</b>. You'll cash out with <b>" +
            this.dalasisFormatter(this.amount - this.fee) +
            "</b>",

          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, withdraw it!",
        })
        .then((result1) => {
          if (result1.isConfirmed) {
            // prompt for pin
            const box1 = Swal.mixin({
              customClass: {
                cancelButton: "btn custom-button bg-danger  mx-2",
                confirmButton: "btn custom-button-2 mx-2",
              },
              buttonsStyling: false,
            });

            box1
              .fire({
                title: "Enter your pin",
                text:
                  "To confirm that you want to withdraw " +
                  this.dalasisFormatter(this.amount) +
                  " from  ",
                input: "password",
                inputAttributes: {
                  required: true,
                },
                inputValidator: (value) => {
                  if (!value) {
                    return "You need to enter your pin!";
                  }
                },
                showCancelButton: true,
                confirmButtonText: "Submit",
                showLoaderOnConfirm: true,
              })
              .then((result) => {
                if (result.isConfirmed) {
                  this.$isLoading(true);

                  const formData = new FormData();

                  formData.append("pin", result.value);
                  //   formData.append("unique_id", company["unique_id"]);
                  formData.append("amount", this.amount);
                  formData.append("type", this.type);
                  formData.append("company_type", this.company_type);
                  // want to add the company_type

                  axios
                    .post("/api/withdraw", formData, {
                      headers: { "Content-Type": "application/json" },
                    })
                    .then((data) => {
                      console.log(data);

                      this.transaction_id = data.data.id;

                      this.$isLoading(false);

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button-2",
                        },
                        buttonsStyling: false,
                      });

                      confirmBox2
                        .fire({
                          icon: "success",
                          title: "Withdrawal Request Sent!",
                          html:
                            "<span>Use your token at any agency to withdraw your money</span>" +
                            "<br><h3>Token: <b>" +
                            data.data.token +
                            "</b></h3>",
                        })
                        .then((res) => {
                          // ask them to pick the transaction type. Give em option to exit/not choose.

                          window.location.href = "/my-withdrawals";
                        });
                    })
                    .catch((err) => {
                      console.log("error", err);
                      this.$isLoading(false);

                      // show them if that city name is taken
                      let errorText = "Transaction Unsuccessful";

                      if (err.response.data.errors["pin"]) {
                        errorText = err.response.data.errors["pin"];
                      } else if (err.response.data.errors["amount"]) {
                        errorText = err.response.data.errors["amount"];
                      } else if (err.response.data.errors["general"]) {
                        errorText = err.response.data.errors["general"];
                      }

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button bg-danger",
                        },
                        buttonsStyling: true,
                      });

                      confirmBox2.fire({
                        icon: "error",
                        title: "Failed!",
                        text: errorText,
                      });
                    });
                }
              });
          }
        });
    },
    eChecks() {
      if (
        this.amount <= this.getUserProfile.balance &&
        this.amount > 0 &&
        this.amount_error == ""
      )
        return true;

      return false;
    },
  },
};
</script>

<style scoped>
.transaction_type a:hover {
  background: aliceblue;
}

/* .withdraw > span{
    width: 100% !important;
} */

.select2-container {
  width: 100% !important;
}
</style>
