var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card custom-card-1 m-0"},[_c('fieldset',[_c('legend',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('span',[_vm._v("Reconciliation Report Data for \""+_vm._s(this.$route.query.transaction_type)+"\" transactions")])]),_vm._v(" "),_c('button',{staticClass:"btn btn-success",on:{"click":function($event){return _vm.exportReport()}}},[_c('i',{staticClass:"fas fa-file-export"}),_vm._v(" Export Excel\n        ")])]),_vm._v(" "),(
          _vm.transactions.length != 0 && _vm.resultType == 'completedTransactions'
        )?_c('div',{staticClass:"card-body table-responsive"},[_c('table',{staticClass:"table table-bordered table-hover border-top",attrs:{"id":"example2"}},[_vm._m(0),_vm._v(" "),_c('tbody',_vm._l((_vm.transactions),function(row,index){return _c('tr',{key:index,staticClass:"text-center"},[_c('td',[_c('span',[_vm._v("\n                  "+_vm._s(row.agency_from)+" -\n                  "+_vm._s(row.agency_from_branch)+"\n                ")]),_vm._v(" "),_c('br'),_vm._v(" "),_c('small',[_vm._v("from: "+_vm._s(row.agent_from_fullname))])]),_vm._v(" "),_c('td',[_c('span',[_vm._v("\n                  "+_vm._s(row.agency_to)+" -\n                  "+_vm._s(row.agency_to_branch)+"\n                ")]),_vm._v(" "),_c('br'),_vm._v(" "),_c('small',[_vm._v("to: "+_vm._s(row.agent_to_fullname))])]),_vm._v(" "),_c('td',[_vm._v("\n                "+_vm._s(_vm.dalasisFormatter(row.amount))+"\n              ")]),_vm._v(" "),_c('td',[_vm._v("\n                "+_vm._s(_vm.dalasisFormatter(row.fee_paid))+"\n              ")]),_vm._v(" "),_c('td',[_vm._v(_vm._s(row.sender_name))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(row.receiver_name))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(row.time_sent))])])}),0)]),_vm._v(" "),_c('div',{staticClass:"pt-3"},[_c('pagination',{attrs:{"align":"center","data":_vm.pageData},on:{"pagination-change-page":_vm.getResults}})],1)]):(
          _vm.transactions.length != 0 && _vm.resultType == 'completedDeposits'
        )?_c('div',{staticClass:"card-body table-responsive"},[_c('table',{staticClass:"table table-bordered table-hover border-top",attrs:{"id":"example2"}},[_vm._m(1),_vm._v(" "),_c('tbody',_vm._l((_vm.transactions),function(row,index){return _c('tr',{key:index,staticClass:"text-center"},[_c('td',[_vm._v(_vm._s(row.agency_from_branch))]),_vm._v(" "),_c('td',[(row.user_type === 'Customer')?_c('router-link',{attrs:{"to":'/view-user-profile/' + row.customer_id}},[_vm._v("\n                  "+_vm._s(row.customer_fullname)+" "),_c('br'),_vm._v(" "),_c('span',[_vm._v(" username: "+_vm._s(row.customer_username)+" ")])]):_vm._e(),_vm._v(" "),(row.user_type === 'Merchant')?_c('router-link',{attrs:{"to":'/view-merchant-profile/' + row.company_id}},[_vm._v("\n                  "+_vm._s(row.merchant_store_name)+" "),_c('br')]):_vm._e()],1),_vm._v(" "),_c('td',[_vm._v("\n                "+_vm._s(_vm.dalasisFormatter(row.amount))+"\n              ")]),_vm._v(" "),_c('td',[_vm._v("\n                "+_vm._s(_vm.dalasisFormatter(row.fee_paid))+"\n              ")]),_vm._v(" "),_c('td',[_vm._v(_vm._s(row.time_sent))])])}),0)]),_vm._v(" "),_c('div',{staticClass:"pt-3"},[_c('pagination',{attrs:{"align":"center","data":_vm.pageData},on:{"pagination-change-page":_vm.getResults}})],1)]):(
          _vm.transactions.length != 0 && _vm.resultType == 'completedWithdrawals'
        )?_c('div',{staticClass:"card-body table-responsive"},[_c('table',{staticClass:"table table-bordered table-hover border-top",attrs:{"id":"example2"}},[_vm._m(2),_vm._v(" "),_c('tbody',_vm._l((_vm.transactions),function(row,index){return _c('tr',{key:index,staticClass:"text-center"},[_c('td',[_vm._v(_vm._s(row.agency_from_branch))]),_vm._v(" "),_c('td',[(row.user_type === 'Customer')?_c('router-link',{attrs:{"to":'/view-user-profile/' + row.customer_id}},[_vm._v("\n                  "+_vm._s(row.customer_fullname)+" "),_c('br'),_vm._v(" "),_c('span',[_vm._v(" username: "+_vm._s(row.customer_username)+" ")])]):_vm._e(),_vm._v(" "),(row.user_type === 'Merchant')?_c('router-link',{attrs:{"to":'/view-merchant-profile/' + row.company_id}},[_vm._v("\n                  "+_vm._s(row.merchant_store_name)+" "),_c('br')]):_vm._e()],1),_vm._v(" "),_c('td',[_vm._v("\n                "+_vm._s(_vm.dalasisFormatter(row.amount - row.fee_paid))+"\n              ")]),_vm._v(" "),_c('td',[_vm._v("\n                "+_vm._s(_vm.dalasisFormatter(row.fee_paid))+"\n              ")]),_vm._v(" "),_c('td',[_vm._v(_vm._s(row.time_sent))])])}),0)]),_vm._v(" "),_c('div',{staticClass:"pt-3"},[_c('pagination',{attrs:{"align":"center","data":_vm.pageData},on:{"pagination-change-page":_vm.getResults}})],1)]):_c('div',{staticClass:"text-center"},[_c('span',[_c('i',[_vm._v(_vm._s(_vm.emptyDataText))])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',{staticClass:"text-center"},[_c('th',[_vm._v("Agency from")]),_vm._v(" "),_c('th',[_vm._v("Agency to")]),_vm._v(" "),_c('th',[_vm._v("Amount")]),_vm._v(" "),_c('th',[_vm._v("Fee Paid")]),_vm._v(" "),_c('th',[_vm._v("Sender")]),_vm._v(" "),_c('th',[_vm._v("Receiver")]),_vm._v(" "),_c('th',[_vm._v("Time Sent")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',{staticClass:"text-center"},[_c('th',[_vm._v("Branch From")]),_vm._v(" "),_c('th',[_vm._v("Customer / Merchant")]),_vm._v(" "),_c('th',[_vm._v("Amount")]),_vm._v(" "),_c('th',[_vm._v("Fee Paid")]),_vm._v(" "),_c('th',[_vm._v("Time Sent")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',{staticClass:"text-center"},[_c('th',[_vm._v("Branch From")]),_vm._v(" "),_c('th',[_vm._v("Customer / Merchant")]),_vm._v(" "),_c('th',[_vm._v("Amount")]),_vm._v(" "),_c('th',[_vm._v("Fee Paid")]),_vm._v(" "),_c('th',[_vm._v("Time Sent")])])])
}]

export { render, staticRenderFns }