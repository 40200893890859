<template>
    <div>
        <div v-if="$gate.isBroker()" class="card  custom-card-1 m-0">
          <form class="form-horizontal" action="" @submit.prevent='createAgency' method="post">

            <fieldset>
                <legend class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center">
                     <span>Create Agency</span>

                    </div>
                   <a href="#" class="nav-link" active-class="active" onclick="history.go(-1);return false;">
                       <button class="btn bg-nyanza font-weight-bold text-gray" >Go back</button>
                    </a>
                </legend>
                    <div class="card-header">
                    </div>


                    <div class="card-body">

                        <div class="row">
                            <div class="col-12">
                                    <div class="text-danger" v-if="form.errors.has('company_name')" v-html="form.errors.get('company_name')" />
                            </div>
                            <div class="col-12 border-bottom">
                                <div class="form-group pb-4">

                                    <label for="">Company Name <span class="text-danger">*</span>
                                    </label>
                                    <input type="text" v-model="form.company_name"  name="company_name"
                                     maxlength="70" placeholder="Enter Company Name" class="form-control"><br>


                                </div>

                            </div>
                            <div class="col-12">
                                    <div class="pt-3  text-danger" v-if="form.errors.has('broker_balance')" v-html="form.errors.get('broker_balance')" />
                            </div>

                            <div class="col-6 border-bottom">
                                <div class="form-group pt-3 pb-4">

                                    <label for="">Broker Balance  <span class="text-danger">*</span>
                                    </label>
                                    <input type="number" v-model="form.broker_balance"
                                      placeholder="Enter initial broker balance" class="form-control"><br>


                                </div>

                            </div>
                            <div class="col-6 border-bottom"></div>

                            <div class="col-6">
                                    <div class="pt-3  text-danger" v-if="form.errors.has('fee_cut_percent')" v-html="form.errors.get('fee_cut_percent')" />
                            </div>
                            <div class="col-6">
                                    <div class="pt-3  text-danger" v-if="form.errors.has('withdraw_fee_cut_percent')" v-html="form.errors.get('withdraw_fee_cut_percent')" />
                            </div>
                            <div class="col-6 border-bottom">
                                <div class="form-group pt-3 pb-4">

                                    <label for="">Transaction fee cut percent <span class="text-danger">*</span>
                                    </label>
                                    <input type="number" v-model="form.fee_cut_percent"
                                      placeholder="Enter transaction fee cut percent" class="form-control"><br>


                                </div>

                            </div>
                            <div class="col-6 border-bottom">
                                <div class="form-group pt-3 pb-4">

                                    <label for="">Withdraw fee cut percent <span class="text-danger">*</span>
                                    </label>
                                    <input type="number" v-model="form.withdraw_fee_cut_percent"
                                      placeholder="Enter withdraw fee cut percent" class="form-control"><br>


                                </div>

                            </div>
                            <div class="col-12">
                                <div class="form-group pt-2 float-right">

                                <button type="button" class="btn btn-outline-success" @click="addBranch">
                                <i class="fa fa-plus"></i> Add Branch
                                </button>

                                </div>
                            </div>
                            <div class="col-12">
                                    <div class="text-danger" v-if="form.errors.has('branches')" v-html="form.errors.get('branches')" />
                            </div>
                            <div v-for='row in form.branches' :key="row.id" class="col-12 pb-5">
                                <div class="row pb-3 mb-2 border-bottom">
                                    <div class="col-6">

                                        <div class="input-group mb-3">
                                            <div class="input-group-prepend">
                                                <button type="button" class="btn ">Branch</button>
                                            </div>
                                        <!-- /btn-group -->
                                            <Select2 class="form-control border-0"  v-model="row.address" :options="locations.towns" />

                                        </div>

                                    </div>
                                    <div class="col-6 d-flex justify-content-end align-items-start">
                                        <button v-if="form.branches.length > 1" type="button" class="btn btn-danger" style="float:left" @click="deleteBranch(row.id)">Remove</button>
                                    </div>

                                    <div class="col-12 pb-2 pt-3 pl-5">

                                        <button  @click="addPhoneNumber(row.id)"  type="button" class="float-right btn btn-sm btn-outline-secondary" >
                                        <i class="fa fa-plus"></i> Phone Numbers
                                        </button>
                                    </div>

                                    <div v-for="ph in row.phone_numbers" :key="ph.id" class="col-6 pl-5 d-flex justify-content-end">
                                        <div class="input-group ml-5 mb-3">
                                            <div class="input-group-prepend">
                                                <span  class="input-group-text"><i class="fas fa-phone-alt"></i></span>
                                            </div>
                                            <input type="number"  v-model="ph.number" class="form-control">
                                            <div class="input-group-append">
                                                <button v-if="row.phone_numbers.length > 1" type="button"
                                                    @click="deletePhoneNumber(row.id, ph.id)"
                                                     class="input-group-text border-0 text-danger bg-white">
                                                    <i class="fa fa-times"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                            </div>


                            <div class="col-12 text-center">
                                <div class="form-group pb-4">

                                    <label for="">Avatar
                                    </label>
                                    <div class="image">
                                        <img class="img-fluid pad"
                                         style="border: 1px dashed #ccc;"
                                         src="/images/profiles/building-none.png" alt="">
                                    </div>

                                    <div class=""><button type="button" class="btn border">Change Avatar</button></div>
                                </div>

                            </div>

                            <div class="col-12 mt-3 d-flex justify-content-center">
                                <button type="submit" :disabled="form.busy" class="btn custom-button-1">
                                    SUBMIT
                                </button>
                            </div>


                        </div>

                    </div>




            </fieldset>

          </form>







        </div>

        <div v-else>
            <not-found></not-found>

        </div>
    </div>
</template>


<script>
import Select2 from 'v-select2-component';


export default {
    name: 'CreateAgency',
    props:{

    },
  components: {
    Select2
  },
  data() {
    return {
        form: new Form({

            company_name: '',
            picture: 'building-none.png',
            fee_cut_percent: '',
            withdraw_fee_cut_percent: '',
            // broker_balance: 0,
            logo: '',
            tin_no: '',
            branches: [
                {
                id: 1,
                address: "",
                phone_numbers: [
                    {
                        id: 1,
                        branch_id: 1,
                        number: '',
                    }
                ],
                }


            ],
        }),

        b_index: 1,
        pn_index: 1,


        locations: []
    }
  },
  created(){

  },
  mounted() {
        this.$store.dispatch("locations")


  },
    computed: {

        getLocations(){ //final output from here
            return this.$store.getters.getLocations
        },

    },
    watch:{
            getLocations: function () {
                this.locations = this.getLocations

            },

    },
  methods:{

      createAgency(){
        if(this.$gate.isBroker()){
            const box1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button-2 mr-2',
                denyButton: 'btn custom-button bg-secondary ml-2'
            },
            buttonsStyling: false
            })

            box1.fire({
                title: 'Enter your password',
                text: 'To confirm you want to add the company "' + this.form.company_name + '" to the system',
                input: 'password',
                inputAttributes: {
                    required: true,
                },
                inputValidator: (value) => {
                    if (!value) {
                    return 'You need to enter your password!'
                    }

                },
                showDenyButton: true,
                denyButtonText: `Back`,
                confirmButtonText: 'Submit',
                }).then((result) => {
                if (result.isConfirmed) {

                this.form.password = result.value

                  this.$isLoading(true)

            this.form.post('api/broker/createAgencyOrFI/agency',{headers:{"Content-Type":"application/json"}})
                .then((data) => {
                    console.log(data)
                  this.$isLoading(false)

                    const confirmBox2 = Swal.mixin({
                    customClass: {
                        confirmButton: 'btn custom-button-2',

                    },
                    buttonsStyling: false
                    })


                    confirmBox2.fire({
                    icon: 'success',
                    title: 'Registered as Agency!',
                    text: 'Agency registration successful'

                    }).then((res)=>{
                        // redirect user to dashboard

                    window.location.href = '/agencies';

                    })



                })
                .catch((err) => {

                        this.$isLoading(false)

                        // show them if that city name is taken
                        let errorText = 'Agency Registration Unsuccessful'

                        if(err.response.data.errors['password']){
                            errorText = err.response.data.errors['password']
                        }
                        else if(err.response.data.errors['general']){
                            errorText = err.response.data.errors['general']
                        }


                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button bg-danger',

                        },
                        buttonsStyling: true
                        })

                        confirmBox2.fire({
                        icon: 'error',
                        title: 'Failed!',
                        text: errorText,

                        })
                });


                }
                })











        }

      },

    addBranch() {

      this.form.branches.push({
            id: ++this.b_index,
            address: "",
            phone_numbers: [
                {
                    id: 1,
                    branch_id: this.b_index,
                    number: '',
                }
            ],

      });
    },
    addPhoneNumber(b_id){

    let i = this.form.branches.findIndex((b) => b.id == b_id);
    this.form.branches[i].phone_numbers.push(
    {
        id: ++this.pn_index,
        branch_id: b_id,
        number: '',
    }
    )


    },
    deleteBranch(index) {
        this.form.branches = [... this.form.branches.filter((b) => b.id != index)]
    },
    deletePhoneNumber(b_id, pn_id){
        let i = this.form.branches.findIndex((b) => b.id == b_id);
        this.form.branches[i].phone_numbers = [... this.form.branches[i].phone_numbers.filter((pn) => pn.id != pn_id)]
    }

  },

}
</script>
