<template>
    <div>
        <div v-if="$gate.isBroker() || $gate.isCashcollector()" class="card  p-5 m-0">
            <fieldset>
                <legend class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center">
                     <span> {{campaignInfo.campaign_name}}</span>

                    </div>
                   <a href="#" class="nav-link" active-class="active" onclick="history.go(-1);return false;">
                       <button class="btn bg-nyanza font-weight-bold text-gray" >Go back</button>
                    </a>
                </legend>
                    <div class="card-header">
                    </div>


                <div>

                    <TabNav :tabs="tabs" :selected="selected" @selected="setSelected">

                        <Tab :isSelected="selected === 'Registered Users' ">
                            <div v-if="$gate.isBroker() || ($gate.canTransact() && $gate.isRegistrationComplete())" class="mt-5">

                                <RegisteredUsers :campaign_id='campaignInfo.Cid' /> 
                            </div>
                            
                        </Tab>
                        <Tab :isSelected="selected === 'Non Registered Users' ">
                            <div v-if="$gate.isBroker() || ($gate.canTransact() && $gate.isRegistrationComplete())" class="mt-5">

                                <Nonregisteredusers :campaign_id='campaignInfo.Cid' /> 
                           </div>
                            
                        </Tab>
                    </TabNav>

                    

                

                </div>



            </fieldset>

</div>
        <div v-else>
            <not-found></not-found>

        </div>

    </div>
</template>

<script>


export default {
    name: 'ViewCampaignUsers',
    props:{

    },
    components: {
        TabNav: () => import('../includes/tabNav' /* webpackChunkName: "js/tab-nav" */),
        Tab: () => import('../includes/tabs' /* webpackChunkName: "js/tabs" */),
        RegisteredUsers: () => import('./users/RegisteredUsers'),
        Nonregisteredusers: () => import('./users/Nonregisteredusers'),
    },
  data() {
    return {

        selected: 'Registered Users',
        logs: [],
       campaignInfo: {},
        tabs: [],
        pageData: {},
        edit_campaign_form: new Form({
            camp_id: 0,
            campaign_name: '',
            campaign_fee: '',
            campaign_end_date: '',
            recurring_month:'',


        }),


    }
  },

  created(){
      if(this.$gate.isBroker() || this.$gate.isCashcollector()){
        this.tabs =  ['Registered Users','Non Registered Users']
        this.$isLoading(true)

        let temp_form = new Form({
            id : this.$route.params.id
        })

        temp_form.post('/api/collector/viewCampaign')
            .then(({data}) => {
                console.log(data);

                this.campaignInfo = data
                this.edit_campaign_form = new Form({
                    camp_id: data.Cid,
                    campaign_name: data.campaign_name,
                    campaign_fee: data.campaign_fee,
                    campaign_end_date: data.campaign_end_date,
                    recurring_month: data.recurring_month,
                    password: ''})

            this.$isLoading(false)

            }).catch((err)=>{
                this.campaignInfo = 'error'
            this.$isLoading(false)

        });



      }
  },
    watch: {

        selected: function(){

            if(this.selected == 'Logs')
                this.getLogs();


        },

    },
  methods:{
    setSelected(tab){
            this.selected = tab;

        },


},

}
</script>
