<template>
    <div v-if="$gate.isBroker()">



        <div class="card  custom-card-1 m-0">
          <form class="form-horizontal" action="" @submit.prevent='createInstitute' method="post">

            <fieldset>
                <legend class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center">
                     <span>Create Institute</span>

                    </div>

                     <a @click="$router.go(-1)" class="nav-link" active-class="active"><button class="btn bg-nyanza font-weight-bold text-gray">Go back</button></a>

                </legend>
                    <div class="card-header">
                    </div>


                    <div class="card-body">

                        <div class="row">
                            <!-- errors -->
                            <div class="col-6">
                                <div class="text-danger" v-if="form.errors.has('name')" v-html="form.errors.get('name')" />
                            </div>
                            <div class="col-6">
                                
                            </div>
                            

                            <!-- enderrors -->

                            <div class="col-6">
                                <div class="form-group pb-4">
                                    <label for="">Institute Name <span class="text-danger">*</span> </label>

                                    <input type="text" v-model="form.name" required  placeholder="Enter Institute Name" class="form-control"><br>


                                </div>

                            </div>
                           




                            <div class="col-12 mt-3 d-flex justify-content-center">
                                <button type="submit" :disabled="form.busy" class="btn custom-button-1">
                                    SUBMIT
                                </button>
                            </div>


                        </div>

                    </div>




            </fieldset>

          </form>







        </div>

    </div>
    <div v-else>
        <not-found></not-found>
    </div>
</template>


<script>



export default {
    name: 'createInstitute',
    props:{

    },
  components: {

  },
  data() {
    return {
        form: new Form({
            name: '',
            
         }),

    }
  },
  created(){
   


  },
  mounted() {



  },
    computed: {


    },
    watch:{


    },
  methods:{

      createInstitute(){

        if(!this.$gate.isBroker())
            return;

            const box1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button-2 mr-2',
                denyButton: 'btn custom-button bg-secondary ml-2'
            },
            buttonsStyling: false
            })

            box1.fire({
                title: 'Enter your password',
                text: 'To confirm that you want to create this Institute',
                input: 'password',
                inputAttributes: {
                    required: true,

                },
                inputValidator: (value) => {
                    if (!value) {
                    return 'You need to enter your password!'
                    }

                },
                showDenyButton: true,
                denyButtonText: `Back`,
                confirmButtonText: 'Submit',
                }).then((result) => {
                if (result.isConfirmed) {

                this.form.admin_password = result.value


                  this.$isLoading(true)

                    this.form.post('/api/broker/createInstitute',{headers:{"Content-Type":"application/json"}})
                        .then((data) => {
                        this.$isLoading(false)

                            const confirmBox2 = Swal.mixin({
                            customClass: {
                                confirmButton: 'btn custom-button-2',

                            },
                            buttonsStyling: false
                            })


                            confirmBox2.fire({
                            icon: 'success',
                            title: 'Institute Creation!',
                            text: 'Institute registration successful!'

                            }).then((res)=>{
                                // redirect user to dashboard
                            //window.location.href = '/broker-cash-distributors';
                            location.reload();

                            })



                        })
                        .catch((err) => {
                        this.$isLoading(false)

                        let errorText = 'Institute Creation Unsuccessful'

                        if(err.response.data.errors['mypassword']){
                            errorText = err.response.data.errors['mypassword']
                        }
                        else if(err.response.data.errors['general']){
                            errorText = err.response.data.errors['general']
                        }


                            const confirmBox2 = Swal.mixin({
                            customClass: {
                                confirmButton: 'btn custom-button bg-danger',

                            },
                            buttonsStyling: true
                            })

                            confirmBox2.fire({
                            icon: 'error',
                            title: 'Failed!',
                            text: errorText,

                            })

                        });


                }
                })



      }

  },

}
</script>
