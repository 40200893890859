<template>
    <div>
        <div v-if="$gate.isBroker()" class="card  custom-card-1 m-0">
          <form class="form-horizontal" action="" @submit.prevent='createAgency' method="post">

            <fieldset>
                <legend class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center">
                     <span>Create Transaction Default</span>

                    </div>
                   <a href="#" class="nav-link" active-class="active" onclick="history.go(-1);return false;">
                       <button class="btn bg-nyanza font-weight-bold text-gray" >Go back</button>
                    </a>
                </legend>
                    <div class="card-header">
                    </div>


                    <div class="card-body">
                      <form @submit.prevent='createType'  method="post" enctype="multipart/form-data">


                      <div class="row">
                        <div class="col-12 text-center pt-3 pt-lg-3 col-lg-4 px-5 px-lg-0 px-2">


                                    <div class="image-container mx-auto">
                                        <div class="image-wrapper">
                                          <div id='image' class="modal-image">
                                              <img src='' alt="">
                                          </div>


                                            <div id="cancel-btn"><i class="fas fa-times"></i></div>

                                        </div>

                                    </div>

                                    <div class="text-center">
                                        <label for="file" class='btn btn-default'> Choose an image</label>

                                    </div>
                                    <input id="file" name='picture' type="file" @change='showSelected' hidden>

                              
                        </div>
                        

                        <div class="col-12 col-lg-8">
                          <div class="form-group pl-lg-2 pt-lg-3">
                            <label for="">Enter Transaction Type</label>
                            <input type="text" v-model="type" class="form-control" placeholder="Enter Transaction Type Name">
                          </div>
                        </div>

                        <div class="col-12 mt-3 d-flex justify-content-center">
                            <button type="submit" :disabled="type.length == 0" class="btn custom-button-1">
                                SUBMIT
                            </button>
                        </div>
                      </div>

                      
                      </form>


                    </div>




            </fieldset>

          </form>






            
        </div>

        <div v-else>
            <not-found></not-found>
            
        </div>
    </div>
</template>


<script>
import Select2 from 'v-select2-component';


export default {
    name: 'CreateTransactionType',
    props:{
       
    },
  components: {
    Select2
  },
  data() {
    return {
      image: null,
      image_name: null,
      type:'',

    }
  },
  created(){

  },  
  mounted() {


  },
    computed: {


    },
    watch:{

    },
  methods:{
      showSelected(event){
          var input = document.getElementById('file');

          this.image = event.target.files[0];
          // console.log(this.picture_form.image)
          this.image_name = input.files.item(0).name

          var upload_div = document.getElementById('image');

          upload_div.innerHTML = '<img style="width:100%;height:100%;object-fit:cover;" src=' + URL.createObjectURL(input.files[0]) + ' alt=' + input.files.item(0).name  +' >';

      },

      createType(){



            const confirmBox1 = Swal.mixin({
            customClass: {
                cancelButton: 'btn custom-button bg-danger mr-2',
                confirmButton: 'btn custom-button-2 ml-2'
            },
            buttonsStyling: false
            })

            confirmBox1.fire({
            title: 'Are you sure?',
            html: 'You will creating a <b>' + this.type + "</b> Transaction Type <b>",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, deposit it!'
            }).then((result1) => {
            if (result1.isConfirmed) {
            

                // prompt for pin
            const box1 = Swal.mixin({
            customClass: {
                cancelButton: 'btn custom-button bg-danger  mr-2',
                confirmButton: 'btn custom-button-2 ml-2'
            },
            buttonsStyling: false
            })

            box1.fire({
                title: 'Enter your password',
                text: 'To confirm that you want to create this Transaction Type',
                input: 'password',
                inputAttributes: {
                    required: true,
                },
                inputValidator: (value) => {
                    if (!value) {
                    return 'You need to enter your password!'
                    }

                },
                showCancelButton: true,
                confirmButtonText: 'Submit',
                showLoaderOnConfirm: true
                }).then((result) => {
                if (result.isConfirmed) {
                this.$isLoading(true) 

                const formData = new FormData();

                formData.append('type', this.type);
                formData.append('image', this.image);
                formData.append('image_name', this.image_name)
                formData.append('password', result.value)


                axios.post('api/transaction-type/createType',formData,{headers:{"Content-Type":"application/json"}})
                    .then((data) => {
                        this.$isLoading(false) 

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button-2',
                        
                        },
                        buttonsStyling: false
                        })

                        confirmBox2.fire({
                        icon: 'success',
                        title: 'Transaction Type Created!',
                        

                        }).then((res)=>{
                               this.$router.push('/transaction-types')

                       
                        })
                    })
                    .catch((err) => {
                        this.$isLoading(false) 


                        // show them if that city name is taken
                        let errorText = 'City Deletion Unsuccessful'

                        if(err.response.data.errors['password']){
                            errorText = err.response.data.errors['password']
                        }
                        else if(err.response.data.errors['general']){
                            errorText = err.response.data.errors['general']
                        }
                        else if(err.response.data.errors['image']){
                            errorText = err.response.data.errors['image']
                        }


                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button bg-danger',
                        
                        },
                        buttonsStyling: true
                        })

                        confirmBox2.fire({
                        icon: 'error',
                        title: 'Failed!',
                        text: errorText,

                        })

                    });
      
      

                }
                })

            }
            })












      
      },


  },

}
</script>