<template>
    <div v-if="$gate.isCustomer() || $gate.isStore()">
        <div>
            <legend class="d-flex justify-content-center flex-wrap">
                <span class='d-flex align-items-center'>{{form.column}} Pay Requests</span>
            </legend>
            <div class="d-flex justify-content-between align-items-center">
            <SearchFilter :data_length='1' :isFound='isFound'
                        :filters='filters' data_type="payments" @search-form='makeSearch'
                        />
            <!-- <div class="text-center"><i>work in progress</i> </div> -->
            <div v-show="requests.length > 0" class="d-flex justify-content-end mt-0 pt-0 mb-3">
                
                <button class="btn btn-sm btn-default"
                    data-toggle="modal" data-target="#filterByModal"
                    style="border-color: gray;">Filter</button>
            </div>
        </div>

            <!-- group by date -->
            <div v-for="(data, index1) in requests" :key="index1" class="pb-3">
                <div class="pb-2 font-weight-bold text-muted">
                    {{data[0].day_key}}
                </div>

                <div class="transactions">
                    <section v-for="(d, index2) in data" :key="index2" class="px-3 pb-4 mb-3">

                        <p class="py-1 text-secondary">
                            Request ID: <i class="font-weight-bold ">{{d['unique_id']}}</i>
                        </p>
                        

                        <div class="d-flex justify-content-between">
                            <div
                            :class="[d['status'] == 'Pending' ? 'text-info' :
                            d['status'] == 'Cancelled' ? 'text-danger' :
                            d['status'] == 'Unpaid' ? 'text-danger' :
                            d['status'] == 'Unpaid- Due to insufficient balance' ? 'text-danger' :
                            d['status'] == 'Pay-Auto' ? 'text-warning' :
                            d['status'] == 'Completed' ? 'text-bgreen' : '', 'font-weight-bold text-md d-flex align-items-center']"
                            >

                                <span v-if="d['status']=='Unpaid'">Unpaid- I do myself</span>
                                <span v-else>{{d['status']}}</span>

                            </div>
               
                        </div>
                        <div class="item pt-2">
                            <div class="detail">
                                <img  :src="'/images/profiles/' + d['picture'] "

                                alt="img" class="image-block imaged w48">
                                <div>

                                   <a href="#">
                                      <strong>{{d['institution_name']}} - {{d['campaign_name']}} Campaign</strong>

                                    </a>
                                    <p>{{d['time_updated']}}</p>
                                    <p v-if="d['request_expiry_date'] != null" class="text-danger">Expiry Date:- {{d['request_expiry_date']}}</p>
                                </div>
                            </div>
                            <div class="middle" v-if="d['status'] == 'Pending' || d['status'] == 'Pay-Auto' || d['status'] == 'Unpaid' || d['status'] == 'Unpaid- Due to insufficient balance'">
                                <button   class="btn btn-sm custom-button-2" @click="payNow(d['request_id'], d['show_fee'], d['amount'] , d['institution_name'])">Pay Now</button>
                                <button  v-if="d['request_status']== 2" class="btn btn-sm custom-button-red1" @click="downloadAgreement(d['request_id'])"><i class="fa fa-download"></i> Agreement</button>


                                <button v-else-if="d['request_status']== 0 && d['pay_auto']== 1"  class="btn btn-sm custom-button-red1" @click="payLater(d['request_id'], d['show_fee'], d['amount'], d['request_expiry_date'] , d['institution_name'])"  >Pay Later</button>
                                
                            </div>
                            <div class="right">
                                <div class="price font-weight-bold "
                                :class="[d['status'] == 'Completed'
                                ? 'text-emerald' : d['status'] != 'Cancelled' ? 'text-info' : 'text-red1']"
                                >
                                    {{dalasisFormatter(d['amount'])}} <br>
                                    <span class="text-red text-sm float-right" v-if="d['status'] != 'Cancelled'">
                                     Fee:    {{dalasisFormatter(d['show_fee'])}}
                                    </span><br/>
                                    <hr class="m-0 font-weight-bold" v-if="d['status'] != 'Cancelled'" />
                                    <span class="text-success font-weight-bold float-right" v-if="d['status'] != 'Cancelled'">
                                      {{dalasisFormatter(d['amount']+ d['show_fee'])}}
                                    </span><br/>
                                    <hr class="m-0 font-weight-bold"  v-if="d['status'] != 'Cancelled'"/>
                                  
                                    

                                 </div>
                            </div>
                        </div>

                    </section>

                </div>

            </div>

            <div class="card py-4 text-center"  v-if="requests.length == 0">
                <span>{{emptyDataText}}
                </span>
            </div>

                <div class="pt-3">
                    <pagination align="center" :data="pageData" @pagination-change-page="getResults"></pagination>

                </div>


            <div class="modal fade" id="filterByModal" tabindex="-1" role="dialog" aria-labelledby="filterByModalTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">


                            <div class="modal-header border-0">
                                <span class="swal2-title" >Filter By</span>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <h5 class="text-muted">Select Column</h5>
                                <div class="form-group ml-3">
                                    <div v-for="(column, index) in filter_column_names" :key='index' class="form-check">
                                        <input class="form-check-input" type="radio" v-model="filter_by.column" :value="column">
                                        <label class="form-check-label" for="exampleRadios1">
                                            {{column}}
                                        </label>
                                    </div>

                                </div>
                                <hr>
                                <h5 class="text-muted">Order By</h5>
                                <div class="form-group ml-3">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" v-model="filter_by.order" value="ascending">
                                        <label class="form-check-label" for="exampleRadios1">
                                            Ascending
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" v-model="filter_by.order" value="descending">
                                        <label class="form-check-label" for="exampleRadios1">
                                            Descending
                                        </label>
                                    </div>
                                </div>

                            </div>
                            <div class="modal-footer border-0 d-flex justify-content-center">
                                <button type="submit" @click="filterData" class="btn custom-button-2 ">Filter</button>
                            </div>

                    </div>
                </div>
            </div>







        </div>

            <Send :getUserProfile='getUserProfile' />
            <Footer :getUserProfile='getUserProfile'  />
    </div>
    <div v-else>
            <not-found></not-found>

    </div>
</template>


<script>

export default {
    name: 'Requests',
  components: {
    SearchFilter: () => import('../includes/SearchFilter.vue' /* webpackChunkName: "js/search-filter" */),
  },
  data() {
    return {

        fee: 0,
        balance: 0,
        pageData: {},
        requests: [],
        searchForm: null,
        isFound: false,
        form: new Form({
            column: 'All',
            order:'descending'
        }),
        emptyDataText: '',
        filter_by: {
            column: 'All',
            order: 'descending'
        },
        filter_column_names: ['All', 'Pending',
                 'Completed', 'Cancelled','Unpaid'],

       
        filters: [
            {
                'id': 1,
               'text': 'Collector Name',
               'value': 'collector' 
            },
            {
                'id': 2,
                'text': 'Campaign Name',
                'value': 'campaign'
            },
            {
                'id': 3,
                'text': 'Date',
                'value': 'date'
            },
             
            
        ]

    }
  },
  created(){
      if(this.$gate.isCustomer() || this.$gate.isStore())
        this.getResults()

     
        

  },
  watch:{
        getUserProfile: function () {
            if((this.getUserProfile.balance ==0 || this.getUserProfile.balance ==0.00) && this.getUserProfile.used_kashlock_credit > 0){
                this.getUserProfile.balance= -this.getUserProfile.used_kashlock_credit;
            }

          document.getElementById("bladeAmount").innerHTML =
            this.dalasisFormatter(this.getUserProfile.balance);
            document.getElementById('bladeCreditAmount').innerHTML = this.dalasisFormatter(this.getUserProfile.kashlock_credit)
            if(this.getUserProfile.kashlock_credit > 0){
                document.getElementById('bladeCreditBlock').style.display = 'block';
            }
           
            if(this.$gate.isStore())
                document.getElementById('bladeCompanyName').innerHTML = this.getUserProfile.institution_name



        },

  },

  mounted() {
        this.$store.dispatch("userProfile")


  },
    computed: {



        getUserProfile(){ //final output from here
            return this.$store.getters.getUserProfile
        },

    },
  methods: {
    makeSearch(data){
           
           this.searchForm = data
           this.getResults()
           // emit up
       },
 
      getResults(page){
        if (typeof page === 'undefined') {
            page = 1;
        }
        let api_route = ""
      
            api_route="api/customer/requests"
        

        this.emptyDataText = ''
        if(this.searchForm == null || (this.searchForm.search_text == '' && (this.searchForm.fromdate == null || this.searchForm.fromdate == ''))){
                this.$isLoading(true)

                this.form.post(api_route + '?page=' + page)
                    .then(({data}) => {

                    if(data.grouped_data != 0){
                    this.requests = data.grouped_data;
                    this.pageData = data.data
                    }else{
                    this.requests = [];
                    this.pageData = {}
                    this.emptyDataText = 'No requests record'
                    }

                        this.$isLoading(false)


                    }).catch((err)=>{
                        this.$isLoading(false)
                        this.emptyDataText = 'No requests record'


                    })
        }
        else{
           this.$isLoading(true)
            this.emptyDataText = ''

            this.searchForm.post(api_route +'/makeSearch?page=',{headers:{"Content-Type":"application/json"}})
                .then(({data}) => {
                    this.isFound = true

                  this.requests = data.grouped_data;
                  this.pageData = data.data
                    this.emptyDataText = 'No search results found...'

                    this.$isLoading(false)

                }).catch((err)=>{
                  this.requests = [];
                  this.pageData = {}
                 this.emptyDataText = 'Something went wrong!'

                    this.$isLoading(false)

                })

        }


      },

      payNow(id, fee, amount, institution_name){

        let verified_image ='<img src="/images/verified-badge.png" alt="image" style="width: 16px; height:16px;">'
            ;
        this.fee = fee;
        let new_balance =
            parseFloat(this.getUserProfile.balance) -
            parseFloat(amount); // this.customer_form.amount - $fee
        //   get the info from id
            const confirmBox1 = Swal.mixin({
            customClass: {
                cancelButton: 'btn custom-button-2 mx-2',
                confirmButton: 'btn custom-button bg-danger mx-2'
            },
            buttonsStyling: false
            })

            confirmBox1.fire({
            title: 'Are you sure?',
            html: "You will be transfering <b>" +
              this.dalasisFormatter(amount) +
              "</b> to </br> <b>" +
                institution_name +
              verified_image +
              "</b> <br> " +
              "The Fee is D" +
              this.fee.toFixed(2) +
              " Hence Your balance will be " +
              this.dalasisFormatter(new_balance - fee),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, send it!',
            showCancelButton: true,
            }).then((result1) => {
            if (result1.isConfirmed) {

                // prompt for pin
            const box1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button-2 mr-2',
                cancelButton: 'btn custom-button bg-danger ml-2'
            },
            buttonsStyling: false
            })

            box1.fire({
                title: 'Enter your pin',
                input: 'password',
                inputAttributes: {
                    maxlength: 4,
                    minlength: 4,
                    pattern: "[0-9]+",
                    required: true,
                },
                inputValidator: (value) => {
                    if (!value) {
                    return 'You need to enter your 4 digit pin!'
                    }

                },
                showCancelButton: true,
                confirmButtonText: 'Submit',
                showLoaderOnConfirm: true
                }).then((result) => {
                if (result.isConfirmed) {

                let formData = new Form({
                    request_id: id,
                    pin: result.value
                });

                    this.$isLoading(true)

                formData.post('api/request/payNow',{headers:{"Content-Type":"application/json"}})
                    .then((data) => {
                    this.$isLoading(false)

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button-2',

                        },
                        buttonsStyling: false
                        })

                        confirmBox2.fire({
                        icon: 'success',
                        title: 'Money sent!',
                        // text: 'Your new balance is ' + new_balance

                        }).then((res)=>{
                            // reloading the page
                        window.location.reload()

                        })



                    })
                    .catch((err) => {
                    this.$isLoading(false)


                        let errorText = 'Operation Unsuccessful'

                        if(err.response.data.errors['pin']){
                            errorText = err.response.data.errors['pin']
                        }
                        else if(err.response.data.errors['general']){
                            errorText = err.response.data.errors['general']
                        }

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button bg-danger',

                        },
                        buttonsStyling: true
                        })

                        confirmBox2.fire({
                        icon: 'error',
                        title: 'Failed!',
                        text: errorText,

                        })
                    });



                }
                })




            }
            })
      },

      payLater(id, fee, amount, request_expiry_date, institution_name){


        let verified_image ='<img src="/images/verified-badge.png" alt="image" style="width: 16px; height:16px;">'
            ;
        this.fee = fee;
        let new_balance =
            parseFloat(this.getUserProfile.balance) -
            parseFloat(amount);
        const confirmBox1 = Swal.mixin({
                customClass: {
                    confirmButton: "btn custom-button bg-danger mr-2",
                    cancelButton: "btn custom-button-2 ml-2",
                },
                buttonsStyling: false,
            });

            confirmBox1
                .fire({
                    title: "Do you accept this agreement?",
                    html: "OK, you have until ("+request_expiry_date+") to make this payment. "+
                            "If you are an auto pay customer, "+
                            "the payment will be made automatically on ("+request_expiry_date+").",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "I agree!",
                    cancelButtonText: "I decline!",
                })
                .then((result1) => {
                    if (result1.isConfirmed) {
                       
                // prompt for pin
            const box1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button-2 mr-2',
                cancelButton: 'btn custom-button bg-danger ml-2'
            },
            buttonsStyling: false
            })

            box1.fire({
                title: 'Enter your pin',
                input: 'password',
                inputAttributes: {
                    maxlength: 4,
                    minlength: 4,
                    pattern: "[0-9]+",
                    required: true,
                },
                inputValidator: (value) => {
                    if (!value) {
                    return 'You need to enter your 4 digit pin!'
                    }

                },
                showCancelButton: true,
                confirmButtonText: 'Submit',
                showLoaderOnConfirm: true
                }).then((result) => {
                if (result.isConfirmed) {

                let formData = new Form({
                    request_id: id,
                    pin: result.value
                });

                    this.$isLoading(true)

                formData.post('api/request/payAutomatic',{headers:{"Content-Type":"application/json"}})
                    .then((data) => {
                    this.$isLoading(false)
                    
                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button-2',

                        },
                        buttonsStyling: false
                        })

                        confirmBox2.fire({
                        icon: 'success',
                        title: 'Automatically Withdrawal Successfully Set!',
                        // text: 'Your new balance is ' + new_balance

                        }).then((res)=>{
                            // reloading the page
                        window.location.reload()

                        })



                    })
                    .catch((err) => {
                    this.$isLoading(false)


                        let errorText = 'Operation Unsuccessful'

                        if(err.response.data.errors['pin']){
                            errorText = err.response.data.errors['pin']
                        }
                        else if(err.response.data.errors['general']){
                            errorText = err.response.data.errors['general']
                        }

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button bg-danger',

                        },
                        buttonsStyling: true
                        })

                        confirmBox2.fire({
                        icon: 'error',
                        title: 'Failed!',
                        text: errorText,

                        })
                    });



                }
                })

                    
                    } else {
                      
                    }
                });
      },


      downloadAgreement(id){

              // prompt for pin
              const box1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button-2 mr-2',
                cancelButton: 'btn custom-button bg-danger ml-2'
            },
            buttonsStyling: false
            })

            box1.fire({
                title: 'Enter your pin',
                input: 'password',
                inputAttributes: {
                    maxlength: 4,
                    minlength: 4,
                    pattern: "[0-9]+",
                    required: true,
                },
                inputValidator: (value) => {
                    if (!value) {
                    return 'You need to enter your 4 digit pin!'
                    }

                },
                showCancelButton: true,
                confirmButtonText: 'Submit',
                showLoaderOnConfirm: true
                }).then((result) => {
                if (result.isConfirmed) {
                    let formData = new Form({
                    request_id: id,
                    pin: result.value
                });
                var data={
                    request_id: id,
                    pin: result.value
                };

                    this.$isLoading(true)

                axios.post('api/request/downloadAgreement',
                data,
                {
                    responseType: 'blob'
                   
                })
                    .then((data) => {
                    this.$isLoading(false)
                    console.log(data.data);
                    var blob=new Blob([data.data]);
                        var link=document.createElement('a');
                        link.href=window.URL.createObjectURL(blob);
                        link.download="kashma_agreement.pdf";
                        link.click();
                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button-2',

                        },
                        buttonsStyling: false
                        })

                        confirmBox2.fire({
                        icon: 'success',
                        title: 'Agreement downlaoded Successfully!',
                        // text: 'Your new balance is ' + new_balance

                        }).then((res)=>{
                            // reloading the page
                        window.location.reload()

                        })



                    })
                    .catch((err) => {
                    this.$isLoading(false)


                        let errorText = 'Operation Unsuccessful'

                        if(err.response.data.errors['pin']){
                            errorText = err.response.data.errors['pin']
                        }
                        else if(err.response.data.errors['general']){
                            errorText = err.response.data.errors['general']
                        }

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button bg-danger',

                        },
                        buttonsStyling: true
                        })

                        confirmBox2.fire({
                        icon: 'error',
                        title: 'Failed!',
                        text: errorText,

                        })
                    });

                }

            });
      },


      filterData(){
            $('#filterByModal').modal('toggle')

            this.form.column = this.filter_by.column
            this.form.order = this.filter_by.order
            // this.searchQuery = this.selected + '?page='

            this.getResults()
      }
  }
}
</script>

<style scoped>
.strong:hover{
    color: black;
}
</style>
<style>
.w-80{
    width: 80%;
}
.w-60{
width:78%;
}
</style>