<template>
  <div>
    <div
      v-if="$gate.isBroker() && typeof companyInfo == 'object'"
      class="card custom-card-1 m-0"
    >
      <fieldset>
        <legend class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center">
            <span> {{ companyInfo.company_name }}</span>
          </div>
          <a
            href="#"
            class="nav-link"
            active-class="active"
            onclick="history.go(-1);return false;"
          >
            <button class="btn bg-nyanza font-weight-bold text-gray">
              Go back
            </button>
          </a>
        </legend>
        <div class="card-header"></div>

        <div>
          <TabNav :tabs="tabs" :selected="selected" @selected="setSelected">
            <Tab :isSelected="selected === 'General'">
              <div class="row mt-5">
                <div class="col-12">
                  <div
                    class="d-flex justify-content-between flex-wrap border-bottom pb-3"
                  >
                    <div class="d-flex align-items-center">
                      <h5>General</h5>
                    </div>
                    <div class="d-flex justify-content-end flex-wrap">
                      <a
                        href="#"
                        class="nav-link"
                        data-toggle="modal"
                        data-target="#editAgencyModal"
                      >
                        <button class="btn btn-outline-info">
                          Edit <i class="fa fa-edit"></i>
                        </button>
                      </a>
                      <a
                        href="#"
                        class="nav-link"
                        data-toggle="modal"
                        data-target="#depositModal"
                      >
                        <button class="btn btn-outline-bgreen">
                          Deposit Broker Balance <i class="fa fa-plus"></i>
                        </button>
                      </a>
                      <a
                        href="#"
                        class="nav-link"
                        data-toggle="modal"
                        data-target="#withdrawModal"
                      >
                        <button class="btn btn-outline-bgreen">
                          Withdraw Broker Balance <i class="fa fa-minus"></i>
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-8 pr-5">
                  <div class="border-bottom font-weight-bold py-3">Profile</div>
                  <div class="row py-3 border-bottom">
                    <div class="col-6">Company name</div>
                    <div class="col-6">
                      {{ companyInfo.company_name }}
                    </div>
                  </div>

                  <div class="row py-3 border-bottom">
                    <div class="col-6">Broker Balance</div>
                    <div class="col-6">
                      {{ dalasisFormatter(companyInfo.broker_balance) }}
                    </div>
                  </div>
                  <div class="row py-3 border-bottom">
                    <div class="col-6">Transaction Type</div>
                    <div class="col-6">
                      {{ companyInfo.transaction_type }}
                    </div>
                  </div>

                  <div class="row py-3 border-bottom">
                    <div class="col-6">Fee cut</div>
                    <div class="col-6">{{ companyInfo.fee_cut_percent }}%</div>
                  </div>
                  <div class="row py-3 border-bottom">
                    <div class="col-6">
                      Email <span class="text-danger">*</span>
                    </div>
                    <div class="col-6">
                      {{ companyInfo.company_email }}
                    </div>
                  </div>
                  <div class="row py-3 border-bottom">
                    <div class="col-6">
                      Phone Number <span class="text-danger">*</span>
                    </div>
                    <div class="col-6">
                      {{ companyInfo.phone_number }}
                    </div>
                  </div>

                  <div class="row py-3 border-bottom">
                    <div class="col-6">Total Merchant Admins</div>
                    <div class="col-6">
                      {{ companyInfo.total_merchant_admins }}
                    </div>
                  </div>
                  <div class="row py-3 border-bottom">
                    <div class="col-6">Total Merchants</div>
                    <div class="col-6">
                      {{ companyInfo.total_merchants }}
                    </div>
                  </div>
                  <div class="row py-3 border-bottom">
                    <div class="col-6">Registered on</div>
                    <div class="col-6">
                      {{ companyInfo.time_created }}
                    </div>
                  </div>
                  <div class="row py-3 border-bottom">
                    <div class="col-6">Unique Id</div>
                    <div class="col-6">
                      {{ companyInfo.unique_id }}
                    </div>
                  </div>
                  <div
                    v-if="companyInfo.customer_promotional_amount > 0"
                    class="row py-3 border-bottom"
                  >
                    <div class="col-6">Customer Promo Amount</div>
                    <div class="col-6">
                      {{ companyInfo.customer_promotional_amount }}
                    </div>
                  </div>
                </div>

                <div class="col-4">
                  <div class="border-bottom font-weight-bold py-3 mb-3">
                    Avatar
                  </div>
                  <div class="image">
                    <img
                      class="img-fluid pad"
                      src="/images/profiles/building-none.png"
                      alt=""
                    />
                  </div>
                  <!-- <div v-if="canEdit" class="btn btn-info w-100">
                                        Change profile picture
                                    </div> -->
                </div>
              </div>
            </Tab>
            <Tab :isSelected="selected === 'Accounts'"> </Tab>

            <Tab :isSelected="selected === 'Transfers'">
              <CustomerCompanyTransfers :company_id="companyInfo.id" />
            </Tab>
            <Tab :isSelected="selected === 'Deposits'">
              <CustomerCompanyDeposits :company_id="companyInfo.id" />
            </Tab>

            <Tab :isSelected="selected === 'Bank Accounts'"> bban </Tab>

            <Tab :isSelected="selected === 'Logs'"> logs </Tab>
          </TabNav>
        </div>
      </fieldset>

      <div
        class="modal fade"
        id="depositModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="depositModalTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <form @submit.prevent="deposit" method="post">
              <div class="modal-header border-0">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="d-flex justify-content-center">
                  <h2 class="swal2-title" id="exampleModalLongTitle">
                    Deposit Broker Balance
                  </h2>
                </div>
                <div class="card-body">
                  <div>
                    Current Broker Balance:
                    {{ dalasisFormatter(companyInfo.broker_balance) }}
                  </div>

                  <div class="form-group py-2 mb-0">
                    <div
                      class="text-danger"
                      v-if="deposit_form.errors.has('amount')"
                      v-html="deposit_form.errors.get('amount')"
                    />

                    <label for="amount">How much</label>
                  </div>
                  <div class="input-group mb-2">
                    <div class="input-group-prepend">
                      <span
                        class="input-group-text font-weight-bold text-navy bg-olive"
                        >D</span
                      >
                    </div>
                    <input
                      type="number"
                      required
                      id="amount"
                      v-model="deposit_form.amount"
                      placeholder="Enter amount in dalasis"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
              <div class="modal-footer border-0 d-flex justify-content-center">
                <button
                  :disabled="deposit_form.busy"
                  type="submit"
                  class="btn custom-button-2"
                >
                  Deposit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="withdrawModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="withdrawModalTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <form @submit.prevent="withdraw" method="post">
              <div class="modal-header border-0">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="d-flex justify-content-center">
                  <h2 class="swal2-title" id="exampleModalLongTitle">
                    Withdraw Broker Balance
                  </h2>
                </div>
                <div class="card-body">
                  <div>
                    Current Broker Balance:
                    {{ dalasisFormatter(companyInfo.broker_balance) }}
                  </div>

                  <div class="form-group py-2 mb-0">
                    <div
                      class="text-danger"
                      v-if="withdraw_form.errors.has('amount')"
                      v-html="withdraw_form.errors.get('amount')"
                    />

                    <label for="amount">How much</label>
                  </div>
                  <div class="input-group mb-2">
                    <div class="input-group-prepend">
                      <span
                        class="input-group-text font-weight-bold text-navy bg-olive"
                        >D</span
                      >
                    </div>
                    <input
                      type="number"
                      required
                      id="amount"
                      v-model="withdraw_form.amount"
                      placeholder="Enter amount in dalasis"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
              <div class="modal-footer border-0 d-flex justify-content-center">
                <button
                  :disabled="withdraw_form.busy"
                  type="submit"
                  class="btn custom-button-2"
                >
                  Withdraw
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="editAgencyModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="editAgencyModalTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <form @submit.prevent="editAgency" method="post">
              <div class="modal-header border-0">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="d-flex justify-content-center">
                  <h2 class="swal2-title" id="exampleModalLongTitle">
                    Edit Merchant Store
                  </h2>
                </div>
                <form @submit.prevent="editAgency" class="form-horizontal">
                  <div class="card-body">
                    <div class="form-group">
                      <div
                        class="text-danger"
                        v-if="edit_agency_form.errors.has('company_name')"
                        v-html="edit_agency_form.errors.get('company_name')"
                      />

                      <label for="inputEmail3">Company Name</label>
                      <input
                        type="text"
                        class="form-control"
                        id="inputEmail3"
                        v-model="edit_agency_form.company_name"
                      />
                    </div>
                    <div class="form-group">
                      <div
                        class="pt-3 text-danger"
                        v-if="edit_agency_form.errors.has('fee_cut_percent')"
                        v-html="edit_agency_form.errors.get('fee_cut_percent')"
                      />

                      <label for="inputCut">Transaction fee cut percent</label>
                      <input
                        type="number"
                        id="inputCut"
                        v-model="edit_agency_form.fee_cut_percent"
                        maxlength="70"
                        placeholder="Enter fee cut percent"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <!-- /.card-body -->
                </form>
              </div>
              <div class="modal-footer border-0 d-flex justify-content-center">
                <button type="submit" class="btn custom-button-2">
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <not-found></not-found>
    </div>
  </div>
</template>




<script>
import TabNav from "../../includes/tabNav.vue";
import Tab from "../../includes/tabs.vue";

export default {
  name: "ViewUserProfile",
  props: {},

  components: {
    TabNav,
    Tab,
    CustomerCompanyTransfers: () =>
      import(
        "./CustomerCompanyTransfers.vue" /* webpackChunkName: "js/customer-transfers" */
      ),
    CustomerCompanyDeposits: () =>
      import(
        "./CustomerCompanyDeposits.vue" /* webpackChunkName: "js/customer-transfers" */
      ),
  },
  data() {
    return {
      selected: "General",
      tabs: ["General", "Accounts", "Transfers", "Deposits", "Logs"],
      logs: [],
      pageData: {},
      companyInfo: {},
      deposit_form: new Form({
        amount: 0,
      }),
      withdraw_form: new Form({
        amount: 0,
      }),
      edit_agency_form: new Form({
        company_id: 0,
        company_name: "",
        picture: null,
        fee_cut_percent: 0,
      }),
    };
  },
  created() {
    // fetch fee table
    this.getMerchantStore();
  },
  watch: {
    selected: function () {},
  },
  methods: {
    setSelected(tab) {
      this.selected = tab;
    },

    getMerchantStore() {
      this.$isLoading(true);

      let temp_form = new Form({
        id: this.$route.params.id,
      });

      temp_form
        .post("/api/broker/getMerchantStore")
        .then(({ data }) => {
          this.companyInfo = data;
          if (this.$gate.isBroker()) {
            // if(this.companyInfo.rank == 'Customer'){
            // this.tabs = ['General', 'Transfers', 'Deposits', 'Bank Accounts', 'Logs']

            // }
            this.edit_agency_form = new Form({
              company_id: data.id,
              company_name: data.company_name,
              picture: null,
              fee_cut_percent: data.fee_cut_percent,
              password: "",
            });
          }

          this.$isLoading(false);
        })
        .catch((err) => {
          this.$isLoading(false);
        });
    },

    deposit() {
      if (this.deposit_form.amount <= 0) return;
      $("#depositModal").modal("toggle");

      const confirmBox1 = Swal.mixin({
        customClass: {
          confirmButton: "btn custom-button bg-danger mr-2",
          cancelButton: "btn custom-button-2 ml-2",
        },
        buttonsStyling: false,
      });

      confirmBox1
        .fire({
          title:
            "Are you sure you want to deposit " +
            this.dalasisFormatter(this.deposit_form.amount) +
            "?",
          text:
            this.companyInfo.company_name +
            " will have a total broker balance of " +
            this.dalasisFormatter(
              parseFloat(this.deposit_form.amount) +
                parseFloat(this.companyInfo.broker_balance)
            ),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, save the changes!",
        })
        .then((result1) => {
          if (result1.isConfirmed) {
            // prompt for pin
            const box1 = Swal.mixin({
              customClass: {
                confirmButton: "btn custom-button bg-danger  mr-2",
                cancelButton: "btn custom-button-2 ml-2",
              },
              buttonsStyling: false,
            });

            box1
              .fire({
                title: "Enter your password",
                text:
                  "To confirm your want to deposit " +
                  this.dalasisFormatter(this.deposit_form.amount) +
                  " for this agency",
                input: "password",
                inputAttributes: {
                  required: true,
                },
                inputValidator: (value) => {
                  if (!value) {
                    return "You need to enter your password!";
                  }
                },
                showCancelButton: true,
                confirmButtonText: "Submit",
                showLoaderOnConfirm: true,
              })
              .then((result) => {
                if (result.isConfirmed) {
                  this.deposit_form.password = result.value;
                  this.deposit_form.company_id = this.companyInfo.id;

                  this.$isLoading(true);

                  this.deposit_form
                    .post("/api/broker/depositForAgency", {
                      headers: { "Content-Type": "application/json" },
                    })
                    .then((data) => {
                      this.$isLoading(false);

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button-2",
                        },
                        buttonsStyling: false,
                      });

                      confirmBox2
                        .fire({
                          icon: "success",
                          title: "Deposit Successful!",
                        })
                        .then((res) => {
                          this.deposit_form.password = null;
                          window.location.reload();
                        });
                    })
                    .catch((err) => {
                      this.$isLoading(false);

                      // show them if that city name is taken
                      let errorText = "Deposit Unsuccessful";

                      if (err.response.data.errors["password"]) {
                        errorText = err.response.data.errors["password"];
                        const confirmBox2 = Swal.mixin({
                          customClass: {
                            confirmButton: "btn custom-button bg-danger",
                          },
                          buttonsStyling: true,
                        });

                        confirmBox2.fire({
                          icon: "error",
                          title: "Failed!",
                          text: errorText,
                        });
                      } else {
                        $("#depositModal").modal("toggle");
                      }
                    });
                }
              });
          }
        });
    },
    withdraw() {
      if (this.withdraw_form.amount <= 0) return;
      $("#withdrawModal").modal("toggle");

      const confirmBox1 = Swal.mixin({
        customClass: {
          confirmButton: "btn custom-button bg-danger mr-2",
          cancelButton: "btn custom-button-2 ml-2",
        },
        buttonsStyling: false,
      });

      confirmBox1
        .fire({
          title:
            "Are you sure you want to withdraw " +
            this.dalasisFormatter(this.withdraw_form.amount) +
            "?",
          text:
            this.companyInfo.company_name +
            " will have a total broker balance of " +
            this.dalasisFormatter(
              parseFloat(this.companyInfo.broker_balance) -
                parseFloat(this.withdraw_form.amount)
            ),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, save the changes!",
        })
        .then((result1) => {
          if (result1.isConfirmed) {
            // prompt for pin
            const box1 = Swal.mixin({
              customClass: {
                confirmButton: "btn custom-button bg-danger  mr-2",
                cancelButton: "btn custom-button-2 ml-2",
              },
              buttonsStyling: false,
            });

            box1
              .fire({
                title: "Enter your password",
                text:
                  "To confirm your want to withdraw " +
                  this.dalasisFormatter(this.withdraw_form.amount) +
                  " for this agency",
                input: "password",
                inputAttributes: {
                  required: true,
                },
                inputValidator: (value) => {
                  if (!value) {
                    return "You need to enter your password!";
                  }
                },
                showCancelButton: true,
                confirmButtonText: "Submit",
                showLoaderOnConfirm: true,
              })
              .then((result) => {
                if (result.isConfirmed) {
                  this.withdraw_form.password = result.value;
                  this.withdraw_form.company_id = this.companyInfo.id;

                  this.$isLoading(true);

                  this.withdraw_form
                    .post("/api/broker/withdrawForAgency", {
                      headers: { "Content-Type": "application/json" },
                    })
                    .then((data) => {
                      this.$isLoading(false);

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button-2",
                        },
                        buttonsStyling: false,
                      });

                      confirmBox2
                        .fire({
                          icon: "success",
                          title: "Withdraw Successful!",
                        })
                        .then((res) => {
                          this.withdraw_form.password = null;
                          window.location.reload();
                        });
                    })
                    .catch((err) => {
                      this.$isLoading(false);

                      // show them if that city name is taken
                      let errorText = "Withdraw Unsuccessful";

                      if (err.response.data.errors["password"]) {
                        errorText = err.response.data.errors["password"];
                        const confirmBox2 = Swal.mixin({
                          customClass: {
                            confirmButton: "btn custom-button bg-danger",
                          },
                          buttonsStyling: true,
                        });

                        confirmBox2.fire({
                          icon: "error",
                          title: "Failed!",
                          text: errorText,
                        });
                      } else {
                        $("#withdrawModal").modal("toggle");
                      }
                    });
                }
              });
          }
        });
    },
    editAgency() {
      $("#editAgencyModal").modal("toggle");

      const confirmBox1 = Swal.mixin({
        customClass: {
          confirmButton: "btn custom-button bg-danger mr-2",
          cancelButton: "btn custom-button-2 ml-2",
        },
        buttonsStyling: false,
      });

      confirmBox1
        .fire({
          title: "Are you sure?",
          text: "Your changes will be saved and the agency will be affected",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, save the changes!",
        })
        .then((result1) => {
          if (result1.isConfirmed) {
            // prompt for pin
            const box1 = Swal.mixin({
              customClass: {
                confirmButton: "btn custom-button bg-danger  mr-2",
                cancelButton: "btn custom-button-2 ml-2",
              },
              buttonsStyling: false,
            });

            box1
              .fire({
                title: "Enter your password",
                text: "To confirm your changes",
                input: "password",
                inputAttributes: {
                  required: true,
                },
                inputValidator: (value) => {
                  if (!value) {
                    return "You need to enter your password!";
                  }
                },
                showCancelButton: true,
                confirmButtonText: "Submit",
                showLoaderOnConfirm: true,
              })
              .then((result) => {
                if (result.isConfirmed) {
                  this.edit_agency_form.password = result.value;
                  this.$isLoading(true);

                  this.edit_agency_form
                    .post("/api/broker/editAgency", {
                      headers: { "Content-Type": "application/json" },
                    })
                    .then((data) => {
                      this.$isLoading(false);

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button-2",
                        },
                        buttonsStyling: false,
                      });

                      confirmBox2
                        .fire({
                          icon: "success",
                          title: "Agency changes saved!",
                        })
                        .then((res) => {
                          window.location.reload();
                        });
                    })
                    .catch((err) => {
                      this.$isLoading(false);

                      // show them if that city name is taken
                      let errorText = "Agency changes Unsuccessful";

                      if (err.response.data.errors["password"]) {
                        errorText = err.response.data.errors["password"];
                        const confirmBox2 = Swal.mixin({
                          customClass: {
                            confirmButton: "btn custom-button bg-danger",
                          },
                          buttonsStyling: true,
                        });

                        confirmBox2.fire({
                          icon: "error",
                          title: "Failed!",
                          text: errorText,
                        });
                      } else {
                        $("#editAgencyModal").modal("toggle");
                      }
                    });
                }
              });
          }
        });
    },
  },
};
</script>
