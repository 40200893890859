<template>
  <div v-if="$gate.isAgentAdmin()">
    <div v-if="!$gate.isRegistrationComplete() && $gate.canTransact()">
      <div class="card px-4 py-2 border-0" style="box-shadow: none">
        <div class="card-body text-center">
          <h3>Congratulations for joining!</h3>
          <div class="font-size-1">
            To be able to send and receive money, you must
            <router-link
              to="/set-pin"
              class="text-info"
              style="text-decoration: underline"
            >
              set your pin</router-link
            >
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="$gate.isRegistrationComplete() && $gate.canTransact()">
      <div class="row general border-bottom">
        <div class="col-12">
          <div class="card row" style="box-shadow: none">
            <div
              v-if="getUserProfile.kashma_balance == null"
              class="px-5 border-bottom pt-5 pb-4 d-flex justify-content-between"
            >
              <!-- cash on hands -->
              <div class="p-0">
                <span class="font-weight-bold font-size-1">Cash on Hand</span>
                <br />
                <div class="d-flex align-items-end">
                  <span class="text-bgreen font-size-4 font-weight-bold">
                    {{ cash_on_hand }}
                  </span>
                </div>
              </div>

              <!-- Reduce cash -->
              <div class="text-center">
                <a
                  href="#"
                  data-toggle="modal"
                  data-target="#decreaseCashOnHandModal"
                >
                  <div class="icon-wrapper bg-danger p-md-5 px-3 px-md-4">
                    <i class="fas fa-arrow-down text-white"></i>
                  </div>
                </a>
              </div>
              <!-- Add more cash -->
              <div class="d-flex justify-content-center align-items-center">
                <a
                  href="#"
                  class="py-2 py-md-3 px-3 px-md-4 bg-green1 add-cash"
                  data-toggle="modal"
                  data-target="#increaseCashOnHandModal"
                >
                  <i class="fas fa-plus text-white"></i>
                </a>
              </div>
            </div>

            <div class="px-5 pb-2 wallet-footer flex-wrap">
              <!-- <div class="text-center">
                                <a  href="#" class="" data-toggle="modal" data-target="#decreaseCashOnHandModal">
                                    <div class="icon-wrapper bg-danger ">

                                        <i class="fas fa-arrow-down text-white"></i>

                                    </div>
                                    <strong>Withdraw</strong>
                                </a>

                            </div> -->
              <div class="text-center">
                <a
                  href="#"
                  class=""
                  data-toggle="modal"
                  data-target="#depositForCustomerModal"
                >
                  <div class="icon-wrapper bg-ogreen1">
                    <i class="fas fa-money-check-alt text-white"></i>
                  </div>
                  <strong>Deposit</strong>
                </a>
              </div>

              <div class="text-center">
                <a href="#" class="">
                  <div class="icon-wrapper bg-yellow">
                    <i class="fas fa-history text-white"></i>
                  </div>
                  <strong>Logs</strong>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12" v-if="getUserProfile.kashma_balance == null">
          <div class="row">
            <div class="col-12">
              <div class="card py-2 px-3" style="box-shadow: none">
                <div class="d-flex justify-content-between">
                  <div class="d-flex font-weight-bold align-items-center">
                    <span class="font-size-1">Branch</span>
                  </div>
                  <div class="d-flex align-items-end">
                    <span class="font-size-3 text-bwgreen font-weight-bold">
                      {{ branch_town }}
                    </span>
                  </div>
                </div>
                <div class="d-flex pb-2 justify-content-between">
                  <div class="d-flex font-weight-bold align-items-center">
                    <span class="font-size-1"
                      >Fee earnings (Money Transfer & Deposits)</span
                    >
                  </div>
                  <div class="d-flex align-items-end">
                    <span class="font-size-3 text-brown1 font-weight-bold">
                      {{ commission }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12 pb-2 text-muted font-weight-bold text-center">
          Agency Daily Stats
        </div>
        <div class="col-12 col-md-3 col-sm-6">
          <div class="info-box">
            <span class="info-box-icon text-brown1"
              ><i class="fas fa-hand-holding-usd"></i
            ></span>

            <div class="info-box-content">
              <span class="info-box-text">
                <span class="btn-outline-info">{{
                  basicStats["fee_earnings"]["count"]
                }}</span>
              </span>

              <span class="info-box-text"
                >Today's earnings (Money Transer)</span
              >
              <span class="info-box-number">{{
                dalasisFormatter(basicStats["fee_earnings"]["sum"])
              }}</span>
            </div>
            <!-- /.info-box-content -->
          </div>
          <!-- /.info-box -->
        </div>
        <!-- /.col -->
        <div class="col-12 col-md-3 col-sm-6">
          <div class="info-box mb-3">
            <span class="info-box-icon text-yellow"
              ><i class="fas fa-arrow-alt-circle-down"></i
            ></span>

            <div class="info-box-content">
              <span class="info-box-text">
                <span class="btn-outline-info">{{
                  basicStats["incoming"]["count"]
                }}</span>
              </span>

              <span class="info-box-text"> Incoming Transactions </span>

              <span class="info-box-number">{{
                dalasisFormatter(basicStats["incoming"]["sum"])
              }}</span>
            </div>
            <!-- /.info-box-content -->
          </div>
          <!-- /.info-box -->
        </div>
        <!-- /.col -->

        <!-- fix for small devices only -->
        <div class="clearfix hidden-md-up"></div>

        <div class="col-12 col-md-3 col-sm-6">
          <div class="info-box mb-3">
            <span class="info-box-icon text-red"
              ><i class="fas fa-arrow-alt-circle-up"></i
            ></span>

            <div class="info-box-content">
              <span class="info-box-text">
                <span class="btn-outline-info">{{
                  basicStats["outgoing"]["count"]
                }}</span>
              </span>

              <span class="info-box-text">Outgoing Transactions</span>
              <span class="info-box-number">{{
                dalasisFormatter(basicStats["outgoing"]["sum"])
              }}</span>
            </div>
            <!-- /.info-box-content -->
          </div>
          <!-- /.info-box -->
        </div>
        <!-- /.col -->
        <div class="col-12 col-md-3 col-sm-6">
          <div class="info-box mb-3">
            <span class="info-box-icon text-green"
              ><i class="fas fa-thumbs-up"></i
            ></span>

            <div class="info-box-content">
              <span class="info-box-text">
                <span class="btn-outline-info">{{
                  basicStats["collected"]["count"]
                }}</span>
              </span>
              <span class="info-box-text">Successful Transactions</span>
              <span class="info-box-number">{{
                dalasisFormatter(basicStats["collected"]["sum"])
              }}</span>
            </div>
            <!-- /.info-box-content -->
          </div>
          <!-- /.info-box -->
        </div>
        <!-- /.col -->
      </div>

      <div class="row mt-5">
        <div class="col-md-12 col-lg-8">
          <div class="card">
            <div class="row">
              <div class="col-12">
                <h3 class="p-3 card-title">
                  Agency Transactions (Incoming & Outgoing)
                </h3>
              </div>
              <div class="col-12 text-muted">
                <TabNav
                  :tabs="[
                    'Last Year',
                    'This Year',
                    'Monthly',
                    'Weekly',
                    'Daily',
                  ]"
                  :selected="selected"
                  @selected="setSelected"
                >
                </TabNav>
              </div>

              <div class="col-12 pt-2">
                <div class="text-center py-3 font-weight-bold">
                  {{ chart1Title }}
                </div>
                <div class="mb-4 ml-3">
                  <div>
                    <span class="text-muted">Incoming Total: </span>
                    <span
                      class="font-weight-bold"
                      style="color: rgba(81, 139, 201, 1)"
                    >
                      {{
                        dalasisFormatter(
                          chart1Selection["incoming"].reduce(
                            (total, num) => total + num,
                            0
                          )
                        )
                      }}
                    </span>
                  </div>
                  <div>
                    <span class="text-muted">Outgoing Total: </span>
                    <span
                      class="font-weight-bold"
                      style="color: rgba(148, 82, 165, 1)"
                    >
                      {{
                        dalasisFormatter(
                          chart1Selection["outgoing"].reduce(
                            (total, num) => total + num,
                            0
                          )
                        )
                      }}
                    </span>
                  </div>
                </div>
                <Chart1
                  :chartData="chart1Selection"
                  :barLabels="['Incoming', 'Outgoing']"
                  :barColors="[
                    'rgba(81,139,201, 0.6)',
                    'rgba(148,82,165, 0.6)',
                  ]"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 col-lg-4">
          <div class="card ml-lg-2">
            <div>
              <div class="text-center py-3 font-weight-bold">
                Branches Earnings Chart
              </div>
              <div class="d-flex justify-content-between mb-4 px-2">
                <div
                  v-for="(label, index) in beChartSelection.labels"
                  :key="index"
                >
                  <span class="text-muted">{{ label }}: </span>
                  <span
                    class="font-weight-bold"
                    :style="{
                      color: beChartSelection.colors[index],
                    }"
                  >
                    {{ dalasisFormatter(beChartSelection.earnings[index]) }}
                  </span>
                </div>
              </div>
            </div>
            <div class="px-3">
              <BEChart :chartData="beChartSelection" />
            </div>
          </div>
        </div>
      </div>

      <!-- Modals -->
      <div
        class="modal fade"
        id="increaseCashOnHandModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="increaseCashOnHandModalTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <form @submit.prevent="increaseCashOnHand" method="post">
              <div class="modal-header border-0">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="d-flex justify-content-center">
                  <h2 class="swal2-title" id="exampleModalLongTitle">
                    Update cash on hand by:
                  </h2>
                </div>
                <div class="input-group pt-2 px-3 mb-2">
                  <div class="input-group-prepend">
                    <span
                      class="input-group-text font-weight-bold text-navy bg-olive"
                      >D</span
                    >
                  </div>
                  <input
                    type="number"
                    required
                    id="amount"
                    v-model="coh_form.amount"
                    placeholder="Enter amount in dalasis"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="modal-footer border-0 d-flex justify-content-center">
                <button type="submit" class="btn custom-button-2">
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="decreaseCashOnHandModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="increaseCashOnHandModalTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <form @submit.prevent="decreaseCashOnHand" method="post">
              <div class="modal-header border-0">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="d-flex justify-content-center">
                  <h2 class="swal2-title" id="exampleModalLongTitle">
                    Decrease cash on hand by:
                  </h2>
                </div>
                <div class="input-group pt-2 px-3 mb-2">
                  <div class="input-group-prepend">
                    <span
                      class="input-group-text font-weight-bold text-navy bg-olive"
                      >D</span
                    >
                  </div>
                  <input
                    type="number"
                    required
                    id="amount"
                    v-model="coh_form.amount"
                    placeholder="Enter amount in dalasis"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="modal-footer border-0 d-flex justify-content-center">
                <button
                  :disabled="
                    coh_form.amount <= 0 ||
                    coh_form.amount > branch_details.cash_on_hand
                  "
                  type="submit"
                  class="btn custom-button-2"
                >
                  Decrease
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <!-- <div class="modal fade" id="depositForCustomerModal" tabindex="-1" role="dialog" aria-labelledby="depositForCustomerModalTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <form  @submit.prevent='depositForCustomer' method="post">

                            <div class="modal-header border-0">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">


                                <div class="d-flex justify-content-center">
                                    <h2 class="swal2-title" id="exampleModalLongTitle">Deposit For Customer/Merchant
                                        from the {{branch_town}} Branch
                                    </h2>
                                </div>
                                <div class="card-body">
                                    <div class="form-group">

                                        <label for="username" >Username/ Email / Phone Number</label>
                                        <div class="input-group date">
                                            <input type="text"
                                             @blur="verifyCustomerText" required id='username' v-model="customer_deposit_form.text"
                                            class="form-control datetimepicker-input" style="border-right:none;"
                                              placeholder="Enter username, email or phone number">
                                            <div class="input-group-append">
                                                <div class="input-group-text bg-white border-left-0">
                                                    <i v-if="customer_deposit_form.id != null" class="fa fa-check text-success"></i>
                                                    <i v-if="!customerExists" class="fa fa-times text-danger"></i>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">

                                        <label for="amount3" >Amount</label>
                                        <input type="number" required id='amount3'
                                        v-model="customer_deposit_form.amount"
                                        :disabled='customer_deposit_form.text.length < 4'
                                        placeholder="Enter amount" class="form-control ">

                                    </div>

                                </div>


                            </div>
                            <div class="modal-footer border-0 d-flex justify-content-center">
                                <button :disabled='
                                customer_deposit_form.amount == null ||
                                customer_deposit_form.amount <= 0 ||
                                customer_deposit_form.amount >= broker_balance ||
                                customer_deposit_form.text == "" ||
                                customer_deposit_form.id == null ||
                                customer_deposit_form.branch_id == null

                                ' type="submit" class="btn custom-button-2 ">Deposit</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div> -->

      <AgencyDepositButton
        :broker_balance="broker_balance"
        :branch_id="branch_id"
        :branch_town="branch_town"
      />

      <AgencyFooter :getUserProfile="getUserProfile" />
    </div>
  </div>
</template>

<script>
import Chart1 from "./../../charts/general/Chart1";
import BEChart from "./../../charts/agent_admin/BEChart";
import TabNav from "./../../includes/tabNav";
import Tab from "./../../includes/tabs";
import AgencyDepositButton from "../../includes/AgencyDepositButton.vue";

export default {
  name: "Dashboard",
  props: {
    branch_id: Number,
    branch_details: Object,
  },
  components: {
    Chart1,
    BEChart,
    TabNav,
    Tab,
    AgencyDepositButton,
  },
  data() {
    return {
      selected: "Daily",

      userInfo: "",
      cash_on_hand: 0,
      branch_town: "",
      commission: "",
      chart1Title: "Daily Incoming & Outgoing Transactions",
      chart1Selection: {
        incoming: [0],
        outgoing: [0],
      },
      beChartSelection: {
        colors: [],
        earnings: [0],
        labels: [],
      },
      basicStats: {
        fee_earnings: {
          count: 0,
          sum: 0,
        },
        incoming: {
          count: 0,
          sum: 0,
        },
        outgoing: {
          count: 0,
          sum: 0,
        },
        collected: {
          count: 0,
          sum: 0,
        },
      },
      broker_balance: 0,

      coh_form: new Form({
        branch_id: 0,
        amount: 0,
        pin: "",
      }),

      customer_deposit_form: new Form({
        branch_id: null,
        id: null,
        pin: null,
        unique_id: null,
        text: "",
        fullname: "",
        amount: null,
      }),
      customerExists: true,
    };
  },
  created() {
    if (this.$gate.isAgentAdmin()) this.dashboardStats();
  },
  watch: {
    branch_id: function () {
      // if it changes... send changes to Admin Dashboard Prop
      this.coh_form.branch_id = this.branch_id;
      this.customer_deposit_form.branch_id = this.branch_id;
    },
    branch_details: function () {
      this.cash_on_hand = this.dalasisFormatter(
        this.branch_details.cash_on_hand
      );
      this.branch_town = this.branch_details.town_name;
      this.commission = this.dalasisFormatter(this.branch_details.fee_earnings);
    },

    getAgentAdminChart1: function () {
      this.chartData = this.getAgentAdminChart1;
      this.chart1Selection = this.getAgentAdminChart1["daily"];
    },
    getUserProfile: function () {
      this.broker_balance = this.getUserProfile.broker_balance;
    },
    selected: function () {
      if (this.selected == "Daily") {
        //
        this.chart1Selection = this.getAgentAdminChart1["daily"];
        this.chart1Title = "Daily Incoming & Outgoing Transactions";
      } else if (this.selected == "Weekly") {
        this.chart1Selection = this.getAgentAdminChart1["weekly"];
        this.chart1Title = "Weekly Incoming & Outgoing Transactions";
      } else if (this.selected == "Monthly") {
        this.chart1Selection = this.getAgentAdminChart1["monthly"];
        this.chart1Title = "Month Incoming & Outgoing Transactions";
      } else if (this.selected == "This Year") {
        this.chart1Selection = this.getAgentAdminChart1["yearly"];
        this.chart1Title = "This Year's Incoming & Outgoing Transactions";
      } else if (this.selected == "Last Year") {
        this.chart1Selection = this.getAgentAdminChart1["last year"];
        this.chart1Title = "Last Year's & Outgoing Transactions";
      }
    },
  },

  mounted() {
    this.$store.dispatch("userProfile");
    this.$store.dispatch("agentAdminChart1");
  },
  computed: {
    getUserProfile() {
      //final output from here
      return this.$store.getters.getUserProfile;
    },

    getAgentAdminChart1() {
      return this.$store.getters.getAgentAdminChart1;
    },
  },
  methods: {
    increaseCashOnHand() {
      console.log(this.getUserProfile);
      $("#increaseCashOnHandModal").modal("toggle");

      let new_cash =
        parseInt(this.coh_form.amount) +
        parseInt(this.branch_details.cash_on_hand);
      new_cash = this.dalasisFormatter(new_cash);

      const confirmBox1 = Swal.mixin({
        customClass: {
          confirmButton: "btn custom-button-2 mr-2",
          cancelButton: "btn custom-button bg-danger ml-2",
        },
        buttonsStyling: false,
      });

      confirmBox1
        .fire({
          title: "Are you sure?",
          text:
            "You'll be increasing your cash on hand by " +
            this.dalasisFormatter(parseInt(this.coh_form.amount)) +
            ". You new balance would be " +
            new_cash,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, increase it!",
        })
        .then((result1) => {
          if (result1.isConfirmed) {
            const box1 = Swal.mixin({
              customClass: {
                confirmButton: "btn custom-button-2 mr-2",
                denyButton: "btn custom-button bg-secondary ml-2",
              },
              buttonsStyling: false,
            });

            box1
              .fire({
                title: "Enter your pin",
                input: "password",
                inputAttributes: {
                  maxlength: 4,
                  minlength: 4,
                  pattern: "[0-9]+",
                  required: true,
                },
                inputValidator: (value) => {
                  if (!value) {
                    return "You need to enter your 4 digit pin!";
                  }
                },
                showDenyButton: true,
                denyButtonText: `Back`,
                confirmButtonText: "Submit",
              })
              .then((result) => {
                if (result.isConfirmed) {
                  this.coh_form.pin = result.value;

                  this.$isLoading(true);

                  this.coh_form
                    .post("api/cash/increase", {
                      headers: {
                        "Content-Type": "application/json",
                      },
                    })
                    .then((data) => {
                      this.$isLoading(false);

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button-2",
                        },
                        buttonsStyling: false,
                      });

                      confirmBox2
                        .fire({
                          icon: "success",
                          title: "Increase!",
                          text:
                            "Increment successful! Your new Cash in Hand  is " +
                            new_cash,
                        })
                        .then((res) => {
                          // reloading the page
                          window.location.href = "/agent-admin-dashboard";
                          // this.cash_on_hand = new_cash
                        });
                    })
                    .catch((err) => {
                      this.$isLoading(false);

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button bg-danger",
                        },
                        buttonsStyling: true,
                      });

                      confirmBox2.fire({
                        icon: "error",
                        title: "Failed!",
                        text: err.response.data.errors["pin"],
                      });
                    });
                } else if (result.isDenied) {
                  $("#increaseCashOnHandModal").modal("show");
                }
              });
          }
        });
    },
    decreaseCashOnHand() {
      // document.getElementById('increaseCashOnHandModal').style.display = "none"
      $("#decreaseCashOnHandModal").modal("toggle");

      let new_cash =
        parseInt(this.branch_details.cash_on_hand) -
        parseInt(this.coh_form.amount);
      new_cash = this.dalasisFormatter(new_cash);

      const confirmBox1 = Swal.mixin({
        customClass: {
          confirmButton: "btn custom-button bg-danger mr-2",
          cancelButton: "btn custom-button-2  ml-2",
        },
        buttonsStyling: false,
      });

      confirmBox1
        .fire({
          title: "Are you sure?",
          text:
            "You'll be decreasing your cash on hand by " +
            this.dalasisFormatter(parseInt(this.coh_form.amount)) +
            ". You new balance would be " +
            new_cash,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, decrease it!",
        })
        .then((result1) => {
          if (result1.isConfirmed) {
            const box1 = Swal.mixin({
              customClass: {
                confirmButton: "btn custom-button-2 mr-2",
                denyButton: "btn custom-button bg-secondary ml-2",
              },
              buttonsStyling: false,
            });

            box1
              .fire({
                title: "Enter your pin",
                input: "password",
                inputAttributes: {
                  maxlength: 4,
                  minlength: 4,
                  pattern: "[0-9]+",
                  required: true,
                },
                inputValidator: (value) => {
                  if (!value) {
                    return "You need to enter your pin!";
                  }
                },
                showDenyButton: true,
                denyButtonText: `Back`,
                confirmButtonText: "Submit",
              })
              .then((result) => {
                if (result.isConfirmed) {
                  this.coh_form.pin = result.value;

                  this.$isLoading(true);

                  this.coh_form
                    .post("api/cash/decrease", {
                      headers: {
                        "Content-Type": "application/json",
                      },
                    })
                    .then((data) => {
                      this.$isLoading(false);

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button-2",
                        },
                        buttonsStyling: false,
                      });

                      confirmBox2
                        .fire({
                          icon: "success",
                          title: "Decrease!",
                          text:
                            "Decrement successful! Your new broker balance is " +
                            new_cash,
                        })
                        .then((res) => {
                          // reloading the page
                          window.location.href = "/agent-admin-dashboard";
                        });
                    })
                    .catch((err) => {
                      this.$isLoading(false);

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button bg-danger",
                        },
                        buttonsStyling: true,
                      });

                      confirmBox2.fire({
                        icon: "error",
                        title: "Failed!",
                        text: err.response.data.errors["pin"],
                      });
                    });
                } else if (result.isDenied) {
                  $("#increaseCashOnHandModal").modal("show");
                }
              });
          }
        });
    },

    setSelected(tab) {
      this.selected = tab;
    },

    dashboardStats() {
      // fetch companies
      this.$isLoading(true);

      axios
        .get("api/agent-admin-dashboard/basicStats")
        .then(({ data }) => {
          this.basicStats = data;
          this.branchesEarningsChart();
          this.$isLoading(false);
        })
        .catch((err) => {
          this.$isLoading(false);
        });
    },

    branchesEarningsChart() {
      axios
        .get("api/agent-admin-dashboard/branchesEarnings")
        .then(({ data }) => {
          this.beChartSelection = data;
          this.$isLoading(false);
        })
        .catch((err) => {
          this.$isLoading(false);
        });
    },

    verifyCustomerText() {
      this.customerExists = true;
      this.customer_deposit_form.id = null;
      this.customer_deposit_form.fullname = "";

      if (this.customer_deposit_form.text.length >= 4) {
        let temp_form = new Form({
          text: this.customer_deposit_form.text,
        });
        temp_form
          .post("api/general/verifyKashMaId")
          .then(({ data }) => {
            if (data.length == 0) {
              this.customerExists = false;
            } else {
              this.customer_deposit_form.id = data[0].id;
              this.customer_deposit_form.unique_id = data[0].unique_id;

              this.customer_deposit_form.fullname = data[0].name;
            }
          })
          .catch((err) => {});

        //   this.customerExists = false;
      }
    },
    depositForCustomer() {
      if (this.depositValidation()) return;

      $("#depositForCustomerModal").modal("toggle");

      const confirmBox1 = Swal.mixin({
        customClass: {
          confirmButton: "btn custom-button bg-danger mr-2",
          cancelButton: "btn custom-button-2 ml-2",
        },
        buttonsStyling: false,
      });

      confirmBox1
        .fire({
          title: "Are you sure?",
          html:
            "You will be depositing <b>" +
            this.dalasisFormatter(this.customer_deposit_form.amount) +
            "</b> to <b>" +
            this.customer_deposit_form.fullname +
            "</b>",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, deposit it!",
        })
        .then((result1) => {
          if (result1.isConfirmed) {
            // prompt for pin
            const box1 = Swal.mixin({
              customClass: {
                confirmButton: "btn custom-button bg-danger  mr-2",
                cancelButton: "btn custom-button-2 ml-2",
              },
              buttonsStyling: false,
            });

            box1
              .fire({
                title: "Enter your pin",
                text:
                  "To confirm that you want to deposit " +
                  this.dalasisFormatter(this.customer_deposit_form.amount) +
                  " to " +
                  this.customer_deposit_form.text,
                input: "password",
                inputAttributes: {
                  required: true,
                },
                inputValidator: (value) => {
                  if (!value) {
                    return "You need to enter your pin!";
                  }
                },
                showCancelButton: true,
                confirmButtonText: "Submit",
                showLoaderOnConfirm: true,
              })
              .then((result) => {
                if (result.isConfirmed) {
                  this.customer_deposit_form.pin = result.value;
                  this.$isLoading(true);

                  this.customer_deposit_form
                    .post("api/deposit/depositForCustomer", {
                      headers: {
                        "Content-Type": "application/json",
                      },
                    })
                    .then((data) => {
                      this.$isLoading(false);

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button-2",
                        },
                        buttonsStyling: false,
                      });

                      confirmBox2
                        .fire({
                          icon: "success",
                          title: "Money deposited!",
                        })
                        .then((res) => {
                          window.location.reload();
                        });
                    })
                    .catch((err) => {
                      this.$isLoading(false);

                      // show them if that city name is taken
                      let errorText = "Deposit Unsuccessful";

                      if (err.response.data.errors["pin"]) {
                        errorText = err.response.data.errors["pin"];
                      } else if (err.response.data.errors["amount"]) {
                        errorText = err.response.data.errors["amount"];
                      } else if (err.response.data.errors["general"]) {
                        errorText = err.response.data.errors["general"];
                      }

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button bg-danger",
                        },
                        buttonsStyling: true,
                      });

                      confirmBox2.fire({
                        icon: "error",
                        title: "Failed!",
                        text: errorText,
                      });
                    });
                }
              });
          }
        });
    },
    depositValidation() {
      let disabled =
        this.customer_deposit_form.amount == null ||
        this.customer_deposit_form.amount <= 0 ||
        this.customer_deposit_form.amount > this.broker_balance ||
        this.customer_deposit_form.text == "" ||
        this.customer_deposit_form.id == null ||
        this.customer_deposit_form.branch_id == null;

      return disabled;
    },
  },
};
</script>

