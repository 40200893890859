<template>
    <div>
        <div v-if="$gate.isBroker() && typeof cityInfo == 'object'" class="card  custom-card-1 m-0">
            <fieldset>
                <legend class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center">
                     <span>{{cityInfo.city_name}}</span>

                    </div>
                   <a href="#" class="nav-link" active-class="active" onclick="history.go(-1);return false;">
                       <button class="btn bg-nyanza font-weight-bold text-gray" >Go back</button>
                    </a>
                </legend>
                    <div class="card-header">
                    </div>


            <div>

                <TabNav :tabs="tabs" :selected="selected" @selected="setSelected">
                    <Tab :isSelected="selected === 'General' ">
                        
                        <div class="row mt-5">
                            <div class="col-12">
                                <div class="border-bottom pb-3"><h5>General</h5></div>

                            </div>
                            <div class="col-12 pr-5">
                                <div class="row py-3 border-bottom">
                                    <div class="col-6 ">
                                        City name
                                    </div>
                                    <div class="col-6">
                                        {{cityInfo.city_name}}
                                    </div>
                                </div>

                                <div class="row py-3 border-bottom">
                                    <div class="col-6 ">
                                        Number of Towns
                                    </div>
                                    <div class="col-6">
                                        {{cityInfo.number_of_towns}}
                                        
                                    </div>
                                </div>
                                <div class="row py-3 border-bottom">
                                    <div class="col-6 ">
                                        Total Agencies
                                    </div>
                                    <div class="col-6">
                                        {{cityInfo.total_agencies}}
                                    </div>
                                </div>
                                <div class="row py-3 border-bottom">
                                    <div class="col-6 ">
                                        Total Users
                                    </div>
                                    <div class="col-6">
                                        {{cityInfo.total_users}}
                                    </div>
                                </div>

                                <div class="row py-3 border-bottom">
                                    <div class="col-6 ">
                                        Created on
                                    </div>
                                    <div class="col-6">
                                        {{cityInfo.time_created}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Tab>
                    <Tab :isSelected="selected === 'Towns' ">
                        <div class="row mt-5">
                            <div class="col-12 pr-5">
                                <div class="d-flex justify-content-between flex-wrap border-bottom pb-3">
                                    <div class="d-flex align-items-center">
                                        <h5>Towns</h5>
                                    </div>
                                    <a href='#' class="nav-link"  data-toggle="modal" data-target="#createTownModal">
                                        <button class="btn btn-outline-info">Create Town <i class="fa fa-plus"></i></button>
                                    </a>
                                </div>

                                <div v-for="town in cityInfo.towns" :key='town.id'  
                                class="row py-5 border-bottom">

                                    <div class="col-12 d-flex justify-content-between flex-wrap pb-2">
                                        <h5 class="d-flex align-items-center"><strong>{{town.town_name}}</strong> </h5>

                                        <div class="d-flex justify-content-end flex-wrap">
                                                <a
                                                href='#' @click="openEditModal(town.id, town.town_name)"
                                                class="nav-link" active-class="active" exact>
                                                    <button type="button" class="btn btn-sm btn-info">Edit town  <i class="fa fa-edit"></i></button>

                                                </a>

                                                <a v-if="town.can_delete" href="#" @click='deleteTown(town.id)'
                                                class="nav-link" active-class="active" exact>
                                                    <button type="button" class="btn btn-sm btn-danger">Delete town  <i class="fa fa-trash"></i></button>

                                                </a>
                                        </div>
                                    </div>


                                    <div class="col-12">
                                        <div class="d-flex justify-content-start">
                                            <span class="pr-3">
                                             Agencies:
                                            <span class="ml-3 badge badge-pill badge-dark">{{town.agencies.length}}</span>
                                             </span>
                                            <div class="d-flex justify-content-between flex-wrap">
                                                <span class="pr-3  text-bwgreen" style=""
                                                v-for="agency in town.agencies" :key="agency.id" >
                                                    <router-link v-bind:to="'/view-agency/' + agency.id"
                                                    style="text-decoration:underline">
                                                    {{agency.company_name}}

                                                    </router-link>
                                                </span>
                                            </div>

                                        </div>

                                        <div class="pt-2 d-flex justify-content-start">
                                            <span class="pr-3">Total Users: </span>
                                            <div class="">
                                            <span class="badge badge-pill badge-dark">{{town.total_users}}</span>

                                            </div>

                                        </div>
                                    </div>
                                    

                                    

                                    <!-- list  the agents.. make them clickable links-->
                                </div>


                            </div>

                        </div>
                    </Tab>



                </TabNav>

            </div>



            </fieldset>




            <!-- modals -->
            <div class="modal fade" id="createTownModal" tabindex="-1" role="dialog" aria-labelledby="createTownModalTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <form  @submit.prevent='createTown' method="post">
                    
                            <div class="modal-header border-0">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">

                                <div class="d-flex justify-content-center">
                                    <h2 class="swal2-title" id="exampleModalLongTitle"
                                    data-toggle="modal" data-target="#createTownModal"
                                    >Create a new town</h2>
                                </div>

                                <div class="card-body">
                                    <div class="form-group">

                                        <label for="town_name" >Town Name</label>
                                        <input type="text" required id='town_name' v-model="form.town_name"  placeholder="Enter town name" class="form-control ">


                                    </div>
                                </div>


                            </div>
                            <div class="modal-footer border-0 d-flex justify-content-center">
                                <button :disabled='form.town_name == ""' type="submit" class="btn custom-button-2 ">Create</button>
                            </div>
                        </form>
                    
                    </div>
                </div>
            </div>



            <div class="modal fade" id="editTownModal" tabindex="-1" role="dialog" aria-labelledby="editTownModalTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <form  @submit.prevent='editTown' method="post">
                    
                            <div class="modal-header border-0">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="card-body">
                                    <div class="form-group">

                                        <label for="town_name" >Town Name</label>
                                        <input type="text" required id='town_name' v-model="edit_form.town_name"  placeholder="Enter Town name" class="form-control ">

                                    </div>
                                </div>

                            </div>
                            <div class="modal-footer border-0 d-flex justify-content-center">
                                <button :disabled='edit_form.town_name == ""' type="submit" class="btn custom-button-2 ">Save Changes</button>
                            </div>
                        </form>
                    
                    </div>
                </div>
            </div>



            
        </div>
        <div v-else-if="!$gate.isBroker() || cityInfo == 'error'">
            <not-found></not-found>
            
        </div>
        
    </div>
</template>

<script>

import TabNav from './../includes/tabNav'
import Tab from './../includes/tabs'

export default {
    name: 'City',
    props:{

    },
    components: {
        TabNav,
        Tab,

    },
  data() {
    return {
        selected: 'General',
        logs: [],
        cityInfo: '',
        tabs: [],
        pageData: {},
        form: null,
        edit_form: null,
    }
  },
  created(){
      if(this.$gate.isBroker()){
        this.tabs =  ['General', 'Towns']
        this.form = new Form({
        city_id: '',
        town_name: '',
        password: '',

        })
        this.edit_form = new Form({
        id: '',
        town_name: '',
        password: '',

        })
        this.$isLoading(true) 

        axios.get('/api/location/getCity/' +  this.$route.params.id)
            .then(({data}) => {
            this.$isLoading(false) 
                
                this.cityInfo = data
                this.form.city_id = this.cityInfo.id

            }).catch((err)=>{
            this.$isLoading(false) 

                this.cityInfo = 'error'
        })
    
      }
  },  
    watch: {

        selected: function(){
            
            if(this.selected == 'Logs')
                this.getLogs();


        },

    },
  methods:{
        setSelected(tab){
            this.selected = tab;

        },
        
        createTown(){
            $('#createTownModal').modal('toggle')

            const box1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button-2 mr-2',
                denyButton: 'btn custom-button bg-secondary ml-2'
            },
            buttonsStyling: false
            })

            box1.fire({
                title: 'Enter your password',
                text: 'To confirm that you want to create a town called "' + this.form.town_name + '"',
                input: 'password',
                inputAttributes: {
                    required: true,
                },
                inputValidator: (value) => {
                    if (!value) {
                    return 'You need to enter your password!'
                    }

                },
                showDenyButton: true,
                denyButtonText: `Back`,
                confirmButtonText: 'Submit',
                }).then((result) => {
                if (result.isConfirmed) {

                this.form.password = result.value


                  this.$isLoading(true) 

                this.form.post('/api/location/createTown',{headers:{"Content-Type":"application/json"}})
                    .then((data) => {
                  this.$isLoading(false) 
                        
                       
                        
                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button-2',
                        
                        },
                        buttonsStyling: false
                        })

                        confirmBox2.fire({
                        icon: 'success',
                        title: 'Town Created!',
                        text: '"' + this.form.town_name + '" is now added to the towns in ' + this.cityInfo.city_name,

                        }).then((res)=>{
                            // reloading the page
                        window.location.reload()

                        })



                    })
                    .catch((err) => {
                  this.$isLoading(false) 
                     
                     // show them if that city name is taken
                        let errorText = 'City Creation Unsuccessful'

                        if(err.response.data.errors['password']){
                            errorText = err.response.data.errors['password']
                        }
                        else if(err.response.data.errors['town_name']){
                            errorText = err.response.data.errors['town_name']
                        }

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button bg-danger',
                        
                        },
                        buttonsStyling: true
                        })

                        confirmBox2.fire({
                        icon: 'error',
                        title: 'Failed!',
                        text: errorText,

                        })
                    });




                }
                else if (result.isDenied) {
                                $('#createTownModal').modal('show')

                }
                })

        },


        editTown(){
            $('#editTownModal').modal('toggle')
            let old_town_name = this.cityInfo.towns.filter((c)=> c.id == this.edit_form.id)[0].town_name;

            const box1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button-2 mr-2',
                denyButton: 'btn custom-button bg-secondary ml-2'
            },
            buttonsStyling: false
            })

            box1.fire({
                title: 'Enter your password',
                text: 'To confirm that you want to edit "' + old_town_name + '" to "' + this.edit_form.town_name + '"',
                input: 'password',
                inputAttributes: {
                    required: true,
                },
                inputValidator: (value) => {
                    if (!value) {
                    return 'You need to enter your password!'
                    }

                },
                showDenyButton: true,
                denyButtonText: `Back`,
                confirmButtonText: 'Submit',
                }).then((result) => {
                if (result.isConfirmed) {

                this.edit_form.password = result.value


                  this.$isLoading(true) 

                this.edit_form.post('/api/location/editTown',{headers:{"Content-Type":"application/json"}})
                    .then((data) => {
                  this.$isLoading(false) 
                        
                       
                        
                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button-2',
                        
                        },
                        buttonsStyling: false
                        })

                        confirmBox2.fire({
                        icon: 'success',
                        title: 'Town Updated!',
                        text: '"..' + old_town_name + '" is now updated to ' + this.edit_form.town_name,

                        }).then((res)=>{
                       
                            let i = this.cityInfo.towns.findIndex((c) => c.id == this.edit_form.id);
                            this.cityInfo.towns[i].town_name = this.edit_form.town_name
                        })



                    })
                    .catch((err) => {
                  this.$isLoading(false) 

                        // show them if that city name is taken
                        let errorText = 'Town Editing Unsuccessful'

                        if(err.response.data.errors['password']){
                            errorText = err.response.data.errors['password']
                        }
                        else if(err.response.data.errors['town_name']){
                            errorText = err.response.data.errors['town_name']
                        }

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button bg-danger',
                        
                        },
                        buttonsStyling: true
                        })

                        confirmBox2.fire({
                        icon: 'error',
                        title: 'Failed!',
                        text: errorText,

                        })
                    });




                }
                else if (result.isDenied) {
                                $('#editTownModal').modal('show')

                }
                })

        },


        deleteTown(id){
        
            let town_name = this.cityInfo.towns.filter((c)=> c.id == id)[0].town_name;
            let delete_form =  new Form({
                id: id,
                password: null
            })
            const confirmBox1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button bg-danger mr-2',
                cancelButton: 'btn custom-button-2 ml-2'
            },
            buttonsStyling: false
            })

            confirmBox1.fire({
            title: 'Are you sure?',
            text: 'You will be deleting "' + town_name + '"',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!'
            }).then((result1) => {
            if (result1.isConfirmed) {


                // prompt for pin
            const box1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button bg-danger  mr-2',
                cancelButton: 'btn custom-button-2 ml-2'
            },
            buttonsStyling: false
            })

            box1.fire({
                title: 'Enter your password',
                text: 'To confirm that you want to delete "' + town_name + '"',
                input: 'password',
                inputAttributes: {
                    required: true,
                },
                inputValidator: (value) => {
                    if (!value) {
                    return 'You need to enter your password!'
                    }

                },
                showCancelButton: true,
                confirmButtonText: 'Submit',
                showLoaderOnConfirm: true
                }).then((result) => {
                if (result.isConfirmed) {
                    delete_form.password = result.value
                  this.$isLoading(true) 

                delete_form.post('/api/location/deleteTown', {headers:{"Content-Type":"application/json"}})
                    .then((data) => {
                        
                       
                  this.$isLoading(false) 
                        
                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button-2',
                        
                        },
                        buttonsStyling: false
                        })

                        confirmBox2.fire({
                        icon: 'success',
                        title: 'Town deleted!',

                        }).then((res)=>{
                       
                            // let i = this.cities.findIndex((c) => c.id == this.edit_form.id);
                            // this.cities[i].city_name = this.edit_form.city_name
                        this.cityInfo.towns = [... this.cityInfo.towns.filter((c) => c.id != id)]

                        })



                    })
                    .catch((err) => {
                  this.$isLoading(false) 

                        // show them if that city name is taken
                        let errorText = 'Town Deletion Unsuccessful'

                        if(err.response.data.errors['password']){
                            errorText = err.response.data.errors['password']
                        }
                        else if(err.response.data.errors['general']){
                            errorText = err.response.data.errors['general']
                        }


                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button bg-danger',
                        
                        },
                        buttonsStyling: true
                        })

                        confirmBox2.fire({
                        icon: 'error',
                        title: 'Failed!',
                        text: errorText,

                        })
                    });






                }
                })

            }
            })
        },

        openEditModal(id, town_name){
            this.edit_form.id =  id
            this.edit_form.town_name = town_name
            $('#editTownModal').modal('toggle')

        }

  },

}
</script>