<template>
  <div v-if="$gate.isAgentAdmin()">
    <div v-if="$gate.isPasswordSetAfterLogin()">
      <div>
        <div class="card py-2 px-3" style="box-shadow: none">
          <div class="d-flex font-weight-bold justify-content-center">
            <span class="font-size-3 text-bwgreen font-weight-bold">{{
              companyInfo.company_name
            }}</span>
          </div>
          <!-- /////////// render only if the company type is not a bank  ///// -->
          <div
            v-if="companyInfo.company_type != 3"
            class="d-flex justify-content-between"
          >
            <div class="d-flex font-weight-bold align-items-center">
              <span class="font-size-1">Broker Balance (With Fees)</span>
            </div>
            <div class="d-flex align-items-end">
              <span class="text-color-2 font-size-4 font-weight-bold">
                {{ broker_balance }}
              </span>
            </div>
          </div>

          <div
            v-if="companyInfo.company_type == 3"
            class="d-flex justify-content-between"
          >
            <div class="d-flex font-weight-bold align-items-center">
              <span class="font-size-1">Cash At Bank</span>
            </div>
            <div class="d-flex align-items-end">
              <span class="text-color-2 font-size-4 font-weight-bold">
                {{ cash_at_bank }}
              </span>
            </div>
          </div>

          <div class="d-flex pb-2 justify-content-between">
            <div class="d-flex font-weight-bold align-items-center">
              <span class="font-size-1">Fee earnings</span>
            </div>
            <div class="d-flex align-items-end">
              <!-- if the company type is a bank -->
              <span
                v-if="companyInfo.company_type == 3"
                class="text-brown1 font-size-4 font-weight-bold"
              >
                D {{ companyInfo.fee_earnings }}
              </span>
              <span v-else class="text-brown1 font-size-4 font-weight-bold">
                {{ commission }}
              </span>
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <div class="d-flex font-weight-bold align-items-center">
              <span class="font-size-1">Agent Admin</span>
            </div>
            <div class="d-flex align-items-end">
              <span class="font-size-1 text-bwgreen font-weight-bold">
                {{ agent_admin_name }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <TabNav
        v-if="companyInfo.company_type != 3"
        :tabs="tabs"
        :selected="selected"
        @selected="setSelected"
      >
      </TabNav>

      <div class="mt-3">
        <AdminDashboard
          :branch_id="branch_id"
          :branch_details="branch_details"
        />
      </div>
    </div>
  </div>
  <div v-else>
    <not-found></not-found>
  </div>
</template>




<script>
import TabNav from "./../../includes/tabNav";
import Tab from "./../../includes/tabs";

import AdminDashboard from "./AdminDashboard";

export default {
  name: "ADContainer.vue",
  components: {
    AdminDashboard,
    TabNav,
    Tab,
  },
  data() {
    return {
      selected: "",
      companyInfo: "",
      tabs: [],
      broker_balance: 0,
      cash_at_bank: 0,
      commission: 0,
      branches: [],
      branch_id: null,
      branch_details: null,
      agent_admin_name: "",
    };
  },
  created() {
    if (this.$gate.isAgentAdmin()) this.getBranches();
  },
  watch: {
    selected: function () {
      for (let i = 0; i < this.tabs.length; i++) {
        if (this.selected == this.tabs[i]) {
          this.branch_id = this.branches[i];
          this.branch_details = [...this.companyInfo.admin_branches].filter(
            (b) => b.id == this.branch_id
          )[0];
          // console.log(this.companyInfo.admin_branches.filter((b)=> b.id == this.branch_id))
          break;
        }
      }
    },
  },

  mounted() {},
  computed: {},
  methods: {
    getBranches() {
      axios
        .get("/api/agency")
        .then(({ data }) => {
          this.companyInfo = data;
          console.log("companyInfos ", this.companyInfo);
          this.agent_admin_name =
            data.admin_branches[0]["first_name"] +
            " " +
            data.admin_branches[0]["last_name"];
          this.companyInfo.admin_branches.forEach((element) => {
            this.tabs.push(element.town_name + " - Branch");
            this.branches.push(element.id);
          });

          this.branch_details = { ...this.companyInfo.admin_branches[0] };
          this.selected = this.tabs[0];
          this.broker_balance = this.dalasisFormatter(data.broker_balance);
          this.cash_at_bank = this.dalasisFormatter(data.cash_at_bank);
          this.commission = this.dalasisFormatter(data.commission);

          this.$isLoading(false);
        })
        .catch((err) => {
          console.log(err);
          this.companyInfo = "error";
          this.$isLoading(false);
        });
    },
    setSelected(tab) {
      this.selected = tab;
    },
  },
};
</script>
