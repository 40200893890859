import Vue from "vue";
import VueRouter from "vue-router";
let injected = false;

Vue.use(VueRouter);
const routes = [
    // should work
    // AGENT
    {
        path: "/agent-dashboard",
        component: require("./components/agent/AgentDashboard.vue").default,
    },
    {
        path: "/new-transaction",
        component: require("./components/agent/NewTransaction.vue").default,
    },
    {
        path: "/edit-agent-profile",
        component: require("./components/agent/EditProfile.vue").default,
    },
    {
        path: "/agent-profile",
        component: require("./components/agent/Profile.vue").default,
    },

    // AGENT ADMIN
    {
        path: "/agent-admin-dashboard",
        component: require("./components/agent/admin/ADContainer.vue").default,
    },
    {
        path: "/agents",
        component: require("./components/agency/Agents.vue").default,
    },

    {
        path: "/agency-profile",
        component: require("./components/agency/AgencyProfile.vue").default,
    },
    {
        path: "/reconciliation-report",
        component: require("./components/agency/ReconciliationReport.vue").default,
    },
    {
        path: "/reconciliation-report-data",
        name: 'reconciliation-report-data',
        component: require("./components/agency/ReconciliationReportData.vue").default,
        props: true
    },

    { path: '/create-agent', component: require('./components/agency/CreateAgent.vue').default },
    { path: '/view-agent/:id', component: require('./components/agency/ViewAgent.vue').default },

    { path: '/outgoing-agency-transactions', component: require('./components/agency/OutgoingAgencyTransactions.vue').default },
    { path: '/incoming-agency-transactions', component: require('./components/agency/IncomingAgencyTransactions.vue').default },
    { path: '/cancelled-agency-transactions', component: require('./components/agency/CancelledAgencyTransactions.vue').default },
    { path: '/collected-agency-transactions', component: require('./components/agency/CollectedAgencyTransactions.vue').default },

    {
        path: "/outgoing-agency-transactions",
        component: require("./components/agency/OutgoingAgencyTransactions.vue")
            .default,
    },
    {
        path: "/incoming-agency-transactions",
        component: require("./components/agency/IncomingAgencyTransactions.vue")
            .default,
    },
    {
        path: "/cancelled-agency-transactions",
        component:
            require("./components/agency/CancelledAgencyTransactions.vue")
                .default,
    },
    {
        path: "/collected-agency-transactions",
        component:
            require("./components/agency/CollectedAgencyTransactions.vue")
                .default,
    },

    // Fee Tables
    {
        path: "/fee-table",
        component: require("./components/feetables/TransactionFeeTable.vue")
            .default,
    },
    {
        path: "/configure-fee-table",
        component:
            require("./components/feetables/ConfigureTransactionFeeTable.vue")
                .default,
    },

    {
        path: "/withdraw-fee-table",
        component: require("./components/feetables/WithdrawFeeTable.vue")
            .default,
    },
    {
        path: "/configure-withdraw-fee-table",
        component:
            require("./components/feetables/ConfigureWithdrawFeeTable.vue")
                .default,
    },

    {
        path: "/p2p-fee-table",
        component: require("./components/feetables/P2PFeeTable.vue").default,
    },
    {
        path: "/configure-p2p-fee-table",
        component: require("./components/feetables/ConfigureP2PFeeTable.vue")
            .default,
    },


    {
        path: "/agent-deposit-payment",
        component: require("./components/feetables/AgentDepositPaymentTable.vue").default,
    },
    {
        path: "/configure-agent-deposit-payment",
        component: require("./components/feetables/ConfigureAgentDepositPayment.vue")
            .default,
    },
    {
        path: "/free-money",
        component: require("./components/feetables/FreeMoneyTable.vue")
            .default,
    },
    {
        path: "/configure-free-money",
        component:
            require("./components/feetables/ConfigureFreeMoneyTable.vue")
                .default,
    },
    // /free-money

    // cashpower free fee configure

    {
        path: "/cashpower-free-fee",
        component: require("./components/feetables/ConfigureCashPowerFreeFee.vue")
            .default,
    },


    // GENERAL
    {
        path: "/transaction-types",
        component:
            require("./components/transaction_types_defaults/TransactionTypes.vue")
                .default,
    },
    {
        path: "/create-transaction-type",
        component:
            require("./components/transaction_types_defaults/CreateTransactionType.vue")
                .default,
    },
    {
        path: "/transaction-defaults",
        component:
            require("./components/transaction_types_defaults/TransactionDefaults.vue")
                .default,
    },

    { path: '/view-user-profile/:id', component: require('./components/broker/customers/ViewCustomerProfile.vue').default },


    /////// this is to view the users who register through the promo code selected
    { path: '/view-users/:promocode', component: require("./components/broker/customers/Customers.vue").default },


    {
        path: "/view-user-profile/:id",
        component:
            require("./components/broker/customers/ViewCustomerProfile.vue")
                .default,
    },

    { path: '/view-profile/:id', component: require('./components/general/ViewProfile.vue').default },
    { path: '/withdraw/:type', component: require('./components/general/Withdraw.vue').default },

    // { path: '/support', component: require('./components/general/Support.vue').default },

    // NAWEC
    {
        path: "/nawec-meters",
        component: require("./components/nawec/Meters.vue").default,
    },

    {
        path: "/nawec-transactions",
        component: require("./components/nawec/NawecTransactionLogs.vue").default,
        name: 'nawectTransactionsLogs',
    },

    // Airtime
    {
        path: "/gsm-operators",
        component: require("./components/airtime/GsmOperators.vue").default,
    },

    {
        path: "/buy-airtime",
        component: require("./components/airtime/BuyAirtime.vue").default,
        name: 'buyAirtime',
    },


    // TRANSACTIONS
    {
        path: "/outgoing-transactions",
        component: require("./components/transactions/OutgoingTransactions.vue")
            .default,
    },
    {
        path: "/incoming-transactions",
        component: require("./components/transactions/IncomingTransactions.vue")
            .default,
    },
    {
        path: "/cancelled-transactions",
        component:
            require("./components/transactions/CancelledTransactions.vue")
                .default,
    },
    {
        path: "/collected-transactions",
        component:
            require("./components/transactions/CollectedTransactions.vue")
                .default,
    },

    // DEPOSITS
    {
        path: "/outgoing-deposits",
        component: require("./components/deposits/OutgoingDeposits.vue")
            .default,
    },
    {
        path: "/cancelled-deposits",
        component: require("./components/deposits/CancelledDeposits.vue")
            .default,
    },
    {
        path: "/completed-deposits",
        component: require("./components/deposits/CompletedDeposits.vue")
            .default,
    },

    // WITHDRAWALS
    {
        path: "/incoming-requests",
        component: require("./components/withdrawals/IncomingRequests.vue")
            .default,
    },
    {
        path: "/cancelled-withdrawals",
        component: require("./components/withdrawals/CancelledWithdrawals.vue")
            .default,
    },
    {
        path: "/completed-withdrawals",
        component: require("./components/withdrawals/CompletedWithdrawals.vue")
            .default,
    },

    //  REGISTRATION
    // { path: '/complete-registration', component: require('./components/registrations/CompleteRegistration.vue').default },
    {
        path: "/set-pin",
        component: require("./components/registrations/SetPin.vue").default,
    },
    {
        path: "/set-password",
        component: require("./components/registrations/SetPassword.vue").default,
    },
    {
        path: "/mrz-registration",
        component: require("./components/registrations/MrzRegistration.vue")
            .default,
    },

    // BROKER
    {
        path: "/broker-dashboard",
        component: require("./components/broker/BrokerDashboard.vue").default,
    },
    {
        path: "/agencies",
        component: require("./components/broker/Agencies.vue").default,
    },
    {
        path: "/view-agency/:id",
        component: require("./components/broker/agencies/ViewAgency.vue")
            .default,
    },

    {
        path: "/create-agency",
        component: require("./components/broker/actions/CreateAgency.vue")
            .default,
    },
    {
        path: "/create-agent-admin/:id",
        component: require("./components/broker/actions/CreateAgentAdmin.vue")
            .default,
    },

    {
        path: "/create-bank-admin/:id",
        component: require("./components/broker/actions/CreateBankAdmin.vue")
            .default,
    },

    {
        path: "/create-branch-agent/:id",
        component: require("./components/broker/actions/CreateBranchAgent.vue")
            .default,
    },
    {
        path: "/create-branch/:id",
        component: require("./components/broker/actions/CreateBranch.vue")
            .default,
    },
    {
        path: "/edit-branch-agent/:id/:agencyId",
        component: require("./components/broker/actions/EditBranch.vue")
            .default,
    },
    {
        path: "/broker-merchant-stores",
        component: require("./components/broker/merchants/MerchantStores.vue")
            .default,
    },
    {
        path: "/create-merchant-store",
        component:
            require("./components/broker/merchants/actions/CreateMerchantStore.vue")
                .default,
    },
    {
        path: "/edit-store-location-merchant/:id/:locationId",
        component: require("./components/merchant/admin/EditStoreLocation.vue")
            .default,
    },

    {
        path: "/broker-agents/:companyType",
        component: require("./components/broker/agencies/Agents.vue").default,
    },
    {
        path: "/broker-agent-admins/:companyType",
        component: require("./components/broker/agencies/AgentAdmins.vue")
            .default,
    },

    {
        path: "/view-agent-profile/:id",
        component: require("./components/broker/agencies/ViewAgentProfile.vue")
            .default,
    },

    {
        path: "/adverts",
        component: require("./components/broker/Adverts.vue").default,
    },
    {
        path: "/create-adverts",
        component: require("./components/broker/adverts/newAdvert.vue").default,
    },

    //////////  financial institutions routes
    {
        path: "/banks",
        component: require("./components/broker/Banks.vue").default,
    },
    {
        path: "/create-bank",
        component: require("./components/broker/actions/CreateBank.vue")
            .default,
    },

    {
        path: "/view-bank/:id",
        component: require("./components/broker/agencies/ViewFinancialInstitution.vue")
            .default,
    },
    // broker-bank-agent-admins

    // BROKER - Customers
    {
        path: "/broker-customers",
        component: require("./components/broker/customers/Customers.vue")
            .default,
    },
    {
        path: "/broker-customers-search",
        component: require("./components/broker/customers/CustomersSearch.vue")
            .default,
    },

    {
        path: "/broker-all-customer-transfers",
        component:
            require("./components/broker/customers/AllCustomersTransfers.vue")
                .default,
    },

    {
        path: "/broker-pending-agency-transactions",
        component:
            require("./components/broker/agencies/all/PendingAgencyTransactions.vue")
                .default,
    },
    {
        path: "/broker-collected-agency-transactions",
        component:
            require("./components/broker/agencies/all/CollectedAgencyTransactions.vue")
                .default,
    },
    {
        path: "/broker-search-transactions",
        component:
            require("./components/broker/SearchTransactions.vue")
                .default,
    },
    // broker-search-transactions
    {
        path: "/broker-cancelled-agency-transactions",
        component:
            require("./components/broker/agencies/all/CancelledAgencyTransactions.vue")
                .default,
    },

    {
        path: "/broker-pending-agency-deposits",
        component:
            require("./components/broker/agencies/all/PendingAgencyDeposits.vue")
                .default,
    },
    {
        path: "/broker-completed-agency-deposits",
        component:
            require("./components/broker/agencies/all/CompletedAgencyDeposits.vue")
                .default,
    },
    {
        path: "/broker-cancelled-agency-deposits",
        component:
            require("./components/broker/agencies/all/CancelledAgencyDeposits.vue")
                .default,
    },

    {
        path: "/broker-pending-agency-withdrawals",
        component:
            require("./components/broker/agencies/all/PendingAgencyWithdrawals.vue")
                .default,
    },
    {
        path: "/broker-completed-agency-withdrawals",
        component:
            require("./components/broker/agencies/all/CompletedAgencyWithdrawals.vue")
                .default,
    },
    {
        path: "/broker-cancelled-agency-withdrawals",
        component:
            require("./components/broker/agencies/all/CancelledAgencyWithdrawals.vue")
                .default,
    },

    {
        path: "/cities",
        component: require("./components/locations/Cities.vue").default,
    },
    {
        path: "/view-city/:id",
        component: require("./components/locations/ViewCity.vue").default,
    },

    // BROKER - Merchant
    {
        path: "/view-merchant-store/:id",
        component:
            require("./components/broker/merchants/ViewMerchantStore.vue")
                .default,
    },
    {
        path: "/broker-merchants-admins",
        component: require("./components/broker/merchants/MerchantAdmins.vue")
            .default,
    },

    {
        path: "/broker-merchants",
        component: require("./components/broker/merchants/Merchants.vue")
            .default,
    },

    //BORKER CASH DISTRIBUTORS
    { path: '/broker-cash-distributors', component: require('./components/broker/distributors/Distributors.vue').default },
    { path: '/create-cash-distributors', component: require('./components/broker/distributors/CreateDistributor.vue').default },
    { path: '/view-cash-distributors/:id', component: require('./components/broker/distributors/ViewDistributor.vue').default },

    //CAMPAIGN
    { path: '/create-campaign/:id', component: require('./components/broker/distributors/CreateCampaign.vue').default },
    { path: '/view-campaign/:id', component: require('./components/broker/distributors/ViewCampaign.vue').default },

    //BROKER KASHLOCK
    { path: '/broker-kashlock-dashboard', component: require('./components/broker/kashlock/BrokerKashlockDashboard.vue').default },
    { path: '/broker-kashlock-settings', component: require('./components/broker/kashlock/KashlockSettings.vue').default },
    { path: '/broker-kashlocks', component: require('./components/broker/kashlock/BrokerKashlocks.vue').default },
    { path: '/broker-kashlocks-view/:id', component: require('./components/broker/kashlock/BrokerViewKashlock.vue').default },
    { path: '/broker-kashlock-finacial-institutes', component: require('./components/broker/kashlock/finacialInstitute/FinacialInstitute.vue').default },
    { path: '/create-finacial-institutes', component: require('./components/broker/kashlock/finacialInstitute/AddInstitute.vue').default },
    { path: '/edit-finacial-institutes/:id', component: require('./components/broker/kashlock/finacialInstitute/EditInstitute.vue').default },

    { path: '/broker-kashlock-investments-dashboard', component: require('./components/broker/kashlock/investment/InvestmentDashboard.vue').default },
    { path: '/broker-kashlock-investments', component: require('./components/broker/kashlock/investment/Investment.vue').default },
    { path: '/create-kashlock-investment', component: require('./components/broker/kashlock/investment/AddInvestment.vue').default },
    { path: '/edit-kashlock-investment/:id', component: require('./components/broker/kashlock/investment/EditInvestment.vue').default },


    //BORKER PRODUCTS
    { path: '/broker-products', component: require('./components/broker/products/Products.vue').default },
    { path: '/create-product', component: require('./components/broker/products/CreateProduct.vue').default },
    { path: '/edit-product/:id', component: require('./components/broker/products/EditProduct.vue').default },

    { path: '/broker-payments', component: require('./components/broker/stripe_payments/Payments.vue').default },
    { path: '/view-payment/:id', component: require('./components/broker/stripe_payments/ViewPayment.vue').default },

    //BORKER CURRENCY
    { path: '/broker-currencies', component: require('./components/broker/currency/Currencies.vue').default },
    { path: '/create-currency', component: require('./components/broker/currency/CreateCurrency.vue').default },
    { path: '/edit-currency/:id', component: require('./components/broker/currency/EditCurrency.vue').default },


    //BORKER CASH Collector
    { path: '/broker-cash-collectors', component: require('./components/broker/collectors/Collectors.vue').default },
    { path: '/create-cash-collectors', component: require('./components/broker/collectors/CreateCollector.vue').default },
    { path: '/view-cash-collectors/:id', component: require('./components/broker/collectors/ViewCollector.vue').default },
    { path: '/view-collector-campaign/:id', component: require('./components/broker/collectors/ViewCampaign.vue').default },

    { path: '/broker-cash-collectors-requests', component: require('./components/broker/collectors/AllRequests.vue').default },
    // CUSTOMER
    {
        path: "/dashboard",
        component: require("./components/customer/Dashboard.vue").default,
    },

    {
        path: "/my-deposits",
        component: require("./components/customer/Deposits.vue").default,
    },
    {
        path: "/my-transfers",
        component: require("./components/general/Transfers.vue").default,
    },
    {
        path: "/my-order-transfers",
        component: require("./components/general/OrderTransaction.vue").default,
    },
    {
        path: "/my-withdrawals",
        component: require("./components/general/Withdrawals.vue").default,
    },

    {
        path: "/my-requests",
        component: require("./components/general/Requests.vue").default,
    },


    {
        path: "/my-bank-accounts",
        component: require("./components/customer/BankAccounts.vue").default,
    },
    {
        path: "/my-profile",
        component: require("./components/customer/Profile.vue").default,
    },

    {
        path: "/add-deposits/:currency",
        component: require("./components/customer/payment/Products.vue").default,
    },
    {
        path: "/payment-form/:id",
        component: require("./components/customer/payment/PaymentForm.vue").default,
    },

    {
        path: "/card-deposits",
        component: require("./components/customer/CardDeposits.vue").default,
    },

    {
        path: "/kashlocks",
        component: require("./components/customer/kashlock/Kashlocks.vue").default,
    },

    {
        path: "/kashlock-create",
        component: require("./components/customer/kashlock/CreateKashlock.vue").default,
    },

    {
        path: "/kashlock-view/:id",
        component: require("./components/customer/kashlock/ViewKashlock.vue").default,
    },

    {
        path: "/kashlock-credit/:id",
        component: require("./components/customer/kashlock/CreditKashlock.vue").default,
    },

    // MERCHANT ADMIN
    {
        path: "/merchant-dashboard",
        component: require("./components/merchant/MerchantDashboard.vue")
            .default,
    },
    {
        path: "/merchant-admin-dashboard",
        component: require("./components/merchant/admin/AdminDashboard.vue")
            .default,
    },
    {
        path: "/merchant-deposits",
        component: require("./components/merchant/Deposits.vue").default,
    },

    {
        path: "/store-profile",
        component: require("./components/merchant/StoreProfile.vue").default,
    },
    {
        path: "/merchants",
        component: require("./components/merchant/admin/Merchants.vue").default,
    },
    {
        path: "/create-merchant",
        component: require("./components/merchant/admin/CreateMerchant.vue")
            .default,
    },



    {
        path: "/create-store-location/:id",
        component: require("./components/merchant/admin/CreateStoreLocation.vue")
            .default,
    },

    {
        path: "/create-store-location-merchant/:id",
        component: require("./components/merchant/admin/CreateStoreLocationMerchant.vue")
            .default,
    },

    {
        path: "/create-merchant-admin/:id",
        component: require("./components/merchant/admin/CreateMerchantAdmin.vue")
            .default,
    },

    {
        path: "/view-merchant-profile/:id",
        component:
            require("./components/merchant/admin/ViewMerchantProfile.vue")
                .default,
    },

    {
        path: "/api-keys",
        component: require("./components/merchant/ApiKeys.vue").default,
    },
    {
        path: "/api-keys/docs",
        component: require("./components/merchant/ApiKeysDoc.vue").default,
    },
    
    {
        path: "/order/transactions",
        component: require("./components/merchant/order/OrderTransactions.vue")
            .default,
    },
    {
        path: "/order/logs/:id",
        component: require("./components/merchant/order/OrderLogs.vue").default,
    },




    {
        path: "/deposit-api-keys",
        component: require("./components/merchant/DepositApiKeys.vue").default,
    },
    {
        path: "/deposit-api-keys/docs",
        component: require("./components/merchant/DepositApiKeysDoc.vue").default,
    },
    {
        path: "/third-party-deposit/transactions",
        component: require("./components/merchant/deposit/DepositTransactions.vue")
            .default,
    },
    {
        path: "/third-party-deposit/logs/:id",
        component: require("./components/merchant/deposit/DepositLogs.vue").default,
    },

    // Settings
    {
        path: "/settings",
        component: require("./components/settings/Settings.vue").default,
    },
    {
        path: "/change-password",
        component: require("./components/settings/ChangePassword.vue").default,
    },
    {
        path: "/change-pin",
        component: require("./components/settings/ChangePin.vue").default,
    },
    {
        path: "/reset-pin",
        component: require("./components/settings/ResetPin.vue").default,
    },
    {
        path: "/change-username",
        component: require("./components/settings/ChangeUserName.vue").default,
    },
    {
        path: "/change-userAddress",
        component: require("./components/settings/ChangeUserAddress.vue").default,
    },





    // Cash distributor
    { path: '/distributor-dashboard', component: require('./components/distributor/DistributorDashboard.vue').default },
    { path: '/distributor-new-transaction', component: require('./components/distributor/NewTransaction.vue').default },
    { path: '/edit-distributor-profile', component: require('./components/distributor/EditProfile.vue').default },
    { path: '/distributor-profile', component: require('./components/distributor/Profile.vue').default },



    // Campaigns
    //CAMPAIGN
    { path: '/distributor-create-campaign', component: require('./components/distributor/CreateCampaign.vue').default },



    { path: '/distributor-campaigns', component: require('./components/distributor/Campaigns.vue').default },
    { path: '/add-beneficiaries/:id', component: require('./components/distributor/AddBeneficiaries.vue').default },

    // DEPOSITS DISTRIBUTOR
    { path: '/distributor-outgoing-deposits', component: require('./components/broker/distributors/deposits/OutgoingDeposits.vue').default },
    { path: '/distributor-cancelled-deposits', component: require('./components/broker/distributors/deposits/CancelledDeposits.vue').default },
    { path: '/distributor-completed-deposits', component: require('./components/broker/distributors/deposits/CompletedDeposits.vue').default },



    // Cash collector
    { path: '/collector-dashboard', component: require('./components/collector/CollectorDashboard.vue').default },
    { path: '/edit-collector-profile', component: require('./components/collector/EditProfile.vue').default },
    { path: '/collector-profile', component: require('./components/collector/Profile.vue').default },
    { path: '/collector-create-campaign', component: require('./components/collector/CreateCampaign.vue').default },
    { path: '/collector-campaigns', component: require('./components/collector/Campaigns.vue').default },
    { path: '/add-individuals/:id/:period', component: require('./components/collector/AddIndividuals.vue').default },
    { path: '/collector-campaign-users/:id', component: require('./components/collector/CollectorCampaignUsers.vue').default },

    { path: '/collector-withdraws', component: require('./components/collector/withdraws.vue').default },

    // WITHDRAWALS
    {
        path: "/collector-incoming-withdrawls",
        component: require("./components/broker/collectors/withdrawals/PendingWithdrawls.vue")
            .default,
    },
    {
        path: "/collector-cancelled-withdrawals",
        component: require("./components/broker/collectors/withdrawals/CancelledWithdrawals.vue")
            .default,
    },
    {
        path: "/collector-completed-withdrawals",
        component: require("./components/broker/collectors/withdrawals/CompletedWithdrawals.vue")
            .default,
    },


    { path: '/bank-deposite', component: require('./components/general/BankDeposite.vue').default },
    { path: '/save-bank', component: require('./components/general/SaveBankAccount.vue').default },

    { path: '/broker-bank-fee', component: require('./components/feetables/BankFee.vue').default },



    //bank admin
    { path: '/bank-admin-dashboard', component: require('./components/bank-admin/BankAdminDashboard.vue').default },
    { path: '/bank-admin/banks', component: require('./components/bank-admin/banks/Banks.vue').default },
    { path: '/bank-admin-create-bank', component: require('./components/bank-admin/banks/CreateBank.vue').default },
    { path: '/bank-admin-edit-bank/:id', component: require('./components/bank-admin/banks/EditBank.vue').default },
    { path: '/bank-admin-view-bank/:id', component: require('./components/bank-admin/banks/ViewBank.vue').default },
];




export default new VueRouter({
    mode: "history",
    routes,
});
