<template>
  <div>
    <div
      v-if="$gate.isBroker() && typeof companyInfo == 'object'"
      class="card custom-card-1 m-0"
    >
      <fieldset>
        <legend class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center">
            <span> {{ companyInfo.company_name }}</span>
          </div>
          <a
            href="#"
            class="nav-link"
            active-class="active"
            onclick="history.go(-1);return false;"
          >
            <button class="btn bg-nyanza font-weight-bold text-gray">
              Go back
            </button>
          </a>
        </legend>
        <div class="card-header"></div>
        <div>
          <TabNav :tabs="tabs" :selected="selected" @selected="setSelected">
            <Tab :isSelected="selected === 'General'">
              <div class="row mt-5">
                <div class="col-12">
                  <div
                    class="
                      d-flex
                      justify-content-between
                      flex-wrap
                      border-bottom
                      pb-3
                    "
                  >
                    <div class="d-flex align-items-center">
                      <h5>General</h5>
                    </div>
                    <div class="d-flex justify-content-end flex-wrap">
                      <a
                        href="#"
                        class="nav-link"
                        data-toggle="modal"
                        data-target="#editAgencyModal"
                      >
                        <button class="btn btn-outline-info">
                          Edit <i class="fa fa-edit"></i>
                        </button>
                      </a>
                      <a
                        href="#"
                        class="nav-link"
                        data-toggle="modal"
                        data-target="#depositModal"
                      >
                        <button class="btn btn-outline-bgreen">
                          Deposit Broker Balance <i class="fa fa-plus"></i>
                        </button>
                      </a>

                      <a
                        href="#"
                        class="nav-link"
                        data-toggle="modal"
                        data-target="#withdrawModal"
                      >
                        <button class="btn btn-outline-bgreen">
                          Withdraw Broker Balance <i class="fa fa-plus"></i>
                        </button>
                      </a>

                      <!-- set the negative broker balance limit -->
                      <a
                        href="#"
                        class="nav-link"
                        data-toggle="modal"
                        data-target="#negativeBrokerBalanceLimit"
                      >
                        <button class="btn btn-outline-bgreen">
                          Negative Broker Balance Limit
                          <i class="fa fa-plus"></i>
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-8 pr-5">
                  <div class="border-bottom font-weight-bold py-3">Profile</div>
                  <div class="row py-3 border-bottom">
                    <div class="col-6">Company name</div>
                    <div class="col-6">
                      {{ companyInfo.company_name }}
                    </div>
                  </div>

                  <div class="row py-3 border-bottom">
                    <div class="col-6">Broker Balance</div>
                    <div class="col-6">
                      {{ dalasisFormatter(companyInfo.broker_balance) }}
                    </div>
                  </div>
                  <div class="row py-3 border-bottom">
                    <div class="col-6">Cash on hand</div>
                    <div class="col-6">
                      {{ dalasisFormatter(companyInfo.cash_on_hand) }}
                    </div>
                  </div>
                  <div class="row py-3 border-bottom">
                    <div class="col-6">Fee earnings (Money Transfer)</div>
                    <div class="col-6">
                      {{ dalasisFormatter(companyInfo.commission) }}
                    </div>
                  </div>
                  <div class="row py-3 border-bottom">
                    <div class="col-6">Transaction Fee cut</div>
                    <div class="col-6">{{ companyInfo.fee_cut_percent }}%</div>
                  </div>
                  <div class="row py-3 border-bottom">
                    <div class="col-6">Withdraw Fee cut</div>
                    <div class="col-6">
                      {{ companyInfo.withdraw_fee_cut_percent }}%
                    </div>
                  </div>
                  <div class="row py-3 border-bottom">
                    <div class="col-6">Branches</div>
                    <div class="col-6">
                      <div
                        v-for="branch in companyInfo.branches"
                        :key="branch.id"
                      >
                        {{ branch.town_name }}
                      </div>
                    </div>
                  </div>

                  <div class="row py-3 border-bottom">
                    <div class="col-6">Total Agent Admins</div>
                    <div class="col-6">
                      {{ companyInfo.total_agent_admins }}
                    </div>
                  </div>
                  <div class="row py-3 border-bottom">
                    <div class="col-6">Total Agents</div>
                    <div class="col-6">
                      {{ companyInfo.total_agents }}
                    </div>
                  </div>
                  <div class="row py-3 border-bottom">
                    <div class="col-6">Registered on</div>
                    <div class="col-6">
                      {{ companyInfo.time_created }}
                    </div>
                  </div>

                  <div class="row py-3 border-bottom">
                    <div class="col-6">Negative Balance Limit</div>
                    <div class="col-6">
                      {{ brokerBalanceNegativeLimit }}
                      <!-- {{companyInfo.time_created}} -->
                    </div>
                  </div>
                </div>

                <div class="col-4">
                  <div class="border-bottom font-weight-bold py-3 mb-3">
                    Avatar
                  </div>
                  <div class="image">
                    <img
                      class="img-fluid pad"
                      src="/images/profiles/building-none.png"
                      alt=""
                    />
                  </div>
                  <!-- <div v-if="canEdit" class="btn btn-info w-100">
                                        Change profile picture
                                    </div> -->
                </div>
              </div>
            </Tab>
            <Tab :isSelected="selected === 'Branches'">
              <div class="row mt-5">
                <div class="col-12 pr-5">
                  <div
                    class="
                      d-flex
                      justify-content-between
                      flex-wrap
                      border-bottom
                      pb-3
                    "
                  >
                    <div class="d-flex align-items-center">
                      <h5>Branches</h5>
                    </div>
                    <router-link
                      v-bind:to="'/create-branch/' + companyInfo.id"
                      href="#"
                      class="nav-link"
                    >
                      <button class="btn btn-outline-info">
                        Create Branches <i class="fa fa-plus"></i>
                      </button>
                    </router-link>
                  </div>

                  <div
                    v-for="branch in companyInfo.branches"
                    :key="branch.id"
                    class="row py-5 border-bottom"
                  >
                    <div
                      class="
                        col-12
                        d-flex
                        justify-content-between
                        flex-wrap
                        pb-2
                      "
                    >
                      <h5 class="d-flex align-items-center">
                        <strong>{{ branch.town_name }}</strong>
                      </h5>

                      <div class="d-flex justify-content-end flex-wrap">
                        <a
                          href="#"
                          class="nav-link"
                          v-if="
                            branch.agent_admin_id == null &&
                            companyInfo.agent_admins.length > 0
                          "
                          @click="
                            openModal(branch.id, 'createAgentAdmin', null)
                          "
                        >
                          <button type="button" class="btn btn-sm btn-dark">
                            Create Agent Admin
                          </button>
                        </a>

                        <router-link
                          v-if="
                            branch.agent_admin_id == null &&
                            companyInfo.agent_admins.length == 0
                          "
                          v-bind:to="'/create-agent-admin/' + branch.id"
                          class="nav-link border-right"
                          active-class="active"
                          exact
                        >
                          <button
                            type="button"
                            class="btn btn-sm btn-secondary"
                          >
                            Create Agent Admin <i class="fa fa-plus"></i>
                          </button>
                        </router-link>

                        <router-link
                          v-if="branch.agent_admin_id != null"
                          v-bind:to="'/create-branch-agent/' + branch.id"
                          class="nav-link border-right"
                          active-class="active"
                          exact
                        >
                          <button
                            type="button"
                            class="btn btn-sm btn-outline-secondary"
                          >
                            Create Agent <i class="fa fa-plus"></i>
                          </button>
                        </router-link>
                        <!-- <router-link
                                                    v-bind:to="{name: 'EditBranch', params: {id: branch.id}} "
                                                    class="nav-link" active-class="active" exact>
                                                        <button type="button" class="btn btn-sm btn-info">Edit Branch  <i class="fa fa-edit"></i></button>

                                                    </router-link> -->
                        <router-link
                          v-bind:to="
                            '/edit-branch-agent/' +
                            branch.id +
                            '/' +
                            companyInfo.id
                          "
                          class="nav-link"
                          active-class="active"
                          exact
                        >
                          <button type="button" class="btn btn-sm btn-info">
                            Edit Branch <i class="fa fa-edit"></i>
                          </button>
                        </router-link>

                        <button
                          v-if="!branch.is_blocked"
                          @click="blockBranch(branch.id)"
                          class="btn custom-button mb-4 btn-danger"
                        >
                          Deactivate Branch
                          <i class="fas fa-ban"></i>
                        </button>

                        <button
                          v-else
                          @click="unblockBranch(branch.id)"
                          class="btn custom-button mb-4 btn-danger"
                        >
                          Activate Branch <i class="fas fa-building"></i>
                        </button>
                      </div>
                    </div>

                    <div class="col-6">
                      <div class="d-flex justify-content-start">
                        <span class="d-flex align-items-center"
                          >Agent Admin:
                        </span>
                        <div
                          class="mx-3 text-bwgreen font-weight-bold"
                          v-if="branch.agent_admin_id != null"
                        >
                          <router-link
                            v-bind:to="
                              '/view-agent-profile/' + branch.agent_admin_id
                            "
                          >
                            {{ branch.first_name }} {{ branch.last_name }}
                          </router-link>
                          <button
                            type="button"
                            @click="
                              openModal(
                                branch.id,
                                'changeAgentAdmin',
                                branch.agent_admin_id
                              )
                            "
                            class="btn btn-sm btn-info"
                          >
                            Change <i class="fa fa-edit"></i>
                          </button>
                        </div>
                      </div>
                      <div class="pt-2 d-flex justify-content-start">
                        <span class="pr-3">Active Agents: </span>
                        <div class="d-flex justify-content-between flex-wrap">
                          <span
                            class="pr-3 text-bwgreen"
                            style=""
                            v-for="agent in branch.active_agents"
                            :key="agent.id"
                          >
                            <router-link
                              v-bind:to="'/view-agent-profile/' + agent.id"
                            >
                              {{ agent.first_name }} {{ agent.last_name }}
                            </router-link>
                          </span>
                        </div>
                      </div>
                      <div class="pt-2 d-flex justify-content-start">
                        <span class="">Phone numbers: </span>
                        <div>
                          <ul style="list-style-type: none">
                            <li v-for="ph in branch.phone_numbers" :key="ph.id">
                              <span>-</span> <span>{{ ph.phone_number }} </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div class="col-6">
                      <div class="d-flex justify-content-end">
                        <span class="d-flex align-items-center"
                          >Cash on Hand:
                        </span>
                        <div class="mx-3 font-weight-bold text-navy">
                          <span>{{
                            dalasisFormatter(branch.cash_on_hand)
                          }}</span>
                        </div>
                      </div>
                      <div class="pt-2 d-flex justify-content-end">
                        <span class="pr-3">Fee earnings: </span>
                        <div class="d-flex justify-content-between flex-wrap">
                          <span class="mr-3 font-weight-bold text-navy">
                            {{ dalasisFormatter(branch.fee_earnings) }}
                          </span>
                        </div>
                      </div>
                    </div>

                    <!-- list  the agents.. make them clickable links-->
                  </div>
                </div>
              </div>
            </Tab>
            <Tab :isSelected="selected === 'Transactions'">
              <AgencyTransactions :company_id="companyInfo.id" />
            </Tab>
            <Tab :isSelected="selected === 'Deposits'">
              <AgencyDeposits :company_id="companyInfo.id" />
            </Tab>
            <Tab :isSelected="selected === 'Withdrawals'">
              <AgencyWithdrawals :company_id="companyInfo.id" />
            </Tab>

            <Tab :isSelected="selected === 'Bank Accounts'"> bban </Tab>

            <Tab :isSelected="selected === 'Logs'">
              <AgencyDepositWithdrawalLogs :company_id="companyInfo.id" />
            </Tab>
          </TabNav>
        </div>
      </fieldset>

      <!-- modals -->
      <div
        class="modal fade"
        id="createAgentAdminModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="createAgentAdminModalTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <form method="post">
              <div class="modal-header border-0 p-0 m-0">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h3 class="swal2-title">Create Agent Admin</h3>

                <div class="d-flex justify-content-between">
                  <div>
                    <a
                      @click="closeModal('new')"
                      class="nav-link"
                      active-class="active"
                      exact
                    >
                      <button type="button" class="btn btn-sm btn-secondary">
                        Create New Agent Admin <i class="fa fa-plus"></i>
                      </button>
                    </a>
                  </div>

                  <div>
                    <a
                      @click="closeModal('old')"
                      class="nav-link"
                      active-class="active"
                      exact
                    >
                      <button
                        type="button"
                        class="btn btn-sm btn-outline-secondary"
                      >
                        Pick From Existing Agent Admins
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="pickAgentAdminModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="pickAgentAdminModalTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <form method="post" @submit.prevent="pickAgentAdmin">
              <div class="modal-header border-0 p-0 m-0">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h3 class="swal2-title">Pick Agent Admin</h3>
                <div class="input-group pt-3">
                  <select
                    v-model="pick_agent_admin_form.agent_admin_id"
                    class="form-control"
                    name=""
                    id=""
                  >
                    <option value="" selected disabled>---</option>
                    <option
                      v-for="agent_admin in companyInfo.agent_admins"
                      :key="agent_admin.id"
                      :value="agent_admin.id"
                    >
                      {{ agent_admin.first_name }} {{ agent_admin.last_name }}
                    </option>
                  </select>
                </div>
                <div
                  class="modal-footer border-0 d-flex justify-content-center"
                >
                  <button
                    :disabled="pick_agent_admin_form.agent_admin_id == ''"
                    type="submit"
                    class="btn custom-button-2"
                  >
                    Assign
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="editAgencyModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="editAgencyModalTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <form @submit.prevent="editAgency" method="post">
              <div class="modal-header border-0">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="d-flex justify-content-center">
                  <h2 class="swal2-title" id="exampleModalLongTitle">
                    Edit Agency
                  </h2>
                </div>
                <form @submit.prevent="editAgency" class="form-horizontal">
                  <div class="card-body">
                    <div class="form-group">
                      <div
                        class="text-danger"
                        v-if="edit_agency_form.errors.has('company_name')"
                        v-html="edit_agency_form.errors.get('company_name')"
                      />

                      <label for="inputEmail3">Company Name</label>
                      <input
                        type="text"
                        class="form-control"
                        id="inputEmail3"
                        v-model="edit_agency_form.company_name"
                      />
                    </div>
                    <div class="form-group">
                      <div
                        class="pt-3 text-danger"
                        v-if="edit_agency_form.errors.has('fee_cut_percent')"
                        v-html="edit_agency_form.errors.get('fee_cut_percent')"
                      />

                      <label for="inputCut">Transaction fee cut percent</label>
                      <input
                        type="number"
                        id="inputCut"
                        v-model="edit_agency_form.fee_cut_percent"
                        maxlength="70"
                        placeholder="Enter fee cut percent"
                        class="form-control"
                      />
                    </div>
                    <div class="form-group">
                      <div
                        class="pt-3 text-danger"
                        v-if="
                          edit_agency_form.errors.has(
                            'withdraw_fee_cut_percent'
                          )
                        "
                        v-html="
                          edit_agency_form.errors.get(
                            'withdraw_fee_cut_percent'
                          )
                        "
                      />

                      <label for="inputCut">Withdraw fee cut percent</label>
                      <input
                        type="number"
                        id="inputCut"
                        v-model="edit_agency_form.withdraw_fee_cut_percent"
                        maxlength="70"
                        placeholder="Enter withdraw fee cut percent"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <!-- /.card-body -->
                </form>
              </div>
              <div class="modal-footer border-0 d-flex justify-content-center">
                <button type="submit" class="btn custom-button-2">
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="depositModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="depositModalTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <form @submit.prevent="deposit" method="post">
              <div class="modal-header border-0">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="d-flex justify-content-center">
                  <h2 class="swal2-title" id="exampleModalLongTitle">
                    Deposit Broker Balance
                  </h2>
                </div>
                <div class="card-body">
                  <div>
                    Current Broker Balance:
                    {{ dalasisFormatter(companyInfo.broker_balance) }}
                  </div>

                  <div class="form-group py-2 mb-0">
                    <div
                      class="text-danger"
                      v-if="deposit_form.errors.has('amount')"
                      v-html="deposit_form.errors.get('amount')"
                    />

                    <label for="amount">How much</label>
                  </div>
                  <div class="input-group mb-2">
                    <div class="input-group-prepend">
                      <span
                        class="
                          input-group-text
                          font-weight-bold
                          text-navy
                          bg-olive
                        "
                        >D</span
                      >
                    </div>
                    <input
                      type="number"
                      required
                      id="amount"
                      v-model="deposit_form.amount"
                      placeholder="Enter amount in dalasis"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
              <div class="modal-footer border-0 d-flex justify-content-center">
                <button
                  :disabled="deposit_form.busy"
                  type="submit"
                  class="btn custom-button-2"
                >
                  Deposit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="withdrawModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="withdrawModalTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <form @submit.prevent="withdraw" method="post">
              <div class="modal-header border-0">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="d-flex justify-content-center">
                  <h2 class="swal2-title" id="exampleModalLongTitle">
                    Withdraw Broker Balance
                  </h2>
                </div>
                <div class="card-body">
                  <div>
                    Current Broker Balance:
                    {{ dalasisFormatter(companyInfo.broker_balance) }}
                  </div>

                  <div class="form-group py-2 mb-0">
                    <div
                      class="text-danger"
                      v-if="withdraw_form.errors.has('amount')"
                      v-html="withdraw_form.errors.get('amount')"
                    />

                    <label for="amount">How much</label>
                  </div>
                  <div class="input-group mb-2">
                    <div class="input-group-prepend">
                      <span
                        class="
                          input-group-text
                          font-weight-bold
                          text-navy
                          bg-olive
                        "
                        >D</span
                      >
                    </div>
                    <input
                      type="number"
                      required
                      id="amount"
                      v-model="withdraw_form.amount"
                      placeholder="Enter amount in dalasis"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
              <div class="modal-footer border-0 d-flex justify-content-center">
                <button
                  :disabled="withdraw_form.busy"
                  type="submit"
                  class="btn custom-button-2"
                >
                  Withdraw
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="negativeBrokerBalanceLimit"
        tabindex="-1"
        role="dialog"
        aria-labelledby="depositModalTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <form @submit.prevent="submitNegativeLimit" method="post">
              <div class="modal-header border-0">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="d-flex justify-content-center">
                  <h2 class="swal2-title" id="exampleModalLongTitle">
                    Negative Broker Balance Limit
                  </h2>
                </div>
                <div class="card-body">
                  <div class="form-group py-2 mb-0">
                    <label for="amount">How much</label>
                  </div>
                  <div class="input-group mb-2">
                    <div class="input-group-prepend">
                      <span
                        class="
                          input-group-text
                          font-weight-bold
                          text-navy
                          bg-olive
                        "
                        >D</span
                      >
                    </div>
                    <input
                      type="number"
                      required
                      id="amount"
                      v-model="negative_limit_form.negative_limit"
                      placeholder="Enter negative amount"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
              <div class="modal-footer border-0 d-flex justify-content-center">
                <button
                  :disabled="this.negative_limit_form.negative_limit > 0"
                  type="submit"
                  class="btn custom-button-2"
                >
                  Set
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <not-found></not-found>
    </div>
  </div>
</template>

<script>
export default {
  name: "AgencyProfile",
  props: {},
  components: {
    TabNav: () =>
      import("../../includes/tabNav" /* webpackChunkName: "js/tab-nav" */),
    Tab: () => import("../../includes/tabs" /* webpackChunkName: "js/tabs" */),
    AgencyTransactions: () =>
      import(
        "./AgencyTransactions" /* webpackChunkName: "js/agency-transactions" */
      ),
    AgencyDeposits: () =>
      import("./AgencyDeposits" /* webpackChunkName: "js/agency-deposits" */),
    AgencyWithdrawals: () =>
      import(
        "./AgencyWithdrawals" /* webpackChunkName: "js/agency-withdrawals" */
      ),
    AgencyDepositWithdrawalLogs: () =>
      import(
        "./AgencyDepositWithdrawalLogs" /* webpackChunkName: "js/agency-withdrawals" */
      ),
  },
  data() {
    return {
      brokerBalanceNegativeLimit: "",
      selected: "General",
      logs: [],
      choosen_branch: null,
      companyInfo: {},
      tabs: [],
      pageData: {},
      edit_agency_form: new Form({
        company_id: 0,
        company_name: "",
        picture: null,
        fee_cut_percent: 0,
        withdraw_cut_percent: 0,
      }),
      pick_agent_admin_form: new Form({
        agent_admin_id: "",
        branch: "",
      }),
      deposit_form: new Form({
        amount: 0,
      }),

      withdraw_form: new Form({
        amount: 0,
      }),

      negative_limit_form: new Form({
        negative_limit: 0,
      }),
    };
  },

  created() {
    console.log("gate ", this.$gate.isBroker());

    if (this.$gate.isBroker()) {
      this.tabs = [
        "General",
        "Branches",
        "Transactions",
        "Deposits",
        "Withdrawals",
        "Bank Accounts",
        "Logs",
      ];
      this.$isLoading(true);

      let temp_form = new Form({
        id: this.$route.params.id,
      });

      temp_form
        .post("/api/broker/getAgency")
        .then(({ data }) => {
          ////////////////////  to display the negative limit to the view agencies page ////////////////

          axios
            .get("/api/broker/considerations/" + data.id)
            .then(({ data }) => {
              this.brokerBalanceNegativeLimit =
                data.agencyLimit[0].negative_limit;
              //   console.log("negative limit", this.brokerBalanceNegativeLimit)
            })
            .catch((err) => {});

          ////////////////////////////////// ends here ////////////////////////

          this.negative_limit_form.company_id = data.id;

          this.companyInfo = data;
          console.log("company Info", this.companyInfo)
          this.edit_agency_form = new Form({
            company_id: data.id,
            company_name: data.company_name,
            picture: null,
            fee_cut_percent: data.fee_cut_percent,
            withdraw_fee_cut_percent: data.withdraw_fee_cut_percent,

            password: "",
          });

          this.$isLoading(false);
        })
        .catch((err) => {
          this.companyInfo = "error";
          this.$isLoading(false);
        });

      // this.getConsiderations();
    }
  },
  watch: {
    selected: function () {
      if (this.selected == "Logs") this.getLogs();
    },
  },
  methods: {
    setSelected(tab) {
      this.selected = tab;
    },
    blockBranch(id) {
      const confirmBox1 = Swal.mixin({
        customClass: {
          cancelButton: "btn custom-button-2 mx-2",
          confirmButton: "btn custom-button bg-danger mx-2",
        },
        buttonsStyling: false,
      });

      confirmBox1
        .fire({
          title: "Are you sure?",
          text: "You'll be deactivate this branch ",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, Deactivate this branch!",
        })
        .then((result1) => {
          if (result1.isConfirmed) {
            const box1 = Swal.mixin({
              customClass: {
                confirmButton: "btn custom-button-2 mx-2",
                denyButton: "btn custom-button bg-secondary mx-2",
              },
              buttonsStyling: false,
            });

            box1
              .fire({
                title: "Enter your password",
                input: "password",
                inputAttributes: {
                  pattern: "[0-9]+",
                  required: true,
                },
                inputValidator: (value) => {
                  if (!value) {
                    return "You need to enter your password!";
                  }
                },
                showDenyButton: true,
                denyButtonText: `Back`,
                confirmButtonText: "Submit",
              })
              .then((result) => {
                if (result.isConfirmed) {
                  this.$isLoading(true);

                  let del_form = new FormData();
                  del_form.append("branch_id", id);
                  del_form.append("password", result.value);
                  let url =
                    window.location.origin + "/api/broker/deactivateBranch";
                  axios
                    .post(url, del_form, {
                      headers: { "Content-Type": "application/json" },
                    })
                    .then((data) => {
                      this.$isLoading(false);

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button-2",
                        },
                        buttonsStyling: false,
                      });

                      confirmBox2
                        .fire({
                          icon: "success",
                          title: "Deactivated!",
                          text: "Branch Deactivated! ... ",
                        })
                        .then((res) => {
                          // reloading the page
                          // window.location.href = '/agents';
                          this.getResults();
                        });
                    })
                    .catch((err) => {
                      this.$isLoading(false);
                      // show them if that city name is taken
                      let errorText = "Deactivation Unsuccessful";

                      if (err.response.data.errors["password"]) {
                        errorText = err.response.data.errors["password"];
                      } else if (err.response.data.errors["general"]) {
                        errorText = err.response.data.errors["general"];
                      }

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button bg-danger",
                        },
                        buttonsStyling: true,
                      });

                      confirmBox2.fire({
                        icon: "error",
                        title: "Failed!",
                        text: errorText,
                      });
                    });
                } else if (result.isDenied) {
                }
              });
          }
        });
    },
    unblockBranch(id) {
      const confirmBox1 = Swal.mixin({
        customClass: {
          cancelButton: "btn custom-button-2 mx-2",
          confirmButton: "btn custom-button bg-danger mx-2",
        },
        buttonsStyling: false,
      });

      confirmBox1
        .fire({
          title: "Are you sure?",
          text: "You'll be activate this branch ",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, Activate this branch!",
        })
        .then((result1) => {
          if (result1.isConfirmed) {
            const box1 = Swal.mixin({
              customClass: {
                confirmButton: "btn custom-button-2 mx-2",
                denyButton: "btn custom-button bg-secondary mx-2",
              },
              buttonsStyling: false,
            });

            box1
              .fire({
                title: "Enter your password",
                input: "password",
                inputAttributes: {
                  pattern: "[0-9]+",
                  required: true,
                },
                inputValidator: (value) => {
                  if (!value) {
                    return "You need to enter your password!";
                  }
                },
                showDenyButton: true,
                denyButtonText: `Back`,
                confirmButtonText: "Submit",
              })
              .then((result) => {
                if (result.isConfirmed) {
                  this.$isLoading(true);

                  let del_form = new FormData();
                  del_form.append("branch_id", id);
                  del_form.append("password", result.value);
                  let url =
                    window.location.origin + "/api/broker/activateBranch";
                  axios
                    .post(url, del_form, {
                      headers: { "Content-Type": "application/json" },
                    })
                    .then((data) => {
                      this.$isLoading(false);

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button-2",
                        },
                        buttonsStyling: false,
                      });

                      confirmBox2
                        .fire({
                          icon: "success",
                          title: "Activated!",
                          text: "Activated Branch! ... ",
                        })
                        .then((res) => {
                          // reloading the page
                          // window.location.href = '/agents';
                          this.getResults();
                        });
                    })
                    .catch((err) => {
                      this.$isLoading(false);
                      // show them if that city name is taken
                      let errorText = "Activation Unsuccessful";

                      if (err.response.data.errors["password"]) {
                        errorText = err.response.data.errors["password"];
                      } else if (err.response.data.errors["general"]) {
                        errorText = err.response.data.errors["general"];
                      }

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button bg-danger",
                        },
                        buttonsStyling: true,
                      });

                      confirmBox2.fire({
                        icon: "error",
                        title: "Failed!",
                        text: errorText,
                      });
                    });
                } else if (result.isDenied) {
                }
              });
          }
        });
    },

    // getConsiderations(){
    //      console.log("getConsiderations", this.negative_limit_form)
    //      const { negative_limit } = this.negative_limit_form;
    //      console.log("destructure", company_id);
    //      axios.get('/api/broker/considerations/22' )
    //         .then(({data}) => {
    //            console.log("considerations ", data)
    //         //   console.log("negative limit", this.brokerBalanceNegativeLimit)

    //         }).catch((err)=>{

    //     })
    // },

    getLogs(page) {
      if (typeof page === "undefined") {
        page = 1;
      }

      axios
        .get("api/agency/logs?page=" + page)
        .then(({ data }) => {})
        .catch((err) => {});
    },

    openModal(branch_id, modal_type, agent_admin_id) {
      this.choosen_branch = branch_id;

      if (modal_type == "createAgentAdmin") {
        $("#createAgentAdminModal").modal("toggle");
      } else if (modal_type == "changeAgentAdmin") {
        this.pick_agent_admin_form.agent_admin_id = agent_admin_id;

        $("#pickAgentAdminModal").modal("toggle");
      }
    },
    closeModal(admin) {
      $("#createAgentAdminModal").modal("toggle");
      if (admin == "new") {
        window.location.href = "/create-agent-admin/" + this.choosen_branch;
      } else if (admin == "old") {
        $("#pickAgentAdminModal").modal("toggle");
      }
    },

    pickAgentAdmin() {
      $("#pickAgentAdminModal").modal("toggle");

      this.pick_agent_admin_form.branch = this.choosen_branch;
      let branch_name = this.companyInfo.branches.filter(
        (d) => d.id == this.pick_agent_admin_form.branch
      )[0]["town_name"];

      let agent_admin = this.companyInfo.agent_admins.filter(
        (d) => d.id == this.pick_agent_admin_form.agent_admin_id
      )[0];
      const confirmBox1 = Swal.mixin({
        customClass: {
          confirmButton: "btn custom-button bg-danger mr-2",
          cancelButton: "btn custom-button-2 ml-2",
        },
        buttonsStyling: false,
      });

      confirmBox1
        .fire({
          title: "Are you sure?",
          text:
            'You will be assigning "' +
            agent_admin.first_name +
            " " +
            agent_admin.last_name +
            '" to the ' +
            branch_name +
            " branch",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, assign them!",
        })
        .then((result1) => {
          if (result1.isConfirmed) {
            // prompt for pin
            const box1 = Swal.mixin({
              customClass: {
                confirmButton: "btn custom-button bg-danger  mr-2",
                cancelButton: "btn custom-button-2 ml-2",
              },
              buttonsStyling: false,
            });

            box1
              .fire({
                title: "Enter your password",
                text:
                  'To confirm that you want to assign "' +
                  agent_admin.first_name +
                  " " +
                  agent_admin.last_name +
                  '" to the ' +
                  branch_name +
                  " branch",
                input: "password",
                inputAttributes: {
                  required: true,
                },
                inputValidator: (value) => {
                  if (!value) {
                    return "You need to enter your password!";
                  }
                },
                showCancelButton: true,
                confirmButtonText: "Submit",
                showLoaderOnConfirm: true,
              })
              .then((result) => {
                if (result.isConfirmed) {
                  this.pick_agent_admin_form.password = result.value;
                  this.$isLoading(true);

                  this.pick_agent_admin_form
                    .post("/api/broker/pickAgentAdmin", {
                      headers: { "Content-Type": "application/json" },
                    })
                    .then((data) => {
                      this.$isLoading(false);

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button-2",
                        },
                        buttonsStyling: false,
                      });

                      confirmBox2
                        .fire({
                          icon: "success",
                          title: "Agent Admin Assigned!",
                        })
                        .then((res) => {
                          window.location.reload();
                        });
                    })
                    .catch((err) => {
                      this.$isLoading(false);

                      // show them if that city name is taken
                      let errorText = "Agent Admin Assignment Unsuccessful";

                      if (err.response.data.errors["password"]) {
                        errorText = err.response.data.errors["password"];
                      } else if (err.response.data.errors["general"]) {
                        errorText = err.response.data.errors["general"];
                      }

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button bg-danger",
                        },
                        buttonsStyling: true,
                      });

                      confirmBox2.fire({
                        icon: "error",
                        title: "Failed!",
                        text: errorText,
                      });
                    });
                }
              });
          }
        });
    },

    editAgency() {
      $("#editAgencyModal").modal("toggle");

      const confirmBox1 = Swal.mixin({
        customClass: {
          confirmButton: "btn custom-button bg-danger mr-2",
          cancelButton: "btn custom-button-2 ml-2",
        },
        buttonsStyling: false,
      });

      confirmBox1
        .fire({
          title: "Are you sure?",
          text: "Your changes will be saved and the agency will be affected",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, save the changes!",
        })
        .then((result1) => {
          if (result1.isConfirmed) {
            // prompt for pin
            const box1 = Swal.mixin({
              customClass: {
                confirmButton: "btn custom-button bg-danger  mr-2",
                cancelButton: "btn custom-button-2 ml-2",
              },
              buttonsStyling: false,
            });

            box1
              .fire({
                title: "Enter your password",
                text: "To confirm your changes",
                input: "password",
                inputAttributes: {
                  required: true,
                },
                inputValidator: (value) => {
                  if (!value) {
                    return "You need to enter your password!";
                  }
                },
                showCancelButton: true,
                confirmButtonText: "Submit",
                showLoaderOnConfirm: true,
              })
              .then((result) => {
                if (result.isConfirmed) {
                  this.edit_agency_form.password = result.value;
                  this.$isLoading(true);

                  this.edit_agency_form
                    .post("/api/broker/editAgency", {
                      headers: { "Content-Type": "application/json" },
                    })
                    .then((data) => {
                      this.$isLoading(false);

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button-2",
                        },
                        buttonsStyling: false,
                      });

                      confirmBox2
                        .fire({
                          icon: "success",
                          title: "Agency changes saved!",
                        })
                        .then((res) => {
                          window.location.reload();
                        });
                    })
                    .catch((err) => {
                      this.$isLoading(false);
                      console.log("errors ", this.edit_agency_form.errors);
                      // show them if that city name is taken
                      let errorText = "Agency changes Unsuccessful";

                      if (err.response.data.errors["password"]) {
                        errorText = err.response.data.errors["password"];
                        const confirmBox2 = Swal.mixin({
                          customClass: {
                            confirmButton: "btn custom-button bg-danger",
                          },
                          buttonsStyling: true,
                        });

                        confirmBox2.fire({
                          icon: "error",
                          title: "Failed!",
                          text: errorText,
                        });
                      } else {
                        $("#editAgencyModal").modal("toggle");
                      }
                    });
                }
              });
          }
        });
    },

    deposit() {
      if (this.deposit_form.amount <= 0) return;
      $("#depositModal").modal("toggle");

      const confirmBox1 = Swal.mixin({
        customClass: {
          confirmButton: "btn custom-button bg-danger mr-2",
          cancelButton: "btn custom-button-2 ml-2",
        },
        buttonsStyling: false,
      });

      confirmBox1
        .fire({
          title:
            "Are you sure you want to deposit " +
            this.dalasisFormatter(this.deposit_form.amount) +
            "?",
          text:
            this.companyInfo.company_name +
            " will have a total broker balance of " +
            this.dalasisFormatter(
              parseFloat(this.deposit_form.amount) +
                parseFloat(this.companyInfo.broker_balance)
            ),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, save the changes!",
        })
        .then((result1) => {
          if (result1.isConfirmed) {
            // prompt for pin
            const box1 = Swal.mixin({
              customClass: {
                confirmButton: "btn custom-button bg-danger  mr-2",
                cancelButton: "btn custom-button-2 ml-2",
              },
              buttonsStyling: false,
            });

            box1
              .fire({
                title: "Enter your password",
                text:
                  "To confirm your want to deposit " +
                  this.dalasisFormatter(this.deposit_form.amount) +
                  " for this agency",
                input: "password",
                inputAttributes: {
                  required: true,
                },
                inputValidator: (value) => {
                  if (!value) {
                    return "You need to enter your password!";
                  }
                },
                showCancelButton: true,
                confirmButtonText: "Submit",
                showLoaderOnConfirm: true,
              })
              .then((result) => {
                if (result.isConfirmed) {
                  this.deposit_form.password = result.value;
                  this.deposit_form.company_id = this.companyInfo.id;

                  this.$isLoading(true);

                  this.deposit_form
                    .post("/api/broker/depositForAgency", {
                      headers: { "Content-Type": "application/json" },
                    })
                    .then((data) => {
                      this.$isLoading(false);

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button-2",
                        },
                        buttonsStyling: false,
                      });

                      confirmBox2
                        .fire({
                          icon: "success",
                          title: "Deposit Successful!",
                        })
                        .then((res) => {
                          this.deposit_form.password = null;
                          window.location.reload();
                        });
                    })
                    .catch((err) => {
                      this.$isLoading(false);

                      // show them if that city name is taken
                      let errorText = "Deposit Unsuccessful";

                      if (err.response.data.errors["password"]) {
                        errorText = err.response.data.errors["password"];
                        const confirmBox2 = Swal.mixin({
                          customClass: {
                            confirmButton: "btn custom-button bg-danger",
                          },
                          buttonsStyling: true,
                        });

                        confirmBox2.fire({
                          icon: "error",
                          title: "Failed!",
                          text: errorText,
                        });
                      } else {
                        $("#depositModal").modal("toggle");
                      }
                    });
                }
              });
          }
        });
    },

    withdraw() {
      if (this.withdraw_form.amount <= 0) return;
      $("#withdrawModal").modal("toggle");

      const confirmBox1 = Swal.mixin({
        customClass: {
          confirmButton: "btn custom-button bg-danger mr-2",
          cancelButton: "btn custom-button-2 ml-2",
        },
        buttonsStyling: false,
      });

      confirmBox1
        .fire({
          title:
            "Are you sure you want to withdraw " +
            this.dalasisFormatter(this.withdraw_form.amount) +
            "?",
          text:
            this.companyInfo.company_name +
            " will have a total broker balance of " +
            this.dalasisFormatter(
              parseFloat(this.companyInfo.broker_balance) -
                parseFloat(this.withdraw_form.amount)
            ),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, save the changes!",
        })
        .then((result1) => {
          if (result1.isConfirmed) {
            // prompt for pin
            const box1 = Swal.mixin({
              customClass: {
                confirmButton: "btn custom-button bg-danger  mr-2",
                cancelButton: "btn custom-button-2 ml-2",
              },
              buttonsStyling: false,
            });

            box1
              .fire({
                title: "Enter your password",
                text:
                  "To confirm your want to withdraw " +
                  this.dalasisFormatter(this.withdraw_form.amount) +
                  " for this agency",
                input: "password",
                inputAttributes: {
                  required: true,
                },
                inputValidator: (value) => {
                  if (!value) {
                    return "You need to enter your password!";
                  }
                },
                showCancelButton: true,
                confirmButtonText: "Submit",
                showLoaderOnConfirm: true,
              })
              .then((result) => {
                if (result.isConfirmed) {
                  console.log("good", this.withdraw_form);
                  this.withdraw_form.password = result.value;
                  this.withdraw_form.company_id = this.companyInfo.id;

                  this.$isLoading(true);

                  this.withdraw_form
                    .post("/api/broker/withdrawForAgency", {
                      headers: { "Content-Type": "application/json" },
                    })
                    .then((data) => {
                      this.$isLoading(false);

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button-2",
                        },
                        buttonsStyling: false,
                      });

                      confirmBox2
                        .fire({
                          icon: "success",
                          title: "Withdraw Successful!",
                        })
                        .then((res) => {
                          this.withdraw_form.password = null;
                          window.location.reload();
                        });
                    })
                    .catch((err) => {
                      console.log(
                        "response error",
                        err.response.data.balance_error
                      );
                      this.$isLoading(false);

                      // show them if that city name is taken
                      let errorText = "Withdraw Unsuccessful";

                      if (err.response.data.errors["password"]) {
                        errorText = err.response.data.errors["password"];
                        const confirmBox2 = Swal.mixin({
                          customClass: {
                            confirmButton: "btn custom-button bg-danger",
                          },
                          buttonsStyling: true,
                        });

                        confirmBox2.fire({
                          icon: "error",
                          title: "Failed!",
                          text: errorText,
                        });
                      } else if (err.response.data.errors["balance_error"]) {
                        console.log("here");
                        errorText = err.response.data.errors.balance_error;
                        const confirmBox2 = Swal.mixin({
                          customClass: {
                            confirmButton: "btn custom-button bg-danger",
                          },
                          buttonsStyling: true,
                        });

                        confirmBox2.fire({
                          icon: "error",
                          title: "Failed!",
                          text: errorText,
                        });
                      } else {
                        $("#withdrawModal").modal("toggle");
                      }
                    });
                }
              });
          }
        });
    },

    submitNegativeLimit() {
      $("#negativeBrokerBalanceLimit").modal("toggle");

      const confirmBox1 = Swal.mixin({
        customClass: {
          confirmButton: "btn custom-button bg-danger mr-2",
          cancelButton: "btn custom-button-2 ml-2",
        },
        buttonsStyling: false,
      });

      confirmBox1
        .fire({
          title: "Are you sure you want to add negative limit?",
          text: "negative broker balance will be activated",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, save the changes!",
        })
        .then((result1) => {
          if (result1.isConfirmed) {
            // prompt for pin
            const box1 = Swal.mixin({
              customClass: {
                confirmButton: "btn custom-button bg-danger  mr-2",
                cancelButton: "btn custom-button-2 ml-2",
              },
              buttonsStyling: false,
            });

            box1
              .fire({
                title: "Enter your password",
                text: "To confirm your want to activate negative broker balance for this agency",
                input: "password",
                inputAttributes: {
                  required: true,
                },
                inputValidator: (value) => {
                  if (!value) {
                    return "You need to enter your password!";
                  }
                },
                showCancelButton: true,
                confirmButtonText: "Submit",
                showLoaderOnConfirm: true,
              })
              .then((result) => {
                if (result.isConfirmed) {
                  this.negative_limit_form.password = result.value;
                  // this.deposit_form.company_id = this.companyInfo.id

                  this.$isLoading(true);

                  this.negative_limit_form
                    .post("/api/broker/negativeBrokerBalanceForAgency", {
                      headers: { "Content-Type": "application/json" },
                    })
                    .then((data) => {
                      this.$isLoading(false);

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button-2",
                        },
                        buttonsStyling: false,
                      });

                      confirmBox2
                        .fire({
                          icon: "success",
                          title: "Changes Added Successfully!",
                        })
                        .then((res) => {
                          this.deposit_form.password = null;
                          window.location.reload();
                        });
                    })
                    .catch((err) => {
                      this.$isLoading(false);

                      // show them if that city name is taken
                      let errorText = "Negative broker balance Unsuccessful";

                      if (err.response.data.errors["password"]) {
                        errorText = err.response.data.errors["password"];
                        const confirmBox2 = Swal.mixin({
                          customClass: {
                            confirmButton: "btn custom-button bg-danger",
                          },
                          buttonsStyling: true,
                        });

                        confirmBox2.fire({
                          icon: "error",
                          title: "Failed!",
                          text: errorText,
                        });
                      } else {
                        $("#negativeBrokerBalanceLimit").modal("toggle");
                      }
                    });
                }
              });
          }
        });
    },
  },
};
</script>
