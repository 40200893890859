<template>
    <div>

        <AgentProfile :canEdit='true' legendTitle="My Profile" :tabs='tabs' :userInfo='userInfo' />

        
        <AgencyFooter v-if="$gate.isAgency()" :getUserProfile='getUserProfile' />

    </div>
</template>


<script>


import AgentProfile from './../includes/AgentProfile'

export default {
    name: 'Profile',
    props:{
       
    },
  components: {
        AgentProfile
  },
  data() {
    return {
        selected: 'General',
        tabs: [],
        logs: [],
        pageData: {},
        userInfo: {},

    }
  },
  created(){

    if(this.$gate.isAgentAdmin()){
        this.tabs = ['General', 'Bank Accounts', 'Logs']
    }
    else{
        this.tabs = ['General', 'Logs']
    }

  },  
  watch:{
        getUserProfile: function () {
            this.userInfo = this.getUserProfile

        },

  },

  mounted() {
        this.$store.dispatch("userProfile")


  },
    computed: {



        getUserProfile(){ //final output from here
            return this.$store.getters.getUserProfile
        },

    },
  methods:{



  },

}
</script>