<template>
    <div v-if="$gate.isCustomer()">
        <div v-if="$gate.canTransact() && $gate.isRegistrationComplete()" class="card  custom-card-1 m-0">
            <fieldset>
                <legend class="d-flex justify-content-between flex-wrap">
                    <span class='d-flex align-items-center justify-content-center'>Transactions Logs</span>
                </legend>
                <div class="transactions-logs">
                    <div>
                        <label for="phone">Date from:</label>
                        <input type="date" class="start-date" v-model="startDate">
                    </div>
                    <div>
                        <label for="phone">Date To:</label>
                        <input type="date" class="end-date" v-model="endDate">
                    </div>
                    <div>
                        <button @click="logs()" class="btn btn-primary filter" type="submit">Filter</button>
                    </div>
                </div>
                <!-- /.card-header -->
                <div class="card-body table-responsive">
                <table   id="example2" class="table table-bordered table-hover border-top">
                    <thead>
                    <tr class="text-center">
                    <th>Ref</th>
                    <th>Reciever Mobile</th>
                    <th>Meter</th>
                    <th>Amount</th>
                    <th>Token</th>
                    <th>Date</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class='text-center' v-for='(row, index) in transactions' :key='index'>

                    <td>
                        {{ row.ref}}
                    </td>

                    <td>
                        {{ row.receiver_mobile}}

                    </td>


                    <td>{{ row.meter_no}}
                    </td>
                    <td>{{dalasisFormatter(row.amount)}}
                    </td>
                    <td>{{row.token}}
                    </td>
                    <td>
                        {{row.transaction_date}}
                    </td>
                    </tr>
                    </tbody>
                </table>

                <div class="pt-3">
                    <pagination align="center" :data="pageData" @pagination-change-page="getResults"></pagination>

                </div>

                </div>


                <!-- /.card-body -->
            </fieldset>
        </div>
        <!-- /.card -->
        <!-- /.card -->
        <div class="card custom-card-1" v-else>
            <legend class="d-flex font-weight-bold text-danger justify-content-center flex-wrap">
                <span>Ops! You can't see cancelled transactions</span>
            </legend>
            <div class="card-header">
            </div>
        </div>
        <AgencyFooter :getUserProfile='getUserProfile' />

    </div>
    <div v-else>
        <not-found></not-found>

    </div>
</template>



<script>

export default {
    name: 'CancelledTransactions',
  components: {
  },
  data() {
    return {
        transactions: [],
        pageData: {},
        searchForm: null,
        isFound: false,
        startDate: '',
        endDate: '',
        broker_balance: 0,
        emptyDataText: '',
        meter_no: '',
        user_id: '',
        }



  },
  created(){
      // fetch the results
      if(this.$gate.isCustomer()){
        //     let formData = new FormData();
        //     formData.append('userId', this.user_id);
        //     formData.append('dateFrom', this.startDate);
        //     formData.append('dateTo', this.endDate);
        //     formData.append('meter_no', this.meter_no);

        // axios.post('/api/nawec/cash-power-logs', formData, {headers:{"Content-Type":"application/json"}})
        //             .then((res) => {
        //                 this.transactions = res.data.transactions;
        //             }).catch((err) => {
        //                 console.log(err)
        //             })
      }


  },
  methods: {

    logs(){
        let formData = new FormData();
            formData.append('userId', this.user_id);
            formData.append('dateFrom', this.startDate);
            formData.append('dateTo', this.endDate);
            formData.append('meter_no', this.meter_no);

        axios.post('/api/nawec/cash-power-logs', formData, {headers:{"Content-Type":"application/json"}})
                    .then((res) => {
                        this.transactions = res.data.transactions;
                    }).catch((err) => {
                        console.log(err)
                    })
    },

    getResults(){

    }
  },
 watch:{
        getUserProfile: function () {
            this.broker_balance = this.getUserProfile.broker_balance
        },

  },

  mounted() {
        this.$store.dispatch("userProfile");
        this.meter_no = this.$route.params.data.meter_no;
        this.user_id = this.$route.params.data.user_id;

  },
  computed: {
        getUserProfile(){ //final output from here
            return this.$store.getters.getUserProfile
        },
  }
}
</script>

<style scoped>
    .transactions-logs{
        display: flex;
        justify-content: center;
        gap: 10px;
        align-items: center;

    }
    .start-date, .end-date{
        border: 1px solid rgb(184, 183, 183);
        border-radius: 4px;
        width: 100%;
        padding: 2px;
        font-size: 18px;
        margin-right: 30px;
    }

    .filter{
        margin-bottom: -28px;
    }
</style>>


