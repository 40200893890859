<template>
    <div>

        <div class="card  custom-card-1 m-0">
            <fieldset>
                <legend class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center">
                        <span>Transaction Fee Table</span>

                    </div>
                   <router-link v-if="$gate.isBroker()" to="/configure-fee-table" class="nav-link" active-class="active" exact>
                       <button class="btn btn-outline-info">Configure <i class="fa fa-cog"></i></button>
                    </router-link>
                </legend>
                <div class="card-header">
                </div>
                <!-- /.card-header -->
                <div class="card-body table-responsive">
                <table id="example2" class="table table-bordered table-hover">
                    <thead>
                    <tr class="text-center">
                    <th></th>
                    <th>From</th>
                    <th>To</th>
                    <th>Fee in Dalasis</th>

                    </tr>
                    </thead>
                    <tbody>
                    <tr class='text-center' v-for='(row, index) in feeTable' :key='index'>
                    <td>{{++index}}</td>

                    <td>
                        {{dalasisFormatter(row.range_from)}}
                    </td>
                    <td>{{dalasisFormatter(row.range_to)}}</td>
                    <td class="text-emerald font-weight-bold">{{dalasisFormatter(row.fee)}}
                    </td>


                    </tr>
                    </tbody>
                </table>
                </div>
                <!-- /.card-body -->
            </fieldset>
        </div>
        <!-- /.card -->

        <AgencyFooter v-if="$gate.isAgency()" :getUserProfile='getUserProfile' />

    </div>
</template>


<script>
export default {
    name: 'FeeTable',
  components: {
   
  },
  data() {
    return {
        feeTable: []

    }
  },
  created(){
      // fetch fee table
                  this.$isLoading(true) 

            axios.get('api/feetable')
                .then(({data}) => {
                  this.$isLoading(false) 

                  this.feeTable = data;

                }).catch((err)=>{
                  this.$isLoading(false) 

                    console.log(err)
                    
                })

  },  

  mounted() {
        this.$store.dispatch("userProfile")

  },
  computed: {
        getUserProfile(){ //final output from here
            return this.$store.getters.getUserProfile
        },
  }
}
</script>