<template>
    <div>
        <div v-if="$gate.isCashdistributor()" class="card  custom-card-1 m-0">
            <fieldset class="mt-5">
                            <legend class="d-flex justify-content-between flex-wrap">
                                <div class="d-flex align-items-center">
                                <span>Campaigns</span>

                                </div>

                           
                            <router-link v-bind:to="'/distributor-create-campaign/'" class="nav-link" active-class="active" exact>
                                <button class="btn btn-outline-info">Create new Campaign <i class="fa fa-plus"></i></button>
                                </router-link>
                            </legend>
                            <div class="card-header">
                            </div>


                        <div>



                        </div>




                        <div  class="card-body mt-2 table-responsive">
                            <table  id="example2" class="table table-hover border-top">
                                <thead >
                                <tr class="text-center">
                                <th>Campaign  Name</th>
                                <th>Users</th>
                                <th>Budget</th>
                                <th>Fee</th>
                                <th>Amount Sent</th>
                                <!-- <th>Telephone</th> -->
                                <!-- <th>Company Registration Info</th> -->
                                <th>Status</th>
                                <th class="border-left">Action</th>
                                </tr>
                                </thead>
                                <tbody v-if="campaigns.length != 0">
                                <tr class='text-center' v-for='(row, index) in campaigns' :key='index'>
                                    <td>{{row.campaign_name}}
                                        <span v-if="row.is_blocked" >
                                            <i class="fas fa-ban text-danger"></i>


                                        </span>

                                    </td>
                                    <td>{{row.users_count}}</td>
                                    <td>{{dalasisFormatter(row.campaign_budget)}}</td>
                                    <td>{{row.campaign_fee}}%</td>
                                    <td>{{dalasisFormatter(row.amount_sent)}}</td>



                                    <!-- <td>{{row.company_registration_info}}</td> -->
                                    <td v-if="row.campaign_status==0"><span class="badge badge-warning badge-pill">Under review</span></td>
                                    <td v-else-if="row.campaign_status==2"><span class="badge badge-danger badge-pill">Blocked</span></td>
                                    <td v-else><span class="badge badge-success badge-pill">Active</span></td>


                                    <td class="d-flex flex-wrap border-left justify-content-around">


                                        <router-link v-bind:to="'/view-campaign/' + row.Cid"
                                        active-class="active" exact class="btn mb-4 custom-button-purple1" >
                                            <i class="fas fa-eye"></i>
                                        </router-link>

                                        <router-link v-if="row.campaign_status==1" v-bind:to="'/add-beneficiaries/' + row.Cid"
                                        active-class="active" exact class="btn mb-4 custom-button-purple1" >
                                            <i class="fas fa-plus"></i> Beneficiaries
                                        </router-link>


                                    </td>



                                </tr>
                                <tfoot>


                                </tfoot>
                                </tbody>
                                <tbody  v-else>
                                    <tr class='text-center'>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td><span><i>You have no campaigns.</i></span></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                    
                                </tbody>
                            </table>
                            <div class="pt-3">
                                <pagination align="center" :data="pageData" @pagination-change-page="getResults"></pagination>

                            </div>

                        </div>
                    


            </fieldset>
        </div>
    </div>

</template>

    <script>

    export default {
        name: 'Campaigns',

        components: {


        },
      data() {
        return {
            campaigns: null,
            pageData: {}






        }
      },
      created(){

          // fetch campaign table
          if(this.$gate.isCashdistributor()){
                this.getResults()

            }


      },
        watch: {



        },
      methods:{

        getResults(page){
                if (typeof page === 'undefined') {
                page = 1;
                }

                this.$isLoading(true)

                let temp_form = new Form({
                    page : page,
            })
            temp_form.post('/api/distributor/campaigns')
                    .then(({data}) => {
                      console.log("campaigns", data)
                      this.campaigns = data.data;
                      this.pageData = data
                        this.$isLoading(false)


                    }).catch((err)=>{
                      this.campaigns = []
                      this.pageData = {}
                    this.$isLoading(false)


                })

            },





      },

    }
    </script>
