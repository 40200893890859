<template>
    <div>
        <div v-if="$gate.isBroker()" class="card  p-2 m-0">
            <fieldset class="mt-5">
                            <legend class="d-flex justify-content-between flex-wrap">
                                <div class="d-flex align-items-center">
                                <span>Finacial Institutes Investments</span>

                                </div>
                                
                                <router-link to="/create-kashlock-investment" class="nav-link" active-class="active" exact>
                                    <button class="btn btn-outline-info">Create new Investment <i class="fa fa-plus"></i></button>
                                </router-link>
                            </legend>
                            <div class="card-header">
                                <div class="d-flex">
                            <select @change="makeSearch()" required v-model="form.institute_id" class="form-control form-control-lg ">
                                <option value=""  selected>All Institutes</option>
                                <option v-for="row in institutes" :key="row.id" :value="row.id">{{row.name}}</option>

                            </select>
                            <select  @change="makeSearch()" required v-model="form.period" class="form-control form-control-lg ">
                                <option value=""  selected>All Period</option>
                                <option value="60">60 Days</option>
                                <option value="90">90 Days</option>
                            <option value="180">180 Days</option>

                            </select>

                            <select  @change="makeSearch()"  required v-model="form.status" class="form-control form-control-lg ">
                                <option value=""  selected>All Status</option>
                                <option value="0">Locked</option>
                                <option value="1">Unlocked</option>

                            </select>
                    

                    </div>
                        <div class="d-flex mt-2">
                            <Datepicker  @input="makeSearch()" input-class="form-control form-control-lg" wrapper-class="inline" placeholder="From date" format="dd/MM/yyyy" :clear-button="true" v-model='form.fromdate'></Datepicker>
                            <Datepicker   @input="makeSearch()" input-class="form-control form-control-lg" wrapper-class="inline" placeholder="To date" format="dd/MM/yyyy" :clear-button="true" v-model='form.todate'  ></Datepicker>
                        </div>
                            </div>


                        <div>



                        </div>




                        <div  class="card-body p-0 mt-2 table-responsive">
                            <table  id="example2" class="table table-hover border-top">
                                <thead >
                                <tr class="text-center">
                                <th>Sr No.</th>
                                <th>Institute Name</th>
                                <th>Amount</th>
                                <th>Interest Rate</th>
                                <th>Period</th>
                                <th>Maturity Date</th>
                                <th>Expected Earning</th>
                                <th>Created</th>
                                <th>Status</th>
                                <th class="border-left">Action</th>
                                </tr>
                                </thead>
                                <tbody v-if="investments.length != 0">
                               
                                <tr class='text-center' v-for='(row, index) in investments' :key='index'>
                                    <td>{{index+1}}</td>
                                    <td>{{row.name}}</td>
                                    <td>{{dalasisFormatter(row.amount)}}</td>
                                    <td>{{row.interest_rate}}%</td>
                                    <td>{{row.period}} days</td>
                                    <td>{{row.maturity_date}}</td>
                                    <td>{{dalasisFormatter(row.expected_earning)}}</td>
                                    <td>{{row.time}}</td>
                                    
                                   
                                    <td v-if="row.Kstatus==0"><span class="badge badge-success badge-pill">Locked</span></td>
                                    <td v-else><span class="badge badge-danger badge-pill">Unlocked</span></td>


                                    <td class="d-flex flex-row border-left justify-content-around">


                                        <router-link v-bind:to="'/edit-kashlock-investment/' + row.id"
                                        active-class="active" exact class="btn mb-4 custom-button-purple1" >
                                            <i class="fas fa-edit"></i>
                                        </router-link>
                                        <button v-if="row.Kstatus==0" @click="unblock(row.id)"  class="btn custom-button mb-4 btn-danger" title="lock">
                                            <i class="fas fa-ban"></i>
                                        </button>

                                        <button v-else  @click="block(row.id)"   class="btn custom-button mb-4 btn-success" title="unlock">
                                            <i class="fas fa-toggle-on"></i>
                                        </button>
                                      </td>



                                </tr>
                                <tfoot>


                                </tfoot>
                                </tbody>
                                <tbody  v-else>
                                    <tr class='text-center'>
                                       
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td><span><i>You have no investment.</i></span></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                      
                                    </tr>
                    
                                </tbody>
                            </table>
                            <div class="pt-3">
                                <pagination align="center" :limit="10" :show-disabled="true" :data="pageData" @pagination-change-page="getResults"></pagination>

                            </div>

                        </div>
                    


            </fieldset>
        </div>
    </div>

</template>

    <script>

    export default {
        name: 'institutes',

        components: {


        },
      data() {
        return {
            balance: 0,
            investments: [],
            pageData: {},
            form: new Form ({
            institute_id: '',
            period: '',
            status: '',
            fromdate: '',
            todate: '',
        }),
        institutes:[]
         }
      },
      created(){

          // fetch investment table
          if(this.$gate.isBroker()){
                this.getResults()
                axios.get('/api/broker/getInstitutes',{headers:{"Content-Type":"application/json"}})
                        .then((data) => {
                            console.log(data.data);
                        this.institutes= data.data

                        })
            }


      },
  

      methods:{

        makeSearch(){
           this.getResults()
            // emit up
        },
        getResults(page){
                if (typeof page === 'undefined') {
                page = 1;
                }

                this.$isLoading(true)

              
            this.form.page=page;
            this.form.post('/api/broker/kashlockInvestments')
                    .then(({data}) => {
                      console.log("investments", data)
                      this.investments = data.data;
                      this.pageData = data
                        this.$isLoading(false)


                    }).catch((err)=>{
                      this.investments = []
                      this.pageData = {}
                    this.$isLoading(false)


                })

            },

            block(id){

            const confirmBox1 = Swal.mixin({
            customClass: {
                cancelButton: 'btn custom-button-2 mx-2',
                confirmButton: 'btn custom-button bg-danger mx-2'
            },
            buttonsStyling: false
            })

            confirmBox1.fire({
            title: 'Are you sure?',
            text: "You'll be locking this investment",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, lock this investment!'
            }).then((result1) => {
            if (result1.isConfirmed) {



            const box1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button-2 mx-2',
                denyButton: 'btn custom-button bg-secondary mx-2'
            },
            buttonsStyling: false
            })

            box1.fire({
                title: 'Enter your password',
                input: 'password',
                inputAttributes: {
                    pattern: "[0-9]+",
                    required: true,
                },
                inputValidator: (value) => {
                    if (!value) {
                    return 'You need to enter your password!'
                    }

                },
                showDenyButton: true,
                denyButtonText: `Back`,
                confirmButtonText: 'Submit',
                }).then((result) => {
                if (result.isConfirmed) {

                this.$isLoading(true)

                let del_form =  new FormData();
                del_form.append('id', id)
                del_form.append('status', 0)
                del_form.append('password', result.value)

                axios.post('/api/broker/markInvestment', del_form,{headers:{"Content-Type":"application/json"}})
                    .then((data) => {
                        this.$isLoading(false)

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button-2',

                        },
                        buttonsStyling: false
                        })

                        confirmBox2.fire({
                        icon: 'success',
                        title: 'Blocked!',
                        text: 'Investment locked! ... '  ,

                        }).then((res)=>{
                            // reloading the page
                        // window.location.href = '/agents';
                        this.getResults()
                        })



                    })
                    .catch((err) => {
                        this.$isLoading(false)
                    // show them if that city name is taken
                        let errorText = 'Investment Locking Unsuccessful'

                        if(err.response.data.errors['password']){
                            errorText = err.response.data.errors['password']
                        }
                        else if(err.response.data.errors['general']){
                            errorText = err.response.data.errors['general']
                        }

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button bg-danger',

                        },
                        buttonsStyling: true
                        })

                        confirmBox2.fire({
                        icon: 'error',
                        title: 'Failed!',
                        text: errorText,

                        })
                    });




                }
                else if (result.isDenied) {

                }
                })


            }
            })



            },

    unblock(id){

    const confirmBox1 = Swal.mixin({
    customClass: {
        cancelButton: 'btn custom-button-2 mx-2',
        confirmButton: 'btn custom-button bg-danger mx-2'
    },
    buttonsStyling: false
    })

    confirmBox1.fire({
    title: 'Are you sure?',
    text: "You'll be unlocking this investment ",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, unlock this investment!'
    }).then((result1) => {
    if (result1.isConfirmed) {



    const box1 = Swal.mixin({
    customClass: {
        confirmButton: 'btn custom-button-2 mx-2',
        denyButton: 'btn custom-button bg-secondary mx-2'
    },
    buttonsStyling: false
    })

    box1.fire({
        title: 'Enter your password',
        input: 'password',
        inputAttributes: {
            pattern: "[0-9]+",
            required: true,
        },
        inputValidator: (value) => {
            if (!value) {
            return 'You need to enter your password!'
            }

        },
        showDenyButton: true,
        denyButtonText: `Back`,
        confirmButtonText: 'Submit',
        }).then((result) => {
        if (result.isConfirmed) {

        this.$isLoading(true)

        let del_form =  new FormData();
        del_form.append('id', id)
        del_form.append('status', 1)
        del_form.append('password', result.value)

        axios.post('/api/broker/markInvestment', del_form,{headers:{"Content-Type":"application/json"}})
            .then((data) => {
                this.$isLoading(false)

                const confirmBox2 = Swal.mixin({
                customClass: {
                    confirmButton: 'btn custom-button-2',

                },
                buttonsStyling: false
                })

                confirmBox2.fire({
                icon: 'success',
                title: 'Unlocked!',
                text: 'Investment Unlocked! ... '  ,

                }).then((res)=>{
                    // reloading the page
                // window.location.href = '/agents';
                this.getResults()
                })



            })
            .catch((err) => {
                this.$isLoading(false)
            // show them if that city name is taken
                let errorText = 'Investment Unlocking Unsuccessful'

                if(err.response.data.errors['password']){
                    errorText = err.response.data.errors['password']
                }
                else if(err.response.data.errors['general']){
                    errorText = err.response.data.errors['general']
                }

                const confirmBox2 = Swal.mixin({
                customClass: {
                    confirmButton: 'btn custom-button bg-danger',

                },
                buttonsStyling: true
                })

                confirmBox2.fire({
                icon: 'error',
                title: 'Failed!',
                text: errorText,

                })
            });




        }
        else if (result.isDenied) {

        }
        })


    }
    })



    },
         

      },

     

    }
    </script>
