<template>
    <div v-if="$gate.isMerchantAdmin() && $gate.isRegistrationComplete() &&
    getUserProfile.verified_at !== null
    ">

        <div class="card  custom-card-1 m-0">
            <fieldset>
                <legend class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center">
                     <span>Merchant Management</span>

                    </div>
                   <!-- <router-link to="/create-merchant" class="nav-link" active-class="active" exact>
                       <button class="btn btn-outline-info">Create new Merchant <i class="fa fa-plus"></i></button>
                    </router-link> -->
                </legend>

            <div>

                <TabNav :tabs="['Active', 'Blocked', 'Admins']" :selected="selected" @selected="setSelected">
                    <Tab :isSelected="selected === 'Active' ">

                    </Tab>

                    <Tab :isSelected="selected === 'Blocked' ">
                    </Tab>


                    <Tab :isSelected="selected === 'Admins' ">
                    </Tab>
                </TabNav>

            </div>




            <div class="card-body mt-2 table-responsive">
                <table  id="example2" class="table table-hover">
                    <thead >
                    <tr class="text-center">
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Username</th>
                    <th>Email</th>

                    <th>Home Address</th>

                    <th class="border-left">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class='text-center' v-for='(row, index) in merchants' :key='index'>
                        <td>{{row.first_name}}</td>
                        <td>{{row.last_name}}</td>
                        <td>{{row.username}}</td>
                        <td>{{row.email}}</td>
                        <td>{{row.town_name}}</td>

                        <td class="d-flex flex-wrap  border-left justify-content-between" v-if="selected == 'Blocked'">
                            <button @click="openModal(row, 1)" class="btn mb-4 custom-button-ogreen1" >
                                <i class="fas fa-check"></i>

                            </button>
                            <router-link v-bind:to="'/view-user-profile/' + row.id"
                            active-class="active" exact class="btn mb-4 custom-button-purple1" >
                                <i class="fas fa-eye"></i>
                            </router-link>
                            <button class="btn custom-button mb-4 btn-danger">
                                <i class="fas fa-times"></i>

                            </button>
                        </td>
                        <td class="d-flex flex-wrap border-left justify-content-between" v-else-if="selected == 'Active'">
                            <router-link v-bind:to="'/view-user-profile/' + row.id"
                            active-class="active" exact class="btn mb-4 custom-button-purple1" >
                                <i class="fas fa-eye"></i>
                            </router-link>
                            <router-link v-if="selected == 'Approved Agents'" v-bind:to="'/edit-agent/' + row.id"
                            active-class="active" exact class="btn mb-4 custom-button btn-secondary" >
                                <i class="fas fa-edit"></i>
                            </router-link>
                            <button class="btn mb-4 custom-button btn-danger">
                                <i class="fas fa-user-slash"></i>
                            </button>
                        </td>



                    </tr>
                    <tfoot>

                    </tfoot>
                    </tbody>
                </table>
                <div class="pt-3">
                    <pagination align="center" :data="pageData" @pagination-change-page="getResults"></pagination>
                </div>

            </div>


            </fieldset>








        </div>
        <!-- /.card -->


        <Footer :getUserProfile='getUserProfile'/>

    </div>
    <div v-else>
        <not-found></not-found>

    </div>
</template>


<script>
export default {
  name: "Merchants",
  props: {},
  components: {
    TabNav: () =>
      import("../../includes/tabNav" /* webpackChunkName: "js/tab-nav" */),
    Tab: () => import("../../includes/tabs" /* webpackChunkName: "js/tabs" */),
  },
  data() {
    return {
      selected: "Active",
      selectedMerchant: {},
      balance: 0,
      searchBy: "",
      searchText: "",
      userInfo: "",
      form: new Form({
        agent_id: 0,
        pin: "",
        branch_id: "",
      }),

      merchants: [],
      company: [],
      pageData: {},
    };
  },
  created() {
    // fetch fee table
    this.$isLoading(true);

    this.getResults();
    axios
      .get("api/company/userCompany")
      .then(({ data }) => {
        this.company = data;
        this.$isLoading(false);
      })
      .catch((err) => {
        this.merchants = [];
        this.pageData = {};
        this.$isLoading(false);
      });
  },
  watch: {
    selected: function () {
      this.getResults();
    },
    getUserProfile: function () {
      if (this.$gate.isStore()) {
        document.getElementById("bladeCompanyName").innerHTML =
          this.getUserProfile.company_name;
        this.userInfo = this.getUserProfile;
        this.balance = this.dalasisFormatter(this.userInfo.balance);
        document.getElementById("bladeAmount").innerHTML = this.balance;
      }
    },
  },
  mounted() {
    this.$store.dispatch("userProfile");
  },
  computed: {
    getUserProfile() {
      //final output from here
      return this.$store.getters.getUserProfile;
    },
  },
  methods: {
    setSelected(tab) {
      this.selected = tab;
    },

    // getResults(page){
    //     if (typeof page === 'undefined') {
    //     page = 1;
    //     }
    //     this.agents = []
    //     this.pageData = {}
    //     let query = '';
    //     if(this.selected == 'Active'){
    //         query = 'active'
    //     }
    //     else if(this.selected == 'Blocked'){
    //         query = 'blocked'
    //     }

    //     else if(this.selected == 'Admins'){
    //         query = 'admins'
    //     }

    //     // axios.get('api/merchantAdmins/approvedMerchants' + query + '?page=' + page)
    //     axios.get('api/merchantAdmins/approvedMerchants')
    //         .then(({data}) => {
    //             console.log("merchant", data)
    //           this.agents = data.data;
    //           this.pageData = data

    //         }).catch((err)=>{
    //           this.agents = []
    //           this.pageData = {}

    //     })
    // },

    getResults(page) {
      if (typeof page === "undefined") {
        page = 1;
      }
      this.merchants = [];
      this.pageData = {};
      let query = "";
      if (this.selected == "Merchant Pending Approval") {
        query = "agentsPendingApproval";
      } else if (this.selected == "Active") {
        query = "approvedMerchants";
      } else if (this.selected == "Blocked") {
        query = "blockedMerchants";
      } else if (this.selected == "Admins") {
        query = "admins";
      }

      axios
        .get("api/merchantAdmins/" + query + "?page=" + page)
        .then(({ data }) => {
          this.merchants = data.data;
          this.pageData = data;
        })
        .catch((err) => {
          this.merchants = [];
          this.pageData = {};
        });
    },

    approveAgent() {
      $("#modal1").modal("hide");

      const confirmBox1 = Swal.mixin({
        customClass: {
          confirmButton: "btn custom-button-2 mr-2",
          cancelButton: "btn custom-button bg-danger ml-2",
        },
        buttonsStyling: false,
      });

      confirmBox1
        .fire({
          title: "Are you sure?",
          text: "You'll be blah blah blah...agent...blah.. accept.. ",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, I approve this agent!",
        })
        .then((result1) => {
          if (result1.isConfirmed) {
            const box1 = Swal.mixin({
              customClass: {
                confirmButton: "btn custom-button-2 mr-2",
                denyButton: "btn custom-button bg-secondary ml-2",
              },
              buttonsStyling: false,
            });

            box1
              .fire({
                title: "Enter your pin",
                input: "password",
                inputAttributes: {
                  maxlength: 4,
                  minlength: 4,
                  pattern: "[0-9]+",
                  required: true,
                },
                inputValidator: (value) => {
                  if (!value) {
                    return "You need to enter your 4 digit pin!";
                  }
                },
                showDenyButton: true,
                denyButtonText: `Back`,
                confirmButtonText: "Submit",
              })
              .then((result) => {
                if (result.isConfirmed) {
                  this.form.pin = result.value;

                  this.form
                    .post("api/agent/approve", {
                      headers: { "Content-Type": "application/json" },
                    })
                    .then((data) => {
                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button-2",
                        },
                        buttonsStyling: false,
                      });

                      confirmBox2
                        .fire({
                          icon: "success",
                          title: "Approved!",
                          text: "Agent Approved! ... ",
                        })
                        .then((res) => {
                          // reloading the page
                          // window.location.href = '/agents';
                          this.getResults();
                        });
                    })
                    .catch((err) => {
                      // come back later
                      let errorText =
                        typeof err.response.data.errors["pin"] !== "undefined"
                          ? err.response.data.errors["pin"][0]
                          : "";

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button bg-danger",
                        },
                        buttonsStyling: true,
                      });

                      confirmBox2.fire({
                        icon: "error",
                        title: "Failed!",
                        text: errorText, // take care later
                      });
                    });
                } else if (result.isDenied) {
                  $("#cashOnHandModal").modal("show");
                }
              });
          }
        });
    },

    openModal(row, modal_id) {
      this.selectedMerchant = row;
      this.form.agent_id = row.id;
      $("#modal" + modal_id).modal("show");
    },
  },
};
</script>
