<template>
    <div>
        <div v-if="$gate.isBankAdmin()" class="card  p-5 m-0">
            <fieldset>
                <legend class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center">
                     <span> {{bankInfo.bank_name}}</span>

                    </div>
                   <a href="#" class="nav-link" active-class="active" onclick="history.go(-1);return false;">
                       <button class="btn bg-nyanza font-weight-bold text-gray" >Go back</button>
                    </a>
                </legend>
                    <div class="card-header">
                    </div>


                <div>

                    <TabNav :tabs="tabs" :selected="selected" @selected="setSelected">
                        <Tab :isSelected="selected === 'General' ">

                            <div class="row mt-5">
                                <div class="col-12">
                                    <div class="d-flex justify-content-between flex-wrap border-bottom pb-3">
                                        <div class="d-flex align-items-center">
                                            <h5>General</h5>
                                        </div>
                                        

                                    </div>

                                </div>
                                <div class="col-8 pr-5">
                                    <div class="border-bottom font-weight-bold py-3">Bank Details</div>
                                    <div class="row py-3 border-bottom">
                                        <div class="col-6 ">
                                            Bank name
                                        </div>
                                        <div class="col-6">
                                            {{bankInfo.bank_name}}
                                        </div>
                                    </div>
                                   
                                    <div class="row py-3 border-bottom">
                                        <div class="col-6 ">
                                            Bank IP Address
                                        </div>
                                        <div class="col-6">
                                            {{bankInfo.bank_ip}}
                                        </div>
                                    </div>

                                   



                                </div>

                                <!-- <div class="col-4">
                                    <div class="border-bottom font-weight-bold py-3 mb-3">Avatar</div>
                                    <div class="image">
                                        <img class="img-fluid pad" src="/images/profiles/building-none.png" alt="">
                                    </div>
                                    
                                </div> -->
                            </div>
                        </Tab>

                    </TabNav>

                </div>



            </fieldset>












        </div>
        <div v-else>
            <not-found></not-found>

        </div>

    </div>
</template>

<script>


export default {
    name: 'ViewBank',
    props:{

    },
    components: {
        TabNav: () => import('../../includes/tabNav' /* webpackChunkName: "js/tab-nav" */),
        Tab: () => import('../../includes/tabs' /* webpackChunkName: "js/tabs" */),



    },
  data() {
    return {

        selected: 'General',
        logs: [],
        bankInfo: {},
        tabs: [],
        pageData: {},
      


    }
  },

  created(){
      if(this.$gate.isBankAdmin()){
        this.tabs =  ['General']
        this.$isLoading(true)

        let temp_form = new Form({
            id : this.$route.params.id
        })

        temp_form.post('/api/bank-admin/viewBank')
            .then(({data}) => {


                this.bankInfo = data
               

            this.$isLoading(false)

            }).catch((err)=>{
                this.bankInfo = 'error'
            this.$isLoading(false)

        });



      }
  },
    watch: {


    },
  methods:{













  },

}
</script>
