<template>
  <div v-if="$gate.isMerchant()">
    <div v-if="!$gate.isRegistrationComplete()">
      <div class="card px-4 py-2 border-0" style="box-shadow: none">
        <div class="card-body text-center">
          <h3>Congratulations for joining!</h3>
          <div style="font-size: 1.2rem">
            To be able to send and receive money, you must
            <router-link
              to="/set-pin"
              class="text-info"
              style="text-decoration: underline"
            >
              set your pin</router-link
            >
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="
        getUserProfile.phone_number !== null &&
        getUserProfile.verified_at === null
      "
    >
      <div class="card px-4 py-2 border-0" style="box-shadow: none">
        <div class="card-body text-center">
          <a
            href="sms-verification"
            style="text-decoration: underline"
            class="card-body border-bottom"
          >
            <div class="in">
              <div>Click here to Verify Phone Number</div>
            </div>
          </a>
        </div>
      </div>
    </div>

    <div
      v-else-if="$gate.isRegistrationComplete() && $gate.canTransact()"
      class="customer general"
    >
      <div class="row">
        <div class="col-12">
          <div class="section wallet-card-section pt-1">
            <div class="wallet-card">
              <!-- Balance -->
              <div class="balance">
                <div class="left">
                  <span class="title">Amount Collected (This Branch)</span>
                  <h1 class="total">{{ balance }}</h1>
                </div>
              </div>
              <!-- Wallet Footer -->
              <div class="wallet-footer"></div>
              <!-- * Wallet Footer -->
            </div>
          </div>
        </div>
      </div>

      <!-- transaction types graph -->
      <div class="row mt-3">
        <div class="col-md-12 col-lg-12">
          <div class="card">
            <div class="row">
              <div class="col-12">
                <h3 class="px-3 py-2 py-lg-3 card-title">Money Traffic</h3>
              </div>
              <div class="col-12 text-muted">
                <TabNav
                  :tabs="years"
                  :selected="selected"
                  @selected="setSelected"
                >
                </TabNav>
              </div>
              <div class="col-12">
                <div class="mt-3 d-flex justify-content-end mr-3">
                  <div class="">
                    <select
                      v-model="selectedStat"
                      id="stats"
                      class="form-control text-sm"
                    >
                      <option value="" disabled>Select Stats Type</option>
                      <option value="Transfers">Transfers</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="text-center py-3 font-weight-bold">
                  {{ chart1Title }}
                </div>

                <div v-if="selectedStat == 'Transfers'" class="row mb-4 ml-3">
                  <div class="d-flex justify-content-between flex-wrap text-xs">
                    <div class="pr-2">
                      <span class="text-muted">Incoming Total: </span>
                      <span
                        class="font-weight-bold"
                        style="color: rgba(81, 139, 201, 1)"
                      >
                        {{
                          dalasisFormatter(
                            chart1Selection["incoming"].reduce(
                              (total, num) => total + num,
                              0
                            )
                          )
                        }}
                      </span>
                    </div>

                    <div class="pr-2">
                      <span class="text-muted">Fees Total: </span>
                      <span
                        class="font-weight-bold"
                        style="color: rgba(187, 46, 114, 1)"
                      >
                        {{
                          dalasisFormatter(
                            chart1Selection["fees"].reduce(
                              (total, num) => total + num,
                              0
                            )
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>

                <MChart1 :chartData="chart1Selection" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Send :getUserProfile="getUserProfile" />
      <AgencyFooter :getUserProfile="getUserProfile" />
    </div>
  </div>
  <div v-else>
    <not-found></not-found>
  </div>
</template>

<script>
export default {
  name: "Dashboard",
  components: {
    TabNav: () =>
      import("./../includes/tabNav" /* webpackChunkName: "js/tab-nav" */),
    Tab: () => import("./../includes/tabs" /* webpackChunkName: "js/tabs" */),
    MChart1: () =>
      import(
        "./../charts/merchant/MChart1.vue" /* webpackChunkName: "js/m-chart-1" */
      ),
  },
  data() {
    return {
      selected: "",
      userInfo: "",
      transfers: [],
      transfers_count: 0,
      balance: 0,
      years: [],
      chart1Title: "Money Traffic",
      chart1Selection: {
        type: "Transfers",
        incoming: [0],
        outgoing: [0],
        deposits: [0],
        fees: [0],
        labels: [],
      },
      selectedStat: "Transfers",
    };
  },
  created() {
    if (
      this.$gate.isMerchant() &&
      this.$gate.isRegistrationComplete() &&
      this.$gate.canTransact()
    ) {
      this.getYears();
    }
  },
  watch: {
    getUserProfile: function () {
      this.userInfo = this.getUserProfile;
      this.balance = this.dalasisFormatter(
        this.userInfo.total_transfered_amount
      ); // commission here means the amounted collected
      document.getElementById("bladeAmount").innerHTML = this.balance;
      console.log("user", this.getUserProfile);
    },
    selected: function () {
      if (this.selected.length != 0) {
        this.getChart1();
      }
    },
    selectedStat: function () {
      if (this.selectedStat.length != 0) {
        this.getChart1();
      }
    },
  },

  mounted() {
    this.$store.dispatch("userProfile");
  },
  computed: {
    getUserProfile() {
      //final output from here
      return this.$store.getters.getUserProfile;
    },
  },
  methods: {
    setSelected(tab) {
      this.selected = tab;
    },

    getYears() {
      axios
        .post("api/merchant-dashboard/years")
        .then(({ data }) => {
          data.forEach((element) => {
            element.forEach((e) => {
              if (!this.years.includes(String(e))) {
                this.years.push(String(e));
              }
            });
          });

          if (this.years.length > 0) {
            this.selected = this.years[0];
          }
        })
        .catch((err) => {});
    },

    getChart1() {
      this.chart1Title = this.selectedStat + " (" + this.selected + ")";

      let form = new Form({
        year: this.selected,
        type: this.selectedStat,
      });

      this.$isLoading(true);

      form
        .post("api/merchant-dashboard/chart1")
        .then(({ data }) => {
          this.chart1Selection = { ...data };

          this.$isLoading(false);
        })
        .catch((err) => {
          this.$isLoading(false);
        });
    },
  },
};
</script>
