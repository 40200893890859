<template>
    <div class="customer ">
        <legend class="d-flex justify-content-center flex-wrap">
            <span class="d-flex align-items-center">My Profile</span>
        </legend>
        <div class="card custom-card-1 mt-2">
            <div class="row">
                <div class="col-12 pt-3 pt-lg-3 col-lg-4 px-2">

                    <div class="image text-center">
                        <img
                            class="img-fluid pad"
                            :src="'/images/profiles/' + userInfo.picture "
                            alt=""
                        />
                    </div>

                    
                    <div data-toggle="modal" data-target="#changePictureModal" class="btn text-info border-0 mt-2 w-100"
                    style="text-decoration: underline">
                        Change profile picture
                    </div>
                </div>
                <div class="col-12  col-lg-8 pt-3 pt-lg-0 pr-lg-5">


                    <div class="row py-3 border-bottom">
                        <div class="col-6 ">
                            First Name
                        </div>
                        <div class="col">
                            {{ userInfo.first_name }}
                        </div>
                    </div>
                    <div class="row py-3 border-bottom">
                        <div class="col-6 ">
                            Last Name
                        </div>
                        <div class="col">
                            {{ userInfo.last_name }}
                        </div>
                    </div>

                    <div class="row py-3 border-bottom">
                        <div class="col-6 ">
                            Username
                        </div>
                        <div class="col">
                            {{ userInfo.username }}
                        </div>
                    </div>

                    <div class="row py-3 border-bottom">
                        <div class="col-6 ">
                            Can Transact
                        </div>
                        <div class="col">
                            {{ userInfo.can_user_transact }}
                        </div>
                    </div>

                    <div class="row py-3 border-bottom">
                        <div class="col-6 ">
                            Email
                        </div>
                        <div class="col">
                            {{ userInfo.email }}
                        </div>
                    </div>
                    <div
                        v-if="userInfo.rank != 'Customer'"
                        class="row py-3 border-bottom"
                    >
                        <div class="col-6 ">
                            Address
                        </div>
                        <div class="col">
                            {{ userInfo.town_name }}
                        </div>
                        <router-link
                         to="change-userAddress">
                         <span class="btn text-info" style="text-decoration: underline">Update User Address</span>

                        </router-link>
                    </div>

                    <div class="row py-3 border-bottom">
                        <div class="col-6 ">
                            Phone Number
                        </div>
                        <div class="col">
                            {{ userInfo.phone_number }}
                        </div>
                    </div>

                    <div class="row py-3 border-bottom">
                        <div class="col-6 ">
                            Pin
                        </div>
                        <div class="col">
                            {{ userInfo.pin }}
                        </div>
                    </div>
                </div>
            </div>
        </div>





            <div class="modal fade" id="changePictureModal" tabindex="-1" role="dialog" 
            data-keyboard="false" data-backdrop="static"
            aria-labelledby="changePictureModalTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <form @submit.prevent='updatePicture'  method="post" enctype="multipart/form-data">
                    
                            <div class="modal-header border-0">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">

                                <div class="d-flex justify-content-center">
                                    <h2 class="swal2-title" id="exampleModalLongTitle">Change Profile Picture</h2>
                                </div>
                                
                                <div>


                                    <div class="image-container mx-auto">
                                        <div class="image-wrapper">
                                          <div id='image' class="modal-image">
                                              <img  :src="'images/profiles/' + userInfo.picture" alt="">
                                          </div>


                                            <div id="cancel-btn"><i class="fas fa-times"></i></div>

                                        </div>

                                    </div>

                                    <div class="text-center">
                                        <label for="file" class='btn btn-default base2'> Choose an image</label>

                                    </div>
                                    <input id="file" name='picture' type="file" @change='showSelected' hidden>

                              
                                </div>

        

                            </div>
                            <div class="modal-footer border-0 d-flex justify-content-center">
                                <button type="submit" class="btn custom-button-2 ">Update</button>
                            </div>
                        </form>
                    
                    </div>
                </div>
            </div>




        <Send :getUserProfile='getUserProfile' />
        <Footer />
    </div>
</template>

<script>

export default {
    name: "Profile",
    components: {

    },
    data() {
        return {
            userInfo: "",
            picture_form: {
            'image': '',
            'image_name':''
            },
        };
    },
    watch: {
        getUserProfile: function() {
            if (this.$gate.isCustomer()) {
                this.userInfo = this.getUserProfile;
                if((this.getUserProfile.balance ==0 || this.getUserProfile.balance ==0.00) && this.getUserProfile.used_kashlock_credit > 0){
                    this.getUserProfile.balance= -this.getUserProfile.used_kashlock_credit;
                 }
                document.getElementById('bladeAmount').innerHTML = this.dalasisFormatter(this.getUserProfile.balance)
                document.getElementById('bladeCreditAmount').innerHTML = this.dalasisFormatter(this.getUserProfile.kashlock_credit)
            if(this.getUserProfile.kashlock_credit > 0){
                document.getElementById('bladeCreditBlock').style.display = 'block';
            }
 
            }
        }
    },

    mounted() {
        this.$store.dispatch("userProfile");
    },
    computed: {
        getUserProfile() {
            //final output from here
            return this.$store.getters.getUserProfile;
        }
    },
    methods:{
            showSelected(event){
                var input = document.getElementById('file');

                this.picture_form.image = event.target.files[0];
                // console.log(this.picture_form.image)
                this.picture_form.image_name = input.files.item(0).name

                var upload_div = document.getElementById('image');

                upload_div.innerHTML = '<img style="width:100%;height:100%;object-fit:cover;" src=' + URL.createObjectURL(input.files[0]) + ' alt=' + input.files.item(0).name  +' >';

            },

        updatePicture(){
                const formData = new FormData();
                $('#changePictureModal').modal('toggle')

                formData.append('image', this.picture_form.image);
                formData.append('image_name', this.picture_form.image_name)
                this.$isLoading(true) 

                axios.post('api/profile/changePicture',formData,{headers:{"Content-Type":"application/json"}})
                    .then((data) => {
                        this.$isLoading(false) 

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button-2',
                        
                        },
                        buttonsStyling: false
                        })

                        confirmBox2.fire({
                        icon: 'success',
                        title: 'Picture Updated!',
                        

                        }).then((res)=>{
                            window.location.reload(); 
                       
                        })
                    })
                    .catch((err) => {
                        this.$isLoading(false) 

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button bg-danger',
                        
                        },
                        buttonsStyling: true
                        })

                        confirmBox2.fire({
                        icon: 'error',
                        title: 'Failed!',
                        text: 'Ops! Something went wrong!',

                        })

                    });
        }
    }
};
</script>

<style scoped>






</style>