<template>
  <div>
    <legend class="d-flex justify-content-between flex-wrap">
      <div class="d-flex align-items-center">
        <span></span>
      </div>
      <a
        href="#"
        class="nav-link"
        active-class="active"
        onclick="history.go(-1);return false;"
      >
        <button class="btn bg-nyanza font-weight-bold text-gray">
          Go back
        </button>
      </a>
    </legend>
    <div class="row">
      <div class="col-md-6 m-auto">
        <div class="card py-2 px-1">
          <div class="card-header">
            <h2>Credit / Debit card details</h2>
          </div>
          <div class="card-body text-center">
            <div v-if="card && !addnewCard">
              <div class="form-group d-flex align-items-center pt-2">
                <!-- <input type="checkbox" class="" id="card" checked /> -->
                <div for="card" class="credit-card" :class="[card.brand]">
                  <div class="credit-card-last4">
                    {{ card.last4 }}
                  </div>
                  <div class="credit-card-expiry">
                    {{ card.exp_month }} /
                    {{ card.exp_year }}
                  </div>
                </div>
                <button
                  class="btn btn-danger"
                  style="height: 63px"
                  @click="deleteCard"
                >
                  <i class="fa fa-trash"></i>
                </button>
              </div>

              <div class="form-group py-2" v-if="!addnewCard">
                <a href="" @click.prevent="addnew">+ Add new Card</a>
              </div>
            </div>

            <div v-else>
              <div class="form-group form-control pt-2">
                <StripeElements
                  :stripe-key="stripeKey"
                  :instance-options="instanceOptions"
                  :elements-options="elementsOptions"
                  #default="{ elements }"
                  ref="elms"
                >
                  <StripeElement
                    type="card"
                    :elements="elements"
                    :options="cardOptions"
                    ref="card"
                  />
                </StripeElements>
              </div>
              <div class="form-group py-2" v-if="card && addnewCard">
                <a href="" @click.prevent="savedcard">Saved card</a>
              </div>
            </div>

            <div class="list pb-4">
              <table class="winner-table">
                <tr>
                  <td>
                    <b>Subtotal</b>
                  </td>
                  <td>
                    <span>{{ product.currency_sign }} </span>

                    {{ product.product_price }}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Service Fee {{ product.service_fee }} %</b>
                  </td>
                  <td>
                    <span>{{ product.currency_sign }} </span>
                    {{ (product.product_price * product.service_fee) / 100 }}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Total Amount</b>
                  </td>
                  <td>
                    <span>{{ product.currency_sign }} </span>
                    {{
                      product.product_price +
                      (product.product_price * product.service_fee) / 100
                    }}
                  </td>
                </tr>

                <tr>
                  <td>
                    <b>Deposit on your kashma account</b>
                  </td>
                  <td>{{ dalasisFormatter(product.converted_amount) }}</td>
                </tr>
              </table>
            </div>

            <button
              v-if="!addnewCard"
              @click="paywithsavecard"
              :disabled="noErrors"
              class="btn btn-success"
              type="button"
            >
              Pay <span>{{ product.currency_sign }} </span
              >{{
                product.product_price +
                (product.product_price * product.service_fee) / 100
              }}
            </button>
            <button
              v-else
              @click="pay"
              :disabled="noErrors"
              class="btn btn-success"
              type="button"
            >
              Pay <span>{{ product.currency_sign }} </span
              >{{
                product.product_price +
                (product.product_price * product.service_fee) / 100
              }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

  <script>
import { StripeElements, StripeElement } from "vue-stripe-elements-plus";

export default {
  name: "PaymentSimple",

  components: {
    StripeElements,
    StripeElement,
  },

  data() {
    return {
      // stripeKey: 'pk_test_51IL495Kk5NyjWfSKzrigU8a3vZYm7zfrDmYKp8nBBGxE2dGxt4iaH6N4aanXxGiChdm9esnNzt3OlbNtUuH1mxgW00erysMah3', // test key, don't hardcode
      stripeKey:
        "pk_test_51MknRvAtGHrTBfhjpY3gz3egrxiapvSKXCsKQrQt7dIBr1omKHlrzWPfY0aZ9xZ1RWw78367TPUarSYbYg8yA8HZ00RZm6aTSr", // test key, don't hardcode

      instanceOptions: {
        // https://stripe.com/docs/js/initializing#init_stripe_js-options
      },
      elementsOptions: {
        // https://stripe.com/docs/js/elements_object/create#stripe_elements-options
      },
      cardOptions: {
        // reactive
        // remember about Vue 2 reactivity limitations when dealing with options
        value: {
          postalCode: "",
        },
        // https://stripe.com/docs/stripe.js#element-options
      },
      product: "",
      card: "",
      noErrors: false,
      addnewCard: true,
    };
  },

  created() {
    axios
      .get("/api/customer/stripe_cards")
      .then(({ data }) => {
        this.card = data;
        this.$isLoading(false);
        console.log(data);
        if (data) {
          this.addnewCard = false;
        }
      })
      .catch((err) => {
        this.card = [];
        this.$isLoading(false);
      });

    let temp_form = new Form({
      id: this.$route.params.id,
    });
    // fetch PRODUCTS table
    temp_form
      .post("/api/customer/products")
      .then(({ data }) => {
        console.log("products", data);
        this.product = data;
        this.$isLoading(false);
      })
      .catch((err) => {
        this.product = "";
        this.$isLoading(false);
      });
  },

  methods: {
    addnew() {
      this.addnewCard = true;
    },
    savedcard() {
      this.addnewCard = false;
    },

    paywithsavecard() {
      console.log("dfgdfg");
      this.noErrors = true;

      // prompt for pin
      const box1 = Swal.mixin({
        customClass: {
          confirmButton: "btn custom-button bg-danger  mr-2",
          denyButton: "btn custom-button-2 ml-2",
        },
        buttonsStyling: false,
      });
      box1
        .fire({
          title: "Enter your pin",
          text:
            "To confirm that you want to deposit " +
            this.dalasisFormatter(this.product.product_price) +
            " to your account",
          input: "password",
          allowOutsideClick: false,
          inputAttributes: {
            required: true,
          },
          inputValidator: (value) => {
            if (!value) {
              return "You need to enter your pin!";
            }
          },
          showDenyButton: true,
          denyButtonText: `Back`,
          confirmButtonText: "Submit",
        })
        .then((result) => {
          if (result.isConfirmed) {
            let temp_form = new Form({
              saved_card: true,
              product_id: this.$route.params.id,
              pin: result.value,
            });

            // fetch PRODUCTS table
            temp_form
              .post("/api/customer/product/payment")
              .then(({ data }) => {
                console.log("products", data);
                this.$isLoading(false);
                this.noErrors = false;
                let confirmBox = Swal.mixin({
                  customClass: {
                    confirmButton: "btn custom-button-2",
                  },
                  buttonsStyling: false,
                });

                confirmBox
                  .fire({
                    icon: "success",
                    title: "Payment successful!",
                    text: "Payment successfully completed!",
                  })
                  .then((res) => {
                    // redirect user to dashboard
                    window.location.href = "/dashboard";
                    //location.reload();
                  });
              })
              .catch((err) => {
                this.$isLoading(false);
                this.noErrors = false;
                let errorText = "Payment Failed!";

                if (err.response.data.errors["product_id"]) {
                  errorText = err.response.data.errors["product_id"];
                } else if (err.response.data.errors["stripe_token"]) {
                  errorText = err.response.data.errors["stripe_token"];
                } else if (err.response.data.errors["message"]) {
                  errorText = err.response.data.errors["message"];
                }
                const confirmBox = Swal.mixin({
                  customClass: {
                    confirmButton: "btn custom-button bg-danger",
                  },
                  buttonsStyling: true,
                });

                confirmBox.fire({
                  icon: "error",
                  title: "Failed!",
                  text: errorText,
                });
              });
          } else {
            this.noErrors = false;
          }
        });
    },

    pay() {
      console.log("dfg");

      this.noErrors = true;
      // ref in template
      const groupComponent = this.$refs.elms;
      const cardComponent = this.$refs.card;
      // Get stripe element
      const cardElement = cardComponent.stripeElement;

      // Access instance methods, e.g. createToken()
      groupComponent.instance.createToken(cardElement).then((result) => {
        // Handle result.error or result.token
        if (result.token) {
          console.log(result.token);

          // prompt for pin
          const box1 = Swal.mixin({
            customClass: {
              confirmButton: "btn custom-button bg-danger  mr-2",
              denyButton: "btn custom-button-2  ml-2",
            },
            buttonsStyling: false,
          });
          box1
            .fire({
              title: "Enter your pin",
              text:
                "To confirm that you want to deposit " +
                this.dalasisFormatter(this.product.product_price) +
                " to your account",
              input: "password",
              allowOutsideClick: false,
              inputAttributes: {
                required: true,
              },
              inputValidator: (value) => {
                if (!value) {
                  return "You need to enter your pin!";
                }
              },
              showDenyButton: true,
              denyButtonText: `Back`,
              confirmButtonText: "Submit",
            })
            .then((result1) => {
              if (result1.isConfirmed) {
                let temp_form = new Form({
                  stripe_token: result.token.id,
                  product_id: this.$route.params.id,
                  update_card: this.addnewCard,
                  pin: result1.value,
                });

                // fetch PRODUCTS table
                temp_form
                  .post("/api/customer/product/payment")
                  .then(({ data }) => {
                    console.log("products", data);
                    this.$isLoading(false);
                    this.noErrors = false;
                    let confirmBox = Swal.mixin({
                      customClass: {
                        confirmButton: "btn custom-button-2",
                      },
                      buttonsStyling: false,
                    });

                    confirmBox
                      .fire({
                        icon: "success",
                        title: "Payment successful!",
                        text: "Payment successfully completed!",
                      })
                      .then((res) => {
                        // redirect user to dashboard
                        window.location.href = "/dashboard";
                        //location.reload();
                      });
                  })
                  .catch((err) => {
                    this.$isLoading(false);
                    this.noErrors = false;
                    let errorText = "Payment Failed!";

                    if (err.response.data.errors["product_id"]) {
                      errorText = err.response.data.errors["product_id"];
                    } else if (err.response.data.errors["stripe_token"]) {
                      errorText = err.response.data.errors["stripe_token"];
                    } else if (err.response.data.errors["message"]) {
                      errorText = err.response.data.errors["message"];
                    }
                    const confirmBox = Swal.mixin({
                      customClass: {
                        confirmButton: "btn custom-button bg-danger",
                      },
                      buttonsStyling: true,
                    });

                    confirmBox.fire({
                      icon: "error",
                      title: "Failed!",
                      text: errorText,
                    });
                  });
              } else {
                this.noErrors = false;
              }
            });
        } else {
          const confirmBox2 = Swal.mixin({
            customClass: {
              confirmButton: "btn custom-button bg-danger",
            },
            buttonsStyling: true,
          });

          confirmBox2.fire({
            icon: "error",
            title: "Failed!",
            text: result.error.message,
          });
        }
      });
    },

    deleteCard() {
      // prompt for pin
      const box1 = Swal.mixin({
        customClass: {
          confirmButton: "btn custom-button bg-danger  mr-2",
          denyButton: "btn custom-button-2   ml-2",
        },
        buttonsStyling: false,
      });
      box1
        .fire({
          title: "Enter your pin",
          text: "To confirm that you want delete this card",
          input: "password",
          allowOutsideClick: false,
          inputAttributes: {
            required: true,
          },
          inputValidator: (value) => {
            if (!value) {
              return "You need to enter your pin!";
            }
          },
          showDenyButton: true,
          denyButtonText: `Back`,
          confirmButtonText: "Submit",
        })
        .then((result) => {
          if (result.isConfirmed) {
            let temp_form = new Form({
              pin: result.value,
            });
            temp_form
              .post("/api/customer/deleteCard")
              .then(({ data }) => {
                console.log("cards", data);
                this.$isLoading(false);
                this.noErrors = false;
                let confirmBox = Swal.mixin({
                  customClass: {
                    confirmButton: "btn custom-button-2",
                  },
                  buttonsStyling: false,
                });

                confirmBox
                  .fire({
                    icon: "success",
                    title: "Deleted successful!",
                    text: "Card successfully removed!",
                  })
                  .then((res) => {
                    // redirect user to dashboard
                    window.location.href = "/dashboard";
                    //location.reload();
                  });
              })
              .catch((err) => {
                this.$isLoading(false);
                this.noErrors = false;
                let errorText = "Failed!";

                if (err.response.data.errors["pin"]) {
                  errorText = err.response.data.errors["pin"];
                } else if (err.response.data.errors["message"]) {
                  errorText = err.response.data.errors["message"];
                }
                const confirmBox = Swal.mixin({
                  customClass: {
                    confirmButton: "btn custom-button bg-danger",
                  },
                  buttonsStyling: true,
                });

                confirmBox.fire({
                  icon: "error",
                  title: "Failed!",
                  text: errorText,
                });
              });
          }
        });
    },
  },
};
</script>