<template>
    <div>
      <div v-if="$gate.isBroker()">
        <div class="row mt-3 border-bottom">
          <div class="col-12 col-sm-6 col-md-4">
            <div class="info-box">
            <span class="info-box-icon text-success"
              ><i class="fas fa-hand-holding-usd"></i
            ></span>

            <div class="info-box-content">
              <span class="info-box-text">
                <span class="btn-outline-info">{{ this.totalLockInvestment  }}</span>
              </span>

              <span class="info-box-text"
                >Total Locked Investments</span
              >
              <span class="info-box-number">{{ this.dalasisFormatter(this.totalLockInvestmentAmount)  }}</span>
            </div>
            <!-- /.info-box-content -->
          </div>
          <!-- /.info-box -->
          </div>
          <div class="col-12 col-sm-6 col-md-4">
            <div class="info-box">
            <span class="info-box-icon text-danger"
              ><i class="fas fa-hand-holding-usd"></i
            ></span>

            <div class="info-box-content">
              <span class="info-box-text">
                <span class="btn-outline-info">{{ this.totalUnlockKashlocks  }}</span>
              </span>

              <span class="info-box-text"
                >Total Unlocked Investments</span
              >
              <span class="info-box-number">{{ this.dalasisFormatter(this.totalUnlockInvestmentAmount)  }}</span>
            </div>
            <!-- /.info-box-content -->
          </div>
          <!-- /.info-box -->
          </div>
  
          <div class="col-12 col-sm-6 col-md-4">
            <div class="info-box">
            <span class="info-box-icon text-brown1"
              ><i class="fas fa-hand-holding-usd"></i
            ></span>

            <div class="info-box-content">
              <span class="info-box-text">
                <span class="btn-outline-info">{{ this.totalEarning  }}</span>
              </span>

              <span class="info-box-text"
                >Total Expected Earning</span
              >
              <span class="info-box-number">{{ this.dalasisFormatter(this.totalEarningAmount)  }}</span>
            </div>
            <!-- /.info-box-content -->
          </div>
          <!-- /.info-box -->
          </div>
          
  
        </div>
   
  
      
        <div  class="row mt-3">
        <div class="col-md-12 col-lg-12">
          <div class="card">
            <div class="row">
              <div class="col-12">
                <h3 class="p-3 card-title">Investment & Expected Earnings</h3>
              </div>
              <div class="col-12 text-muted">
                <TabNav
                  :tabs="[
                    'Investments per financial service',
                    'Last Year',
                    'This Year',
                    'Monthly',
                    'Weekly',
                    'Daily',
                  ]"
                  :selected="selected"
                  @selected="setSelected"
                >
                </TabNav>
              </div>

              <div class="col-12 pt-2">
                <div class="text-center py-3 font-weight-bold">
                  {{ chart1Title }}
                </div>
                <div class="mb-4 ml-3">
                  <div>
                    <span class="text-muted">Total Investment: </span>
                    <span
                      class="font-weight-bold"
                      style="color: rgba(15, 45, 54, 1)"
                    >
                      {{
                        dalasisFormatter(
                          chart1Selection["total_investment"].reduce(
                            (total, num) => total + num,
                            0
                          )
                        )
                      }}
                    </span>
                  </div>
                  <div>
                    <span class="text-muted">Total  Expected Earning: </span>
                    <span
                      class="font-weight-bold"
                      style="color: rgb(63, 146, 194)"
                    >
                      {{
                        dalasisFormatter(
                          chart1Selection["total_earning"].reduce(
                            (total, num) => total + num,
                            0
                          )
                        )
                      }}
                    </span>
                  </div>
                </div>
                <STChart2 :chartData="chart1Selection" />
              </div>
            </div>
          </div>
        </div>

     
      </div>
    </div>
  </div>
  </template>
  <script>
  import STChart2 from "../../../charts/kashlock/STChart2.vue";
  import Tab from "../../../includes/tabs.vue";
import TabNav from "../../../includes/tabNav.vue";
  export default {
    name: "BrokerKashlockDashboard",
    components: {
    STChart2,
    TabNav,
    Tab,
  },
  data() {
    return {
        totalLockKashlocks:0,
        totalUnlockKashlocks:0,
        totalLockInvestment:0,
        totalLockKashlocksAmount:0,
        totalUnlockKashlocksAmount:0,
        totalLockInvestmentAmount:0,
        totalUnlockInvestmentAmount:0,
        totalEarning:0,
        totalEarningAmount:0,
        chart1Selection: {
          total_investment: [0],
          total_earning: [0],
      },
      chart1Title: "Daily Investment & Expected Earnings",
      selected: "Daily",
     }
    },
    created() {
        this.getKashlockCount();
    },
  watch:{
    getInvestmentChart1: function () {
      this.chartData = this.getInvestmentChart1;
      this.chart1Selection = this.getInvestmentChart1["daily"];
    },
    selected: function () {
      if (this.selected == "Daily") {
        this.chart1Selection = this.getInvestmentChart1["daily"];
        this.chart1Title = "Daily Investment & Expected Earnings";
        this.chart1BrokerTotal = this.getInvestmentChart1["daily"]["broker"];
      } else if (this.selected == "Weekly") {
        this.chart1Selection = this.getInvestmentChart1["weekly"];
        this.chart1Title = "Weekly Investment & Expected Earnings";
      } else if (this.selected == "Monthly") {
        this.chart1Selection = this.getInvestmentChart1["monthly"];
        this.chart1Title = "Monthly Investment & Expected Earnings";
      } else if (this.selected == "This Year") {
        this.chart1Selection = this.getInvestmentChart1["yearly"];
        this.chart1Title = "This Year's Investment & Expected Earnings";
      } else if (this.selected == "Last Year") {
        this.chart1Selection = this.getInvestmentChart1["last year"];
        this.chart1Title = "Last Year's Investment & Expected Earnings";
      } else if (this.selected == "Investments per financial service") {
        this.chart1Selection = this.getInvestmentChart1["period"];
        this.chart1Title = "Investments per Financial Service";
      }
    },
  
  },
  mounted() {
    if (this.$gate.isBroker()) {
      this.$store.dispatch("userProfile");
      this.$store.dispatch("investmentChart1Data");

 
    }
  },
  computed: {
    getUserProfile() {
      //final output from here
      return this.$store.getters.getUserProfile;
    },
    getInvestmentChart1() {
      console.log("chart1 data", this.$store.getters.getInvestmentChart1);
      return this.$store.getters.getInvestmentChart1;
    },
   
  },

  methods:{
    setSelected(tab) {
      this.selected = tab;
    },
    getKashlockCount() {
      axios
        .get("api/broker/investmentCount")
        .then(({ data }) => {
  
          this.totalLockKashlocks = data.totalLockKashlocks;
          this.totalUnlockKashlocks = data.totalUnlockKashlocks;
          this.totalLockInvestment = data.totalLockInvestment;
          this.totalLockInvestment = data.totalLockInvestment;
          this.totalUnlockKashlocks = data.totalUnlockKashlocks;
          this.totalLockKashlocksAmount = data.totalLockKashlocksAmount;
          this.totalUnlockKashlocksAmount = data.totalUnlockKashlocksAmount;
          this.totalLockInvestmentAmount = data.totalLockInvestmentAmount;
          this.totalUnlockInvestmentAmount = data.totalUnlockInvestmentAmount;
          this.totalEarning = data.totalEarning;
          this.totalEarningAmount = data.totalEarningAmount;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  }
  }
  </script>
  
  
  
  
  