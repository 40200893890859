<template>
    <div>
        <div v-if="$gate.isBroker()" class="card  custom-card-1 m-0">
            <fieldset>
                <legend class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center justify-content-between w-100">
                     <span>Payments Management</span>
                     <div class="d-flex align-items-center justify-content-end">
                                            <button class="btn btn-success"  @click="downloadFile"><i class="fas fa-file-export"></i> Export Report</button>
                       </div>
                    </div>
                   
                </legend>

                <div class="card-header">
                    <SearchFilter :data_length='1' :isFound='isFound'
                        :filters='filters' data_type="payments" @search-form='makeSearch'
                        />

                </div>


            <div>


            </div>




            <div class="card-body mt-2 table-responsive">
                <table  id="example2" class="table table-hover border-top">
                    <thead >
                    <tr class="text-center">
                    <th>Sr No.</th>
                    <th>Customer Name</th>
                    <th>Phone Number</th>
                    <th>Amount</th>
                    <th>Fee</th>
                    <th>Kashma Amount</th>
                    <th>Receipt</th>
                    <th>Status</th>
                    <th>Date</th>
                    <th class="border-left">Action</th>
                    </tr>
                    </thead>
                    <tbody v-if="payments.length > 0">
                    <tr class='text-center' v-for='(row, index) in payments' :key='index'>
                        <td>{{ index + 1 }}</td>
                        <td>{{row.username}}
                            <span v-if="row.payment_status==1" >
                                <i class="fas fa-ban text-danger"></i>


                            </span>

                        </td>
                        <td>
                            {{row.phone_number}}</td>
                        <td><span>{{ row.currency_sign}}</span>
                            
                             
                             {{row.amount}}</td>
                        
                        <td><span>{{ row.currency_sign}}</span>
                              {{row.service_fee}}</td>
                        <td>{{dalasisFormatter(row.exchange_amount)}}</td>
                        <td><a :href="[row.receipt_url]" target="_blank">Click Here</a></td>
                        <td v-if="row.payment_status=='succeeded'"><span class="badge badge-success badge-pill">{{ row.payment_status }}</span></td>
                        <td v-else><span class="badge badge-danger badge-pill">{{ row.payment_status }}</span></td>

                        <td>{{ row.date }}</td>

                        <td class="d-flex flex-wrap border-left justify-content-around">
                           
                            
                            <router-link v-bind:to="'/view-payment/' + row.Sid"
                            active-class="active" exact class="btn mb-4 custom-button-purple1" >
                                <i class="fas fa-eye"></i>
                            </router-link>
                         
                          

                        </td>



                    </tr>
                    <tfoot>


                    </tfoot>
                    </tbody>
                    <tbody  v-else>
                                    <tr class='text-center'>
                                    
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td><span><i>{{ emptyDataText }}</i></span></td>
                                        <td></td>
                                        <td></td>
                                        
                                    </tr>
                    
                                </tbody>
                </table>
                <div class="pt-3">
                    <pagination align="center" :data="pageData" @pagination-change-page="getResults"></pagination>

                </div>

            </div>


            </fieldset>








        </div>
        <div v-else>
            <not-found></not-found>

        </div>
    </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
export default {
    name: 'Payments',
    props:{

    },
    components: {
        Datepicker,
        SearchFilter: () => import('../../includes/SearchFilter.vue' /* webpackChunkName: "js/search-filter" */),

    },
 
  data() {
    return {
        
        searchBy : '',
        searchForm: null,
        isFound: false,

        form: null,

        payments: [],
        pageData: {},
        emptyDataText: '',
        filters: [
            {
                'id': 1,
               'text': 'Customer',
               'value': 'customer_to' 
            },
            {
                'id': 2,
                'text': 'Amount',
                'value': 'amount'
            },
            {
                'id': 3,
                'text': 'Fee Paid',
                'value': 'fee_paid'
            },
            {
                'id': 4,
                'text': 'Payment Status',
                'value': 'payment_status'
            },
            {
                'id': 5,
                'text': 'Date',
                'value': 'date'
            },
        ],
    }
  },
  created(){
      // fetch fee table
      if(this.$gate.isBroker()){
            this.getResults()

        }

  },
    watch: {


    },
  methods:{

    downloadFile(){
        this.searchForm.post('/api/broker/export_payments', {
            validateStatus: (s) => s <= 500,
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            }
            }).then((response) => {

                    this.$isLoading(false);
                    console.log('res', response);
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    console.log(url);
                    const link = document.createElement('a');
                    link.href = url;

                    link.setAttribute('download', 'payments-report.xlsx'); //or any other extension
                    document.body.appendChild(link);
                    link.click();


                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button-2',

                        },
                        buttonsStyling: false
                        })

                        confirmBox2.fire({
                        icon: 'success',
                        title: 'Payments report exported Sucessfully!',

                        }).then((res)=>{

                         window.location.reload()


                        })



                    })
                    .catch((err) => {
                        this.$isLoading(false)

                        // show them if that city name is taken
                        let errorText = 'Payments report exported Unsuccessful'
                     });
        },
    makeSearch(data){
           
            this.searchForm = data
            this.getResults()
            // emit up
        },



        getResults(page){
            if (typeof page === 'undefined') {
            page = 1;
            }
            
            this.$isLoading(true)
            if(this.searchForm == null || (this.searchForm.search_text == '' && (this.searchForm.fromdate == null || this.searchForm.fromdate == ''))){

            axios.get('api/broker/payments?page=' + page)
                .then(({data}) => {
                  console.log("Payments", data)
                  
                    this.$isLoading(false)
                    if(data.data.length != 0){
                        this.payments = data.data;
                        console.log(this.payments.length);
                        this.pageData = data
                    }else{
                        this.payments = [];
                        this.pageData = {}
                        this.emptyDataText = 'You have no Payments.'
                    }


                }).catch((err)=>{
                  this.payments = []
                  this.pageData = {}
                this.$isLoading(false)


            })

            }else{

                this.searchForm.post('api/broker/payments/makeSearch?page=',{headers:{"Content-Type":"application/json"}})
                .then(({data}) => {
                  console.log("Payments", data)
                  this.isFound=  true;
                  this.payments = data.data;
                  console.log(this.payments.length);
                  this.pageData = data
                    this.$isLoading(false)
                    this.emptyDataText = 'No search results found...'

                }).catch((err)=>{
                  this.payments = []
                  this.pageData = {}
                this.$isLoading(false)
                this.emptyDataText = 'Something went wrong!'


            })
            }

        },



    },

        



}
</script>
<style scoped>
.w-80{
    width: 100%;
}

</style>