<template>
    <div>
        <div v-if="$gate.isBroker()" class="card  p-5 m-0">
            <fieldset>
                <legend class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center">
                     <span> {{paymentInfo.username}}</span>

                    </div>
                   <a href="#" class="nav-link" active-class="active" onclick="history.go(-1);return false;">
                       <button class="btn bg-nyanza font-weight-bold text-gray" >Go back</button>
                    </a>
                </legend>
                    <div class="card-header">
                    </div>


                <div>

                    <TabNav :tabs="tabs" :selected="selected" @selected="setSelected">
                        <Tab :isSelected="selected === 'General' ">

                            <div class="row mt-5">
                                <div class="col-12">
                                    <div class="d-flex justify-content-between flex-wrap border-bottom pb-3">
                                        <div class="d-flex align-items-center">
                                            <h5>General</h5>
                                        </div>
                                      

                                    </div>

                                </div>
                                <div class="col-8 pr-5">
                                    <div class="border-bottom font-weight-bold py-3">Payment Details</div>
                                    <div class="row py-3 border-bottom">
                                        <div class="col-6 ">
                                            Transaction ID
                                        </div>
                                        <div class="col-6">
                                            {{paymentInfo.transaction_id}}
                                        </div>
                                    </div>
                                    <div class="row py-3 border-bottom">
                                        <div class="col-6 ">
                                            Username
                                        </div>
                                        <div class="col-6">
                                            {{paymentInfo.username}}
                                        </div>
                                    </div>
                                    <div class="row py-3 border-bottom">
                                        <div class="col-6 ">
                                            Phone Number
                                        </div>
                                        <div class="col-6">
                                            {{paymentInfo.phone_number}}
                                        </div>
                                    </div>
                                    <div class="row py-3 border-bottom">
                                        <div class="col-6 ">
                                        Amount
                                        </div>
                                        <div class="col-6">
                                            <span>{{ paymentInfo.currency_sign}}</span>
                                             {{paymentInfo.amount}}
                                        </div>
                                    </div>
                                    <div class="row py-3 border-bottom">
                                        <div class="col-6 ">
                                       Service fee
                                        </div>
                                        <div class="col-6">
                                            <span>{{ paymentInfo.currency_sign}}</span>
                                            {{paymentInfo.service_fee}}
                                        </div>
                                    </div>
                                    <div class="row py-3 border-bottom">
                                        <div class="col-6 ">
                                        Currency
                                        </div>
                                        <div class="col-6">
                                            {{paymentInfo.currency}}
                                        </div>
                                    </div>
                                    <div class="row py-3 border-bottom">
                                        <div class="col-6 ">
                                        Kashma Dalasis Amount
                                        </div>
                                        <div class="col-6">
                                            {{dalasisFormatter(paymentInfo.exchange_amount)}}
                                        </div>
                                    </div>
                                    <div class="row py-3 border-bottom">
                                        <div class="col-6 ">
                                            Service fee Dalasis Amount
                                        </div>
                                        <div class="col-6">
                                            {{dalasisFormatter(paymentInfo.service_fee_exchange_amount)}}
                                        </div>
                                    </div>

                                    <div class="row py-3 border-bottom">
                                        <div class="col-6 ">
                                        Receipt url
                                        </div>
                                        <div class="col-6">
                                            <a :href="[paymentInfo.receipt_url]" target="_blank">Click Here</a>
                                        </div>
                                    </div>
                                    <div class="row py-3 border-bottom">
                                        <div class="col-6 ">
                                        Date
                                        </div>
                                        <div class="col-6">
                                            {{paymentInfo.date}}
                                        </div>
                                    </div>
                                    <div class="row py-3 border-bottom">
                                        <div class="col-6 ">
                                           Payment Status
                                        </div>
                                        <div class="col-6">
                                            {{paymentInfo.payment_status}}
                                        </div>
                                    </div>
                                   




                                </div>

                                <div class="col-4">
                                    <div class="border-bottom font-weight-bold py-3 mb-3">Avatar</div>
                                    <div class="image">
                                        <img class="img-fluid pad" src="/images/profiles/building-none.png" alt="">
                                    </div>
                                    <!-- <div v-if="canEdit" class="btn btn-info w-100">
                                        Change profile picture
                                    </div> -->
                                </div>
                            </div>
                        </Tab>






                    </TabNav>

                </div>



            </fieldset>


        </div>
        <div v-else>
            <not-found></not-found>

        </div>

    </div>
</template>

<script>


export default {
    name: 'ViewPayment',
    props:{

    },
    components: {
        TabNav: () => import('../../includes/tabNav' /* webpackChunkName: "js/tab-nav" */),
        Tab: () => import('../../includes/tabs' /* webpackChunkName: "js/tabs" */),
    },
  data() {
    return {

        selected: 'General',
        logs: [],
       paymentInfo: {},
        tabs: [],
        pageData: {},
      


    }
  },

  created(){
      if(this.$gate.isBroker()){
        this.tabs =  ['General']
        this.$isLoading(true)

        let temp_form = new Form({
            id : this.$route.params.id
        })

        temp_form.post('/api/broker/viewPayments')
            .then(({data}) => {


                this.paymentInfo = data
             

            this.$isLoading(false)

            }).catch((err)=>{
                this.paymentInfo = 'error'
            this.$isLoading(false)

        });



      }
  },
    watch: {

        selected: function(){

            if(this.selected == 'Logs')
                this.getLogs();


        },

    },
  methods:{

},

}
</script>
