<template>
  <div v-if="$gate.isAgent() || $gate.isAgentAdmin()">
    <div
      v-if="$gate.canTransact() && $gate.isRegistrationComplete()"
      class="card custom-card-1 m-0"
    >
      <fieldset>
        <legend class="d-flex justify-content-between flex-wrap">
          <span class="d-flex align-items-center">Completed Withdrawals</span>
          <div v-if="$gate.isAgentAdmin()" class="">
            <select
              v-if="
                $gate.isAgentAdmin() && companyInfo.admin_branches.length > 1
              "
              v-model="branch"
              required
              class="form-control"
              name=""
              id=""
            >
              <option value="all" selected>All</option>
              <option
                v-for="branch in companyInfo.admin_branches"
                :key="branch.id"
                :value="branch.id"
              >
                {{ branch.town_name }} - Branch
              </option>
            </select>
          </div>
        </legend>

        <div>
          <SearchFilter
            :data_length="withdrawals.length"
            :filters="filters"
            data_type="completed"
            :isFound="isFound"
            @search-form="makeSearch"
            style="display: inline-block; margin-right: 15px"
          />
          <button
            @click="acceptRequest()"
            class="btn btn-success"
            style="display: inline-block; font-size: 18px"
          >
            Accept Withdrawal
          </button>
        </div>
        <!-- /.card-header -->
        <div v-if="withdrawals.length != 0" class="card-body table-responsive">
          <table
            id="example2"
            class="table table-bordered table-hover border-top"
          >
            <thead>
              <tr class="text-center">
                <th>ID</th>
                <th>Branch from</th>
                <th>Customer / Merchant</th>
                <th>User Type</th>
                <th>Amount</th>
                <th>Fee Paid</th>
                <th>Time Sent</th>
                <th>Time Completed</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="text-center"
                v-for="(row, index) in withdrawals"
                :key="index"
              >
                <td>{{ row.unique_id }}</td>

                <td>
                  {{ row.agency_from_branch }}
                </td>
                <td>
                  <router-link
                    v-if="row.user_type === 'Customer'"
                    v-bind:to="'/view-user-profile/' + row.customer_id"
                  >
                    {{ row.customer_fullname }} <br />
                    <span> username: {{ row.customer_username }} </span>
                  </router-link>

                  <router-link
                    v-if="row.user_type === 'Merchant'"
                    v-bind:to="'/view-merchant-profile/' + row.company_id"
                  >
                    {{ row.merchant_store_name }} <br />
                  </router-link>
                </td>
                <td>
                  {{ row.user_type }}
                </td>

                <td>
                  {{ dalasisFormatter(row.amount - row.fee_paid) }}
                </td>

                <td>
                  {{ dalasisFormatter(row.fee_paid) }}
                </td>

                <td>{{ row.time_sent }}</td>
                <td>
                  {{ row.time_updated }}
                </td>
              </tr>
            </tbody>
          </table>

          <div class="pt-3">
            <pagination
              align="center"
              :data="pageData"
              @pagination-change-page="getResults"
            ></pagination>
          </div>
        </div>

        <div class="text-center" v-else>
          <span
            ><i>{{ emptyDataText }}</i></span
          >
        </div>
        <!-- /.card-body -->
      </fieldset>
    </div>
    <!-- /.card -->
    <!-- /.card -->
    <div class="card custom-card-1" v-else>
      <legend
        class="d-flex font-weight-bold text-danger justify-content-center flex-wrap"
      >
        <span>Ops! You can't see completed withdrawals</span>
      </legend>
      <div class="card-header"></div>
    </div>
    <AgencyFooter :getUserProfile="getUserProfile" />
  </div>
  <div v-else>
    <not-found></not-found>
  </div>
</template>



<script>
import SearchFilter from "./../includes/SearchFilter";

export default {
  name: "CompletedWithdrawals",
  components: {
    SearchFilter,
  },
  data() {
    return {
      customer: "",
      incoming_withdrawals: [],
      withdrawals: [],
      pageData: {},
      searchForm: null,
      isFound: false,
      filters: [
        {
          id: 1,
          text: "Customer",
          value: "customer",
        },
        {
          id: 3,
          text: "Amount",
          value: "amount",
        },
        {
          id: 4,
          text: "Fee Paid",
          value: "fee_paid",
        },
      ],
      broker_balance: 0,
      companyInfo: {
        admin_branches: [],
      },
      branch: 0,
      data_form: new Form({
        branch_id: null,
      }),

      emptyDataText: "",
    };
  },
  created() {
    // fetch the results
    if (this.$gate.isAgent() || this.$gate.isAgentAdmin())
      if (this.$gate.canTransact() && this.$gate.isRegistrationComplete()) {
        this.getBranches();
      }
  },
  methods: {
    acceptRequest() {
      console.log("here ", this.data_form.branch_id);
      if (this.branch === "all" && this.getUserProfile.user_type == 2) {
        Swal.fire({
          title: "Please withdraw from a branch",
          icon: "warning",
        });
        return;
      }
      let formData = new FormData();
      const confirmBox1 = Swal.mixin({
        customClass: {
          confirmButton: "btn custom-button-2 mr-2",
          cancelButton: "btn custom-button bg-danger ml-2",
        },
        buttonsStyling: false,
      });
      confirmBox1
        .fire({
          title: "Are you sure?",
          text: "You'll be accepting withdrawal",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, accept it!",
          cancelButtonText: "No, don't!",
        })
        .then((result1) => {
          if (result1.isConfirmed) {
            // prompt for pin
            const box0 = Swal.mixin({
              customClass: {
                confirmButton: "btn custom-button-2 mr-2",
                cancelButton: "btn custom-button bg-danger ml-2",
              },
              buttonsStyling: false,
            });

            box0
              .fire({
                title: "Enter the withdraw token",
                input: "text",
                inputAttributes: {
                  required: true,
                },
                inputValidator: (value) => {
                  if (!value) {
                    return "You need to enter the withdraw token!";
                  }
                },
                showCancelButton: true,
                confirmButtonText: "Submit",
                showLoaderOnConfirm: true,
              })
              .then((result0) => {
                if (result0.isConfirmed) {
                  formData.append("withdraw_token", result0.value);

                  // prompt for pin
                  const box1 = Swal.mixin({
                    customClass: {
                      confirmButton: "btn custom-button-2 mr-2",
                      cancelButton: "btn custom-button bg-danger ml-2",
                    },
                    buttonsStyling: false,
                  });

                  box1
                    .fire({
                      title: "Enter your pin",
                      input: "password",
                      inputAttributes: {
                        maxlength: 4,
                        minlength: 4,
                        pattern: "[0-9]+",
                        required: true,
                      },
                      inputValidator: (value) => {
                        if (!value) {
                          return "You need to enter your 4 digit pin!";
                        }
                      },
                      showCancelButton: true,
                      confirmButtonText: "Submit",
                      showLoaderOnConfirm: true,
                    })
                    .then((result) => {
                      if (result.isConfirmed) {
                        this.$isLoading(true);

                        formData.append("pin", result.value);
                        if (this.getUserProfile.user_type == 2) {
                          formData.append("branch_id", this.branch);
                        } else {
                          formData.append(
                            "branch_id",
                            this.getUserProfile.branch_id
                          );
                        }

                        axios
                          .post("api/withdrawal/accept", formData, {
                            headers: {
                              "Content-Type": "application/json",
                            },
                          })
                          .then((data) => {
                            this.$isLoading(false);

                            const confirmBox2 = Swal.mixin({
                              customClass: {
                                confirmButton: "btn custom-button-2",
                              },
                              buttonsStyling: false,
                            });

                            confirmBox2
                              .fire({
                                icon: "success",
                                title: "Complete!",
                                text: "Withdraw successful!",
                              })
                              .then((res) => {
                                // reloading the page
                                window.location.href = "/completed-withdrawals";
                              });
                          })
                          .catch((err) => {
                            this.$isLoading(false);

                            const confirmBox2 = Swal.mixin({
                              customClass: {
                                confirmButton: "btn custom-button bg-danger",
                              },
                              buttonsStyling: true,
                            });

                            confirmBox2.fire({
                              icon: "error",
                              title: "Failed!",
                              text: err.response.data.errors,
                            });
                          });
                      }
                    });
                }
              });
          }
        });
    },
    getResults(page) {
      if (typeof page === "undefined") {
        page = 1;
      }
      this.emptyDataText = "";
      if (this.searchForm == null || this.searchForm.search_text == "") {
        this.$isLoading(true);

        this.data_form
          .post("api/withdrawal/completed?page=" + page)
          .then(({ data }) => {
            if (data.data.length != 0) {
              this.withdrawals = data.data;
              this.pageData = data;
            } else {
              this.withdrawals = [];
              this.pageData = {};
              this.emptyDataText = "You have no completed withdrawals";
            }

            this.$isLoading(false);
          })
          .catch((err) => {
            this.$isLoading(false);
          });
        this.data_form
          .post("api/withdrawal/incoming?page=" + page)
          .then(({ data }) => {
            if (data.data.length != 0) {
              this.incoming_withdrawals = data.data;
              this.pageData = data;
            } else {
              this.incoming_withdrawals = [];
              this.pageData = {};
              this.emptyDataText = "You have no incoming requests";
            }

            this.$isLoading(false);
          })
          .catch((err) => {
            this.$isLoading(false);
          });
      } else {
        this.$isLoading(true);
        this.emptyDataText = "";

        this.searchForm
          .post("api/withdrawal/makeSearch?page=", {
            headers: { "Content-Type": "application/json" },
          })
          .then(({ data }) => {
            this.isFound = true;

            this.withdrawals = data.data;
            this.pageData = data;
            this.emptyDataText = "No search results found...";

            this.$isLoading(false);
          })
          .catch((err) => {
            this.withdrawals = [];
            this.pageData = {};
            this.emptyDataText = "Something went wrong!";

            this.$isLoading(false);
          });
      }
    },
    getBranches() {
      axios
        .get("/api/agency")
        .then(({ data }) => {
          this.companyInfo = data;

          if (data.admin_branches.length > 1 || this.$gate.isAgent())
            this.branch = "all";
          else this.branch = data.admin_branches[0]["id"];
        })
        .catch((err) => {});
    },
    makeSearch(data) {
      this.searchForm = data;
      this.getResults();
      // emit up
    },
  },
  watch: {
    getUserProfile: function () {
      this.broker_balance = this.getUserProfile.broker_balance;
    },

    branch: function () {
      this.searchForm = null;
      this.isFound = false;
      this.data_form.branch_id = this.branch;
      this.getResults();
    },
  },

  mounted() {
    this.$store.dispatch("userProfile");
  },
  computed: {
    getUserProfile() {
      //final output from here
      return this.$store.getters.getUserProfile;
    },
  },
};
</script>
