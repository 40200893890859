<template>
    <div v-if="$gate.isCustomer()">
        <legend class="text-center">
            <div class="">
                <span>Buy GSM Credit</span>
            </div>
        </legend>
        <div class="card custom-card-1 m-0">
            <div class="gsm-container">

                <img @click="next('africell')" class="gsm-logo" src="/images/africell.png" alt="" />

                <img @click="next('comium')" class="gsm-logo" src="/images/comium.png" alt="" />

                <img @click="next('gamcel')" class="gsm-logo" src="/images/gamcel.png" alt="" />

                <img @click="next('qcell')" class="gsm-logo" src="/images/qcell.jpeg" alt="" />
            </div>
        </div>
    </div>
    <div v-else>
        <not-found></not-found>
    </div>
</template>

<script>
import BuyAirtime from './BuyAirtime.vue';
export default {
    name: "GsmOperators",
    components: {
        BuyAirtime
    },
    data() {
        return {
            balance: 0,
        };
    },

    methods: {
        next(gsm){
            if (gsm == 'africell') {
                localStorage.setItem('data', JSON.stringify({'gsm': gsm, 'img': "/images/africell.png"}));
            }else if(gsm == 'qcell'){
                localStorage.setItem('data', JSON.stringify({'gsm': gsm, 'img': "/images/qcell.jpeg"}));
            } else if(gsm == 'gamcel'){
                localStorage.setItem('data', JSON.stringify({'gsm': gsm, 'img': "/images/gamcel.png"}));
            } else if (gsm == 'comium'){
                localStorage.setItem('data', JSON.stringify({'gsm': gsm, 'img': "/images/comium.png"}));
            }
            this.$router.push({
            name: "buyAirtime", //use name for router push
            // params: { gsm }
        });
        },
    },

    // elseif(gsm == 'gamcel'){
    //             localStorage.setItem('data', JSON.stringify({'gsm': gsm, 'img': "/images/africell.png"}));
    //         } elseif(gsm == 'qcell'){
    //             localStorage.setItem('data', JSON.stringify({'gsm': gsm, 'img': "/images/africell.png"}));
    //         } elseif(gsm == 'comium'){
    //             localStorage.setItem('data', JSON.stringify({'gsm': gsm, 'img': "/images/africell.png"}));
    //         }

    watch: {
        getUserProfile: function () {
            if (this.$gate.isStore()) {
                document.getElementById("bladeCompanyName").innerHTML =
                    this.getUserProfile.company_name;
            }
        },
    },

    mounted() {
        this.$store.dispatch("userProfile");
    },
    computed: {
        getUserProfile() {
            //final output from here
            return this.$store.getters.getUserProfile;
        },
    },
};
</script>

<style scoped>
.gsm-container {
    display: grid;
    gap: 10%;
    grid-template-columns: repeat(2, 1fr);
}
.gsm-logo {
    width: 50%;
    border-radius: 10%;
    height: 90%;
}

img:nth-child(odd) {
    justify-self: end;
    border: 2px solid rgb(222, 220, 220);
}
</style>
