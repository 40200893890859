<template>
    <div>
      <div class="row" v-if="products.length > 0">
        <div class="col-md-3" v-for='(row, index) in products' :key='index'>
          <div class="card py-2 px-1">
                    <div class="d-flex flex-column-reverse align-items-center justify-content-between px-3 py-2">
                        <div class="d-flex align-items-center">
                            <span class="font-size-1">{{ row.product_name }}</span>
                        </div>
                        <div>
                            <span
                                class="font-size-4 text-color-2 float-right font-weight-bold"
                                ><span >{{ row.currency_sign }} </span> 
                          {{ row.product_price }}</span
                            >
                        </div>
                    </div>
                    <router-link v-bind:to="'/payment-form/' + row.id" class="btn btn-success">
                      Buy</router-link>
            </div>
          </div>
      </div>
      <div class="row" v-else>
        <div class="col-md-12 text-center">
          <h4>Product not found!</h4>
          
        </div>
      </div>
    </div>
</template>


<script>

    export default {
        
        data () {
            return {
              products: [],
          }
        },
        created(){
      // fetch PRODUCTS table
         this.getResults()

      

       },
        methods: {
          getResults(){
          this.$isLoading(true)
          let temp_form = new Form({
            currency : this.$route.params.currency
        })

        temp_form.post('/api/customer/products')
                .then(({data}) => {
                  console.log("products", data)
                  this.products = data;
                  console.log(this.products.length);
                  this.pageData = data
                    this.$isLoading(false)


                }).catch((err)=>{
                  this.products = []
                  this.$isLoading(false)


            })

        }
      }
    }
</script>