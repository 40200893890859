<template>
    <div>
        <div v-if="$gate.isRegistrationComplete() && $gate.canTransact() || $gate.isBroker()" class="d-flex justify-content-center">
            <form class="form-horizontal" action="" @submit.prevent='sendDetails' method="post">

                <div class="card w-100 custom-card-1 m-0 bg-white">
                <fieldset>
                    <legend class="d-flex justify-content-start flex-wrap">
                        <span>Change Username</span>
                    
                    </legend>
                    <div class="card-header">
                    </div>

                    <div class="card-body">

                        <div class="row">

                            <div class="col-12 d-flex justify-content-center">

                                <div class="form-group">
                                <label class="text-center">Enter new username</label>

                                    <input type="text" v-model="form.newUsername"
                                     class="form-control"
                                    required placeholder="Enter new username ...">
                                </div>
                                
                            </div>


                            <div  class="col-12 mt-3 d-flex justify-content-center">
                                <button type="submit" :disabled="form.busy" class="btn custom-button-1">
                                    SUBMIT
                                </button>
                            </div>


                        </div>

                    </div>
                </fieldset>
                </div>


            </form>

            <Footer v-if="$gate.isCustomer() || $gate.isStore()"  :getUserProfile='getUserProfile' />
            <AgencyFooter v-else-if="$gate.isAgency()" :getUserProfile='getUserProfile' />
        </div>
    </div>
</template>

<script>

export default {
  name: 'ChangeUser',
  components: {

  },
  data() {
    return {
        form: new Form({
            newUsername: '',
        }),

        isCorrectPassword: false


    }
  },
  created(){

        

  },

  methods: {
      sendDetails(){
        //  run fields error checks method
        
        // if(this.errorChecks() == false)
        //     return

        this.form.post('api/profile/changeUsername',{headers:{"Content-Type":"application/json"}})
            .then((data) => {

                const confirmBox2 = Swal.mixin({
                customClass: {
                    confirmButton: 'btn custom-button-2',
                
                },
                buttonsStyling: false
                })

                confirmBox2.fire({
                icon: 'success',
                title: 'Username changed!',
                text: 'You can use your new username to log in next time!'

                }).then((res)=>{
                    // redirect user to dashboard
                    if(this.$gate.isAgent())
                        window.location.href = '/agent-dashboard';
                    else if(this.$gate.isAgentAdmin())
                        window.location.href = '/agent-admin-dashboard';

                    else if(this.$gate.isCustomer())
                        window.location.href = '/dashboard';

                })



            })
            .catch((err) => {
               
                const confirmBox2 = Swal.mixin({
                customClass: {
                    confirmButton: 'btn custom-button bg-danger',
                
                },
                buttonsStyling: true
                })

                confirmBox2.fire({
                icon: 'error',
                title: 'Failed!',
                text: err.response.data.errors.newUsername,

                })


            });


      },



  },
  mounted() {
        // console.log("user profile", this.getUserProfile);
        this.$store.dispatch("userProfile")

  },
  computed: {
        getUserProfile(){ //final output from here
            return this.$store.getters.getUserProfile
        },
  }

}
</script>

<style scoped>
.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step,
.swal2-progress-steps .swal2-progress-step {
    background: #2d6a4f !important;
}
</style>