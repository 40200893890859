<template>
    <div>
        <div v-if="$gate.isBroker()" class="card  custom-card-1 m-0">
            <fieldset>
                <legend class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center">
                        <span>Agency Management</span>
                    </div>
                    <form @submit.prevent="submitSearchAgency" class="input-group mb-2 w-50 d-flex align-items-center">
                        <input type="text" v-model="searchAgency" class="form-control mr-1" placeholder="Search Agency Here" aria-label="Enter text here" aria-describedby="button-addon2">
                        <button class="btn btn-primary" type="submit" id="button-addon2">Submit</button>
                    </form>
                    <div class="d-flex align-items-center">
                        <router-link to="/create-agency" class="nav-link" active-class="active" exact>
                        <button class="btn btn-outline-info">Create new Agency <i class="fa fa-plus"></i></button>
                        </router-link>
                    </div>
                </legend>

                <div class="card-header">
                </div>


            <div>

                <!-- <TabNav :tabs="['Approved Agencies', 'Blocked Agencies']" :selected="selected" @selected="setSelected">
                    <Tab :isSelected="selected === 'Approved Agencies' ">

                    </Tab>

                    <Tab :isSelected="selected === 'Blocked Agencies' ">
                    </Tab>

                </TabNav> -->

            </div>




            <div class="card-body mt-2 table-responsive">
                <table  id="example2" class="table table-hover border-top">
                    <thead >
                    <tr class="text-center">
                    <th>Company Name</th>
                    <th>Broker Balance</th>
                    <th>Cash on hand</th>
                    <th>Fee Cut Percent</th>
                    <th>Total Agent Admins</th>
                    <th>Total Agents</th>

                    <th class="border-left">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class='text-center' v-for='(row, index) in agencies' :key='index'>
                        <td>{{row.company_name}}
                            <span v-if="row.is_blocked" >
                                <i class="fas fa-ban text-danger"></i>


                            </span>

                        </td>
                        <td>{{dalasisFormatter(row.broker_balance)}}</td>
                        <td>{{dalasisFormatter(row.cash_on_hand)}}</td>

                        <td>{{row.fee_cut_percent}}%</td>

                        <td>{{row.total_agent_admins}}</td>
                        <td>{{row.total_agents}}</td>
                        <td class="d-flex flex-wrap border-left justify-content-between">
                            <router-link v-bind:to="'/view-agency/' + row.id"
                            active-class="active" exact class="btn mb-4 custom-button-purple1" >
                                <i class="fas fa-eye"></i>
                            </router-link>
                            <!-- <button class="btn mb-4 custom-button btn-info">
                                    <i class="fas fa-edit"></i>

                            </button> -->
                            <button v-if="!row.is_blocked" @click="blockCompany(row.id)"  class="btn custom-button mb-4 btn-danger">
                                <i class="fas fa-ban"></i>
                            </button>

                            <button v-else  @click="unblockCompany(row.id)"   class="btn custom-button mb-4 btn-danger">
                                <i class="fas fa-building"></i>
                            </button>

                        </td>



                    </tr>
                    <tfoot>


                    </tfoot>
                    </tbody>
                </table>
                <div class="pt-3">
                    <pagination align="center" :data="pageData" @pagination-change-page="getResults"></pagination>

                </div>

            </div>


            </fieldset>

        </div>
        <div v-else>
            <not-found></not-found>

        </div>
    </div>
</template>

<script>
export default {
  name: "Agencies",
  props: {},
  components: {},
  data() {
    return {
      searchBy: "",
      searchText: "",

      form: null,

      agencies: [],
      company: [],
      pageData: {},
      searchAgency: "",
    };
  },
  created() {
    // fetch fee table
    if (this.$gate.isBroker()) {
      this.getBranch();
      this.getResults();
      this.form = new Form({
        agent_id: 0,
        pin: "",
        agent_admin_id: "",
      });
    }
  },
  watch: {},
  methods: {
    getBranch() {
      axios
        .get("api/broker/branch/" + 17)
        .then(({ data }) => {
          console.log("branches", data);
        })
        .catch((err) => {});
    },

    getResults(page) {
      if (typeof page === "undefined") {
        page = 1;
      }

      this.$isLoading(true);

      if (this.searchAgency != "") {
        console.log("here");
        axios
          .post("api/broker/agencies?page=" + page, {
            filter: this.searchAgency,
          })
          .then(({ data }) => {
            console.log("agencies", data);
            this.agencies = data.data;
            this.pageData = data;
            this.$isLoading(false);
          })
          .catch((err) => {
            this.agencies = [];
            this.pageData = {};
            this.$isLoading(false);
          });
      } else {
        axios
          .post("api/broker/agencies?page=" + page)
          .then(({ data }) => {
            console.log("agencies", data);
            this.agencies = data.data;
            this.pageData = data;
            this.$isLoading(false);
          })
          .catch((err) => {
            this.agencies = [];
            this.pageData = {};
            this.$isLoading(false);
          });
      }
    },
    blockCompany(id) {
      const confirmBox1 = Swal.mixin({
        customClass: {
          cancelButton: "btn custom-button-2 mx-2",
          confirmButton: "btn custom-button bg-danger mx-2",
        },
        buttonsStyling: false,
      });

      confirmBox1
        .fire({
          title: "Are you sure?",
          text: "You'll be blocking this company's account ",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, block this company!",
        })
        .then((result1) => {
          if (result1.isConfirmed) {
            const box1 = Swal.mixin({
              customClass: {
                confirmButton: "btn custom-button-2 mx-2",
                denyButton: "btn custom-button bg-secondary mx-2",
              },
              buttonsStyling: false,
            });

            box1
              .fire({
                title: "Enter your password",
                input: "password",
                inputAttributes: {
                  pattern: "[0-9]+",
                  required: true,
                },
                inputValidator: (value) => {
                  if (!value) {
                    return "You need to enter your password!";
                  }
                },
                showDenyButton: true,
                denyButtonText: `Back`,
                confirmButtonText: "Submit",
              })
              .then((result) => {
                if (result.isConfirmed) {
                  this.$isLoading(true);

                  let del_form = new FormData();
                  del_form.append("company_id", id);
                  del_form.append("password", result.value);

                  axios
                    .post("api/broker/blockCompany", del_form, {
                      headers: { "Content-Type": "application/json" },
                    })
                    .then((data) => {
                      this.$isLoading(false);

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button-2",
                        },
                        buttonsStyling: false,
                      });

                      confirmBox2
                        .fire({
                          icon: "success",
                          title: "Blocked!",
                          text: "Company Blocked! ... ",
                        })
                        .then((res) => {
                          // reloading the page
                          // window.location.href = '/agents';
                          this.getResults();
                        });
                    })
                    .catch((err) => {
                      this.$isLoading(false);
                      // show them if that city name is taken
                      let errorText = "Customer Blocking Unsuccessful";

                      if (err.response.data.errors["password"]) {
                        errorText = err.response.data.errors["password"];
                      } else if (err.response.data.errors["general"]) {
                        errorText = err.response.data.errors["general"];
                      }

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button bg-danger",
                        },
                        buttonsStyling: true,
                      });

                      confirmBox2.fire({
                        icon: "error",
                        title: "Failed!",
                        text: errorText,
                      });
                    });
                } else if (result.isDenied) {
                }
              });
          }
        });
    },

    unblockCompany(id) {
      const confirmBox1 = Swal.mixin({
        customClass: {
          cancelButton: "btn custom-button-2 mx-2",
          confirmButton: "btn custom-button bg-danger mx-2",
        },
        buttonsStyling: false,
      });

      confirmBox1
        .fire({
          title: "Are you sure?",
          text: "You'll be unblocking this company's account ",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, unblock this company!",
        })
        .then((result1) => {
          if (result1.isConfirmed) {
            const box1 = Swal.mixin({
              customClass: {
                confirmButton: "btn custom-button-2 mx-2",
                denyButton: "btn custom-button bg-secondary mx-2",
              },
              buttonsStyling: false,
            });

            box1
              .fire({
                title: "Enter your password",
                input: "password",
                inputAttributes: {
                  pattern: "[0-9]+",
                  required: true,
                },
                inputValidator: (value) => {
                  if (!value) {
                    return "You need to enter your password!";
                  }
                },
                showDenyButton: true,
                denyButtonText: `Back`,
                confirmButtonText: "Submit",
              })
              .then((result) => {
                if (result.isConfirmed) {
                  this.$isLoading(true);

                  let del_form = new FormData();
                  del_form.append("company_id", id);
                  del_form.append("password", result.value);

                  axios
                    .post("api/broker/unblockCompany", del_form, {
                      headers: { "Content-Type": "application/json" },
                    })
                    .then((data) => {
                      this.$isLoading(false);

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button-2",
                        },
                        buttonsStyling: false,
                      });

                      confirmBox2
                        .fire({
                          icon: "success",
                          title: "Unblocked!",
                          text: "Company Unblocked! ... ",
                        })
                        .then((res) => {
                          // reloading the page
                          // window.location.href = '/agents';
                          this.getResults();
                        });
                    })
                    .catch((err) => {
                      this.$isLoading(false);
                      // show them if that city name is taken
                      let errorText = "Customer Blocking Unsuccessful";

                      if (err.response.data.errors["password"]) {
                        errorText = err.response.data.errors["password"];
                      } else if (err.response.data.errors["general"]) {
                        errorText = err.response.data.errors["general"];
                      }

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button bg-danger",
                        },
                        buttonsStyling: true,
                      });

                      confirmBox2.fire({
                        icon: "error",
                        title: "Failed!",
                        text: errorText,
                      });
                    });
                } else if (result.isDenied) {
                }
              });
          }
        });
    },
    submitSearchAgency() {
      this.getResults();
    },
  },
};
</script>
