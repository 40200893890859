<template>
  <div>
    <div v-if="$gate.isMerchantAdmin()">
      <div class="card custom-card-1 mt-0 mx-0">
        <legend class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center">
            <span>Api Keys</span>
          </div>

          <div class="d-flex justify-content-between flex-wrap">
            <router-link to="/deposit-api-keys/docs">
              <button class="btn btn-outline-primary mr-3">
                Integration Doc
              </button>
            </router-link>
            <button
              class="btn btn-outline-bgreen"
              @click="handleCreateApiKey"
            >
              Create Key <i class="fa fa-plus ml-1"></i>
            </button>
          </div>

        </legend>

        <div class="card-body mt-2 table-responsive">
          <table
            id="example2"
            class="table table-hover"
          >
            <thead>
              <tr class="text-center">
                <th>Name</th>
                <th>Key Type</th>
                <th>Publishable Key</th>
                <th>Secret Key</th>
                <th>Domain Name</th>
                <th>Return Url</th>
                <th class="border-left">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="text-center"
                v-if="apiKeys.length === 0"
              >
                <td
                  colspan="7"
                  class="text-center"
                >
                  No Records Found
                </td>
              </tr>
              <tr
                v-for="(data, index) in apiKeys"
                :key="index"
                class="text-center"
              >
                <td class="align-middle">
                  {{ data.key_name }}
                </td>
                <td class="align-middle">
                  {{ data.key_type }}
                </td>
                <td class="align-middle">
                  {{ data.publishable_key }}
                </td>
                <td class="align-middle">
                  {{ data.secret_key }}
                </td>
                <td class="align-middle">
                  {{ data.domain_name }}
                </td>
                <td class="align-middle">
                  {{ data.return_url }}
                </td>
                <td class="border-left">
                  <div class="d-flex flex-wrap justify-content-around">
                    <button
                      type="button"
                      class="btn btn-outline-info btn-sm mb-3 mr-lg-2"
                      @click="handleEditApiApiKey(data)"
                    >
                      Edit
                    </button>

                    <button
                      class="btn btn-outline-danger btn-sm mb-3"
                      @click="deleteApiKey(data)"
                    >
                      Delete
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          class="modal fade"
          id="createUpdateApiKeyModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="createApiKeyTitle"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-dialog-centered"
            role="document"
          >
            <div class="modal-content">
              <form
                @submit.prevent="handleSubmitForm"
                method="post"
              >
                <div class="modal-header border-0">
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="d-flex justify-content-center">
                    <h2
                      class="swal2-title"
                      id="exampleModalLongTitle"
                    >
                      {{
                                                editModeData.isEdit
                                                    ? "Update"
                                                    : "Create"
                                            }}
                      API Key
                    </h2>
                  </div>

                  <div class="card-body">
                    <div class="form-group">
                      <label for="inputEmail3">Key Name</label>
                      <input
                        type="text"
                        class="form-control"
                        id="inputEmail3"
                        v-model="
                                                    create_update_key_form.key_name
                                                "
                      />
                      <div
                        class="text-danger"
                        v-if="
                                                    create_update_key_form.errors.has(
                                                        'key_name'
                                                    )
                                                "
                        v-html="
                                                    create_update_key_form.errors.get(
                                                        'key_name'
                                                    )
                                                "
                      />
                    </div>

                    <div
                      class="form-group"
                      v-if="!editModeData.isEdit"
                    >
                      <label>Key Type</label>

                      <select
                        v-model="
                                                    create_update_key_form.key_type
                                                "
                        id=""
                        class="form-control"
                      >
                        <option value="test">
                          Test
                        </option>
                        <option value="live">
                          Live
                        </option>
                      </select>

                      <div
                        class="text-danger"
                        v-if="
                                                    create_update_key_form.errors.has(
                                                        'key_type'
                                                    )
                                                "
                        v-html="
                                                    create_update_key_form.errors.get(
                                                        'key_type'
                                                    )
                                                "
                      />
                    </div>

                    <div class="form-group">
                      <label for="inputEmail3">Domain Name</label>
                      <input
                        type="text"
                        class="form-control"
                        id="inputEmail3"
                        v-model="
                                                    create_update_key_form.domain_name
                                                "
                      />
                      <div
                        class="text-danger"
                        v-if="
                                                    create_update_key_form.errors.has(
                                                        'domain_name'
                                                    )
                                                "
                        v-html="
                                                    create_update_key_form.errors.get(
                                                        'domain_name'
                                                    )
                                                "
                      />
                    </div>

                    <div class="form-group">
                      <label for="inputEmail3">Return Url</label>
                      <input
                        type="text"
                        class="form-control"
                        id="inputEmail3"
                        v-model="
                                                    create_update_key_form.return_url
                                                "
                      />
                      <div
                        class="text-danger"
                        v-if="
                                                    create_update_key_form.errors.has(
                                                        'return_url'
                                                    )
                                                "
                        v-html="
                                                    create_update_key_form.errors.get(
                                                        'return_url'
                                                    )
                                                "
                      />
                    </div>
                  </div>
                  <div class="d-flex justify-content-center">
                    <button
                      type="submit"
                      class="btn custom-button-2"
                    >
                      {{
                                                editModeData.isEdit
                                                    ? "Save"
                                                    : "Create"
                                            }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer :getUserProfile="getUserProfile" />
    </div>
    <div v-else>
      <not-found></not-found>
    </div>
  </div>
</template>

<script>
export default {
  name: "ApiKeys",
  props: {},
  components: {},
  data() {
    return {
      balance: 0,
      formInputNames: {
        key_name: "",
        key_type: "",
        domain_name: "",
        return_url: "",
      },
      apiKeys: [],
      isLoading: false,
      editModeData: {
        isEdit: false,
        id: null,
      },

      create_update_key_form: new Form({
        ...this.formInputNames,
      }),
    };
  },
  created() {
    // fetch apikeys table
    this.getMerchantApiKeys();
  },
  watch: {
    getUserProfile: function () {
      this.balance = this.dalasisFormatter(this.getUserProfile.balance);
      document.getElementById("bladeAmount").innerHTML = this.balance;
    },
  },
  mounted() {
    this.$store.dispatch("userProfile");
  },
  computed: {
    getUserProfile() {
      //final output from here
      return this.$store.getters.getUserProfile;
    },
  },
  methods: {
    getMerchantApiKeys() {
      if (!this.$gate.isMerchantAdmin()) {
        return;
      }
      this.isLoading = true;

      axios
        .get("/api/deposit-api-keys")
        .then(({ data }) => {
          this.apiKeys = data.api_keys;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
        });
    },

    handleResetForm(resetValue = true) {
      for (let [key, _] of Object.entries(this.formInputNames)) {
        if (resetValue) this.create_update_key_form[key] = "";
        if (this.create_update_key_form.errors.has(key)) {
          this.create_update_key_form.errors.set(key, "");
        }
      }
    },

    handleResetEditMode() {
      if (this.editModeData.isEdit) {
        this.editModeData = {
          isEdit: false,
          id: null,
        };
      }
    },

    handleResetToggleModal() {
      this.handleResetEditMode();
      this.handleResetForm();
      $("#createUpdateApiKeyModal").modal("toggle");
    },

    handleCreateApiKey() {
      console.log(this.create_update_key_form);
      this.handleResetToggleModal();
    },

    createApiKey() {
      const reqData = {
        key_name: this.create_update_key_form.key_name,
        key_type: this.create_update_key_form.key_type,
        domain_name: this.create_update_key_form.domain_name,
        return_url: this.create_update_key_form.return_url,
      };

      axios
        .post("/api/deposit-api-keys/create", reqData)
        .then(({ data }) => {
          this.handleResetToggleModal();
          this.getMerchantApiKeys();
          const confirmBox2 = Swal.mixin({
            customClass: {
              confirmButton: "btn custom-button-2",
            },
            buttonsStyling: false,
          });

          confirmBox2.fire({
            icon: "success",
            title: data.message,
          });
        })
        .catch((err) => {
          let errors = err.response.data.errors;

          if (
            errors &&
            Object.keys(errors).length === 0 &&
            Object.getPrototypeOf(errors) === Object.prototype
          ) {
            let errorText = "Operation Unsuccessful";
            const confirmBox2 = Swal.mixin({
              customClass: {
                confirmButton: "btn custom-button bg-danger",
              },
              buttonsStyling: true,
            });

            confirmBox2.fire({
              icon: "error",
              title: "Failed!",
              text: errorText,
            });
          } else {
            this.handleResetForm(false);
            for (let [key, value] of Object.entries(errors)) {
              this.create_update_key_form.errors.set(key, value);
            }
          }
        });
    },

    handleEditApiApiKey(rec) {
      this.editModeData.isEdit = true;
      this.editModeData.id = rec.id;

      this.create_update_key_form.key_name = rec.key_name;
      this.create_update_key_form.domain_name = rec.domain_name;
      this.create_update_key_form.return_url = rec.return_url;

      $("#createUpdateApiKeyModal").modal("toggle");
    },

    updateApiKey(id) {
      const confirmBox1 = Swal.mixin({
        customClass: {
          confirmButton: "btn custom-button-2 mr-2",
          cancelButton: "btn custom-button bg-danger ml-2",
        },
        buttonsStyling: false,
      });
      confirmBox1
        .fire({
          title: "Are you sure?",
          text: "You want to update!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
        })
        .then((result1) => {
          if (result1.isConfirmed) {
            const reqData = {
              key_name: this.create_update_key_form.key_name,
              domain_name: this.create_update_key_form.domain_name,
              return_url: this.create_update_key_form.return_url,
            };

            axios
              .put(`/api/deposit-api-keys/update/${id}`, reqData)
              .then(({ data }) => {
                this.handleResetToggleModal();
                this.getMerchantApiKeys();
                const confirmBox2 = Swal.mixin({
                  customClass: {
                    confirmButton: "btn custom-button-2",
                  },
                  buttonsStyling: false,
                });

                confirmBox2.fire({
                  icon: "success",
                  title: data.message,
                });
              })
              .catch((err) => {
                let errors = err.response.data.errors;

                if (
                  errors &&
                  Object.keys(errors).length > 0 &&
                  Object.getPrototypeOf(errors) === Object.prototype
                ) {
                  this.handleResetForm(false);
                  for (let [key, value] of Object.entries(errors)) {
                    this.create_update_key_form.errors.set(key, value);
                  }
                } else {
                  let errorText =
                    err?.response?.data?.message ?? "Operation Unsuccessful";
                  const confirmBox2 = Swal.mixin({
                    customClass: {
                      confirmButton: "btn custom-button bg-danger",
                    },
                    buttonsStyling: true,
                  });

                  confirmBox2.fire({
                    icon: "error",
                    title: "Failed!",
                    text: errorText,
                  });
                }
              });
          }
        });
    },

    handleSubmitForm() {
      if (this.editModeData.isEdit) {
        this.updateApiKey(this.editModeData.id);
      } else {
        this.createApiKey();
      }
    },

    deleteApiKey(rec) {
      const confirmBox1 = Swal.mixin({
        customClass: {
          confirmButton: "btn custom-button bg-danger mr-2",
          cancelButton: "btn custom-button-2 ml-2",
        },
        buttonsStyling: false,
      });
      confirmBox1
        .fire({
          title: "Are you sure?",
          text: 'You will be deleting "' + rec.key_name + '"',
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
        })
        .then((result1) => {
          if (result1.isConfirmed) {
            axios
              .delete(`/api/deposit-api-keys/delete/${rec.id}`)
              .then(({ data }) => {
                this.handleResetEditMode();
                this.handleResetForm();
                this.getMerchantApiKeys();
                const confirmBox2 = Swal.mixin({
                  customClass: {
                    confirmButton: "btn custom-button-2",
                  },
                  buttonsStyling: false,
                });

                confirmBox2.fire({
                  icon: "success",
                  title: data.message,
                });
              })
              .catch((err) => {
                let error = err.response.data.message;

                let errorText = error ?? "Operation Unsuccessful";
                const confirmBox2 = Swal.mixin({
                  customClass: {
                    confirmButton: "btn custom-button bg-danger",
                  },
                  buttonsStyling: true,
                });

                confirmBox2.fire({
                  icon: "error",
                  title: "Failed!",
                  text: errorText,
                });
              });
          }
        });
    },
  },
};
</script>
