<template>
    <div v-if="$gate.isCashcollector()">



        <div class="card  custom-card-1 m-0">
          <form class="form-horizontal" action="" @submit.prevent='createCampaign' method="post">

            <fieldset>
                <legend class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center">
                     <span>Create Campaign </span>

                    </div>

                     <a @click="$router.go(-1)" class="nav-link" active-class="active"><button class="btn bg-nyanza font-weight-bold text-gray">Go back</button></a>

                </legend>
                    <div class="card-header">
                    </div>


                    <div class="card-body">

                        <div class="row">
                            <!-- errors -->
                            <div class="col-6">
                                <div class="text-danger" v-if="form.errors.has('campaign_name')" v-html="form.errors.get('username')" />
                            </div>
                            <div class="col-6">
                                    <div class="text-danger" v-if="form.errors.has('campaign_fee')" v-html="form.errors.get('institution_name')" />
                            </div>

                            <!-- enderrors -->

                            <div class="col-6">
                                <div class="form-group pb-4">
                                    <label for="">Campaign Name <span class="text-danger">*</span> </label>

                                    <input type="text" v-model="form.campaign_name" required  placeholder="Enter Campaign Name" class="form-control"><br>


                                </div>

                            </div>
                            <div class="col-6">
                                <div class="form-group pb-4">
                                    <label for="">Campaign Type <span class="text-danger">*</span> </label>
                                    <select class="form-control" @change="onchangecampaign_type" v-model="form.campaign_type" required>
                                    <option value="">Select Type</option>
                                    <option value="0">Normal</option>
                                    <option value="1">Recurring</option>
                                    </select>

                                </div>

                            </div>

                            <div class="col-6" v-if="showRecuring">
                                <div class="form-group pb-4">
                                    <label for="">Recurring Period Type <span class="text-danger">*</span> </label>
                                    <select class="form-control" @change="onchangetype" v-model="form.period_type" required>
                                    <option value="">Select Type</option>
                                    <option value="year">Yearly</option>
                                    <option value="month">Monthly</option>
                                    <option value="week">Weekly</option>
                                    </select>

                                </div>

                            </div>

                            <div class="col-6" v-if="showRecuring">
                                <div class="form-group pb-4">
                                    <label for="">Recurring Period <span class="text-danger">*</span> </label>

                                    <input type="number" @change="calculateExpiry" v-model="form.time_period" min="1" required  placeholder="Enter Recurring Period" class="form-control"><br>


                                </div>

                            </div>
                            <div class="col-6" v-if="showRecuring">
                                <div class="form-group pb-4">
                                    <label for="">Grace Period (Days) <span class="text-danger">*</span> </label>

                                    <input type="number"  v-model="form.grace_period" min="0" required  placeholder="Enter Number of days " class="form-control"><br>


                                </div>

                            </div>

                            <div class="col-6" v-if="showRecuring">
                                <div class="form-group pb-4">
                                    <label for="">Campaign End Date <span class="text-danger">*</span> </label>

                                    <input type="date" v-model="form.campaign_end_date" required  placeholder="Enter Campaign End Date" class="form-control" readonly /><br>


                                </div>

                            </div>
                            <div class="col-6" v-else>
                                <div class="form-group pb-4">
                                    <label for="">Campaign End Date <span class="text-danger">*</span> </label>

                                    <input type="date" v-model="form.campaign_end_date" required  placeholder="Enter Campaign End Date" class="form-control" /><br>


                                </div>

                            </div>
                            <div class="col-6">
                                <div class="form-group pb-4">
                                    <label for="">Who Pays the Fee <span class="text-danger">*</span> </label>
                                    <select class="form-control"  v-model="form.who_pay_fee" required>
                                    <option value="">Select Who Pays the Fee</option>
                                    <option value="0">Customer</option>
                                    <option value="1">Collector</option>
                                    </select>

                                </div>

                            </div>
                            
                            
                           





                            <div class="col-12 mt-3 d-flex justify-content-center">
                                <button type="submit" :disabled="form.busy" class="btn custom-button-1">
                                    SUBMIT
                                </button>
                            </div>


                        </div>

                    </div>




            </fieldset>

          </form>







        </div>

    </div>
    <div v-else>
        <not-found></not-found>
    </div>
</template>


<script>
import moment from 'moment';




export default {
    name: 'createCampaign',
    props:{

    },
  components: {

  },
  data() {
    return {
        form: new Form({


            campaign_name: '',
            campaign_type: '',
            campaign_end_date: '',
            period_type: '',
            time_period: '',
            grace_period: '',
            who_pay_fee: '',
            
            user_id: '',


        }),
        showRecuring: false

    }
  },
  created(){



  },
  mounted() {



  },
    computed: {


    },
    watch:{


    },
  methods:{

    onchangecampaign_type(){

        if(this.form.campaign_type==1){
            this.showRecuring= true;
        }else{
            this.showRecuring= false; 
        }
        
    },
    onchangetype(){
        this.form.time_period='';
        this.form.campaign_end_date='';
    },



    calculateExpiry() {
        if(this.form.period_type.length  >= 1){
        if (this.form.time_period.length >= 1) {
           var month= this.form.time_period;
           var date = new Date();
           if(this.form.period_type=='month'){
            var newDate = new Date(date.setMonth(date.getMonth() + parseInt(month)));
           }else if(this.form.period_type=='week'){

            var newDate = new Date(date.setDate(date.getDate() + 7 * parseInt(month)));
           }else{
           
            var newDate = new Date(date.setFullYear(date.getFullYear() + parseInt(month)));
           }
       
           
            this.form.campaign_end_date = moment(newDate).format('yyyy-MM-DD');
          }
        }else{
            this.form.time_period='';
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please first select recuring period type!',
              
                })
        }
        },

      createCampaign(){

        if(!this.$gate.isCashcollector())
            return;

            const box1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button-2 mr-2',
                denyButton: 'btn custom-button bg-secondary ml-2'
            },
            buttonsStyling: false
            })

            box1.fire({
                title: 'Enter your pin',
                text: 'To confirm that you want to create this Campaign',
                input: 'password',
                inputAttributes: {
                    required: true,

                },
                inputValidator: (value) => {
                    if (!value) {
                    return 'You need to enter your pin!'
                    }

                },
                showDenyButton: true,
                denyButtonText: `Back`,
                confirmButtonText: 'Submit',
                }).then((result) => {
                if (result.isConfirmed) {

                 this.form.pin = result.value


                  this.$isLoading(true)

                    this.form.post('/api/collector/createCampaign',{headers:{"Content-Type":"application/json"}})
                        .then((data) => {
                        this.$isLoading(false)

                            const confirmBox2 = Swal.mixin({
                            customClass: {
                                confirmButton: 'btn custom-button-2',

                            },
                            buttonsStyling: false
                            })


                            confirmBox2.fire({
                            icon: 'success',
                            title: 'Campaign Creation!',
                            text: 'Campaign registration successful!'

                            }).then((res)=>{
                                // redirect user to dashboard
                            //window.location.href = '/broker-cash-distributors';
                            location.reload();

                            })



                        })
                        .catch((err) => {
                        this.$isLoading(false)

                        let errorText = 'Campaign Creation Unsuccessful'

                        if(err.response.data.errors['mypassword']){
                            errorText = err.response.data.errors['mypassword']
                        }
                        else if(err.response.data.errors['general']){
                            errorText = err.response.data.errors['general']
                        }


                            const confirmBox2 = Swal.mixin({
                            customClass: {
                                confirmButton: 'btn custom-button bg-danger',

                            },
                            buttonsStyling: true
                            })

                            confirmBox2.fire({
                            icon: 'error',
                            title: 'Failed!',
                            text: errorText,

                            })

                        });


                }
                })



      }

  },

}
</script>
