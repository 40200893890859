<template>
  <div>
    <div v-if="$gate.isBankAdmin()">
      <!-- <div v-if="unusualTransactionNotificationArr.length">
                <div class="alert alert-danger" v-for="unusualTransactionNotification in unusualTransactionNotificationArr">
                    {{unusualTransactionNotification.name}} has some unusual transactions
                    <button @click="markAsRead" style="margin-top: -5px; font-size: 12px" class="btn btn-xs btn-success float-right">
                            Mark As read
                    </button>
                </div>
            </div> -->

      <!-- <div class="card">
        <div class="d-flex justify-content-between px-3 py-2">
          <div class="d-flex align-items-center">
            <span class="font-size-1">Total Fee Earnings (Money Transfer)</span>
          </div>
          <div>
            <span
              class="font-size-4 text-color-2 float-right font-weight-bold"
              >0</span
            >
          </div>
        </div>
      </div> -->

      <div class="row mt-3 border-bottom">
        

        <div class="col-12 col-sm-6 col-md-3">
          <div class="info-box">
            <span class="info-box-icon text-brown1"
              ><i class="fas fa-hand-holding-usd"></i
            ></span>

            <div class="info-box-content">
             <span class="info-box-text"
                >Total Banks</span
              >
              <span class="info-box-number">0</span>
            </div>
            <!-- /.info-box-content -->
          </div>
          <!-- /.info-box -->
        </div>

    

      </div>
 

    

  </div>
</div>
</template>
<script>
export default {
  name: "BankAdminDashboard",
}
</script>




