<template>
    <div>
        <div v-if="$gate.isBroker()" class="card custom-card-1 m-0">
            <fieldset>
                <legend class="d-flex justify-content-center flex-wrap">
                    <span>All Pending Transactions</span>
                </legend>

                <SearchFilter
                    :data_length="transactions.length"
                    :filters="filters"
                    data_type="incoming"
                    @search-form="makeSearch"
                    :isFound="isFound"
                />
                <!-- /.card-header -->
                <div
                    v-if="transactions.length != 0"
                    class="card-body table-responsive"
                >
                    <table
                        id="example2"
                        class="table table-bordered table-hover border-top"
                    >
                        <thead>
                            <tr class="text-center">
                                <th>Transaction Id</th>
                                <th>Agency from</th>
                                <th>Agency to</th>
                                <th>Amount</th>
                                <th>Fee Paid</th>
                                <th>Sender</th>
                                <th>Receiver</th>
                                <th>Time Sent</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                class="text-center"
                                v-for="(row, index) in transactions"
                                :key="index"
                            >
                                <td>{{ row.transaction_id }}</td>
                                <td>
                                    <span>
                                        {{ row.agency_from }} -
                                        {{ row.agency_from_branch }}
                                    </span>
                                    <br />

                                    <small
                                        >from:
                                        {{ row.agent_from_fullname }}</small
                                    >
                                </td>

                                <td>
                                    <span>
                                        {{ row.agency_to }} -
                                        {{ row.agency_to_branch }}
                                    </span>
                                    <br />
                                    <small
                                        >to: {{ row.agent_to_fullname }}</small
                                    >
                                </td>
                                <td>
                                    {{ dalasisFormatter(row.amount) }}
                                </td>

                                <td>
                                    {{ dalasisFormatter(row.fee_paid) }}
                                </td>

                                <td>{{ row.sender_name }}</td>
                                <td>{{ row.receiver_name }}</td>
                                <td>{{ row.time_sent }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="pt-3">
                        <pagination
                            align="center"
                            :data="pageData"
                            @pagination-change-page="getResults"
                        ></pagination>
                    </div>
                </div>

                <div class="text-center" v-else>
                    <span
                        ><i>{{ emptyDataText }}</i></span
                    >
                </div>
                <!-- /.card-body -->
            </fieldset>
        </div>
        <!-- /.card -->

        <div v-else>
            <not-found></not-found>
        </div>
    </div>
</template>

<script>
export default {
    name: "PendingTransactions",
    components: {
        SearchFilter: () =>
            import(
                "../../../includes/SearchFilter.vue" /* webpackChunkName: "js/search-filter" */
            ),
    },
    data() {
        return {
            isFound: false,
            searchForm: null,
            transactions: [],
            pageData: {},
            filters: [
                {
                    id: 1,
                    text: "Agency From",
                    value: "agency_from",
                },
                {
                    id: 2,
                    text: "Amount",
                    value: "amount",
                },
                {
                    id: 3,
                    text: "Fee Paid",
                    value: "fee_paid",
                },
            ],
            sort_by: {
                column: "Time Sent",
                order: "descending",
            },
            sort_column_names: [
                "Amount",
                "Fee Paid",
                "Sender",
                "Receiver",
                "Time Sent",
            ],
            emptyDataText: "",
        };
    },
    created() {
        // fetch fee table
        if (this.$gate.isBroker()) {
            this.getResults();
        }
    },
    methods: {
        // getResults(page) {
        //     if (typeof page === "undefined") {
        //         page = 1;
        //     }

        //     this.$isLoading(true);

        //     axios
        //         .get("api/broker/transaction/allPending?page=" + page)
        //         .then(({ data }) => {
        //             if (data.data.length != 0) {
        //                 this.transactions = data.data;
        //                 this.pageData = data;
        //             } else {
        //                 this.transactions = [];
        //                 this.pageData = {};
        //                 this.emptyDataText =
        //                     "There are no pending transactions";
        //             }

        //             this.$isLoading(false);
        //         })
        //         .catch((err) => {
        //             this.transactions = [];
        //             this.pageData = {};
        //             this.$isLoading(false);
        //         });
        // },
        getResults(page) {
            if (typeof page === "undefined") {
                page = 1;
            }
            this.emptyDataText = "";
            if (this.searchForm == null || this.searchForm.search_text == "") {
                this.$isLoading(true);

                axios
                    .get("api/broker/transaction/allPending?page=" + page)
                    .then(({ data }) => {
                        if (data.data.length != 0) {
                            this.transactions = data.data;
                            this.pageData = data;
                        } else {
                            this.transactions = [];
                            this.pageData = {};
                            this.emptyDataText =
                                "You have no outgoing transactions";
                        }

                        this.$isLoading(false);
                    })
                    .catch((err) => {
                        this.$isLoading(false);
                    });
            } else {
                this.$isLoading(true);
                this.emptyDataText = "";

                this.searchForm
                    .post("api/transaction/makeSearch?page=", {
                        headers: { "Content-Type": "application/json" },
                    })
                    .then(({ data }) => {
                        this.isFound = true;

                        this.transactions = data.data;
                        this.pageData = data;
                        this.emptyDataText = "No search results found...";

                        this.$isLoading(false);
                    })
                    .catch((err) => {
                        this.transactions = [];
                        this.pageData = {};
                        this.emptyDataText = "Something went wrong!";

                        this.$isLoading(false);
                    });
            }
        },

        makeSearch(data){
            this.searchForm = data
            this.getResults()
            // emit up
        }
    },
};
</script>
