<template>
    <div>
        <div class="row mt-5">
            <div class="col-12">
                <div class="d-flex justify-content-between flex-wrap border-bottom pb-3">
                    <div class="d-flex align-items-center">
                        <h5>Transfers</h5>
                    </div>

                </div>
                <div class="form-group pt-3">
                    <label>Transaction Type</label>

                    <select v-model="selected" id="" class="form-control">
                        <option value="all">All</option>
                        <option value="incoming">Incoming</option>
                        <option value="outgoing">Outgoing</option>

                    </select>
                </div>
            </div>


            <div class="col-12">


                <div class="mt-2 mx-0">
                    <fieldset>
                        <legend class="d-flex justify-content-center flex-wrap">
                            <span>{{selected[0].toUpperCase() + selected.substring(1, selected.length)}} Transfers</span>

                        </legend>

                        <SearchFilter :data_length='transfers.length' :isFound='isFound'
                        :filters='filters' data_type="incoming"
                        />

                        <div  v-if="transfers.length != 0" class="card-body mt-0 pt-0">

                        <div class="d-flex justify-content-end mt-0 pt-0 mb-3">
                            <button class="btn btn-sm btn-default"
                             data-toggle="modal" data-target="#sortByModal"
                             style="border-color: gray;">Sort by</button>
                        </div>

                        <div class="table-responsive">
                            <table id="example2" class="table table-bordered table-hover border-top">
                                <thead >

                                <tr  class="text-left">
                                <th v-for="(column, index) in column_names" :key="index">
                                    {{column}}
                                </th>
                                </tr>

                                </thead>
                                <tbody v-if="selected != 'all'" >
                                <tr  class='' v-for='(row, index) in transfers' :key='index'>
                                    <td>
                                    <a  :href="'/view-agent-profile/' + row.other_id" class="cursor" >
                                        {{row.other_name}}
                                    </a>
                                    </td>
                                    <td>
                                        {{row.amount}}
                                    </td>
                                    <td>
                                        {{row.time_sent}}
                                    </td>
                                </tr>

                                </tbody>
                                <tbody v-else>
                                <tr  class='' v-for='(row, index) in transfers' :key='index'>
                                    <td>
                                        {{row.status}}
                                    </td>
                                    <td>
                                        <a  class="cursor" :href="'/view-agent-profile/' + row.from_id">
                                            {{row.from_name}}
                                        </a>

                                    </td>

                                    <td>
                                        <a  class="cursor" :href="'/view-agent-profile/' + row.to_id">
                                            {{row.to_name}}
                                        </a>

                                    </td>
                                    <td>
                                        {{row.amount}}
                                    </td>
                                    <td>
                                        {{row.time_sent}}
                                    </td>
                                </tr>

                                </tbody>

                            </table>
                        </div>

                        <div class="pt-3">
                            <pagination align="center" :data="pageData" @pagination-change-page="getResults"></pagination>

                        </div>
                        </div>

                        <div class="text-center"  v-else>
                        <span><i v-if="selected != 'all'">They have no {{selected}} transfers</i>
                                <i v-else>They have no transfers</i>
                        </span>
                        </div>

                    </fieldset>
                </div>


            </div>

        </div>


            <div class="modal fade" id="sortByModal" tabindex="-1" role="dialog" aria-labelledby="sortByModalTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">


                            <div class="modal-header border-0">
                                <span class="swal2-title" >Sort By</span>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <h5 class="text-muted">Select Column</h5>
                                <div class="form-group ml-3">
                                    <div v-for="(column, index) in sort_column_names" :key='index' class="form-check">
                                        <input class="form-check-input" type="radio" v-model="sort_by.column" :value="column">
                                        <label class="form-check-label" for="exampleRadios1">
                                            {{column}}
                                        </label>
                                    </div>

                                </div>
                                <hr>
                                <h5 class="text-muted">Order By</h5>
                                <div class="form-group ml-3">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" v-model="sort_by.order" value="asc">
                                        <label class="form-check-label" for="exampleRadios1">
                                            Ascending
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" v-model="sort_by.order" value="desc">
                                        <label class="form-check-label" for="exampleRadios1">
                                            Descending
                                        </label>
                                    </div>
                                </div>

                            </div>
                            <div class="modal-footer border-0 d-flex justify-content-center">
                                <button type="submit" @click="sortData" class="btn custom-button-2 ">Sort</button>
                            </div>

                    </div>
                </div>
            </div>
    </div>
</template>


<script>


export default {
    name: 'AgentTransfers',
    props:{
        customer_id: Number
    },
    components: {
    SearchFilter: () => import('../../includes/SearchFilter.vue' /* webpackChunkName: "js/search-filter" */),

    },
  data() {
    return {
        selected: 'all',
        isFound: false,

        transfers: [],
        pageData: {},
        form: new Form({
            agent_id: '',
            column: 'Time Sent',
            order:'asc'
        }),

        searchQuery: 'all?page=',
        filters: [
            {
                'id': 1,
               'text': 'Agent_to',
               'value': 'agent_to'
            },
            {
                'id': 2,
               'text': 'Agent_from',
               'value': 'agent_from'
            },
            {
                'id': 3,
                'text': 'Amount',
                'value': 'amount'
            },
            {
                'id': 4,
                'text': 'Fee Paid',
                'value': 'fee_paid'
            },
        ],

        sort_by: {
            column: 'Time Sent',
            order: 'desc'
        },
        sort_column_names: ['From', 'To', 'Amount', 'Time Sent'],

        column_names:['Transaction Status', 'From', 'To', 'Amount', 'Time Sent']


    }
  },
  created(){
    this.form.agent_id = this.customer_id
    this.getResults()


  },
    watch: {

        selected: function(){
            this.resetSort()
            if(this.selected == 'all'){
                this.column_names = ['Transfer Status', 'From', 'To', 'Amount', 'Time Sent']

                this.sort_column_names = ['From', 'To', 'Amount', 'Time Sent']

                this.searchQuery = this.selected + '?page='
                this.getResults()


            }
            else if(this.selected === 'incoming'){

                this.column_names = ['From', 'Amount', 'Time Sent']

                this.sort_column_names = [... this.column_names]

                this.searchQuery = this.selected + '?page='

                this.getResults()
            }

            else if(this.selected === 'outgoing'){

                this.column_names = ['To', 'Amount', 'Time Sent']

                this.sort_column_names = [... this.column_names]

                this.searchQuery = this.selected + '?page='

                this.getResults()
            }
        },

    },
  methods:{
      getResults(page){
        if (typeof page === 'undefined') {
            page = 1;
        }

            this.$isLoading(true)

            this.form.post('/api/broker/agentTransactions/'+ this.searchQuery + page)
                .then(({data}) => {
                  this.transfers = data.data;
                  this.pageData = data
                  this.$isLoading(false)

                }).catch((err)=>{
                    this.transfers = []
                    this.pageData = {}
                  this.$isLoading(false)

                })

      },

      sortData(){
            $('#sortByModal').modal('toggle')

            this.form.column = this.sort_by.column
            this.form.order = this.sort_by.order
            this.searchQuery = this.selected + '?page='

            this.getResults()

      },

      resetSort(){
          if(this.selected != 'all'){
            if(this.form.column == 'Transaction Type' ){
                this.sort_by.column = 'Time sent'
                this.form.column = this.sort_by.column
            }
            if(this.selected != 'collected'){
                if(this.form.column == 'Collecting Agent' ){
                    this.sort_by.column = 'Time sent'
                    this.form.column = this.sort_by.column
                }
            }

          }

      }
  },

}
</script>
