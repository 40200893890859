<template>
  <div>
    <div class="card custom-card-1 m-0">
      <fieldset>
        <legend class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center">
            <span>Free Money Table</span>
          </div>
          <router-link
            v-if="$gate.isBroker()"
            to="/configure-free-money"
            class="nav-link"
            active-class="active"
            exact
          >
            <button class="btn btn-outline-info">
              Configure <i class="fa fa-cog"></i>
            </button>
          </router-link>
        </legend>
        <div class="card-header"></div>
        <!-- /.card-header -->
        <div class="card-body table-responsive">
          <table id="example2" class="table table-bordered table-hover">
            <thead>
              <tr class="text-center">
                <th></th>
                <th>Start Rand</th>
                <th>End Rand</th>
                <th>Type</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="text-center"
                v-for="(row, index) in freeMoney"
                :key="index"
              >
                <td>{{ ++index }}</td>

                <td>
                  {{ row.start_rand }}
                </td>
                <td>{{ row.end_rand }}</td>
                <td class="text-emerald font-weight-bold">
                  {{ `${row.type} users` }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </fieldset>
    </div>
    <!-- /.card -->

    <AgencyFooter v-if="$gate.isAgency()" :getUserProfile="getUserProfile" />
  </div>
</template>


<script>
export default {
  name: "FreeMoney",
  components: {},
  data() {
    return {
      freeMoney: [],
    };
  },
  created() {
    // fetch fee table
    this.$isLoading(true);

    axios
      .get("api/freeMoneyTable")
      .then(({ data }) => {
        this.$isLoading(false);

        this.freeMoney = data;
      })
      .catch((err) => {
        this.$isLoading(false);

        console.log(err);
      });
  },

  mounted() {
    this.$store.dispatch("userProfile");
  },
  computed: {
    getUserProfile() {
      //final output from here
      return this.$store.getters.getUserProfile;
    },
  },
};
</script>