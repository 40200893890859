<template>
  <div
    v-if="
      $gate.isBroker() &&
      (userInfo.rank == 'Agent-Admin' || userInfo.rank == 'Agent')
    "
  >
    <div class="card custom-card-1 m-0">
      <fieldset>
        <legend class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center">
            <span>
              Agent {{ userInfo.rank === "Agent-Admin" ? "Admin" : "" }} /
              Teller
              {{ userInfo.rank === "Agent-Admin" ? "Admin" : "" }} Profile (
              {{ userInfo.first_name }} {{ userInfo.last_name }} )</span
            >
          </div>
          <a
            href="#"
            class="nav-link"
            active-class="active"
            onclick="history.go(-1);return false;"
          >
            <button class="btn bg-nyanza font-weight-bold text-gray">
              Go back
            </button>
          </a>
        </legend>

        <div class="card-header"></div>

        <div>
          <TabNav :tabs="tabs" :selected="selected" @selected="setSelected">
            <Tab :isSelected="selected === 'General'">
              <div class="row mt-5">
                <div class="col-12">
                  <div
                    class="d-flex justify-content-between align-items-center flex-wrap border-bottom pb-4"
                  >
                    <div><h5>General</h5></div>
                    <div>
                      <a
                        href="#"
                        class="nav-link"
                        data-toggle="modal"
                        data-target="#editAgentModal"
                      >
                        <button class="btn btn-outline-info">
                          Edit <i class="fa fa-edit"></i>
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-8 pr-5">
                  <div class="border-bottom font-weight-bold py-3">Profile</div>

                  <div class="row py-3 border-bottom">
                    <div class="col-6">First Name</div>
                    <div class="col">
                      {{ userInfo.first_name }}
                    </div>
                  </div>
                  <div class="row py-3 border-bottom">
                    <div class="col-6">Last Name</div>
                    <div class="col">
                      {{ userInfo.last_name }}
                    </div>
                  </div>

                  <div class="row py-3 border-bottom">
                    <div class="col-6">Username</div>
                    <div class="col">
                      {{ userInfo.username }}
                    </div>
                  </div>
                  <div class="row py-3 border-bottom">
                    <div class="col-6">Rank</div>
                    <div class="col">
                      {{ userInfo.rank }}
                    </div>
                  </div>

                  <div class="row py-3 border-bottom">
                    <div class="col-6">Can Transact</div>
                    <div class="col">
                      {{ userInfo.can_user_transact }}
                    </div>
                  </div>

                  <div class="row py-3 border-bottom">
                    <div class="col-6">Email</div>
                    <div class="col">
                      {{ userInfo.email }}
                    </div>
                  </div>
                  <div
                    v-if="userInfo.rank != 'Customer'"
                    class="row py-3 border-bottom"
                  >
                    <div class="col-6">Address</div>
                    <div class="col">
                      {{ userInfo.town_name }}
                    </div>
                  </div>
                  <div class="row py-3 border-bottom">
                    <div class="col-6">Phone Number</div>
                    <div class="col">
                      {{ userInfo.phone_number }}
                    </div>
                  </div>
                  <div class="row py-3 border-bottom">
                    <div class="col-6">Company</div>
                    <div class="col">
                      <router-link :to="'/view-agency/' + userInfo.company_id">
                        {{ userInfo.company_name }}
                      </router-link>
                    </div>
                  </div>
                  <div class="row py-3 border-bottom">
                    <div class="col-6">
                      {{
                        userInfo.rank === "Agent-Admin" ? "Branches" : "Branch"
                      }}
                    </div>
                    <div v-if="userInfo.rank === 'Agent'" class="col">
                      <!-- loop through if thw user is an Admin -->
                      {{ userInfo.branch }}
                    </div>
                    <div v-else>
                      <div class="d-flex justify-content-between flex-wrap">
                        <span
                          class="pr-3 text-bwgreen"
                          style=""
                          v-for="b in userInfo.branches"
                          :key="b.id"
                        >
                          {{ b.branch }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="row py-3 border-bottom">
                    <div class="col-6">Pin</div>
                    <div class="col">
                      {{ userInfo.pin }}
                    </div>
                  </div>
                </div>

                <div class="col-4">
                  <div class="border-bottom font-weight-bold py-3 mb-3">
                    Avatar
                  </div>

                  <div class="image">
                    <img
                      class="img-fluid pad"
                      src="/images/profiles/profile-none.png"
                      alt=""
                    />
                  </div>
                  <!-- <div v-if="canEdit" class="btn btn-info w-100">
                                    Change profile picture
                                </div> -->
                </div>
              </div>
            </Tab>

            <Tab :isSelected="selected === 'Transactions'">
              <!--  -->
              <AgentTransfers :customer_id="userInfo.id" />
            </Tab>

            <Tab :isSelected="selected === 'Logs'"> deposits </Tab>

            <Tab :isSelected="selected === 'Logs'"> </Tab>
          </TabNav>
        </div>
      </fieldset>

      <!-- ///// agent edit modal starts here //// -->

      <div
        class="modal fade"
        id="editAgentModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="editAgencyModalTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <form @submit.prevent="editAgent" method="post">
              <div class="modal-header border-0">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="d-flex justify-content-center">
                  <h2 class="swal2-title" id="exampleModalLongTitle">
                    Edit Agent
                  </h2>
                </div>
                <form @submit.prevent="editAgent" class="form-horizontal">
                  <div class="card-body">
                    <!-- /.card-body -->
                    <div class="form-group">
                      <div
                        class="text-danger"
                        v-if="edit_agent_form.errors.has('username')"
                        v-html="edit_agent_form.errors.get('username')"
                      />

                      <label for="inputEmail3">Username</label>
                      <input
                        type="text"
                        class="form-control"
                        id="inputEmail3"
                        v-model="edit_agent_form.username"
                      />
                    </div>
                    <div class="form-group">
                      <div
                        class="text-danger"
                        v-if="edit_agent_form.errors.has('email')"
                        v-html="edit_agent_form.errors.get('email')"
                      />

                      <label for="inputEmail3">Agent Email</label>
                      <input
                        type="text"
                        class="form-control"
                        id="inputEmail3"
                        v-model="edit_agent_form.email"
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div class="modal-footer border-0 d-flex justify-content-center">
                <button type="submit" class="btn custom-button-2">
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <!-- agent edit modal ends here -->
    </div>
  </div>
  <div v-else-if="!$gate.isBroker() || userInfo.rank == 'Customer'">
    <not-found></not-found>
  </div>
</template>


<script>
import AgentProfile from "../../includes/AgentProfile";
import TabNav from "../../includes/tabNav.vue";
import Tab from "../../includes/tabs.vue";
import AgentTransfers from "./AgentTransfers.vue";

export default {
  name: "ViewUserProfile",
  props: {},
  components: {
    AgentProfile,
    TabNav,
    Tab,
    AgentTransfers,
  },
  data() {
    return {
      selected: "General",
      tabs: ["General", "Transactions", "Logs"],
      logs: [],
      pageData: {},
      userInfo: {
        rank: "",
      },
      edit_agent_form: new Form({
        user_id: this.$route.params.id,
        username: "",
        email: "",
        picture: null,
      }),
    };
  },
  created() {
    // fetch fee table
    this.getUser();
  },
  watch: {
    selected: function () {},
  },
  methods: {
    setSelected(tab) {
      this.selected = tab;
    },

    getUser() {
      this.$isLoading(true);

      let temp_form = new Form({
        id: this.$route.params.id,
      });

      temp_form
        .post("/api/profile/getUserProfile")
        .then(({ data }) => {
          this.userInfo = data;

          this.edit_agent_form = new Form({
            user_id: data.id,
            username: data.username,
            email: data.email,
            picture: null,
            password: "",
          });

          this.$isLoading(false);
        })
        .catch((err) => {
          this.$isLoading(false);
        });
    },

    editAgent() {
      $("#editAgentModal").modal("toggle");

      const confirmBox1 = Swal.mixin({
        customClass: {
          confirmButton: "btn custom-button bg-danger mr-2",
          cancelButton: "btn custom-button-2 ml-2",
        },
        buttonsStyling: false,
      });

      confirmBox1
        .fire({
          title: "Are you sure?",
          text: "Your changes will be saved and the agency will be affected",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, save the changes!",
        })
        .then((result1) => {
          if (result1.isConfirmed) {
            // prompt for pin
            const box1 = Swal.mixin({
              customClass: {
                confirmButton: "btn custom-button bg-danger  mr-2",
                cancelButton: "btn custom-button-2 ml-2",
              },
              buttonsStyling: false,
            });

            box1
              .fire({
                title: "Enter your password",
                text: "To confirm your changes",
                input: "password",
                inputAttributes: {
                  required: true,
                },
                inputValidator: (value) => {
                  if (!value) {
                    return "You need to enter your password!";
                  }
                },
                showCancelButton: true,
                confirmButtonText: "Submit",
                showLoaderOnConfirm: true,
              })
              .then((result) => {
                if (result.isConfirmed) {
                  this.edit_agent_form.password = result.value;
                  this.$isLoading(true);

                  this.edit_agent_form
                    .post("/api/broker/edit-agent", {
                      headers: { "Content-Type": "application/json" },
                    })
                    .then((data) => {
                      this.$isLoading(false);

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button-2",
                        },
                        buttonsStyling: false,
                      });

                      confirmBox2
                        .fire({
                          icon: "success",
                          title: "Agent changes saved!",
                        })
                        .then((res) => {
                          window.location.reload();
                        });
                    })
                    .catch((err) => {
                      this.$isLoading(false);
                      console.log("errors ", this.edit_agent_form.errors);
                      // show them if that city name is taken
                      let errorText = "Agency changes Unsuccessful";

                      if (err.response.data.errors["password"]) {
                        errorText = err.response.data.errors["password"];
                        const confirmBox2 = Swal.mixin({
                          customClass: {
                            confirmButton: "btn custom-button bg-danger",
                          },
                          buttonsStyling: true,
                        });

                        confirmBox2.fire({
                          icon: "error",
                          title: "Failed!",
                          text: errorText,
                        });
                      } else {
                        $("#editAgentModal").modal("toggle");
                      }
                    });
                }
              });
          }
        });
    },
  },
};
</script>
