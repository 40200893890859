var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.$gate.isAgent() || _vm.$gate.isAgentAdmin())?_c('div',[(_vm.$gate.canTransact() && _vm.$gate.isRegistrationComplete())?_c('div',{staticClass:"card custom-card-1 m-0"},[_c('fieldset',[_c('legend',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('span',{staticClass:"d-flex align-items-center"},[_vm._v("Incoming Requests")]),_vm._v(" "),(_vm.$gate.isAgentAdmin())?_c('div',{},[(
                            _vm.$gate.isAgentAdmin() &&
                            _vm.companyInfo.admin_branches.length > 1
                        )?_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.branch),expression:"branch"}],staticClass:"form-control",attrs:{"required":"","name":"","id":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.branch=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"all","selected":""}},[_vm._v("All")]),_vm._v(" "),_vm._l((_vm.companyInfo.admin_branches),function(branch){return _c('option',{key:branch.id,domProps:{"value":branch.id}},[_vm._v("\n                            "+_vm._s(branch.town_name)+" - Branch\n                        ")])})],2):_vm._e()]):_vm._e()]),_vm._v(" "),_c('SearchFilter',{attrs:{"data_length":_vm.withdrawals.length,"filters":_vm.filters,"data_type":"incoming","isFound":_vm.isFound},on:{"search-form":_vm.makeSearch}}),_vm._v(" "),(_vm.withdrawals.length != 0)?_c('div',{staticClass:"card-body table-responsive"},[_c('table',{staticClass:"table table-bordered table-hover border-top",attrs:{"id":"example2"}},[_vm._m(0),_vm._v(" "),_c('tbody',_vm._l((_vm.withdrawals),function(row,index){return _c('tr',{key:index,staticClass:"text-center"},[_c('td',[_vm._v(_vm._s(row.unique_id))]),_vm._v(" "),_c('td',[_vm._v("\n                                "+_vm._s(row.agency_from_branch)+"\n                            ")]),_vm._v(" "),_c('td',[(row.user_type === 'Customer')?_c('router-link',{attrs:{"to":'/view-user-profile/' +
                                        row.customer_id}},[_vm._v("\n                                    "+_vm._s(row.customer_fullname)+" "),_c('br'),_vm._v(" "),_c('span',[_vm._v("\n                                        username:\n                                        "+_vm._s(row.customer_username)+"\n                                    ")])]):_vm._e(),_vm._v(" "),(row.user_type === 'Merchant')?_c('router-link',{attrs:{"to":'/view-merchant-profile/' +
                                        row.company_id}},[_vm._v("\n                                    "+_vm._s(row.merchant_store_name)+" "),_c('br')]):_vm._e()],1),_vm._v(" "),_c('td',[_vm._v("\n                                "+_vm._s(row.user_type)+"\n                            ")]),_vm._v(" "),_c('td',[_vm._v("\n                                "+_vm._s(_vm.dalasisFormatter(
                                        row.amount - row.fee_paid
                                    ))+"\n                            ")]),_vm._v(" "),_c('td',[_vm._v("\n                                "+_vm._s(_vm.dalasisFormatter(row.fee_paid))+"\n                            ")]),_vm._v(" "),_c('td',[_vm._v(_vm._s(row.time_sent))]),_vm._v(" "),_c('td',[_c('button',{staticClass:"btn btn-sm custom-button-ogreen1 text-white",on:{"click":function($event){return _vm.acceptRequest(row.withdraw_id)}}},[_vm._v("\n                                    Accept\n                                ")])])])}),0)]),_vm._v(" "),_c('div',{staticClass:"pt-3"},[_c('pagination',{attrs:{"align":"center","data":_vm.pageData},on:{"pagination-change-page":_vm.getResults}})],1)]):_c('div',{staticClass:"text-center"},[_c('span',[_c('i',[_vm._v(_vm._s(_vm.emptyDataText))])])])],1)]):_c('div',{staticClass:"card custom-card-1"},[_vm._m(1),_vm._v(" "),_c('div',{staticClass:"card-header"})]),_vm._v(" "),_c('AgencyFooter',{attrs:{"getUserProfile":_vm.getUserProfile}})],1):_c('div',[_c('not-found')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',{staticClass:"text-center"},[_c('th',[_vm._v("ID")]),_vm._v(" "),_c('th',[_vm._v("Branch from")]),_vm._v(" "),_c('th',[_vm._v("Customer / Merchant")]),_vm._v(" "),_c('th',[_vm._v("User Type")]),_vm._v(" "),_c('th',[_vm._v("Amount to give")]),_vm._v(" "),_c('th',[_vm._v("Fee Paid")]),_vm._v(" "),_c('th',[_vm._v("Time Sent")]),_vm._v(" "),_c('th',[_vm._v("Action")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('legend',{staticClass:"d-flex font-weight-bold text-danger justify-content-center flex-wrap"},[_c('span',[_vm._v("Ops! You can't see incoming requests")])])
}]

export { render, staticRenderFns }