<template>
    <div v-if="$gate.isCustomer()">
        <div v-if="!$gate.isRegistrationComplete()">
            <div class="card px-4 py-2 border-0" style="box-shadow: none">
                <div class="card-body text-center">
                    <h3>Congratulations for joining!</h3>
                    <div style="font-size: 1.2rem">
                        To be able to send and receive money, you must
                        <router-link
                            to="/set-pin"
                            class="text-info"
                            style="text-decoration: underline"
                        >
                            set your pin</router-link
                        >
                    </div>
                </div>
            </div>
        </div>

        <div
            v-if="
                getUserProfile.phone_number !== null &&
                getUserProfile.verified_at === null
            "
        >
            <div class="card px-4 py-2 border-0" style="box-shadow: none">
                <div class="card-body text-center">
                    <a
                        href="sms-verification"
                        style="text-decoration: underline"
                        class="card-body border-bottom"
                    >
                        <div class="in">
                            <div>Click here to Verify Phone Number</div>
                        </div>
                    </a>
                </div>
            </div>
        </div>

        <div
            class="customer general"
            v-else-if="$gate.isRegistrationComplete() && $gate.canTransact()"
        >
            <div class="row">
                <div class="col-12">
                    <div class="section wallet-card-section pt-1">
                        <div class="wallet-card">
                            <!-- Balance -->
                            <div class="balance">
                                <div class="left d-flex">
                                    <div>
                                        <span class="title">Total Balance</span>
                                        <h1 class="total">{{ balance }} </h1>
                                    </div>&nbsp;
                                  
                                    <div v-if="credit>0">
                                        <span class="title ml-4 text-warning">Kashlock Credit</span>
                                    <h2 class=" text-warning">  + {{ this.dalasisFormatter(credit) }}</h2>
                                    </div>
                                    

                                    
                                   
                                </div>

                                <div
                                    class="d-flex justify-content-center align-items-center"
                                >
                                    <router-link to="/bank-deposite"
                                        class="py-2 py-md-3 px-3 px-md-4 bg-green1 add-cash"
                                        data-toggle="modal"
                                        data-target="#increaseCashOnHandModal"
                                    >
                                        <i class="fas fa-plus text-white"></i>
                                    </router-link>
                                </div>
                            </div>
                            <!-- * Balance -->
                            <!-- Wallet Footer -->
                            <div class="wallet-footer">

                                <!-- <div class="item" v-if ="userInfo.campaign_amount_withdrawal > 0 || userInfo.campaign_amount_both > 0">

                                    <a @click="withdrawask">
                                        <div class="icon-wrapper bg-danger">
                                            <i role="img" class="fas fa-arrow-down md hydrated" aria-label="add outline"></i>
                                        </div>
                                        <strong>Withdraw</strong>
                                    </a>
                                </div> -->
                                <div class="item">

                                    <router-link to="/withdraw/0">
                                        <div class="icon-wrapper bg-danger">
                                            <i
                                                role="img"
                                                class="fas fa-arrow-down md hydrated"
                                                aria-label="add outline"
                                            ></i>
                                        </div>
                                        <strong>Withdraw</strong>
                                    </router-link>
                                </div>


                                <!-- <div class="item"  v-if ="userInfo.campaign_amount_send > 0 || userInfo.campaign_amount_both > 0">
                                    <a href="#" @click="sendask" >
                                        <div class="icon-wrapper bg-ogreen1">
                                            <i role="img" class="fas fa-arrow-right md hydrated" aria-label="add outline"></i>
                                        </div>
                                        <strong>Send</strong>
                                    </a>
                                </div> -->
                                <div class="item">
                                    <a href="#" data-toggle="modal" data-target="#sendModal">
                                        <div class="icon-wrapper bg-ogreen1">
                                            <i
                                                role="img"
                                                class="fas fa-arrow-right md hydrated"
                                                aria-label="add outline"
                                            ></i>
                                        </div>
                                        <strong>Send</strong>
                                    </a>
                                </div>
                                <div class="item">
                                    <a href="#" @click="openCurrencyModal">
                                        <div class="icon-wrapper bg-info">
                                            <i
                                                role="img"
                                                class="fas fa-credit-card md hydrated"
                                                aria-label="add outline"
                                            ></i>
                                        </div>
                                        <strong>Add Deposit</strong>
                                    </a>
                                </div>
                            </div>
                            <!-- * Wallet Footer -->
                        </div>
                    </div>
                </div>
            </div>

            <!-- transaction types graph -->
            <div v-if="years.length > 0" class="row mt-3">
                <div class="col-md-12 col-lg-8">
                    <div class="card">
                        <div class="row">
                            <div class="col-12 text-muted">
                                <TabNav
                                    :tabs="years"
                                    :selected="selected"
                                    @selected="setSelected"
                                >
                                </TabNav>
                            </div>
                            <div class="col-12">
                                <div
                                    class="mt-3 d-flex justify-content-end mr-3"
                                >
                                    <div class="">
                                        <select
                                            v-model="selectedStat"
                                            id="stats"
                                            class="form-control text-sm"
                                        >
                                            <option value="" disabled>
                                                Select Stats Type
                                            </option>
                                            <option value="Transfers">
                                                Transfers
                                            </option>
                                            <option value="Deposits">
                                                Deposits
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="text-center py-3 font-weight-bold">
                                    {{ chart1Title }}
                                </div>

                                <div
                                    v-if="selectedStat == 'Transfers'"
                                    class="row mb-4 ml-3"
                                >
                                    <div
                                        class="d-flex justify-content-between flex-wrap text-xs"
                                    >
                                        <div class="pr-2">
                                            <span class="text-muted"
                                                >Incoming Total:
                                            </span>
                                            <span
                                                class="font-weight-bold"
                                                style="
                                                    color: rgba(
                                                        81,
                                                        139,
                                                        201,
                                                        1
                                                    );
                                                "
                                            >
                                                {{
                                                    dalasisFormatter(
                                                        chart1Selection[
                                                            "incoming"
                                                        ].reduce(
                                                            (total, num) =>
                                                                total + num,
                                                            0
                                                        )
                                                    )
                                                }}
                                            </span>
                                        </div>
                                        <div class="">
                                            <span class="text-muted"
                                                >Outgoing Total:
                                            </span>
                                            <span
                                                class="font-weight-bold"
                                                style="
                                                    color: rgba(
                                                        148,
                                                        82,
                                                        165,
                                                        1
                                                    );
                                                "
                                            >
                                                {{
                                                    dalasisFormatter(
                                                        chart1Selection[
                                                            "outgoing"
                                                        ].reduce(
                                                            (total, num) =>
                                                                total + num,
                                                            0
                                                        )
                                                    )
                                                }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-else-if="selectedStat == 'Deposits'"
                                    class="row mb-4 ml-3"
                                >
                                    <div
                                        class="d-flex justify-content-between flex-wrap text-xs"
                                    >
                                        <div class="pr-2">
                                            <span class="text-muted"
                                                >Completed Total:
                                            </span>
                                            <span
                                                class="font-weight-bold"
                                                style="
                                                    color: rgba(50, 109, 76, 1);
                                                "
                                            >
                                                {{
                                                    dalasisFormatter(
                                                        chart1Selection[
                                                            "completed"
                                                        ].reduce(
                                                            (total, num) =>
                                                                total + num,
                                                            0
                                                        )
                                                    )
                                                }}
                                            </span>
                                        </div>
                                        <div class="pr-2">
                                            <span class="text-muted"
                                                >Pending Total:
                                            </span>
                                            <span
                                                class="font-weight-bold"
                                                style="
                                                    color: rgba(
                                                        240,
                                                        147,
                                                        57,
                                                        0.8
                                                    );
                                                "
                                            >
                                                {{
                                                    dalasisFormatter(
                                                        chart1Selection[
                                                            "pending"
                                                        ].reduce(
                                                            (total, num) =>
                                                                total + num,
                                                            0
                                                        )
                                                    )
                                                }}
                                            </span>
                                        </div>
                                        <div class="">
                                            <span class="text-muted"
                                                >Cancelled Total:
                                            </span>
                                            <span
                                                class="font-weight-bold"
                                                style="
                                                    color: rgba(188, 38, 37, 1);
                                                "
                                            >
                                                {{
                                                    dalasisFormatter(
                                                        chart1Selection[
                                                            "cancelled"
                                                        ].reduce(
                                                            (total, num) =>
                                                                total + num,
                                                            0
                                                        )
                                                    )
                                                }}
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <MChart1 :chartData="chart1Selection" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-12 col-lg-4">
                    <!-- DIRECT CHAT -->
                    <div class="card direct-chat direct-chat-primary">
                        <div class="card-header bg-white">
                            <h3 class="card-title">Transactions Rankings</h3>

                            <div class="card-tools">
                                <button
                                    type="button"
                                    class="btn btn-tool"
                                    data-card-widget="collapse"
                                >
                                    <i class="fas fa-minus"></i>
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-tool"
                                    data-toggle="tooltip"
                                    title="Contacts"
                                    data-widget="chat-pane-toggle"
                                >
                                    <i class="fas fa-medal"></i>
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-tool"
                                    data-card-widget="remove"
                                >
                                    <i class="fas fa-times"></i>
                                </button>
                            </div>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body">
                            <!-- Conversations are loaded here -->
                            <div class="direct-chat-messages">
                                <div class="card-body table-responsive p-0">
                                    <table
                                        class="table table-striped table-valign-middle"
                                    >
                                        <thead>
                                            <tr>
                                                <th>Rank</th>
                                                <th>Category</th>
                                                <th>{{ rankBy }} Amount</th>
                                                <!-- <th>More</th> -->
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                v-for="(
                                                    rank, index
                                                ) in transactionRankings"
                                                :key="index"
                                            >
                                                <td>{{ ++index }}</td>
                                                <td>
                                                    <i
                                                        v-if="index <= 3"
                                                        :class="[
                                                            index == 1
                                                                ? 'text-orange'
                                                                : index == 2
                                                                ? 'text-gray'
                                                                : index == 3
                                                                ? 'text-bronze'
                                                                : '',
                                                            'fas fa-crown',
                                                        ]"
                                                    >
                                                    </i>

                                                    {{ rank.type }}
                                                </td>
                                                <td>
                                                    {{
                                                        dalasisFormatter(
                                                            rank.amount
                                                        )
                                                    }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!--/.direct-chat-messages-->

                            <!-- Contacts are loaded here -->
                            <div class="direct-chat-contacts">
                                <ul class="contacts-list">
                                    <li><span>Rank by</span></li>
                                    <li class="ml-3">
                                        <a
                                            class="font-weight-bold"
                                            @click="setRankBy('Incoming')"
                                            href="#"
                                            data-toggle="tooltip"
                                            title="Contacts"
                                            data-widget="chat-pane-toggle"
                                        >
                                            Incoming
                                        </a>
                                    </li>
                                    <li class="ml-3">
                                        <a
                                            class="font-weight-bold"
                                            @click="setRankBy('Outgoing')"
                                            href="#"
                                            data-toggle="tooltip"
                                            title="Contacts"
                                            data-widget="chat-pane-toggle"
                                        >
                                            Outgoing
                                        </a>
                                    </li>

                                    <!-- End Contact Item -->
                                </ul>
                                <!-- /.contacts-list -->
                            </div>
                            <!-- /.direct-chat-pane -->
                        </div>
                        <!-- /.card-body -->

                        <!-- /.card-footer-->
                    </div>
                    <!--/.direct-chat -->
                </div>
            </div>

            <div v-if="transfers.length > 0" class="row mt-4">
                <div class="col-12">
                    <legend
                        class="border-top pt-3 d-flex justify-content-between flex-wrap"
                    >
                        <span class="d-flex align-items-center">Transfers</span>
                        <router-link
                            v-show="transfers_count >= 3"
                            to="/my-transfers"
                            class="text-info"
                            >View All</router-link
                        >
                    </legend>
                </div>
                <div class="col-12">
                    <div class="transactions">
                        <section
                            v-for="(d, index2) in transfers"
                            :key="index2"
                            class="px-3 pb-md-4 mb-3"
                        >
                            <p class="py-1 text-secondary">
                                Transfer ID:
                                <i class="font-weight-bold">{{
                                    d["unique_id"]
                                }}</i>
                            </p>
                            <div class="d-flex justify-content-between">
                                <div
                                    class="font-weight-bold text-md d-flex align-items-center"
                                >
                                    <span>{{ d["status"] }}</span>
                                </div>
                            </div>
                            <div class="item pt-2">
                                <div class="detail">
                                    <img
                                        :src="
                                            '/images/profiles/' + d['other_pic']
                                        "
                                        alt="img"
                                        class="image-block imaged w48"
                                    />
                                    <div>
                                        <router-link
                                            v-bind:to="
                                                '/view-profile/' + d['other_id']
                                            "
                                        >
                                            <strong
                                                >{{ d["other_name"] }}
                                                <img
                                                    v-if="d['is_verified']"
                                                    src="/images/verified-badge.png"
                                                    alt="image"
                                                    style="
                                                        width: 16px;
                                                        height: 16px;
                                                    "
                                                />
                                            </strong>
                                        </router-link>

                                        <p>
                                            <span class="font-weight-bold">{{
                                                d["transaction_type"]
                                            }}</span>
                                            <br />
                                            {{ d["time_updated"] }}
                                        </p>
                                    </div>
                                </div>
                                <div class="right">
                                    <div
                                        :class="[
                                            d['status'] == 'Received'
                                                ? 'text-emerald'
                                                : 'text-red1',
                                            'price font-weight-bold',
                                        ]"
                                    >
                                        <span v-if="d['status'] == 'Received'"
                                            >+</span
                                        >
                                        <span v-else>-</span>
                                        {{ dalasisFormatter(d["amount"]) }}
                                    </div>
                                </div>
                            </div>
                        </section>

                        <div
                            v-show="transfers_count >= 3"
                            class="pt-0 mt-0 border-bottom text-center font-weight-bold"
                        >
                            <h3>. . .</h3>
                        </div>
                    </div>
                </div>
            </div>

            <Send :getUserProfile="getUserProfile" />

            <Footer :getUserProfile="getUserProfile" />
        </div>
    </div>
    <div v-else>
        <not-found></not-found>
    </div>
</template>

<script>
export default {
    name: "Dashboard",
    components: {
        TabNav: () =>
            import("./../includes/tabNav" /* webpackChunkName: "js/tab-nav" */),
        Tab: () =>
            import("./../includes/tabs" /* webpackChunkName: "js/tabs" */),
        MChart1: () =>
            import(
                "../charts/merchant/MChart1.vue" /* webpackChunkName: "js/m-chart-1" */
            ),
    },
    data() {
        return {
            selected: "",
            chart1Title: "Stats",
            transactionRankings: [],
            userInfo: "",
            transfers: [],
            transfers_count: 0,
            balance: 0,
            credit: 0,
            years: [],
            selectedStat: "Transfers",
            chart1Selection: {
                type: "Transfers",
                incoming: [0],
                outgoing: [0],
                fees: [0],
                deposits: [0],
                labels: [],
            },
            rankBy: "Incoming",
            currencies:[],
            options:{},
        };
    },
    created() {
        if (
            this.$gate.isCustomer() &&
            this.$gate.isRegistrationComplete() &&
            this.$gate.canTransact()
        ) {
            this.getYears();
            this.getTranfers();
            this.rankings();
        }

        axios.get('/api/broker/currencies',{headers:{"Content-Type":"application/json"}})
                        .then((data) => {
                            console.log(data.data);
                        this.currencies= data.data.data

                        this.currencies.forEach((value, index) => {
                            console.log(value.currency_name);
                            var name=value.currency_name;
                         this.options[name] = name;
                            }) 
                            console.log( this.options);
                        })
                        

                 
    },
    watch: {
        getUserProfile: function () {
            this.userInfo = this.getUserProfile
            

            if((this.getUserProfile.balance ==0 || this.getUserProfile.balance ==0.00) && this.getUserProfile.used_kashlock_credit > 0){
                this.getUserProfile.balance= -this.getUserProfile.used_kashlock_credit;
            }
            this.balance = this.dalasisFormatter(this.getUserProfile.balance)
            this.credit = this.getUserProfile.kashlock_credit

            document.getElementById('bladeAmount').innerHTML = this.balance
            document.getElementById('bladeCreditAmount').innerHTML = this.dalasisFormatter(this.credit)
            if(this.credit > 0){
                document.getElementById('bladeCreditBlock').style.display = 'block';
            }
            
        },
        selected: function () {
            if (this.selected.length != 0) {
                this.getChart1();
            }
        },
        selectedStat: function () {
            if (this.selectedStat.length != 0) {
                this.getChart1();
            }
        },
    },

    mounted() {
        this.$store.dispatch("userProfile");
    },
    computed: {
        getUserProfile() {
            //final output from here
            return this.$store.getters.getUserProfile;
        },
    },
    methods: {
        openCurrencyModal() {
        

            Swal.fire({
                    title: "Currencies",
                    text: "Please choose a currency!",
                    input:"select",
                    inputOptions: this.options,
                                showCancelButton: true,
                                inputPlaceholder: "Select Currency",
                                 preConfirm: (value) => {

                                    if (value) {
                                       // Handle return value 
                                       } else {
                                     Swal.showValidationMessage('You need to choose a currency option!')   
                                    }
                                  }
                    
                })
                .then((result) => {
                    var inputValue = result.value;
                    if(inputValue != null && inputValue != ''){
                      
                        console.log(result.value);
                        window.location.href = "/add-deposits/"+inputValue;
                    } 
                });
        },

        openSendModal() {
            const confirmBox1 = Swal.mixin({
                customClass: {
                    confirmButton: "btn custom-button bg-danger mr-2",
                    cancelButton: "btn custom-button-2 ml-2",
                },
                buttonsStyling: false,
            });

            confirmBox1
                .fire({
                    title: "Are you sure?",
                    html: "You want to withdraw to your campaign money or personal?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Campaign",
                    cancelButtonText: "Personal",
                })
                .then((result1) => {
                    if (result1.isConfirmed) {
                        console.log(result1.isConfirmed);
                        $("#sendModal").modal("show");
                        $("#send_type").val(1);
                    } else {
                        $("#sendModal").modal("show");
                        $("#send_type").val(0);
                    }
                });
        },
        openWithdrawModal() {
            const confirmBox1 = Swal.mixin({
                customClass: {
                    confirmButton: "btn custom-button bg-danger mr-2",
                    cancelButton: "btn custom-button-2 ml-2",
                },
                buttonsStyling: false,
            });

            confirmBox1
                .fire({
                    title: "Are you sure?",
                    html: "You want to withdraw to your campaign money or personal?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Campaign",
                    cancelButtonText: "Personal",
                })
                .then((result1) => {
                    if (result1.isConfirmed) {
                        window.location.href = "/withdraw/1";
                    } else {
                        window.location.href = "/withdraw/0";
                    }
                });
        },
        setSelected(tab) {
            this.selected = tab;
        },
        getTranfers() {
            this.$isLoading(true);

            axios
                .get("api/customer-dashboard/transfers", {
                    headers: { "Content-Type": "application/json" },
                })
                .then(({ data }) => {
                    this.transfers_count = data[0].length;
                    this.transfers = data[0];
                    if (this.transfers_count >= 3) {
                        this.transfers.pop();
                    }

                    this.$isLoading(false);
                })
                .catch((err) => {
                    this.$isLoading(false);
                });
        },

        getYears() {
            axios
                .post("api/customer-dashboard/years")
                .then(({ data }) => {
                    data.forEach((element) => {
                        element.forEach((e) => {
                            if (!this.years.includes(String(e))) {
                                this.years.push(String(e));
                            }
                        });
                    });

                    if (this.years.length > 0) {
                        this.selected = this.years[0];
                    }
                })
                .catch((err) => {});
        },
        rankings() {
            //   this.$isLoading(true)
            let form = new Form({
                rank_by: this.rankBy,
            });
            form.post("api/customer-dashboard/transactionRankings")
                .then(({ data }) => {
                    this.transactionRankings = data;
                    //   this.$isLoading(false)
                })
                .catch((err) => {
                    //   this.$isLoading(false)
                });
        },
        setRankBy(rb) {
            this.rankBy = rb;
            this.transactionRankings = [];
            this.rankings();
        },

        getChart1() {
            this.chart1Title = this.selectedStat + " (" + this.selected + ")";

            let form = new Form({
                year: this.selected,
                type: this.selectedStat,
            });

            this.$isLoading(true);

            form.post('api/customer-dashboard/chart1')
            .then(({data}) => {

                this.$isLoading(false)


            }).catch((err)=>{
                this.$isLoading(false)


            })

        },

        withdrawask(){
            const confirmBox1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button bg-danger mr-2',
                cancelButton: 'btn custom-button-2 ml-2'
            },
            buttonsStyling: false
        })

        confirmBox1.fire({
            title: 'Are you sure?',
            html: 'You want to withdraw to your campaign money or personal?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Campaign',
            cancelButtonText: 'Personal'
        }).then((result1) => {
            if (result1.isConfirmed) {
                console.log(result1.isConfirmed);
                window.location.href = "/withdraw/1";

            }else{
                window.location.href = "/withdraw/0";
            }
        });
        },

        sendask(){
            const confirmBox1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button bg-danger mr-2',
                cancelButton: 'btn custom-button-2 ml-2'
            },
            buttonsStyling: false
        })

        confirmBox1.fire({
            title: 'Are you sure?',
            html: 'You want to withdraw to your campaign money or personal?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Campaign',
            cancelButtonText: 'Personal'
        }).then((result1) => {
            if (result1.isConfirmed) {
                console.log(result1.isConfirmed);
                $('#sendModal').modal('show');
                $('#send_type').val(1);

            }else{
                $('#sendModal').modal('show');
                $('#send_type').val(0);
            }
        });
        }
  }
}
</script>

<style scoped>
.contacts-list a:hover {
    color: #d8f3dc;
}

.contacts-list li:hover {
    background-image: linear-gradient(
        110.3deg,
        rgb(85, 101, 114) 4.3%,
        rgb(69, 73, 92) 96.7%
    );
    border: 2px solid inherit;
    border-radius: 0px 25px 25px 0px;
}
</style>
