<template>
    <div>
        <div v-if="$gate.isBroker()" class="card  custom-card-1 m-0">
            <fieldset>
                <legend class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center">
                     <span>Merchant Store Management</span>

                    </div>
                   <router-link to="/create-merchant-store" class="nav-link" active-class="active" exact>
                       <button class="btn btn-outline-info">Create new Merchant Store <i class="fa fa-plus"></i></button>
                    </router-link>
                </legend>

                <div class="card-header">
                </div>


            <div>

                <!-- <TabNav :tabs="['Approved merchant_stores', 'Blocked merchant_stores']" :selected="selected" @selected="setSelected">
                    <Tab :isSelected="selected === 'Approved merchant_stores' ">
                            
                    </Tab>

                    <Tab :isSelected="selected === 'Blocked merchant_stores' ">
                    </Tab>

                </TabNav> -->

            </div>




            <div class="card-body mt-2 table-responsive">
                <table  id="example2" class="table table-hover border-top">
                    <thead >
                    <tr class="text-center">
                    <th>Store Name</th>
                    <th>Broker Balance</th>

                    <th>Fee Cut Percent</th>
                    <th>Transaction Type</th>
                    <th>Total Accounts</th>
 
                    <th class="border-left">Action / Customer Promo</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class='text-center' v-for='(row, index) in merchant_stores' :key='index'>
                        <td>{{row.company_name}}</td>
                        <td>{{dalasisFormatter(row.broker_balance)}}</td>

                        <td>{{row.fee_cut_percent}} %</td>


                        <td>{{row.transaction_type}}</td>
                        <td></td>
                        <td class="d-flex flex-wrap border-left justify-content-between">
                            <router-link v-bind:to="'/view-merchant-store/' + row.id"
                            active-class="active" exact class="btn mb-4 custom-button-purple1" >
                                <i class="fas fa-eye"></i>
                            </router-link>

                            <button v-if="!row.is_blocked" @click="blockMerchantStores(row.id)"  class="btn custom-button mb-4 btn-danger">
                                <i class="fas fa-ban"></i>
                            </button>

                            <button v-else  @click="unblockMerchantStores(row.id)"   class="btn custom-button mb-4 btn-danger">
                                <i class="fas fa-building"></i>
                            </button>

                            <button v-if="row.customer_promotional_amount > 0" @click="deActivateCustomerPromo(row.id)" class="btn mb-4 custom-button btn-success px-3">
                                    <i class="fas fa-dollar-sign"></i>

                            </button>
                            <button v-else class="btn mb-4 custom-button btn-danger" @click="activateCustomerPromo(row.id)">
                                    <i class="fas fa-money-bill-wave"></i>

                            </button>
                        </td>



                    </tr>
                    <tfoot>
                    

                    </tfoot>
                    </tbody>
                </table>
                <div class="pt-3">
                    <pagination align="center" :data="pageData" @pagination-change-page="getResults"></pagination>

                </div>

            </div>


            </fieldset>







            
        </div>
        <div v-else>
            <not-found></not-found>
            
        </div>
    </div>
</template>

<script>
export default {
  name: "MerchantStores",
  props: {},
  components: {},
  data() {
    return {
      searchBy: "",
      searchText: "",

      form: null,

      merchant_stores: [],
      company: [],
      pageData: {},
    };
  },
  created() {
    // fetch fee table
    if (this.$gate.isBroker()) {
      this.getResults();
      this.form = new Form({
        agent_id: 0,
        pin: "",
        agent_admin_id: "",
      });
    }
  },
  watch: {},
  methods: {
    getResults(page) {
      if (typeof page === "undefined") {
        page = 1;
      }

      this.$isLoading(true);

      axios
        .get("api/broker/merchantStores?page=" + page)
        .then(({ data }) => {
          this.merchant_stores = data.data;
          this.pageData = data;
          this.$isLoading(false);
        })
        .catch((err) => {
          this.merchant_stores = [];
          this.pageData = {};
          this.$isLoading(false);
        });
    },
    blockMerchantStores(id) {
      const confirmBox1 = Swal.mixin({
        customClass: {
          cancelButton: "btn custom-button-2 mx-2",
          confirmButton: "btn custom-button bg-danger mx-2",
        },
        buttonsStyling: false,
      });

      confirmBox1
        .fire({
          title: "Are you sure?",
          text: "You'll be blocking this merchant store ",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, block this Merchant store !",
        })
        .then((result1) => {
          if (result1.isConfirmed) {
            const box1 = Swal.mixin({
              customClass: {
                confirmButton: "btn custom-button-2 mx-2",
                denyButton: "btn custom-button bg-secondary mx-2",
              },
              buttonsStyling: false,
            });

            box1
              .fire({
                title: "Enter your password",
                input: "password",
                inputAttributes: {
                  pattern: "[0-9]+",
                  required: true,
                },
                inputValidator: (value) => {
                  if (!value) {
                    return "You need to enter your password!";
                  }
                },
                showDenyButton: true,
                denyButtonText: `Back`,
                confirmButtonText: "Submit",
              })
              .then((result) => {
                if (result.isConfirmed) {
                  this.$isLoading(true);

                  let del_form = new FormData();
                  del_form.append("company_id", id);
                  del_form.append("password", result.value);

                  axios
                    .post("api/broker/blockCompany", del_form, {
                      headers: { "Content-Type": "application/json" },
                    })
                    .then((data) => {
                      this.$isLoading(false);

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button-2",
                        },
                        buttonsStyling: false,
                      });

                      confirmBox2
                        .fire({
                          icon: "success",
                          title: "Blocked!",
                          text: "Merchant Store Blocked! ... ",
                        })
                        .then((res) => {
                          // reloading the page
                          // window.location.href = '/agents';
                          this.getResults();
                        });
                    })
                    .catch((err) => {
                      this.$isLoading(false);
                      // show them if that city name is taken
                      let errorText = "Merchant store Blocking Unsuccessful";

                      if (err.response.data.errors["password"]) {
                        errorText = err.response.data.errors["password"];
                      } else if (err.response.data.errors["general"]) {
                        errorText = err.response.data.errors["general"];
                      }

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button bg-danger",
                        },
                        buttonsStyling: true,
                      });

                      confirmBox2.fire({
                        icon: "error",
                        title: "Failed!",
                        text: errorText,
                      });
                    });
                } else if (result.isDenied) {
                }
              });
          }
        });
    },
    unblockMerchantStores(id) {
      const confirmBox1 = Swal.mixin({
        customClass: {
          cancelButton: "btn custom-button-2 mx-2",
          confirmButton: "btn custom-button bg-danger mx-2",
        },
        buttonsStyling: false,
      });

      confirmBox1
        .fire({
          title: "Are you sure?",
          text: "You'll be unblocking Merchant store ",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, unblock this Merchant store!",
        })
        .then((result1) => {
          if (result1.isConfirmed) {
            const box1 = Swal.mixin({
              customClass: {
                confirmButton: "btn custom-button-2 mx-2",
                denyButton: "btn custom-button bg-secondary mx-2",
              },
              buttonsStyling: false,
            });

            box1
              .fire({
                title: "Enter your password",
                input: "password",
                inputAttributes: {
                  pattern: "[0-9]+",
                  required: true,
                },
                inputValidator: (value) => {
                  if (!value) {
                    return "You need to enter your password!";
                  }
                },
                showDenyButton: true,
                denyButtonText: `Back`,
                confirmButtonText: "Submit",
              })
              .then((result) => {
                if (result.isConfirmed) {
                  this.$isLoading(true);
                  let del_form = new FormData();
                  del_form.append("company_id", id);
                  del_form.append("password", result.value);

                  axios
                    .post("api/broker/unblockCompany", del_form, {
                      headers: { "Content-Type": "application/json" },
                    })
                    .then((data) => {
                      this.$isLoading(false);

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button-2",
                        },
                        buttonsStyling: false,
                      });

                      confirmBox2
                        .fire({
                          icon: "success",
                          title: "Unblocked!",
                          text: "Merchant Store Unblocked! ... ",
                        })
                        .then((res) => {
                          // reloading the page
                          // window.location.href = '/agents';
                          this.getResults();
                        });
                    })
                    .catch((err) => {
                      this.$isLoading(false);
                      // show them if that city name is taken
                      let errorText = "Merchant Blocking Unsuccessful";

                      if (err.response.data.errors["password"]) {
                        errorText = err.response.data.errors["password"];
                      } else if (err.response.data.errors["general"]) {
                        errorText = err.response.data.errors["general"];
                      }

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button bg-danger",
                        },
                        buttonsStyling: true,
                      });

                      confirmBox2.fire({
                        icon: "error",
                        title: "Failed!",
                        text: errorText,
                      });
                    });
                } else if (result.isDenied) {
                }
              });
          }
        });
    },

    activateCustomerPromo(id) {
      const confirmBox1 = Swal.mixin({
        customClass: {
          cancelButton: "btn custom-button-2 mx-2",
          confirmButton: "btn custom-button bg-danger mx-2",
        },
        buttonsStyling: false,
      });

      confirmBox1
        .fire({
          title: "Are you sure?",
          text: "You'll be activating the customer promo in this merchant store ",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, activate it to this Merchant store !",
        })
        .then((result1) => {
          if (result1.isConfirmed) {
            const box1 = Swal.mixin({
              customClass: {
                confirmButton: "btn custom-button-2 mx-2",
                denyButton: "btn custom-button bg-secondary mx-2",
              },
              buttonsStyling: false,
            });

            box1
              .fire({
                title: "Enter promotional amount",
                input: "number",
                inputAttributes: {
                  required: true,
                },
                inputValidator: (value) => {
                  if (!value) {
                    return "You need to enter the promotional amount!";
                  }
                },
                showDenyButton: true,
                denyButtonText: `Back`,
                confirmButtonText: "Submit",
              })
              .then((result2) => {
                if (result2.isConfirmed) {
                  const box2 = Swal.mixin({
                    customClass: {
                      confirmButton: "btn custom-button-2 mx-2",
                      denyButton: "btn custom-button bg-secondary mx-2",
                    },
                    buttonsStyling: false,
                  });

                  box2
                    .fire({
                      title: "Enter your password",
                      input: "password",
                      inputAttributes: {
                        pattern: "[0-9]+",
                        required: true,
                      },
                      inputValidator: (value) => {
                        if (!value) {
                          return "You need to enter your password!";
                        }
                      },
                      showDenyButton: true,
                      denyButtonText: `Back`,
                      confirmButtonText: "Submit",
                    })
                    .then((result3) => {
                      if (result3.isConfirmed) {
                        this.$isLoading(true);

                        let customer_promo_form = new FormData();
                        customer_promo_form.append("company_id", id);
                        customer_promo_form.append("password", result3.value);
                        customer_promo_form.append(
                          "promotional_amount",
                          result2.value
                        );

                        axios
                          .post(
                            "api/broker/activateMerchantCustomerPromo",
                            customer_promo_form,
                            {
                              headers: { "Content-Type": "application/json" },
                            }
                          )
                          .then((data) => {
                            this.$isLoading(false);

                            const confirmBox2 = Swal.mixin({
                              customClass: {
                                confirmButton: "btn custom-button-2",
                              },
                              buttonsStyling: false,
                            });

                            confirmBox2
                              .fire({
                                icon: "success",
                                title: "Blocked!",
                                text: "Merchant Customer Promo Activated! ... ",
                              })
                              .then((res) => {
                                // reloading the page
                                // window.location.href = '/agents';
                                this.getResults();
                              });
                          })
                          .catch((err) => {
                            this.$isLoading(false);
                            // show them if that city name is taken
                            let errorText =
                              "Merchant Customer Promo Activation Unsuccessful";

                            if (err.response.data.errors["password"]) {
                              errorText = err.response.data.errors["password"];
                            } else if (err.response.data.errors["general"]) {
                              errorText = err.response.data.errors["general"];
                            }

                            const confirmBox2 = Swal.mixin({
                              customClass: {
                                confirmButton: "btn custom-button bg-danger",
                              },
                              buttonsStyling: true,
                            });

                            confirmBox2.fire({
                              icon: "error",
                              title: "Failed!",
                              text: errorText,
                            });
                          });
                      } else if (result3.isDenied) {
                        // go back to previous step
                        box1.fire();
                      }
                    });
                } else if (result2.isDenied) {
                  // go back to previous step
                  box1.fire();
                }
              });
          }
        });
    },

    deActivateCustomerPromo(id) {
      const confirmBox1 = Swal.mixin({
        customClass: {
          cancelButton: "btn custom-button-2 mx-2",
          confirmButton: "btn custom-button bg-danger mx-2",
        },
        buttonsStyling: false,
      });

      confirmBox1
        .fire({
          title: "Are you sure?",
          text: "You'll be Deactivating the customer Promo In this Store ",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, Deativate it!",
        })
        .then((result1) => {
          if (result1.isConfirmed) {
            const box1 = Swal.mixin({
              customClass: {
                confirmButton: "btn custom-button-2 mx-2",
                denyButton: "btn custom-button bg-secondary mx-2",
              },
              buttonsStyling: false,
            });

            box1
              .fire({
                title: "Enter your password",
                input: "password",
                inputAttributes: {
                  pattern: "[0-9]+",
                  required: true,
                },
                inputValidator: (value) => {
                  if (!value) {
                    return "You need to enter your password!";
                  }
                },
                showDenyButton: true,
                denyButtonText: `Back`,
                confirmButtonText: "Submit",
              })
              .then((result) => {
                if (result.isConfirmed) {
                  this.$isLoading(true);
                  let customer_promo_form = new FormData();
                  customer_promo_form.append("company_id", id);
                  customer_promo_form.append("password", result.value);

                  axios
                    .post(
                      "api/broker/deActivateMerchantCustomerPromo",
                      customer_promo_form,
                      {
                        headers: { "Content-Type": "application/json" },
                      }
                    )
                    .then((data) => {
                      this.$isLoading(false);

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button-2",
                        },
                        buttonsStyling: false,
                      });

                      confirmBox2
                        .fire({
                          icon: "success",
                          title: "Unblocked!",
                          text: "Merchant Customer Promo Deactivation Successful! ... ",
                        })
                        .then((res) => {
                          // reloading the page
                          // window.location.href = '/agents';
                          this.getResults();
                        });
                    })
                    .catch((err) => {
                      this.$isLoading(false);
                      // show them if that city name is taken
                      let errorText =
                        "Merchant Customer Deactivation Unsuccessful";

                      if (err.response.data.errors["password"]) {
                        errorText = err.response.data.errors["password"];
                      } else if (err.response.data.errors["general"]) {
                        errorText = err.response.data.errors["general"];
                      }

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button bg-danger",
                        },
                        buttonsStyling: true,
                      });

                      confirmBox2.fire({
                        icon: "error",
                        title: "Failed!",
                        text: errorText,
                      });
                    });
                } else if (result.isDenied) {
                }
              });
          }
        });
    },
  },
};
</script>
