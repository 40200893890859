<template>
    <div>
        <div v-if="$gate.isBroker()" class="card  custom-card-1 m-0">
            <fieldset>
                <legend class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center">
                     <span>Merchants Admin Management</span>

                    </div>

                </legend>

                <div class="card-header">
                </div>




            <div v-if="merchants.length != 0" class="card-body mt-2 table-responsive">
                <table  id="example2" class="table table-hover border-top">
                    <thead >
                    <tr class="text-center">
                    <th>Full Name</th>
                    <th>Store</th>
                    <th>Type</th>

                    <th>Can Transact</th>
                    <th>Total Transactions</th>


                    <th class="border-left">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class='text-center' v-for='(row, index) in merchants' :key='index'>
                        <td>{{row.first_name}} {{row.last_name}}</td>
                        <td>

                            <router-link v-bind:to="'/view-merchant-store/' + row.company_id"
                            active-class="active" exact class="" >
                               {{row.company_name}}
                            </router-link>
                        </td>

                        <td>
                            {{row.user_type}}
                        </td>

                        <td>
                        <span :class="[row.can_user_transact == 'Yes' ? 'text-emerald' : 'text-red1', 'font-weight-bold']">
                        {{row.can_user_transact}}

                        </span>

                        </td>

                        <td>{{row.total_transactions}}</td>
                        <td class="d-flex flex-wrap border-left justify-content-between">
                            <router-link v-bind:to="'/view-merchant-profile/' + row.user_id"
                            active-class="active" exact class="btn mb-4 custom-button-purple1" >
                                <i class="fas fa-eye"></i>
                            </router-link>

                            <button v-if="!row.is_blocked" @click="blockUser(row.user_id)"  class="btn custom-button mb-4 btn-danger">
                                <i class="fas fa-user-slash"></i>
                            </button>

                            <button v-else  @click="unblockUser(row.user_id)"   class="btn custom-button mb-4 btn-danger">
                                <i class="fas fa-user"></i>
                            </button>
                        </td>



                    </tr>
                    <tfoot>


                    </tfoot>
                    </tbody>
                </table>
                <div class="pt-3">
                    <pagination align="center" :data="pageData" @pagination-change-page="getResults"></pagination>

                </div>

            </div>



            </fieldset>








        </div>
        <div v-else>
            <not-found></not-found>

        </div>
    </div>
</template>

<script>

export default {
    name: 'Merchants',
    props:{

    },
  components: {

  },
  data() {
    return {
        searchBy : '',
        searchText: '',

        form: null,

        merchants: [],
        company: [],
        emptyDataText: '',
        pageData: {}
    }
  },
  created(){
      // fetch fee table
        if(this.$gate.isBroker()){
            this.getResults()
        }

  },
    watch: {


    },
  methods:{

        getResults(page){
            if (typeof page === 'undefined') {
            page = 1;
            }

            this.$isLoading(true)


            axios.get('api/broker/merchants?page=' + page+'&merchant_admin=true')
                .then(({data}) => {

                //   this.merchants = data.data;
                //   this.pageData = data
                if(data.data.length != 0){
                  this.merchants = data.data;
                  this.pageData = data
                }else{
                  this.merchants = [];
                  this.pageData = {}
                  this.emptyDataText = 'There are no merchants'
                }

                    this.$isLoading(false)


                }).catch((err)=>{
                  this.merchants = []
                  this.pageData = {}
                this.$isLoading(false)


            })

        },

        blockUser(id){

const confirmBox1 = Swal.mixin({
customClass: {
    cancelButton: 'btn custom-button-2 mx-2',
    confirmButton: 'btn custom-button bg-danger mx-2'
},
buttonsStyling: false
})

confirmBox1.fire({
title: 'Are you sure?',
text: "You'll be blocking this merchants's account ",
icon: 'warning',
showCancelButton: true,
confirmButtonText: 'Yes, block this merchant!'
}).then((result1) => {
if (result1.isConfirmed) {



const box1 = Swal.mixin({
customClass: {
    confirmButton: 'btn custom-button-2 mx-2',
    denyButton: 'btn custom-button bg-secondary mx-2'
},
buttonsStyling: false
})

box1.fire({
    title: 'Enter your password',
    input: 'password',
    inputAttributes: {
        pattern: "[0-9]+",
        required: true,
    },
    inputValidator: (value) => {
        if (!value) {
        return 'You need to enter your password!'
        }

    },
    showDenyButton: true,
    denyButtonText: `Back`,
    confirmButtonText: 'Submit',
    }).then((result) => {
    if (result.isConfirmed) {

    this.$isLoading(true)

    let del_form =  new FormData();
    del_form.append('user_id', id)
    del_form.append('password', result.value)

    axios.post('api/broker/blockUser', del_form,{headers:{"Content-Type":"application/json"}})
        .then((data) => {
            this.$isLoading(false)

            const confirmBox2 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button-2',

            },
            buttonsStyling: false
            })

            confirmBox2.fire({
            icon: 'success',
            title: 'Blocked!',
            text: 'User Blocked! ... '  ,

            }).then((res)=>{
                // reloading the page
            // window.location.href = '/agents';
            this.getResults()
            })



        })
        .catch((err) => {
            this.$isLoading(false)
           // show them if that city name is taken
            let errorText = 'Merchant Blocking Unsuccessful'

            if(err.response.data.errors['password']){
                errorText = err.response.data.errors['password']
            }
            else if(err.response.data.errors['general']){
                errorText = err.response.data.errors['general']
            }

            const confirmBox2 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button bg-danger',

            },
            buttonsStyling: true
            })

            confirmBox2.fire({
            icon: 'error',
            title: 'Failed!',
            text: errorText,

            })
        });




    }
    else if (result.isDenied) {

    }
    })


}
})



},

unblockUser(id){

const confirmBox1 = Swal.mixin({
customClass: {
    cancelButton: 'btn custom-button-2 mx-2',
    confirmButton: 'btn custom-button bg-danger mx-2'
},
buttonsStyling: false
})

confirmBox1.fire({
title: 'Are you sure?',
text: "You'll be unblocking this merchants's account ",
icon: 'warning',
showCancelButton: true,
confirmButtonText: 'Yes, unblock this merchant!'
}).then((result1) => {
if (result1.isConfirmed) {



const box1 = Swal.mixin({
customClass: {
    confirmButton: 'btn custom-button-2 mx-2',
    denyButton: 'btn custom-button bg-secondary mx-2'
},
buttonsStyling: false
})

box1.fire({
    title: 'Enter your password',
    input: 'password',
    inputAttributes: {
        pattern: "[0-9]+",
        required: true,
    },
    inputValidator: (value) => {
        if (!value) {
        return 'You need to enter your password!'
        }

    },
    showDenyButton: true,
    denyButtonText: `Back`,
    confirmButtonText: 'Submit',
    }).then((result) => {
    if (result.isConfirmed) {

    this.$isLoading(true)

    let del_form =  new FormData();
    del_form.append('user_id', id)
    del_form.append('password', result.value)

    axios.post('api/broker/unblockUser', del_form,{headers:{"Content-Type":"application/json"}})
        .then((data) => {
            this.$isLoading(false)

            const confirmBox2 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button-2',

            },
            buttonsStyling: false
            })

            confirmBox2.fire({
            icon: 'success',
            title: 'Unblocked!',
            text: 'User Unblocked! ... '  ,

            }).then((res)=>{
                // reloading the page
            this.getResults()
            })



        })
        .catch((err) => {
            this.$isLoading(false)
           // show them if that city name is taken
            let errorText = 'Merchant Blocking Unsuccessful'

            if(err.response.data.errors['password']){
                errorText = err.response.data.errors['password']
            }
            else if(err.response.data.errors['general']){
                errorText = err.response.data.errors['general']
            }

            const confirmBox2 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button bg-danger',

            },
            buttonsStyling: true
            })

            confirmBox2.fire({
            icon: 'error',
            title: 'Failed!',
            text: errorText,

            })
        });




    }
    else if (result.isDenied) {

    }
    })


}
})



},


  },

}
</script>
