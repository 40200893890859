<template>
    <div>
        <div v-if="$gate.isBroker()" class="card  custom-card-1 m-0">
            <fieldset>
                <legend class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center">
                     <span>Customers registered with {{ customers[0].code }} promocode</span>

                    </div>
                   <!-- <router-link to="/create-customer" class="nav-link" active-class="active" exact>
                       <button class="btn btn-outline-info">Create new Customer <i class="fa fa-plus"></i></button>
                    </router-link> -->
                </legend>

                <div class="card-header">
                </div>

            <div class="card-body mt-2 table-responsive">
                <table  id="example2" class="table table-hover border-top">
                    <thead >
                    <tr class="text-center">
                    <th>Full Name</th>
                    <th>Broker Balance</th>
                    <th>Can Transact</th>
                    <th>Total Transfers</th>
                    <th>Total Deposits</th>

 
                    <th class="border-left">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class='text-center' v-for='(row, index) in customers' :key='index'>
                        <td>{{row.fullname}}
                                        <img v-if="row['is_mrz_verified']" src="/images/verified-badge.png" 
                                        alt="image" style="width: 16px; height:16px;">

                            <span v-if="row.is_blocked" >
                                <i class="fas fa-ban text-danger"></i>


                            </span>
                        </td>

                        <td>{{dalasisFormatter(row.broker_balance)}}</td>

                        <td>
                        <span v-if="!row.is_blocked" :class="[row.can_user_transact == 'Yes' ? 'text-emerald' : 'text-red1', 'font-weight-bold']">
                        {{row.can_user_transact}}
                        </span>
                        <span v-else class="text-danger font-weight-bold">BLOCKED</span>
                            
                        </td>

                        <td>{{row.total_transfers}}</td>
                        <td>{{row.total_deposits}}</td>
                        <td class="d-flex flex-wrap border-left justify-content-between">
                            <router-link v-bind:to="'/view-user-profile/' + row.id"
                            active-class="active" exact class="btn mb-4 custom-button-purple1" >
                                <i class="fas fa-eye"></i>
                            </router-link>

                            <button v-if="!row.is_blocked" @click="blockUser(row.id)"  class="btn custom-button mb-4 btn-danger"> 
                                <i class="fas fa-user-slash"></i>
                            </button>

                            <button v-else  @click="unblockUser(row.id)"   class="btn custom-button mb-4 btn-danger"> 
                                <i class="fas fa-user"></i>
                            </button>

                        </td>
                        


                    </tr>
                    <tfoot>
                    

                    </tfoot>
                    </tbody>
                </table>
                <div class="pt-3">
                    <pagination align="center" :data="pageData" @pagination-change-page="getResults"></pagination>

                </div>

            </div>


            </fieldset>
            
        </div>
        <div v-else>
            <not-found></not-found>
            
        </div>
    </div>
</template>

<script>
export default {
  name: "Customers",
  props: {},
  components: {},
  data() {
    return {
      searchBy: "",
      searchText: "",

      form: null,

      customers: [],
      company: [],
      pageData: {},
    };
  },

  created() {
    // fetch fee table
    if (this.$gate.isBroker()) {
      this.getResults();
      this.form = new Form({
        agent_id: 0,
        pin: "",
        agent_admin_id: "",
      });
    }
  },
  watch: {},
  methods: {
    getResults(page) {
      if (typeof page === "undefined") {
        page = 1;
      }

      this.$isLoading(true);

      axios
        .post("/api/broker/customers?page=" + page, {
          users_by_promocode: this.$route.params.promocode,
        })
        .then((response) => {
          this.customers = response.data.data;
          console.log("customer", this.customers);
          this.pageData = data;
          this.$isLoading(false);
        })
        .catch((err) => {
          this.customers = [];
          this.pageData = {};
          this.$isLoading(false);
        });
    },

    blockUser(id) {
      const confirmBox1 = Swal.mixin({
        customClass: {
          cancelButton: "btn custom-button-2 mx-2",
          confirmButton: "btn custom-button bg-danger mx-2",
        },
        buttonsStyling: false,
      });

      confirmBox1
        .fire({
          title: "Are you sure?",
          text: "You'll be blocking this customer's account ",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, block this customer!",
        })
        .then((result1) => {
          if (result1.isConfirmed) {
            const box1 = Swal.mixin({
              customClass: {
                confirmButton: "btn custom-button-2 mx-2",
                denyButton: "btn custom-button bg-secondary mx-2",
              },
              buttonsStyling: false,
            });

            box1
              .fire({
                title: "Enter your password",
                input: "password",
                inputAttributes: {
                  pattern: "[0-9]+",
                  required: true,
                },
                inputValidator: (value) => {
                  if (!value) {
                    return "You need to enter your password!";
                  }
                },
                showDenyButton: true,
                denyButtonText: `Back`,
                confirmButtonText: "Submit",
              })
              .then((result) => {
                if (result.isConfirmed) {
                  this.$isLoading(true);

                  let del_form = new FormData();
                  del_form.append("user_id", id);
                  del_form.append("password", result.value);

                  axios
                    .post("api/broker/blockUser", del_form, {
                      headers: { "Content-Type": "application/json" },
                    })
                    .then((data) => {
                      this.$isLoading(false);

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button-2",
                        },
                        buttonsStyling: false,
                      });

                      confirmBox2
                        .fire({
                          icon: "success",
                          title: "Blocked!",
                          text: "User Blocked! ... ",
                        })
                        .then((res) => {
                          // reloading the page
                          // window.location.href = '/agents';
                          this.getResults();
                        });
                    })
                    .catch((err) => {
                      this.$isLoading(false);
                      // show them if that city name is taken
                      let errorText = "Customer Blocking Unsuccessful";

                      if (err.response.data.errors["password"]) {
                        errorText = err.response.data.errors["password"];
                      } else if (err.response.data.errors["general"]) {
                        errorText = err.response.data.errors["general"];
                      }

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button bg-danger",
                        },
                        buttonsStyling: true,
                      });

                      confirmBox2.fire({
                        icon: "error",
                        title: "Failed!",
                        text: errorText,
                      });
                    });
                } else if (result.isDenied) {
                }
              });
          }
        });
    },

    unblockUser(id) {
      const confirmBox1 = Swal.mixin({
        customClass: {
          cancelButton: "btn custom-button-2 mx-2",
          confirmButton: "btn custom-button bg-danger mx-2",
        },
        buttonsStyling: false,
      });

      confirmBox1
        .fire({
          title: "Are you sure?",
          text: "You'll be unblocking this customer's account ",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, unblock this customer!",
        })
        .then((result1) => {
          if (result1.isConfirmed) {
            const box1 = Swal.mixin({
              customClass: {
                confirmButton: "btn custom-button-2 mx-2",
                denyButton: "btn custom-button bg-secondary mx-2",
              },
              buttonsStyling: false,
            });

            box1
              .fire({
                title: "Enter your password",
                input: "password",
                inputAttributes: {
                  pattern: "[0-9]+",
                  required: true,
                },
                inputValidator: (value) => {
                  if (!value) {
                    return "You need to enter your password!";
                  }
                },
                showDenyButton: true,
                denyButtonText: `Back`,
                confirmButtonText: "Submit",
              })
              .then((result) => {
                if (result.isConfirmed) {
                  this.$isLoading(true);

                  let del_form = new FormData();
                  del_form.append("user_id", id);
                  del_form.append("password", result.value);

                  axios
                    .post("api/broker/unblockUser", del_form, {
                      headers: { "Content-Type": "application/json" },
                    })
                    .then((data) => {
                      this.$isLoading(false);

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button-2",
                        },
                        buttonsStyling: false,
                      });

                      confirmBox2
                        .fire({
                          icon: "success",
                          title: "Unblocked!",
                          text: "User Unblocked! ... ",
                        })
                        .then((res) => {
                          // reloading the page
                          this.getResults();
                        });
                    })
                    .catch((err) => {
                      this.$isLoading(false);
                      // show them if that city name is taken
                      let errorText = "Customer Blocking Unsuccessful";

                      if (err.response.data.errors["password"]) {
                        errorText = err.response.data.errors["password"];
                      } else if (err.response.data.errors["general"]) {
                        errorText = err.response.data.errors["general"];
                      }

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button bg-danger",
                        },
                        buttonsStyling: true,
                      });

                      confirmBox2.fire({
                        icon: "error",
                        title: "Failed!",
                        text: errorText,
                      });
                    });
                } else if (result.isDenied) {
                }
              });
          }
        });
    },
  },
};
</script>