<template>
  <div>
    <div class="row mt-5">
      <div class="col-12">
        <div
          class="d-flex justify-content-between flex-wrap border-bottom pb-3"
        >
          <div class="d-flex align-items-center">
            <h5>Transfers</h5>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="mt-2 mx-0">
          <fieldset>
            <legend class="d-flex justify-content-center flex-wrap">
              <span>All Transfers</span>
            </legend>

            <div class="d-flex align-items-center">
              <!-- SearchFilter component -->
              <SearchFilter
                :data_length="transfers.length"
                :isFound="isFound"
                :filters="filters"
                data_type="incoming"
              />

              <!-- Export button -->
              <button class="btn btn-lg btn-success ml-3" @click="exportReport">
                Export
              </button>
            </div>

            <div v-if="transfers.length != 0" class="card-body mt-0 pt-0">
              <div class="d-flex justify-content-end mt-0 pt-0 mb-3">
                <button
                  class="btn btn-sm btn-default"
                  data-toggle="modal"
                  data-target="#sortByModal"
                  style="border-color: gray"
                >
                  Sort by
                </button>
              </div>

              <div class="table-responsive">
                <table
                  id="example2"
                  class="table table-bordered table-hover border-top"
                >
                  <thead>
                    <tr class="text-left">
                      <th v-for="(column, index) in column_names" :key="index">
                        {{ column }}
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr class="" v-for="(row, index) in transfers" :key="index">
                      <td>
                        <a
                          class="cursor"
                          :href="'/view-agent-profile/' + row.from_id"
                        >
                          {{ row.from_name }}
                        </a>
                      </td>

                      <td>
                        <a
                          class="cursor"
                          :href="'/view-agent-profile/' + row.to_id"
                        >
                          {{ row.to_name }}
                        </a>
                      </td>
                      <td>
                        {{ row.receiver_fee_cut }}
                      </td>
                      <td>
                        {{ row.time_sent }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="pt-3">
                <pagination
                  align="center"
                  :data="pageData"
                  @pagination-change-page="getResults"
                ></pagination>
              </div>
            </div>

            <div class="text-center" v-else>
              <span
                ><i v-if="selected != 'all'"
                  >They have no {{ selected }} transfers</i
                >
                <i v-else>They have no transfers</i>
              </span>
            </div>
          </fieldset>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="sortByModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="sortByModalTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header border-0">
            <span class="swal2-title">Sort By</span>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <h5 class="text-muted">Select Column</h5>
            <div class="form-group ml-3">
              <div
                v-for="(column, index) in sort_column_names"
                :key="index"
                class="form-check"
              >
                <input
                  class="form-check-input"
                  type="radio"
                  v-model="sort_by.column"
                  :value="column"
                />
                <label class="form-check-label" for="exampleRadios1">
                  {{ column }}
                </label>
              </div>
            </div>
            <hr />
            <h5 class="text-muted">Order By</h5>
            <div class="form-group ml-3">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  v-model="sort_by.order"
                  value="asc"
                />
                <label class="form-check-label" for="exampleRadios1">
                  Ascending
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  v-model="sort_by.order"
                  value="desc"
                />
                <label class="form-check-label" for="exampleRadios1">
                  Descending
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "MerchantTransactions",
  props: {
    merchant_id: Number,
  },
  components: {
    SearchFilter: () =>
      import(
        "../../includes/SearchFilter.vue" /* webpackChunkName: "js/search-filter" */
      ),
  },
  data() {
    return {
      selected: "all",
      isFound: false,

      transfers: [],
      pageData: {},
      form: new Form({
        merchant_id: "",
        // column: "Time Sent",
        // order: "asc",
      }),

      filters: [
        {
          id: 1,
          text: "Agent_to",
          value: "agent_to",
        },
        {
          id: 2,
          text: "Agent_from",
          value: "agent_from",
        },
        {
          id: 3,
          text: "Amount",
          value: "amount",
        },
        {
          id: 4,
          text: "Fee Paid",
          value: "fee_paid",
        },
      ],

      sort_by: {
        column: "Time Sent",
        order: "desc",
      },
      sort_column_names: ["From", "To", "Amount", "Time Sent"],

      column_names: ["From", "To", "Amount", "Time Sent"],
    };
  },
  created() {
    this.form.merchant_id = this.merchant_id;
    this.getResults();
  },

  methods: {
    getResults(page) {
      if (typeof page === "undefined") {
        page = 1;
      }

      this.$isLoading(true);

      this.form
        .post("/api/merchant/merchant-transactions/all" + "?page=" + page)
        .then(({ data }) => {
          this.transfers = data.data;
          this.pageData = data;
          this.$isLoading(false);
        })
        .catch((err) => {
          this.transfers = [];
          this.pageData = {};
          this.$isLoading(false);
        });
    },
    exportReport() {
      this.$isLoading(true);
      this.form
        .post(
          "/api/merchant/merchant-transactions-export/all" + "?page=" + 1, // Remove the { and } from here
          {
            validateStatus: (s) => s <= 500,
            responseType: "blob",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/pdf",
            },
          }
        )
        .then((response) => {
          this.$isLoading(false);

          // Create a Blob from the binary data in the response
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });

          // Create a temporary URL for the Blob
          const url = window.URL.createObjectURL(blob);

          // Create a link element to trigger the download
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "transactions-report.xlsx"); // Set the desired file name here
          document.body.appendChild(link);
          link.click();

          // Clean up the temporary URL
          window.URL.revokeObjectURL(url);

          // Show a success message (you can use any notification library, in this example, I'll assume you are using Swal)
          Swal.fire({
            icon: "success",
            title: "Transactions report exported Successfully!",
          });
        })
        .catch((err) => {
          this.$isLoading(false);
          // Show an error message
          let errorText = "Transactions report export Unsuccessful";
          Swal.fire({
            icon: "error",
            title: "Error",
            text: errorText,
          });
        });
    },
  },
};
</script>
