<template>
    <div>
        <div v-if="$gate.isBroker()" class="card  custom-card-1 m-0">
            <fieldset>
                <legend class="d-flex justify-content-center flex-wrap">
                    <span>All Transfers</span>
                </legend>

                 <SearchFilter :data_length='transfers.length'
                 :filters='filters'
                 @search-form="makeSearch"
                 :isFound="isFound"
                 />
                <!-- /.card-header -->
                <div  v-if="transfers.length != 0" class="card-body table-responsive">
                <table id="example2" class="table table-bordered table-hover border-top">
                    <thead >
                    <tr class="text-center">
                    <th>TransactionsID</th>
                    <th>From</th>
                    <th>To</th>
                    <th>Amount</th>
                    <th>Time Sent</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class='text-center' v-for='(row, index) in transfers' :key='index'>
                     <td>
                        {{ row.unique_id}}
                    </td>
                    <td>

                        <router-link v-if="row.from_user_type === 'Customer'" class="cursor" :to="'/view-user-profile/' + row.from_id">
                            {{row.from_name}}
                        </router-link>

                        <router-link v-else-if="row.from_user_type === 'Merchant'" class="cursor" :to="'/view-merchant-store/' + row.from_id">
                            {{row.from_name}}
                        </router-link>

                    </td>

                    <td>
                        <router-link v-if="row.to_user_type === 'Customer'" class="cursor" :to="'/view-user-profile/' + row.to_id">
                            {{row.to_name}}
                        </router-link>
                        <router-link v-else-if="row.to_user_type === 'Merchant'" class="cursor" :to="'/view-merchant-store/' + row.to_id">
                            {{row.to_name}}
                        </router-link>


                    </td>
                    <td>
                        {{ dalasisFormatter(row.amount)}}
                    </td>


                    <td>{{row.time_sent}}
                    </td>
                    </tr>

                    </tbody>
                </table>
                <div class="pt-3">
                    <pagination align="center" limit="10" show-disabled="true"  :data="pageData" @pagination-change-page="getResults"></pagination>

                </div>
                </div>

                <div class="text-center"  v-else>
                <span><i>{{emptyDataText}}</i></span>
                </div>
                <!-- /.card-body -->
            </fieldset>
        </div>
        <!-- /.card -->

        <div v-else>
            <not-found></not-found>

        </div>
    </div>
</template>



<script>
import SearchFilter from './../../includes/SearchFilter'

export default {
    name: 'CustomerTransfers',
  components: {
   SearchFilter

  },
  data() {
    return {
        isFound: false,
        searchForm: null,
        transfers: [],
        pageData: {},
        filters: [
            {
                'id': 1,
               'text': 'Customer From',
               'value': 'customer_from'
            },
            {
                'id': 2,
               'text': 'Customer To',
               'value': 'customer_to'
            },
            {
                'id': 3,
                'text': 'Amount',
                'value': 'amount'
            },

        ],
        sort_by: {
            column: 'Time Sent',
            order: 'descending'
        },
        sort_column_names: ['Amount', 'Fee Paid',
                 'Sender', 'Receiver', 'Time Sent'],
        emptyDataText: ''

    }
  },
  created(){
      // fetch fee table
      if(this.$gate.isBroker()){
        this.getResults()
      }

  },
  methods: {

      getResults(page){
        if (typeof page === 'undefined') {
            page = 1;
        }
        this.emptyDataText = "";
        if (this.searchForm == null || this.searchForm.search_text == "") {
            this.$isLoading(true)
            axios.post('api/broker/customers/allTransfers?page=' + page)
                .then(({data}) => {

                if(data.data.length != 0){
                  this.transfers = data.data;
                  this.pageData = data
                }else{
                  this.transfers = [];
                  this.pageData = {}
                  this.emptyDataText = 'There are no transfers'
                }

                  this.$isLoading(false)

                }).catch((err)=>{
                    this.transfers = []
                    this.pageData = {}
                  this.$isLoading(false)

                })

            }else{
                this.$isLoading(true);
                this.emptyDataText = "";

                this.searchForm
                    .post("api/broker/customers/allTransfers/makeSearch?page=", {
                        headers: { "Content-Type": "application/json" },
                    })
                    .then(({ data }) => {
                        this.isFound = true;

                        this.transfers = data.data;
                        this.pageData = data;
                        this.emptyDataText = "No search results found...";

                        this.$isLoading(false);
                    })
                    .catch((err) => {
                        this.transfers = [];
                        this.pageData = {};
                        this.emptyDataText = "Something went wrong!";

                        this.$isLoading(false);
                    });
            }

      },
      makeSearch(data) {
            this.searchForm = data;
            this.getResults();

        },
  }
}
</script>

<style scoped>
    .cursor{
        cursor: pointer !important;
    }
</style>
