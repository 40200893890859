export default class Gate {
    constructor(user) {
        this.user = user;
    }

    isBroker() {
        return this.user.user_type === 1;
    }

    isAgentAdmin() {
        return this.user.user_type === 2;
    }
    isAgent() {
        return this.user.user_type === 3;
    }
    isAgency() {
        return this.user.user_type === 3 || this.user.user_type === 2;

    }
    isCustomer() {
        return this.user.user_type === 4;
    }
    isMerchantAdmin() {
        return this.user.user_type === 5;
    }
    isMerchant() {
        return this.user.user_type === 6;
    }
    isCashdistributor() {
        return this.user.user_type === 7;
    }
    isCashcollector() {
        return this.user.user_type === 8;
    }

    isBankAdmin() {
        return this.user.user_type === 9;
    }
    isStore() {
        return this.user.user_type === 5 || this.user.user_type === 6;
    }
    canTransact() {
        return this.user.can_transact === 1;
    }

    isRegistrationComplete() {
        return this.user.pin !== null;

    }

    isMerchantOrMerchantAdmin() {
        return this.user.user_type === 5 || this.user.user_type == 6;
    }


    isPasswordSetAfterLogin() {
        return this.user.pwd_set_after_login == 1;

    }



}
