import { render, staticRenderFns } from "./NawecTransactionLogs.vue?vue&type=template&id=db4705f8&scoped=true&"
import script from "./NawecTransactionLogs.vue?vue&type=script&lang=js&"
export * from "./NawecTransactionLogs.vue?vue&type=script&lang=js&"
import style0 from "./NawecTransactionLogs.vue?vue&type=style&index=0&id=db4705f8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db4705f8",
  null
  
)

export default component.exports