<script>
import { Doughnut } from 'vue-chartjs'

export default {
  extends: Doughnut,
  props: ['chartData'],
  data () {

      return {
          dataPoints: null,
          stackedX: false,
          stackedY: false,
          yAxisLabel : 'Dalasis',
          xAxisLabel: '',
          yearLabels: [],
          barColors: []
      }
  },
  mounted () {
        this.drawChart()
  },
  watch:{
      chartData: function(){
          this.drawChart()
      }
  },

  methods:{

      drawChart(){

          if(true){
              this.renderChart({
                  labels:  this.chartData['labels'],
                  datasets: [
                    {
                    label: 'Transfers',
                    backgroundColor     : this.chartData['colors'],
                    data                : this.chartData['transfers'],
                    
                    }, 

                ]

              }, {
                  legend: {
                      position: 'bottom',
                      display: true
                  },

                  responsive: true,
                  maintainAspectRatio: false})
          }

      

      }
  }
}
</script>