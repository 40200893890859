<template>
  <div>
    <div class="card custom-card-1 m-0">
      <fieldset>
        <legend class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center">
            <span
              >Reconciliation Report Data for "{{
                this.$route.query.transaction_type
              }}" transactions</span
            >
          </div>
          <button class="btn btn-success" @click="exportReport()">
            <i class="fas fa-file-export"></i> Export Excel
          </button>
        </legend>

        <div
          v-if="
            transactions.length != 0 && resultType == 'completedTransactions'
          "
          class="card-body table-responsive"
        >
          <table
            id="example2"
            class="table table-bordered table-hover border-top"
          >
            <thead>
              <tr class="text-center">
                <th>Agency from</th>
                <th>Agency to</th>
                <th>Amount</th>
                <th>Fee Paid</th>
                <th>Sender</th>
                <th>Receiver</th>
                <th>Time Sent</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="text-center"
                v-for="(row, index) in transactions"
                :key="index"
              >
                <td>
                  <span>
                    {{ row.agency_from }} -
                    {{ row.agency_from_branch }}
                  </span>
                  <br />

                  <small>from: {{ row.agent_from_fullname }}</small>
                </td>

                <td>
                  <span>
                    {{ row.agency_to }} -
                    {{ row.agency_to_branch }}
                  </span>
                  <br />
                  <small>to: {{ row.agent_to_fullname }}</small>
                </td>
                <td>
                  {{ dalasisFormatter(row.amount) }}
                </td>

                <td>
                  {{ dalasisFormatter(row.fee_paid) }}
                </td>

                <td>{{ row.sender_name }}</td>
                <td>{{ row.receiver_name }}</td>
                <td>{{ row.time_sent }}</td>
              </tr>
            </tbody>
          </table>
          <div class="pt-3">
            <pagination
              align="center"
              :data="pageData"
              @pagination-change-page="getResults"
            ></pagination>
          </div>
        </div>

        <div
          v-else-if="
            transactions.length != 0 && resultType == 'completedDeposits'
          "
          class="card-body table-responsive"
        >
          <table
            id="example2"
            class="table table-bordered table-hover border-top"
          >
            <thead>
              <tr class="text-center">
                <th>Branch From</th>
                <th>Customer / Merchant</th>
                <th>Amount</th>
                <th>Fee Paid</th>
                <th>Time Sent</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="text-center"
                v-for="(row, index) in transactions"
                :key="index"
              >
                <td>{{ row.agency_from_branch }}</td>

                <td>
                  <router-link
                    v-if="row.user_type === 'Customer'"
                    v-bind:to="'/view-user-profile/' + row.customer_id"
                  >
                    {{ row.customer_fullname }} <br />
                    <span> username: {{ row.customer_username }} </span>
                  </router-link>

                  <router-link
                    v-if="row.user_type === 'Merchant'"
                    v-bind:to="'/view-merchant-profile/' + row.company_id"
                  >
                    {{ row.merchant_store_name }} <br />
                  </router-link>
                </td>
                <td>
                  {{ dalasisFormatter(row.amount) }}
                </td>

                <td>
                  {{ dalasisFormatter(row.fee_paid) }}
                </td>

                <td>{{ row.time_sent }}</td>
              </tr>
            </tbody>
          </table>
          <div class="pt-3">
            <pagination
              align="center"
              :data="pageData"
              @pagination-change-page="getResults"
            ></pagination>
          </div>
        </div>

        <div
          v-else-if="
            transactions.length != 0 && resultType == 'completedWithdrawals'
          "
          class="card-body table-responsive"
        >
          <table
            id="example2"
            class="table table-bordered table-hover border-top"
          >
            <thead>
              <tr class="text-center">
                <th>Branch From</th>
                <th>Customer / Merchant</th>
                <th>Amount</th>
                <th>Fee Paid</th>
                <th>Time Sent</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="text-center"
                v-for="(row, index) in transactions"
                :key="index"
              >
                <td>{{ row.agency_from_branch }}</td>

                <td>
                  <router-link
                    v-if="row.user_type === 'Customer'"
                    v-bind:to="'/view-user-profile/' + row.customer_id"
                  >
                    {{ row.customer_fullname }} <br />
                    <span> username: {{ row.customer_username }} </span>
                  </router-link>

                  <router-link
                    v-if="row.user_type === 'Merchant'"
                    v-bind:to="'/view-merchant-profile/' + row.company_id"
                  >
                    {{ row.merchant_store_name }} <br />
                  </router-link>
                </td>
                <td>
                  {{ dalasisFormatter(row.amount - row.fee_paid) }}
                </td>

                <td>
                  {{ dalasisFormatter(row.fee_paid) }}
                </td>

                <td>{{ row.time_sent }}</td>
              </tr>
            </tbody>
          </table>
          <div class="pt-3">
            <pagination
              align="center"
              :data="pageData"
              @pagination-change-page="getResults"
            ></pagination>
          </div>
        </div>

        <div class="text-center" v-else>
          <span
            ><i>{{ emptyDataText }}</i></span
          >
        </div>
        <!-- /.card-body -->
      </fieldset>
    </div>
    <!-- /.card -->
  </div>
</template>

<script>
export default {
  name: "reconciliation-report-data",
  components: {},
  props: {
    branch_id: Number,
    transaction_type: String,
    start_date: String,
    end_date: String,
  },
  data() {
    return {
      transactions: [],
      pageData: {},
      emptyDataText: "",
      resultType: "",
    };
  },

  watch: {},

  mounted() {
    // this.getResults();
  },

  created() {
    this.getResults();
  },
  methods: {
    getResults(page) {
      if (typeof page === "undefined") {
        page = 1;
      }
      this.emptyDataText = "";

      this.$isLoading(true);
      axios
        .get(
          "api/agency/reconciliation-data?page=" +
            page +
            "&branch_id=" +
            this.$route.query.branch_id +
            "&transaction_type=" +
            this.$route.query.transaction_type +
            "&start_date=" +
            this.$route.query.start_date +
            "&end_date=" +
            this.$route.query.end_date
        )
        .then(({ data }) => {
          console.log("data type ", data.type);
          if (data.data.length != 0 && data.type == "completedTransactions") {
            this.transactions = data.data.data;
            this.resultType = "completedTransactions";
            console.log("transactions", this.transactions);
            this.pageData = data.data;
            // window.location.reload();
          } else if (
            data.data.length != 0 &&
            data.type == "completedDeposits"
          ) {
            this.transactions = data.data.data;
            this.resultType = "completedDeposits";
            console.log("transactions", this.transactions);
            this.pageData = data.data;
          } else if (
            data.data.length != 0 &&
            data.type == "completedWithdrawals"
          ) {
            this.transactions = data.data.data;
            this.resultType = "completedWithdrawals";
            console.log("transactions", this.transactions);
            this.pageData = data.data;
          } else {
            this.transactions = [];
            this.pageData = {};
            this.emptyDataText =
              "There are no " +
              this.$route.query.transaction_type +
              " transactions";
          }

          this.$isLoading(false);
        })
        .catch((err) => {
          this.$isLoading(false);
        });
    },
    exportReport() {
      this.$isLoading(true);
      axios
        .get(
          "api/agency/export-reconciliation-data?branch_id=" +
            this.$route.query.branch_id +
            "&transaction_type=" +
            this.$route.query.transaction_type +
            "&start_date=" +
            this.$route.query.start_date +
            "&end_date=" +
            this.$route.query.end_date,
          {
            validateStatus: (s) => s <= 500,
            responseType: "blob",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/pdf",
            },
          }
        )
        .then((response) => {
          this.$isLoading(false);
          console.log("res", response);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          console.log(url);
          const link = document.createElement("a");
          link.href = url;

          link.setAttribute("download", "transactions-report.xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();

          const confirmBox2 = Swal.mixin({
            customClass: {
              confirmButton: "btn custom-button-2",
            },
            buttonsStyling: false,
          });

          confirmBox2
            .fire({
              icon: "success",
              title: "Transactions report exported Sucessfully!",
            })
            .then((res) => {
              //  window.location.reload()
            });
        })
        .catch((err) => {
          this.$isLoading(false);

          // show them if that city name is taken
          let errorText = "Transactions report exported Unsuccessful";
        });
      // window.open(`/agency/export-reconciliation-data?branch_id=` +
      //         this.$route.query.branch_id +
      //         `&transaction_type=` +
      //         this.$route.query.transaction_type +
      //         `&start_date=` +
      //         this.$route.query.start_date +
      //         `&end_date=` +
      //         this.$route.query.end_date);
      // this.$isLoading(false);
      // axios
      //     .get(
      //     "api/agency/export-reconciliation-data?branch_id=" +
      //         this.$route.query.branch_id +
      //         "&transaction_type=" +
      //         this.$route.query.transaction_type +
      //         "&start_date=" +
      //         this.$route.query.start_date +
      //         "&end_date=" +
      //         this.$route.query.end_date
      //     )
      //     .then(({ data }) => {
      //         const confirmBox2 = Swal.mixin({
      //             customClass: {
      //             confirmButton: "btn custom-button-2",
      //             },
      //             buttonsStyling: false,
      //         });
      //         confirmBox2.fire({
      //             icon: "success",
      //             title: "Export Successful!",
      //         })
      //         .then((res) => {
      //             this.$isLoading(false);
      //             window.location.reload();
      //         });
      //     })
      //     .catch((err) => {
      //         this.$isLoading(false);
      //     });
    },
  },
};
</script>
