<template>
    <div v-if="$gate.isCustomer()">
        <div>
            <legend class="d-flex justify-content-center flex-wrap">
                <span class='d-flex align-items-center'>{{form.column}} Card Deposits</span>
            </legend>
            <!-- <div class="text-center"><i>work in progress</i> </div> -->
            <div v-show="deposits.length > 0" class="d-flex justify-content-end mt-0 pt-0 mb-3">
                <button class="btn btn-sm btn-default"
                    data-toggle="modal" data-target="#filterByModal"
                    style="border-color: gray;">Filter</button>
            </div>


            <!-- group by date -->
            <div v-for="(data, index1) in deposits" :key="index1" class="pb-3">
                <div class="pb-2 font-weight-bold text-muted">
                    {{data[0].day_key}}
                </div>

                <div class="transactions">
                    <section v-for="(d, index2) in data" :key="index2" class="px-3 pb-4 mb-3">
                        <p class="py-1 text-secondary">
                            Deposit ID: <i class="font-weight-bold ">{{d['unique_id']}}</i>
                        </p>
                        <div class="d-flex justify-content-between">
                            <div
                            :class="[d['payment_status'] == 'Incoming' ? 'text-info' :
                            d['payment_status'] == 'failed' ? 'text-danger' :
                            d['payment_status'] == 'succeeded' ? 'text-bgreen' : '', 'font-weight-bold text-md d-flex align-items-center']"
                            >

                                <span>{{d['payment_status']}}</span>
                            </div>
                           
                        </div>
                        <div class="item pt-2">
                            <div v-if="d.product_id" class="detail">
                                <img
                                :src="'/images/profiles/' + d['picture'] "

                                alt="img" class="image-block imaged w48">
                                <div>

                                    <a :href="[d.receipt_url]" target="_blank">
                                      <strong><span >{{ d.currency_sign }}</span>
                                                   
                                    {{d['amount']}} - Card </strong>

                                    <p><span >{{ d.currency_sign }}</span>
                                                    {{d['service_fee']}} - Service fee </p>
                                      <p>{{d['username']}} - Customer </p>

                                      </a>
                                    <p>{{d['date']}}</p>
                                </div>
                            </div>
                      
                            <div class="right">
                                <div class="price font-weight-bold "
                                :class="[d['payment_status'] == 'Incoming'
                                || d['payment_status'] == 'succeeded'
                                ? 'text-emerald' : 'text-red1']"
                                >
                                 
                                    {{dalasisFormatter(d['exchange_amount'])}}
                                 </div>
                            </div>
                        </div>

                    </section>

                </div>

            </div>

            <div class="card py-4 text-center"  v-if="deposits.length == 0">
                <span>{{emptyDataText}}
                </span>
            </div>

                <div class="pt-3">
                    <pagination align="center" :data="pageData" @pagination-change-page="getResults"></pagination>

                </div>


            <div class="modal fade" id="filterByModal" tabindex="-1" role="dialog" aria-labelledby="filterByModalTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">


                            <div class="modal-header border-0">
                                <span class="swal2-title" >Filter By</span>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <h5 class="text-muted">Select Column</h5>
                                <div class="form-group ml-3">
                                    <div v-for="(column, index) in filter_column_names" :key='index' class="form-check">
                                        <input class="form-check-input" type="radio" v-model="filter_by.column" :value="column">
                                        <label class="form-check-label" for="exampleRadios1">
                                            {{column}}
                                        </label>
                                    </div>

                                </div>
                                <hr>
                                <h5 class="text-muted">Order By</h5>
                                <div class="form-group ml-3">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" v-model="filter_by.order" value="ascending">
                                        <label class="form-check-label" for="exampleRadios1">
                                            Ascending
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" v-model="filter_by.order" value="descending">
                                        <label class="form-check-label" for="exampleRadios1">
                                            Descending
                                        </label>
                                    </div>
                                </div>

                            </div>
                            <div class="modal-footer border-0 d-flex justify-content-center">
                                <button type="submit" @click="filterData" class="btn custom-button-2 ">Filter</button>
                            </div>

                    </div>
                </div>
            </div>





        </div>



            <Send :getUserProfile='getUserProfile' />
            <Footer :getUserProfile='getUserProfile' />
    </div>
    <div v-else>
            <not-found></not-found>

    </div>
</template>

<script>

export default {
    name: 'Deposits',
  components: {

  },
  data() {
    return {


        balance: 0,
        pageData: {},
        deposits: [],
        searchForm: null,
        isFound: false,
        form: new Form({
            column: 'All',
            order:'descending'
        }),

        emptyDataText: '',
        filter_by: {
            column: 'All',
            order: 'descending'
        },
        filter_column_names: ['All', 'succeeded',
                 'failed'],

    }
  },
  created(){
      if(this.$gate.isCustomer())
        this.getResults()

  },
  watch:{
        getUserProfile: function () {
            if((this.getUserProfile.balance ==0 || this.getUserProfile.balance ==0.00) && this.getUserProfile.used_kashlock_credit > 0){
                this.getUserProfile.balance= -this.getUserProfile.used_kashlock_credit;
            }

          document.getElementById("bladeAmount").innerHTML =
            this.dalasisFormatter(this.getUserProfile.balance);
            document.getElementById('bladeCreditAmount').innerHTML = this.dalasisFormatter(this.getUserProfile.kashlock_credit)
            if(this.getUserProfile.kashlock_credit > 0){
                document.getElementById('bladeCreditBlock').style.display = 'block';
            }
            this.balance = this.dalasisFormatter(this.getUserProfile.balance)
          

        },

  },

  mounted() {
        this.$store.dispatch("userProfile")


  },
    computed: {



        getUserProfile(){ //final output from here
            return this.$store.getters.getUserProfile
        },

    },
  methods: {
      getResults(page){
        if (typeof page === 'undefined') {
            page = 1;
        }
        this.emptyDataText = ''
        if(this.searchForm == null || this.searchForm.search_text == ''){
                    this.$isLoading(true)

            this.form.post('api/customer/card-deposits?page=' + page)
                .then(({data}) => {

                if(data.grouped_data != 0){
                  this.deposits = data.grouped_data;
                  this.pageData = data.data
                }else{
                  this.deposits = [];
                  this.pageData = {}
                  this.emptyDataText = 'No deposit record'
                }

                    this.$isLoading(false)


                }).catch((err)=>{
                    this.$isLoading(false)


                })
        }
        else{
                    this.$isLoading(true)
            this.emptyDataText = ''

            axios.post('api/customer/makeSearch?page=',{headers:{"Content-Type":"application/json"}})
                .then(({data}) => {
                    this.isFound = true

                  this.deposits = data.data;
                  this.pageData = data.grouped_data
                    this.emptyDataText = 'No search results found...'

                    this.$isLoading(false)

                }).catch((err)=>{
                  this.deposits = [];
                  this.pageData = {}
                 this.emptyDataText = 'Something went wrong!'

                    this.$isLoading(false)

                })

        }


      },

      accept(id, amount, agency){
        //   get the info from id
            let new_balance = this.dalasisFormatter(
                            parseInt(this.getUserProfile.balance) + parseInt(amount)
                        )
            const confirmBox1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button-2 mr-2',
                cancelButton: 'btn custom-button bg-danger ml-2'
            },
            buttonsStyling: false
            })

            confirmBox1.fire({
            title: 'Are you sure?',
            html: "You'll be accepting the <b>" + this.dalasisFormatter(amount) + "</b> deposit from " + agency
            + '<br> Your new balance will be <b> ' + new_balance + '</b>',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, accept it!',
            cancelButtonText: "No, don't!"
            }).then((result1) => {
            if (result1.isConfirmed) {



                // prompt for pin
            const box1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button-2 mr-2',
                cancelButton: 'btn custom-button bg-danger ml-2'
            },
            buttonsStyling: false
            })

            box1.fire({
                title: 'Enter your pin',
                input: 'password',
                inputAttributes: {
                    maxlength: 4,
                    minlength: 4,
                    pattern: "[0-9]+",
                    required: true,
                },
                inputValidator: (value) => {
                    if (!value) {
                    return 'You need to enter your 4 digit pin!'
                    }

                },
                showCancelButton: true,
                confirmButtonText: 'Submit',
                showLoaderOnConfirm: true
                }).then((result) => {
                if (result.isConfirmed) {

                let formData = new Form({
                    deposit_id: id,
                    pin: result.value
                });

                    this.$isLoading(true)

                formData.post('api/customer/acceptAgencyDeposit',{headers:{"Content-Type":"application/json"}})
                    .then((data) => {
                    this.$isLoading(false)

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button-2',

                        },
                        buttonsStyling: false
                        })

                        confirmBox2.fire({
                        icon: 'success',
                        title: 'Accepted!',
                        text: 'Your new balance is ' + new_balance

                        }).then((res)=>{
                            // reloading the page
                        window.location.reload()

                        })



                    })
                    .catch((err) => {
                    this.$isLoading(false)


                        let errorText = 'Operation Unsuccessful'

                        if(err.response.data.errors['pin']){
                            errorText = err.response.data.errors['pin']
                        }
                        else if(err.response.data.errors['general']){
                            errorText = err.response.data.errors['general']
                        }

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button bg-danger',

                        },
                        buttonsStyling: true
                        })

                        confirmBox2.fire({
                        icon: 'error',
                        title: 'Failed!',
                        text: errorText,

                        })
                    });



                }
                })




            }
            })
      },
      filterData(){
            $('#filterByModal').modal('toggle')

            this.form.column = this.filter_by.column
            this.form.order = this.filter_by.order
            // this.searchQuery = this.selected + '?page='

            this.getResults()
      }
  }
}
</script>
