<template>
  <div>
    <div
      v-if="
        ($gate.isBroker() && typeof companyInfo === 'object') ||
        $gate.isAgentAdmin()
      "
      class="card custom-card-1 m-0"
    >
      <form
        class="form-horizontal"
        action=""
        @submit.prevent="createBranch"
        method="post"
      >
        <fieldset>
          <legend class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center">
              <span>Create Branch</span>
            </div>
            <a
              href="#"
              class="nav-link"
              active-class="active"
              onclick="history.go(-1);return false;"
            >
              <button class="btn bg-nyanza font-weight-bold text-gray">
                Go back
              </button>
            </a>
          </legend>
          <div class="card-header"></div>
          <legend>
            <span style="" class="font-weight-bold text-muted">{{
              companyInfo.company_name
            }}</span>
          </legend>

          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="form-group d-flex justify-content-between">
                  <div>
                    <div
                      class="text-danger"
                      v-if="form.errors.has('branches')"
                      v-html="form.errors.get('branches')"
                    />

                    <div
                      class="text-danger"
                      v-if="form.errors.has('phone_numbers')"
                      v-html="form.errors.get('phone_numbers')"
                    />
                  </div>

                  <button
                    type="button"
                    class="btn btn-outline-success"
                    @click="addBranch"
                  >
                    <i class="fa fa-plus"></i> Add Branch
                  </button>
                </div>
              </div>

              <div
                v-for="row in form.branches"
                :key="row.id"
                class="col-12 pb-5"
              >
                <div class="row pb-3 mb-2 border-bottom">
                  <div class="col-6">
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <button type="button" class="btn">Branch</button>
                      </div>
                      <!-- /btn-group -->
                      <Select2
                        class="form-control border-0"
                        required
                        v-model="row.address"
                        :options="locations.towns"
                      />
                    </div>
                  </div>
                  <div
                    class="col-6 d-flex justify-content-end align-items-start"
                  >
                    <button
                      v-if="form.branches.length > 1"
                      type="button"
                      class="btn btn-danger"
                      style="float: left"
                      @click="deleteBranch(row.id)"
                    >
                      Remove
                    </button>
                  </div>

                  <div class="col-12 pb-2 pt-3 pl-5">
                    <button
                      @click="addPhoneNumber(row.id)"
                      type="button"
                      class="float-right btn btn-sm btn-outline-secondary"
                    >
                      <i class="fa fa-plus"></i> Phone Numbers
                    </button>
                  </div>

                  <div
                    v-for="ph in row.phone_numbers"
                    :key="ph.id"
                    class="col-6 pl-5 d-flex justify-content-end"
                  >
                    <div class="input-group ml-5 mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text"
                          ><i class="fas fa-phone-alt"></i
                        ></span>
                      </div>
                      <input
                        required
                        type="number"
                        v-model="ph.number"
                        class="form-control"
                      />
                      <div class="input-group-append">
                        <button
                          v-if="row.phone_numbers.length > 1"
                          type="button"
                          @click="deletePhoneNumber(row.id, ph.id)"
                          class="input-group-text border-0 text-danger bg-white"
                        >
                          <i class="fa fa-times"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 mt-3 d-flex justify-content-center">
                <button
                  type="submit"
                  :disabled="form.busy"
                  class="btn custom-button-1"
                >
                  CREATE
                </button>
              </div>
            </div>
          </div>
        </fieldset>
      </form>
    </div>

    <div v-else-if="!$gate.isBroker() || companyInfo == 'error'">
      <not-found></not-found>
    </div>
  </div>
</template>


<script>
import Select2 from "v-select2-component";

export default {
  name: "CreateBranch",
  props: {},
  components: {
    Select2,
  },
  data() {
    return {
      form: null,

      companyInfo: "",

      b_index: 1, // branch index
      pn_index: 1, // phone index

      locations: [],
    };
  },
  created() {
    if (this.$gate.isBroker() || this.$gate.isAgentAdmin()) {
      this.$isLoading(true);

      let temp_form = new Form({
        id: this.$route.params.id,
      });

      temp_form
        .post("/api/broker/getAgency")
        .then(({ data }) => {
          this.companyInfo = data;
          this.form = new Form({
            company_id: this.companyInfo.id,
            password: null,
            branches: [
              {
                id: 1,
                address: "",
                phone_numbers: [
                  {
                    id: 1,
                    branch_id: 1,
                    number: "",
                  },
                ],
              },
            ],
          });
          this.$isLoading(false);
        })
        .catch((err) => {
          this.companyInfo = "error";
          this.$isLoading(false);
        });
    }
  },
  mounted() {
    if (this.$gate.isBroker() || this.$gate.isAgentAdmin())
      this.$store.dispatch("locations");
    return;
  },
  computed: {
    getLocations() {
      //final output from here
      return this.$store.getters.getLocations;
    },
  },
  watch: {
    getLocations: function () {
      this.locations = this.getLocations;
    },
  },
  methods: {
    createBranch() {
      if (this.$gate.isBroker() || this.$gate.isAgentAdmin()) {
        const box1 = Swal.mixin({
          customClass: {
            confirmButton: "btn custom-button-2 mr-2",
            denyButton: "btn custom-button bg-secondary ml-2",
          },
          buttonsStyling: false,
        });

        box1
          .fire({
            title: "Enter your password",
            text: "To confirm the branches you're created",
            input: "password",
            inputAttributes: {
              required: true,
            },
            inputValidator: (value) => {
              if (!value) {
                return "You need to enter your password!";
              }
            },
            showDenyButton: true,
            denyButtonText: `Back`,
            confirmButtonText: "Submit",
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.form.password = result.value;

              this.$isLoading(true);
              this.form
                .post("/api/broker/createBranch", {
                  headers: { "Content-Type": "application/json" },
                })
                .then((data) => {
                  this.$isLoading(false);

                  const confirmBox2 = Swal.mixin({
                    customClass: {
                      confirmButton: "btn custom-button-2",
                    },
                    buttonsStyling: false,
                  });

                  confirmBox2
                    .fire({
                      icon: "success",
                      title: "Created as Branch(es)!",
                      text: "Branch(es) registration successful",
                    })
                    .then((res) => {
                      window.history.back();
                      // redirect.. change later!
                      //   window.location.href =
                      //     "/view-agency/" + this.companyInfo.id;
                    });
                })
                .catch((err) => {
                  this.$isLoading(false);
                  let errorText = "The branch creations didn't work!";
                  if (err.response.data.errors["password"]) {
                    errorText = err.response.data.errors["password"];
                  }

                  const confirmBox2 = Swal.mixin({
                    customClass: {
                      confirmButton: "btn custom-button bg-danger",
                    },
                    buttonsStyling: true,
                  });

                  confirmBox2.fire({
                    icon: "error",
                    title: "Failed!",
                    text: errorText,
                  });
                });
            }
          });
      }
    },

    addBranch() {
      this.form.branches.push({
        id: ++this.b_index,
        address: "",
        phone_numbers: [
          {
            id: 1,
            branch_id: this.b_index,
            number: "",
          },
        ],
      });
    },
    addPhoneNumber(b_id) {
      let i = this.form.branches.findIndex((b) => b.id == b_id);
      this.form.branches[i].phone_numbers.push({
        id: ++this.pn_index,
        branch_id: b_id,
        number: "",
      });
    },
    deleteBranch(index) {
      this.form.branches = [...this.form.branches.filter((b) => b.id != index)];
    },
    deletePhoneNumber(b_id, pn_id) {
      let i = this.form.branches.findIndex((b) => b.id == b_id);
      this.form.branches[i].phone_numbers = [
        ...this.form.branches[i].phone_numbers.filter((pn) => pn.id != pn_id),
      ];
    },
  },
};
</script>
