<template>
    <div v-if="$gate.isRegistrationComplete() && $gate.isAgentAdmin()">



        <div class="card  custom-card-1 m-0">
          <form class="form-horizontal" action="" @submit.prevent='createAgent' method="post">

            <fieldset>
                <legend class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center">
                     <span>Create Agent</span>

                    </div>
                   <router-link to="/agents" class="nav-link" active-class="active" exact>
                       <button class="btn bg-nyanza font-weight-bold text-gray">Go back</button>
                    </router-link>
                </legend>
                    <div class="card-header">
                    </div>


                    <div class="card-body">

                        <div class="row">
                            <!-- errors -->
                            <div class="col-4">
                                    <div class="text-danger" v-if="form.errors.has('first_name')" v-html="form.errors.get('first_name')" />
                            </div>
                            <div class="col-4">
                                    <div class="text-danger" v-if="form.errors.has('last_name')" v-html="form.errors.get('last_name')" />
                            </div>
                            <div class="col-4">
                                    <div class="text-danger" v-if="form.errors.has('username')" v-html="form.errors.get('username')" />
                            </div>
                            <!-- enderrors -->
                            <div class="col-4">
                                <div class="form-group pb-4">
                                    
                                    <label for="">First Name <span class="text-danger">*</span> 
                                    </label>
                                    <input type="text" v-model="form.first_name" required name="first_name"
                                     maxlength="70" placeholder="Enter First Name" class="form-control"><br>
                                    

                                </div>

                            </div>
                            <div class="col-4">
                                <div class="form-group pb-4">
                                    <label for="">Last Name <span class="text-danger">*</span> </label>
                                
                                    <input type="text" v-model="form.last_name" required maxlength="70" placeholder="Enter Last name" class="form-control"><br>
                                    

                                </div>

                            </div>
                            <div class="col-4">
                                <div class="form-group pb-4">
                                    <label for="">Username <span class="text-danger">*</span> </label>
                                
                                    <input type="text" v-model="form.username" required maxlength="70" placeholder="Enter Last name" class="form-control"><br>
                                    

                                </div>

                            </div>



                            <div class="col-4">
                                <div class="form-group py-4">
                                    
                                    <label for="">Phone number <span class="text-danger">*</span> </label>
                                    <input type="number" v-model="form.phone_number" required maxlength="7"  placeholder="Enter Phone number" class="form-control"><br>

                                </div>

                            </div>

                            <div class="col-4">
                                <div class="form-group py-4">
                                    <label for="">Tin Number <span class="text-danger">*</span> </label>
                                
                                    <input type="text" v-model="form.tin_no" placeholder="Enter Last name" class="form-control"><br>


                                </div>

                            </div>
                            <div class="col-4">
                                <div class="form-group py-4">
                                    <label for="">ID Card number <span class="text-danger">*</span> </label>
                                    <input type="text" v-model="form.id_card_no" placeholder="Enter Last name" class="form-control"><br>

                                </div>

                            </div>


                            <!-- errors -->
                            <div class="col-6">
                                    <div class="text-danger" v-if="form.errors.has('address')" v-html="form.errors.get('address')" />
                            </div>
                            <div class="col-6">
                                    <div class="text-danger" v-if="form.errors.has('branch')" v-html="form.errors.get('branch')" />
                            </div>
                            <!-- enderrors -->


                            <div class="col-6">
                                <div class="form-group py-4">
                                    <label for="">Address <span class="text-danger">*</span> </label>
                                
                                        <Select2 required v-model="form.address" :options="locations.towns" />
                                    
                                </div>

                            </div>
                            <div class="col-6">
                                <div class="form-group py-4">
                                    <label for="">Branch <span class="text-danger">*</span> </label>
                                        <Select2 required v-model="form.branch" :options="branches" />

                                </div>

                            </div>
                            

                            <div class="col-12 text-center border-top pt-4 pb-3">
                               <i> </i>
                            </div>
                            <div class="col-6">
                                <div class="form-group py-4">
                                    <label for="">Email <span class="text-danger">*</span> </label>
                                    <input type="email" v-model="form.email" placeholder="Enter email" class="form-control"><br>

                                </div>           
                            </div>
                            <div class="col-12 d-flex justify-content-center">

                                <div class="form-group">
                                <label class="text-center">Enter password <span class="text-danger">*</span></label>
                                    <input type="password" v-model="form.password" class="form-control"
                                     required placeholder="Enter ...">
                                </div>
                            </div>
                            <div class="col-12 d-flex justify-content-center">

                                <div class="form-group">
                                <label class="text-center">Repeat password</label>

                                    <input type="password" v-model="form.password_confirmation" class="form-control" required
                                     placeholder="Repeat ...">
                                </div>
                                
                            </div>
                            <div class="col-12 text-danger text-center">
                                  <div v-if="form.errors.has('password')" v-html="form.errors.get('password')" />
                            </div>


                            <div class="col-12 mt-3 d-flex justify-content-center">
                                <button type="submit" :disabled="form.busy" class="btn custom-button-1">
                                    SUBMIT
                                </button>
                            </div>


                        </div>

                    </div>




            </fieldset>

          </form>






            
        </div>

    </div>
    <div v-else>
        <not-found></not-found>
    </div>
</template>


<script>
import Select2 from 'v-select2-component';


export default {
    name: 'CreateAgent',
    props:{
       
    },
  components: {
    Select2
  },
  data() {
    return {
        form: new Form({

            first_name: '',
            last_name: '',
            username: '',
            id_card_no: '',
            tin_no: '',
            phone_number: '',
            address: '',
            branch: '',
            password: '',
            password_confirmation: '',
            admin_password: null
        }),

        branches: [],


        locations: []
    }
  },
  created(){

    if(this.$gate.isAgentAdmin() && this.$gate.isRegistrationComplete()){
        this.$isLoading(true) 

        axios.get('api/company/userCompany')
            .then(({data}) => {
                
                this.branches = data.branches
                        this.$isLoading(false) 

            }).catch((err)=>{
                        this.$isLoading(false) 

        })
    }

  },  
  mounted() {
        this.$store.dispatch("locations")


  },
    computed: {

        getLocations(){ //final output from here
            return this.$store.getters.getLocations
        },

    },
    watch:{
            getLocations: function () {
                this.locations = this.getLocations

            },
            
    },
  methods:{

      createAgent(){

        if(!this.$gate.isAgentAdmin() || !this.$gate.isRegistrationComplete())
            return; 

            const box1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button-2 mr-2',
                denyButton: 'btn custom-button bg-secondary ml-2'
            },
            buttonsStyling: false
            })

            box1.fire({
                title: 'Enter your password',
                text: 'To confirm that you want to create this agent',
                input: 'password',
                inputAttributes: {
                    required: true,
                },
                inputValidator: (value) => {
                    if (!value) {
                    return 'You need to enter your password!'
                    }

                },
                showDenyButton: true,
                denyButtonText: `Back`,
                confirmButtonText: 'Submit',
                }).then((result) => {
                if (result.isConfirmed) {

                this.form.admin_password = result.value


                  this.$isLoading(true) 

                    this.form.post('api/agent/createAgent',{headers:{"Content-Type":"application/json"}})
                        .then((data) => {
                        this.$isLoading(false) 

                            const confirmBox2 = Swal.mixin({
                            customClass: {
                                confirmButton: 'btn custom-button-2',
                            
                            },
                            buttonsStyling: false
                            })


                            confirmBox2.fire({
                            icon: 'success',
                            title: 'Registered as Agent!',
                            text: 'Agent registration successful! They can log in and set their pin'

                            }).then((res)=>{
                                // redirect user to dashboard
                            window.location.href = '/agents';

                            })



                        })
                        .catch((err) => {
                        this.$isLoading(false) 

                        let errorText = 'Agent Creation Unsuccessful'

                        if(err.response.data.errors['password']){
                            errorText = err.response.data.errors['password']
                        }
                        else if(err.response.data.errors['general']){
                            errorText = err.response.data.errors['general']
                        }


                            const confirmBox2 = Swal.mixin({
                            customClass: {
                                confirmButton: 'btn custom-button bg-danger',
                            
                            },
                            buttonsStyling: true
                            })

                            confirmBox2.fire({
                            icon: 'error',
                            title: 'Failed!',
                            text: errorText,

                            })

                        });


                }
                })



      }

  },

}
</script>