<template>
    <div>
      <div v-if="$gate.isMerchantAdmin()">
        <div class="card custom-card-1 mt-0 mx-0">
          <legend class="d-flex justify-content-between flex-wrap mb-4">
            <div class="d-flex align-items-center">
              <span>Integration Instructions</span>
            </div>
  
            <div class="text-right">
              <button
                onclick="history.go(-1);return false;"
                class="btn bg-nyanza font-weight-bold text-gray"
              >
                Go back
              </button>
            </div>
  
          </legend>
  
          <p class="font-weight-bold mb-0">Add script reference at the end (before body closing tag)</p>
  
          <pre
            id="scriptcode"
            class="bg-light mt-4 rounded"
          >
  
  
          <span class="text-primary">&lt;script </span><span class="text-primary">src=</span><span class="text-danger">'{{url}}/js/deposit-script.js'</span><span class="text-primary">&gt;&lt;/script&gt;</span>
     
          </pre>
  
          <p class="
            font-weight-bold
            mb-0">Add hidden input fields with value and submit button</p>
          <pre
            id="code"
            class="bg-light mt-4 rounded"
          >
  
          <span class="text-primary">&lt;input</span> <span class="text-primary">type=</span><span class="text-danger">'hidden'</span> <span class="text-primary">id=</span><span class="text-danger">'publishableKey'</span> <span class="text-primary">name=</span><span class="text-danger">'publishableKey'</span> <span class="text-primary">value=</span><span class="text-danger">'xxx_xxxxxx-xxxxxx-xxxxx-xxxxxx'</span> <span class="text-primary">/&gt;</span>
          <span class="text-primary">&lt;input</span> <span class="text-primary">type=</span><span class="text-danger">'hidden'</span> <span class="text-primary">id=</span><span class="text-danger">'depositId'</span> <span class="text-primary">name=</span><span class="text-danger">'depositId'</span> <span class="text-primary">value=</span><span class="text-danger">'as151'</span> <span class="text-primary">/&gt;</span>
          <span class="text-primary">&lt;input</span> <span class="text-primary">type=</span><span class="text-danger">'hidden'</span> <span class="text-primary">id=</span><span class="text-danger">'depositAmount'</span> <span class="text-primary">name=</span><span class="text-danger">'depositAmount'</span> <span class="text-primary">value=</span><span class="text-danger">'1000'</span> <span class="text-primary">/&gt;</span>
          <span class="text-primary">&lt;input</span> <span class="text-primary">type=</span><span class="text-danger">'hidden'</span> <span class="text-primary">id=</span><span class="text-danger">'customerName'</span> <span class="text-primary">name=</span><span class="text-danger">'customerName'</span> <span class="text-primary">value=</span><span class="text-danger">''</span> <span class="text-primary">/&gt;</span>
          <span class="text-primary">&lt;input</span> <span class="text-primary">type=</span><span class="text-danger">'hidden'</span> <span class="text-primary">id=</span><span class="text-danger">'customerEmail'</span> <span class="text-primary">name=</span><span class="text-danger">'customerEmail'</span> <span class="text-primary">value=</span><span class="text-danger">''</span> <span class="text-primary">/&gt;</span>
          <span class="text-primary">&lt;input</span> <span class="text-primary">type=</span><span class="text-danger">'hidden'</span> <span class="text-primary">id=</span><span class="text-danger">'customerPhone'</span> <span class="text-primary">name=</span><span class="text-danger">'customerPhone'</span> <span class="text-primary">value=</span><span class="text-danger">''</span> <span class="text-primary">/&gt;</span>
          
          <span class="text-primary">&lt;button</span> <span class="text-primary">id=</span><span class="text-danger">"pay-btn"</span><span class="text-primary">></span>Deposit<span class="text-primary">&lt;/button&gt;</span>
          </pre>
  
          <p class="text-danger">Please make sure you are using same id name.</p>
  
        </div>
        <Footer :getUserProfile="getUserProfile" />
      </div>
      <div v-else>
        <not-found></not-found>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "ApiKeysDoc",
    props: {},
    components: {},
    data() {
      return {
        balance: 0,
        url: "",
      };
    },
    created() {
      // fetch apikeys table
      this.getMerchantApiKeys();
    },
    watch: {
      getUserProfile: function () {
        this.balance = this.dalasisFormatter(this.getUserProfile.balance);
        document.getElementById("bladeAmount").innerHTML = this.balance;
      },
    },
    mounted() {
      this.$store.dispatch("userProfile");
      this.url = url;
    },
    computed: {
      getUserProfile() {
        //final output from here
        return this.$store.getters.getUserProfile;
      },
    },
  };
  </script>
  