<template>
    <div v-if="$gate.isBroker()">



        <div class="card  custom-card-1 m-0">
          <form class="form-horizontal" action="" @submit.prevent='editInvestment' method="post">

            <fieldset>
                <legend class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center">
                     <span>Edit Investment</span>

                    </div>

                     <a @click="$router.go(-1)" class="nav-link" active-class="active"><button type="button" class="btn bg-nyanza font-weight-bold text-gray">Go back</button></a>

                </legend>
                    <div class="card-header">
                    </div>


                    <div class="card-body">

                        <div class="row">
                            <!-- errors -->
                            <div class="col-6">
                                <div class="text-danger" v-if="form.errors.has('name')" v-html="form.errors.get('name')" />
                            </div>
                            <div class="col-6">
                                
                            </div>
                            

                            <!-- enderrors -->

                            <div class="col-6">
                                <div class="form-group pb-4">

                                    <label for="">Finacial Institutes<span class="text-danger">*</span>
                                    </label>
                                    <select class="form-control" v-model="form.institute_id" >
                                      <option value="">Select Institute</option>
                                    <option :selected="form.institute_id == row.id" :value="row.id" v-for='(row, index) in institutes' :key='index' >{{ row.name }}</option>
                                    
                                    </select>


                                </div>

                            </div>

                            <div class="col-6">
                                <div class="form-group pb-4">

                                    <label for="">Amount <span class="text-danger">*</span>
                                    </label>
                                    <input type="number" v-model="form.amount" required name="amount"
                                    placeholder="Enter Amount" class="form-control" ><br>


                                </div>

                            </div>
                            <div class="col-6">
                                <div class="form-group pb-4">

                                    <label for="">Interest Rate <span>&#37;</span><span class="text-danger">*</span>
                                    </label>
                                    <input type="number" v-model="form.interest_rate" required name="interest_rate"
                                    placeholder="Enter Interest Rate %" class="form-control" min="0" max="100" ><br>


                                </div>

                            </div>
                            <!-- <div class="col-6">
                                <div class="form-group pb-4">

                                    <label for="">Kashlock Period <span class="text-danger">*</span>
                                    </label>
                                   <select class="form-control" v-model="form.period" required >
                                        <option  value="">Sleect Days</option>
                                        <option :selected="form.institute_id == '60'" value="60">60 Days</option>
                                        <option :selected="form.institute_id == '90'" value="90">90 Days</option>
                                        <option :selected="form.institute_id == '180'" value="180">180 Days</option>
                                   </select>


                                </div>

                            </div>
                            <div class="col-6">
                                <div class="form-group pb-4">

                                    <label for="">Maturity Date <span class="text-danger">*</span>
                                    </label>
                                    <input type="date" v-model="form.maturity_date" required name="maturity_date"
                                    placeholder="Enter Maturity Date" class="form-control" ><br>


                                </div>

                            </div> -->
                            <div class="col-6">
                                <div class="form-group pb-4">

                                    <label for="">Excepted Earning <span class="text-danger">*</span>
                                    </label>
                                    <input type="number" v-model="form.expected_earning" required name="expected_earning"
                                    placeholder="Enter Excepted Earning" class="form-control" ><br>


                                </div>

                            </div>

                           




                            <div class="col-12 mt-3 d-flex justify-content-center">
                                <button type="submit" :disabled="form.busy" class="btn custom-button-1">
                                    SUBMIT
                                </button>
                            </div>


                        </div>

                    </div>




            </fieldset>

          </form>







        </div>

    </div>
    <div v-else>
        <not-found></not-found>
    </div>
</template>


<script>

import moment from 'moment';

export default {
    name: 'editInvestment',
    props:{

    },
  components: {

  },
  data() {
    return {
        form: new Form({
            institute_id: '',
            amount: '',
            interest_rate: '',
            maturity_date: '',
            period:'',
            expected_earning: '',
            
         }),
         institutes:[]

    }
  },
  created(){

    let temp_form = new Form({
            id : this.$route.params.id
        })

        temp_form.post('/api/broker/viewInvestment')
            .then(({data}) => {


                this.InstituteInfo = data
                console.log(data);
                this.form = new Form({
                    institute_id: data.institute_id,
                    amount: data.amount,
                    interest_rate: data.interest_rate,
                    period:data.period,
                    maturity_date: moment(data.maturity_date).format('yyyy-MM-DD'),
                    expected_earning: data.expected_earning,
                    password: ''
                })

            this.$isLoading(false)

            }).catch((err)=>{
                this.InstituteInfo = 'error'
            this.$isLoading(false)

        });

    axios.get('/api/broker/getInstitutes',{headers:{"Content-Type":"application/json"}})
                        .then((data) => {
                            console.log(data.data);
                        this.institutes= data.data

                        })


  },
  mounted() {



  },
    computed: {


    },
    watch:{


    },
  methods:{

    editInvestment(){

        if(!this.$gate.isBroker())
            return;

            const box1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button-2 mr-2',
                denyButton: 'btn custom-button bg-secondary ml-2'
            },
            buttonsStyling: false
            })

            box1.fire({
                title: 'Enter your password',
                text: 'To confirm that you want to update this Investment',
                input: 'password',
                inputAttributes: {
                    required: true,

                },
                inputValidator: (value) => {
                    if (!value) {
                    return 'You need to enter your password!'
                    }

                },
                showDenyButton: true,
                denyButtonText: `Back`,
                confirmButtonText: 'Submit',
                }).then((result) => {
                if (result.isConfirmed) {

                this.form.password = result.value
                this.form.id = this.$route.params.id


                  this.$isLoading(true)

                    this.form.post('/api/broker/editInvestment',{headers:{"Content-Type":"application/json"}})
                        .then((data) => {
                        this.$isLoading(false)

                            const confirmBox2 = Swal.mixin({
                            customClass: {
                                confirmButton: 'btn custom-button-2',

                            },
                            buttonsStyling: false
                            })


                            confirmBox2.fire({
                            icon: 'success',
                            title: 'Investment updated!',
                            text: 'Investment updated successful!'

                            }).then((res)=>{
                                // redirect user to dashboard
                            //window.location.href = '/broker-cash-distributors';
                            location.reload();

                            })



                        })
                        .catch((err) => {
                        this.$isLoading(false)

                        let errorText = 'Investment updated Unsuccessful'

                        if(err.response.data.errors['mypassword']){
                            errorText = err.response.data.errors['mypassword']
                        }
                        else if(err.response.data.errors['general']){
                            errorText = err.response.data.errors['general']
                        }


                            const confirmBox2 = Swal.mixin({
                            customClass: {
                                confirmButton: 'btn custom-button bg-danger',

                            },
                            buttonsStyling: true
                            })

                            confirmBox2.fire({
                            icon: 'error',
                            title: 'Failed!',
                            text: errorText,

                            })

                        });


                }
                })



      }

  },

}
</script>
