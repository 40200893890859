<template>
    <div v-if="$gate.isCustomer() || $gate.isStore()">
      <div>
        <legend class="d-flex justify-content-center flex-wrap">
          <span class="d-flex align-items-center"
            >{{ form.column }} Order Transfers</span
          >
        </legend>
        <!-- <div class="text-center"><i>work in progress</i> </div> -->
        <div
          v-show="transfers.length > 0"
          class="d-flex justify-content-end mt-0 pt-0 mb-3"
        >
          <button
            class="btn btn-sm btn-default"
            data-toggle="modal"
            data-target="#filterByModal"
            style="border-color: gray"
          >
            Filter
          </button>
        </div>
  
        <!-- group by date -->
        <div v-for="(data, index1) in transfers" :key="index1" class="pb-3">
          <div class="pb-2 font-weight-bold text-muted">
            {{ data[0].day_key }}
          </div>
  
          <div class="transactions">
             
            <section
              v-for="(d, index2) in data"
              :key="index2"
              class="px-3 pb-md-4 mb-3" style="position: relative;"
            >
            <div
              v-if="d['mode'] == 'test'"
              class="position-absolute test-pay-btn px-3 py-2 mb-0"
            >
              Test
            </div>
              <p class="py-1 text-secondary">
                Order ID: <i class="font-weight-bold">{{ d["order_id"] }}</i>
              </p>
              <div class="d-flex justify-content-between">
                <div class="font-weight-bold text-md d-flex align-items-center">
                  <span v-if="d['pay_status'] == 1 && d['order_status'] == 'PENDING'">Order Pay Request</span>
                  <span v-else-if="d['pay_status'] == 1 && d['order_status'] == 'FAILED'">Order Pay Request</span>
                  <span v-else>Sent</span>
                </div>
              </div>
              <div class="item pt-2">
                <div class="detail">
                  <img
                    :src="'/images/profiles/' + d['other_pic']"
                    alt="img"
                    class="image-block imaged w48"
                  />
  
                  <div>
                    <router-link v-bind:to="'/view-profile/' + d['id']">
                      <strong
                        >{{ d["other_name"] }}
                        <img
                          v-if="d['is_verified']"
                          src="/images/verified-badge.png"
                          alt="image"
                          style="width: 16px; height: 16px"
                        />
                      </strong>
                    </router-link>
  
                    <p>
                      <span class="font-weight-bold">Order</span>
                    </p>
  
                    <p>
                      {{ d["time_updated"] }}
                    </p>
                  </div>
                </div>
                <div class="middle" v-if="d['pay_status'] == 1 && d['order_status'] == 'PENDING'">
                  <button   class="btn btn-sm custom-button-2" @click="payNow(d['orderID'],d['order_id'],d['order_amount'],d['other_name'],d['mode'])" >Pay Now</button>
                     <button   class="btn btn-sm custom-button btn-danger" @click="cancelRequest(d['orderID'],d['order_amount'],d['mode'])" >Cancel</button>
                   </div>
                  <div class="middle" v-if="d['pay_status'] == 1 && d['order_status'] == 'FAILED'">
                    <p><span class="text-danger font-weight-bold text-md">Cancelled</span></p>
                  </div>
                <div class="right">
                  <div
                    style="text-align: end"
                    :class="[
                     d['pay_status'] == 1 && (d['order_status'] == 'PENDING' || d['order_status'] == 'FAILED') ? 'text-emerald' : 'text-red1',
                      'price  font-weight-bold',
                    ]"
                  >
                   
                    <span v-if="d['pay_status'] == 1 && (d['order_status'] == 'PENDING' || d['order_status'] == 'FAILED')"
                      > {{ dalasisFormatter(d["order_amount"]) }} <br
                    /></span>

                    <span v-else
                      >- {{ dalasisFormatter(d["order_amount"]) }} <br
                    /></span>
                  
                    <span
                      class="text-red text-sm float-right"
                      v-if="d['status'] == 'Received' && $gate.isStore()"
                    >
                      - {{ dalasisFormatter(d["fee_paid"]) }}
                      <span class="text-gray">( Fee )</span>
                    </span>
                    <br />
                    <span
                      class="text-red text-sm float-right"
                      v-if="d['discount'] != null && $gate.isStore()"
                    >
                      - {{ dalasisFormatter(d["discount"]) }}
                      <span class="text-gray">(Discount)</span>
                    </span>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
  
        <div class="card py-4 text-center" v-if="transfers.length == 0">
          <span>{{ emptyDataText }} </span>
        </div>
  
        <div class="pt-3">
          <pagination
            align="center"
            :data="pageData"
            @pagination-change-page="getResults"
          ></pagination>
        </div>
  
        <div
          class="modal fade"
          id="filterByModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="filterByModalTitle"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header border-0">
                <span class="swal2-title">Filter By</span>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h5 class="text-muted">Select Column</h5>
                <div class="form-group ml-3">
                  <div
                    v-for="(column, index) in filter_column_names"
                    :key="index"
                    class="form-check"
                  >
                    <input
                      class="form-check-input"
                      type="radio"
                      v-model="filter_by.column"
                      :value="column"
                    />
                    <label class="form-check-label" for="exampleRadios1">
                      {{ column }}
                    </label>
                  </div>
                </div>
                <hr />
                <h5 class="text-muted">Order By</h5>
                <div class="form-group ml-3">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      v-model="filter_by.order"
                      value="ascending"
                    />
                    <label class="form-check-label" for="exampleRadios1">
                      Ascending
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      v-model="filter_by.order"
                      value="descending"
                    />
                    <label class="form-check-label" for="exampleRadios1">
                      Descending
                    </label>
                  </div>
                </div>
              </div>
              <div class="modal-footer border-0 d-flex justify-content-center">
                <button
                  type="submit"
                  @click="filterData"
                  class="btn custom-button-2"
                >
                  Filter
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <Send :getUserProfile="getUserProfile" />
      <Footer :getUserProfile="getUserProfile" />
    </div>
    <div v-else>
      <not-found></not-found>
    </div>
  </template>
  
  <script>
  export default {
    name: "OrderTransactions",
    components: {},
    data() {
      return {
        testMode:false,
        hasReloaded: false,
        balance: 0,
        credit: 0,
        pageData: {},
        transfers: [],
        searchForm: null,
        isFound: false,
        form: new Form({
          column: "All",
          order: "descending",
        }),
  
        emptyDataText: "",
        filter_by: {
          column: "All",
          order: "descending",
        },
        filter_column_names: ["All"],
      };
    },
    created() {
      if (this.$gate.isCustomer() || this.$gate.isStore()) this.getResults();
    },
    watch: {
      getUserProfile: function () {
        if (this.getUserProfile.user_type == 6) {
          document.getElementById("bladeAmount").innerHTML =
            this.dalasisFormatter(+this.getUserProfile.total_transfered_amount);
        } else {
          if((this.getUserProfile.balance ==0 || this.getUserProfile.balance ==0.00) && this.getUserProfile.used_kashlock_credit > 0){
                this.getUserProfile.balance= -this.getUserProfile.used_kashlock_credit;
            }

          document.getElementById("bladeAmount").innerHTML =
            this.dalasisFormatter(this.getUserProfile.balance);
            document.getElementById('bladeCreditAmount').innerHTML = this.dalasisFormatter(this.getUserProfile.kashlock_credit)
            if(this.getUserProfile.kashlock_credit > 0){
                document.getElementById('bladeCreditBlock').style.display = 'block';
            }
            
        }
        if (this.$gate.isStore())
          document.getElementById("bladeCompanyName").innerHTML =
            this.getUserProfile.company_name;
      },
    },
  
    mounted() {
      this.$store.dispatch("userProfile");
    },
    computed: {
      getUserProfile() {
        //final output from here
        return this.$store.getters.getUserProfile;
      },
    },
    methods: {
      getResults(page) {
        if (typeof page === "undefined") {
          page = 1;
        }
        let api_route = "";
        if (this.$gate.isCustomer()) {
          api_route = "api/customer/getOrderTransaction";
        } else if (this.$gate.isStore()) {
          (this.form["location_id"] = this.getUserProfile.balance),
            (api_route = "api/merchant/transfers");
        }
  
        this.emptyDataText = "";
   
          this.$isLoading(true);
  
          this.form
            .post(api_route + "?page=" + page)
            .then(({ data }) => {
              if (data.grouped_data != 0) {
                this.transfers = data.grouped_data;
                this.pageData = data.orders;
              } else {
                this.transfers = [];
                this.pageData = {};
                this.emptyDataText = "No transfer record";
              }
  
              this.$isLoading(false);
            })
            .catch((err) => {
              this.$isLoading(false);
            });
       
      },
  
      filterData() {
        $("#filterByModal").modal("toggle");
  
        this.form.column = this.filter_by.column;
        this.form.order = this.filter_by.order;
        // this.searchQuery = this.selected + '?page='
  
        this.getResults();
      },

      cancelRequest(id,amount,mode){

//   get the info from id


    const confirmBox1 = Swal.mixin({
    customClass: {
        confirmButton: 'btn custom-button-2 mr-2',
        cancelButton: 'btn custom-button bg-danger ml-2'
    },
    buttonsStyling: false
    })

    confirmBox1.fire({
    title: 'Are you sure?',
    text: "You'll be cancelling pay request of the " + this.dalasisFormatter(amount),
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, cancel it!',
    cancelButtonText: "No, don't!"
    }).then((result1) => {
    if (result1.isConfirmed) {



        // prompt for pin
    const box1 = Swal.mixin({
    customClass: {
        confirmButton: 'btn custom-button-2 mr-2',
        cancelButton: 'btn custom-button bg-danger ml-2'
    },
    buttonsStyling: false
    })

    box1.fire({
        title: 'Enter your pin',
        input: 'password',
        inputAttributes: {
            maxlength: 4,
            minlength: 4,
            pattern: "[0-9]+",
            required: true,
        },
        inputValidator: (value) => {
            if (!value) {
            return 'You need to enter your 4 digit pin!'
            }

        },
        showCancelButton: true,
        confirmButtonText: 'Submit',
        showLoaderOnConfirm: true
        }).then((result) => {
        if (result.isConfirmed) {

        let formData = new FormData();
        formData.append('id', id);
        formData.append('mode', mode);

        formData.append('pin', result.value);


        axios.post('/api/order/payment-request-cancel',formData,{headers:{"Content-Type":"application/json"}})
            .then((data) => {

                const confirmBox2 = Swal.mixin({
                customClass: {
                    confirmButton: 'btn custom-button-2',

                },
                buttonsStyling: false
                })

                confirmBox2.fire({
                icon: 'success',
                title: 'Cancelled!',
                text: 'Cancellation successful!'

                }).then((res)=>{
                    // reloading the page
                location.reload();
                //window.location.href = '/outgoing-requests';

                })



            })
            .catch((err) => {



                const confirmBox2 = Swal.mixin({
                customClass: {
                    confirmButton: 'btn custom-button bg-danger',

                },
                buttonsStyling: true
                })

                confirmBox2.fire({
                icon: 'error',
                title: 'Failed!',
                text: err.response.data.errors,

                })
            });



        }
        })




    }
    })
},

depositValidation(order_amount) {
      if(this.getUserProfile.kashlock_credit > 0){


        let disabled =
        order_amount == null ||
        order_amount <= 0 ||
        order_amount > (this.getUserProfile.balance+this.getUserProfile.kashlock_credit);
        return disabled;
      }else{
        let disabled =
        order_amount == null ||
        order_amount <= 0 ||
        order_amount > this.getUserProfile.balance;
        return disabled;
      }
      
},

      payNow(id,orderToken, amount, institution_name,mode){


   
     

          let kashlock_credit=    this.getUserProfile.kashlock_credit;
          if(kashlock_credit > 0 && amount > this.getUserProfile.balance){
            const confirmBox1 = Swal.mixin({
              customClass: {
                  cancelButton: 'btn custom-button-2 mx-2',
                  confirmButton: 'btn custom-button bg-danger mx-2'
              },
              buttonsStyling: false
              })
              confirmBox1.fire({
              title: 'Are you sure?',
              html: "You want to use your kashlock credit amount <b>" +
                this.dalasisFormatter(kashlock_credit) +
                "</b> for this transaction. </br>",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Yes, use it!',
              cancelButtonText: "No, don't use it!",
              showCancelButton: true,
              }).then((result1) => {
              if (result1.isConfirmed) {
                this.sendAmount(id,orderToken, amount, institution_name,mode,kashlock_credit,true)
               }else{
                this.sendAmount(id,orderToken, amount, institution_name,mode,kashlock_credit,false)
               }
              })

          }else{
            this.sendAmount(id,orderToken, amount, institution_name,mode,kashlock_credit,false)
          }


          },

      sendAmount(id,orderToken, amount, institution_name,mode,kashlock_credit,useCredit){
        const confirmBox2 = Swal.mixin({
        customClass: {
          confirmButton: "btn custom-button bg-danger",
        },
        buttonsStyling: true,
      });

      if (this.depositValidation(amount)) {
        confirmBox2.fire({
          icon: "error",
          title: "Failed!",
          text: "Insufficient Balance",
        });
        return;
      }


        let verified_image ='<img src="/images/verified-badge.png" alt="image" style="width: 16px; height:16px;">'
              ;
              
                  //this.fee = fee;
                  let new_balance =
              parseFloat(this.getUserProfile.balance) -
              parseFloat(amount); // this.customer_form.amount - $fee
          //   get the info from id
              const confirmBox1 = Swal.mixin({
              customClass: {
                  cancelButton: 'btn custom-button-2 mx-2',
                  confirmButton: 'btn custom-button bg-danger mx-2'
              },
              buttonsStyling: false
              })

              if(useCredit){
                if(amount <= kashlock_credit){
                  var credit_amt=parseFloat(kashlock_credit)-parseFloat(amount);
                 credit_amt=parseFloat(kashlock_credit)-parseFloat(credit_amt);
                }else if(amount > kashlock_credit){
                  var credit_amt=parseFloat(kashlock_credit);
                  

                }else{
                  var credit_amt=parseFloat(amount)-parseFloat(kashlock_credit);
                // credit_amt=parseFloat(credit_amt)-parseFloat(kashlock_credit);
                }
               

                let new_balance =
              parseFloat(this.getUserProfile.balance) -
              parseFloat(amount)+parseFloat(credit_amt); // this.customer_form.amount - $fee

              if(new_balance ==0.00 || new_balance==0){
                new_balance= -(this.getUserProfile.used_kashlock_credit+parseFloat(credit_amt));
              }

              let amountnew=parseFloat(amount)-parseFloat(credit_amt);
                var text="You will be transfering <b>" +this.dalasisFormatter(amountnew)
                 +" + <span class='text-warning'>"+this.dalasisFormatter(credit_amt)+
                "</span></b> to </br> <b>" +
                  institution_name +
                verified_image +
                "</b> <br> " +
                " Hence Your balance will be " +
                this.dalasisFormatter(new_balance)
              }else{

                let new_balance =
              parseFloat(this.getUserProfile.balance) -
              parseFloat(amount); // this.customer_form.amount - $fee

                var text="You will be transfering <b>" +
                this.dalasisFormatter(amount) +
                "</b> to </br> <b>" +
                  institution_name +
                verified_image +
                "</b> <br> " +
                " Hence Your balance will be " +
                this.dalasisFormatter(new_balance);
              }

              confirmBox1.fire({
              title: 'Are you sure?',
              html: text,
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Yes, send it!',
              showCancelButton: true,
              }).then((result1) => {
              if (result1.isConfirmed) {

                  // prompt for pin
              const box1 = Swal.mixin({
              customClass: {
                  confirmButton: 'btn custom-button-2 mr-2',
                  cancelButton: 'btn custom-button bg-danger ml-2'
              },
              buttonsStyling: false
              })

              box1.fire({
                  title: 'Enter your pin',
                  input: 'password',
                  inputAttributes: {
                      maxlength: 4,
                      minlength: 4,
                      pattern: "[0-9]+",
                      required: true,
                  },
                  inputValidator: (value) => {
                      if (!value) {
                      return 'You need to enter your 4 digit pin!'
                      }

                  },
                  showCancelButton: true,
                  confirmButtonText: 'Submit',
                  showLoaderOnConfirm: true
                  }).then((result) => {
                  if (result.isConfirmed) {

                  let formData = new Form({
                      id:id,
                      orderToken: orderToken,
                      count:2,
                      pin: result.value,
                      mode:mode,
                      useCredit:useCredit
                  });

                      this.$isLoading(true)
                  let url = `/api/order/payment${mode=='test' ? "/test" : ""}`;
                  formData.post(url,{headers:{"Content-Type":"application/json"}})
                      .then((data) => {
                        console.log('orderdata',data);
                      this.$isLoading(false)
                      if(data.data.orderData.status=='SUCCESS'){
                        const confirmBox2 = Swal.mixin({
                          customClass: {
                              confirmButton: 'btn custom-button-2',

                          },
                          buttonsStyling: false
                          })

                          confirmBox2.fire({
                          icon: 'success',
                          title: 'Money sent!',
                          // text: 'Your new balance is ' + new_balance

                          }).then((res)=>{
                              // reloading the page
                          window.location.reload()

                          })
                      }else{
                        const confirmBox2 = Swal.mixin({
                          customClass: {
                              confirmButton: 'btn custom-button bg-danger',

                          },
                          buttonsStyling: true
                          })

                          confirmBox2.fire({
                          icon: 'error',
                          title: 'Failed!',
                          text: data.data.message,

                        }).then((res)=>{
                              // reloading the page
                          window.location.reload()

                          })
                      }

                          



                      })
                      .catch((err) => {
                      this.$isLoading(false)


                          let errorText = 'Operation Unsuccessful'

                          if(err.response.data.errors['pin']){
                              errorText = err.response.data.errors['pin']
                          }
                          else if(err.response.data.errors['general']){
                              errorText = err.response.data.errors['general']
                          }

                          const confirmBox2 = Swal.mixin({
                          customClass: {
                              confirmButton: 'btn custom-button bg-danger',

                          },
                          buttonsStyling: true
                          })

                          confirmBox2.fire({
                          icon: 'error',
                          title: 'Failed!',
                          text: errorText,

                          })
                      });



                  }
                  })




              }
              })
      }    

    },
  };
  </script>
  
  <style scoped>
  .strong:hover {
    color: black;
  }
   .test-pay-btn {
     background: #2d6a4f !important;
     top: 0;
     right: 0;
     overflow: hidden;
     color: #fff;
     border-bottom-left-radius: 20px;
     border-top-right-radius: 20px;
     font-weight: 600;
     font-size: 15px;
   }
  </style>
