<template>
    <div>
        <div v-if="$gate.isBroker() || $gate.isCashdistributor()" class="card  p-5 m-0">
            <fieldset>
                <legend class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center">
                     <span> {{campaignInfo.campaign_name}}</span>

                    </div>
                   <a href="#" class="nav-link" active-class="active" onclick="history.go(-1);return false;">
                       <button class="btn bg-nyanza font-weight-bold text-gray" >Go back</button>
                    </a>
                </legend>
                    <div class="card-header">
                    </div>


                <div>

                    <TabNav :tabs="tabs" :selected="selected" @selected="setSelected">
                        <Tab :isSelected="selected === 'General' ">

                            <div class="row mt-5">
                                <div class="col-12">
                                    <div class="d-flex justify-content-between flex-wrap border-bottom pb-3">
                                        <div class="d-flex align-items-center">
                                            <h5>General</h5>
                                        </div>
                                        <div class="d-flex align-items-center justify-content-end">
                                            <button class="btn btn-success"  @click="downloadFile"><i class="fas fa-file-export"></i> Export Detail Report</button>
                                            <button class="btn btn-primary ml-2"  @click="downloadDepositsFile"><i class="fas fa-file-export"></i> Export Deposits Report</button>
                                        </div>
                                        <div v-if="$gate.isBroker()" class="d-flex justify-content-end flex-wrap">
                                            <a href='#' class="nav-link" data-toggle="modal" data-target="#editCampaignModal">
                                                <button class="btn btn-outline-info">Edit <i class="fa fa-edit"></i></button>
                                            </a>

                                        </div>

                                    </div>

                                </div>
                                <div class="col-8 pr-5">
                                    <div class="border-bottom font-weight-bold py-3">Campaign Details</div>
                                    <div class="row py-3 border-bottom">
                                        <div class="col-6 ">
                                            Campaign name
                                        </div>
                                        <div class="col-6">
                                            {{campaignInfo.campaign_name}}
                                        </div>
                                    </div>
                                    <div class="row py-3 border-bottom">
                                        <div class="col-6 ">
                                        Amount Sent
                                        </div>
                                        <div class="col-6">
                                            {{dalasisFormatter(campaignInfo.amount_sent)}}
                                        </div>
                                    </div>
                                    <div class="row py-3 border-bottom">
                                        <div class="col-6 ">
                                        Campaign Budget
                                        </div>
                                        <div class="col-6">
                                            {{dalasisFormatter(campaignInfo.campaign_budget)}}
                                        </div>
                                    </div>
                                    <div class="row py-3 border-bottom">
                                        <div class="col-6 ">
                                        Campaign Fee
                                        </div>
                                        <div class="col-6">
                                            {{campaignInfo.campaign_fee}}%
                                        </div>
                                    </div>
                                    <div class="row py-3 border-bottom">
                                        <div class="col-6 ">
                                        Campaign Fee Amount
                                        </div>
                                        <div class="col-6">
                                            {{dalasisFormatter(campaignInfo.campaign_fee_amount)}}
                                        </div>
                                    </div>

                                    <div class="row py-3 border-bottom">
                                        <div class="col-6 ">
                                        Registered Users
                                        </div>
                                        <div class="col-6">
                                            {{campaignInfo.register_users}}
                                        </div>
                                    </div>
                                    <div class="row py-3 border-bottom">
                                        <div class="col-6 ">
                                        Non Registered Users
                                        </div>
                                        <div class="col-6">
                                            {{campaignInfo.non_register_users}}
                                        </div>
                                    </div>
                                    <div class="row py-3 border-bottom">
                                        <div class="col-6 ">
                                            Acceptance rate
                                        </div>
                                        <div class="col-6">
                                            {{campaignInfo.accept_count}}
                                        </div>
                                    </div>
                                    <div class="row py-3 border-bottom">
                                        <div class="col-6 ">
                                            Pending rate
                                        </div>
                                        <div class="col-6">
                                            {{campaignInfo.pending_count}}
                                        </div>
                                    </div>

                                    <div class="row py-3 border-bottom">
                                        <div class="col-6 ">
                                            Cancelled rate
                                        </div>
                                        <div class="col-6">
                                            {{campaignInfo.canceled_count}}
                                        </div>
                                    </div>



                                </div>

                                <div class="col-4">
                                    <div class="border-bottom font-weight-bold py-3 mb-3">Avatar</div>
                                    <div class="image">
                                        <img class="img-fluid pad" src="/images/profiles/building-none.png" alt="">
                                    </div>
                                    <!-- <div v-if="canEdit" class="btn btn-info w-100">
                                        Change profile picture
                                    </div> -->
                                </div>
                            </div>
                        </Tab>

                        <Tab :isSelected="selected === 'Outgoing Deposits' ">

                            <div v-if="$gate.isBroker() || ($gate.canTransact() && $gate.isRegistrationComplete())" class="mt-5">

                                <OutgoingDeposits :campaign_id='campaignInfo.Cid' />
                            </div>

                            <div class="" v-else>
                            <legend class="d-flex font-weight-bold text-danger justify-content-center flex-wrap">
                                <span>Ops! You can't see outgoing deposits</span>
                            </legend>
                            <div class="card-header">
                            </div>
                        </div>

                        </Tab>
                        <Tab :isSelected="selected === 'Completed Deposits' ">
                            <div v-if="$gate.isBroker() || ($gate.canTransact() && $gate.isRegistrationComplete())" class="mt-5">

                            <CompletedDeposits :campaign_id='campaignInfo.Cid' />
                        </div>
                            <div class="card custom-card-1" v-else>
                            <legend class="d-flex font-weight-bold text-danger justify-content-center flex-wrap">
                                <span>Ops! You can't see completed deposits</span>
                            </legend>
                            <div class="card-header">
                            </div>
                        </div>

                        </Tab>
                        <Tab :isSelected="selected === 'Cancelled Deposits' ">

                        <div v-if="$gate.isBroker() || ($gate.canTransact() && $gate.isRegistrationComplete())" class="mt-5">

                            <CancelledDeposits :campaign_id='campaignInfo.Cid' />
                        </div>
                        <div class="card custom-card-1" v-else>
                            <legend class="d-flex font-weight-bold text-danger justify-content-center flex-wrap">
                                <span>Ops! You can't see cancelled deposits</span>
                            </legend>
                            <div class="card-header">
                            </div>
                        </div>

                        </Tab>





                    </TabNav>

                </div>



            </fieldset>






            <!-- modals -->


            <div class="modal fade" id="editCampaignModal" tabindex="-1" role="dialog" aria-labelledby="editCampaignModalTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <form @submit.prevent='editCampaign' method="post">

                            <div class="modal-header border-0">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="d-flex justify-content-center">
                                    <h2 class="swal2-title" id="exampleModalLongTitle">Edit Campaign</h2>
                                </div>
                                    <form  @submit.prevent='editCampaign'  class="form-horizontal">
                                        <div class="card-body">
                                        <div class="form-group">
                                            <div class="text-danger" v-if="edit_campaign_form.errors.has('campaign_name')" v-html="edit_campaign_form.errors.get('campaign_name')" />

                                            <label for="inputEmail3" >Campaign Name</label>
                                            <input type="text" class="form-control" id="inputEmail3" v-model="edit_campaign_form.campaign_name">

                                        </div>
                                        <div class="form-group">
                                            <div class="pt-3  text-danger" v-if="edit_campaign_form.errors.has('campaign_budget')" v-html="edit_campaign_form.errors.get('campaign_budget')" />
                                                <label for="">Campaign Budget <span class="text-danger">*</span>
                                                </label>
                                                <input type="number" v-model="edit_campaign_form.campaign_budget" required name="campaign_budget"
                                                placeholder="Enter Campaign Budget" class="form-control"><br>


                                                </div>
                                        <div class="form-group">
                                            <div class="pt-3  text-danger" v-if="edit_campaign_form.errors.has('campaign_fee')" v-html="edit_campaign_form.errors.get('campaign_fee')" />

                                            <label for="inputCut" >Campaign Fee</label>
                                            <input type="number" id="inputCut" v-model="edit_campaign_form.campaign_fee"
                                            maxlength="70" placeholder="Enter Campaign Fee" class="form-control">
                                        </div>


                                        </div>
                                        <!-- /.card-body -->

                                    </form>







                            </div>
                            <div class="modal-footer border-0 d-flex justify-content-center">
                                <button type="submit" class="btn custom-button-2 ">Save Changes</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>











        </div>
        <div v-else>
            <not-found></not-found>

        </div>

    </div>
</template>

<script>


export default {
    name: 'ViewCampaign',
    props:{

    },
    components: {
        TabNav: () => import('../../includes/tabNav' /* webpackChunkName: "js/tab-nav" */),
        Tab: () => import('../../includes/tabs' /* webpackChunkName: "js/tabs" */),
        OutgoingDeposits: () => import('./deposits/OutgoingDeposits'),
        CompletedDeposits: () => import('./deposits/CompletedDeposits'),
        CancelledDeposits: () => import('./deposits/CancelledDeposits'),


    },
  data() {
    return {

        selected: 'General',
        logs: [],
       campaignInfo: {},
        tabs: [],
        pageData: {},
        edit_campaign_form: new Form({
            camp_id: 0,
            campaign_name: '',
            campaign_fee: 0,


        }),


    }
  },

  created(){
      if(this.$gate.isBroker() || this.$gate.isCashdistributor()){
        this.tabs =  ['General','Outgoing Deposits','Completed Deposits','Cancelled Deposits']
        this.$isLoading(true)

        let temp_form = new Form({
            id : this.$route.params.id
        })

        temp_form.post('/api/broker/viewCampaign')
            .then(({data}) => {


                this.campaignInfo = data
                this.edit_campaign_form = new Form({
                    camp_id: data.Cid,
                    campaign_name: data.campaign_name,
                   campaign_fee:data.campaign_fee,
                   campaign_budget:data.campaign_budget,

                    password: ''})

            this.$isLoading(false)

            }).catch((err)=>{
                this.campaignInfo = 'error'
            this.$isLoading(false)

        });



      }
  },
    watch: {

        selected: function(){

            if(this.selected == 'Logs')
                this.getLogs();


        },

    },
  methods:{

    downloadFile(){
        axios.post('/api/broker/export_campaign', this.edit_campaign_form, {
            validateStatus: (s) => s <= 500,
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            }
            }).then((response) => {

                    this.$isLoading(false);
                    console.log('res', response);
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    console.log(url);
                    const link = document.createElement('a');
                    link.href = url;

                    link.setAttribute('download', 'campaign-report.xlsx'); //or any other extension
                    document.body.appendChild(link);
                    link.click();


                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button-2',

                        },
                        buttonsStyling: false
                        })

                        confirmBox2.fire({
                        icon: 'success',
                        title: 'Campaign report exported Sucessfully!',

                        }).then((res)=>{

                         window.location.reload()


                        })



                    })
                    .catch((err) => {
                        this.$isLoading(false)

                        // show them if that city name is taken
                        let errorText = 'Campaign report exported Unsuccessful'
                     });
        },

        downloadDepositsFile(){
        axios.post('/api/broker/distributor/exportDeposite', this.edit_campaign_form, {
            validateStatus: (s) => s <= 500,
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            }
            }).then((response) => {

                    this.$isLoading(false);
                    console.log('res', response);
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    console.log(url);
                    const link = document.createElement('a');
                    link.href = url;

                    link.setAttribute('download', 'campaign-deposits-report.xlsx'); //or any other extension
                    document.body.appendChild(link);
                    link.click();


                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button-2',

                        },
                        buttonsStyling: false
                        })

                        confirmBox2.fire({
                        icon: 'success',
                        title: 'Campaign report exported Sucessfully!',

                        }).then((res)=>{

                         window.location.reload()


                        })



                    })
                    .catch((err) => {
                        this.$isLoading(false)

                        // show them if that city name is taken
                        let errorText = 'Campaign report exported Unsuccessful'
                     });
        },

        setSelected(tab){
            this.selected = tab;

        },

    openModal(branch_id, modal_type, agent_admin_id){
            this.choosen_branch = branch_id

            if(modal_type == 'createAgentAdmin'){
            $('#createAgentAdminModal').modal('toggle')

            }
            else if(modal_type == 'changeAgentAdmin'){
            this.pick_agent_admin_form.agent_admin_id = agent_admin_id

            $('#pickAgentAdminModal').modal('toggle')

            }

        },
        closeModal(admin){
            $('#createAgentAdminModal').modal('toggle')
            if(admin == 'new'){


                window.location.href = '/create-agent-admin/' + this.choosen_branch;

            }
            else if(admin == 'old'){
            $('#pickAgentAdminModal').modal('toggle')

            }
        },



        editCampaign(){
            $('#editCampaignModal').modal('toggle')

            const confirmBox1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button bg-danger mr-2',
                cancelButton: 'btn custom-button-2 ml-2'
            },
            buttonsStyling: false
            })

            confirmBox1.fire({
            title: 'Are you sure?',
            text: 'Your changes will be saved and the campaign will be affected',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, save the changes!'
            }).then((result1) => {
            if (result1.isConfirmed) {


                // prompt for pin
            const box1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button bg-danger  mr-2',
                cancelButton: 'btn custom-button-2 ml-2'
            },
            buttonsStyling: false
            })

            box1.fire({
                title: 'Enter your password',
                text: 'To confirm your changes',
                input: 'password',
                inputAttributes: {
                    required: true,
                },
                inputValidator: (value) => {
                    if (!value) {
                    return 'You need to enter your password!'
                    }

                },
                showCancelButton: true,
                confirmButtonText: 'Submit',
                showLoaderOnConfirm: true
                }).then((result) => {
                if (result.isConfirmed) {
                    this.edit_campaign_form.password = result.value
                  this.$isLoading(true)

                this.edit_campaign_form.post('/api/broker/editCampaign', {headers:{"Content-Type":"application/json"}})
                    .then((data) => {


                  this.$isLoading(false)

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button-2',

                        },
                        buttonsStyling: false
                        })

                        confirmBox2.fire({
                        icon: 'success',
                        title: 'Campaign changes saved!',

                        }).then((res)=>{

                         window.location.reload()


                        })



                    })
                    .catch((err) => {
                        this.$isLoading(false)

                        // show them if that city name is taken
                        let errorText = 'Campaign changes Unsuccessful'

                        if(err.response.data.errors['password']){
                            errorText = err.response.data.errors['password']
                                                    const confirmBox2 = Swal.mixin({
                                            customClass: {
                                                confirmButton: 'btn custom-button bg-danger',

                                            },
                                            buttonsStyling: true
                                            })

                                            confirmBox2.fire({
                                            icon: 'error',
                                            title: 'Failed!',
                                            text: errorText,

                                            })
                        }
                        else{
                            $('#editCampaignModal').modal('toggle')

                        }



                    });






                }
                })

            }
            })
        },












  },

}
</script>
