<template>
    <div  class="customer ">
        <legend class="d-flex justify-content-center flex-wrap">
            <span class='d-flex align-items-center'>My Bank Accounts</span>
        </legend>
       <div class="settings">
           <i>Coming Soon</i>
       </div>

            <Send :getUserProfile='getUserProfile' />
            <Footer :getUserProfile='getUserProfile' />
            

    </div>
</template>

<script>

export default {
  name: 'BankAccounts',
  components: {
  },
  watch:{
        getUserProfile: function () {
           
            if(this.$gate.isStore()){
                document.getElementById('bladeCompanyName').innerHTML = this.getUserProfile.company_name

            }


        },

  },

  mounted() {
        this.$store.dispatch("userProfile")


  },
    computed: {



        getUserProfile(){ //final output from here
            return this.$store.getters.getUserProfile
        },

    },
}
</script>