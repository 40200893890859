<template>
    <div>
        <div v-if="$gate.isBroker()" class="card  p-2 m-0">
            <fieldset class="mt-5">
                            <legend class="d-flex justify-content-between flex-wrap">
                                <div class="d-flex align-items-center">
                                <span>Kashlocks</span>

                                </div>

                           
                                <!-- <div class="d-flex align-items-center justify-content-end">
                                    <button class="btn btn-success"  ><i class="fas fa-file-export"></i> Export Report</button>
                                </div> -->
                            </legend>
                            <div class="card-header">
                    <div class="d-flex">
                    <select @change="makeSearch()" required v-model="form.customer_id" class="form-control form-control-lg ">
                        <option value=""  selected>All Customer</option>
                        <option v-for="row in customers" :key="row.id" :value="row.user_id">{{row.first_name+' '+row.last_name}}</option>

                    </select>
                    <select  @change="makeSearch()" required v-model="form.period" class="form-control form-control-lg ">
                        <option value=""  selected>All Period</option>
                        <option value="60">60 Days</option>
                          <option value="90">90 Days</option>
                       <option value="180">180 Days</option>

                    </select>

                    <select  @change="makeSearch()"  required v-model="form.status" class="form-control form-control-lg ">
                        <option value=""  selected>All Status</option>
                        <option value="0">Locked</option>
                          <option value="1">Unlocked</option>

                    </select>
                    

                    </div>
                    <div class="d-flex mt-2">
                        <Datepicker  @input="makeSearch()" input-class="form-control form-control-lg" wrapper-class="inline" placeholder="From date" format="dd/MM/yyyy" :clear-button="true" v-model='form.fromdate'></Datepicker>
			            <Datepicker   @input="makeSearch()" input-class="form-control form-control-lg" wrapper-class="inline" placeholder="To date" format="dd/MM/yyyy" :clear-button="true" v-model='form.todate'  ></Datepicker>
                    </div>

                    <!-- <SearchFilter :data_length='requests.length' :isFound='isFound'
                        :filters='filters' :status='status'   data_type="requests" @search-form='makeSearch'
                        /> -->

                </div>


                        <div>



                        </div>




                        <div  class="card-body p-0 mt-2 table-responsive">
                            <table  id="example2" class="table table-hover border-top">
                                <thead >
                                <tr class="text-center">
                                <th>Sr No.</th>
                                <th>Customer Name</th>
                                <th>Period</th>
                                <th>Amount</th>
                                <th>Credit</th>
                                <th>Created Date</th>
                                <th>Maturity Date</th>
                                <th>Status</th>
                                <th class="border-left">Action</th>
                                </tr>
                                </thead>
                                <tbody v-if="kashlocks.length != 0">
                               
                                <tr class='text-center' v-for='(row, index) in kashlocks' :key='index'>
                                    <td>{{index+1}}</td>
                                    <td>{{row.first_name+' '+row.last_name}}
                                     </td>
                                    <td>{{row.period}} Days</td>
                                    <td>{{dalasisFormatter(row.amount)}}</td>
                                    <td><span class="text-danger">-{{dalasisFormatter(row.credit_amount)}}</span></td>
                                    <td>{{row.time}}</td>
                                    <td>{{row.maturity_date}}</td>
                                   
                                    <td v-if="row.Kstatus==0"><span class="badge badge-success badge-pill">Locked</span></td>
                                    <td v-else><span class="badge badge-danger badge-pill">Unlocked</span>
                                        <span v-if="row.cancel_at != null" class="badge badge-primary badge-pill">Cancelled</span>
                                    </td>


                                    <td class="d-flex flex-wrap border-left justify-content-around">


                                        <router-link v-bind:to="'/broker-kashlocks-view/' + row.Cid"
                                        active-class="active" exact class="btn mb-4 custom-button-purple1" >
                                            <i class="fas fa-eye"></i>
                                        </router-link>
                                      </td>



                                </tr>
                                <tfoot>


                                </tfoot>
                                </tbody>
                                <tbody  v-else>
                                    <tr class='text-center'>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td><span><i>You have no kashlocks.</i></span></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                    
                                </tbody>
                            </table>
                            <div class="pt-3">
                                <pagination align="center" :data="pageData" @pagination-change-page="getResults"></pagination>

                            </div>

                        </div>
                    


            </fieldset>
        </div>
    </div>

</template>

    <script>

    export default {
        name: 'kashlocks',

        components: {


        },
      data() {
        return {
            balance: 0,
            kashlocks: null,
            pageData: {},
            fee:'',
            customers:[],
            form: new Form ({
            customer_id: '',
            period: '',
            status: '',
            fromdate: '',
            todate: '',
        }),




        }
      },
      created(){

          // fetch campaign table
          if(this.$gate.isBroker()){
                this.getResults()
           
              
                this.getCustomers();
            }


      },
  

      methods:{

        getCustomers(){
        axios.get('api/broker/getKashlockCustomer')
                .then(({data}) => {
                  console.log("Collectors", data)
                  
                    this.$isLoading(false)
                    if(data.length != 0){
                        this.customers = data;
                       
                    }else{
                        this.customers = [];
                       
                    }


                }).catch((err)=>{
                  this.customers = []
                 
                this.$isLoading(false)


            })
    },
        getResults(page){
                if (typeof page === 'undefined') {
                page = 1;
                }

                this.$isLoading(true)

              
            this.form.page=page;
            this.form.post('/api/broker/kashlocks')
                    .then(({data}) => {
                      console.log("kashlocks", data)
                      this.kashlocks = data.data;
                      this.pageData = data
                        this.$isLoading(false)


                    }).catch((err)=>{
                      this.kashlocks = []
                      this.pageData = {}
                    this.$isLoading(false)


                })

            },


            makeSearch(){
           this.getResults()
            // emit up
        },

      },

     

    }
    </script>
