<template>
    <div>
        <div v-if="$gate.isBroker()" class="card  custom-card-1 m-0">
            <fieldset>
                <legend class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center">
                     <span>Products Management</span>

                    </div>
                   <router-link to="/create-product" class="nav-link" active-class="active" exact>
                       <button class="btn btn-outline-info">Create new Product <i class="fa fa-plus"></i></button>
                    </router-link>
                </legend>

                <div class="card-header">
                </div>


            <div>


            </div>




            <div class="card-body mt-2 table-responsive">
                <table  id="example2" class="table table-hover border-top">
                    <thead >
                    <tr class="text-center">
                    <th>Sr No.</th>
                    <th>Product Name</th>
                    <th>Price</th>
                    <th>Currency</th>
                    <th>Fee</th>
                    <th>Status</th>
                    <th class="border-left">Action</th>
                    </tr>
                    </thead>
                    <tbody v-if="products.length > 0">
                    <tr class='text-center' v-for='(row, index) in products' :key='index'>
                        <td>{{ index + 1 }}</td>
                        <td>{{row.product_name}}
                            <span v-if="row.status==1" >
                                <i class="fas fa-ban text-danger"></i>


                            </span>

                        </td>
                        <td>{{row.product_price}}</td>
                        <td>{{row.currency}}</td>
                        <td>{{row.service_fee}} %</td>
                     
                        <td v-if="row.status==1"><span class="badge badge-warning badge-pill">Inactive</span></td>
                        <td v-else><span class="badge badge-success badge-pill">Active</span></td>


                        <td class="d-flex flex-wrap border-left justify-content-around">
                           
                            
                            <router-link v-bind:to="'/edit-product/' + row.id"
                            active-class="active" exact class="btn mb-4 custom-button-purple1" >
                                <i class="fas fa-edit"></i>
                            </router-link>
                         
                            <!-- <button class="btn mb-4 custom-button btn-info">
                                    <i class="fas fa-edit"></i>

                            </button> -->


                            <button v-if="row.status==1" @click="active(row.id)"  class="btn custom-button mb-4 btn-success" title="active">
                                <i class="fas fa-circle"></i>
                            </button>

                            <button v-else  @click="inactive(row.id)"   class="btn custom-button mb-4 btn-danger" title="inactive">
                                <i class="fas fa-ban"></i>
                            </button>

                        </td>



                    </tr>
                    <tfoot>


                    </tfoot>
                    </tbody>
                    <tbody  v-else>
                                    <tr class='text-center'>
                                    
                                        <td></td>
                                        <td></td>
                                        <td><span><i>You have no products.</i></span></td>
                                        <td></td>
                                        <td></td>
                                        
                                    </tr>
                    
                                </tbody>
                </table>
                <div class="pt-3">
                    <pagination align="center" :data="pageData" @pagination-change-page="getResults"></pagination>

                </div>

            </div>


            </fieldset>








        </div>
        <div v-else>
            <not-found></not-found>

        </div>
    </div>
</template>

<script>

export default {
    name: 'Products',
    props:{

    },
  components: {

  },
  data() {
    return {
        searchBy : '',
        searchText: '',

        form: null,

        products: [],
        pageData: {}
    }
  },
  created(){
      // fetch fee table
      if(this.$gate.isBroker()){
            this.getResults()

        }

  },
    watch: {


    },
  methods:{

        getResults(page){
            if (typeof page === 'undefined') {
            page = 1;
            }

            this.$isLoading(true)


            axios.get('api/broker/products?page=' + page)
                .then(({data}) => {
                  console.log("products", data)
                  this.products = data.data;
                  console.log(this.products.length);
                  this.pageData = data
                    this.$isLoading(false)


                }).catch((err)=>{
                  this.products = []
                  this.pageData = {}
                this.$isLoading(false)


            })

        },




        inactive(id){

            const confirmBox1 = Swal.mixin({
            customClass: {
                cancelButton: 'btn custom-button-2 mx-2',
                confirmButton: 'btn custom-button bg-danger mx-2'
            },
            buttonsStyling: false
            })

            confirmBox1.fire({
            title: 'Are you sure?',
            text: "You'll be deactivating this product",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, deactivate this product!'
            }).then((result1) => {
            if (result1.isConfirmed) {



            const box1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button-2 mx-2',
                denyButton: 'btn custom-button bg-secondary mx-2'
            },
            buttonsStyling: false
            })

            box1.fire({
                title: 'Enter your password',
                input: 'password',
                inputAttributes: {
                    pattern: "[0-9]+",
                    required: true,
                },
                inputValidator: (value) => {
                    if (!value) {
                    return 'You need to enter your password!'
                    }

                },
                showDenyButton: true,
                denyButtonText: `Back`,
                confirmButtonText: 'Submit',
                }).then((result) => {
                if (result.isConfirmed) {

                this.$isLoading(true)

                let del_form =  new FormData();
                del_form.append('product_id', id)
                del_form.append('status', 1)
                del_form.append('password', result.value)

                axios.post('api/product/mark', del_form,{headers:{"Content-Type":"application/json"}})
                    .then((data) => {
                        this.$isLoading(false)

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button-2',

                        },
                        buttonsStyling: false
                        })

                        confirmBox2.fire({
                        icon: 'success',
                        title: 'Deactivated!',
                        text: 'Product Deactivated! ... ' ,

                        }).then((res)=>{
                            // reloading the page
                        // window.location.href = '/agents';
                        this.getResults()
                        })



                    })
                    .catch((err) => {
                        this.$isLoading(false)
                       // show them if that city name is taken
                        let errorText = 'Product Deactivation Unsuccessful'

                        if(err.response.data.errors['password']){
                            errorText = err.response.data.errors['password']
                        }
                        else if(err.response.data.errors['general']){
                            errorText = err.response.data.errors['general']
                        }

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button bg-danger',

                        },
                        buttonsStyling: true
                        })

                        confirmBox2.fire({
                        icon: 'error',
                        title: 'Failed!',
                        text: errorText,

                        })
                    });




                }
                else if (result.isDenied) {

                }
                })


            }
            })



        },

        active(id){

            const confirmBox1 = Swal.mixin({
            customClass: {
                cancelButton: 'btn custom-button-2 mx-2',
                confirmButton: 'btn custom-button bg-danger mx-2'
            },
            buttonsStyling: false
            })

            confirmBox1.fire({
            title: 'Are you sure?',
            text: "You'll be activate this product ",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, activate this product!'
            }).then((result1) => {
            if (result1.isConfirmed) {



            const box1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button-2 mx-2',
                denyButton: 'btn custom-button bg-secondary mx-2'
            },
            buttonsStyling: false
            })

            box1.fire({
                title: 'Enter your password',
                input: 'password',
                inputAttributes: {
                    pattern: "[0-9]+",
                    required: true,
                },
                inputValidator: (value) => {
                    if (!value) {
                    return 'You need to enter your password!'
                    }

                },
                showDenyButton: true,
                denyButtonText: `Back`,
                confirmButtonText: 'Submit',
                }).then((result) => {
                if (result.isConfirmed) {

                this.$isLoading(true)

                let del_form =  new FormData();
                del_form.append('product_id', id)
                del_form.append('status', 0)
                del_form.append('password', result.value)

                axios.post('api/product/mark', del_form,{headers:{"Content-Type":"application/json"}})
                    .then((data) => {
                        this.$isLoading(false)

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button-2',

                        },
                        buttonsStyling: false
                        })

                        confirmBox2.fire({
                        icon: 'success',
                        title: 'Activated!',
                        text: 'Product Activated! ... '  ,

                        }).then((res)=>{
                            // reloading the page
                        // window.location.href = '/agents';
                        this.getResults()
                        })



                    })
                    .catch((err) => {
                        this.$isLoading(false)
                       // show them if that city name is taken
                        let errorText = 'Product Activation Unsuccessful'

                        if(err.response.data.errors['password']){
                            errorText = err.response.data.errors['password']
                        }
                        else if(err.response.data.errors['general']){
                            errorText = err.response.data.errors['general']
                        }

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button bg-danger',

                        },
                        buttonsStyling: true
                        })

                        confirmBox2.fire({
                        icon: 'error',
                        title: 'Failed!',
                        text: errorText,

                        })
                    });




                }
                else if (result.isDenied) {

                }
                })


            }
            })



        },

        

  },

}
</script>
