<template>
    <div>

        <div class="card  custom-card-1 m-0">
            <fieldset>
                <legend class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center">
                        <span>Transaction Types</span>

                    </div>
                   <router-link v-if="$gate.isBroker()" to="/create-transaction-type" class="nav-link" active-class="active" exact>
                       <button class="btn btn-outline-info">Create new Transaction Type <i class="fa fa-plus"></i></button>
                    </router-link>
                </legend>
                <div class="card-header">
                </div>
                <!-- /.card-header -->
                <div class="card-body">
                   <div class="row">
                       <div v-for="t in types" :key="t.id" class="col-lg-4 col-sm-12 col-md-3 ">
                           <div class="card">

                           <div class="card-body text-center">
                               <img :src="'/images/transaction_types/' + t.picture" alt="" 
                               style="width:64px; height:64px;">

                           </div>
            <div class="card-header">
                <h3 class="card-title">{{t.type}}</h3>

                <div class="card-tools">
                  <!-- <button type="button" class="btn btn-sm btn-info" ><i class="fas fa-eye"></i>
                  </button> -->
                  <button type="button" class="btn btn-sm btn-info" ><i class="fas fa-edit"></i>
                  </button>
                  <button type="button" class="btn btn-sm btn-danger">
                    <i class="fas fa-trash"></i></button>

                </div>
            </div>
                           </div>
                       </div>

                   </div>
                </div>
                <!-- /.card-body -->
            </fieldset>
        </div>
        <!-- /.card -->



    </div>
</template>

<script>

export default {
    name: 'TransactionTypes',
    props:{
       
    },
  components: {

  },
  data() {
    return {


        types: [],

    }
  },
  created(){
        if(this.$gate.isBroker()){
            this.getTransactionTypes()

        }
  },  
  mounted() {

  },
    computed: {

    },
    watch:{

    },
  methods:{


      getTransactionTypes(){
            this.$isLoading(true) 
          

            axios.get('api/transaction-type')
                .then(({data}) => {
                  this.types = data;
                  this.$isLoading(false) 


                }).catch((err)=>{
                  this.types = []
                  this.$isLoading(false) 

                    
            })
      }

  },

}
</script>