<template>
    <div>
        <div v-if="$gate.isBroker()" class="card  custom-card-1 m-0">
            <fieldset>
                <legend class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center">
                     <span>Cities</span>

                    </div>
                   <a href='#' class="nav-link"  data-toggle="modal" data-target="#createCityModal">
                       <button class="btn btn-outline-info">Create new City <i class="fa fa-plus"></i></button>
                    </a>
                </legend>

                <div class="card-header">
                </div>


            <div>

                <!-- <TabNav :tabs="['Approved Agencies', 'Blocked Agencies']" :selected="selected" @selected="setSelected">
                    <Tab :isSelected="selected === 'Approved Agencies' ">
                            
                    </Tab>

                    <Tab :isSelected="selected === 'Blocked Agencies' ">
                    </Tab>

                </TabNav> -->

            </div>




            <div class="card-body mt-2">
                <table  id="example2" class="table table-hover">
                    <thead >
                    <tr class="text-center">
                    <th>City Name</th>
                    <th>Number of towns</th>
                    <th>Total Agencies</th>
                    <th>Date Created</th>
                    <th class="border-left">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class='text-center' v-for='(row, index) in cities' :key='index'>
                        <td>{{row.city_name}}</td>
                        <td>{{row.number_of_towns}}
                        </td>

                        <td>{{row.total_agencies}}</td>
                        <td>{{row.time_created}}</td>
                        <td class="d-flex flex-wrap border-left justify-content-between">
                            <router-link v-bind:to="'/view-city/' + row.id"
                            active-class="active" exact class="btn mb-4 custom-button-purple1" >
                                <i class="fas fa-eye"></i>
                            </router-link>
                            <button @click="openEditModal(row.id, row.city_name)" class="btn mb-4 custom-button btn-info"> 
                                    <i class="fas fa-edit"></i>

                            </button>
                            <button v-if="row.number_of_towns == 0 && row.total_agencies == 0" 
                            @click='deleteCity(row.id)'
                            class="btn mb-4 custom-button btn-danger"> 
                                    <i class="fas fa-trash"></i>

                            </button>
                        </td>
                        


                    </tr>
                    <tfoot>
                    

                    </tfoot>
                    </tbody>
                </table>
                <div class="pt-3">
                    <pagination align="center" :data="pageData" @pagination-change-page="getResults"></pagination>

                </div>

            </div>


            </fieldset>






            <!-- Modals -->
            <div class="modal fade" id="createCityModal" tabindex="-1" role="dialog" aria-labelledby="createCityModalTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <form  @submit.prevent='createCity' method="post">
                    
                            <div class="modal-header border-0">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">

                                <div class="d-flex justify-content-center">
                                    <h2 class="swal2-title" id="exampleModalLongTitle">Create a new city</h2>
                                </div>

                                <div class="card-body">
                                    <div class="form-group">

                                        <label for="city_name" >City Name</label>
                                        <input type="text" required id='city_name' v-model="form.city_name"  placeholder="Enter city name" class="form-control ">

                                    </div>
                                </div>

                            </div>
                            <div class="modal-footer border-0 d-flex justify-content-center">
                                <button :disabled='form.city_name == ""' type="submit" class="btn custom-button-2 ">Create</button>
                            </div>
                        </form>
                    
                    </div>
                </div>
            </div>



            <div class="modal fade" id="editCityModal" tabindex="-1" role="dialog" aria-labelledby="editCityModalTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <form  @submit.prevent='editCity' method="post">
                    
                            <div class="modal-header border-0">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">

                                <div class="d-flex justify-content-center">
                                    <h2 class="swal2-title" id="exampleModalLongTitle">Edit city</h2>
                                </div>
                                <div class="card-body">
                                    <div class="form-group">

                                        <label for="city_name" >City Name</label>
                                        <input type="text" required id='city_name' v-model="edit_form.city_name"  placeholder="Enter city name" class="form-control ">

                                    </div>
                                </div>

                            </div>
                            <div class="modal-footer border-0 d-flex justify-content-center">
                                <button :disabled='edit_form.city_name == ""' type="submit" class="btn custom-button-2 ">Save Changes</button>
                            </div>
                        </form>
                    
                    </div>
                </div>
            </div>





            
        </div>
        <div v-else>
            <not-found></not-found>
            
        </div>
    </div>
</template>

<script>

export default {
    name: 'Cities',
    props:{
       
    },
  components: {

  },
  data() {
    return {
        searchBy : '',
        searchText: '',

        form: null,
        edit_form: null,
        agencies: [],
        cities: [],
        company: [],
        pageData: {}
    }
  },
  created(){
      // fetch fee table
        if(this.$gate.isBroker()){
            this.getResults()
            this.form = new Form({

            city_name: '',
            password: '',

            })
            this.edit_form = new Form({
            id: '',
            city_name: '',
            password: '',

            })
        }

  },  
    watch: {


    },
  methods:{

        getResults(page){
            if (typeof page === 'undefined') {
            page = 1;
            }

          
            this.$isLoading(true) 

            axios.get('/api/location/cities?page=' + page)
                .then(({data}) => {
                 
                  this.cities = data.data;
                  this.pageData = data
                    this.$isLoading(false) 

                }).catch((err)=>{
                  this.cities = []
                  this.pageData = {}
                    this.$isLoading(false) 
                    
            })

        },

        createCity(){
            $('#createCityModal').modal('toggle')

            const box1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button-2 mr-2',
                denyButton: 'btn custom-button bg-secondary ml-2'
            },
            buttonsStyling: false
            })

            box1.fire({
                title: 'Enter your password',
                text: 'To confirm that you want to create a city called "' + this.form.city_name + '"',
                input: 'password',
                inputAttributes: {
                    required: true,
                },
                inputValidator: (value) => {
                    if (!value) {
                    return 'You need to enter your password!'
                    }

                },
                showDenyButton: true,
                denyButtonText: `Back`,
                confirmButtonText: 'Submit',
                }).then((result) => {
                if (result.isConfirmed) {

                this.form.password = result.value

                  this.$isLoading(true) 


                this.form.post('api/location/createCity',{headers:{"Content-Type":"application/json"}})
                    .then((data) => {
                  this.$isLoading(false) 
                        
                       
                        
                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button-2',
                        
                        },
                        buttonsStyling: false
                        })

                        confirmBox2.fire({
                        icon: 'success',
                        title: 'City Created!',
                        text: '"' + this.form.city_name + '" is now added to the system',

                        }).then((res)=>{
                            // reloading the page
                        window.location.href = '/cities';

                        })



                    })
                    .catch((err) => {
                    this.$isLoading(false) 

                        // show them if that city name is taken
                        let errorText = 'City Creation Unsuccessful'

                        if(err.response.data.errors['password']){
                            errorText = err.response.data.errors['password']
                        }
                        else if(err.response.data.errors['city_name']){
                            errorText = err.response.data.errors['city_name']
                        }

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button bg-danger',
                        
                        },
                        buttonsStyling: true
                        })

                        confirmBox2.fire({
                        icon: 'error',
                        title: 'Failed!',
                        text: errorText,

                        })
                    });




                }
                else if (result.isDenied) {
                                $('#createCityModal').modal('show')

                }
                })

        },

        editCity(){
            $('#editCityModal').modal('toggle')
            let old_city_name = this.cities.filter((c)=> c.id == this.edit_form.id)[0].city_name;

            const box1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button-2 mr-2',
                denyButton: 'btn custom-button bg-secondary ml-2'
            },
            buttonsStyling: false
            })

            box1.fire({
                title: 'Enter your password',
                text: 'To confirm that you want to edit "' + old_city_name + '" to "' + this.edit_form.city_name + '"',
                input: 'password',
                inputAttributes: {
                    required: true,
                },
                inputValidator: (value) => {
                    if (!value) {
                    return 'You need to enter your password!'
                    }

                },
                showDenyButton: true,
                denyButtonText: `Back`,
                confirmButtonText: 'Submit',
                }).then((result) => {
                if (result.isConfirmed) {

                this.edit_form.password = result.value


                  this.$isLoading(true) 

                this.edit_form.post('api/location/editCity',{headers:{"Content-Type":"application/json"}})
                    .then((data) => {
                        
                    this.$isLoading(false) 
                       
                        
                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button-2',
                        
                        },
                        buttonsStyling: false
                        })

                        confirmBox2.fire({
                        icon: 'success',
                        title: 'City Updated!',
                        text: '"..' + old_city_name + '" is now updated to ' + this.edit_form.city_name,

                        }).then((res)=>{
                       
                            let i = this.cities.findIndex((c) => c.id == this.edit_form.id);
                            this.cities[i].city_name = this.edit_form.city_name
                        })



                    })
                    .catch((err) => {
                    this.$isLoading(false) 
                       
                       // show them if that city name is taken
                        let errorText = 'City Editing Unsuccessful'

                        if(err.response.data.errors['password']){
                            errorText = err.response.data.errors['password']
                        }
                        else if(err.response.data.errors['city_name']){
                            errorText = err.response.data.errors['city_name']
                        }

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button bg-danger',
                        
                        },
                        buttonsStyling: true
                        })

                        confirmBox2.fire({
                        icon: 'error',
                        title: 'Failed!',
                        text: errorText,

                        })
                    });




                }
                else if (result.isDenied) {
                                $('#editCityModal').modal('show')

                }
                })

        },

        deleteCity(id){
        
            let city_name = this.cities.filter((c)=> c.id == id)[0].city_name;
            let delete_form =  new Form({
                id: id,
                password: null
            })
            const confirmBox1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button bg-danger mr-2',
                cancelButton: 'btn custom-button-2 ml-2'
            },
            buttonsStyling: false
            })

            confirmBox1.fire({
            title: 'Are you sure?',
            text: 'You will be deleting "' + city_name + '"',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!'
            }).then((result1) => {
            if (result1.isConfirmed) {


                // prompt for pin
            const box1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button bg-danger  mr-2',
                cancelButton: 'btn custom-button-2 ml-2'
            },
            buttonsStyling: false
            })

            box1.fire({
                title: 'Enter your password',
                text: 'To confirm that you want to delete "' + city_name + '"',
                input: 'password',
                inputAttributes: {
                    required: true,
                },
                inputValidator: (value) => {
                    if (!value) {
                    return 'You need to enter your password!'
                    }

                },
                showCancelButton: true,
                confirmButtonText: 'Submit',
                showLoaderOnConfirm: true
                }).then((result) => {
                if (result.isConfirmed) {
                    delete_form.password = result.value
                  this.$isLoading(true) 

                delete_form.post('api/location/deleteCity', {headers:{"Content-Type":"application/json"}})
                    .then((data) => {
                        
                       
                  this.$isLoading(false) 
                        
                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button-2',
                        
                        },
                        buttonsStyling: false
                        })

                        confirmBox2.fire({
                        icon: 'success',
                        title: 'City deleted!',

                        }).then((res)=>{
                       
                            // let i = this.cities.findIndex((c) => c.id == this.edit_form.id);
                            // this.cities[i].city_name = this.edit_form.city_name
                        this.cities = [... this.cities.filter((c) => c.id != id)]

                        })



                    })
                    .catch((err) => {
                        this.$isLoading(false) 

                        // show them if that city name is taken
                        let errorText = 'City Deletion Unsuccessful'

                        if(err.response.data.errors['password']){
                            errorText = err.response.data.errors['password']
                        }
                        else if(err.response.data.errors['general']){
                            errorText = err.response.data.errors['general']
                        }


                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button bg-danger',
                        
                        },
                        buttonsStyling: true
                        })

                        confirmBox2.fire({
                        icon: 'error',
                        title: 'Failed!',
                        text: errorText,

                        })
                    });






                }
                })

            }
            })
        },

        openEditModal(id, city_name){
            this.edit_form.id =  id
            this.edit_form.city_name = city_name
            $('#editCityModal').modal('toggle')

        }


  },

}
</script>