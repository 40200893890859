<template>
  <div>
    <div v-if="$gate.isBroker()" class="card custom-card-1 m-0">
      <fieldset>
        <legend class="d-flex justify-content-center flex-wrap">
          <span>All Completed Deposits</span>
        </legend>

        <SearchFilter
          :data_length="deposits.length"
          :filters="filters"
          data_type="incoming"
          @search-form="makeSearch"
          :isFound="isFound"
        />
        <!-- /.card-header -->
        <div v-if="deposits.length != 0" class="card-body table-responsive">
          <table
            id="example2"
            class="table table-bordered table-hover border-top"
          >
            <thead>
              <tr class="text-center">
                <th>Agency from</th>
                <th>Customer / Merchant</th>
                <th>User Type</th>
                <th>Amount</th>
                <th>Fee Paid</th>
                <th>Time Sent</th>
                <th>Time Completed</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="text-center"
                v-for="(row, index) in deposits"
                :key="index"
              >
                <td>
                  <span>
                    {{ row.agency_from }} -
                    {{ row.agency_from_branch }}
                  </span>
                  <br />

                  <small>from: {{ row.agent_from_fullname }}</small>
                </td>

                <td>
                  <span>
                    <router-link
                      v-if="row.other_user_type === 'Customer'"
                      class="cursor"
                      :to="'/view-user-profile/' + row.other_id"
                    >
                      {{ row.other_name }}
                      <img
                        v-if="row['other_is_mrz_verified']"
                        src="/images/verified-badge.png"
                        alt="image"
                        style="width: 16px; height: 16px"
                      />
                    </router-link>
                    <router-link
                      v-else-if="row.other_user_type === 'Merchant'"
                      class="cursor"
                      :to="'/view-merchant-store/' + row.other_id"
                    >
                      {{ row.other_name }}
                    </router-link>
                  </span>
                </td>
                <td>
                  {{ row.other_user_type }}
                </td>
                <td>
                  {{ dalasisFormatter(row.amount) }}
                </td>

                <td>
                  {{ dalasisFormatter(row.fee_paid) }}
                </td>

                <td>{{ row.time_sent }}</td>
                <td>
                  {{ row.time_updated }}
                </td>
              </tr>
            </tbody>
          </table>
          <div class="pt-3">
            <pagination
              align="center"
              :data="pageData"
              @pagination-change-page="getResults"
            ></pagination>
          </div>
        </div>

        <div class="text-center" v-else>
          <span
            ><i>{{ emptyDataText }}</i></span
          >
        </div>
        <!-- /.card-body -->
      </fieldset>
    </div>
    <!-- /.card -->

    <div v-else>
      <not-found></not-found>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompletedDeposits",
  components: {
    SearchFilter: () =>
      import(
        "../../../includes/SearchFilter.vue" /* webpackChunkName: "js/search-filter" */
      ),
  },
  data() {
    return {
      isFound: false,
      searchForm: null,
      deposits: [],
      pageData: {},
      filters: [
        {
          id: 1,
          text: "Agency From",
          value: "agency_from",
        },
        {
          id: 2,
          text: "Amount",
          value: "amount",
        },
        {
          id: 3,
          text: "Fee Paid",
          value: "fee_paid",
        },
      ],
      sort_by: {
        column: "Time Sent",
        order: "descending",
      },
      sort_column_names: [
        "Amount",
        "Fee Paid",
        "Sender",
        "Receiver",
        "Time Sent",
      ],
      emptyDataText: "",
    };
  },
  created() {
    // fetch fee table
    if (this.$gate.isBroker()) {
      this.getResults();
    }
  },
  methods: {
    getResults(page) {
      if (typeof page === "undefined") {
        page = 1;
      }
      this.emptyDataText = "";
      if (this.searchForm == null || this.searchForm.search_text == "") {
        this.$isLoading(true);

        axios
          .get("api/broker/deposit/allCompleted?page=" + page)
          .then(({ data }) => {
            if (data.data.length != 0) {
              this.deposits = data.data;
              this.pageData = data;
            } else {
              this.deposits = [];
              this.pageData = {};
              this.emptyDataText = "You have no outgoing transactions";
            }

            this.$isLoading(false);
          })
          .catch((err) => {
            this.$isLoading(false);
          });
      } else {
        this.$isLoading(true);
        this.emptyDataText = "";

        this.searchForm
          .post("api/deposit/makeSearch?page=", {
            headers: { "Content-Type": "application/json" },
          })
          .then(({ data }) => {
            this.isFound = true;

            this.deposits = data.data;
            this.pageData = data;
            this.emptyDataText = "No search results found...";

            this.$isLoading(false);
          })
          .catch((err) => {
            this.deposits = [];
            this.pageData = {};
            this.emptyDataText = "Something went wrong!";

            this.$isLoading(false);
          });
      }
    },

    makeSearch(data) {
      this.searchForm = data;
      this.getResults();
      // emit up
    },
  },
};
</script>
