<template>
    <div v-if="$gate.isAgent() || $gate.isAgentAdmin()">


        <div class="">
            <form v-if="$gate.canTransact() && $gate.isRegistrationComplete()" @submit.prevent='sendFunds' class="form-horizontal" method='post'>


                <div class="card w-100 custom-card-1 m-0 bg-white">
                <fieldset>
                    <legend class="d-flex justify-content-between flex-wrap">
                        <span>Money Transfer</span>

                        <div v-if="this.userInfo.kashma_balance == null">
                        <span v-if="$gate.isAgentAdmin()"  class="text-bgreen">Broker Balance:
                            <span class="text-color-2 font-weight-bold">{{broker_balance}}</span>
                        </span> <br>
                        </div>

                        <div v-else>
                        <span v-if="$gate.isAgentAdmin()"  class="text-bgreen">Cash At Bank:
                            <span class="text-color-2 font-weight-bold">{{"D "+this.userInfo.cash_at_bank}}</span>
                        </span> <br>
                        </div>

                    </legend>
                <div class="card-header">
                    <div v-if="$gate.isAgentAdmin()">
                        <label v-show="userInfo.admin_branches.length > 1" for="">Select Branch</label>
                        <select v-if="$gate.isAgentAdmin()" v-model="branch" required class="form-control" name="" id="">
                            <option v-for="branch in userInfo.admin_branches" :key="branch.id"
                            :value='branch.id' >
                                {{branch.town_name}} - Branch
                            </option>
                        </select>
                    </div>

                </div>
                    <div class="card-body">

                        <div class="row">

                            <!-- 1 -->
                            <div class="col-12">
                                <div class="form-group border-bottom py-4">
                                    <h4 class="card-header bg-white">
                                        <label for='amount'> How much?</label>

                                    </h4>

                                    <div class="input-group mb-2">
                                        <div class="input-group-prepend">
                                        <span class="input-group-text font-weight-bold text-navy bg-olive">D</span>
                                        </div>
                                        <input type="number" id='amount' v-model="amount"  placeholder="Enter amount in dalasis" class="form-control">
                                    </div>

                                    <div class=" font-weight-bold">
                                        <span :class="[noerrors.amount == false ? 'text-danger' : 'text-success']" >
                                            {{messages.fee}}
                                        </span>
                                    </div>

                                </div>


                                <!-- tell them the fee -->
                            </div>

                            <!-- 2 -->
                            <div class="col-12">
                                <div class="form-group border-bottom  py-4">
                                    <h4 class="card-header bg-white">
                                        <label for='location'> To where?</label>

                                    </h4>

                                    <div>
                                        <Select2 v-model="location" :options="locations.towns" />

                                    </div>
                                    <!-- <select name="" id="location" required v-model="location" class="form-control">
                                        <option v-for="row in locations.towns" :key="row.id" :value="row.id" >
                                               {{row.town_name}} ({{row.city_name}})
                                        </option>
                                    </select>
                                                                 -->


                                    <div v-if="noerrors.amount && noerrors.location" class="pt-3 pl-3">
                                        <div v-if="townAgents.length > 0" >
                                            <i class="text-success" style="font-size:1.2rem;" >Available Agencies in <b>{{locations.towns.filter((t)=> t.id == location)[0]['town_name']}}</b></i>

                                            <div v-for="row in townAgents" :key="row.user_id" class="form-check px-5 pb-2">
                                            <label class="form-check-label" :for="'radio1' + row.user_id">
                                                <input type="radio" class="form-check-input" :id="'radio' + row.user_id" v-model="receiving_branch" :value="row.branch_id" checked>
                                                    <span style="font-size:1.2rem;">{{row.company_name}}</span>
                                            </label>
                                            </div>

                                        </div>
                                        <div v-else-if="townAgents.length == 0 && cityAgents.length != 0">
                                            <i class="text-bgreen" style="font-size:1.2rem;" >There are no available agencies in <b>{{locations.towns.filter((t)=> t.id == location)[0]['town_name']}}</b>
                                            but here are some in the <b>{{locations.towns.filter((t)=> t.id == location)[0]['city_name']}}</b> area </i>

                                            <div v-for="row in cityAgents" :key="row.user_id" class="form-check d-flex justify-content-between px-5 pb-2">
                                            <label class="form-check-label" :for="'radio1' + row.user_id">
                                                <input type="radio" class="form-check-input" :id="'radio' + row.user_id" v-model="receiving_branch" :value="row.branch_id" checked>
                                                    <span style="font-size:1.2rem;">{{row.company_name}}</span>
                                            </label>
                                                    <span class="">{{row.town_name}}</span>

                                            </div>

                                        </div>
                                        <div class="text-center" v-else-if="no_agents && townAgents.length == 0 && cityAgents.length == 0">
                                            <i class="text-danger" style="font-size:1.2rem;">There are no available agents in <b>{{locations.towns.filter((t)=> t.id == location)[0]['town_name']}}</b> or
                                            <b>{{locations.towns.filter((t)=> t.id == location)[0]['city_name']}}</b>
                                            </i>

                                        </div>


                                    </div>

                                </div>

                            </div>

                            <!-- 3 -->
                            <div class="col-12">
                                <div class="form-group py-4" style="border-bottom:1px solid #ccc;">
                                    <h4 class="card-header bg-white">
                                        <label> Sender details</label>

                                    </h4>

                                    <div class="form-group row px-md-5">

                                        <div class="col-12 col-md-6">
                                            <label for="senderName" class=" col-form-label text-md-right">Name</label>

                                            <input id="senderName" type="text" class="form-control " v-model="senderName"
                                            placeholder="Enter Sender's name"
                                            >
                                        </div>
                                        <div class="col-12 col-md-6">
                                            <label for="senderPhoneNumber" class=" col-form-label text-md-right">Phone Number</label>

                                            <input id="senderPhoneNumber" type="number" class="form-control " v-model="senderPhoneNumber"
                                            placeholder="Enter Sender's phone number"
                                            >
                                        </div>
                                        <div class="col-12 col-md-6">
                                            <label for="senderIDCardNo" class=" col-form-label text-md-right">ID Card No</label>

                                            <input id="senderIDCardNo" type="number" class="form-control " v-model="senderIDCardNo"
                                            required
                                            placeholder="Enter Sender's ID Card No"
                                            >
                                        </div>
                                        <div class="col-12 col-md-6">

                                        </div>
                                    </div>

                                </div>

                                <div class="form-group py-4" style="border-bottom:1px solid #ccc;">
                                    <h4 class="card-header bg-white">
                                        <label for='sender'> Receiver details</label>

                                    </h4>

                                    <div class="form-group row px-md-5">

                                        <div class="col-12 col-md-6">
                                            <label for="receiverName" class=" col-form-label text-md-right">Name</label>

                                            <input id="receiverName" type="text" class="form-control " v-model="receiverName"
                                            placeholder="Enter Receiver's Name"
                                            >
                                        </div>
                                        <div class="col-12 col-md-6">
                                            <label for="receiverPhone" class=" col-form-label text-md-right">Phone Number</label>

                                            <input id="receiverPhone" type="number" class="form-control " v-model="receiverPhoneNumber"
                                            placeholder="Enter Receiver's phone number"
                                            >
                                        </div>
                                        <div class="col-12 col-md-6">
                                            <label for="receiverIDCardNo" class=" col-form-label text-md-right">ID Card No</label>

                                            <input id="receiverIDCardNo" type="number" class="form-control " v-model="receiverIDCardNo"
                                            required
                                            placeholder="Enter Receiver's ID Card No"
                                            >
                                        </div>
                                        <div class="col-12 col-md-6">

                                        </div>
                                    </div>

                                </div>

                            </div>


                            <div class="col-12 d-flex justify-content-center">
                                <button :disabled='errorCheck == true' type="submit" class="btn custom-button-1">
                                    SEND
                                </button>
                            </div>


                        </div>

                    </div>



                </fieldset>

                </div>

            </form>

            <div class="card custom-card-1" v-else>
                <legend class="d-flex font-weight-bold text-danger justify-content-center flex-wrap">
                    <span>Ops! You can't make transactions</span>
                </legend>
                <div class="card-header">
                </div>
            </div>

        </div>


        <AgencyFooter :getUserProfile='getUserProfile' />

    </div>
    <div v-else>
            <not-found></not-found>

    </div>
</template>

<script>
import Select2 from 'v-select2-component';

export default {
  name: 'NewTransaction',
  components: {
   Select2
  },
  data() {
    return {
        userInfo: {
            admin_branches:[]
        },
        broker_balance: '',
        branch: null,

        amount: '',
        location: '',
        senderName: '',
        receiverName: '',
        senderPhoneNumber: '',
        receiverPhoneNumber: '',
        senderIDCardNo:'',
        receiverIDCardNo: '',
        receiving_branch:'',
        pin: '',
        brokerBalanceNegativeLimit: '',


        messages:{
            'fee': ''
        },

        noerrors: {
            'amount': false,
            'location': false,
            'senderName': false,
            'receiverName': false,

        },

        feeTable:[],
        locations: [],
        townAgents: [],
        cityAgents: [],
        fee: 0,
        no_agents: false

    }
  },
  created(){
      // fetch fee table
        this.$isLoading(true)

            axios.get('api/feetable')
                .then(({data}) => {
                  this.feeTable = data;
                    this.$isLoading(false)

                }).catch((err)=>{

                    this.$isLoading(false)

                })
    // fetch available locations (will use coordinate system one day) or user addresses
  },
  watch:{
      amount: function (){

        this.noerrors.amount = false

          if(this.amount.length >= 1){

            if(this.amount >= this.feeTable[0].range_from &&
            this.amount <= this.feeTable[(this.feeTable.length - 1)].range_to){
                // get the fees from the fee table
                this.messages.fee = ""

                this.feeTable.forEach(element => {
                   if(this.userInfo.kashma_balance == null){
                    if(this.amount > (this.userInfo.broker_balance + Math.abs(this.brokerBalanceNegativeLimit))){
                        this.noerrors.amount = false;
                        this.messages.fee = "You don't have enough broker balance to send";

                    }
                    else if(this.amount >= element.range_from && this.amount <= element.range_to){
                        this.messages.fee = "The transaction fee is D" + element.fee;
                        this.fee = element.fee
                        if(this.location != ''){
                            // send request to find agents in that town and agents in other towns of that city
                            this.lookForAgents()

                        }
                    this.noerrors.amount = true

                    }
                   } else{

                     if(this.amount >= element.range_from && this.amount <= element.range_to){
                            this.messages.fee = "The transaction fee is D" + element.fee;
                            this.fee = element.fee
                            if(this.location != ''){
                                // send request to find agents in that town and agents in other towns of that city
                                this.lookForAgents()

                            }
                        this.noerrors.amount = true

                        }
                   }
                });


            }
            else if(this.amount < this.feeTable[0].range_from){
                this.messages.fee = "You can't send an amount less than D" + this.feeTable[0].range_from
            }

            else if(this.amount > this.feeTable[(this.feeTable.length - 1)].range_to){
                this.messages.fee = "You can't send an amount greater than D" + this.feeTable[(this.feeTable.length - 1)].range_to

            }

          }
          else{
                this.messages.fee = ""

          }
      },

      location: function (){
            if(this.noerrors.amount == true && this.location != ''){
                // send request to find agents in that town and agents in other towns of that city
                this.lookForAgents()
                this.noerrors.location = true;

            }
      },
      senderName: function (){
          if(this.senderName.length >= 3 && this.senderName.length <= 100){
              this.noerrors.senderName = true
          }
          else{
              this.noerrors.senderName = false;

          }
      },
      receiverName: function (){
          if(this.receiverName.length >= 3 && this.receiverName.length <= 100){
              this.noerrors.receiverName = true
          }
          else{
              this.noerrors.receiverName = false;
          }
      },

      branch: function(){
          this.amount = ''
          this.location = ''
          this.senderName = ''
          this.senderPhoneNumber = ''
          this.receiverPhoneNumber = ''
          this.receiverName = ''
      },


        getUserProfile: function () {
            this.userInfo = this.getUserProfile

            if(this.$gate.isAgentAdmin()){
                this.branch = this.userInfo.admin_branches[0].id

            }

            else if(this.$gate.isAgent()){
                this.branch = this.userInfo.branch_id
            }

            this.broker_balance = this.dalasisFormatter(this.userInfo.broker_balance);

             axios.get('api/broker/considerations/'+this.getUserProfile.agency)
                .then(({data}) => {
                    this.brokerBalanceNegativeLimit = data.agencyLimit[0].negative_limit;
                  console.log("negative limit", this.brokerBalanceNegativeLimit)

                }).catch((err)=>{

            })
        },
        getLocations: function () {
            this.locations = this.getLocations

        },
  },

  mounted() {
        this.$store.dispatch("userProfile")
        this.$store.dispatch("locations")


  },
    computed: {

        errorCheck(){
            return this.eChecks()
        },

        getUserProfile(){ //final output from here
            return this.$store.getters.getUserProfile
        },
        getLocations(){ //final output from here
            return this.$store.getters.getLocations
        },

    },
  methods: {
      sendFunds(){
        //  run fields error checks method

            if(this.eChecks() || this.receiving_branch.length == 0)
                return

            const confirmBox1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button-2 mr-2',
                cancelButton: 'btn custom-button bg-danger ml-2'
            },
            buttonsStyling: false
            })

            confirmBox1.fire({
            title: 'Are you sure?',
            text: "You'll be sending D" + this.amount + " to " + this.receiverName
             + '. They\'ll receive D' + (this.amount - this.fee),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, send it!'
            }).then((result1) => {
            if (result1.isConfirmed) {


                // prompt for pin
            const box1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button-2 mr-2',
                cancelButton: 'btn custom-button bg-danger ml-2'
            },
            buttonsStyling: false
            })

            box1.fire({
                title: 'Enter your pin',
                input: 'password',
                inputAttributes: {
                    maxlength: 4,
                    minlength: 4,
                    pattern: "[0-9]+",
                    required: true,
                },
                inputValidator: (value) => {
                    if (!value) {
                    return 'You need to enter your 4 digit pin!'
                    }

                },
                showCancelButton: true,
                confirmButtonText: 'Submit',
                showLoaderOnConfirm: true
                }).then((result) => {
                if (result.isConfirmed) {

                let formData = new FormData();
                formData.append('amount', this.amount);
                formData.append('sending_branch', this.branch);
                formData.append('location', this.location);
                formData.append('senderName', this.senderName);
                formData.append('receiverName', this.receiverName)
                formData.append('senderPhoneNumber', this.senderPhoneNumber);
                formData.append('receiverPhoneNumber', this.receiverPhoneNumber);
                formData.append('senderIDCardNo', this.senderIDCardNo)
                formData.append('receiverIDCardNo', this.receiverIDCardNo)

                formData.append('receiving_branch', this.receiving_branch);

                formData.append('pin', result.value);


                this.$isLoading(true)

                axios.post('api/transaction',formData,{headers:{"Content-Type":"application/json"}})
                    .then((data) => {
                        console.log("data", data);
                  this.$isLoading(false)
                        let html_text = ''
                        if(this.$gate.isAgentAdmin() && this.userInfo.kashma_balance == null)
                            html_text ='<span>Transfer successful! Your new broker balance is D' + (this.userInfo.broker_balance - this.amount) + '</span>'
                            + '<br><h3>Token: <b>' + data.data.Transfer_Token + '</b></h3>' ;
                        else
                            html_text ='<span>Transfer successful!</span>'
                            + '<br><h3>Token: <b>' + data.data.Transfer_Token + '</b></h3>' ;


                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button-2',

                        },
                        buttonsStyling: false
                        })

                        confirmBox2.fire({
                        icon: 'success',
                        title: 'Sent!',
                        text: this.userInfo.kashma_balance === null ? 'Transfer successful! Your new broker balance is D' + (this.userInfo.broker_balance - this.amount) : "Transfer successful",
                        html: html_text

                        }).then((res)=>{
                            // reloading the page
                        window.location.href = '/outgoing-transactions';

                        })



                    })
                    .catch((err) => {
                        this.$isLoading(false)

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button bg-danger',

                        },
                        buttonsStyling: true
                        })

                        confirmBox2.fire({
                        icon: 'error',
                        title: 'Failed!',
                        text: err.response.data.errors,

                        })
                    });




                }
                })

            }
            })
      },
      eChecks(){

          if(this.noerrors.amount && this.noerrors.location && this.noerrors.senderName && this.noerrors.receiverName)
            return false;

        return true
      },

      lookForAgents(){
          let temp_form = new Form({
              'town_id' : this.location,
              'amount' : this.amount,
              'sending_branch': this.branch
          });
            temp_form.post('api/transaction/availableAgents')
                .then(({data}) => {
                    console.log("available agents ", data)
                //   this.companies = data;
                this.no_agents = false

                this.receiving_branch = ''
                if(data.town_agents.length == 0 && data.town_agents.length == 0)
                    this.no_agents = true

                this.townAgents = data.town_agents
                this.cityAgents = data.city_agents



                }).catch((err)=>{


                })
      },
  }
}
</script>


<style scoped>


</style>
