<template>
    <div v-if="$gate.isBroker()">

        <div class="card  custom-card-1 m-0">
            <fieldset>
                <legend class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center">
                     <span> Distributor  Profile ( {{userInfo.username}} )</span>

                    </div>
                   <a href="#" class="nav-link" active-class="active" onclick="history.go(-1);return false;">
                       <button class="btn bg-nyanza font-weight-bold text-gray" >Go back</button>
                    </a>
                </legend>


                    <div class="card-header">
                    </div>


            <div>

                <TabNav :tabs="tabs" :selected="selected" @selected="setSelected">
                    <Tab :isSelected="selected === 'General' ">

                        <div class="row mt-5">
                            <div class="col-12">
                                    <div class="d-flex justify-content-between flex-wrap border-bottom pb-3">
                                        <div class="d-flex align-items-center">
                                            <h5>General</h5>
                                        </div>
                                        <div class="d-flex justify-content-end flex-wrap">
                                            <a href='#' class="nav-link" data-toggle="modal" data-target="#editDistributorModal">
                                                <button class="btn btn-outline-info">Edit <i class="fa fa-edit"></i></button>
                                            </a>
                                            <a href='#' class="nav-link" data-toggle="modal" data-target="#depositModal">
                                                <button class="btn btn-outline-bgreen">Deposit Broker Balance <i class="fa fa-plus"></i></button>
                                            </a>

                                             <a href='#' class="nav-link" data-toggle="modal" data-target="#withdrawModal">
                                                <button class="btn btn-outline-bgreen">Withdraw Broker Balance <i class="fa fa-plus"></i></button>
                                            </a>

                                            <!-- set the negative broker balance limit -->
                                            <a href='#' class="nav-link" data-toggle="modal" data-target="#negativeBrokerBalanceLimit">
                                                <button class="btn btn-outline-bgreen">Negative Broker Balance Limit <i class="fa fa-plus"></i></button>
                                            </a>
                                        </div>

                                    </div>

                                </div>

                            <div class="col-8 pr-5">
                                <div class="border-bottom font-weight-bold py-3">Profile</div>

                                <div class="row py-3 border-bottom">
                                    <div class="col-6 ">
                                        Institution Name
                                    </div>
                                    <div class="col">
                                        {{userInfo.institution_name}}
                                    </div>
                                </div>
                                <div class="row py-3 border-bottom">
                                    <div class="col-6 ">
                                        Username
                                    </div>
                                    <div class="col">
                                        {{userInfo.username}}
                                    </div>
                                </div>
                                <div class="row py-3 border-bottom">
                                    <div class="col-6 ">
                                        Broker Balance
                                    </div>
                                    <div class="col">
                                        {{dalasisFormatter(userInfo.broker_balance)}}
                                    </div>
                                </div>
                                <div class="row py-3 border-bottom">
                                    <div class="col-6 ">
                                        Cash on Hand
                                    </div>
                                    <div class="col">
                                        {{dalasisFormatter(userInfo.cash_on_hand)}}
                                    </div>
                                </div>
                                <div class="row py-3 border-bottom">
                                        <div class="col-6 ">
                                           Total Campigns Fee cut
                                        </div>
                                        <div class="col-6">
                                            {{dalasisFormatter(userInfo.total_campaign_fee)}}
                                        </div>
                                    </div>
                                <!-- <div class="row py-3 border-bottom">
                                        <div class="col-6 ">
                                           Transaction Fee cut
                                        </div>
                                        <div class="col-6">
                                            {{userInfo.fee_cut_percent}}%
                                        </div>
                                    </div>
                                    <div class="row py-3 border-bottom">
                                        <div class="col-6 ">
                                           Withdraw Fee cut
                                        </div>
                                        <div class="col-6">
                                            {{userInfo.withdraw_fee_cut_percent}}%
                                        </div>
                                    </div> -->
                                    <div class="row py-3 border-bottom">
                                        <div class="col-6 ">
                                            Negative Balance Limit
                                        </div>
                                        <div class="col-6">
                                            {{brokerBalanceNegativeLimit}}
                                            <!-- {{companyInfo.time_created}} -->
                                        </div>
                                    </div>


                                <div class="row py-3 border-bottom">
                                    <div class="col-6 ">
                                        Email
                                    </div>
                                    <div class="col">
                                        {{userInfo.email}}
                                    </div>
                                </div>

                                <div class="row py-3 border-bottom">
                                    <div class="col-6 ">
                                        Telephone number
                                    </div>
                                    <div class="col">
                                        {{userInfo.telephone}}
                                    </div>
                                </div>

                                <div class="row py-3 border-bottom">
                                    <div class="col-6 ">
                                        Address
                                    </div>
                                    <div class="col">
                                        {{userInfo.town_name}}
                                    </div>
                                </div>



                                <div class="row py-3 border-bottom">
                                    <div class="col-6 ">
                                        Pin
                                    </div>
                                    <div class="col">
                                       {{userInfo.pin}}
                                    </div>
                                </div>


                            </div>

                            <div class="col-4">
                                <div class="border-bottom font-weight-bold py-3 mb-3">Avatar</div>

                                <div class="image">
                                    <img class="img-fluid pad" src="/images/profiles/profile-none.png" alt="">
                                </div>
                                <!-- <div v-if="canEdit" class="btn btn-info w-100">
                                    Change profile picture
                                </div> -->
                            </div>
                        </div>
                    </Tab>

                    <Tab :isSelected="selected === 'Primary Contact' ">
                        <!--  -->
                        <div class="row mt-5">
                            <div class="col-12">
                                <div class="border-bottom pb-3"><h5>Primary Contact</h5></div>

                            </div>
                            <div class="col-8 pr-5">
                                <div class="border-bottom font-weight-bold py-3">Profile</div>


                                <div class="row py-3 border-bottom">
                                    <div class="col-6 ">
                                        Name
                                    </div>
                                    <div class="col">
                                        {{userInfo.contact_name}}
                                    </div>
                                </div>

                                <div class="row py-3 border-bottom">
                                    <div class="col-6 ">
                                        Position
                                    </div>
                                    <div class="col">
                                        {{userInfo.contact_position}}
                                    </div>
                                </div>
                                <div class="row py-3 border-bottom">
                                    <div class="col-6 ">
                                        Email
                                    </div>
                                    <div class="col">
                                        {{userInfo.contact_email}}
                                    </div>
                                </div>

                                <div class="row py-3 border-bottom">
                                    <div class="col-6 ">
                                        Telephone number
                                    </div>
                                    <div class="col">
                                        {{userInfo.contact_telephone}}
                                    </div>
                                </div>

                                <div class="row py-3 border-bottom">
                                    <div class="col-6 ">
                                        Address
                                    </div>
                                    <div class="col">
                                        {{userInfo.Tadd}}
                                    </div>
                                </div>



                                <div class="row py-3 border-bottom">
                                    <div class="col-6 ">
                                        Company registration information
                                    </div>
                                    <div class="col">
                                       {{userInfo.company_registration_info}}
                                    </div>
                                </div>


                            </div>

                            <div class="col-4">
                                <div class="border-bottom font-weight-bold py-3 mb-3">Avatar</div>

                                <div class="image">
                                    <img class="img-fluid pad" src="/images/profiles/profile-none.png" alt="">
                                </div>
                                <!-- <div v-if="canEdit" class="btn btn-info w-100">
                                    Change profile picture
                                </div> -->
                            </div>
                        </div>
                    </Tab>


                    <Tab :isSelected="selected === 'Campaigns' ">
                            <DistributorCampaigns :user_id='userInfo.user_id' />
                        </Tab>
                    <Tab :isSelected="selected === 'Transactions' ">
                            <AgencyTransactions :distributor_id='userInfo.id' />
                        </Tab>
                        <Tab :isSelected="selected === 'Deposits' ">
                            <AgencyDeposits :distributor_id='userInfo.id' />
                        </Tab>
                        <Tab :isSelected="selected === 'Withdrawals' ">
                            <AgencyWithdrawals :distributor_id='userInfo.id' />
                        </Tab>




                </TabNav>

            </div>



            </fieldset>








        </div>

        <div class="modal fade" id="editDistributorModal" tabindex="-1" role="dialog" aria-labelledby="editDistributorModalTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <form @submit.prevent='editDistributor' method="post">

                            <div class="modal-header border-0">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="d-flex justify-content-center">
                                    <h2 class="swal2-title" id="exampleModalLongTitle">Edit Distributor</h2>
                                </div>
                                    <form  @submit.prevent='editDistributor'  class="form-horizontal">
                                        <div class="card-body">
                                        <div class="form-group">
                                            <div class="text-danger" v-if="edit_distributor_form.errors.has('company_name')" v-html="edit_distributor_form.errors.get('company_name')" />

                                            <label for="inputEmail3" >Institution Name</label>
                                            <input type="text" class="form-control" id="inputEmail3" v-model="edit_distributor_form.institution_name">

                                        </div>
                                        <div class="form-group">
                                            <div class="pt-3  text-danger" v-if="edit_distributor_form.errors.has('fee_cut_percent')" v-html="edit_distributor_form.errors.get('fee_cut_percent')" />

                                            <label for="inputCut" >Transaction fee cut percent</label>
                                            <input type="number" id="inputCut" v-model="edit_distributor_form.fee_cut_percent"
                                            maxlength="70" placeholder="Enter fee cut percent" class="form-control">
                                        </div>
                                        <div class="form-group">
                                            <div class="pt-3  text-danger" v-if="edit_distributor_form.errors.has('withdraw_fee_cut_percent')" v-html="edit_distributor_form.errors.get('withdraw_fee_cut_percent')" />

                                            <label for="inputCut" >Withdraw fee cut percent</label>
                                            <input type="number" id="inputCut" v-model="edit_distributor_form.withdraw_fee_cut_percent"
                                            maxlength="70" placeholder="Enter withdraw fee cut percent" class="form-control">
                                        </div>

                                        </div>
                                        <!-- /.card-body -->

                                    </form>







                            </div>
                            <div class="modal-footer border-0 d-flex justify-content-center">
                                <button type="submit" class="btn custom-button-2 ">Save Changes</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>


            <div class="modal fade" id="depositModal" tabindex="-1" role="dialog" aria-labelledby="depositModalTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <form @submit.prevent='deposit' method="post">

                            <div class="modal-header border-0">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="d-flex justify-content-center">
                                    <h2 class="swal2-title" id="exampleModalLongTitle">Deposit Broker Balance</h2>
                                </div>
                                        <div class="card-body">
                                            <div>
                                                Current Broker Balance: {{dalasisFormatter(userInfo.broker_balance)}}
                                            </div>

                                            <div class="form-group py-2 mb-0">
                                            <div class="text-danger" v-if="deposit_form.errors.has('amount')" v-html="deposit_form.errors.get('amount')" />

                                                    <label for="amount" >How much</label>

                                            </div>
                                            <div class="input-group mb-2">

                                                <div class="input-group-prepend">
                                                <span class="input-group-text font-weight-bold text-navy bg-olive">D</span>
                                                </div>
                                                <input type="number" required id='amount' v-model="deposit_form.amount"  placeholder="Enter amount in dalasis" class="form-control ">

                                            </div>


                                        </div>

                            </div>
                            <div class="modal-footer border-0 d-flex justify-content-center">
                                <button :disabled='deposit_form.busy' type="submit" class="btn custom-button-2 ">Deposit</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>

               <div class="modal fade" id="withdrawModal" tabindex="-1" role="dialog" aria-labelledby="withdrawModalTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <form @submit.prevent='withdraw' method="post">

                            <div class="modal-header border-0">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="d-flex justify-content-center">
                                    <h2 class="swal2-title" id="exampleModalLongTitle">Withdraw Broker Balance</h2>
                                </div>
                                        <div class="card-body">
                                            <div>
                                                Current Broker Balance: {{dalasisFormatter(userInfo.broker_balance)}}
                                            </div>

                                            <div class="form-group py-2 mb-0">
                                            <div class="text-danger" v-if="withdraw_form.errors.has('amount')" v-html="withdraw_form.errors.get('amount')" />

                                                    <label for="amount" >How much</label>

                                            </div>
                                            <div class="input-group mb-2">

                                                <div class="input-group-prepend">
                                                <span class="input-group-text font-weight-bold text-navy bg-olive">D</span>
                                                </div>
                                                <input type="number" required id='amount' v-model="withdraw_form.amount"  placeholder="Enter amount in dalasis" class="form-control ">

                                            </div>


                                        </div>

                            </div>
                            <div class="modal-footer border-0 d-flex justify-content-center">
                                <button :disabled='withdraw_form.busy' type="submit" class="btn custom-button-2 ">Withdraw</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>

            <div class="modal fade" id="negativeBrokerBalanceLimit" tabindex="-1" role="dialog" aria-labelledby="depositModalTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <form @submit.prevent='submitNegativeLimit' method="post">

                            <div class="modal-header border-0">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="d-flex justify-content-center">
                                    <h2 class="swal2-title" id="exampleModalLongTitle">Negative Broker Balance Limit</h2>
                                </div>
                                        <div class="card-body">

                                            <div class="form-group py-2 mb-0">
                                                <label for="amount" >How much</label>
                                            </div>
                                            <div class="input-group mb-2">

                                                <div class="input-group-prepend">
                                                <span class="input-group-text font-weight-bold text-navy bg-olive">D</span>
                                                </div>
                                                <input type="number" required id='amount' v-model="negative_limit_form.negative_limit"  placeholder="Enter negative amount" class="form-control ">

                                            </div>


                                        </div>

                            </div>
                            <div class="modal-footer border-0 d-flex justify-content-center">
                                <button :disabled="this.negative_limit_form.negative_limit > 0" type="submit" class="btn custom-button-2 ">Set</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
    </div>
    <div v-else-if="!$gate.isBroker()">
        <not-found></not-found>

    </div>

</template>


<script>



import TabNav from '../../includes/tabNav.vue'
import Tab from '../../includes/tabs.vue'

export default {
    name: 'ViewDistributor',
    props:{

    },
  components: {

        TabNav,
        Tab,
        DistributorCampaigns: () => import('./DistributorCampaigns' /* webpackChunkName: "js/agency-transactions" */),
        AgencyTransactions: () => import('./DistributorTransactions' /* webpackChunkName: "js/agency-transactions" */),
        AgencyDeposits: () => import('./DistributorDeposits' /* webpackChunkName: "js/agency-deposits" */),
        AgencyWithdrawals: () => import('./DistributorWithdrawals' /* webpackChunkName: "js/agency-withdrawals" */),
        // CustomerTransfers
  },
  data() {
    return {
        brokerBalanceNegativeLimit: '',
        selected: 'General',
        tabs: ['General','Primary Contact','Campaigns', 'Deposits'],
        logs: [],
        pageData: {},
        userInfo: {
            rank: ''
        },

        edit_distributor_form: new Form({
            distributor_id: 0,
            institution_name: '',
            fee_cut_percent: 0,
            withdraw_cut_percent: 0

        }),
        deposit_form : new Form({
            amount: 0
        }),

        withdraw_form : new Form({
            amount: 0
        }),
        negative_limit_form : new Form({
            negative_limit: 0,
        })

    }
  },
  created(){
      // fetch fee table


    let temp_form = new Form({
            id : this.$route.params.id
        })

        temp_form.post('/api/broker/getDistributor')
            .then(({data}) => {


                ////////////////////  to display the negative limit to the view agencies page ////////////////

                    axios.get('/api/broker/considerationsDistributor/'+data.Cid )
                    .then(({data}) => {
                    this.brokerBalanceNegativeLimit = data.agencyLimit[0].negative_limit
                    //   console.log("negative limit", this.brokerBalanceNegativeLimit)

                    }).catch((err)=>{

                    })

                ////////////////////////////////// ends here ////////////////////////

                this.negative_limit_form.distributor_id = data.Cid;

                this.userInfo = data
                this.edit_distributor_form = new Form({
                    distributor_id: data.Cid,
                    institution_name: data.institution_name,
                    fee_cut_percent: data.fee_cut_percent,
                    withdraw_fee_cut_percent: data.withdraw_fee_cut_percent,

                    password: ''})

            this.$isLoading(false)

            }).catch((err)=>{
                this.userInfo = 'error'
            this.$isLoading(false)

        });


  },
    watch: {
        selected: function(){



        },

    },
  methods:{
        setSelected(tab){
            this.selected = tab;

        },




        editDistributor(){
            $('#editDistributorModal').modal('toggle')

            const confirmBox1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button bg-danger mr-2',
                cancelButton: 'btn custom-button-2 ml-2'
            },
            buttonsStyling: false
            })

            confirmBox1.fire({
            title: 'Are you sure?',
            text: 'Your changes will be saved and the distributor will be affected',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, save the changes!'
            }).then((result1) => {
            if (result1.isConfirmed) {


                // prompt for pin
            const box1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button bg-danger  mr-2',
                cancelButton: 'btn custom-button-2 ml-2'
            },
            buttonsStyling: false
            })

            box1.fire({
                title: 'Enter your password',
                text: 'To confirm your changes',
                input: 'password',
                inputAttributes: {
                    required: true,
                },
                inputValidator: (value) => {
                    if (!value) {
                    return 'You need to enter your password!'
                    }

                },
                showCancelButton: true,
                confirmButtonText: 'Submit',
                showLoaderOnConfirm: true
                }).then((result) => {
                if (result.isConfirmed) {
                    this.edit_distributor_form.password = result.value
                  this.$isLoading(true)

                this.edit_distributor_form.post('/api/broker/editDistributor', {headers:{"Content-Type":"application/json"}})
                    .then((data) => {


                  this.$isLoading(false)

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button-2',

                        },
                        buttonsStyling: false
                        })

                        confirmBox2.fire({
                        icon: 'success',
                        title: 'Distributor changes saved!',

                        }).then((res)=>{

                         window.location.reload()


                        })



                    })
                    .catch((err) => {
                        this.$isLoading(false)

                        // show them if that city name is taken
                        let errorText = 'Distributor changes Unsuccessful'

                        if(err.response.data.errors['password']){
                            errorText = err.response.data.errors['password']
                                                    const confirmBox2 = Swal.mixin({
                                            customClass: {
                                                confirmButton: 'btn custom-button bg-danger',

                                            },
                                            buttonsStyling: true
                                            })

                                            confirmBox2.fire({
                                            icon: 'error',
                                            title: 'Failed!',
                                            text: errorText,

                                            })
                        }
                        else{
                            $('#editDistributorModal').modal('toggle')

                        }



                    });






                }
                })

            }
            })
        },

        deposit(){
            if(this.deposit_form.amount <= 0)
                return
            $('#depositModal').modal('toggle')

            const confirmBox1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button bg-danger mr-2',
                cancelButton: 'btn custom-button-2 ml-2'
            },
            buttonsStyling: false
            })

            confirmBox1.fire({
            title: 'Are you sure you want to deposit ' + this.dalasisFormatter(this.deposit_form.amount) + '?',
            text: this.userInfo.institution_name + ' will have a total broker balance of ' +
             this.dalasisFormatter(parseFloat(this.deposit_form.amount) + parseFloat(this.userInfo.broker_balance)),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, save the changes!'
            }).then((result1) => {
            if (result1.isConfirmed) {


                // prompt for pin
            const box1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button bg-danger  mr-2',
                cancelButton: 'btn custom-button-2 ml-2'
            },
            buttonsStyling: false
            })

            box1.fire({
                title: 'Enter your password',
                text: 'To confirm your want to deposit ' + this.dalasisFormatter(this.deposit_form.amount) + ' for this distributor',
                input: 'password',
                inputAttributes: {
                    required: true,
                },
                inputValidator: (value) => {
                    if (!value) {
                    return 'You need to enter your password!'
                    }

                },
                showCancelButton: true,
                confirmButtonText: 'Submit',
                showLoaderOnConfirm: true
                }).then((result) => {
                if (result.isConfirmed) {
                    this.deposit_form.password = result.value
                    this.deposit_form.distributor_id = this.userInfo.Cid

                  this.$isLoading(true)

                this.deposit_form.post('/api/broker/depositForDistributor', {headers:{"Content-Type":"application/json"}})
                    .then((data) => {


                  this.$isLoading(false)

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button-2',

                        },
                        buttonsStyling: false
                        })

                        confirmBox2.fire({
                        icon: 'success',
                        title: 'Deposit Successful!',

                        }).then((res)=>{
                        this.deposit_form.password = null
                         window.location.reload()


                        })



                    })
                    .catch((err) => {
                        this.$isLoading(false)

                        // show them if that city name is taken
                        let errorText = 'Deposit Unsuccessful'


                        if(err.response.data.errors['password']){
                            errorText = err.response.data.errors['password']
                                                    const confirmBox2 = Swal.mixin({
                                            customClass: {
                                                confirmButton: 'btn custom-button bg-danger',

                                            },
                                            buttonsStyling: true
                                            })

                                            confirmBox2.fire({
                                            icon: 'error',
                                            title: 'Failed!',
                                            text: errorText,

                                            })
                        }
                        else{
                            $('#depositModal').modal('toggle')

                        }
                    });






                }
                })

            }
            })
        },

         withdraw(){
            if(this.withdraw_form.amount <= 0)
                return
            $('#withdrawModal').modal('toggle')

            const confirmBox1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button bg-danger mr-2',
                cancelButton: 'btn custom-button-2 ml-2'
            },
            buttonsStyling: false
            })

            confirmBox1.fire({
            title: 'Are you sure you want to withdraw ' + this.dalasisFormatter(this.withdraw_form.amount) + '?',
            text: this.userInfo.institution_name + ' will have a total broker balance of ' +
             this.dalasisFormatter(parseFloat(this.userInfo.broker_balance) - parseFloat(this.withdraw_form.amount) ),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, save the changes!'
            }).then((result1) => {
            if (result1.isConfirmed) {

                // prompt for pin
            const box1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button bg-danger  mr-2',
                cancelButton: 'btn custom-button-2 ml-2'
            },
            buttonsStyling: false
            })

            box1.fire({
                title: 'Enter your password',
                text: 'To confirm your want to withdraw ' + this.dalasisFormatter(this.withdraw_form.amount) + ' for this distributor',
                input: 'password',
                inputAttributes: {
                    required: true,
                },
                inputValidator: (value) => {
                    if (!value) {
                    return 'You need to enter your password!'
                    }

                },
                showCancelButton: true,
                confirmButtonText: 'Submit',
                showLoaderOnConfirm: true
                }).then((result) => {
                if (result.isConfirmed) {
                    console.log("good", this.withdraw_form);
                    this.withdraw_form.password = result.value
                    this.withdraw_form.distributor_id = this.userInfo.Cid

                  this.$isLoading(true)

                this.withdraw_form.post('/api/broker/withdrawForDistributor', {headers:{"Content-Type":"application/json"}})
                    .then((data) => {


                  this.$isLoading(false)

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button-2',

                        },
                        buttonsStyling: false
                        })

                        confirmBox2.fire({
                        icon: 'success',
                        title: 'Withdraw Successful!',

                        }).then((res)=>{
                        this.withdraw_form.password = null
                         window.location.reload()


                        })



                    })
                    .catch((err) => {
                        this.$isLoading(false)

                        // show them if that city name is taken
                        let errorText = 'Withdraw Unsuccessful'


                        if(err.response.data.errors['password']){
                            errorText = err.response.data.errors['password']
                                                    const confirmBox2 = Swal.mixin({
                                            customClass: {
                                                confirmButton: 'btn custom-button bg-danger',

                                            },
                                            buttonsStyling: true
                                            })

                                            confirmBox2.fire({
                                            icon: 'error',
                                            title: 'Failed!',
                                            text: errorText,

                                            })
                        }
                        else{
                            $('#withdrawModal').modal('toggle')

                        }
                    });






                }
                })

            }
            })
        },

         submitNegativeLimit(){

            $('#negativeBrokerBalanceLimit').modal('toggle')

            const confirmBox1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button bg-danger mr-2',
                cancelButton: 'btn custom-button-2 ml-2'
            },
            buttonsStyling: false
            })

            confirmBox1.fire({
            title: 'Are you sure you want to add negative limit?',
            text: "negative broker balance will be activated",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, save the changes!'
            }).then((result1) => {
            if (result1.isConfirmed) {


                // prompt for pin
            const box1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button bg-danger  mr-2',
                cancelButton: 'btn custom-button-2 ml-2'
            },
            buttonsStyling: false
            })

            box1.fire({
                title: 'Enter your password',
                text: 'To confirm your want to activate negative broker balance for this agency',
                input: 'password',
                inputAttributes: {
                    required: true,
                },
                inputValidator: (value) => {
                    if (!value) {
                    return 'You need to enter your password!'
                    }

                },
                showCancelButton: true,
                confirmButtonText: 'Submit',
                showLoaderOnConfirm: true
                }).then((result) => {
                if (result.isConfirmed) {
                    this.negative_limit_form.password = result.value
                    // this.deposit_form.company_id = this.userInfo.id

                  this.$isLoading(true)

                this.negative_limit_form.post('/api/broker/negativeBrokerBalanceForDistributor', {headers:{"Content-Type":"application/json"}})
                    .then((data) => {


                  this.$isLoading(false)

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button-2',

                        },
                        buttonsStyling: false
                        })

                        confirmBox2.fire({
                        icon: 'success',
                        title: 'Changes Added Successfully!',

                        }).then((res)=>{
                        this.deposit_form.password = null
                         window.location.reload()


                        })



                    })
                    .catch((err) => {
                        this.$isLoading(false)

                        // show them if that city name is taken
                        let errorText = 'Negative broker balance Unsuccessful'


                        if(err.response.data.errors['password']){
                            errorText = err.response.data.errors['password']
                                                    const confirmBox2 = Swal.mixin({
                                            customClass: {
                                                confirmButton: 'btn custom-button bg-danger',

                                            },
                                            buttonsStyling: true
                                            })

                                            confirmBox2.fire({
                                            icon: 'error',
                                            title: 'Failed!',
                                            text: errorText,

                                            })
                        }
                        else{
                            $('#negativeBrokerBalanceLimit').modal('toggle')

                        }
                    });






                }
                })

            }
            })
        },


  },

}
</script>
