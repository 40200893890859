<template>
    <div v-if="$gate.isBroker()">



        <div class="card  custom-card-1 m-0">
          <form class="form-horizontal" action="" @submit.prevent='createCollector' method="post">

            <fieldset>
                <legend class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center">
                     <span>Create Cash Collector</span>

                    </div>
                   <router-link to="/broker-cash-Collectors" class="nav-link" active-class="active" exact>
                       <button class="btn bg-nyanza font-weight-bold text-gray">Go back</button>
                    </router-link>
                </legend>
                    <div class="card-header">
                    </div>


                    <div class="card-body">

                        <div class="row">
                            <!-- errors -->
                            <div class="col-6">
                                <div class="text-danger" v-if="form.errors.has('username')" v-html="form.errors.get('username')" />
                            </div>
                            <div class="col-6">
                                    <div class="text-danger" v-if="form.errors.has('institution_name')" v-html="form.errors.get('institution_name')" />
                            </div>

                            <!-- enderrors -->

                            <div class="col-6">
                                <div class="form-group pb-4">
                                    <label for="">Username <span class="text-danger">*</span> </label>

                                    <input type="text" v-model="form.username" required maxlength="70" placeholder="Enter Username" class="form-control"><br>


                                </div>

                            </div>
                            <div class="col-6">
                                <div class="form-group pb-4">

                                    <label for="">Institution Name <span class="text-danger">*</span>
                                    </label>
                                    <input type="text" v-model="form.institution_name" required name="institution_name"
                                     maxlength="70" placeholder="Enter Institution Name" class="form-control"><br>


                                </div>

                            </div>

                          <!-- errors -->
                          <div class="col-6">
                                <div class="text-danger" v-if="form.errors.has('email')" v-html="form.errors.get('email')" />
                            </div>
                            <div class="col-6">
                                    <div class="text-danger" v-if="form.errors.has('telephone')" v-html="form.errors.get('telephone')" />
                            </div>

                            <!-- enderrors -->


                            <div class="col-6">
                                <div class="form-group py-4">
                                    <label for="">Email <span class="text-danger">*</span> </label>

                                    <input type="text" v-model="form.email" placeholder="Enter Email" class="form-control"><br>


                                </div>

                            </div>


                            <div class="col-6">
                                <div class="form-group py-4">

                                    <label for="">Telephone number <span class="text-danger">*</span> </label>
                                    <input type="number" v-model="form.telephone" required maxlength="7"  placeholder="Enter Telephone number" class="form-control"><br>

                                </div>

                            </div>




                            <!-- errors -->
                            <div class="col-12">
                                    <div class="text-danger" v-if="form.errors.has('address')" v-html="form.errors.get('address')" />
                            </div>

                            <!-- enderrors -->


                            <div class="col-12">
                                <div class="form-group py-4">
                                    <label for="">Address <span class="text-danger">*</span> </label>

                                        <Select2 required v-model="form.address" :options="locations.towns" />

                                </div>

                            </div>



                            <!-- errors -->
                          <div class="col-6">
                                <div class="text-danger" v-if="form.errors.has('password')" v-html="form.errors.get('password')" />
                            </div>
                            <div class="col-6">
                                    <div class="text-danger" v-if="form.errors.has('password_confirmation')" v-html="form.errors.get('password_confirmation')" />
                            </div>

                            <!-- enderrors -->


                            <div class="col-6">
                                <div class="form-group py-4">
                                    <label for="">Password <span class="text-danger">*</span> </label>

                                    <input type="password" v-model="form.password" placeholder="Enter Password" class="form-control"><br>


                                </div>

                            </div>


                            <div class="col-6">
                                <div class="form-group py-4">

                                    <label class="text-center">Repeat password</label>

                                        <input type="password" v-model="form.password_confirmation" class="form-control" required
                                        placeholder="Repeat ...">
                                        </div>
                                </div>


                            <div class="col-12">
                                <h5 class="font-weight-bold text-emerald">Primary Contact</h5>

                            </div>


                              <!-- errors -->
                          <div class="col-6">
                                <div class="text-danger" v-if="form.errors.has('contact_name')" v-html="form.errors.get('contact_name')" />
                            </div>
                            <div class="col-6">
                                    <div class="text-danger" v-if="form.errors.has('contact_position')" v-html="form.errors.get('contact_position')" />
                            </div>

                            <!-- enderrors -->


                            <div class="col-6">
                                <div class="form-group py-4">
                                    <label for="">Name <span class="text-danger">*</span> </label>

                                    <input type="text" v-model="form.contact_name" placeholder="Enter Name" class="form-control"><br>


                                </div>

                            </div>


                            <div class="col-6">
                                <div class="form-group py-4">

                                    <label for="">Position <span class="text-danger">*</span> </label>
                                    <input type="text" v-model="form.contact_position" required maxlength="50"  placeholder="Enter Position" class="form-control"><br>

                                </div>

                            </div>



                                   <!-- errors -->
                          <div class="col-6">
                                <div class="text-danger" v-if="form.errors.has('contact_email')" v-html="form.errors.get('contact_email')" />
                            </div>
                            <div class="col-6">
                                    <div class="text-danger" v-if="form.errors.has('contact_telephone')" v-html="form.errors.get('contact_telephone')" />
                            </div>

                            <!-- enderrors -->


                            <div class="col-6">
                                <div class="form-group py-4">
                                    <label for="">Email <span class="text-danger">*</span> </label>

                                    <input type="text" v-model="form.contact_email" placeholder="Enter Email" class="form-control"><br>


                                </div>

                            </div>


                            <div class="col-6">
                                <div class="form-group py-4">

                                    <label for="">Telephone number <span class="text-danger">*</span> </label>
                                    <input type="number" v-model="form.contact_telephone" required maxlength="7"  placeholder="Enter Telephone number" class="form-control"><br>

                                </div>

                            </div>




                        <!-- errors -->
                        <div class="col-6">
                                    <div class="text-danger" v-if="form.errors.has('address')" v-html="form.errors.get('address')" />
                            </div>


                          <div class="col-6">
                                <div class="text-danger" v-if="form.errors.has('company_registration_info')" v-html="form.errors.get('contact_email')" />
                            </div>
                        <!-- enderrors -->

                        <div class="col-6">
                                <div class="form-group py-4">
                                    <label for="">Address <span class="text-danger">*</span> </label>

                                        <Select2 required v-model="form.contact_address" :options="locations.towns" />

                                </div>

                            </div>
                        <div class="col-6">
                                <div class="form-group py-4">

                                    <label for=""> Company registration information <span class="text-danger">*</span> </label>

                                    <textarea v-model="form.company_registration_info" required   placeholder="Enter  Company registration information" class="form-control"></textarea>
                                </div>

                            </div>


                            <div class="col-12 mt-3 d-flex justify-content-center">
                                <button type="submit" :disabled="form.busy" class="btn custom-button-1">
                                    SUBMIT
                                </button>
                            </div>


                        </div>

                    </div>




            </fieldset>

          </form>







        </div>

    </div>
    <div v-else>
        <not-found></not-found>
    </div>
</template>


<script>
import Select2 from 'v-select2-component';


export default {
    name: 'CreateCollector',
    props:{

    },
  components: {
    Select2
  },
  data() {
    return {
        form: new Form({


            username: '',
            institution_name: '',
            telephone: '',
            email: '',
            contact_name: '',
            contact_email: '',
            contact_position: '',
            password: '',
            password_confirmation: '',
            contact_telephone: '',
            contact_address: '',
            company_registration_info: '',
        }),

        branches: [],


        locations: []
    }
  },
  created(){



  },
  mounted() {
        this.$store.dispatch("locations")


  },
    computed: {

        getLocations(){ //final output from here
            return this.$store.getters.getLocations
        },

    },
    watch:{
            getLocations: function () {
                this.locations = this.getLocations

            },

    },
  methods:{

      createCollector(){

        if(!this.$gate.isBroker())
            return;

            const box1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button-2 mr-2',
                denyButton: 'btn custom-button bg-secondary ml-2'
            },
            buttonsStyling: false
            })

            box1.fire({
                title: 'Enter your password',
                text: 'To confirm that you want to create this Cash Collector',
                input: 'password',
                inputAttributes: {
                    required: true,

                },
                inputValidator: (value) => {
                    if (!value) {
                    return 'You need to enter your password!'
                    }

                },
                showDenyButton: true,
                denyButtonText: `Back`,
                confirmButtonText: 'Submit',
                }).then((result) => {
                if (result.isConfirmed) {

                this.form.admin_password = result.value


                  this.$isLoading(true)

                    this.form.post('api/broker/createCollector',{headers:{"Content-Type":"application/json"}})
                        .then((data) => {
                        this.$isLoading(false)

                            const confirmBox2 = Swal.mixin({
                            customClass: {
                                confirmButton: 'btn custom-button-2',

                            },
                            buttonsStyling: false
                            })


                            confirmBox2.fire({
                            icon: 'success',
                            title: 'Registered as Cash Collector!',
                            text: 'Cash Collector registration successful! They can log in and set their pin'

                            }).then((res)=>{
                                // redirect user to dashboard
                            window.location.href = '/broker-cash-Collectors';

                            })



                        })
                        .catch((err) => {
                        this.$isLoading(false)

                        let errorText = 'Cash Collector Creation Unsuccessful'

                        if(err.response.data.errors['mypassword']){
                            errorText = err.response.data.errors['mypassword']
                        }
                        else if(err.response.data.errors['general']){
                            errorText = err.response.data.errors['general']
                        }


                            const confirmBox2 = Swal.mixin({
                            customClass: {
                                confirmButton: 'btn custom-button bg-danger',

                            },
                            buttonsStyling: true
                            })

                            confirmBox2.fire({
                            icon: 'error',
                            title: 'Failed!',
                            text: errorText,

                            })

                        });


                }
                })



      }

  },

}
</script>
