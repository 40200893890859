<template>
    <div v-if="$gate.isStore() || $gate.isCustomer()" >
            <legend class="text-center">
                <div class="">
                    <span>Nawec Meters</span>
                </div>

            </legend>
        <div class="card  custom-card-1 m-0">
            <fieldset>
<!-- Coming Soon Feature -->
                <div class="text-right">
                   <a href='#' class="nav-link" data-toggle="modal" data-target="#addMeterModal" >
                       <button class="btn btn-outline-info ">Add new meter <i class="fa fa-plus"></i></button>
                    </a>
                </div>


            <div class="table-responsive card-body mt-2">
                <table  id="example2" class="table table-hover">
                    <thead >
                    <tr class="text-center">
                    <th>Meter No</th>
                    <th>Meter Owner</th>
                    <th>Date Created</th>
                    <th class="border-left">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class='text-center' v-for='(row, index) in meters' :key='index'>
                        <td>{{row.meter_no}}
                        </td>

                        <td>{{row.meter_owner}}</td>
                        <td>{{row.date_created}}</td>
                        <td class="d-flex flex-wrap border-left justify-content-between">
                            <button @click="deleteMeter(row.id, row.user_id)" class="btn mb-4 btn-sm  btn-danger">
                                    Delete Meter

                            </button>
                            <button
                            data-toggle="modal" @click="buyPower(row)" data-target="#buyCashPowerModal"
                            class="btn mb-4  btn-sm text-white bg-ogreen1">
                                   Buy Cash Power
                            </button>

                            <!-- <router-link to="/nawec-transactions"> -->
                                <button @click="nawecTranansactions(row)"
                                class="btn mb-4  btn-sm text-white bg-primary">
                                    Logs
                                </button>
                            <!-- </router-link> -->
                        </td>



                    </tr>
                    <tfoot>


                    </tfoot>
                    </tbody>
                </table>
                <div class="pt-3">
                    <pagination align="center" :data="pageData" @pagination-change-page="getResults"></pagination>

                </div>

            </div>


            </fieldset>






            <!-- Modals -->
            <div class="modal fade" id="addMeterModal" tabindex="-1" role="dialog" aria-labelledby="addMeterModalTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <form  @submit.prevent='addMeter' method="post">

                            <div class="modal-header border-0">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">

                                <div class="d-flex justify-content-center">
                                    <h2 class="swal2-title" id="exampleModalLongTitle">Add Meter</h2>
                                </div>
                                <div class="card-body">
                                    <div class="form-group">

                                        <label for="meter_no" >Meter no</label>
                                        <input type="text" required id='meter_no' v-model="add_meter_no"  placeholder="Enter meter no" class="form-control ">

                                    </div>
                                </div>

                            </div>
                            <div class="modal-footer border-0 d-flex justify-content-center">
                                <button  type="submit"
                                class="btn custom-button-2 ">Save</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>

             <!-- Buy Cash power meter -->
            <div  class="modal fade" id="buyCashPowerModal" tabindex="-1" role="dialog" aria-labelledby="addMeterModalTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <form  @submit.prevent='buyCashPower' method="post">

                            <div class="modal-header border-0">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">

                                <div class="d-flex justify-content-center">
                                    <h2 class="swal2-title" id="exampleModalLongTitle">Buy CashPower</h2>
                                </div>
                                <div class="card-body">
                                    <div class="form-group">

                                        <label for="meter_no">Amount</label>
                                        <input type="text" required id='meter_no' v-model="amount"  placeholder="Enter amount here" class="form-control ">

                                    </div>
                                </div>

                                <div class="form-group py-0 my-0">
                                    <label v-if="amount_error.length > 0"
                                        class="text-danger">
                                        {{amount_error}}
                                    </label>
                                    <label v-else-if="amount_fee.length > 0" for="" class="text-success">
                                        {{amount_fee}}
                                    </label>
                                </div>

                            </div>
                            <div class="modal-footer border-0 d-flex justify-content-center">
                                <button
                                :disabled='!noErrors'
                                type="submit" class="btn custom-button-2 ">Save</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>



            <Send :getUserProfile='getUserProfile' />
            <Footer :getUserProfile='getUserProfile' />

        </div>

    </div>
    <div v-else>
        <not-found></not-found>

    </div>

</template>


<script>
import $ from 'jquery'
export default {
  name: 'NawecMeters',
  components: {

  },
  data() {
    return {
        balance: 0,
        add_meter_no: '',
        meters: [],
        pageData: {},
        cashPowerFeeTable: [],
        amount: '',
        amount_error: '',
        amount_fee: '',
        fee: 0,
        // values to buy the cashpower
        meter_no: '',
        cus_phone: '',
        name: '',
        email: '',
        id: '',
        startDate: '',
        endDate: '',
    }
  },

  created(){
    if(this.$gate.isCustomer()){
            this.getResults()

            axios.get('/api/nawec/cash-power-fees')
                .then(({data}) => {
                  this.cashPowerFeeTable = data.fees;
                //   console.log(this.cashPowerFeeTable[0].range_from);

                    this.$isLoading(false)

                }).catch((err)=>{

                    this.$isLoading(false)

                })

                axios.get('/api/nawec/free-cashpower-durations')
                .then((res) => {
                    this.startDate = res.data.durations[0].start_date;
                    this.endDate = res.data.durations[0].end_date;
                }).catch((err) => {
                    console.log(err);
                })
        }
  },

  methods:{
    buyPower(row){
        this.cus_phone = row.phone_number;
        this.name = row.name;
        this.email = row.email;
        this.id = row.user_id;
        this.meter_no = row.meter_no;
        this.phone = row.phone_number; // this should be the phone to send the tokent
        // console.log('row', row);
    },

      addMeter(){
          if(this.add_meter_no.length === 0)
          return
            this.$isLoading(true)

            let add_form =  new FormData();
            add_form.append('meter_no', this.add_meter_no)


        //   make an api call to check for meter details
            axios.post('api/nawec/confirmMeter',add_form,{headers:{"Content-Type":"application/json"}})
                .then(({data}) => {
                    // console.log("data" +data)
                    this.$isLoading(false)

                    const confirmBox2 = Swal.mixin({
                    customClass: {
                        confirmButton: 'btn custom-button-2',

                    },
                    buttonsStyling: false
                    })


                    confirmBox2.fire({
                    icon: 'success',
                    title: 'Meter Registered!',
                    text: 'Meter registration successful'

                    }).then((res)=>{
                        // redirect user to dashboard

                    window.location.href = '/nawec-meters';

                    })


                }).catch((err)=>{
                    this.$isLoading(false)
                    // console.log("error ", err.response.data.errors)
                    let errorText = 'Meter Registration Unsuccessful'

                        errorText = err.response.data.errors


                    const confirmBox2 = Swal.mixin({
                    customClass: {
                        confirmButton: 'btn custom-button bg-danger',

                    },
                    buttonsStyling: true
                    })

                    confirmBox2.fire({
                    icon: 'error',
                    title: 'Failed!',
                    text: errorText,

                    })

                })

      },

      getResults(page){
            if (typeof page === 'undefined') {
            page = 1;
            }

            this.$isLoading(true)


            axios.get('api/nawec/savedMeters?page=' + page)
                .then(({data}) => {
                //   console.log("meters", data.message.data)
                  this.meters = data.message.data;
                  this.pageData = data
                    this.$isLoading(false)


                }).catch((err)=>{
                  this.meters = []
                  this.pageData = {}
                this.$isLoading(false)


            })

        },

        deleteMeter(id, userId){

            const confirmBox1 = Swal.mixin({
            customClass: {
                cancelButton: 'btn custom-button-2 mx-2',
                confirmButton: 'btn custom-button bg-danger mx-2'
            },
            buttonsStyling: false
            })

            confirmBox1.fire({
            title: 'Are you sure?',
            text: "You'll be deleting the Meter ",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete this meter!'
            }).then((result1) => {
            if (result1.isConfirmed) {



            const box1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button-2 mx-2',
                denyButton: 'btn custom-button bg-secondary mx-2'
            },
            buttonsStyling: false
            })

            box1.fire({
                title: 'Enter your pin',
                input: 'password',
                inputAttributes: {
                    maxlength: 4,
                    minlength: 4,
                    pattern: "[0-9]+",
                    required: true,
                },
                inputValidator: (value) => {
                    if (!value) {
                    return 'You need to enter your 4 digit pin!'
                    }

                },
                showDenyButton: true,
                denyButtonText: `Back`,
                confirmButtonText: 'Submit',
                }).then((result) => {
                if (result.isConfirmed) {

                this.$isLoading(true)
                let del_form =  new FormData();
                del_form.append('id', id)
                del_form.append('user_id', userId)
                del_form.append('pin', result.value)

                axios.post('api/nawec/deleteMeter', del_form,{headers:{"Content-Type":"application/json"}})
                    .then((data) => {
                        this.$isLoading(false)

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button-2',

                        },
                        buttonsStyling: false
                        })

                        confirmBox2.fire({
                        icon: 'success',
                        title: 'Deleted!',
                        text: 'Meter Deleted! ... '  ,

                        }).then((res)=>{
                            // reloading the page
                        // window.location.href = '/agents';
                        this.getResults()
                        })



                    })
                    .catch((err) => {
                        this.$isLoading(false)

                        // come back later
                        let errorText = typeof err.response.data.errors['pin'] !== 'undefined' ? err.response.data.errors['pin'][0] : '';

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button bg-danger',

                        },
                        buttonsStyling: true
                        })

                        confirmBox2.fire({
                        icon: 'error',
                        title: 'Failed!',
                        text: errorText, // take care later

                        })
                    });


                }
                else if (result.isDenied) {

                }
                })
            }
            })
            },

            //// buy cashpower
            buyCashPower(){

            const confirmBox1 = Swal.mixin({
            customClass: {
                cancelButton: 'btn custom-button bg-danger mx-2',
                confirmButton: 'btn custom-button-2 mx-2'
            },
            buttonsStyling: false
            })

            confirmBox1.fire({
            title: 'Are you sure?',
            html: 'You will be purchasing a cashpower for ' + this.dalasisFormatter(this.amount) + '<b>'
             + '</b>. You\'ll get <b>' + this.dalasisFormatter(this.amount - this.fee) +"</b>",

             icon: 'warning',
             showCancelButton: true,
             confirmButtonText: 'Yes, purchase it!'
            }).then((result1) => {
                if (result1.isConfirmed) {
                // close please
                $('#buyCashPowerModal').modal('hide')
                // prompt for pin
            const box1 = Swal.mixin({
            customClass: {
                cancelButton: 'btn custom-button bg-danger  mx-2',
                confirmButton: 'btn custom-button-2 mx-2'
            },
            buttonsStyling: false
            })

            box1.fire({
                title: 'Enter your pin',
                text: 'To confirm that you want to withdraw ' + this.dalasisFormatter(this.amount) + " from  ",
                input: 'password',
                inputAttributes: {
                    required: true,
                },
                inputValidator: (value) => {
                    if (!value) {
                    return 'You need to enter your pin!'
                    }

                },
                showCancelButton: true,
                confirmButtonText: 'Submit',
                showLoaderOnConfirm: true
                }).then((result) => {
                if (result.isConfirmed) {
                this.$isLoading(true)

                let formData = new FormData();

                // formData['pin'] = result.value;
                // formData['amount'] =  this.amount;
                // formData['meterNo'] = this.meter_no;
                // formData['name'] = this.name;
                // formData['phone'] =  this.phone;
                // formData['cus_phone'] = this.phone;
                // formData['email'] =  this.email;
                formData.append('pin', result.value);
                formData.append('amount', this.amount);
                formData.append('meter_no', this.meter_no);
                formData.append('name', this.name);
                formData.append('phone_number', this.phone);
                formData.append('cus_phone', this.phone);
                formData.append('email', this.email);
                formData.append('user_id', this.id);


                axios.post('/api/nawec/purchase-cash-power', formData, {headers:{"Content-Type":"application/json"}})
                    .then((res) => {
                        // console.log(res.data.data.nawec.token)

                  this.$isLoading(false)

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button-2',

                        },
                        buttonsStyling: false
                        })

                        confirmBox2.fire({
                        icon: 'success',
                        title: 'Purchase Successful!',
                        html: '<span>Your Cashpower token is: </span>'
                            + '<br><h3>Token: <b>' + res.data.data.nawec.token + '</b></h3>'
                            +'<h3>Units: <b>' + res.data.data.nawec.units + '</b></h3>'


                        }).then((res)=>{

                        // ask them to pick the transaction type. Give em option to exit/not choose.

                        window.location.href = '/nawec-meters'


                        })



                    })
                    .catch((err) => {
                        // console.log("error", err);
                        this.$isLoading(false)

                        // show them if that city name is taken
                        let errorText = 'Transaction Unsuccessful'

                        if(err.response.data.errors['pin']){
                            errorText = err.response.data.errors['pin']
                        }
                        else if(err.response.data.errors['transaction']){
                            errorText = err.response.data.errors['transaction']
                        }


                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button bg-danger',

                        },
                        buttonsStyling: true
                        })

                        confirmBox2.fire({
                        icon: 'error',
                        title: 'Failed!',
                        text: errorText,

                        })
                    });

                }
                })

            } else{
                // window.location.href = '/nawec-meters';
            }
            })

      },

      eChecks(){
        if(this.amount <= this.getUserProfile.balance
          && this.amount >= 40 && this.amount_error == "") // the 40 is hard-coded, i will remove it
          return true

        return false
      },

        nawecTranansactions(data){
            this.$router.push({
            name: "nawectTransactionsLogs", //use name for router push
            params: { data }
        });
        },
         },

         watch:{
        getUserProfile: function () {

            if(this.$gate.isStore()){
                document.getElementById('bladeCompanyName').innerHTML = this.getUserProfile.company_name

            }
            document.getElementById('bladeAmount').innerHTML =  this.dalasisFormatter(this.getUserProfile.balance)


        },

        amount: function(){
            // console.log("amount", this.amount);
        // this.noerrors.amount = false
            this.amount_fee = ""
            this.amount_error = ""

          if(this.amount.length >= 1){

            if(this.amount >= this.cashPowerFeeTable[0].range_from &&
            this.amount <= this.cashPowerFeeTable[(this.cashPowerFeeTable.length - 1)].range_to){
                // get the fees from the fee table
                this.amount_fee = ""

                this.cashPowerFeeTable.forEach(element => {

                     const startDate = new Date(this.startDate);
                     const endDate = new Date(this.endDate);
                    const today = new Date();
                    if(today >= startDate && today <= endDate){
                        // console.log("start and end date ", startDate, endDate);
                        this.amount_fee = "The transaction fee is free today";
                        this.fee = 0;
                    }
                    if (this.amount >= element.range_from && this.amount <= element.range_to){
                        // console.log("start and end date ", startDate.toISOString().split['T'], endDate.toISOString().split['T']);
                        this.amount_fee = "The transaction fee is D" + element.fee;
                        this.fee = element.fee

                    }
                    else if(this.amount > this.getUserProfile.balance){
                        this.amount_fee = '';
                        this.fee = 0
                        this.amount_error = "You can't purchase more than your current balance";

                    }
                });

            }
            else if(this.amount > this.getUserProfile.balance){

                this.amount_error = "You can't purchase more than your current balance"
                // this.town_agencies = []
                // this.city_agencies = []
            }
            else if(this.amount < this.cashPowerFeeTable[0].range_from){
                this.amount_error = "You can't purchase an amount less than D" + this.cashPowerFeeTable[0].range_from
                // this.town_agencies = []
                // this.city_agencies = []
            }

            else if(this.amount > this.cashPowerFeeTable[(this.cashPowerFeeTable.length - 1)].range_to){
                this.amount_error = "You can't purchase an amount greater than D" + this.cashPowerFeeTable[(this.cashPowerFeeTable.length - 1)].range_to
                // this.town_agencies = []
                // this.city_agencies = []
            }
            else{
                this.amount_error = ""

            }
          }
          else{
                this.amount_error = ""

          }


        }
  },

  mounted() {
        this.$store.dispatch("userProfile")
  },
    computed: {

        getUserProfile(){ //final output from here
            return this.$store.getters.getUserProfile
        },

        noErrors(){
            return this.eChecks()
        },



    },
}
</script>

