export default {
    state: {
        // user
        userProfileData: [],

        // locations
        locationsData: [],

        // agent admin chart 1
        agentAdminChart1Data: [],

        // agent chart 1
        agentChart1Data: [],

        // broker chart 1
        brokerChart1Data: [],

        // distributor chart 1
        distributorChart1Data: [],

        // kashlock chart 1
        kashlockChart1Data: [],

         // investment chart 1
         investmentChart1Data: [],

        cashPowerSalesChartData: [],

        airTimeSalesChartData: [],
    },

    getters: {
        // User-----------------------------------
        getUserProfile(state) {
            return state.userProfileData;
        },

        // Location----------------------------
        getLocations(state) {
            return state.locationsData;
        },

        // agent admin chart 1-----------------------------
        getAgentAdminChart1(state) {
            return state.agentAdminChart1Data;
        },

        // broker chart 1---------------------------------
        getBrokerChart1(state) {
            return state.brokerChart1Data;
        },

        getCashPowerSalesData(state) {
            return state.cashPowerSalesChartData;
        },

        getAirtimeSalesData(state) {
            return state.airTimeSalesChartData;
        },

        // agent chart 1-----------------------------
        getAgentChart1(state) {
            return state.agentChart1Data;
        },
        // distributor chart 1-----------------------------
        getDistributorChart1(state) {
            return state.distributorChart1Data;
        },

        // kashlock chart 1---------------------------------
        getKashlockChart1(state) {
            return state.kashlockChart1Data;
        },

        // investment chart 1---------------------------------
        getInvestmentChart1(state) {
            return state.investmentChart1Data;
        },
    },

    actions: {
        // User -----------------------------------------------
        userProfile(context) {
            axios
                .get("/api/profile")

                .then(response => {
                    //    console.log(response.data)
                    context.commit("userProfileMutate", response.data[0]); //categories will be run from mutation
                })

                .catch(() => {
                    console.log("Error........");
                });
        },

        // Locations ---------------------------------
        locations(context) {
            axios
                .get("/api/location")

                .then(response => {
                    context.commit("locationsMutate", response.data); //categories will be run from mutation
                })

                .catch(() => {
                    console.log("Error........");
                });
        },

        // dashboard-------------------------------

        // agent-admin chart 1
        agentAdminChart1(context) {
            axios
                .get("api/agent-admin-dashboard/chart1")

                .then(response => {
                    context.commit("agentAdminChart1Mutate", response.data); //categories will be run from mutation
                })

                .catch(() => {
                    console.log("Error........");
                });
        },

        // agent chart 1
        agentChart1(context) {
            axios
                .get("api/agent-dashboard/chart1")

                .then(response => {
                    context.commit("agentChart1Mutate", response.data); //categories will be run from mutation
                })

                .catch(() => {
                    console.log("Error........");
                });
        },

        // distributor chart 1
        distributorChart1Data(context) {
            axios
                .get("api/distributor-dashboard/chart1")

                .then(response => {
                    console.log(response.data);
                    context.commit("distributorChart1Mutate", response.data); //categories will be run from mutation
                })

                .catch(() => {
                    console.log("Error........");
                });
        },

         // kashlock chart 1
         kashlockChart1Data(context) {
            axios
                .get("api/kashlock-dashboard/chart1")

                .then(response => {
                    console.log(response.data);
                    context.commit("kashlockChart1Mutate", response.data); //categories will be run from mutation
                })

                .catch(() => {
                    console.log("Error........");
                });
        },

          // kashlock chart 1
          investmentChart1Data(context) {
            axios
                .get("api/investment-dashboard/chart1")

                .then(response => {
                    console.log(response.data);
                    context.commit("investmentChart1Mutate", response.data); //categories will be run from mutation
                })

                .catch(() => {
                    console.log("Error........");
                });
        },
        // broker chart 1
        async brokerChart1({ commit }) {
            try {
                const [chart1Response, cashPowerSalesResponse, airtimeSalesResponse] = await Promise.all([
                    axios.get("api/broker-dashboard/chart1"),
                    axios.get("api/broker-dashboard/cashPowerSalesChart"),
                    axios.get("api/broker-dashboard/airTimeSalesChart"),
                ]);

                // Commit mutations to update the state with the fetched data
                commit("brokerChart1Mutate", chart1Response.data);
                commit("brokerCashPowerSalesMutate", cashPowerSalesResponse.data);
                commit("brokerAirtimeSalesMutate", airtimeSalesResponse.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },


    },

    mutations: {
        // User --------------------------
        userProfileMutate(state, data) {
            return (state.userProfileData = data);
        },

        // Locations --------------------------
        locationsMutate(state, data) {
            return (state.locationsData = data);
        },

        // agent-admin
        agentAdminChart1Mutate(state, data) {
            return (state.agentAdminChart1Data = data);
        },
        // agent
        agentChart1Mutate(state, data) {
            return (state.agentChart1Data = data);
        },
        // distributor
        distributorChart1Mutate(state, data) {
            return (state.distributorChart1Data = data);
        },
        // kashlock
        kashlockChart1Mutate(state, data) {
            return (state.kashlockChart1Data = data);
        },
        // investment
        investmentChart1Mutate(state, data) {
            return (state.investmentChart1Data = data);
        },
        // broker
        brokerChart1Mutate(state, data) {
            return (state.brokerChart1Data = data);
        },
        brokerCashPowerSalesMutate(state, data) {
            return (state.cashPowerSalesChartData = data);
        },

        brokerAirtimeSalesMutate(state, data) {
            return (state.airTimeSalesChartData = data);
        }
    }
};
