<template>
    <div>


        <div v-if="!$gate.isRegistrationComplete()">
            <div class="card px-4 py-2 border-0" style="box-shadow:none;">
                <div class="card-body text-center">
                    <h3>Congratulations for joining!</h3>
                    <div style="font-size: 1.2rem;">To be a certified cash collector,
                         you must <router-link to="/set-pin" class="text-info" style="text-decoration:underline" >
                        set your pin</router-link>
                    </div>
                </div>
            </div>
        </div>

        <div v-else-if="$gate.isRegistrationComplete() && $gate.canTransact()">

            <div class="row general">

                <div class="col-12">
                    <div class="card row " style="box-shadow:none;">
                        <div class="px-5 border-bottom pt-5 pb-4 d-flex justify-content-between">
                            <!-- cash on hands -->
                            <div class="p-0">
                                <span class="font-weight-bold font-size-1">Broker Balance</span> <br>
                                <div class="d-flex align-items-end">
                                    <span class="text-bgreen font-size-4 font-weight-bold">
                                        {{broker_balance}}
                                    </span>
                                </div>

                            </div>


                        </div>

                        <!-- <div class="px-5  pb-2 wallet-footer flex-wrap">

                            <div class="text-center">
                                <a  href="#" class="" data-toggle="modal" data-target="#depositForCustomerModal">
                                    <div class="icon-wrapper bg-ogreen1 ">

                                        <i class="fas fa-money-check-alt text-white"></i>

                                    </div>
                                    <strong>Deposit</strong>
                                </a>

                            </div>

                            <div class="text-center">
                                <a  href="#" class="">
                                    <div  class="icon-wrapper bg-yellow ">

                                            <i class="fas fa-history text-white"></i>

                                    </div>
                                    <strong>Logs</strong>
                                </a>

                            </div>

                        </div> -->

                    </div>
                </div>

                <div v-if="userInfo.can_transact != 0" class="col-12">
                    <div class="card px-5 border-0" style="box-shadow:none;">
                        <div class="d-flex  py-3  justify-content-between flex-wrap">
                            <div class="d-flex font-weight-bold align-items-center">
                                <span class="font-size-1">Cash Collector</span>

                            </div>
                            <div class="d-flex align-items-end">
                                <span class="text-bwgreen font-size-3 font-weight-bold">
                                    {{userInfo.institution_name}}
                                </span>
                            </div>

                        </div>


                    </div>

                </div>


            </div>

            <div class="row mt-3 border-bottom">
                <div
                    class="col-12 pb-2 text-muted font-weight-bold text-center"
                >
                    Daily Stats
                </div>

                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box">
                        <span class="info-box-icon text-brown1"
                            ><i class="far fa-paper-plane"></i
                        ></span>

                        <div class="info-box-content">
                            <span class="info-box-text">
                                <span class="btn-outline-info">{{ basicStats["total_requests"]["count"] }}</span>
                            </span>

                            <span class="info-box-text">Total Requests</span>
                            <span class="info-box-number">{{ dalasisFormatter(basicStats["total_requests"]["sum"]) }}</span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>

                <!-- /.col -->
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box mb-3">
                        <span class="info-box-icon" style="color: #9584e4"
                            ><i class="fas fa-hourglass-half"></i
                        ></span>

                        <div class="info-box-content">
                            <span class="info-box-text">
                                <span class="btn-outline-info">{{ basicStats["pending"]["count"] }}</span>
                            </span>

                            <span class="info-box-text"> Pending </span>

                            <span class="info-box-number">{{ dalasisFormatter(basicStats["pending"]["sum"]) }}</span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>
                <!-- /.col -->

                <!-- fix for small devices only -->
                <div class="clearfix hidden-md-up"></div>

                <!-- /.col -->
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box mb-3">
                        <span class="info-box-icon text-success"
                            ><i class="fas fa-thumbs-up"></i
                        ></span>

                        <div class="info-box-content">
                            <span class="info-box-text">
                                <span class="btn-outline-info">{{ basicStats["collected"]["count"] }}</span>
                            </span>
                            <span class="info-box-text"
                                >Successful Paid</span
                            >
                            <span class="info-box-number">{{ dalasisFormatter(basicStats["collected"]["sum"]) }}</span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>

                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box">
                        <span class="info-box-icon text-danger"
                            ><i class="fas fa-times"></i
                        ></span>

                        <div class="info-box-content">
                            <span class="info-box-text">
                                <span class="btn-outline-info">{{ basicStats["cancelled"]["count"] }}</span>
                            </span>

                            <span class="info-box-text">Cancelled</span>
                            <span class="info-box-number">{{ dalasisFormatter(basicStats["cancelled"]["sum"]) }}</span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>

                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box">
                        <span class="info-box-icon text-danger"
                            ><i class="fas fa-times"></i
                        ></span>

                        <div class="info-box-content">
                            <span class="info-box-text">
                                <span class="btn-outline-info">{{ basicStats['unpaid']['count'] }}</span>
                            </span>

                            <span class="info-box-text">Unpaid</span>
                            <span class="info-box-number">{{ dalasisFormatter(basicStats["unpaid"]["sum"]) }}</span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>

                <!-- /.col -->
            </div>

            <div class="row mt-3 border-bottom">
                <div
                    class="col-12 pb-2 text-muted font-weight-bold text-center"
                >
                    Weekly Stats
                </div>

                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box">
                        <span class="info-box-icon text-brown1"
                            ><i class="far fa-paper-plane"></i
                        ></span>

                        <div class="info-box-content">
                            <span class="info-box-text">
                                <span class="btn-outline-info">{{ weeklyStats['total_requests']['count'] }}</span>
                            </span>

                            <span class="info-box-text">Total Requests</span>
                            <span class="info-box-number">{{ dalasisFormatter(weeklyStats["total_requests"]["sum"]) }}</span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>

                <!-- /.col -->
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box mb-3">
                        <span class="info-box-icon" style="color: #9584e4"
                            ><i class="fas fa-hourglass-half"></i
                        ></span>

                        <div class="info-box-content">
                            <span class="info-box-text">
                                <span class="btn-outline-info">{{ weeklyStats['pending']['count'] }}</span>
                            </span>

                            <span class="info-box-text"> Pending </span>

                            <span class="info-box-number">{{ dalasisFormatter(weeklyStats["pending"]["sum"]) }}</span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>
                <!-- /.col -->

                <!-- fix for small devices only -->
                <div class="clearfix hidden-md-up"></div>

                <!-- /.col -->
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box mb-3">
                        <span class="info-box-icon text-success"
                            ><i class="fas fa-thumbs-up"></i
                        ></span>

                        <div class="info-box-content">
                            <span class="info-box-text">
                                <span class="btn-outline-info">{{ weeklyStats['collected']['count'] }}</span>
                            </span>
                            <span class="info-box-text"
                                >Successful Paid</span
                            >
                            <span class="info-box-number">{{ dalasisFormatter(weeklyStats["collected"]["sum"]) }}</span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>

                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box">
                        <span class="info-box-icon text-danger"
                            ><i class="fas fa-times"></i
                        ></span>

                        <div class="info-box-content">
                            <span class="info-box-text">
                                <span class="btn-outline-info">{{ weeklyStats['cancelled']['count'] }}</span>
                            </span>

                            <span class="info-box-text">Cancelled</span>
                            <span class="info-box-number">{{ dalasisFormatter(weeklyStats["cancelled"]["sum"]) }}</span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>


                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box">
                        <span class="info-box-icon text-danger"
                            ><i class="fas fa-times"></i
                        ></span>

                        <div class="info-box-content">
                            <span class="info-box-text">
                                <span class="btn-outline-info">{{ weeklyStats['unpaid']['count'] }}</span>
                            </span>

                            <span class="info-box-text">Unpaid</span>
                            <span class="info-box-number">{{ dalasisFormatter(weeklyStats["unpaid"]["sum"]) }}</span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>

                <!-- /.col -->
            </div>

            <div class="row mt-3 border-bottom">
                <div
                    class="col-12 pb-2 text-muted font-weight-bold text-center"
                >
                    Monthly Stats
                </div>

                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box">
                        <span class="info-box-icon text-brown1"
                            ><i class="far fa-paper-plane"></i
                        ></span>

                        <div class="info-box-content">
                            <span class="info-box-text">
                                <span class="btn-outline-info">{{ monthlyStatsTransactions['total_requests']['count'] }}</span>
                            </span>

                            <span class="info-box-text">Total Requests</span>
                            <span class="info-box-number">{{ dalasisFormatter(monthlyStatsTransactions["total_requests"]["sum"]) }}</span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>

                <!-- /.col -->
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box mb-3">
                        <span class="info-box-icon" style="color: #9584e4"
                            ><i class="fas fa-hourglass-half"></i
                        ></span>

                        <div class="info-box-content">
                            <span class="info-box-text">
                                <span class="btn-outline-info">{{ monthlyStatsTransactions['pending']['count'] }}</span>
                            </span>

                            <span class="info-box-text"> Pending </span>

                            <span class="info-box-number">{{ dalasisFormatter(monthlyStatsTransactions["pending"]["sum"]) }}</span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>
                <!-- /.col -->

                <!-- fix for small devices only -->
                <div class="clearfix hidden-md-up"></div>

                <!-- /.col -->
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box mb-3">
                        <span class="info-box-icon text-success"
                            ><i class="fas fa-thumbs-up"></i
                        ></span>

                        <div class="info-box-content">
                            <span class="info-box-text">
                                <span class="btn-outline-info">{{ monthlyStatsTransactions['collected']['count'] }}</span>
                            </span>
                            <span class="info-box-text"
                                >Successful Paid</span
                            >
                            <span class="info-box-number">{{ dalasisFormatter(monthlyStatsTransactions["collected"]["sum"]) }}</span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>

                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box">
                        <span class="info-box-icon text-danger"
                            ><i class="fas fa-times"></i
                        ></span>

                        <div class="info-box-content">
                            <span class="info-box-text">
                                <span class="btn-outline-info">{{ monthlyStatsTransactions['cancelled']['count'] }}</span>
                            </span>

                            <span class="info-box-text">Cancelled</span>
                            <span class="info-box-number">{{ dalasisFormatter(monthlyStatsTransactions["cancelled"]["sum"]) }}</span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>

                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box">
                        <span class="info-box-icon text-danger"
                            ><i class="fas fa-times"></i
                        ></span>

                        <div class="info-box-content">
                            <span class="info-box-text">
                                <span class="btn-outline-info">{{ monthlyStatsTransactions['unpaid']['count'] }}</span>
                            </span>

                            <span class="info-box-text">Unpaid</span>
                            <span class="info-box-number">{{ dalasisFormatter(monthlyStatsTransactions["unpaid"]["sum"]) }}</span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>

                <!-- /.col -->
            </div>

            <div class="row mt-3 border-bottom">
                <div
                    class="col-12 pb-2 text-muted font-weight-bold text-center"
                >
                    Overall Stats
                </div>

                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box">
                        <span class="info-box-icon text-brown1"
                            ><i class="far fa-paper-plane"></i
                        ></span>

                        <div class="info-box-content">
                            <span class="info-box-text">
                                <span class="btn-outline-info">{{ overallStats['total_requests']['count'] }}</span>
                            </span>

                            <span class="info-box-text">Total Requests</span>
                            <span class="info-box-number">{{ dalasisFormatter(overallStats["total_requests"]["sum"]) }}</span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>

                <!-- /.col -->
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box mb-3">
                        <span class="info-box-icon" style="color: #9584e4"
                            ><i class="fas fa-hourglass-half"></i
                        ></span>

                        <div class="info-box-content">
                            <span class="info-box-text">
                                <span class="btn-outline-info">{{ overallStats['pending']['count'] }}</span>
                            </span>

                            <span class="info-box-text"> Pending </span>

                            <span class="info-box-number">{{ dalasisFormatter(overallStats["pending"]["sum"]) }}</span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>
                <!-- /.col -->

                <!-- fix for small devices only -->
                <div class="clearfix hidden-md-up"></div>

                <!-- /.col -->
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box mb-3">
                        <span class="info-box-icon text-success"
                            ><i class="fas fa-thumbs-up"></i
                        ></span>

                        <div class="info-box-content">
                            <span class="info-box-text">
                                <span class="btn-outline-info">{{ overallStats['collected']['count'] }}</span>
                            </span>
                            <span class="info-box-text"
                                >Successful Paid</span
                            >
                            <span class="info-box-number">{{ dalasisFormatter(overallStats["collected"]["sum"]) }}</span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>

                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box">
                        <span class="info-box-icon text-danger"
                            ><i class="fas fa-times"></i
                        ></span>

                        <div class="info-box-content">
                            <span class="info-box-text">
                                <span class="btn-outline-info">{{ overallStats['cancelled']['count'] }}</span>
                            </span>

                            <span class="info-box-text">Cancelled</span>
                            <span class="info-box-number">{{ dalasisFormatter(overallStats["cancelled"]["sum"]) }}</span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>



                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box">
                        <span class="info-box-icon text-danger"
                            ><i class="fas fa-times"></i
                        ></span>

                        <div class="info-box-content">
                            <span class="info-box-text">
                                <span class="btn-outline-info">{{ overallStats['unpaid']['count'] }}</span>
                            </span>

                            <span class="info-box-text">Unpaid</span>
                            <span class="info-box-number">{{ dalasisFormatter(overallStats["unpaid"]["sum"]) }}</span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>
                

                <!-- /.col -->
            </div>

        

          


        </div>

        <div v-else-if="$gate.isRegistrationComplete() && !$gate.canTransact()">
            <div class="card px-4 py-2 border-0" style="box-shadow:none;">
                <div class="card-body text-center">
                    <h3>Congratulations for completing your registration!</h3>
                    <div style="font-size: 1.2rem;">Your details have been sent to broker for approval
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>


<script>

import TabNav from './../includes/tabNav'
import Tab from './../includes/tabs'


export default {
  name: 'Dashboard',
  components: {
    TabNav,
    Tab,
  },
  data() {
    return {
        selected: 'Daily',

        userInfo: '',
        broker_balance: 0,
        basicStats: {},
        weeklyStats: {},
        monthlyStatsTransactions: {},

        chart1Title: 'Daily Outgoing Transactions',
        chart1Selection: {
        'outgoing': [0]
        },


    }
  },
  created(){

    // fetch available locations (will use coordinate system one day) or user addresses
    this.dashboardStats();
    this.dashboardWeeklyStats();
    this.monthlyStats();
    this.overallStats();
    this.basicStats = {
                fee_earnings: {
                    count: 0,
                    sum: 0,
                },
                cancelled: {
                    count: 0,
                    sum: 0,
                },
                pending: {
                    count: 0,
                    sum: 0,
                },

                collected: {
                    count: 0,
                    sum: 0,
                },
            };

             this.weeklyStats = {
                fee_earnings: {
                    count: 0,
                    sum: 0,
                },
                cancelled: {
                    count: 0,
                    sum: 0,
                },
                pending: {
                    count: 0,
                    sum: 0,
                },

                collected: {
                    count: 0,
                    sum: 0,
                },
            };

            this.monthlyStatsTransactions = {
                fee_earnings: {
                    count: 0,
                    sum: 0,
                },
                cancelled: {
                    count: 0,
                    sum: 0,
                },
                pending: {
                    count: 0,
                    sum: 0,
                },

                collected: {
                    count: 0,
                    sum: 0,
                },
            };
            this.overallStats = {
                fee_earnings: {
                    count: 0,
                    sum: 0,
                },
                cancelled: {
                    count: 0,
                    sum: 0,
                },
                pending: {
                    count: 0,
                    sum: 0,
                },

                collected: {
                    count: 0,
                    sum: 0,
                },
            };
  },
  watch:{
        getUserProfile: function () {
            this.userInfo = this.getUserProfile
            this.broker_balance = this.userInfo.broker_balance

        },

  },

  mounted() {
        this.$store.dispatch("userProfile")
        this.$store.dispatch("CollectorChart1Data")


  },
    computed: {



        getUserProfile(){ //final output from here

            return this.$store.getters.getUserProfile
        },
        getCollectorChart1(){
            return this.$store.getters.getCollectorChart1
        }
    },
  methods: {
        setSelected(tab){
            this.selected = tab;

        },

        dashboardStats() {
            // fetch companies
            this.$isLoading(true);

            axios
                .get("api/collector-dashboard/basicStats")
                .then(({ data }) => {
                    console.log("basic stats", data)
                    this.basicStats = data;
                   this.$isLoading(false);
                })
                .catch((err) => {
                    this.$isLoading(false);
                });
        },

           monthlyStats() {
            // fetch companies
            this.$isLoading(true);

            axios
                .get("api/collector-dashboard/monthlyStats")
                .then(({ data }) => {
                    console.log("monthly stats", data)
                    this.monthlyStatsTransactions = data;
                    this.$isLoading(false);
                })
                .catch((err) => {
                    this.$isLoading(false);
                });
        },

        dashboardWeeklyStats() {
            // fetch companies
            this.$isLoading(true);

            axios
                .get("api/collector-dashboard/weeklyStats")
                .then(({ data }) => {
                    console.log("weekly stats", data)
                    this.weeklyStats = data;
                    this.$isLoading(false);
                })
                .catch((err) => {
                    this.$isLoading(false);
                });
        },

        overallStats() {
            // fetch companies
            this.$isLoading(true);

            axios
                .get("api/collector-dashboard/overallStats")
                .then(({ data }) => {
                    console.log("Overalll stats", data)
                    this.overallStats = data;
                    this.$isLoading(false);
                })
                .catch((err) => {
                    this.$isLoading(false);
                });
        },
  }
}
</script>

<style scoped>
.add-cash{

    font-size: 2.4rem;
    border-radius: 10px;
}

.sub-cash{
    font-size: 1.4rem;
    border-radius: 10px;
}

.add-cash:hover{
    background-color: #CCD673;
    background-image: linear-gradient( 135deg, #CCD673 10%, #a3d673 100%);
}

.icon-wrapper {
    background: #6236FF;
    width: 48px;
    height: 48px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    color: #fff;
    font-size: 24px;
    margin-bottom: 14px;
}
 .wallet-footer {
    border-top: 1px solid #DCDCE9;
    padding-top: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

 .wallet-footer strong {
    display: block;
    color: #27173E;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.2em;
}
</style>
