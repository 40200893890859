<template>
    <div v-if="$gate.isCustomer()">



        <div class="card  custom-card-1 m-0">
          <form class="form-horizontal" action="" @submit.prevent='createKashlockCredit' method="post">

            <fieldset>
                <legend class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center">
                     <span>KashLock Credit </span>

                    </div>

                     <a @click="$router.go(-1)" class="nav-link" active-class="active"><button class="btn bg-nyanza font-weight-bold text-gray">Go back</button></a>

                </legend>
                    <div class="card-header">
                    </div>


                    <div class="card-body">

                        <div class="row">
                            <!-- errors -->
                            <div class="col-6">
                                <div class="text-danger" v-if="form.errors.has('amount')" v-html="form.errors.get('amount')" />
                            </div>
                            

                            <!-- enderrors -->

                            <div class="col-12">
                                <div class="form-group pb-4">
                                   
                                    <h3>Credit Amount:  <b>{{ dalasisFormatter(this.credit_amount) }}</b></h3>
                                    <p>You can use {{this.credit_per}}% of your Kashlock amount whenever your balance is low.</p>
                                  </div>

                            </div>
                           
                           
                            <div class="col-12">
                                <div class="form-group pb-4">
                                    <label for="">Terms & Conditions</label>
                                    <div class="terms-box mb-2">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                    </div>

                                    <label><input type="checkbox" v-model="form.term_status" value="1" required> I agree with the Terms of service <span class="text-danger">*</span> </label>
                                    


                                </div>

                            </div>




                            <div class="col-12 mt-3 d-flex justify-content-center">
                                <button type="submit" :disabled="form.busy" class="btn custom-button-1">
                                    SUBMIT
                                </button>
                            </div>


                        </div>

                    </div>




            </fieldset>

          </form>







        </div>

    </div>
    <div v-else>
        <not-found></not-found>
    </div>
</template>


<script>



export default {
    name: 'createKashlockCredit',
    props:{

    },
  components: {

  },
  data() {
    return {
        credit_per:0,
        credit_amount:0,
        Kashlock:[],
        form: new Form({
            id:this.$route.params.id,
            term_status: '',

        }),

    }
  },
  created(){
    if(this.$gate.isCustomer()){
     
        this.$isLoading(true)

        let temp_form = new Form({
            id : this.$route.params.id
        })

        temp_form.post('/api/customer/viewKashlock')
            .then(({data}) => {


                this.Kashlock = data
                axios.get('/api/customer/getKaslockSettings')
                    .then(({data}) => {
                        this.credit_per = data.credit_amount_percentage;
                        this.credit_amount=  (this.Kashlock.amount*this.credit_per)/100;


                    }).catch((err)=>{

                })

            this.$isLoading(false)

            }).catch((err)=>{
                this.Kashlock = 'error'
            this.$isLoading(false)

        });



      }
 


  },
  mounted() {



  },
    computed: {


    },
    watch:{


    },
  methods:{

      createKashlockCredit(){

        if(!this.$gate.isCustomer())
            return;

            const box1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button-2 mr-2',
                denyButton: 'btn custom-button bg-secondary ml-2'
            },
            buttonsStyling: false
            })

            box1.fire({
                title: 'Enter your pin',
                text: 'To confirm that you want to get credit on  your Kashlock account',
                input: 'password',
                inputAttributes: {
                    required: true,

                },
                inputValidator: (value) => {
                    if (!value) {
                    return 'You need to enter your pin!'
                    }

                },
                showDenyButton: true,
                denyButtonText: `Back`,
                confirmButtonText: 'Submit',
                }).then((result) => {
                if (result.isConfirmed) {

                 this.form.pin = result.value


                  this.$isLoading(true)

                    this.form.post('/api/customer/createKashlockCredit',{headers:{"Content-Type":"application/json"}})
                        .then((data) => {
                        this.$isLoading(false)

                            const confirmBox2 = Swal.mixin({
                            customClass: {
                                confirmButton: 'btn custom-button-2',

                            },
                            buttonsStyling: false
                            })


                            confirmBox2.fire({
                            icon: 'success',
                            title: 'Kashlock credit Creation!',
                            text: 'Kashlock credit created successful!'

                            }).then((res)=>{
                                // redirect user to dashboard
                            window.location.href = '/kashlocks';
                           // location.reload();

                            })



                        })
                        .catch((err) => {
                        this.$isLoading(false)

                        let errorText = 'Kashlock credit Creation Unsuccessful'

                        if(err.response.data.errors['mypassword']){
                            errorText = err.response.data.errors['mypassword']
                        }
                        else if(err.response.data.errors['general']){
                            errorText = err.response.data.errors['general']
                        }


                            const confirmBox2 = Swal.mixin({
                            customClass: {
                                confirmButton: 'btn custom-button bg-danger',

                            },
                            buttonsStyling: true
                            })

                            confirmBox2.fire({
                            icon: 'error',
                            title: 'Failed!',
                            text: errorText,

                            })

                        });


                }
                })



      }

  },

}
</script>
<style>
.terms-box {
    padding: 11px;
    overflow-y: auto;
    max-height: 200px;
    border-radius: 10px;
    border: 1px solid #d3cece;
    box-shadow: inset 0 0 10px #d5d5d5;
}
</style>