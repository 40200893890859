<template>
  <div v-if="$gate.isMerchantAdmin()">
    <div>
      <legend class="d-flex justify-content-center flex-wrap">
        <span class="d-flex align-items-center"
          >{{ form.key_name }} Orders</span
        >
      </legend>
      <div
        v-show="orders.length > 0"
        class="d-flex justify-content-end mt-0 pt-0 mb-3"
      >
        <div class="btn-group btn-group-sm mr-3" role="group">
          <button
            type="button"
            :class="[
              form.type === 'live' ? 'btn-primary' : 'btn-outline-primary',
              'btn',
            ]"
            @click="handleKeyType('live')"
          >
            Live Data
          </button>
          <button
            type="button"
            :class="[
              form.type === 'test' ? 'btn-primary' : 'btn-outline-primary',
              'btn',
            ]"
            @click="handleKeyType('test')"
          >
            Test Data
          </button>
        </div>

        <button
          class="btn btn-sm btn-default"
          data-toggle="modal"
          data-target="#filterByModal"
          style="border-color: gray"
        >
          Filter
        </button>
      </div>

      <div class="card mt-2 table-responsive">
        <table id="example2" class="table table-hover">
          <thead>
            <tr class="text-center">
              <th>Order Id</th>
              <th>Order Token</th>
              <th>Key Name</th>
              <th>Order Amount</th>
              <th>Fee Amount</th>
              <th>Order Status</th>
              <th>Order Date</th>
              <th>Add.info</th>
              <!-- <th>Updated at</th> -->
              <th>Customer Details</th>
              <th>Trans. Id</th>
              <th>Trans. Cust. Name</th>
              <th>Trans. Cust. Email</th>
              <th class="border-left">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr class="text-center" v-if="orders.length === 0">
              <td colspan="11" class="text-center">No Records Found</td>
            </tr>
            <tr
              v-for="(data, index) in orders"
              :key="index"
              class="text-center"
            >
              <td class="align-middle">
                {{ data.order_id }}
              </td>
              <td class="align-middle">
                {{ data.order_token }}
              </td>
              <td class="align-middle">
                <span v-if="data.apikey">
                  {{ data.apikey.key_name }}
                </span>
              </td>
              <td class="align-middle">
                {{ dalasisFormatter(data.order_amount) }}
              </td>
              <td class="align-middle">
                {{ dalasisFormatter(data.fee) }}
              </td>
              <td class="align-middle">
                <span
                  v-bind:class="[
                    data.status === 'SUCCESS'
                      ? 'text-success'
                      : data.status === 'FAILED'
                      ? 'text-danger'
                      : 'text-secondary',
                    'font-weight-bold',
                  ]"
                >
                  {{ data.status }}
                </span>
              </td>
              <td class="align-middle">
                {{ data.order_date }}
              </td>
             <td class="align-middle">
                                {{ data.domain }}
            </td>
              <!-- <td class="align-middle">
                                {{ data.updated_at }}
                            </td> -->
              <td class="align-middle">
                <span v-if="data.customer_name"> {{ data.customer_name }}</span>
                <span v-if="data.customer_email">
                  {{ data.customer_email }}</span
                >
                <span v-if="data.customer_phone">
                  {{ data.customer_phone }}</span
                >
              </td>
              <td class="align-middle">
                <span v-if="data.transaction">
                  {{ data.transaction.id }}
                </span>
              </td>
              <td class="align-middle">
                <span v-if="data.transaction && data.transaction.customer">
                  {{ data.transaction.customer.first_name }}
                  {{ data.transaction.customer.last_name }}
                </span>
              </td>
              <td class="align-middle">
                <span v-if="data.transaction && data.transaction.customer">
                  {{ data.transaction.customer.email }}
                </span>
              </td>
              <td class="border-left">
                <div
                  v-if="form.type === 'live'"
                  class="d-flex flex-wrap justify-content-around"
                >
                  <router-link :to="{ path: '/order/logs/' + data.id }">
                    <button type="button" class="btn btn-outline-info btn-sm">
                      View Logs
                    </button>
                  </router-link>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="pt-3">
        <pagination
          align="center"
          :data="pageData"
          @pagination-change-page="getResults"
        ></pagination>
      </div>

      <div
        class="modal fade"
        id="filterByModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="filterByModalTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header border-0">
              <span class="swal2-title">Filter By</span>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <h5 class="text-muted">Api Key</h5>

              <div class="form-group ml-3">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    v-model="filter_by.key_name"
                    value="All"
                  />
                  <label class="form-check-label" for="exampleRadios1">
                    All
                  </label>
                </div>
                <div
                  v-for="key in apiKeys"
                  :key="key.key_name"
                  class="form-check"
                >
                  <input
                    class="form-check-input"
                    type="radio"
                    v-model="filter_by.key_name"
                    :value="key.key_name"
                  />
                  <label class="form-check-label" for="exampleRadios1">
                    {{ key.key_name }}
                  </label>
                </div>
              </div>
              <hr />
              <h5 class="text-muted">Order Status</h5>
              <div class="form-group ml-3">
                <div
                  v-for="(status, index) in filterOrderStatus"
                  :key="index"
                  class="form-check"
                >
                  <input
                    class="form-check-input"
                    type="radio"
                    v-model="filter_by.status"
                    :value="status.value"
                  />
                  <label class="form-check-label" for="exampleRadios1">
                    {{ status.name }}
                  </label>
                </div>
              </div>

              <hr />
              <h5 class="text-muted">Order By</h5>
              <div class="form-group ml-3">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    v-model="filter_by.order"
                    value="ascending"
                  />
                  <label class="form-check-label" for="exampleRadios1">
                    Ascending
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    v-model="filter_by.order"
                    value="descending"
                  />
                  <label class="form-check-label" for="exampleRadios1">
                    Descending
                  </label>
                </div>
              </div>
            </div>
            <div class="modal-footer border-0 d-flex justify-content-center">
              <button
                type="submit"
                @click="filterData"
                class="btn custom-button-2"
              >
                Filter
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer :getUserProfile="getUserProfile" />
  </div>
  <div v-else>
    <not-found></not-found>
  </div>
</template>

<script>
export default {
  name: "OrderTransactions",
  components: {},
  data() {
    return {
      balance: 0,
      apiKeys: [],
      pageData: {},
      orders: [],
      searchForm: null,
      isFound: false,
      form: new Form({
        key_name: "All",
        status: "All",
        order: "descending",
        type: "live",
      }),

      emptyDataText: "",
      filter_by: {
        key_name: "All",
        status: "All",
        order: "descending",
      },
      filterOrderStatus: [
        { name: "All", value: "All" },
        { name: "Pending", value: "PENDING" },
        { name: "Success", value: "SUCCESS" },
        { name: "Failed", value: "FAILED" },
      ],
    };
  },
  created() {
    this.getResults();
    this.getMerchantApiKeys();
  },
  watch: {
    getUserProfile: function () {
      this.balance = this.dalasisFormatter(this.getUserProfile.balance);
      document.getElementById("bladeAmount").innerHTML = this.balance;
    },
  },

  mounted() {
    this.$store.dispatch("userProfile");
  },
  computed: {
    getUserProfile() {
      //final output from here
      return this.$store.getters.getUserProfile;
    },
  },
  methods: {
    handleKeyType(keyType) {
      this.form.type = keyType;
      this.getMerchantApiKeys();
      this.getResults();
    },

    filterData() {
      $("#filterByModal").modal("toggle");

      this.form.key_name = this.filter_by.key_name;
      this.form.status = this.filter_by.status;
      this.form.order = this.filter_by.order;

      this.getResults();
    },

    getMerchantApiKeys() {
      if (!this.$gate.isMerchantAdmin()) {
        return;
      }

      axios
        .get("/api/api-keys")
        .then(({ data }) => {
          this.apiKeys = data.api_keys.filter(
            (key) => key.key_type === this.form.type
          );
        })
        .catch(() => {});
    },
    getResults(page) {
      if (!this.$gate.isMerchantAdmin()) {
        return;
      }
      if (typeof page === "undefined") {
        page = 1;
      }

      this.$isLoading(true);

      this.form
        .post("/api/order/transactions?page=" + page)
        .then(({ data }) => {
          if (
            data.orders &&
            Object.keys(data.orders).length > 0 &&
            Object.getPrototypeOf(data.orders) === Object.prototype
          ) {
            this.orders = data.orders.data;
            this.pageData = data.orders;
          } else {
            this.orders = [];
            this.pageData = {};
          }

          this.$isLoading(false);
        })
        .catch(() => {
          this.$isLoading(false);
        });
    },
  },
};
</script>
