<template>
    <div>
      <div v-if="$gate.isBroker()">
        <div class="row mt-3 border-bottom">
          
  
          <div class="col-12 col-sm-6 col-md-4">
            <div class="info-box">
            <span class="info-box-icon text-success"
              ><i class="fas fa-hand-holding-usd"></i
            ></span>

            <div class="info-box-content">
              <span class="info-box-text">
                <span class="btn-outline-info">{{ this.totalLockKashlocks  }}</span>
              </span>

              <span class="info-box-text"
                >Total Locked Kashlocks</span
              >
              <span class="info-box-number">{{ this.dalasisFormatter(this.totalLockKashlocksAmount)  }}</span>
            </div>
            <!-- /.info-box-content -->
          </div>
          <!-- /.info-box -->
          </div>
          <div class="col-12 col-sm-6 col-md-4">
            <div class="info-box">
            <span class="info-box-icon text-danger"
              ><i class="fas fa-hand-holding-usd"></i
            ></span>

            <div class="info-box-content">
              <span class="info-box-text">
                <span class="btn-outline-info">{{ this.totalUnlockKashlocks  }}</span>
              </span>

              <span class="info-box-text"
                >Total Unlocked Kashlocks</span
              >
              <span class="info-box-number">{{ this.dalasisFormatter(this.totalUnlockKashlocksAmount)  }}</span>
            </div>
            <!-- /.info-box-content -->
          </div>
          <!-- /.info-box -->
          </div>
          <div class="col-12 col-sm-6 col-md-4">
            <div class="info-box">
            <span class="info-box-icon text-brown1"
              ><i class="fas fa-hand-holding-usd"></i
            ></span>

            <div class="info-box-content">
              <span class="info-box-text">
                <span class="btn-outline-info">{{ this.totalFeeKashlocks  }}</span>
              </span>

              <span class="info-box-text"
                >Total Fee Earning</span
              >
              <span class="info-box-number">{{ this.dalasisFormatter(this.totalFeeKashlocksAmount)  }}</span>
            </div>
            <!-- /.info-box-content -->
          </div>
          <!-- /.info-box -->
          </div>
  
        </div>
   
  
      
        <div  class="row mt-3">
        <div class="col-md-12 col-lg-12">
          <div class="card">
            <div class="row">
              <div class="col-12">
                <h3 class="p-3 card-title">Investment & Fee Earnings</h3>
              </div>
              <div class="col-12 text-muted">
                <TabNav
                  :tabs="[
                    'Investments per financial service',
                    'Last Year',
                    'This Year',
                    'Monthly',
                    'Weekly',
                    'Daily',
                  ]"
                  :selected="selected"
                  @selected="setSelected"
                >
                </TabNav>
              </div>

              <div class="col-12 pt-2">
                <div class="text-center py-3 font-weight-bold">
                  {{ chart1Title }}
                </div>
                <div class="mb-4 ml-3">
                  <div>
                    <span class="text-muted">Total Investment: </span>
                    <span
                      class="font-weight-bold"
                      style="color: rgba(15, 45, 54, 1)"
                    >
                      {{
                        dalasisFormatter(
                          chart1Selection["total_investment"].reduce(
                            (total, num) => total + num,
                            0
                          )
                        )
                      }}
                    </span>
                  </div>
                  <div>
                    <span class="text-muted">Total Fee: </span>
                    <span
                      class="font-weight-bold"
                      style="color: rgb(63, 146, 194)"
                    >
                      {{
                        dalasisFormatter(
                          chart1Selection["total_fee"].reduce(
                            (total, num) => total + num,
                            0
                          )
                        )
                      }}
                    </span>
                  </div>
                </div>
                <STChart :chartData="chart1Selection" />
              </div>
            </div>
          </div>
        </div>

     
      </div>
    </div>
  </div>
  </template>
  <script>
  import STChart from "../../charts/kashlock/STChart.vue";
  import Tab from "../../includes/tabs.vue";
import TabNav from "../../includes/tabNav.vue";
  export default {
    name: "BrokerKashlockDashboard",
    components: {
    STChart,
    TabNav,
    Tab,
  },
  data() {
    return {
        totalLockKashlocks:0,
        totalUnlockKashlocks:0,
        totalFeeKashlocks:0,
        totalLockKashlocksAmount:0,
        totalUnlockKashlocksAmount:0,
        totalFeeKashlocksAmount:0,
        chart1Selection: {
            total_investment: [0],
            total_fee: [0],
      },
      chart1Title: "Daily Investment & Fee Earnings",
      selected: "Daily",
     }
    },
    created() {
        this.getKashlockCount();
    },
  watch:{
    getKashlockChart1: function () {
      this.chartData = this.getKashlockChart1;
      this.chart1Selection = this.getKashlockChart1["daily"];
    },
    selected: function () {
      if (this.selected == "Daily") {
        this.chart1Selection = this.getKashlockChart1["daily"];
        this.chart1Title = "Daily Investment & Fee Earnings";
        this.chart1BrokerTotal = this.getKashlockChart1["daily"]["broker"];
      } else if (this.selected == "Weekly") {
        this.chart1Selection = this.getKashlockChart1["weekly"];
        this.chart1Title = "Weekly Investment & Fee Earnings";
      } else if (this.selected == "Monthly") {
        this.chart1Selection = this.getKashlockChart1["monthly"];
        this.chart1Title = "Monthly Investment & Fee Earnings";
      } else if (this.selected == "This Year") {
        this.chart1Selection = this.getKashlockChart1["yearly"];
        this.chart1Title = "This Year's Investment & Fee Earnings";
      } else if (this.selected == "Last Year") {
        this.chart1Selection = this.getKashlockChart1["last year"];
        this.chart1Title = "Last Year's Investment & Fee Earnings";
    } else if (this.selected == "Investments per financial service") {
        this.chart1Selection = this.getKashlockChart1["period"];
        this.chart1Title = "Investments per Financial Service";
      }
    },
  
  },
  mounted() {
    if (this.$gate.isBroker()) {
      this.$store.dispatch("userProfile");
      this.$store.dispatch("kashlockChart1Data");

 
    }
  },
  computed: {
    getUserProfile() {
      //final output from here
      return this.$store.getters.getUserProfile;
    },
    getKashlockChart1() {
      console.log("chart1 data", this.$store.getters.getKashlockChart1);
      return this.$store.getters.getKashlockChart1;
    },
   
  },

  methods:{
    setSelected(tab) {
      this.selected = tab;
    },
    getKashlockCount() {
      axios
        .get("api/broker/KashlockCount")
        .then(({ data }) => {
  
          this.totalLockKashlocks = data.totalLockKashlocks;
          this.totalUnlockKashlocks = data.totalUnlockKashlocks;
          this.totalFeeKashlocks = data.totalFeeKashlocks;
          this.totalLockKashlocksAmount = data.totalLockKashlocksAmount;
          this.totalUnlockKashlocksAmount = data.totalUnlockKashlocksAmount;
          this.totalFeeKashlocksAmount = data.totalFeeKashlocksAmount;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  }
  }
  </script>
  
  
  
  
  