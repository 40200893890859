<template>
    <div>


        <div v-if="!$gate.isRegistrationComplete()">
            <div class="card px-4 py-2 border-0" style="box-shadow:none;">
                <div class="card-body text-center">
                    <h3>Congratulations for joining!</h3>
                    <div style="font-size: 1.2rem;">To be a certified cash distributor,
                         you must <router-link to="/set-pin" class="text-info" style="text-decoration:underline" >
                        set your pin</router-link>
                    </div>
                </div>
            </div>
        </div>

        <div v-else-if="$gate.isRegistrationComplete() && $gate.canTransact()">

            <div class="row general">

                <div class="col-12">
                    <div class="card row " style="box-shadow:none;">
                        <div class="px-5 border-bottom pt-5 pb-4 d-flex justify-content-between">
                            <!-- cash on hands -->
                            <div class="p-0">
                                <span class="font-weight-bold font-size-1">Broker Balance</span> <br>
                                <div class="d-flex align-items-end">
                                    <span class="text-bgreen font-size-4 font-weight-bold">
                                        {{broker_balance}}
                                    </span>
                                </div>

                            </div>


                        </div>

                        <!-- <div class="px-5  pb-2 wallet-footer flex-wrap">

                            <div class="text-center">
                                <a  href="#" class="" data-toggle="modal" data-target="#depositForCustomerModal">
                                    <div class="icon-wrapper bg-ogreen1 ">

                                        <i class="fas fa-money-check-alt text-white"></i>

                                    </div>
                                    <strong>Deposit</strong>
                                </a>

                            </div>

                            <div class="text-center">
                                <a  href="#" class="">
                                    <div  class="icon-wrapper bg-yellow ">

                                            <i class="fas fa-history text-white"></i>

                                    </div>
                                    <strong>Logs</strong>
                                </a>

                            </div>

                        </div> -->

                    </div>
                </div>

                <div v-if="userInfo.can_transact != 0" class="col-12">
                    <div class="card px-5 border-0" style="box-shadow:none;">
                        <div class="d-flex  py-3  justify-content-between flex-wrap">
                            <div class="d-flex font-weight-bold align-items-center">
                                <span class="font-size-1">Cash Distributor</span>

                            </div>
                            <div class="d-flex align-items-end">
                                <span class="text-bwgreen font-size-3 font-weight-bold">
                                    {{userInfo.institution_name}}
                                </span>
                            </div>

                        </div>


                    </div>

                </div>


            </div>

            <div class="row mt-5">

                <div class="col-12">
                <div class="card" style="box-shadow:none;">
                    <div class="row">
                        <div class="col-12">
                            <h3 class="p-3 card-title">Outgoing Deposits</h3>

                        </div>
                        <div class="col-12 text-muted">
                            <TabNav :tabs="['Last Year', 'This Year', 'Monthly', 'Weekly' , 'Daily']" :selected="selected" @selected="setSelected">

                            </TabNav>
                        </div>

                        <div class="col-12 pt-2">
                                <div class="text-center py-3 font-weight-bold">{{chart1Title}}</div>
                                <div class="mb-4 ml-3">

                                  <div >
                                        <span class="text-muted ">Outgoing Total: </span>
                                        <span class="font-weight-bold" style="color: rgba(148,82,165, 1);">
                                            {{dalasisFormatter(chart1Selection['outgoing'].reduce((total, num) => total + num, 0))}}
                                        </span>

                                    </div>

                                </div>
                            <Chart1 :chartData="chart1Selection" :barLabels='["Outgoing"]'
                                :barColors='["rgba(148,82,165, 0.6)"]'
                            />
                        </div>

                    </div>
                </div>

                </div>


            </div>

            <AgencyDepositButton :broker_balance='userInfo.broker_balance' :branch_id='userInfo.branch_id'
            :branch_town='userInfo.branch' />


            <AgencyFooter :getUserProfile='getUserProfile' />


        </div>

        <div v-else-if="$gate.isRegistrationComplete() && !$gate.canTransact()">
            <div class="card px-4 py-2 border-0" style="box-shadow:none;">
                <div class="card-body text-center">
                    <h3>Congratulations for completing your registration!</h3>
                    <div style="font-size: 1.2rem;">Your details have been sent to broker for approval
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>


<script>
import Chart1 from './../charts/general/Chart2.vue'
import TabNav from './../includes/tabNav'
import Tab from './../includes/tabs'
import AgencyDepositButton from './../includes/AgencyDepositButton.vue'

export default {
  name: 'Dashboard',
  components: {
      Chart1,
    AgencyDepositButton,
    TabNav,
    Tab,
  },
  data() {
    return {
        selected: 'Daily',

        userInfo: '',
        broker_balance: 0,


        chart1Title: 'Daily Outgoing Transactions',
        chart1Selection: {
        'outgoing': [0]
        },

    }
  },
  created(){

    // fetch available locations (will use coordinate system one day) or user addresses
  },
  watch:{
        getUserProfile: function () {
            this.userInfo = this.getUserProfile

            this.broker_balance = this.dalasisFormatter(this.userInfo.broker_balance)



        },
        getDistributorChart1: function(){
            this.chartData = this.getDistributorChart1
            this.chart1Selection = this.getDistributorChart1['daily'];

        },
        selected: function(){

           if(this.selected ==  'Daily'){
            //
            this.chart1Selection = this.getDistributorChart1['daily'];
            this.chart1Title = 'Daily Outgoing Transactions'

           }
           else if(this.selected == 'Weekly'){
               this.chart1Selection = this.getDistributorChart1['weekly'];
                this.chart1Title = 'Weekly  Outgoing Transactions'

           }
           else if(this.selected == 'Monthly'){
               this.chart1Selection = this.getDistributorChart1['monthly'];
                this.chart1Title = 'Month  Outgoing Transactions'


           }
           else if(this.selected == 'This Year'){
               this.chart1Selection = this.getDistributorChart1['yearly'];
            this.chart1Title = 'This Year\'s  Outgoing Transactions'


           }
           else if(this.selected == 'Last Year'){
               this.chart1Selection = this.getDistributorChart1['last year'];
            this.chart1Title = 'Last Year\'s & Outgoing Transactions'


           }
        },
  },

  mounted() {
        this.$store.dispatch("userProfile")
        this.$store.dispatch("distributorChart1Data")


  },
    computed: {



        getUserProfile(){ //final output from here

            return this.$store.getters.getUserProfile
        },
        getDistributorChart1(){
            return this.$store.getters.getDistributorChart1
        }
    },
  methods: {
        setSelected(tab){
            this.selected = tab;

        },
  }
}
</script>

<style scoped>
.add-cash{

    font-size: 2.4rem;
    border-radius: 10px;
}

.sub-cash{
    font-size: 1.4rem;
    border-radius: 10px;
}

.add-cash:hover{
    background-color: #CCD673;
    background-image: linear-gradient( 135deg, #CCD673 10%, #a3d673 100%);
}

.icon-wrapper {
    background: #6236FF;
    width: 48px;
    height: 48px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    color: #fff;
    font-size: 24px;
    margin-bottom: 14px;
}
 .wallet-footer {
    border-top: 1px solid #DCDCE9;
    padding-top: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

 .wallet-footer strong {
    display: block;
    color: #27173E;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.2em;
}
</style>
