<template>
  <div>
    <div
      v-if="$gate.isStore() && typeof companyInfo == 'object'"
      class="card custom-card-1 mt-0 mx-0"
    >
      <fieldset>
        <legend class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center">
            <span> Store Profile</span>
          </div>
          <a
            href="#"
            class="nav-link"
            active-class="active"
            onclick="history.go(-1);return false;"
          >
            <button class="btn bg-nyanza font-weight-bold text-gray">
              Go back
            </button>
          </a>
        </legend>
        <div class="card-header"></div>

        <div>
          <TabNav :tabs="tabs" :selected="selected" @selected="setSelected">
            <Tab :isSelected="selected === 'General'">
              <div class="row mt-5">
                <div class="col-12">
                  <div
                    class="d-flex justify-content-between flex-wrap border-bottom pb-3"
                  >
                    <div class="d-flex align-items-center">
                      <h5>General</h5>
                    </div>
                    <div class="d-flex justify-content-end flex-wrap">
                      <a
                        href="#"
                        class="nav-link"
                        data-toggle="modal"
                        data-target="#editAgencyModal"
                      >
                        <button class="btn btn-outline-info">
                          Edit <i class="fa fa-edit"></i>
                        </button>
                      </a>
                      <!-- <a
                        href='#'
                        class="nav-link"
                      >
                        <button class="btn btn-outline-bgreen">Deposit Broker Balance <i class="fa fa-plus"></i></button>
                      </a> -->
                    </div>
                  </div>
                </div>

                <div class="col-12 col-lg-4">
                  <div class="border-bottom font-weight-bold py-3 mb-3">
                    Avatar
                  </div>
                  <div class="image text-center">
                    <img
                      class="img-fluid pad"
                      src="/images/profiles/building-none.png"
                      alt=""
                    />
                  </div>
                  <!-- <div v-if="canEdit" class="btn btn-info w-100">
                                        Change profile picture
                                    </div> -->
                </div>

                <div class="col-12 col-lg-8 pr-lg-5">
                  <div class="border-bottom font-weight-bold py-3">Profile</div>
                  <div class="row py-3 border-bottom">
                    <div class="col-6">Company name</div>
                    <div class="col-6">
                      {{ companyInfo.company_name }}
                    </div>
                  </div>

                  <div
                    v-if="userInfo.user_type == 5"
                    class="row py-3 border-bottom"
                  >
                    <div class="col-6">Broker Balance</div>
                    <div class="col-6">
                      {{ dalasisFormatter(companyInfo.broker_balance) }}
                    </div>
                  </div>
                  <div class="row py-3 border-bottom">
                    <div class="col-6">Transaction Type</div>
                    <div class="col-6">
                      {{ companyInfo.transaction_type }}
                    </div>
                  </div>

                  <div class="row py-3 border-bottom">
                    <div class="col-6">Fee cut</div>
                    <div class="col-6">{{ companyInfo.fee_cut_percent }}%</div>
                  </div>
                  <div class="row py-3 border-bottom">
                    <div class="col-6">
                      Email <span class="text-danger">*</span>
                    </div>
                    <div class="col-6">
                      {{ companyInfo.company_email }}
                    </div>
                  </div>
                  <div class="row py-3 border-bottom">
                    <div class="col-6">
                      Phone Number <span class="text-danger">*</span>
                    </div>
                    <div class="col-6">
                      {{ companyInfo.phone_number }}
                    </div>
                  </div>

                  <div
                    v-if="userInfo.user_type == 5"
                    class="row py-3 border-bottom"
                  >
                    <div class="col-6">Total Merchant Admins</div>
                    <div class="col-6">
                      {{ companyInfo.total_merchant_admins }}
                    </div>
                  </div>
                  <div
                    v-if="userInfo.user_type == 5"
                    class="row py-3 border-bottom"
                  >
                    <div class="col-6">Total Merchants</div>
                    <div class="col-6">
                      {{ companyInfo.total_merchants }}
                    </div>
                  </div>
                  <div class="row py-3 border-bottom">
                    <div class="col-6">Registered on</div>
                    <div class="col-6">
                      {{ companyInfo.time_created }}
                    </div>
                  </div>
                </div>
              </div>
            </Tab>

            <Tab
              v-if="userInfo.user_type == 5"
              :isSelected="selected === 'Locations'"
            >
              <div class="row mt-5">
                <div class="col-12 pr-5">
                  <div
                    class="d-flex justify-content-between flex-wrap border-bottom pb-3"
                  >
                    <div class="d-flex align-items-center">
                      <h5>Locations</h5>
                    </div>
                    <router-link
                      v-bind:to="'/create-store-location/' + companyInfo.id"
                      href="#"
                      class="nav-link"
                    >
                      <button class="btn btn-outline-info">
                        Create Location <i class="fa fa-plus"></i>
                      </button>
                    </router-link>
                  </div>

                  <div
                    v-for="location in companyInfo.locations"
                    :key="location.id"
                    class="row py-5 border-bottom"
                  >
                    <div
                      class="col-12 d-flex justify-content-between flex-wrap pb-2"
                    >
                      <h5 class="d-flex align-items-center">
                        <strong>{{ location.location_name }}</strong>
                      </h5>

                      <div class="d-flex justify-content-end flex-wrap">
                        <a
                          href="#"
                          class="nav-link"
                          v-if="
                            location.merchant_admin_id == null &&
                            companyInfo.merchant_admins.length > 0
                          "
                          @click="
                            openModal(location.id, 'createMerchantAdmin', null)
                          "
                        >
                          <button type="button" class="btn btn-sm btn-dark">
                            Create Merchant Admin
                          </button>
                        </a>

                        <router-link
                          v-if="
                            location.merchant_admin_id == null &&
                            companyInfo.merchant_admins.length == 0
                          "
                          v-bind:to="'/create-merchant-admin/' + location.id"
                          class="nav-link border-right"
                          active-class="active"
                          exact
                        >
                          <button
                            type="button"
                            class="btn btn-sm btn-secondary"
                          >
                            Create Merchant Admin <i class="fa fa-plus"></i>
                          </button>
                        </router-link>

                        <router-link
                          v-if="location.merchant_admin_id != null"
                          v-bind:to="
                            '/create-store-location-merchant/' + location.id
                          "
                          class="nav-link border-right"
                          active-class="active"
                          exact
                        >
                          <button
                            type="button"
                            class="btn btn-sm btn-outline-secondary"
                          >
                            Create Merchant <i class="fa fa-plus"></i>
                          </button>
                        </router-link>

                        <router-link
                          v-bind:to="
                            '/edit-store-location-merchant/' +
                            location.id +
                            '/' +
                            companyInfo.id
                          "
                          class="nav-link"
                          active-class="active"
                          exact
                        >
                          <button type="button" class="btn btn-sm btn-info">
                            Edit Store Location <i class="fa fa-edit"></i>
                          </button>
                        </router-link>

                        <button
                          v-if="!location.is_blocked"
                          @click="blockLocation(location.id)"
                          class="btn custom-button mb-4 btn-danger"
                        >
                          Deactivate location
                          <i class="fas fa-ban"></i>
                        </button>

                        <button
                          v-else
                          @click="unblockLocation(location.id)"
                          class="btn custom-button mb-4 btn-danger"
                        >
                          Activate Store Location
                          <i class="fas fa-building"></i>
                        </button>
                      </div>
                    </div>

                    <div class="col-6">
                      <div class="d-flex justify-content-start">
                        <span class="d-flex align-items-center"
                          >Merchant Admin:
                        </span>
                        <div
                          class="mx-3 text-bwgreen font-weight-bold"
                          v-if="location.merchant_admin_id != null"
                        >
                          <router-link
                            v-bind:to="
                              '/view-merchant-profile/' +
                              location.merchant_admin_id
                            "
                          >
                            {{ location.first_name }} {{ location.last_name }}
                          </router-link>
                          <button
                            type="button"
                            @click="
                              openModal(
                                location.id,
                                'changeMerchantAdmin',
                                location.merchant_admin_id
                              )
                            "
                            class="btn btn-sm btn-info"
                          >
                            Change <i class="fa fa-edit"></i>
                          </button>
                        </div>
                      </div>
                      <div class="pt-2 d-flex justify-content-start">
                        <span class="pr-3">Active Merchants: </span>
                        <div class="d-flex justify-content-between flex-wrap">
                          <span
                            class="pr-3 text-bwgreen"
                            style=""
                            v-for="merchant in location.active_merchants"
                            :key="merchant.id"
                          >
                            <router-link
                              v-bind:to="
                                '/view-merchant-profile/' + merchant.id
                              "
                            >
                              {{ merchant.first_name }} {{ merchant.last_name }}
                            </router-link>
                          </span>
                        </div>
                      </div>
                      <div class="pt-2 d-flex justify-content-start">
                        <span class="">Phone numbers: </span>
                        <div>
                          <ul style="list-style-type: none">
                            <li
                              v-for="ph in location.phone_numbers"
                              :key="ph.id"
                            >
                              <span>-</span> <span>{{ ph.phone_number }} </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="pt-2 d-flex justify-content-end">
                        <span class="pr-3">Amount Collected: </span>
                        <div class="d-flex justify-content-between flex-wrap">
                          <span class="mr-3 font-weight-bold text-navy">
                            {{ dalasisFormatter(location.fee_earnings) }}
                          </span>
                        </div>
                      </div>
                    </div>

                    <!-- list  the agents.. make them clickable links-->
                  </div>
                </div>
              </div>
            </Tab>

            <Tab :isSelected="selected === 'Bank Accounts'"> bban </Tab>

            <Tab :isSelected="selected === 'Logs'"> logs </Tab>
          </TabNav>
        </div>
      </fieldset>

      <!-- modals -->
      <div
        class="modal fade"
        id="createMerchantAdminModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="createMerchantAdminModalTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <form method="post">
              <div class="modal-header border-0 p-0 m-0">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h3 class="swal2-title">Create Merchant Admin</h3>

                <div class="d-flex justify-content-between">
                  <div>
                    <a
                      @click="closeModal('new')"
                      class="nav-link"
                      active-class="active"
                      exact
                    >
                      <button type="button" class="btn btn-sm btn-secondary">
                        Create New Merchant Admin <i class="fa fa-plus"></i>
                      </button>
                    </a>
                  </div>

                  <div>
                    <a
                      @click="closeModal('old')"
                      class="nav-link"
                      active-class="active"
                      exact
                    >
                      <button
                        type="button"
                        class="btn btn-sm btn-outline-secondary"
                      >
                        Pick From Existing Agent Admins
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="pickMerchantAdminModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="pickMerchantAdminModalTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <form method="post" @submit.prevent="pickMerchantAdmin">
              <div class="modal-header border-0 p-0 m-0">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h3 class="swal2-title">Pick Merchant Admin</h3>
                <div class="input-group pt-3">
                  <select
                    v-model="pick_merchant_admin_form.merchant_admin_id"
                    class="form-control"
                    name=""
                    id=""
                  >
                    <option value="" selected disabled>---</option>
                    <option
                      v-for="merchant_admin in companyInfo.merchant_admins"
                      :key="merchant_admin.id"
                      :value="merchant_admin.id"
                    >
                      {{ merchant_admin.first_name }}
                      {{ merchant_admin.last_name }}
                    </option>
                  </select>
                </div>
                <div
                  class="modal-footer border-0 d-flex justify-content-center"
                >
                  <button
                    :disabled="pick_merchant_admin_form.merchant_admin_id == ''"
                    type="submit"
                    class="btn custom-button-2"
                  >
                    Assign
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="editAgencyModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="editAgencyModalTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <form @submit.prevent="editAgency" method="post">
              <div class="modal-header border-0">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="d-flex justify-content-center">
                  <h2 class="swal2-title" id="exampleModalLongTitle">
                    Edit Merchant Store
                  </h2>
                </div>
                <form @submit.prevent="editAgency" class="form-horizontal">
                  <div class="card-body">
                    <div class="form-group">
                      <div
                        class="text-danger"
                        v-if="edit_agency_form.errors.has('company_name')"
                        v-html="edit_agency_form.errors.get('company_name')"
                      />

                      <label for="inputEmail3">Company Name</label>
                      <input
                        type="text"
                        class="form-control"
                        id="inputEmail3"
                        v-model="edit_agency_form.company_name"
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div class="modal-footer border-0 d-flex justify-content-center">
                <button type="submit" class="btn custom-button-2">
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <!-- <Footer :getUserProfile="getUserProfile" /> -->
    </div>
    <div v-else>
      <not-found></not-found>
    </div>
  </div>
</template>




<script>
export default {
  name: "ViewUserProfile",
  props: {},
  components: {
    TabNav: () =>
      import("../includes/tabNav" /* webpackChunkName: "js/tab-nav" */),
    Tab: () => import("../includes/tabs" /* webpackChunkName: "js/tabs" */),
  },
  data() {
    return {
      choosen_location: null,

      selected: "General",
      tabs: [],
      logs: [],
      pageData: {},
      companyInfo: {},
      userInfo: "",
      deposit_form: new Form({
        amount: 0,
      }),
      edit_agency_form: new Form({
        company_name: "",
        picture: null,
        fee_cut_percent: 0,
      }),

      pick_merchant_admin_form: new Form({
        merchant_admin_id: "",
        store_location_id: "",
      }),
    };
  },
  created() {
    // fetch fee table
    this.getMerchantStore();
  },

  // created() {
  //   console.log("gate ", this.$gate.isBroker());

  //   if (this.$gate.isBroker()) {
  //     this.tabs = [
  //       "General",
  //       "locationes",
  //       "Transactions",
  //       "Deposits",
  //       "Withdrawals",
  //       "Bank Accounts",
  //       "Logs",
  //     ];
  //     this.$isLoading(true);

  //     let temp_form = new Form({
  //       id: this.$route.params.id,
  //     });

  //     temp_form
  //       .post("/api/broker/getAgency")
  //       .then(({ data }) => {
  //         this.companyInfo = data;
  //         this.edit_agency_form = new Form({
  //           company_id: data.id,
  //           company_name: data.company_name,
  //           picture: null,
  //           fee_cut_percent: data.fee_cut_percent,
  //           withdraw_fee_cut_percent: data.withdraw_fee_cut_percent,

  //           password: "",
  //         });

  //         this.$isLoading(false);
  //       })
  //       .catch((err) => {
  //         this.companyInfo = "error";
  //         this.$isLoading(false);
  //       });
  //   }
  // },
  watch: {
    getUserProfile: function () {
      if (this.$gate.isStore()) {
        this.userInfo = this.getUserProfile;
        document.getElementById("bladeCompanyName").innerHTML =
          this.getUserProfile.company_name;

        if (this.getUserProfile.user_type == 5) {
          document.getElementById("bladeAmount").innerHTML =
            this.dalasisFormatter(this.getUserProfile.balance);
          this.tabs = ["General", "Bank Accounts", "Locations", "Logs"];
        } else {
          document.getElementById("bladeAmount").innerHTML =
            this.dalasisFormatter(this.getUserProfile.total_transfered_amount);
          this.tabs = ["General", "Bank Accounts", "Logs"];
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch("userProfile");
    console.log("user type", this.userInfo);

    // if (this.userInfo.user_type == 5) {
    //   this.tabs = ["General", "Bank Accounts", "Locations", "Logs"];
    // } else {
    //   this.tabs = ["General", "Bank Accounts", "Logs"];
    // }
  },
  computed: {
    getUserProfile() {
      //final output from here
      return this.$store.getters.getUserProfile;
    },
  },
  methods: {
    setSelected(tab) {
      this.selected = tab;
    },

    getMerchantStore() {
      this.$isLoading(true);

      axios
        .post("/api/merchant/getMerchantStore")
        .then(({ data }) => {
          this.companyInfo = data;

          if (this.$gate.isStore()) {
            this.edit_agency_form = new Form({
              company_name: data.company_name,
              picture: null,
              fee_cut_percent: data.fee_cut_percent,
              password: "",
            });
          }

          this.$isLoading(false);
        })
        .catch((err) => {
          this.$isLoading(false);
        });
    },

    editAgency() {
      $("#editAgencyModal").modal("toggle");

      const confirmBox1 = Swal.mixin({
        customClass: {
          confirmButton: "btn custom-button bg-danger mr-2",
          cancelButton: "btn custom-button-2 ml-2",
        },
        buttonsStyling: false,
      });

      confirmBox1
        .fire({
          title: "Are you sure?",
          text: "Your changes will be saved and the store profile will be affected",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, save the changes!",
        })
        .then((result1) => {
          if (result1.isConfirmed) {
            // prompt for pin
            const box1 = Swal.mixin({
              customClass: {
                confirmButton: "btn custom-button bg-danger  mr-2",
                cancelButton: "btn custom-button-2 ml-2",
              },
              buttonsStyling: false,
            });

            box1
              .fire({
                title: "Enter your password",
                text: "To confirm your changes",
                input: "password",
                inputAttributes: {
                  required: true,
                },
                inputValidator: (value) => {
                  if (!value) {
                    return "You need to enter your password!";
                  }
                },
                showCancelButton: true,
                confirmButtonText: "Submit",
                showLoaderOnConfirm: true,
              })
              .then((result) => {
                if (result.isConfirmed) {
                  this.edit_agency_form.password = result.value;
                  this.$isLoading(true);

                  this.edit_agency_form
                    .post("/api/merchant/editAgency", {
                      headers: { "Content-Type": "application/json" },
                    })
                    .then((data) => {
                      this.$isLoading(false);

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button-2",
                        },
                        buttonsStyling: false,
                      });

                      confirmBox2
                        .fire({
                          icon: "success",
                          title: "Agency changes saved!",
                        })
                        .then((res) => {
                          window.location.reload();
                        });
                    })
                    .catch((err) => {
                      this.$isLoading(false);

                      // show them if that city name is taken
                      let errorText = "Agency changes Unsuccessful";

                      if (err.response.data.errors["password"]) {
                        errorText = err.response.data.errors["password"];
                        const confirmBox2 = Swal.mixin({
                          customClass: {
                            confirmButton: "btn custom-button bg-danger",
                          },
                          buttonsStyling: true,
                        });

                        confirmBox2.fire({
                          icon: "error",
                          title: "Failed!",
                          text: errorText,
                        });
                      } else {
                        $("#editAgencyModal").modal("toggle");
                      }
                    });
                }
              });
          }
        });
    },

    openModal(location_id, modal_type, merchant_admin_id) {
      this.choosen_location = location_id;

      if (modal_type == "createMerchantAdmin") {
        $("#createMerchantAdminModal").modal("toggle");
      } else if (modal_type == "changeMerchantAdmin") {
        this.pick_merchant_admin_form.merchant_admin_id = merchant_admin_id;

        $("#pickMerchantAdminModal").modal("toggle");
      }
    },
    closeModal(admin) {
      $("#createMerchantAdminModal").modal("toggle");
      if (admin == "new") {
        window.location.href =
          "/create-merchant-admin/" + this.choosen_location;
      } else if (admin == "old") {
        $("#pickMerchantAdminModal").modal("toggle");
      }
    },

    pickMerchantAdmin() {
      $("#pickMerchantAdminModal").modal("toggle");

      this.pick_merchant_admin_form.store_location_id = this.choosen_location;
      // let branch_name = this.companyInfo.store_locations.filter(
      //   (d) => d.id == this.pick_merchant_admin_form.branch
      // )[0]["town_name"];

      // let agent_admin = this.companyInfo.agent_admins.filter(
      //   (d) => d.id == this.pick_agent_admin_form.agent_admin_id
      // )[0];
      const confirmBox1 = Swal.mixin({
        customClass: {
          confirmButton: "btn custom-button bg-danger mr-2",
          cancelButton: "btn custom-button-2 ml-2",
        },
        buttonsStyling: false,
      });

      confirmBox1
        .fire({
          title: "Are you sure?",
          text: "you will be making the assignment",
          //   'You will be assigning "' +
          //   agent_admin.first_name +
          //   " " +
          //   agent_admin.last_name +
          //   '" to the ' +
          //   branch_name +
          //   " branch",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, assign them!",
        })
        .then((result1) => {
          if (result1.isConfirmed) {
            // prompt for pin
            const box1 = Swal.mixin({
              customClass: {
                confirmButton: "btn custom-button bg-danger  mr-2",
                cancelButton: "btn custom-button-2 ml-2",
              },
              buttonsStyling: false,
            });

            box1
              .fire({
                title: "Enter your password",
                text: "To confirm",
                // 'To confirm that you want to assign "' +
                // agent_admin.first_name +
                // " " +
                // agent_admin.last_name +
                // '" to the ' +
                // branch_name +
                // " branch",
                input: "password",
                inputAttributes: {
                  required: true,
                },
                inputValidator: (value) => {
                  if (!value) {
                    return "You need to enter your password!";
                  }
                },
                showCancelButton: true,
                confirmButtonText: "Submit",
                showLoaderOnConfirm: true,
              })
              .then((result) => {
                if (result.isConfirmed) {
                  this.pick_merchant_admin_form.password = result.value;
                  this.$isLoading(true);

                  this.pick_merchant_admin_form
                    .post("/api/merchant/pickMerchantAdmin", {
                      headers: { "Content-Type": "application/json" },
                    })
                    .then((data) => {
                      this.$isLoading(false);

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button-2",
                        },
                        buttonsStyling: false,
                      });

                      confirmBox2
                        .fire({
                          icon: "success",
                          title: "Agent Admin Assigned!",
                        })
                        .then((res) => {
                          window.location.reload();
                        });
                    })
                    .catch((err) => {
                      this.$isLoading(false);

                      // show them if that city name is taken
                      let errorText = "Agent Admin Assignment Unsuccessful";

                      if (err.response.data.errors["password"]) {
                        errorText = err.response.data.errors["password"];
                      } else if (err.response.data.errors["general"]) {
                        errorText = err.response.data.errors["general"];
                      }

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button bg-danger",
                        },
                        buttonsStyling: true,
                      });

                      confirmBox2.fire({
                        icon: "error",
                        title: "Failed!",
                        text: errorText,
                      });
                    });
                }
              });
          }
        });
    },
  },
};
</script>