var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.$gate.isBroker())?_c('div',{staticClass:"card custom-card-1 m-0"},[_c('fieldset',[_vm._m(0),_vm._v(" "),_c('SearchFilter',{attrs:{"data_length":_vm.withdrawals.length,"filters":_vm.filters,"data_type":"incoming","isFound":_vm.isFound},on:{"search-form":_vm.makeSearch}}),_vm._v(" "),(_vm.withdrawals.length != 0)?_c('div',{staticClass:"card-body table-responsive"},[_c('table',{staticClass:"table table-bordered table-hover border-top",attrs:{"id":"example2"}},[_vm._m(1),_vm._v(" "),_c('tbody',_vm._l((_vm.withdrawals),function(row,index){return _c('tr',{key:index,staticClass:"text-center"},[_c('td',[_c('span',[_vm._v("\n                                    "+_vm._s(row.agency_from)+" -\n                                    "+_vm._s(row.agency_from_branch)+"\n                                ")]),_vm._v(" "),_c('br'),_vm._v(" "),_c('small',[_vm._v("from:\n                                    "+_vm._s(row.agent_from_fullname))])]),_vm._v(" "),_c('td',[_c('span',[(
                                            row.other_user_type ===
                                            'Customer'
                                        )?_c('router-link',{staticClass:"cursor",attrs:{"to":'/view-user-profile/' +
                                            row.other_id}},[_vm._v("\n                                        "+_vm._s(row.other_name)+"\n                                        "),(
                                                row['other_is_mrz_verified']
                                            )?_c('img',{staticStyle:{"width":"16px","height":"16px"},attrs:{"src":"/images/verified-badge.png","alt":"image"}}):_vm._e()]):(
                                            row.other_user_type ===
                                            'Merchant'
                                        )?_c('router-link',{staticClass:"cursor",attrs:{"to":'/view-merchant-store/' +
                                            row.other_id}},[_vm._v("\n                                        "+_vm._s(row.other_name)+"\n                                    ")]):_vm._e()],1)]),_vm._v(" "),_c('td',[_vm._v("\n                                "+_vm._s(row.other_user_type)+"\n                            ")]),_vm._v(" "),_c('td',[_vm._v("\n                                "+_vm._s(_vm.dalasisFormatter(row.amount))+"\n                            ")]),_vm._v(" "),_c('td',[_vm._v("\n                                "+_vm._s(_vm.dalasisFormatter(row.fee_paid))+"\n                            ")]),_vm._v(" "),_c('td',[_vm._v(_vm._s(row.time_sent))]),_vm._v(" "),_c('td',[_vm._v("\n                                "+_vm._s(row.time_updated)+"\n                            ")])])}),0)]),_vm._v(" "),_c('div',{staticClass:"pt-3"},[_c('pagination',{attrs:{"align":"center","data":_vm.pageData},on:{"pagination-change-page":_vm.getResults}})],1)]):_c('div',{staticClass:"text-center"},[_c('span',[_c('i',[_vm._v(_vm._s(_vm.emptyDataText))])])])],1)]):_c('div',[_c('not-found')],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('legend',{staticClass:"d-flex justify-content-center flex-wrap"},[_c('span',[_vm._v("All Completed Withdrawals")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',{staticClass:"text-center"},[_c('th',[_vm._v("Agency from")]),_vm._v(" "),_c('th',[_vm._v("Customer / Merchant")]),_vm._v(" "),_c('th',[_vm._v("User Type")]),_vm._v(" "),_c('th',[_vm._v("Amount")]),_vm._v(" "),_c('th',[_vm._v("Fee Paid")]),_vm._v(" "),_c('th',[_vm._v("Time Sent")]),_vm._v(" "),_c('th',[_vm._v("Time Completed")])])])
}]

export { render, staticRenderFns }