<template>
  <div>
    <div v-if="$gate.isCustomer()" class="card p-5 m-0">
      <fieldset>
        <legend class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center">
            <span> {{ Kashlock.first_name + " " + Kashlock.last_name }}</span>
          </div>
          <a
            href="#"
            class="nav-link"
            active-class="active"
            onclick="history.go(-1);return false;"
          >
            <button class="btn bg-nyanza font-weight-bold text-gray">
              Go back
            </button>
          </a>
        </legend>
        <div class="card-header"></div>

        <div>
          <TabNav :tabs="tabs" :selected="selected" @selected="setSelected">
            <Tab :isSelected="selected === 'General'">
              <div class="row mt-5">
                <div class="col-12">
                  <div
                    class="d-flex justify-content-between flex-wrap border-bottom pb-3"
                  >
                    <div class="d-flex align-items-center">
                      <h5>General</h5>
                    </div>
                  </div>
                </div>
                <div class="col-8 pr-5">
                  <div class="border-bottom font-weight-bold py-3">
                    Kashlock Details
                  </div>
                  <div class="row py-3 border-bottom">
                    <div class="col-6">Customer name</div>
                    <div class="col-6">
                      {{ Kashlock.first_name + " " + Kashlock.last_name }}
                    </div>
                  </div>

                  <div class="row py-3 border-bottom">
                    <div class="col-6">Amount</div>
                    <div class="col-6">
                      {{ dalasisFormatter(Kashlock.amount) }}
                    </div>
                  </div>
                  <div class="row py-3 border-bottom">
                    <div class="col-6">Period</div>
                    <div class="col-6">{{ Kashlock.period }} Days</div>
                  </div>

                  <div class="row py-3 border-bottom">
                    <div class="col-6">Created Date</div>
                    <div class="col-6">
                      {{ Kashlock.time }}
                    </div>
                  </div>
                  <div class="row py-3 border-bottom">
                    <div class="col-6">Maturity Date</div>
                    <div class="col-6">
                      {{ Kashlock.maturity_date }}
                    </div>
                  </div>
                  <div class="row py-3 border-bottom">
                    <div class="col-6">Cancellation fee</div>
                    <div class="col-6">
                      <span v-if="Kashlock.cancellation_fee > 0">{{
                        dalasisFormatter(Kashlock.cancellation_fee)
                      }}</span>
                      <i v-else class="text-danger">(not set)</i>
                    </div>
                  </div>
                  <div class="row py-3 border-bottom">
                    <div class="col-6">Cancel Date</div>
                    <div class="col-6">
                      <span v-if="Kashlock.cancel_at != null">{{
                        Kashlock.cancel_at
                      }}</span>
                      <i v-else class="text-danger">(not set)</i>
                    </div>
                  </div>
                  <div class="row py-3 border-bottom">
                    <div class="col-6">Status</div>
                    <div class="col-6">
                      <span
                        v-if="Kashlock.Kstatus == 0"
                        class="badge badge-success badge-pill"
                        >Locked</span
                      >
                      <span v-else class="badge badge-danger badge-pill"
                        >Unlocked</span
                      >
                    </div>
                  </div>
                  <div class="row py-3 border-bottom">
                    <div class="col-6">
                      KashLock Credit Amount ( {{ Kashlock.percentage ?? 0 }}%)
                    </div>
                    <div class="col-6">
                      <span class="text-danger"
                        >-{{ dalasisFormatter(Kashlock.credit_amount) }}</span
                      >
                    </div>
                  </div>
                </div>

                <!-- <div class="col-4">
                                    <div class="border-bottom font-weight-bold py-3 mb-3">Avatar</div>
                                    <div class="image">
                                        <img class="img-fluid pad" src="/images/profiles/building-none.png" alt="">
                                    </div>

                                </div> -->
              </div>
            </Tab>
          </TabNav>
        </div>
      </fieldset>
    </div>
    <div v-else>
      <not-found></not-found>
    </div>
  </div>
</template>

<script>
export default {
  name: "ViewBank",
  props: {},
  components: {
    TabNav: () =>
      import("../../includes/tabNav" /* webpackChunkName: "js/tab-nav" */),
    Tab: () => import("../../includes/tabs" /* webpackChunkName: "js/tabs" */),
  },
  data() {
    return {
      selected: "General",
      logs: [],
      Kashlock: {},
      tabs: [],
      pageData: {},
    };
  },

  created() {
    if (this.$gate.isCustomer()) {
      this.tabs = ["General"];
      this.$isLoading(true);

      let temp_form = new Form({
        id: this.$route.params.id,
      });

      temp_form
        .post("/api/customer/viewKashlock")
        .then(({ data }) => {
          this.Kashlock = data;

          this.$isLoading(false);
        })
        .catch((err) => {
          this.Kashlock = "error";
          this.$isLoading(false);
        });
    }
  },
  watch: {},
  methods: {
    setSelected(tab) {
      this.selected = tab;
    },
  },
};
</script>
