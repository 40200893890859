<template>
    <div>
        <div v-if="$gate.isBroker()" class="card  custom-card-1 m-0">
            <fieldset>
                <legend class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center">
                        <span v-if="this.$route.params.companyType == 'bank'">Teller/Cashier Management</span>
                        <span v-else>Agents Management</span>
                    </div>
                    <form @submit.prevent="submitSearchAgent" class="input-group mb-2 w-50 d-flex align-items-center">
                        <input type="text" v-model="searchAgent" class="form-control mr-1" placeholder="Search Agent Admin Here" aria-label="Enter text here" aria-describedby="button-addon2">
                        <button class="btn btn-primary" type="submit" id="button-addon2">Submit</button>
                    </form>
                </legend>

                <div class="card-header">
                </div>




            <div v-if="agents.length != 0" class="card-body mt-2 table-responsive">
                <table  id="example2" class="table table-hover border-top">
                    <thead >
                    <tr class="text-center">
                    <th>Full Name</th>
                    <th>Agency</th>

                    <th>Can Transact</th>
                    <th>Total Transactions</th>


                    <th class="border-left">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class='text-center' v-for='(row, index) in agents' :key='index'>
                        <td>{{row.first_name}} {{row.last_name}}</td>
                        <td>

                            <router-link v-bind:to="'/view-agency/' + row.company_id"
                            active-class="active" exact class="" >
                               {{row.company_name}}
                            </router-link>
                        </td>

                        <td>
                        <span :class="[row.status == 'Yes' ? 'text-emerald' : 'text-red1', 'font-weight-bold']">
                        {{row.status}}

                        </span>

                        </td>

                        <td>{{row.total_transactions}}</td>
                        <td class="d-flex flex-wrap border-left justify-content-between">
                            <router-link v-bind:to="'/view-agent-profile/' + row.user_id"
                            active-class="active" exact class="btn mb-4 custom-button-purple1" >
                                <i class="fas fa-eye"></i>
                            </router-link>

                                 <button v-if="!row.is_blocked" @click="blockUser(row.user_id)"  class="btn custom-button mb-4 btn-danger">
                                <i class="fas fa-user-slash"></i>
                            </button>

                            <button v-else  @click="unblockUser(row.user_id)"   class="btn custom-button mb-4 btn-danger">
                                <i class="fas fa-user"></i>
                            </button>
                        </td>



                    </tr>
                    <tfoot>


                    </tfoot>
                    </tbody>
                </table>
                <div class="pt-3">
                    <pagination align="center" :data="pageData" @pagination-change-page="getResults"></pagination>

                </div>

            </div>


            </fieldset>








        </div>
        <div v-else>
            <not-found></not-found>

        </div>
    </div>
</template>

<script>
export default {
  name: "agents",
  props: {},
  components: {},
  data() {
    return {
      searchAgent: "",
      searchBy: "",
      searchText: "",

      form: null,

      agents: [],
      company: [],
      emptyDataText: "",
      pageData: {},
    };
  },
  created() {
    // fetch fee table
    if (this.$gate.isBroker()) {
      this.getResults();
      this.form = new Form({
        agent_id: 0,
        pin: "",
        agent_admin_id: "",
      });
    }
  },
  watch: {
    "$route.params.companyType": function () {
      window.location.reload();
    },
  },
  methods: {
    getResults(page) {
      if (typeof page === "undefined") {
        page = 1;
      }

      this.$isLoading(true);

      let axiosQuery = null;

      if (this.searchAgent != "") {
        if (this.$route.params.companyType == "agency") {
          axiosQuery = axios.post("/api/broker/agents?page=" + page, {
            filter: this.searchAgent,
          });
        } else if (this.$route.params.companyType == "bank") {
          axiosQuery = axios.post(
            "/api/broker/agents?page=" + page + "&companyType=3"
          );
        }

        axiosQuery
          .then(({ data }) => {
            console.log("agents ", data);
            //   this.agents = data.data;
            //   this.pageData = data
            if (data.data.length != 0) {
              this.agents = data.data;
              this.pageData = data;
            } else {
              this.agents = [];
              this.pageData = {};
              //   this.emptyDataText = 'There are no agents'
            }

            this.$isLoading(false);
          })
          .catch((err) => {
            this.agents = [];
            this.pageData = {};
            this.$isLoading(false);
          });
      } else {
        if (this.$route.params.companyType == "agency") {
          axiosQuery = axios.post("/api/broker/agents?page=" + page);
        } else if (this.$route.params.companyType == "bank") {
          axiosQuery = axios.post(
            "/api/broker/agents?page=" + page + "&companyType=3"
          );
        }

        axiosQuery
          .then(({ data }) => {
            console.log("agents ", data);
            //   this.agents = data.data;
            //   this.pageData = data
            if (data.data.length != 0) {
              this.agents = data.data;
              this.pageData = data;
            } else {
              this.agents = [];
              this.pageData = {};
              //   this.emptyDataText = 'There are no agents'
            }

            this.$isLoading(false);
          })
          .catch((err) => {
            this.agents = [];
            this.pageData = {};
            this.$isLoading(false);
          });
      }
    },

    blockUser(id) {
      const confirmBox1 = Swal.mixin({
        customClass: {
          cancelButton: "btn custom-button-2 mx-2",
          confirmButton: "btn custom-button bg-danger mx-2",
        },
        buttonsStyling: false,
      });

      confirmBox1
        .fire({
          title: "Are you sure?",
          text: "You'll be blocking this Agent's account ",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, block this Agent!",
        })
        .then((result1) => {
          if (result1.isConfirmed) {
            const box1 = Swal.mixin({
              customClass: {
                confirmButton: "btn custom-button-2 mx-2",
                denyButton: "btn custom-button bg-secondary mx-2",
              },
              buttonsStyling: false,
            });

            box1
              .fire({
                title: "Enter your password",
                input: "password",
                inputAttributes: {
                  pattern: "[0-9]+",
                  required: true,
                },
                inputValidator: (value) => {
                  if (!value) {
                    return "You need to enter your password!";
                  }
                },
                showDenyButton: true,
                denyButtonText: `Back`,
                confirmButtonText: "Submit",
              })
              .then((result) => {
                if (result.isConfirmed) {
                  this.$isLoading(true);

                  let del_form = new FormData();
                  del_form.append("user_id", id);
                  del_form.append("password", result.value);

                  axios
                    .post(
                      window.location.origin + "/api/broker/blockUser",
                      del_form,
                      {
                        headers: { "Content-Type": "application/json" },
                      }
                    )
                    .then((data) => {
                      this.$isLoading(false);

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button-2",
                        },
                        buttonsStyling: false,
                      });

                      confirmBox2
                        .fire({
                          icon: "success",
                          title: "Blocked!",
                          text: "User Blocked! ... ",
                        })
                        .then((res) => {
                          // reloading the page
                          // window.location.href = '/agents';
                          this.getResults();
                        });
                    })
                    .catch((err) => {
                      this.$isLoading(false);
                      // show them if that city name is taken
                      let errorText = "Agent Blocking Unsuccessful";

                      if (err.response.data.errors["password"]) {
                        errorText = err.response.data.errors["password"];
                      } else if (err.response.data.errors["general"]) {
                        errorText = err.response.data.errors["general"];
                      }

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button bg-danger",
                        },
                        buttonsStyling: true,
                      });

                      confirmBox2.fire({
                        icon: "error",
                        title: "Failed!",
                        text: errorText,
                      });
                    });
                } else if (result.isDenied) {
                }
              });
          }
        });
    },

    unblockUser(id) {
      const confirmBox1 = Swal.mixin({
        customClass: {
          cancelButton: "btn custom-button-2 mx-2",
          confirmButton: "btn custom-button bg-danger mx-2",
        },
        buttonsStyling: false,
      });

      confirmBox1
        .fire({
          title: "Are you sure?",
          text: "You'll be unblocking this customer's account ",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, unblock this customer!",
        })
        .then((result1) => {
          if (result1.isConfirmed) {
            const box1 = Swal.mixin({
              customClass: {
                confirmButton: "btn custom-button-2 mx-2",
                denyButton: "btn custom-button bg-secondary mx-2",
              },
              buttonsStyling: false,
            });

            box1
              .fire({
                title: "Enter your password",
                input: "password",
                inputAttributes: {
                  pattern: "[0-9]+",
                  required: true,
                },
                inputValidator: (value) => {
                  if (!value) {
                    return "You need to enter your password!";
                  }
                },
                showDenyButton: true,
                denyButtonText: `Back`,
                confirmButtonText: "Submit",
              })
              .then((result) => {
                if (result.isConfirmed) {
                  this.$isLoading(true);

                  let del_form = new FormData();
                  del_form.append("user_id", id);
                  del_form.append("password", result.value);

                  axios
                    .post(
                      window.location.origin + "/api/broker/unblockUser",
                      del_form,
                      {
                        headers: { "Content-Type": "application/json" },
                      }
                    )
                    .then((data) => {
                      this.$isLoading(false);

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button-2",
                        },
                        buttonsStyling: false,
                      });

                      confirmBox2
                        .fire({
                          icon: "success",
                          title: "Unblocked!",
                          text: "User Unblocked! ... ",
                        })
                        .then((res) => {
                          // reloading the page
                          this.getResults();
                        });
                    })
                    .catch((err) => {
                      this.$isLoading(false);
                      // show them if that city name is taken
                      let errorText = "Customer Blocking Unsuccessful";

                      if (err.response.data.errors["password"]) {
                        errorText = err.response.data.errors["password"];
                      } else if (err.response.data.errors["general"]) {
                        errorText = err.response.data.errors["general"];
                      }

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button bg-danger",
                        },
                        buttonsStyling: true,
                      });

                      confirmBox2.fire({
                        icon: "error",
                        title: "Failed!",
                        text: errorText,
                      });
                    });
                } else if (result.isDenied) {
                }
              });
          }
        });
    },
    submitSearchAgent() {
      this.getResults();
    },
  },
};
</script>
