<template>
    <div>
        <div v-if="$gate.isBroker()" class="card  custom-card-1 m-0">
          <form class="form-horizontal" action="" @submit.prevent='createStore' method="post">

            <fieldset>
                <legend class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center">
                     <span>Create Merchant Store</span>

                    </div>
                   <a href="#" class="nav-link" active-class="active" onclick="history.go(-1);return false;">
                       <button class="btn bg-nyanza font-weight-bold text-gray" >Go back</button>
                    </a>
                </legend>
                    <div class="card-header">
                    </div>


                    <div class="card-body">

                        <div class="row" >
                            <div class="col-12">
                            <legend>
                                <span style="" class="font-weight-bold text-muted">Merchant Store Details</span>

                            </legend>
                            </div>
                            <div class="col-6">
                                    <div class="text-danger" v-if="form.errors.has('company_name')" v-html="form.errors.get('company_name')" />
                            </div>
                            <div class="col-6">
                                    <div class="pt-3 text-danger" v-if="form.errors.has('broker_balance')" v-html="form.errors.get('broker_balance')" />
                            </div>   

                            <div class="col-6 ">
                                <div class="form-group pb-4">
                                    
                                    <label for="">Store Name <span class="text-danger">*</span> 
                                    </label>
                                    <input type="text" v-model="form.company_name"  name="company_name"
                                     maxlength="70" placeholder="Enter store Name" class="form-control"><br>
                                    

                                </div>

                            </div>

                            <div class="col-6">
                                <div class="form-group pb-4">
                                    
                                    <label for="">Broker Balance
                                    </label>
                                    <input type="number" v-model="form.broker_balance" 
                                      placeholder="Enter initial broker balance" class="form-control"><br>
                                    

                                </div>

                            </div>    



                            <div class="col-6">
                                    <div class="text-danger" v-if="form.errors.has('company_email')" v-html="form.errors.get('company_email')" />
                            </div>
                            <div class="col-6">
                                    <div class="pt-3 text-danger" v-if="form.errors.has('phone_number')" v-html="form.errors.get('phone_number')" />
                            </div>   

                            <div class="col-6 ">
                                <div class="form-group pb-4">
                                    
                                    <label for="">Email
                                    </label>
                                    <input type="email" v-model="form.company_email"  name="company_email"
                                     maxlength="70" placeholder="Enter store email" class="form-control"><br>
                                    

                                </div>

                            </div>

                            <div class="col-6">
                                <div class="form-group pb-4">
                                    
                                    <label for="">Phone Number  <span class="text-danger">*</span> 
                                    </label>
                                    <input type="number" v-model="form.phone_number" 
                                      placeholder="Enter store phone number" class="form-control"><br>
                                    

                                </div>

                            </div> 
                            <div class="col-6">
                                    <div class="text-danger" v-if="form.errors.has('fee_cut_percent')" v-html="form.errors.get('fee_cut_percent')" />
                            </div>
                            <div class="col-6">
                                    <div class="text-danger" v-if="form.errors.has('transaction_type')" v-html="form.errors.get('transaction_type')" />
                            </div>
                            <div class="col-6 ">
                                <div class="form-group py-4">
                                    
                                    <label for="">Transaction fee cut percent<span class="text-danger">*</span> 
                                    </label>
                                    <input type="number" v-model="form.fee_cut_percent" 
                                      placeholder="Enter fee cut percent" class="form-control"><br>
                                    

                                </div>

                            </div>

                            <div class="col-6 ">
                                <div class="form-group py-4">
                                    <label for="">Transaction Type <span class="text-danger">*</span> </label>
                                
                                       
                                        <select class="form-control" required v-model="form.transaction_type" >
                                            <option v-for="tt in transaction_types" :key="tt.id"
                                            :value='tt.id'>
                                            {{tt.type}}
                                            </option>

                                        </select>
                                    
                                </div>

                            </div>



                            <div class="col-12 text-center">
                                <div class="form-group pb-4">
                                    
                                    <label for="">Avatar
                                    </label>
                                    <div class="image">
                                        <img class="img-fluid pad"
                                         style="border: 1px dashed #ccc;"
                                         src="/images/profiles/building-none.png" alt="">
                                    </div>
                                    
                                    <div class=""><button type="button" class="btn border">Change Avatar</button></div>
                                </div>

                            </div>

                        </div>




                        <div class="row pt-3" style="background: whitesmoke;">
                            <div class="col-12 ">
                            <legend>
                                <span style="" class="font-weight-bold text-muted">Merchant Admin Details</span>

                            </legend>
                            </div>
                            <!-- errors -->
                            <div class="col-4">
                                    <div class="text-danger" v-if="form.errors.has('first_name')" v-html="form.errors.get('first_name')" />
                            </div>
                            <div class="col-4">
                                    <div class="text-danger" v-if="form.errors.has('last_name')" v-html="form.errors.get('last_name')" />
                            </div>
                            <div class="col-4">
                                    <div class="text-danger" v-if="form.errors.has('username')" v-html="form.errors.get('username')" />
                            </div>
                            <!-- enderrors -->
                            <div class="col-4">
                                <div class="form-group pb-4">
                                    
                                    <label for="">First Name <span class="text-danger">*</span> 
                                    </label>
                                    <input type="text" v-model="form.first_name" required name="first_name"
                                     maxlength="70" placeholder="Enter First Name" class="form-control"><br>
                                    

                                </div>

                            </div>
                            <div class="col-4">
                                <div class="form-group pb-4">
                                    <label for="">Last Name <span class="text-danger">*</span> </label>
                                
                                    <input type="text" v-model="form.last_name" required maxlength="70" placeholder="Enter Last name" class="form-control"><br>
                                    

                                </div>

                            </div>
                            <div class="col-4">
                                <div class="form-group pb-4">
                                    <label for="">Username <span class="text-danger">*</span> </label>
                                
                                    <input type="text" v-model="form.username" required maxlength="70" placeholder="Enter Last name" class="form-control"><br>
                                    

                                </div>

                            </div>



                            <div class="col-4">
                                <div class="form-group py-4">
                                    
                                    <label for="">Phone number <span class="text-danger">*</span> </label>
                                    <input type="number" v-model="form.display_phone_number" required maxlength="7"  placeholder="Enter Phone number" class="form-control"><br>

                                </div>

                            </div>

                            <div class="col-4">
                                <div class="form-group py-4">
                                    <label for="">Tin Number <span class="text-danger">*</span> </label>
                                
                                    <input type="text" v-model="form.tin_no" placeholder="Enter Last name" class="form-control"><br>


                                </div>

                            </div>
                            <div class="col-4">
                                <div class="form-group py-4">
                                    <label for="">ID Card number <span class="text-danger">*</span> </label>
                                    <input type="text" v-model="form.id_card_no" placeholder="Enter Last name" class="form-control"><br>

                                </div>

                            </div>


                            <!-- errors -->
                            <div class="col-6">
                                    <div class="text-danger" v-if="form.errors.has('address')" v-html="form.errors.get('address')" />
                            </div>
                            <div class="col-6">
                                    <div class="text-danger" v-if="form.errors.has('branch')" v-html="form.errors.get('branch')" />
                            </div>
                            <!-- enderrors -->


                            <div class="col-6">
                                <div class="form-group py-4">
                                    <label for="">Address <span class="text-danger">*</span> </label>
                                
                                        <Select2 required v-model="form.address" :options="locations.towns" />
                                    
                                </div>

                            </div>
                            <div class="col-6">

                            </div>
                            

                            <div class="col-12 text-center pt-4 pb-3">
                               <i> </i>
                            </div>
                            <div class="col-6">
                                <div class="form-group py-4">
                                    <label for="">Email <span class="text-danger">*</span> </label>
                                    <input type="email" v-model="form.email" placeholder="Enter email" class="form-control"><br>

                                </div>           
                            </div>
                            <div class="col-12 d-flex justify-content-center">

                                <div class="form-group">
                                <label class="text-center">Enter password <span class="text-danger">*</span></label>
                                    <input type="password" v-model="form.password" class="form-control"
                                     required placeholder="Enter ...">
                                </div>
                            </div>
                            <div class="col-12 d-flex justify-content-center">

                                <div class="form-group">
                                <label class="text-center">Repeat password</label>

                                    <input type="password" v-model="form.password_confirmation" class="form-control" required
                                     placeholder="Repeat ...">
                                </div>
                                
                            </div>
                            <div class="col-12 text-danger text-center">
                                  <div v-if="form.errors.has('password')" v-html="form.errors.get('password')" />
                            </div>


                            <div class="col-12 py-3 d-flex justify-content-center">
                                <button type="submit" :disabled="form.busy" class="btn custom-button-1">
                                    SUBMIT
                                </button>
                            </div>


                        </div>



                    </div>




            </fieldset>

          </form>






            
        </div>

        <div v-else>
            <not-found></not-found>
            
        </div>
    </div>
</template>


<script>
import Select2 from 'v-select2-component';


export default {
    name: 'CreateMerchantStore',
    props:{
       
    },
  components: {
    Select2
  },
  data() {
    return {
        form: new Form({

            company_name: '',
            company_picture: 'building-none.png',
            broker_balance: 0,
            logo: '',
            transaction_type: '',
            fee_cut_percent: '',
            company_tin_no: '',
            company_email: '',
            phone_number: '',
            company_address: '',
            first_name: '',
            last_name: '',
            username: '',
            id_card_no: '',
            tin_no: '',
            display_phone_number: '',
            address: '',
            password: '',
            password_confirmation: '',
        }),

        transaction_types: [],

        locations: []
    }
  },
  created(){
        if(this.$gate.isBroker()){
            this.getTransactionTypes()

        }
  },  
  mounted() {
        this.$store.dispatch("locations")


  },
    computed: {

        getLocations(){ //final output from here
            return this.$store.getters.getLocations
        },

    },
    watch:{
            getLocations: function () {
                this.locations = this.getLocations

            },
            
    },
  methods:{

      createStore(){
        if(this.$gate.isBroker()){
            const box1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button-2 mr-2',
                denyButton: 'btn custom-button bg-secondary ml-2'
            },
            buttonsStyling: false
            })

            box1.fire({
                title: 'Enter your password',
                text: 'To confirm you want to add the merchant store "' + this.form.company_name + '" to the system',
                input: 'password',
                inputAttributes: {
                    required: true,
                },
                inputValidator: (value) => {
                    if (!value) {
                    return 'You need to enter your password!'
                    }

                },
                showDenyButton: true,
                denyButtonText: `Back`,
                confirmButtonText: 'Submit',
                }).then((result) => {
                if (result.isConfirmed) {

                this.form.password = result.value

                  this.$isLoading(true) 

            this.form.post('api/broker/createMerchantStore',{headers:{"Content-Type":"application/json"}})
                .then((data) => {
                  this.$isLoading(false) 

                    const confirmBox2 = Swal.mixin({
                    customClass: {
                        confirmButton: 'btn custom-button-2',
                    
                    },
                    buttonsStyling: false
                    })


                    confirmBox2.fire({
                    icon: 'success',
                    title: 'Registered as Merchant Store!',
                    text: 'Merchant Store Registration Successful'

                    }).then((res)=>{
                        // redirect user to dashboard
                    
                    window.location.href = '/broker-merchant-stores';

                    })



                })
                .catch((err) => {

                        this.$isLoading(false) 

                        // show them if that city name is taken
                        let errorText = 'Merchant Store Registration Unsuccessful'

                        if(err.response.data.errors['password']){
                            errorText = err.response.data.errors['password']
                        }
                        else if(err.response.data.errors['general']){
                            errorText = err.response.data.errors['general']
                        }


                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button bg-danger',
                        
                        },
                        buttonsStyling: true
                        })

                        confirmBox2.fire({
                        icon: 'error',
                        title: 'Failed!',
                        text: errorText,

                        })
                });


                }
                })











        }

      },

      getTransactionTypes(){
            this.$isLoading(true) 
          

            axios.get('api/transaction-type')
                .then(({data}) => {
                  this.transaction_types = data;
                  this.$isLoading(false) 


                }).catch((err)=>{
                  this.transaction_types = []
                  this.$isLoading(false) 

                    
            })
      }

  },

}
</script>