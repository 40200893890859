<template>
    <div class="card  custom-card-1 m-0">

        <TabNav :tabs="tabs" :selected="selected" @selected="setSelected">
                    <Tab :isSelected="selected === 'General' ">
                        
                        <div class="row mt-5">
                            <div class="col-12">
                                    <div class="d-flex justify-content-between flex-wrap border-bottom pb-3">
                                        <div class="d-flex align-items-center">
                                            <h5>General</h5>
                                        </div>
                                       

                                    </div>

                                </div>
                           
                            <div class="col-8 pr-5">
                                <div class="border-bottom font-weight-bold py-3">Profile</div>

                                <div class="row py-3 border-bottom">
                                    <div class="col-6 ">
                                        Institution Name
                                    </div>
                                    <div class="col">
                                        {{userInfo.institution_name}}
                                    </div>
                                </div>
                                <div class="row py-3 border-bottom">
                                    <div class="col-6 ">
                                        Username
                                    </div>
                                    <div class="col">
                                        {{userInfo.username}}
                                    </div>
                                </div>
                                

                                
                                <div class="row py-3 border-bottom">
                                    <div class="col-6 ">
                                        Email
                                    </div>
                                    <div class="col">
                                        {{userInfo.email}}
                                    </div>
                                </div>

                                <div class="row py-3 border-bottom">
                                    <div class="col-6 ">
                                        Telephone number
                                    </div>
                                    <div class="col">
                                        {{userInfo.telephone}}
                                    </div>
                                </div>

                                <div class="row py-3 border-bottom">
                                    <div class="col-6 ">
                                        Address
                                    </div>
                                    <div class="col">
                                        {{userInfo.town_name}}
                                    </div>
                                </div>

                                
                             
                                <!-- <div class="row py-3 border-bottom">
                                    <div class="col-6 ">
                                        Pin
                                    </div>
                                    <div class="col">
                                       {{userInfo.pin}}
                                    </div>
                                </div> -->


                            </div>

                            <div class="col-4">
                                <div class="border-bottom font-weight-bold py-3 mb-3">Avatar</div>

                                <div class="image">
                                    <img class="img-fluid pad" src="/images/profiles/profile-none.png" alt="">
                                </div>
                                <!-- <div v-if="canEdit" class="btn btn-info w-100">
                                    Change profile picture
                                </div> -->
                            </div>
                        </div>
                    </Tab>

                    <Tab :isSelected="selected === 'Primary Contact' ">
                        <!--  -->
                        <div class="row mt-5">
                            <div class="col-12">
                                <div class="border-bottom pb-3"><h5>Primary Contact</h5></div>

                            </div>
                            <div class="col-8 pr-5">
                                <div class="border-bottom font-weight-bold py-3">Profile</div>


                                <div class="row py-3 border-bottom">
                                    <div class="col-6 ">
                                        Name
                                    </div>
                                    <div class="col">
                                        {{userInfo.contact_name}}
                                    </div>
                                </div>

                                <div class="row py-3 border-bottom">
                                    <div class="col-6 ">
                                        Position
                                    </div>
                                    <div class="col">
                                        {{userInfo.contact_position}}
                                    </div>
                                </div>
                                <div class="row py-3 border-bottom">
                                    <div class="col-6 ">
                                        Email
                                    </div>
                                    <div class="col">
                                        {{userInfo.contact_email}}
                                    </div>
                                </div>

                                <div class="row py-3 border-bottom">
                                    <div class="col-6 ">
                                        Telephone number
                                    </div>
                                    <div class="col">
                                        {{userInfo.contact_telephone}}
                                    </div>
                                </div>

                                <div class="row py-3 border-bottom">
                                    <div class="col-6 ">
                                        Address
                                    </div>
                                    <div class="col">
                                        {{userInfo.Tadd}}
                                    </div>
                                </div>

                                
                             
                                <div class="row py-3 border-bottom">
                                    <div class="col-6 ">
                                        Company registration information
                                    </div>
                                    <div class="col">
                                       {{userInfo.company_registration_info}}
                                    </div>
                                </div>


                            </div>

                            <div class="col-4">
                                <div class="border-bottom font-weight-bold py-3 mb-3">Avatar</div>

                                <div class="image">
                                    <img class="img-fluid pad" src="/images/profiles/profile-none.png" alt="">
                                </div>
                                <!-- <div v-if="canEdit" class="btn btn-info w-100">
                                    Change profile picture
                                </div> -->
                            </div>
                        </div>
                    </Tab>



                    


                </TabNav>

    </div>
</template>


<script>

import TabNav from '../includes/tabNav.vue'
import Tab from '../includes/tabs.vue'


export default {
    name: 'Profile',
    props:{
       
    },
  components: {
    TabNav,
    Tab,
  },
  data() {
    return {
        
        selected: 'General',
        tabs: [],
        logs: [],
        pageData: {},
        userInfo: {},

    }
  },
  created(){


        this.tabs = ['General','Primary Contact', 'Logs']


  },  
  watch:{
        getUserProfile: function () {
            this.userInfo = this.getUserProfile


        },

  },

  mounted() {
        this.$store.dispatch("userProfile")


  },
    computed: {



        getUserProfile(){ //final output from here
            return this.$store.getters.getUserProfile
        },

    },
  methods:{

    setSelected(tab){
            this.selected = tab;

        },

  },

}
</script>