<template>
    <div>

        <div v-if="!getUserProfile.is_mrz_verified" class="d-flex justify-content-center">

            <div class="main">

                <!-- Sign up form -->
                <section class="signup">
                    <div class="container">

                        <div class="signup-content">
                            <div class="row px-md-5">
                                <div class="col-12 text-center">
                                    <h4 class="form-title">MRZ Authentication</h4>

                                </div>
                                <div class="col-12 pb-2">

                                    <!-- insert error message -->
                                </div>
                                <div class="col-12">
                                    <div class="row">


                                        <div class="col-12 col-md-6 text-center">

                                                <span v-if="errors.mrz.length > 0" class="text-danger" role="alert">
                                                    <strong>{{errors.mrz[0]}}</strong>
                                                </span>

                                            <div class="image-container mx-auto" style="height:auto;">
                                                <div class="image-wrapper">
                                                    <div id='front_image' class="modal-image">
                                                        <img src="#" alt="Front of Id">
                                                    </div>
                                                    <div class="content">
                                                    <div class="icon"><i class="fas fa-cloud-upload-alt text-teal"></i></div>
                                                    <div class="text">No file chosen, yet!</div>
                                                    </div>

                                                    <div id="cancel-btn"><i class="fas fa-times"></i>
                                                    </div>
                                                </div>

                                            </div>

                                            <div class="text-center mt-1 mb-4">
                                            <label for="front" class="btn text-info border-0  w-100" style="text-decoration: underline"> 
                                            Upload Front of id</label>

                                            </div>
                                        </div>

                                        <div class="col-12 col-md-6 text-center">
<!-- 
                                                        <span class="text-danger" role="alert">
                                                            <strong>Please input a valid image</strong>
                                                        </span> -->

                                            <div class="image-container mx-auto"  style="height:auto;">
                                                <div class="image-wrapper">
                                                    <div id='back_image' class="modal-image">
                                                        <img src="#" alt="Back of Id">
                                                    </div>
                                                    <div class="content">
                                                    <div class="icon"><i class="fas fa-cloud-upload-alt text-teal"></i></div>
                                                    <div class="text">No file chosen, yet!</div>
                                                    <span class="text-info text text-md">You can leave this blank if your MRZ code and picture are on the same side</span>

                                                    </div>


                                                    <div id="cancel-btn"><i class="fas fa-times"></i></div>

                                                </div>

                                            </div>
                                            <div class="text-center mt-1 mb-4">
                                            <label for="back" class="btn text-info border-0  w-100" style="text-decoration: underline"> 
                                            Upload Back of id</label>

                                            </div>
                                        </div>
                                        <div class="col-12 border-top py-3"></div>
                                        <div class="col-12 col-md-6 text-center">

                                            <span v-if="errors.selfie.length > 0" class="text-danger" role="alert">
                                                <strong>{{errors.selfie[0]}}</strong>
                                            </span>

                                            <div class="image-container mx-auto"  style="height:auto;">
                                                <div class="image-wrapper">
                                                    <div id='selfie_image' class="modal-image">
                                                        <img src="#" alt="Face Selfie">
                                                    </div>
                                                    <div class="content">
                                                    <div class="icon"><i class="fas fa-cloud-upload-alt text-teal"></i></div>
                                                    <div class="text">No file chosen, yet!</div>

                                                    </div>


                                                    <div id="cancel-btn"><i class="fas fa-times"></i></div>

                                                </div>

                                            </div>
                                            <div class="text-center mt-1 mb-4">
                                            <label for="selfie" class="btn text-pink border-0  w-100" style="text-decoration: underline"> 
                                            Upload Face Selfie</label>

                                            </div>
                                        </div>                            

                                    </div>

                                </div>

                                <div class="col-12 signup-form">

                                    <form @submit.prevent='sendDetails' id="mrzForm" method="POST" enctype="multipart/form-data" class="register-form" >


                                        <input id="front" type="file" name="front" required
                                         @change='showSelected' 
                                        hidden>
                                        <input id="back" type="file" name="back" 
                                         @change='showSelected'  hidden>
                                        <input id="selfie" type="file" name="selfie" 
                                         @change='showSelected'  hidden>


                                    </form>
                                </div>
                                <div class="col-12">
                                    <div class="text-center">
                                        <div class="">
                                            <button :disabled="mrz_form.front === '' || mrz_form.selfie === '' " type="submit" id="btn-submit" form="mrzForm" class="btn  custom-button-2" required>
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>



            </div>





        </div>
        <div v-else>
            <not-found></not-found>
            
        </div>

    </div>
</template>

<script>

export default {
  name: 'MrzRegistration',
  components: {

  },
  data() {
    return {
        mrz_form: {
        front: '',
        back: '',
        selfie: '',
        },  
        url:{
        front: '',
        back: '',
        selfie: '',
        },
        errors: {
            front: '',
            back: '',
            selfie: '',
            mrz: ''
        }
    }
  },
  created(){

        

  },

  methods: {
      sendDetails(){
        //  run fields error checks method
        
        if(this.mrz_form.selfie === '' || this.mrz_form.front === '')
            return

        // send request and return data to verify user info
        const formData1 = new FormData();
        formData1.append('front', this.mrz_form.front)
        formData1.append('back', this.mrz_form.back)
        formData1.append('selfie', this.mrz_form.selfie)
        this.$isLoading(true) 

        axios.post('api/mrz-registration/confirmation',formData1,{headers:{"Content-Type":"application/json"}})
            .then((data) => {
                this.$isLoading(false) 
                console.log(data)
                let options = { year: 'numeric', month: 'long', day: 'numeric' };
                let tempDOB = data.data['id_data']['date_of_birth']
                const DOB = new Date(tempDOB.substring(0, 2) + "-" + tempDOB.substring(2, 4) + "-" + tempDOB.substring(4));

                let formatted_data = 
                `
                <h4 class="font-weight-bold" id="swal2-title">
                Verify Information</h4>
                <h6>Please ensure the information below is correct</h6>
                <div class="row py-3 text-left text-md bg-nyanza">
                    <div class="col-6 pr-1">
                    Name
                    </div>
                    <div class="col-6 font-weight-bold">
                    ${data.data['id_data']['first_name']} ${data.data['id_data']['last_name']} 
                    </div>

                    <div class="col-6 pt-1 pr-1">
                    ID Number: 
                    </div>
                    <div class="col-6 pt-1 font-weight-bold">
                    ${data.data['id_data']['document_number']}
                    </div>

                    <div class="col-6 pt-1 pr-1">
                    Gender: 
                    </div>
                    <div class="col-6 pt-1 font-weight-bold">
                    ${data.data['id_data']['sex']}
                    </div>

                    <div class="col-6 pt-1 pr-1">
                    Date of Birth
                    </div>
                    <div class="col-6 pt-1 font-weight-bold">
                    ${DOB.toLocaleDateString("en-US", options)}
                    </div>


                </div>
                <p class="pt-2 text-left">Image Match Score: ${data.data['match_score']} %</p>

                `;


                const confirmBox1 = Swal.mixin({
                    customClass: {
                        cancelButton: 'btn custom-button bg-danger mx-2',
                        confirmButton: 'btn custom-button-2 mx-2'
                    },
                    buttonsStyling: false
                })

                confirmBox1.fire({
                // imageUrl: this.url.selfie,
                // imageWidth: 400,
                // imageHeight: 200,
                imageAlt: 'ID Image and Selfie Merge',
                html: formatted_data,
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, it does. save it!',
                cancelButtonText: 'No, it doesn\'t',
                allowOutsideClick: false
                }).then((result) => {
                if (result.isConfirmed) {

                const formData2 = new FormData();
                formData2.append('mrz', data.data['id_data']['mrz'])

                axios.post('api/mrz-registration',formData2,{headers:{"Content-Type":"application/json"}})
                    .then(data => {

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button-2',
                        
                        },
                        buttonsStyling: false
                        })

                        confirmBox2.fire({
                        icon: 'success',
                        title: 'You account has been successfully verified!',

                        }).then((res)=>{

                        // redirect
                        // if(this.$gate.isCustomer()){
                        //     window.location.href = '/dashboard'

                        // }
                        
                            window.location.href = '/dashboard'

                       
                        })
                    }).catch(err => {

                    })

                }
                })

                this.$isLoading(false) 
            })
            .catch((err) => {
                console.log(err.response.data)
                this.$isLoading(false) 
                this.errors = {...this.errors, ...err.response.data.errors}
    
                const confirmBox2 = Swal.mixin({
                customClass: {
                    confirmButton: 'btn custom-button bg-danger',
                
                },
                buttonsStyling: true
                })

                confirmBox2.fire({
                icon: 'error',
                title: 'Failed!',
                text: "Your MRZ registration didn't work. Please try again",

                })

            });


      },
        showSelected(event){

                let side = event.target.id
                let input = document.getElementById(side);
                let upload_div = document.getElementById(side + '_image');
                this.mrz_form[side] = event.target.files[0];
                this.url[side] =  URL.createObjectURL(input.files[0]);
                // console.log(event, '/n ', input.files[0])
                upload_div.innerHTML = '<img style="width:100%;height:100%;object-fit:cover;" src=' + this.url[side] + ' alt=' + input.files.item(0).name  +' >';

    },


  },
  mounted() {
        this.$store.dispatch("userProfile")

  },
  computed: {
        getUserProfile(){ //final output from here
            return this.$store.getters.getUserProfile
        },
  },
  watch:{
        getUserProfile: function () {
            this.balance = this.dalasisFormatter(this.getUserProfile.balance)
            document.getElementById('bladeAmount').innerHTML = this.dalasisFormatter(this.getUserProfile.balance)
            if(this.$gate.isStore())
                document.getElementById('bladeCompanyName').innerHTML = this.getUserProfile.company_name
           

        },

  },

}
</script>

