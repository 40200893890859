<template>
    <div v-if="$gate.isMerchantAdmin()">
      <div>
        <legend class="d-flex justify-content-center flex-wrap">
          <span class="d-flex align-items-center">Deposit Logs</span>
        </legend>
  
        <div class="text-right">
          <button
            onclick="history.go(-1);return false;"
            class="btn bg-nyanza font-weight-bold text-gray"
          >
            Go back
          </button>
        </div>
  
        <div class="card mt-2 table-responsive">
          <table id="example2" class="table table-hover">
            <thead>
              <tr class="text-center">
                <th>Deposit Id</th>
                <th>Deposit Token</th>
                <th>Log Id</th>
                <th>Log Type</th>
                <th>Request</th>
                <th>Response</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="text-center"
                v-if="!order || order.deposit_logs.length === 0"
              >
                <td colspan="10" class="text-center">No Records Found</td>
              </tr>
              <tr
                v-for="(data, index) in order.deposit_logs"
                :key="index"
                class="text-center"
              >
                <td class="align-middle">
                  {{ order.deposit_id }}
                </td>
                <td class="align-middle">
                  {{ order.deposit_token }}
                </td>
                <td class="align-middle">
                  {{ data.id }}
                </td>
                <td class="align-middle">
                  {{ data.log_type }}
                </td>
                <td class="align-middle">
                  <pre
                    class="text-left pre-container"
                    v-if="data.request"
                    v-html="JSON.stringify(data.request, null, 1)"
                  />
                </td>
                <td class="align-middle">
                  <pre
                    class="text-left pre-container"
                    v-if="data.response"
                    v-html="JSON.stringify(data.response, null, 1)"
                  />
                </td>
  
                <!-- <td class="align-middle">
                                  {{ data.created_at }}
                              </td>
                              <td class="align-middle">
                                  {{ data.updated_at }}
                              </td> -->
              </tr>
            </tbody>
          </table>
        </div>
      </div>
  
      <Footer :getUserProfile="getUserProfile" />
    </div>
    <div v-else>
      <not-found></not-found>
    </div>
  </template>
  
  <script>
  export default {
    name: "OrderLogs",
    components: {},
    data() {
      return {
        id: "",
        balance: 0,
        order: null,
      };
    },
    created() {
      this.id = this.$route.params.id;
    },
    watch: {
      getUserProfile: function () {
        this.balance = this.dalasisFormatter(this.getUserProfile.balance);
        document.getElementById("bladeAmount").innerHTML = this.balance;
      },
    },
  
    mounted() {
      this.$store.dispatch("userProfile");
      this.getResults();
    },
    computed: {
      getUserProfile() {
        //final output from here
        return this.$store.getters.getUserProfile;
      },
    },
    methods: {
      getResults() {
        if (!this.$gate.isMerchantAdmin()) {
          return;
        }
        this.$isLoading(true);
        axios
          .get("/api/third-party-deposit/logs/" + this.id)
          .then(({ data }) => {
            if (
              data.order &&
              Object.keys(data.order).length > 0 &&
              Object.getPrototypeOf(data.order) === Object.prototype
            ) {
              this.order = data.order;
            } else {
              this.order = null;
            }
  
            this.$isLoading(false);
          })
          .catch((err) => {
            this.$isLoading(false);
          });
      },
    },
  };
  </script>
  <style scoped>
  /* .pre-container {
      max-width: 350px;
      max-height: 300px;
      overflow: auto;
  } */
  </style>
  