<template>
  <div class="customer">
    <div class="settings">
      <div v-if="$gate.isAgentAdmin()" class="p-0 m-0">
        <legend class="d-flex justify-content-center flex-wrap">
          <h1 class="d-flex align-items-center">Reconciliation Reports</h1>
        </legend>
        <div class="card mb-12 col-12">
          <div class="card-header">
            <div class="font-weight-bold text-muted mt-1">
              Generate Transactions Report by Branch
            </div>
          </div>
          <div class="card-body border-bottom">
            <form @submit.prevent="generateReport" class="form-horizontal">
              <div class="form-group">
                <label for="inputA">Select Branch</label>
                <select
                  v-model="timeline_form.branch_id"
                  class="form-control"
                  id="inputA"
                  required
                >
                  <option value="0">All Branches</option>
                  <option
                    v-for="ag_br in this.company.branches"
                    :key="ag_br.id"
                    :value="ag_br.id"
                  >
                    {{ ag_br.town_name }} ({{ ag_br.city_name }})
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="inputB">Select Transaction Type</label>
                <select
                  v-model="timeline_form.transaction_type"
                  class="form-control"
                  id="inputB"
                  required
                >
                  <option value="CompletedTransactions">
                    Completed Transactions
                  </option>
                  <option value="CompletedDeposits">Completed Deposits</option>
                  <option value="completedWithdrawals">
                    Completed Withdrawals
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="inputSD">Start Date</label>
                <input
                  type="date"
                  class="form-control"
                  id="inputSD"
                  v-model="timeline_form.start_date"
                  required
                />
              </div>
              <div class="form-group">
                <label for="inputED">End Date</label>
                <input
                  type="date"
                  class="form-control"
                  id="inputED"
                  v-model="timeline_form.end_date"
                  required
                />
              </div>
              <div class="d-grid">
                <button type="submit" class="btn btn-success btn-block">
                  Run
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Reconciliation",
  components: {},
  data() {
    return {
      company: [],
      timeline_form: new Form({
        branch_id: 0,
        transaction_type: "",
        start_date: "",
        end_date: "",
      }),
    };
  },
  created() {
    this.getBranches();
  },
  methods: {
    getBranches() {
      axios
        .get("api/company/userCompany")
        .then(({ data }) => {
          this.company = data;
          this.$isLoading(false);
        })
        .catch((err) => {
          this.$isLoading(false);
        });
    },
    generateReport() {
      this.$router.push({
        name: "reconciliation-report-data",
        // these params are not persited in the next page, it becomes undefined by page reload
        query: {
          branch_id: this.timeline_form.branch_id,
          transaction_type: this.timeline_form.transaction_type,
          start_date: this.timeline_form.start_date,
          end_date: this.timeline_form.end_date,
        },
      });
      // return this.$router.push({ path: 'reconciliation-report-data', query: { branch_id: this.timeline_form.branch_id, transaction_type: this.timeline_form.transaction_type, start_date: this.timeline_form.start_date, end_date: this.timeline_form.end_date } });
    },
  },
};
</script>
