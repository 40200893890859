<template>
  <div class="customer">
    <legend class="d-flex justify-content-center flex-wrap">
      <span class="d-flex align-items-center">Settings</span>
    </legend>
    <div class="settings">
      <div v-if="$gate.isAgentAdmin()" class="p-0 m-0">
        <div class="font-weight-bold text-muted mt-1 pb-2">Agency</div>
        <div class="card mb-3">
          <a
            href="#"
            class="card-body border-bottom"
            data-toggle="modal"
            data-target="#editAgencyModal"
          >
            <div class="in">
              <div>Edit Agency Profile</div>
              <span>></span>
            </div>
          </a>
        </div>
      </div>

      <div class="font-weight-bold text-muted mt-1 pb-2">Profile Settings</div>
      <div class="card mb-3">
        <router-link to="change-username" class="card-body border-bottom">
          <div class="in">
            <div>Change Username</div>
            <span>></span>
          </div>
        </router-link>
        <router-link
          v-if="!getUserProfile.is_mrz_verified"
          to="mrz-registration"
          class="card-body border-bottom"
        >
          <div class="in">
            <div>MRZ verification</div>
            <span>></span>
          </div>
        </router-link>
        <a
          v-if="
            ($gate.isMerchantAdmin() || $gate.isCustomer()) &&
            getUserProfile.phone_number !== null &&
            getUserProfile.verified_at === null
          "
          href="sms-verification"
          class="card-body border-bottom"
        >
          <div class="in">
            <div>Verify Phone Number</div>
            <span>></span>
          </div>
        </a>
      </div>

      <div class="font-weight-bold text-muted mt-1 pb-2">Security</div>
      <div class="card mb-3">
        <router-link to="/change-password" class="card-body border-bottom">
          <div class="in">
            <div>Update Password</div>
            <span>></span>
          </div>
        </router-link>

        <router-link
          v-if="!$gate.isBroker() && $gate.isRegistrationComplete()"
          to="/change-pin"
          class="card-body border-bottom"
        >
          <div class="in">
            <div>Update PIN</div>
            <span>></span>
          </div>
        </router-link>

        <div class="card-body border-bottom">
          <div class="in">
            <div style="cursor: pointer" @click="resetPin()">Reset PIN</div>
            <span>></span>
          </div>
        </div>
      </div>
    </div>

    <Send
      v-if="$gate.isCustomer() || $gate.isStore()"
      :getUserProfile="getUserProfile"
    />
    <Footer
      v-if="$gate.isCustomer() || $gate.isStore()"
      :getUserProfile="getUserProfile"
    />
    <AgencyFooter
      v-else-if="$gate.isAgency()"
      :getUserProfile="getUserProfile"
    />

    <!-- ////////// modal //////////// -->
    <div
      class="modal fade"
      id="editAgencyModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="editAgencyModalTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <form @submit.prevent="editAgency" method="post">
            <div class="modal-header border-0">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="d-flex justify-content-center">
                <h2 class="swal2-title" id="exampleModalLongTitle">
                  Edit Agency
                </h2>
              </div>
              <form @submit.prevent="editAgency" class="form-horizontal">
                <div class="card-body">
                  <div class="form-group">
                    <div
                      class="text-danger"
                      v-if="edit_agency_form.errors.has('company_name')"
                      v-html="edit_agency_form.errors.get('company_name')"
                    />

                    <label for="inputEmail3">Company Name</label>
                    <input
                      type="text"
                      class="form-control"
                      id="inputEmail3"
                      v-model="edit_agency_form.company_name"
                    />
                  </div>
                </div>
                <!-- /.card-body -->
              </form>
            </div>
            <div class="modal-footer border-0 d-flex justify-content-center">
              <button type="submit" class="btn custom-button-2">
                Save Changes
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Settings",
  components: {},
  data() {
    return {
      edit_agency_form: new Form({
        company_id: 0,
        company_name: "",
        picture: null,
      }),
    };
  },

  watch: {
    getUserProfile: function () {
      this.edit_agency_form.company_name = this.getUserProfile.agency_name;
      this.edit_agency_form.company_id = this.getUserProfile.agency;

      if (this.$gate.isCustomer() || this.$gate.isMerchant()) {
        document.getElementById("bladeAmount").innerHTML =
          this.dalasisFormatter(this.getUserProfile.balance);
        if (this.$gate.isStore()) {
          document.getElementById("bladeCompanyName").innerHTML =
            this.getUserProfile.company_name;
        }
      }
    },
  },

  mounted() {
    this.$store.dispatch("userProfile");
  },

  created() {
    console.log("gate", this.$gate.isAgentAdmin());
  },

  computed: {
    getUserProfile() {
      //final output from here
      return this.$store.getters.getUserProfile;
    },
  },

  methods: {
    resetPin(id) {
      const confirmBox1 = Swal.mixin({
        customClass: {
          cancelButton: "btn custom-button-2 mx-2",
          confirmButton: "btn custom-button bg-danger mx-2",
        },
        buttonsStyling: false,
      });

      confirmBox1
        .fire({
          title: "Are you sure?",
          text: "You'll be resetting you pin ",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, reset it!",
        })
        .then((result1) => {
          if (result1.isConfirmed) {
            const box1 = Swal.mixin({
              customClass: {
                confirmButton: "btn custom-button-2 mx-2",
                denyButton: "btn custom-button bg-secondary mx-2",
              },
              buttonsStyling: false,
            });

            box1
              .fire({
                title: "Enter your password",
                input: "password",
                inputAttributes: {
                  pattern: "[0-9]+",
                  required: true,
                },
                inputValidator: (value) => {
                  if (!value) {
                    return "You need to enter your password!";
                  }
                },
                showDenyButton: true,
                denyButtonText: `Back`,
                confirmButtonText: "Submit",
              })
              .then((result) => {
                if (result.isConfirmed) {
                  this.$isLoading(true);

                  let resetForm = new FormData();
                  resetForm.append("password", result.value);

                  axios
                    .post("api/profile/resetPin", resetForm, {
                      headers: {
                        "Content-Type": "application/json",
                      },
                    })
                    .then((data) => {
                      this.$isLoading(false);

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button-2",
                        },
                        buttonsStyling: false,
                      });

                      confirmBox2.fire({
                        icon: "success",
                        title: "Reset!",
                        text: "Pin Reset! ... ",
                      });
                      // .then((res) => {
                      //     // reloading the page
                      //     // window.location.href = '/agents';
                      //     this.getResults();
                      // });
                    })
                    .catch((err) => {
                      this.$isLoading(false);
                      // show them if that city name is taken
                      let errorText = "Pin Reset Unsuccesful";

                      if (err.response.data.errors["password"]) {
                        errorText = err.response.data.errors["password"];
                      } else if (err.response.data.errors["general"]) {
                        errorText = err.response.data.errors["general"];
                      }

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button bg-danger",
                        },
                        buttonsStyling: true,
                      });

                      confirmBox2.fire({
                        icon: "error",
                        title: "Failed!",
                        text: errorText,
                      });
                    });
                } else if (result.isDenied) {
                }
              });
          }
        });
    },

    editAgency() {
      // console.log(this.getUserProfile.agency_name);
      $("#editAgencyModal").modal("toggle");

      const confirmBox1 = Swal.mixin({
        customClass: {
          confirmButton: "btn custom-button bg-danger mr-2",
          cancelButton: "btn custom-button-2 ml-2",
        },
        buttonsStyling: false,
      });

      confirmBox1
        .fire({
          title: "Are you sure?",
          text: "Your changes will be saved and the agency will be affected",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, save the changes!",
        })
        .then((result1) => {
          if (result1.isConfirmed) {
            // prompt for pin
            const box1 = Swal.mixin({
              customClass: {
                confirmButton: "btn custom-button bg-danger  mr-2",
                cancelButton: "btn custom-button-2 ml-2",
              },
              buttonsStyling: false,
            });

            box1
              .fire({
                title: "Enter your password",
                text: "To confirm your changes",
                input: "password",
                inputAttributes: {
                  required: true,
                },
                inputValidator: (value) => {
                  if (!value) {
                    return "You need to enter your password!";
                  }
                },
                showCancelButton: true,
                confirmButtonText: "Submit",
                showLoaderOnConfirm: true,
              })
              .then((result) => {
                if (result.isConfirmed) {
                  this.edit_agency_form.password = result.value;
                  this.$isLoading(true);

                  axios
                    .post("api/agency/editAgency", this.edit_agency_form, {
                      headers: { "Content-Type": "application/json" },
                    })
                    .then((data) => {
                      this.$isLoading(false);

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button-2",
                        },
                        buttonsStyling: false,
                      });

                      confirmBox2
                        .fire({
                          icon: "success",
                          title: "Agency changes saved!",
                        })
                        .then((res) => {
                          window.location.reload();
                        });
                    })
                    .catch((err) => {
                      this.$isLoading(false);

                      // show them if that city name is taken
                      let errorText = "Agency changes Unsuccessful";

                      if (err.response.data.errors["password"]) {
                        errorText = err.response.data.errors["password"];
                        const confirmBox2 = Swal.mixin({
                          customClass: {
                            confirmButton: "btn custom-button bg-danger",
                          },
                          buttonsStyling: true,
                        });

                        confirmBox2.fire({
                          icon: "error",
                          title: "Failed!",
                          text: errorText,
                        });
                      } else {
                        $("#editAgencyModal").modal("toggle");
                      }
                    });
                }
              });
          }
        });
    },
  },
};
</script>
