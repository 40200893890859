<template>
    <div>

        <div class="card  custom-card-1 m-0">
            <fieldset>
                <legend class="d-flex justify-content-between flex-wrap">
                    <span>{{legendTitle}}</span>

                </legend>
                    <div class="card-header">
                    </div>


            <div>

                <TabNav :tabs="tabs" :selected="selected" @selected="setSelected">
                    <Tab :isSelected="selected === 'General' ">
                        
                        <div class="row mt-5">
                            <div class="col-8 pr-5">
                                <div class="border-bottom pb-3"><h5>Profile</h5></div>
                                <div class="row py-3 border-bottom">
                                    <div class="col-6 ">
                                        First Name
                                    </div>
                                    <div class="col">
                                        {{userInfo.first_name}}
                                    </div>
                                </div>
                                <div class="row py-3 border-bottom">
                                    <div class="col-6 ">
                                        Last Name
                                    </div>
                                    <div class="col">
                                        {{userInfo.last_name}}
                                    </div>
                                </div>

                                <div class="row py-3 border-bottom">
                                    <div class="col-6 ">
                                        Username
                                    </div>
                                    <div class="col">
                                        {{userInfo.username}}
                                    </div>
                                </div>

                                <div class="row py-3 border-bottom">
                                    <div class="col-6 ">
                                        Email
                                    </div>
                                    <div class="col">
                                        {{userInfo.email}}
                                    </div>
                                </div>
                                <div class="row py-3 border-bottom">
                                    <div class="col-6 ">
                                        Address
                                    </div>
                                    <div class="col">
                                        {{userInfo.town_name}}
                                    </div>
                                </div>
                                <div class="row py-3 border-bottom">
                                    <div class="col-6 ">
                                        Phone Number(s)
                                    </div>
                                    <div class="col">
                                       
                                    </div>
                                </div>
                                <div class="row py-3 border-bottom">
                                    <div class="col-6 ">
                                        <strong>Company branch</strong>
                                    </div>
                                    <div class="col">
                                        <strong>{{userInfo.branch}}</strong>
                                    </div>
                                </div>
                                <div class="row py-3 border-bottom">
                                    <div class="col-6 ">
                                        Pin
                                    </div>
                                    <div class="col">
                                       {{userInfo.pin}}
                                    </div>
                                </div>


                            </div>

                            <div class="col-4">
                                <div class="border-bottom pb-3 mb-3"><h5>Avatar</h5></div>
                                <div class="image">
                                    <img class="img-fluid pad" src="/images/profiles/profile-none.png" alt="">
                                </div>
                                <!-- <div v-if="canEdit" class="btn btn-info w-100">
                                    Change profile picture
                                </div> -->
                            </div>
                        </div>
                    </Tab>

                    <Tab :isSelected="selected === 'Logs' ">
                    </Tab>

                    <Tab v-if="$gate.isAgentAdmin()" :isSelected="selected === 'Bank Accounts' ">
                        bban
                    </Tab>

                    <Tab v-if="canEdit" :isSelected="selected === 'Change Password' ">
                        change
                    </Tab>

                </TabNav>

            </div>



            </fieldset>







            
        </div>


    </div>
</template>


<script>
import TabNav from './tabNav'
import Tab from './tabs'

export default {
    name: 'AgentProfile',
    props:{
       legendTitle: String,
       tabs: Array,
       userInfo: Object,
       canEdit: Boolean
    },
  components: {
        TabNav: () => import('./tabNav' /* webpackChunkName: "js/tab-nav" */),
        Tab: () => import('./tabs' /* webpackChunkName: "js/tabs" */),
  },
  data() {
    return {
        selected: 'General',
        logs: [],
        pageData: {},

    }
  },
  created(){


  },  
    watch: {

        selected: function(){
            
            if(this.selected == 'Logs')
                this.getLogs();


        },

    },
  methods:{
        setSelected(tab){
            this.selected = tab;

        },

        getLogs(page){
            if (typeof page === 'undefined') {
            page = 1;
            }

            axios.get('api/agent/logs?page=' + page)
                .then(({data}) => {


                }).catch((err)=>{

                    
            })
        },


  },

}
</script>