<template>
    <div class="w-80">
        <div class="card-header my-4">
            <form v-if="data_length || isFound" @submit.prevent='sendSearch' method="post">
                <div class="input-group">
                    <select @change="CheckSelected" required v-model="form.search_by" class="bg-lightgray text-emerald font-weight-bold">
                        <option value="" disabled selected>Search by</option>
                        <option v-for="row in filters" :key="row.id" :value="row.value">{{row.text}}</option>

                    </select>
                    <div v-if="date == 'date'" class="d-flex justify-content-between w-60">
                        <Datepicker  input-class="form-control form-control-lg" wrapper-class="inline" placeholder="From date" format="dd/MM/yyyy" :clear-button="true" v-model='form.fromdate'></Datepicker>
			            <Datepicker  input-class="form-control form-control-lg" wrapper-class="inline" placeholder="To date" format="dd/MM/yyyy" :clear-button="true" v-model='form.todate'  ></Datepicker>
                    </div>
                    <select v-else-if="statuss=='status'" required v-model="form.search_text" class="form-control form-control-lg ">
                        <option value="" disabled selected>Select Status</option>
                        <option v-for="row in status" :key="row.id" :value="row.value">{{row.text}}</option>

                    </select>
                    <input v-else type="search" class="form-control form-control-lg"
                    :disabled='form.search_by == ""'
                     v-model="form.search_text" placeholder="Search">
                     


                    <div class="input-group-append">
                        <button :disabled='form.search_by == ""' type="submit" class="btn btn-navbar text-navy">
                            <i class="fa fa-search"></i>
                        </button>
                    </div>
                </div>
            </form>
        </div>

        <div class="mt-3" v-if="isFound && form.search_by != '' && form.search_text != ''">
            <label>Showing search results for '{{form.search_text}}'</label>
        </div>
    </div>

</template>


<script>
import Datepicker from 'vuejs-datepicker';
export default {
    name: 'SearchFilter',
    props:{
        data_length: Number,
        filters: Array,
        status: Array,
        data_type: String,
        isFound: Boolean

    },
    components: {
        Datepicker,
    },
  data() {
    return {
        date: '',
        statuss: '',
        form: new Form({

            search_by : '',
            search_text: '',
            fromdate: '',
            todate: '',
            data_type: '',
            company_id: null
        }),

    }
  },
  created(){
      // fetch fee table


  },
  methods:{
    CheckSelected(){
        console.log(this.form.search_by);
      if(this.form.search_by =='date'){
        this.date=  this.form.search_by
      this.form.search_text ='';
      this.statuss= '';
     }else if(this.form.search_by =='status'){
        this.statuss=  this.form.search_by
        this.form.search_text ='';
        this.date= '';
        this.form.fromdate='';
        this.form.todate='';
      }else{
        this.statuss= '';
       this.date= '';
        this.form.fromdate='';
        this.form.todate='';
      }  
     
    },

      sendSearch(){
        this.form.data_type = this.data_type;
        this.$emit('search-form', this.form);
      }
  }
}
</script>

<style scoped>
select{
    border: none;
}
.w-60{
width:76%;
}
</style>
<style >
.vdp-datepicker  div:first-child{
    display: flex;
    align-items: center;
}
.vdp-datepicker__clear-button{
    padding: 10px;
}

</style>
