<template>
    



    <div v-if="$gate.isRegistrationComplete() && $gate.isCustomer() ">
        <div
            v-if="
                getUserProfile.phone_number !== null &&
                getUserProfile.verified_at === null
            "
        >
            <div class="card px-4 py-2 border-0" style="box-shadow: none">
                <div class="card-body text-center">
                    <a
                        href="sms-verification"
                        style="text-decoration: underline"
                        class="card-body border-bottom"
                    >
                        <div class="in">
                            <div>Click here to Verify Phone Number</div>
                        </div>
                    </a>
                </div>
            </div>
        </div>


        <div class="customer general"
          v-else-if="$gate.isRegistrationComplete() && $gate.canTransact()">

            <div class="card w-100 custom-card-1 m-0 bg-white">
            <fieldset>

                <div class="card-body">
            
                    <form   @submit.prevent="send" method="post">

                        <div class="row px-md-5">

                            <div class=" col-12 pt d-flex justify-content-center">
                                <h2 class="swal2-title" id="exampleModalLongTitle">Deposite to Bank Account</h2>
                            </div>

                    
                            <div v-if="bank_accounts.length > 0" class="col-md-7 pt-4 m-auto">
                                <!-- show available agencies -->

                                <label class="pt-4">Choose Bank Account <span class="text-danger">*</span></label><br/>
                                <div v-show="loading" class="loader-1 text-ogreen1"></div>
                                <div v-if="bank_accounts.length > 0">
                                <div v-for="row in bank_accounts" :key="row.id" class="form-check boxx px-3 pb-3">
                                    <label class="ml-4 form-check-label" :for="'radio' + row.id">
                                        <input required type="radio" class="form-check-input" name="banks" :id="'radio' + row.id" v-model="form.account_id" :value="row.id"  checked>
                                            <span class="text-md font-weight-bold text-bgreen">{{row.bank_name}}</span> &nbsp;/&nbsp;
                                            <span class="text-md text-bgreen">{{ row.account_holder_name }}</span> &nbsp;/&nbsp;
                                            <span class="text-md text-bgreen">{{ row.account_no }}</span>
                                    </label>
                                    <a href="#" @click="removeBank(row.id)" class="text-danger"> <i class="fa fa-trash"></i></a>
                                    </div>


                                </div>

                            
                            

                            </div>
                            <div class="col-12 pt-4 text-center">
                            <router-link class="m-auto" to="/save-bank"><i class="fa fa-plus"></i> Add new bank account</router-link>
                            </div>

                        
                            
                            <div class="col-md-7 pt-4 m-auto">
                        
                            <label>Deposite Amount <span class="text-danger">*</span></label>
                            <input required type="number" name="amount" v-model="form.amount" class="form-control" placeholder="Enter Deposite Amount" />
                            <p class="text-bgreen" v-if="form.amount>0 && getUserProfile.user_type == 4" >{{"The Transaction fee is D" + this.fee.toFixed(2)}} </p>
                            
                            </div>
                            
                            </div>
                    
                        
                        <div v-if="!loading" class="modal-footer mt-3 border-0 d-flex justify-content-center">
                            <button
                            :disabled='!noErrors'
                            type="submit" class="btn custom-button-2 ">Send</button>
                        </div>
                    </form>
                </div>

                



            </fieldset>

            </div>
        </div>

    </div>
    <div v-else>
       <not-found></not-found>

    </div>
</template>



<script>


export default {
  name: 'BankDeposite',

  data() {
    return {
    bank_accounts:[],
    fee: 0,
    amount_error:"",
    form: new Form({
        account_id: '',
        amount:'',
        
      }),
    loading: false,  
    }
  },
  watch:{
    "form.amount": function (val) {
        this.amount_error = "";
             /// call the api end point to know the fee
             axios.get("/api/bank-deposite-withdraw-fee", {
                  headers: { "Content-Type": "application/json" },
                })
                .then((response) => {
                    if(response.data.deposite_fee >0){

                        this.fee = (this.form.amount*response.data.deposite_fee)/100;
                    }
                  
                });

        

      },
  },
  mounted() {
        this.$store.dispatch("userProfile");
    },
  computed: {
      noErrors(){
            return this.eChecks()
        },
        getUserProfile() {
            //final output from here
            return this.$store.getters.getUserProfile;
        },
},


  created(){
    

      // fetch bank account
        this.$isLoading(true) 

            axios.get('api/customer-bank-accounts')
                .then(({data}) => {
                  this.bank_accounts = data;
                    this.$isLoading(false) 

                }).catch((err)=>{
                   
                    this.$isLoading(false) 
                    
                })

  },
  
  methods:{

    eChecks(){
        if(this.form.account_id !="" && this.form.amount !="" ){
           
            return true
        }else{
        
            return false
        }
    

      
     },

    removeBank(bank_id){

const box1 = Swal.mixin({
    customClass: {
        confirmButton: 'btn custom-button-2 mr-2',
        denyButton: 'btn custom-button bg-secondary ml-2'
    },
    buttonsStyling: false
    })

    box1.fire({
        title: 'Enter your pin',
        text: 'To confirm that you want to delete bank details',
        input: 'password',
        inputAttributes: {
            required: true,

        },
        inputValidator: (value) => {
            if (!value) {
            return 'You need to enter your pin!'
            }

        },
        showDenyButton: true,
        denyButtonText: `Back`,
        confirmButtonText: 'Submit',
        }).then((result) => {
        if (result.isConfirmed) {

         this.form.pin = result.value
         this.form.bank_id = bank_id
        this.$isLoading(true)
        this.form.post('/api/delete-bank-account',{headers:{"Content-Type":"application/json"}})
                                    .then((data) => {
                                    this.$isLoading(false)

                                        const confirmBox2 = Swal.mixin({
                                        customClass: {
                                            confirmButton: 'btn custom-button-2',

                                        },
                                        buttonsStyling: false
                                        })


                                        confirmBox2.fire({
                                        icon: 'success',
                                        title: 'Bank Details!',
                                        text: 'Bank Details Deleted Successfully!'

                                        }).then((res)=>{
                                            // redirect user to dashboard
                                        window.location.href = '/bank-deposite';
                                    // location.reload();

                                        })



                                    })
                                    .catch((err) => {
                                    this.$isLoading(false)

                                    let errorText = 'Bank Details Deletetion Unsuccessful'

                                    if(err.response.data.errors['password']){
                                        errorText = err.response.data.errors['password']
                                    }
                                    else if(err.response.data.errors['general']){
                                        errorText = err.response.data.errors['general']
                                    }


                                        const confirmBox2 = Swal.mixin({
                                        customClass: {
                                            confirmButton: 'btn custom-button bg-danger',

                                        },
                                        buttonsStyling: true
                                        })

                                        confirmBox2.fire({
                                        icon: 'error',
                                        title: 'Failed!',
                                        text: errorText,

                                        })

                                    });
         


        }
        })


},
     
    send() {

const box1 = Swal.mixin({
    customClass: {
        confirmButton: 'btn custom-button-2 mr-2',
        denyButton: 'btn custom-button bg-secondary ml-2'
    },
    buttonsStyling: false
    })

    box1.fire({
        title: 'Enter your pin',
        text: 'To confirm that you want to deposite money to bank',
        input: 'password',
        inputAttributes: {
            required: true,

        },
        inputValidator: (value) => {
            if (!value) {
            return 'You need to enter your pin!'
            }

        },
        showDenyButton: true,
        denyButtonText: `Back`,
        confirmButtonText: 'Submit',
        }).then((result) => {
        if (result.isConfirmed) {

         this.form.pin = result.value


          this.$isLoading(true)
          this.form.post('/api/sendOTP',{headers:{"Content-Type":"application/json"}})
                .then((data) => {
                this.$isLoading(false)
                
                const box1 = Swal.mixin({
                    customClass: {
                        confirmButton: 'btn custom-button-2 mr-2',
                        denyButton: 'btn custom-button bg-secondary ml-2'
                    },
                    buttonsStyling: false
                    })

                    box1.fire({
                        title: 'Enter OTP which sent on your phone number.',
                        text: 'To confirm that you want to deposite money to bank',
                        input: 'text',
                        inputAttributes: {
                            required: true,

                        },
                        inputValidator: (value) => {
                            if (!value) {
                            return 'You need to enter your OTP!'
                            }

                        },
                        showDenyButton: true,
                        denyButtonText: `Back`,
                        confirmButtonText: 'Submit',
                        }).then((result) => {
                        if (result.isConfirmed) {
                            this.form.otp = result.value
                            this.form.post('/api/verifyOTP',{headers:{"Content-Type":"application/json"}})
                                .then((data) => {
                                this.$isLoading(false)

                                if(data.success==true){
                                    this.form.post('/api/customer-save-bank-accounts',{headers:{"Content-Type":"application/json"}})
                                    .then((data) => {
                                    this.$isLoading(false)

                                        const confirmBox2 = Swal.mixin({
                                        customClass: {
                                            confirmButton: 'btn custom-button-2',

                                        },
                                        buttonsStyling: false
                                        })


                                        confirmBox2.fire({
                                        icon: 'success',
                                        title: 'Bank Details!',
                                        text: 'Bank Details saved successful!'

                                        }).then((res)=>{
                                            // redirect user to dashboard
                                        window.location.href = '/bank-deposite';
                                    // location.reload();

                                        })



                                    })
                                    .catch((err) => {
                                    this.$isLoading(false)

                                    let errorText = 'Bank Details saved Unsuccessful'

                                    if(err.response.data.errors['password']){
                                        errorText = err.response.data.errors['password']
                                    }
                                    else if(err.response.data.errors['general']){
                                        errorText = err.response.data.errors['general']
                                    }


                                        const confirmBox2 = Swal.mixin({
                                        customClass: {
                                            confirmButton: 'btn custom-button bg-danger',

                                        },
                                        buttonsStyling: true
                                        })

                                        confirmBox2.fire({
                                        icon: 'error',
                                        title: 'Failed!',
                                        text: errorText,

                                        })

                                    });
                                }else{

                                    const confirmBox2 = Swal.mixin({
                                    customClass: {
                                        confirmButton: 'btn custom-button bg-danger',

                                    },
                                    buttonsStyling: true
                                    })

                                    confirmBox2.fire({
                                    icon: 'error',
                                    title: 'Failed!',
                                    text: errorText,

                                    })
                                    
                                }

                               


                                })
                                .catch((err) => {
                                this.$isLoading(false)

                                let errorText = 'OTP verification failed'

                                if(err.response.data.errors['password']){
                                    errorText = err.response.data.errors['password']
                                }
                                else if(err.response.data.errors['general']){
                                    errorText = err.response.data.errors['general']
                                }


                                    const confirmBox2 = Swal.mixin({
                                    customClass: {
                                        confirmButton: 'btn custom-button bg-danger',

                                    },
                                    buttonsStyling: true
                                    })

                                    confirmBox2.fire({
                                    icon: 'error',
                                    title: 'Failed!',
                                    text: data.message,

                                    })

                                });

                        }
                        })
                   



                }).catch((err) => {
                this.$isLoading(false)

                let errorText = 'OTP process failed!'

                if(err.response.data.errors['password']){
                    errorText = err.response.data.errors['password']
                }
                else if(err.response.data.errors['general']){
                    errorText = err.response.data.errors['general']
                }


                    const confirmBox2 = Swal.mixin({
                    customClass: {
                        confirmButton: 'btn custom-button bg-danger',

                    },
                    buttonsStyling: true
                    })

                    confirmBox2.fire({
                    icon: 'error',
                    title: 'Failed!',
                    text: errorText,

                    })

                });






            


        }
        })


},
 



  }

 

}
</script>

<style scoped>

.transaction_type a:hover{

    background: aliceblue;

}

/* .withdraw > span{
    width: 100% !important;
} */

.select2-container {
    width: 100% !important;
}

</style>
