<template>
    <div>
        <div v-if="$gate.isBroker()" class="card  custom-card-1 m-0">
            <fieldset>
                <legend class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center">
                     <span>Currencies Management</span>

                    </div>
                   <router-link to="/create-currency" class="nav-link" active-class="active" exact>
                       <button class="btn btn-outline-info">Create new Currency <i class="fa fa-plus"></i></button>
                    </router-link>
                </legend>

                <div class="card-header">
                </div>


            <div>


            </div>




            <div class="card-body mt-2 table-responsive">
                <table  id="example2" class="table table-hover border-top">
                    <thead >
                    <tr class="text-center">
                    <th>Sr No.</th>
                    <th>Currency Name</th>
                    <th>Currency Sign</th>
                    <th>Price</th>
                    <th class="border-left">Action</th>
                    </tr>
                    </thead>
                    <tbody v-if="currencies.length > 0">
                    <tr class='text-center' v-for='(row, index) in currencies' :key='index'>
                        <td>{{ index + 1 }}</td>
                        <td>{{row.currency_name}}
                           

                        </td>
                        <td>{{row.currency_sign}}</td>
                        <td>{{row.currency_price}}</td>
                   
                        


                        <td class="d-flex flex-wrap border-left justify-content-around">
                           
                            
                            <router-link v-bind:to="'/edit-currency/' + row.id"
                            active-class="active" exact class="btn mb-4 custom-button-purple1" >
                                <i class="fas fa-edit"></i>
                            </router-link>
                         



                        </td>



                    </tr>
                    <tfoot>


                    </tfoot>
                    </tbody>
                    <tbody  v-else>
                                    <tr class='text-center'>
                                    
                                        <td></td>
                                        <td></td>
                                        <td><span><i>You have no currencies.</i></span></td>
                                        <td></td>
                                        <td></td>
                                        
                                    </tr>
                    
                                </tbody>
                </table>
                <div class="pt-3">
                    <pagination align="center" :data="pageData" @pagination-change-page="getResults"></pagination>

                </div>

            </div>


            </fieldset>








        </div>
        <div v-else>
            <not-found></not-found>

        </div>
    </div>
</template>

<script>

export default {
    name: 'currencies',
    props:{

    },
  components: {

  },
  data() {
    return {
        searchBy : '',
        searchText: '',

        form: null,

        currencies: [],
        pageData: {}
    }
  },
  created(){
      // fetch fee table
      if(this.$gate.isBroker()){
            this.getResults()

        }

  },
    watch: {


    },
  methods:{

        getResults(page){
            if (typeof page === 'undefined') {
            page = 1;
            }

            this.$isLoading(true)


            axios.get('api/broker/currencies?page=' + page)
                .then(({data}) => {
                  console.log("currencies", data)
                  this.currencies = data.data;
                  console.log(this.currencies.length);
                  this.pageData = data
                    this.$isLoading(false)


                }).catch((err)=>{
                  this.currencies = []
                  this.pageData = {}
                this.$isLoading(false)


            })

        },





        

  },

}
</script>
