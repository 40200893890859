<template>
    <div>
        <div v-if="$gate.isBroker()" class="card  custom-card-1 m-0">
            <fieldset>
                <legend class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center">
                     <span>Cash Collectors Management</span>

                    </div>
                   <router-link to="/create-cash-Collectors" class="nav-link" active-class="active" exact>
                       <button class="btn btn-outline-info">Create new Cash Collector <i class="fa fa-plus"></i></button>
                    </router-link>
                </legend>

                <div class="card-header">
               <SearchFilter :data_length='Collectors.length'
                 :filters='filters' data_type="collectors" :isFound='isFound'
                 @search-form='makeSearch'
                 />
                </div>


            <div>

                <!-- <TabNav :tabs="['Approved Collectors', 'Blocked Collectors']" :selected="selected" @selected="setSelected">
                    <Tab :isSelected="selected === 'Approved Collectors' ">

                    </Tab>

                    <Tab :isSelected="selected === 'Blocked Collectors' ">
                    </Tab>

                </TabNav> -->

            </div>




            <div class="card-body mt-2 table-responsive">
                <table  id="example2" class="table table-hover border-top">
                    <thead >
                    <tr class="text-center">
                    <th>User Name</th>
                    <th>Institution Name</th>
                    <th>Email</th>
                     <th>Telephone</th>
                    <!-- <th>Company Registration Info</th> -->
                    <th>Status</th>
                    <th class="border-left">Action</th>
                    </tr>
                    </thead>
                    <tbody v-if="Collectors.length != 0">
                    <tr class='text-center' v-for='(row, index) in Collectors' :key='index'>
                        <td>{{row.username}}</td>
                        <td>{{row.institution_name}}
                            <span v-if="row.is_blocked" >
                                <i class="fas fa-ban text-danger"></i>


                            </span>

                        </td>
                     
                        <td>{{row.email}}</td>
                         <td>{{row.telephone}}</td>

                        <!-- <td>{{row.company_registration_info}}</td> -->
                        <td v-if="row.status==0"><span class="badge badge-warning badge-pill">Under review</span></td>
                        <td v-else-if="row.is_blocked"><span class="badge badge-danger badge-pill">Blocked</span></td>
                        <td v-else><span class="badge badge-success badge-pill">Active</span></td>


                        <td class="d-flex flex-wrap border-left justify-content-around">
                            <button v-if="row.status==0" @click="approve(row.user_id)"  class="btn custom-button mb-4 btn-success" title="approve">
                                <i class="fas fa-check"></i>
                            </button>

                            <router-link v-bind:to="'/view-cash-collectors/' + row.Cid"
                            active-class="active" exact class="btn mb-4 custom-button-purple1" >
                                <i class="fas fa-eye"></i>
                            </router-link>
                            <!-- <button class="btn mb-4 custom-button btn-info">
                                    <i class="fas fa-edit"></i>

                            </button> -->


                            <button v-if="!row.is_blocked" @click="block(row.Cid)"  class="btn custom-button mb-4 btn-danger" title="block">
                                <i class="fas fa-ban"></i>
                            </button>

                            <button v-else  @click="unblock(row.Cid)"   class="btn custom-button mb-4 btn-danger" title="unblock">
                                <i class="fas fa-building"></i>
                            </button>

                        </td>



                    </tr>
                    <tfoot>


                    </tfoot>
                    </tbody>
                    <tbody  v-else>
                                    <tr class='text-center'>
                                        <td></td>
                                        <td></td>
                                        <td><span><i>{{ emptyDataText }}</i></span></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                    
                                </tbody>
                </table>
                <div class="pt-3">
                    <pagination align="center" :data="pageData" @pagination-change-page="getResults"></pagination>

                </div>

            </div>


            </fieldset>








        </div>
        <div v-else>
            <not-found></not-found>

        </div>
    </div>
</template>

<script>
import SearchFilter from './../../includes/SearchFilter'
export default {
    name: 'Collectors',
    props:{

    },
    components: {
   SearchFilter
  },
  data() {
    return {
        searchBy : '',
        searchText: '',
        searchForm: null,
        form: null,
        isFound: false,
        filters: [
            {
                'id': 1,
               'text': 'Name',
               'value': 'name'
            },
            {
                'id': 3,
                'text': 'Email',
                'value': 'email'
            },
            {
                'id': 4,
                'text': 'Phone Number',
                'value': 'phone_number'
            },

        ],
        Collectors: [],
        company: [],
        pageData: {},
        emptyDataText: ''
    }
  },
  created(){
      // fetch fee table
      if(this.$gate.isBroker()){
            this.getResults()

        }

  },
    watch: {


    },
  methods:{

        getResults(page){
            if (typeof page === 'undefined') {
            page = 1;
            }

            this.$isLoading(true)

            this.emptyDataText = ''

        if(this.searchForm == null || this.searchForm.search_text == ''){

            axios.get('api/broker/collectors?page=' + page)
                .then(({data}) => {
                  console.log("Collectors", data)
                  this.$isLoading(false)
                  if(data.data.length != 0){
                  this.Collectors = data.data;
                  this.pageData = data
                    
                  }else{
                    this.Collectors = [];
                  this.pageData = {}
                  this.emptyDataText = 'You have no cash collectors'
                  }


                }).catch((err)=>{
                  this.Collectors = []
                  this.pageData = {}
                this.$isLoading(false)


            })


        }
        else{
            this.$isLoading(true)
            this.emptyDataText = ''
            this.searchForm.campaign_id= this.campaign_id ? this.campaign_id : "all"
            this.searchForm.post('/api/broker/collector/makeSearch?page=',{headers:{"Content-Type":"application/json"}})
                .then(({data}) => {
                    this.isFound = true

                    this.Collectors = data.data;
                    this.pageData = data
                    this.emptyDataText = 'No search results found...'

                    this.$isLoading(false)

                }).catch((err)=>{
                  this.Collectors = [];
                  this.pageData = {}
                 this.emptyDataText = 'Something went wrong!'

                    this.$isLoading(false)

                })

        }

        },

        makeSearch(data){
            this.searchForm = data
            this.getResults()
            // emit up
        },
        approve(id){

            const confirmBox1 = Swal.mixin({
            customClass: {
                cancelButton: 'btn custom-button-2 mx-2',
                confirmButton: 'btn custom-button bg-danger mx-2'
            },
            buttonsStyling: false
            })

            confirmBox1.fire({
            title: 'Are you sure?',
            text: "You'll be approving this Collector account ",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, approve this Collector!'
            }).then((result1) => {
            if (result1.isConfirmed) {



            const box1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button-2 mx-2',
                denyButton: 'btn custom-button bg-secondary mx-2'
            },
            buttonsStyling: false
            })

            box1.fire({
                title: 'Enter your password',
                input: 'password',
                inputAttributes: {
                    pattern: "[0-9]+",
                    required: true,
                },
                inputValidator: (value) => {
                    if (!value) {
                    return 'You need to enter your password!'
                    }

                },
                showDenyButton: true,
                denyButtonText: `Back`,
                confirmButtonText: 'Submit',
                }).then((result) => {
                if (result.isConfirmed) {

                this.$isLoading(true)

                let del_form =  new FormData();
                del_form.append('user_id', id)
                del_form.append('password', result.value)

                axios.post('api/collector/approve', del_form,{headers:{"Content-Type":"application/json"}})
                    .then((data) => {
                        this.$isLoading(false)

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button-2',

                        },
                        buttonsStyling: false
                        })

                        confirmBox2.fire({
                        icon: 'success',
                        title: 'Approved!',
                        text: 'Collector Approved! ... '  ,

                        }).then((res)=>{
                            // reloading the page
                        // window.location.href = '/agents';
                        this.getResults()
                        })



                    })
                    .catch((err) => {
                        this.$isLoading(false)
                    // show them if that city name is taken
                        let errorText = 'Collector Approving Unsuccessful'

                        if(err.response.data.errors['password']){
                            errorText = err.response.data.errors['password']
                        }
                        else if(err.response.data.errors['general']){
                            errorText = err.response.data.errors['general']
                        }

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button bg-danger',

                        },
                        buttonsStyling: true
                        })

                        confirmBox2.fire({
                        icon: 'error',
                        title: 'Failed!',
                        text: errorText,

                        })
                    });




                }
                else if (result.isDenied) {

                }
                })


            }
            })



            },



        block(id){

            const confirmBox1 = Swal.mixin({
            customClass: {
                cancelButton: 'btn custom-button-2 mx-2',
                confirmButton: 'btn custom-button bg-danger mx-2'
            },
            buttonsStyling: false
            })

            confirmBox1.fire({
            title: 'Are you sure?',
            text: "You'll be blocking this Collector account ",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, block this Collector!'
            }).then((result1) => {
            if (result1.isConfirmed) {



            const box1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button-2 mx-2',
                denyButton: 'btn custom-button bg-secondary mx-2'
            },
            buttonsStyling: false
            })

            box1.fire({
                title: 'Enter your password',
                input: 'password',
                inputAttributes: {
                    pattern: "[0-9]+",
                    required: true,
                },
                inputValidator: (value) => {
                    if (!value) {
                    return 'You need to enter your password!'
                    }

                },
                showDenyButton: true,
                denyButtonText: `Back`,
                confirmButtonText: 'Submit',
                }).then((result) => {
                if (result.isConfirmed) {

                this.$isLoading(true)

                let del_form =  new FormData();
                del_form.append('collector_id', id)
                del_form.append('password', result.value)

                axios.post('api/broker/blockCollector', del_form,{headers:{"Content-Type":"application/json"}})
                    .then((data) => {
                        this.$isLoading(false)

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button-2',

                        },
                        buttonsStyling: false
                        })

                        confirmBox2.fire({
                        icon: 'success',
                        title: 'Blocked!',
                        text: 'Collector Blocked! ... '  ,

                        }).then((res)=>{
                            // reloading the page
                        // window.location.href = '/agents';
                        this.getResults()
                        })



                    })
                    .catch((err) => {
                        this.$isLoading(false)
                       // show them if that city name is taken
                        let errorText = 'Collector Blocking Unsuccessful'

                        if(err.response.data.errors['password']){
                            errorText = err.response.data.errors['password']
                        }
                        else if(err.response.data.errors['general']){
                            errorText = err.response.data.errors['general']
                        }

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button bg-danger',

                        },
                        buttonsStyling: true
                        })

                        confirmBox2.fire({
                        icon: 'error',
                        title: 'Failed!',
                        text: errorText,

                        })
                    });




                }
                else if (result.isDenied) {

                }
                })


            }
            })



        },

        unblock(id){

            const confirmBox1 = Swal.mixin({
            customClass: {
                cancelButton: 'btn custom-button-2 mx-2',
                confirmButton: 'btn custom-button bg-danger mx-2'
            },
            buttonsStyling: false
            })

            confirmBox1.fire({
            title: 'Are you sure?',
            text: "You'll be unblocking this Collector account ",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, unblock this Collector!'
            }).then((result1) => {
            if (result1.isConfirmed) {



            const box1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button-2 mx-2',
                denyButton: 'btn custom-button bg-secondary mx-2'
            },
            buttonsStyling: false
            })

            box1.fire({
                title: 'Enter your password',
                input: 'password',
                inputAttributes: {
                    pattern: "[0-9]+",
                    required: true,
                },
                inputValidator: (value) => {
                    if (!value) {
                    return 'You need to enter your password!'
                    }

                },
                showDenyButton: true,
                denyButtonText: `Back`,
                confirmButtonText: 'Submit',
                }).then((result) => {
                if (result.isConfirmed) {

                this.$isLoading(true)

                let del_form =  new FormData();
                del_form.append('collector_id', id)
                del_form.append('password', result.value)

                axios.post('api/broker/unblockCollector', del_form,{headers:{"Content-Type":"application/json"}})
                    .then((data) => {
                        this.$isLoading(false)

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button-2',

                        },
                        buttonsStyling: false
                        })

                        confirmBox2.fire({
                        icon: 'success',
                        title: 'Unblocked!',
                        text: 'Collector Unblocked! ... '  ,

                        }).then((res)=>{
                            // reloading the page
                        // window.location.href = '/agents';
                        this.getResults()
                        })



                    })
                    .catch((err) => {
                        this.$isLoading(false)
                       // show them if that city name is taken
                        let errorText = 'Collector Blocking Unsuccessful'

                        if(err.response.data.errors['password']){
                            errorText = err.response.data.errors['password']
                        }
                        else if(err.response.data.errors['general']){
                            errorText = err.response.data.errors['general']
                        }

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button bg-danger',

                        },
                        buttonsStyling: true
                        })

                        confirmBox2.fire({
                        icon: 'error',
                        title: 'Failed!',
                        text: errorText,

                        })
                    });




                }
                else if (result.isDenied) {

                }
                })


            }
            })



        },

  },

}
</script>
