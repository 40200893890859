<template>
  <div v-if="$gate.isAgentAdmin()">
    <div class="card custom-card-1 m-0">
      <fieldset>
        <legend class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center">
            <span> Agency Profile</span>
          </div>
          <a
            href="#"
            class="nav-link"
            active-class="active"
            onclick="history.go(-1);return false;"
          >
            <button class="btn bg-nyanza font-weight-bold text-gray">
              Go back
            </button>
          </a>
        </legend>
        <div class="card-header"></div>

        <div>
          <TabNav :tabs="tabs" :selected="selected" @selected="setSelected">
            <Tab :isSelected="selected === 'General'">
              <div class="row mt-5">
                <div class="col-12">
                  <div class="border-bottom pb-3"><h5>General</h5></div>
                </div>
                <div class="col-12 col-lg-4">
                  <div class="border-bottom font-weight-bold py-3 mb-3">
                    Avatar
                  </div>
                  <div class="image text-center">
                    <img
                      class="img-fluid pad"
                      src="/images/profiles/building-none.png"
                      alt=""
                    />
                  </div>
                </div>

                <div class="col-12 col-lg-8 pr-lg-5">
                  <div class="border-bottom font-weight-bold py-3">Profile</div>
                  <div class="row py-3 border-bottom">
                    <div class="col-6">Company name</div>
                    <div class="col-6">
                      {{ companyInfo.company_name }}
                    </div>
                  </div>

                  <div v-if="companyInfo.cash_at_bank">
                    <div class="row py-3 border-bottom">
                      <div class="col-6">CashAtBank</div>
                      <div class="col-6">
                        {{ dalasisFormatter(companyInfo.cash_at_bank) }}
                      </div>
                    </div>
                    <div class="row py-3 border-bottom">
                      <div class="col-6">KashMa Bank Balance</div>
                      <div class="col-6">
                        {{ dalasisFormatter(companyInfo.kashma_bank_balance) }}
                      </div>
                    </div>
                    <div class="row py-3 border-bottom">
                      <div class="col-6">Fee earnings (Money Transfer)</div>
                      <div class="col-6">
                        {{ dalasisFormatter(companyInfo.fee_earnings) }}
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div class="row py-3 border-bottom">
                      <div class="col-6">Broker Balance</div>
                      <div class="col-6">
                        {{ dalasisFormatter(companyInfo.broker_balance) }}
                      </div>
                    </div>
                    <div class="row py-3 border-bottom">
                      <div class="col-6">Cash on hand</div>
                      <div class="col-6">
                        {{ dalasisFormatter(companyInfo.cash_on_hand) }}
                      </div>
                    </div>
                    <div class="row py-3 border-bottom">
                      <div class="col-6">Fee earnings (Money Transfer)</div>
                      <div class="col-6">
                        {{ dalasisFormatter(companyInfo.commission) }}
                      </div>
                    </div>
                  </div>
                  <div class="row py-3 border-bottom">
                    <div class="col-6">Fee Cut</div>
                    <div class="col-6">{{ companyInfo.fee_cut_percent }}%</div>
                  </div>
                  <div class="row py-3 border-bottom">
                    <div class="col-6">Branches</div>
                    <div class="col-6">
                      <div
                        v-for="branch in companyInfo.branches"
                        :key="branch.id"
                      >
                        {{ branch.town_name }}
                      </div>
                    </div>
                  </div>

                  <div class="row py-3 border-bottom">
                    <div class="col-6">Total Agent Admins</div>
                    <div class="col-6">
                      {{ companyInfo.total_agent_admins }}
                    </div>
                  </div>
                  <div class="row py-3 border-bottom">
                    <div class="col-6">Total Agents</div>
                    <div class="col-6">
                      {{ companyInfo.total_agents }}
                    </div>
                  </div>
                  <div class="row py-3 border-bottom">
                    <div class="col-6">Registered on</div>
                    <div class="col-6">
                      {{ companyInfo.time_created }}
                    </div>
                  </div>
                </div>
              </div>
            </Tab>

            <Tab :isSelected="selected === 'Branches'">
              <div class="row mt-5">
                <div class="col-12 pr-5">
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center">
                      <h5>Branches</h5>
                    </div>
                    <router-link
                      v-bind:to="'/create-branch/' + companyInfo.id"
                      href="#"
                      class="nav-link"
                    >
                      <button class="btn btn-outline-info">
                        Create Branches <i class="fa fa-plus"></i>
                      </button>
                    </router-link>
                  </div>

                  <div
                    class="d-flex justify-content-between flex-wrap border-bottom pb-3"
                  >
                    <div class="d-flex align-items-center">
                      <h5></h5>
                    </div>
                  </div>

                  <div
                    v-for="branch in companyInfo.branches"
                    :key="branch.id"
                    class="row py-5 border-bottom"
                  >
                    <div
                      class="col-12 d-flex justify-content-between flex-wrap pb-2"
                    >
                      <h5 class="d-flex align-items-center">
                        <strong>{{ branch.town_name }}</strong>
                      </h5>
                      <div class="d-flex justify-content-end flex-wrap">
                        <a
                          href="#"
                          class="nav-link"
                          v-if="
                            branch.agent_admin_id == null &&
                            companyInfo.total_agent_admins > 0
                          "
                          @click="
                            openModal(branch.id, 'createAgentAdmin', null)
                          "
                        >
                          <button type="button" class="btn btn-sm btn-dark">
                            Create Agent Admin
                          </button>
                        </a>

                        <router-link
                          v-if="
                            branch.agent_admin_id == null &&
                            companyInfo.total_agent_admins == 0
                          "
                          v-bind:to="'/create-agent-admin/' + branch.id"
                          class="nav-link border-right"
                          active-class="active"
                          exact
                        >
                          <button
                            type="button"
                            class="btn btn-sm btn-secondary"
                          >
                            Create Agent Admin <i class="fa fa-plus"></i>
                          </button>
                        </router-link>

                        <router-link
                          v-if="branch.agent_admin_id != null"
                          v-bind:to="'/create-branch-agent/' + branch.id"
                          class="nav-link border-right"
                          active-class="active"
                          exact
                        >
                          <button
                            type="button"
                            class="btn btn-sm btn-outline-secondary"
                          >
                            Create Agent <i class="fa fa-plus"></i>
                          </button>
                        </router-link>
                        <!-- <router-link
                          v-bind:to="
                            '/edit-branch-agent/' +
                            branch.id +
                            '/' +
                            companyInfo.id
                          "
                          class="nav-link"
                          active-class="active"
                          exact
                        >
                          <button type="button" class="btn btn-sm btn-info">
                            Edit Branch <i class="fa fa-edit"></i>
                          </button>
                        </router-link> -->

                        <button
                          v-if="!branch.is_blocked"
                          @click="blockBranch(branch.id)"
                          class="btn custom-button mb-4 btn-danger"
                        >
                          Deactivate Branch
                          <i class="fas fa-ban"></i>
                        </button>

                        <button
                          v-else
                          @click="unblockBranch(branch.id)"
                          class="btn custom-button mb-4 btn-danger"
                        >
                          Activate Branch <i class="fas fa-building"></i>
                        </button>
                      </div>
                    </div>

                    <div class="col-6">
                      <div class="d-flex justify-content-start">
                        <span class="d-flex align-items-center"
                          >Agent Admin:
                        </span>
                        <div
                          class="mx-3 text-bwgreen font-weight-bold"
                          v-if="branch.agent_admin_id != null"
                        >
                          <router-link
                            v-bind:to="
                              '/view-user-profile/' + branch.agent_admin_id
                            "
                          >
                            {{ branch.first_name }} {{ branch.last_name }}
                          </router-link>
                        </div>
                      </div>
                      <div class="pt-2 d-flex justify-content-start">
                        <span class="pr-3">Agents: </span>
                        <div class="d-flex justify-content-between flex-wrap">
                          <span
                            class="pr-3 text-bwgreen"
                            style=""
                            v-for="agent in branch.agents"
                            :key="agent.id"
                          >
                            <router-link
                              v-bind:to="'/view-user-profile/' + agent.id"
                            >
                              {{ agent.first_name }} {{ agent.last_name }}
                            </router-link>
                          </span>
                        </div>
                      </div>
                      <div class="pt-2 d-flex justify-content-start">
                        <span class="">Phone numbers: </span>
                        <div>
                          <ul style="list-style-type: none">
                            <li v-for="ph in branch.phone_numbers" :key="ph.id">
                              <span>-</span> <span>{{ ph.phone_number }} </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div v-if="companyInfo.cash_at_bank" class="col-6">
                      <div class="d-flex justify-content-end">
                        <span class="d-flex align-items-center"
                          >Cash At Bank:
                        </span>
                        <div class="mx-3 font-weight-bold text-navy">
                          <span>{{
                            dalasisFormatter(companyInfo.cash_at_bank)
                          }}</span>
                        </div>
                      </div>
                      <div class="pt-2 d-flex justify-content-end">
                        <span class="pr-3">Fee earnings: </span>
                        <div class="d-flex justify-content-between flex-wrap">
                          <span class="mr-3 font-weight-bold text-navy">
                            {{ dalasisFormatter(companyInfo.fee_earnings) }}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div v-else class="col-6">
                      <div class="d-flex justify-content-end">
                        <span class="d-flex align-items-center"
                          >Cash on Hand:
                        </span>
                        <div class="mx-3 font-weight-bold text-navy">
                          <span>{{
                            dalasisFormatter(branch.cash_on_hand)
                          }}</span>
                        </div>
                      </div>
                      <div class="pt-2 d-flex justify-content-end">
                        <span class="pr-3">Fee earnings: </span>
                        <div class="d-flex justify-content-between flex-wrap">
                          <span class="mr-3 font-weight-bold text-navy">
                            {{ dalasisFormatter(branch.fee_earnings) }}
                          </span>
                        </div>
                      </div>
                    </div>

                    <!-- list  the agents.. make them clickable links-->
                  </div>
                </div>
              </div>
            </Tab>
            <Tab :isSelected="selected === 'Bank Accounts'"> bban </Tab>

            <Tab :isSelected="selected === 'Logs'"> </Tab>
          </TabNav>
        </div>
      </fieldset>
      <div
        class="modal fade"
        id="createAgentAdminModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="createAgentAdminModalTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <form method="post">
              <div class="modal-header border-0 p-0 m-0">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h3 class="swal2-title">Create Agent Admin</h3>

                <div class="d-flex justify-content-between">
                  <div>
                    <a
                      @click="closeModal('new')"
                      class="nav-link"
                      active-class="active"
                      exact
                    >
                      <button type="button" class="btn btn-sm btn-secondary">
                        Create New Agent Admin <i class="fa fa-plus"></i>
                      </button>
                    </a>
                  </div>

                  <div>
                    <a
                      @click="closeModal('old')"
                      class="nav-link"
                      active-class="active"
                      exact
                    >
                      <button
                        type="button"
                        class="btn btn-sm btn-outline-secondary"
                      >
                        Pick From Existing Agent Admins
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="pickAgentAdminModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="pickAgentAdminModalTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <form method="post" @submit.prevent="pickAgentAdmin">
              <div class="modal-header border-0 p-0 m-0">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h3 class="swal2-title">Pick Agent Admin</h3>
                <div class="input-group pt-3">
                  <select
                    v-model="pick_agent_admin_form.agent_admin_id"
                    class="form-control"
                    name=""
                    id=""
                  >
                    <option value="" selected disabled>---</option>
                    <option
                      v-for="agent_admin in companyInfo.agent_admins"
                      :key="agent_admin.id"
                      :value="agent_admin.id"
                    >
                      {{ agent_admin.first_name }} {{ agent_admin.last_name }}
                    </option>
                  </select>
                </div>
                <div
                  class="modal-footer border-0 d-flex justify-content-center"
                >
                  <button
                    :disabled="pick_agent_admin_form.agent_admin_id == ''"
                    type="submit"
                    class="btn custom-button-2"
                  >
                    Assign
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <AgencyFooter :getUserProfile="getUserProfile" />
  </div>
  <div v-else>
    <not-found></not-found>
  </div>
</template>

<script>
import TabNav from "./../includes/tabNav";
import Tab from "./../includes/tabs";

export default {
  name: "AgencyProfile",
  props: {},
  components: {
    TabNav,
    Tab,
  },
  data() {
    return {
      selected: "General",
      logs: [],
      companyInfo: {},
      pageData: {},
      tabs: ["General", "Branches", "Bank Accounts", "Logs"],
      pick_agent_admin_form: new Form({
        agent_admin_id: "",
        branch: "",
      }),
    };
  },
  created() {
    if (this.$gate.isAgentAdmin()) {
      this.tabs = ["General", "Branches", "Bank Accounts", "Logs"];
      this.$isLoading(true);

      axios
        .get("/api/agency")
        .then(({ data }) => {
          this.companyInfo = data;
          console.log("company info ", this.companyInfo);
          console.log(
            "kashma bank balance ",
            this.companyInfo.kashma_bank_balance
          );
          this.$isLoading(false);
        })
        .catch((err) => {
          this.companyInfo = "error";
          this.$isLoading(false);
        });
    }
  },
  watch: {
    selected: function () {},
  },
  methods: {
    openModal(branch_id, modal_type, agent_admin_id) {
      this.choosen_branch = branch_id;

      if (modal_type == "createAgentAdmin") {
        $("#createAgentAdminModal").modal("toggle");
      } else if (modal_type == "changeAgentAdmin") {
        this.pick_agent_admin_form.agent_admin_id = agent_admin_id;

        $("#pickAgentAdminModal").modal("toggle");
      }
    },
    closeModal(admin) {
      $("#createAgentAdminModal").modal("toggle");
      if (admin == "new") {
        window.location.href = "/create-agent-admin/" + this.choosen_branch;
      } else if (admin == "old") {
        $("#pickAgentAdminModal").modal("toggle");
      }
    },

    pickAgentAdmin() {
      $("#pickAgentAdminModal").modal("toggle");

      this.pick_agent_admin_form.branch = this.choosen_branch;
      let branch_name = this.companyInfo.branches.filter(
        (d) => d.id == this.pick_agent_admin_form.branch
      )[0]["town_name"];

      let agent_admin = this.companyInfo.agent_admins.filter(
        (d) => d.id == this.pick_agent_admin_form.agent_admin_id
      )[0];
      const confirmBox1 = Swal.mixin({
        customClass: {
          confirmButton: "btn custom-button bg-danger mr-2",
          cancelButton: "btn custom-button-2 ml-2",
        },
        buttonsStyling: false,
      });

      confirmBox1
        .fire({
          title: "Are you sure?",
          text:
            'You will be assigning "' +
            agent_admin.first_name +
            " " +
            agent_admin.last_name +
            '" to the ' +
            branch_name +
            " branch",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, assign them!",
        })
        .then((result1) => {
          if (result1.isConfirmed) {
            // prompt for pin
            const box1 = Swal.mixin({
              customClass: {
                confirmButton: "btn custom-button bg-danger  mr-2",
                cancelButton: "btn custom-button-2 ml-2",
              },
              buttonsStyling: false,
            });

            box1
              .fire({
                title: "Enter your password",
                text:
                  'To confirm that you want to assign "' +
                  agent_admin.first_name +
                  " " +
                  agent_admin.last_name +
                  '" to the ' +
                  branch_name +
                  " branch",
                input: "password",
                inputAttributes: {
                  required: true,
                },
                inputValidator: (value) => {
                  if (!value) {
                    return "You need to enter your password!";
                  }
                },
                showCancelButton: true,
                confirmButtonText: "Submit",
                showLoaderOnConfirm: true,
              })
              .then((result) => {
                if (result.isConfirmed) {
                  this.pick_agent_admin_form.password = result.value;
                  this.$isLoading(true);

                  this.pick_agent_admin_form
                    .post("/api/broker/pickAgentAdmin", {
                      headers: { "Content-Type": "application/json" },
                    })
                    .then((data) => {
                      this.$isLoading(false);

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button-2",
                        },
                        buttonsStyling: false,
                      });

                      confirmBox2
                        .fire({
                          icon: "success",
                          title: "Agent Admin Assigned!",
                        })
                        .then((res) => {
                          window.location.reload();
                        });
                    })
                    .catch((err) => {
                      this.$isLoading(false);

                      // show them if that city name is taken
                      let errorText = "Agent Admin Assignment Unsuccessful";

                      if (err.response.data.errors["password"]) {
                        errorText = err.response.data.errors["password"];
                      } else if (err.response.data.errors["general"]) {
                        errorText = err.response.data.errors["general"];
                      }

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button bg-danger",
                        },
                        buttonsStyling: true,
                      });

                      confirmBox2.fire({
                        icon: "error",
                        title: "Failed!",
                        text: errorText,
                      });
                    });
                }
              });
          }
        });
    },

    setSelected(tab) {
      this.selected = tab;
    },
    getCompany() {},
  },

  mounted() {
    this.$store.dispatch("userProfile");
  },
  computed: {
    getUserProfile() {
      //final output from here
      return this.$store.getters.getUserProfile;
    },
  },
};
</script>
