<template>
    <div v-if="$gate.isCustomer()">
        <div>
            <legend class="d-flex justify-content-center flex-wrap">
                <span class='d-flex align-items-center'>{{form.column}} Deposits</span>
            </legend>
            <!-- <div class="text-center"><i>work in progress</i> </div> -->
            <div v-show="deposits.length > 0" class="d-flex justify-content-end mt-0 pt-0 mb-3">
                <button class="btn btn-sm btn-default"
                    data-toggle="modal" data-target="#filterByModal"
                    style="border-color: gray;">Filter</button>
            </div>


            <!-- group by date -->
            <div v-for="(data, index1) in deposits" :key="index1" class="pb-3">
                <div class="pb-2 font-weight-bold text-muted">
                    {{data[0].day_key}}
                </div>

                <div class="transactions">
                    <section v-for="(d, index2) in data" :key="index2" class="px-3 pb-4 mb-3" style="position: relative;">
                        <div
                        v-if="d['third_party_merchant_test'] !=null"
                        class="position-absolute test-pay-btn px-3 py-2 mb-0"
                        >
                        Test
                        </div>
                        <p class="py-1 text-secondary" >
                            Deposit ID: <i class="font-weight-bold ">{{d['unique_id']}}</i>
                        </p>
                        <div class="d-flex justify-content-between">
                            <div
                            :class="[d['status'] == 'Incoming' ? 'text-info' :
                            d['status'] == 'Cancelled' ? 'text-danger' :
                            d['status'] == 'Completed' ? 'text-bgreen' : '', 'font-weight-bold text-md d-flex align-items-center']"
                            >

                                <span>{{d['status']}}</span>
                            </div>
                            <div v-if="d.status == 'Incoming' && d.third_party_merchant ==null && d.third_party_merchant_test==null">
                                <button  @click="accept(d['deposit_id'], d['amount'], d['institution_name'])" class="btn btn-sm custom-button-2">Accept</button>

                            </div>

                            <div v-if="d.status == 'Incoming' && d.third_party_merchant !=null">
                                <button  @click="acceptthirdparty(d['deposit_id'], d['amount'], d['username'], d['third_party_merchant'],'live')" class="btn btn-sm custom-button-2">Accept</button>
                                <button  @click="cancelDeposit(d['deposit_id'], d['amount'], d['username'], d['third_party_merchant'],'live')" class="btn btn-sm custom-button bg-danger">Cancel</button>

                            </div>
                            <div v-if="d.status == 'Incoming' && d.third_party_merchant_test !=null">
                                <button  @click="acceptthirdparty(d['deposit_id'], d['amount'], d['username'], d['third_party_merchant_test'],'test')" class="btn btn-sm custom-button-2">Accept</button>
                                <button  @click="cancelDeposit(d['deposit_id'], d['amount'], d['username'], d['third_party_merchant_test'],'test')" class="btn btn-sm custom-button bg-danger">Cancel</button>

                            </div>
                        </div>
                        <div class="item pt-2">
                            <div v-if="d.campaign_id" class="detail">
                                <img
                                :src="'/images/profiles/' + d['picture'] "

                                alt="img" class="image-block imaged w48">
                                <div>

                                    <router-link v-bind:to="'/view-profile/' + d['user_id']">
                                      <strong>{{d['campaign_name']}} - Campaign </strong>
                                      <p>{{d['institution_name']}} - Distributor </p>

                                    </router-link>
                                    <p>{{d['time_updated']}}</p>
                                </div>
                            </div>
                            <div v-else-if="d.third_party_merchant" class="detail">
                                <img
                                :src="'/images/profiles/' + d['picture'] "

                                alt="img" class="image-block imaged w48">
                                <div>

                                    <router-link v-bind:to="'/view-profile/' + d['user_id']">
                                      <strong>{{d['username']}} - Third Party Merchant </strong>
                                      <p>{{d['first_name']}} {{d['last_name']}}- Merchant </p>

                                    </router-link>
                                    <p>{{d['time_updated']}}</p>
                                </div>
                            </div>
                            <div v-else-if="d.third_party_merchant_test" class="detail">
                                <img
                                :src="'/images/profiles/' + d['picture'] "

                                alt="img" class="image-block imaged w48">
                                <div>

                                    <router-link v-bind:to="'/view-profile/' + d['user_id']">
                                      <strong>{{d['username']}} - Third Party Merchant </strong>
                                      <p>{{d['first_name']}} {{d['last_name']}}- Merchant </p>

                                    </router-link>
                                    <p>{{d['time_updated']}}</p>
                                </div>
                            </div>
                            <div v-else class="detail">
                                <img
                                :src="'/images/profiles/' + d['picture'] "

                                alt="img" class="image-block imaged w48">
                                <div>

                                    <router-link v-bind:to="'/view-profile/' + d['other_id']">
                                      <strong>{{d['company_name']}} - {{d['town_name']}} Branch</strong>

                                    </router-link>
                                    <p>{{d['time_updated']}}</p>
                                </div>
                            </div>
                            <div class="right">
                                <div class="price font-weight-bold "
                                :class="[d['status'] == 'Incoming'
                                || d['status'] == 'Completed'
                                ? 'text-emerald' : 'text-red1']"
                                >
                                    <span v-if="d['status'] == 'Incoming'">+</span>
                                    {{dalasisFormatter(d['amount'])}}
                                 </div>
                            </div>
                        </div>

                    </section>

                </div>

            </div>

            <div class="card py-4 text-center"  v-if="deposits.length == 0">
                <span>{{emptyDataText}}
                </span>
            </div>

                <div class="pt-3">
                    <pagination align="center" :data="pageData" @pagination-change-page="getResults"></pagination>

                </div>


            <div class="modal fade" id="filterByModal" tabindex="-1" role="dialog" aria-labelledby="filterByModalTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">


                            <div class="modal-header border-0">
                                <span class="swal2-title" >Filter By</span>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <h5 class="text-muted">Select Column</h5>
                                <div class="form-group ml-3">
                                    <div v-for="(column, index) in filter_column_names" :key='index' class="form-check">
                                        <input class="form-check-input" type="radio" v-model="filter_by.column" :value="column">
                                        <label class="form-check-label" for="exampleRadios1">
                                            {{column}}
                                        </label>
                                    </div>

                                </div>
                                <hr>
                                <h5 class="text-muted">Order By</h5>
                                <div class="form-group ml-3">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" v-model="filter_by.order" value="ascending">
                                        <label class="form-check-label" for="exampleRadios1">
                                            Ascending
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" v-model="filter_by.order" value="descending">
                                        <label class="form-check-label" for="exampleRadios1">
                                            Descending
                                        </label>
                                    </div>
                                </div>

                            </div>
                            <div class="modal-footer border-0 d-flex justify-content-center">
                                <button type="submit" @click="filterData" class="btn custom-button-2 ">Filter</button>
                            </div>

                    </div>
                </div>
            </div>





        </div>



            <Send :getUserProfile='getUserProfile' />
            <Footer :getUserProfile='getUserProfile' />
    </div>
    <div v-else>
            <not-found></not-found>

    </div>
</template>

<script>
import moment from 'moment';
export default {
    name: 'Deposits',
  components: {

  },
  data() {
    return {


        balance: 0,
        timer:false,
        testMode:true,
        hit:false,
        pageData: {},
        deposits: [],
        id:null,
        deposit_id:null,
        pin:null,
        new_balance:0,
        orderPaymentDetails:[],
        searchForm: null,
        isFound: false,
        form: new Form({
            column: 'All',
            order:'descending'
        }),

        emptyDataText: '',
        filter_by: {
            column: 'All',
            order: 'descending'
        },
        filter_column_names: ['All', 'Incoming',
                 'Completed', 'Cancelled'],

    }
  },
  created(){
      if(this.$gate.isCustomer())
        this.getResults()

  },
  watch:{
        getUserProfile: function () {
            if((this.getUserProfile.balance ==0 || this.getUserProfile.balance ==0.00) && this.getUserProfile.used_kashlock_credit > 0){
                this.getUserProfile.balance= -this.getUserProfile.used_kashlock_credit;
            }

            this.balance = this.dalasisFormatter(this.getUserProfile.balance)
            document.getElementById('bladeAmount').innerHTML = this.balance
            document.getElementById('bladeCreditAmount').innerHTML = this.dalasisFormatter(this.getUserProfile.kashlock_credit)
            if(this.getUserProfile.kashlock_credit > 0){
                document.getElementById('bladeCreditBlock').style.display = 'block';
            }

        },

  },

  mounted() {
        this.$store.dispatch("userProfile")


  },
    computed: {



        getUserProfile(){ //final output from here
            return this.$store.getters.getUserProfile
        },

    },
    beforeDestroy() {
      clearInterval(this.interval);
    },
  methods: {
      getResults(page){
        if (typeof page === 'undefined') {
            page = 1;
        }
        this.emptyDataText = ''
        if(this.searchForm == null || this.searchForm.search_text == ''){
                    this.$isLoading(true)

            this.form.post('api/customer/deposits?page=' + page)
                .then(({data}) => {

                if(data.grouped_data != 0){
                  this.deposits = data.grouped_data;
                  this.pageData = data.data
                }else{
                  this.deposits = [];
                  this.pageData = {}
                  this.emptyDataText = 'No deposit record'
                }

                    this.$isLoading(false)


                }).catch((err)=>{
                    this.$isLoading(false)


                })
        }
        else{
                    this.$isLoading(true)
            this.emptyDataText = ''

            axios.post('api/customer/makeSearch?page=',{headers:{"Content-Type":"application/json"}})
                .then(({data}) => {
                    this.isFound = true

                  this.deposits = data.data;
                  this.pageData = data.grouped_data
                    this.emptyDataText = 'No search results found...'

                    this.$isLoading(false)

                }).catch((err)=>{
                  this.deposits = [];
                  this.pageData = {}
                 this.emptyDataText = 'Something went wrong!'

                    this.$isLoading(false)

                })

        }


      },

      accept(id, amount, agency){
        //   get the info from id
            let new_balance = this.dalasisFormatter(
                        parseFloat(this.getUserProfile.balance) + parseFloat(amount)
                        )
            const confirmBox1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button-2 mr-2',
                cancelButton: 'btn custom-button bg-danger ml-2'
            },
            buttonsStyling: false
            })

            confirmBox1.fire({
            title: 'Are you sure?',
            html: "You'll be accepting the <b>" + this.dalasisFormatter(amount) + "</b> deposit from " + agency
            + '<br> Your new balance will be <b> ' + new_balance + '</b>',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, accept it!',
            cancelButtonText: "No, don't!"
            }).then((result1) => {
            if (result1.isConfirmed) {



                // prompt for pin
            const box1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button-2 mr-2',
                cancelButton: 'btn custom-button bg-danger ml-2'
            },
            buttonsStyling: false
            })

            box1.fire({
                title: 'Enter your pin',
                input: 'password',
                inputAttributes: {
                    maxlength: 4,
                    minlength: 4,
                    pattern: "[0-9]+",
                    required: true,
                },
                inputValidator: (value) => {
                    if (!value) {
                    return 'You need to enter your 4 digit pin!'
                    }

                },
                showCancelButton: true,
                confirmButtonText: 'Submit',
                showLoaderOnConfirm: true
                }).then((result) => {
                if (result.isConfirmed) {

                let formData = new Form({
                    deposit_id: id,
                    pin: result.value
                });

                    this.$isLoading(true)

                formData.post('api/customer/acceptAgencyDeposit',{headers:{"Content-Type":"application/json"}})
                    .then((data) => {
                    this.$isLoading(false)

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button-2',

                        },
                        buttonsStyling: false
                        })

                        confirmBox2.fire({
                        icon: 'success',
                        title: 'Accepted!',
                        text: 'Your new balance is ' + new_balance

                        }).then((res)=>{
                            // reloading the page
                        window.location.reload()

                        })



                    })
                    .catch((err) => {
                    this.$isLoading(false)


                        let errorText = 'Operation Unsuccessful'

                        if(err.response.data.errors['pin']){
                            errorText = err.response.data.errors['pin']
                        }
                        else if(err.response.data.errors['general']){
                            errorText = err.response.data.errors['general']
                        }

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button bg-danger',

                        },
                        buttonsStyling: true
                        })

                        confirmBox2.fire({
                        icon: 'error',
                        title: 'Failed!',
                        text: errorText,

                        })
                    });



                }
                })




            }
            })
      },



      acceptthirdparty(id, amount, agency,deposit_id,mode){
        if(mode=='test'){
            this.testMode=true;
        }else{
            this.testMode=false;
        }
        
        //   get the info from id
            let new_balance = this.dalasisFormatter(
                            parseFloat(this.getUserProfile.balance) + parseFloat(amount)
                        )
                        console.log('new_balance',parseFloat(this.getUserProfile.balance));
                        console.log('new_balance', parseFloat(amount));

            const confirmBox1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button-2 mr-2',
                cancelButton: 'btn custom-button bg-danger ml-2'
            },
            buttonsStyling: false
            })

            confirmBox1.fire({
            title: 'Are you sure?',
            html: "You'll be accepting the <b>" + this.dalasisFormatter(amount) + "</b> deposit from " + agency
            + '<br> Your new balance will be <b> ' + new_balance + '</b>',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, accept it!',
            cancelButtonText: "No, don't!"
            }).then((result1) => {
            if (result1.isConfirmed) {
                this.hit= false;


                // prompt for pin
            const box1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button-2 mr-2',
                cancelButton: 'btn custom-button bg-danger ml-2'
            },
            buttonsStyling: false
            })

            box1.fire({
                title: 'Enter your pin',
                input: 'password',
                inputAttributes: {
                    maxlength: 4,
                    minlength: 4,
                    pattern: "[0-9]+",
                    required: true,
                },
                inputValidator: (value) => {
                    if (!value) {
                    return 'You need to enter your 4 digit pin!'
                    }

                },
                showCancelButton: true,
                confirmButtonText: 'Submit',
                showLoaderOnConfirm: true
                }).then((result) => {
                if (result.isConfirmed) {

                let formData = new Form({
                    id: deposit_id,
                    status: 2,
                    test_mode:this.testMode
                });

                    this.$isLoading(true)

                    formData.post('api/third-party-deposit/updateDeposit',{headers:{"Content-Type":"application/json"}})
                    .then((data) => {
                  
                  
                        const orderData = data.data.orderData;
                        console.log('handleResponse',orderData);
                    if (orderData && orderData.pay_status === 2) {
                        console.log('handleResponse');
                            this.handleResponse(orderData,deposit_id, id, result.value,new_balance);
                       
                        }
                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: "btn custom-button bg-danger",
                        },
                        buttonsStyling: true,
                        });
                        if (
                          orderData &&
                          orderData.status === "FAILED" &&
                          message
                        ) {
                          let errorText = "Operation Unsuccessful";
                          confirmBox2.fire({
                            icon: "error",
                            title: "Failed!",
                            text: errorText,
                          });
                          this.$isLoading(false)
                        }
  
                    



                    })
                    .catch((err) => {
                    this.$isLoading(false)


                        let errorText = 'Operation Unsuccessful'

                        if(err.response.data.errors['pin']){
                            errorText = err.response.data.errors['pin']
                        }
                        else if(err.response.data.errors['general']){
                            errorText = err.response.data.errors['general']
                        }

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button bg-danger',

                        },
                        buttonsStyling: true
                        })

                        confirmBox2.fire({
                        icon: 'error',
                        title: 'Failed!',
                        text: errorText,

                        })
                    });


                



                }
                })




            }
            })
      },

      cancelDeposit(id, amount, agency,order_id, mode){
                if(mode=='test'){
                    this.testMode=true;
                }else{
                    this.testMode=false;
                }
     
            const confirmBox1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button-2 mr-2',
                cancelButton: 'btn custom-button bg-danger ml-2'
            },
            buttonsStyling: false
            })

            confirmBox1.fire({
            title: 'Are you sure?',
            html: "You'll be cancelling this request",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, cancel it!',
            cancelButtonText: "No, don't!"
            }).then((result1) => {
            if (result1.isConfirmed) {



                // prompt for pin
            const box1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button-2 mr-2',
                cancelButton: 'btn custom-button bg-danger ml-2'
            },
            buttonsStyling: false
            })

            box1.fire({
                title: 'Enter your pin',
                input: 'password',
                inputAttributes: {
                    maxlength: 4,
                    minlength: 4,
                    pattern: "[0-9]+",
                    required: true,
                },
                inputValidator: (value) => {
                    if (!value) {
                    return 'You need to enter your 4 digit pin!'
                    }

                },
                showCancelButton: true,
                confirmButtonText: 'Submit',
                showLoaderOnConfirm: true
                }).then((result) => {
                if (result.isConfirmed) {

                let formData = new Form({
                    deposit_id: id,
                    order_id:order_id,
                    pin: result.value,
                    test_mode:this.testMode
                });

                    this.$isLoading(true)

                formData.post('api/customer/cancelDeposit',{headers:{"Content-Type":"application/json"}})
                    .then((data) => {
                    this.$isLoading(false)

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button-2',

                        },
                        buttonsStyling: false
                        })

                        confirmBox2.fire({
                        icon: 'success',
                        title: 'Cancelled!',
                        text: 'Request cancelled successfully.'

                        }).then((res)=>{
                            // reloading the page
                        window.location.reload()

                        })



                    })
                    .catch((err) => {
                    this.$isLoading(false)


                        let errorText = 'Operation Unsuccessful'

                        if(err.response.data.errors['pin']){
                            errorText = err.response.data.errors['pin']
                        }
                        else if(err.response.data.errors['general']){
                            errorText = err.response.data.errors['general']
                        }

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button bg-danger',

                        },
                        buttonsStyling: true
                        })

                        confirmBox2.fire({
                        icon: 'error',
                        title: 'Failed!',
                        text: errorText,

                        })
                    });



                }
                })




            }
            })
      },

      countDownTimer() {
        if (this.countDown > 0) {
          setTimeout(() => {
            this.countDown -= 1;
            this.countDownTimer();
          }, 1000);
        }
      },
  
      redirectAfterTenSec() {
        console.log('redirectAfterTenSec'); 
        this.interval = setInterval(() => {
          this.handleRedirect();
        }, 3000);
      },

       // Function to calculate time difference and show timer
    showTimer(oldDate) {
    // Calculate time difference
     oldDate = moment(oldDate);
    let now = moment();
    const diffInSeconds = now.diff(oldDate, 'seconds');
    const targetTimeInSeconds = diffInSeconds + (2 * 60); // 2 minutes in seconds
    this.$isLoading(false);
    // Display timer with SweetAlert
    Swal.fire({
        title: 'Please wait..',
        html: '<div class="text-center"><div id="timer"></div><br/><p>please donot refresh the page.</p></div>',
        timer: (targetTimeInSeconds - diffInSeconds) * 1000, // Convert remaining time to milliseconds
        timerProgressBar: true,
        allowOutsideClick: false, // Prevent closing by clicking outside
        allowEscapeKey: false, // Prevent closing by pressing Escape key
        showConfirmButton: false, // Remove confirm button
         showCancelButton: false, // Remove cancel button
        onBeforeOpen: () => {
          // Update timer text every second
          const timerInterval = setInterval(() => {
            const content = Swal.getContent();
            if (content) {
              const timerElement = content.querySelector('#timer');
              if (timerElement) {
                const remainingTimeInSeconds = Math.ceil((targetTimeInSeconds - moment().diff(oldDate, 'seconds')) % 3600);
                const minutes = Math.floor(remainingTimeInSeconds / 60);
                const seconds = remainingTimeInSeconds % 60;
                timerElement.textContent = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

                // Stop the timer when time is up
                if (remainingTimeInSeconds <= 0) {
                  clearInterval(timerInterval);
                  Swal.close();
                }
            }
          }
        }, 1000);
      },
      onClose: () => {
        clearInterval(timerInterval);
      }
    });
  },
  
      handleRedirect() {
        console.log('handleRedirect'); 
        const reqData = {
                      id: this.deposit_id,
                      test_mode:this.testMode
                    };
        axios.post('/api/third-party-deposit/check-request', reqData).then(({ data }) => {
            console.log('orderData',data);
          const orderData = data.orderData;
          if(!this.timer){
            this.showTimer(orderData.updateTime);
            this.timer=true;
          }
      
          if (orderData && orderData.pay_status === 3 ) {
            const reqData = {
                    id: orderData.id,
                    pin: this.pin,
                    orderToken: orderData.deposit_token,
                    count: 1,
                    request_from_deposit:true
                    
                  };

                  let url = `/api/third-party-deposit/payment${this.testMode ? "/test" : ""}`;

                  axios
                    .post(url, reqData)
                    .then(({ data }) => {
                        this.$isLoading(false)
                      const { orderData, return_url, message } = data;
                      const confirmBoxSuccess = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button-2",
                        },
                        buttonsStyling: false,
                      });
                      if (orderData && orderData.pay_status === 1 && orderData.status === "SUCCESS") {

                if(!this.hit){
                    this.hit=true;
                        let formData = new Form({
                                deposit_id: this.id,
                                pin: this.pin,
                                test_mode:this.testMode
                            });
            formData.post('api/customer/acceptAgencyDeposit',{headers:{"Content-Type":"application/json"}})
                    .then((data) => {
                    this.$isLoading(false)

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button-2',

                        },
                        buttonsStyling: false
                        })

                        confirmBox2.fire({
                        icon: 'success',
                        title: 'Accepted!',
                        text: 'Your new balance is ' + this.new_balance

                        }).then((res)=>{
                            // reloading the page
                        window.location.reload()

                        })



                    })
                    .catch((err) => {
                    this.$isLoading(false)


                        let errorText = 'Operation Unsuccessful'

                        if(err.response.data.errors['pin']){
                            errorText = err.response.data.errors['pin']
                        }
                        else if(err.response.data.errors['general']){
                            errorText = err.response.data.errors['general']
                        }

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button bg-danger',

                        },
                        buttonsStyling: true
                        })

                        confirmBox2.fire({
                        icon: 'error',
                        title: 'Failed!',
                        text: errorText,

                        })
                    });
                }

                      }

                      if (
                        orderData &&
                        orderData.status === "FAILED" &&
                        message
                      ) {
                        let errorText = message ?? "Operation Unsuccessful";
                        confirmBox2.fire({
                          icon: "error",
                          title: "Failed!",
                          text: errorText,
                        });
                      }

          
                    })
                    .catch((err) => {
                        this.$isLoading(false)
                     
                    });



           


          }
          if (orderData.status === 'FAILED') {
            let errorText = "Operation Unsuccessful";
            const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button bg-danger',

                        },
                        buttonsStyling: true
                        })

                        confirmBox2.fire({
                        icon: 'error',
                        title: 'Failed!',
                        text: errorText,

                        })

                        window.location.reload()
          }
          });




       
      },
  
      handleResponse(orderData,deposit_id, id, pin,new_balance) {
       console.log('handleResponse22',new_balance); 
        this.orderPaymentDetails = orderData;
        this.deposit_id=deposit_id;
        this.id=id;
        this.pin=pin;
        this.new_balance=new_balance;
        this.countDownTimer();
        this.redirectAfterTenSec();
      },
      filterData(){
            $('#filterByModal').modal('toggle')

            this.form.column = this.filter_by.column
            this.form.order = this.filter_by.order
            // this.searchQuery = this.selected + '?page='

            this.getResults()
      }
  }
}
</script>
<style scoped>
  .strong:hover {
    color: black;
  }
   .test-pay-btn {
     background: #2d6a4f !important;
     top: 0;
     right: 0;
     overflow: hidden;
     color: #fff;
     border-bottom-left-radius: 20px;
     border-top-right-radius: 20px;
     font-weight: 600;
     font-size: 15px;
   }
  </style>