<template>
  <div v-if="$gate.isAgency() && $gate.isRegistrationComplete() && $gate.canTransact()">

    <div
      class="modal fade"
      id="depositForCustomerModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="depositForCustomerModalTitle"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        role="document"
      >
        <div class="modal-content">
          <form
            @submit.prevent='depositForCustomer'
            method="post"
          >

            <div class="modal-header border-0">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">

              <div class="d-flex justify-content-center">
                <h2
                  class="swal2-title"
                  id="exampleModalLongTitle"
                >Deposit For Customer/Merchant
                  from the {{branch_town}} Branch
                </h2>
              </div>
              <div class="card-body">
                <div class="form-group">

                  <label for="username">Username/ Email / Phone Number</label>
                  <div class="input-group date">
                    <input
                      type="text"
                      @blur="verifyCustomerText"
                      required
                      id='username'
                      v-model="customer_deposit_form.text"
                      class="form-control datetimepicker-input"
                      style="border-right:none;"
                      placeholder="Enter username, email or phone number"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text bg-white border-left-0">
                        <i
                          v-if="customer_deposit_form.id != null"
                          class="fa fa-check text-success"
                        ></i>
                        <i
                          v-if="!customerExists"
                          class="fa fa-times text-danger"
                        ></i>

                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group">

                  <label for="amount3">Amount</label>
                  <input
                    type="number"
                    required
                    id='amount3'
                    v-model="customer_deposit_form.amount"
                    :disabled='customer_deposit_form.text.length < 4'
                    placeholder="Enter amount"
                    class="form-control "
                  >

                </div>

              </div>

            </div>
            <div class="modal-footer border-0 d-flex justify-content-center">

                <button v-if="parseInt(this.userInfo.kashma_balance) >= 0"
                :disabled='
                            customer_deposit_form.amount == null ||
                            customer_deposit_form.amount <= 0 ||
                            customer_deposit_form.amount >= this.userInfo.kashma_balance ||
                            customer_deposit_form.text == "" ||
                            customer_deposit_form.id == null ||
                            customer_deposit_form.branch_id == null

                            '
                type="submit"
                class="btn custom-button-2 "
              >Deposit</button>


                <button v-else
                :disabled='
                             customer_deposit_form.amount == null ||
                            customer_deposit_form.amount <= 0 ||
                            broker_balance - customer_deposit_form.amount < brokerBalanceNegativeLimit ||   // note the -20 should be change to negative limit for this company
                            customer_deposit_form.text == "" ||
                            customer_deposit_form.id == null ||
                            customer_deposit_form.branch_id == null

                            '
                type="submit"
                class="btn custom-button-2 "
              >Deposit</button>
            </div>
          </form>

        </div>
      </div>
    </div>

  </div>

</template>


<script>
export default {
  name: "AgencyDepositButton",
  props: {
    branch_id: Number,
    branch_town: String,
    broker_balance: Number,
  },
  components: {},
  data() {
    return {
      selected: "Daily",
      userInfo: "",
      cash_on_hand: 0,
      commission: "",
      brokerBalanceNegativeLimit: "",

      customer_deposit_form: new Form({
        branch_id: null,
        id: null,
        pin: null,
        unique_id: null,
        text: "",
        fullname: "",
        amount: null,
      }),
      customerExists: true,
    };
  },
  created() {
    // this.getConsideration()
  },
  watch: {
    getUserProfile: function () {
      (this.broker_balance = this.getUserProfile.broker_balance),
        // this.agencyId = this.getUserProfile.agency;
        (this.customer_deposit_form.agencyId = this.getUserProfile.agency);
      // console.log(this.getUserProfile.agency);
        this.userInfo =  this.getUserProfile;

      axios
        .get("api/broker/considerations/" + this.getUserProfile.agency)
        .then(({ data }) => {
          this.brokerBalanceNegativeLimit = data.agencyLimit[0].negative_limit;
          //   console.log("negative limit", this.brokerBalanceNegativeLimit)
        })
        .catch((err) => {});
    },

    branch_id: function () {
      this.customer_deposit_form.branch_id = this.branch_id;
    },
  },

  updated() {
    console.log("updated running");
    console.log(this.broker_balance - this.customer_deposit_form.amount <= -20);
  },

  mounted() {
    this.$store.dispatch("userProfile");
  },
  computed: {
    getUserProfile() {
      //final output from here
      return this.$store.getters.getUserProfile;
    },
  },
  methods: {
    // getConsideration(){
    //         axios.get('api/broker/considerations/'+22)
    //             .then(({data}) => {
    //               console.log("considerations", data)

    //             }).catch((err)=>{

    //         })
    //   },

    verifyCustomerText() {
      this.customerExists = true;
      this.customer_deposit_form.id = null;
      this.customer_deposit_form.fullname = "";

      if (this.customer_deposit_form.text.length >= 4) {
        let temp_form = new Form({
          text: this.customer_deposit_form.text,
        });
        temp_form
          .post("api/general/verifyKashMaId")
          .then(({ data }) => {
            if (data.length == 0) {
              this.customerExists = false;
            } else {
              this.customer_deposit_form.id = data[0].id;
              this.customer_deposit_form.unique_id = data[0].unique_id;

              this.customer_deposit_form.fullname = data[0].name;
            }
          })
          .catch((err) => {});

        //   this.customerExists = false;
      }
    },
    depositForCustomer() {
      if (this.depositValidation()) return;

      $("#depositForCustomerModal").modal("toggle");

      const confirmBox1 = Swal.mixin({
        customClass: {
          confirmButton: "btn custom-button bg-danger mr-2",
          cancelButton: "btn custom-button-2 ml-2",
        },
        buttonsStyling: false,
      });

      confirmBox1
        .fire({
          title: "Are you sure?",
          html:
            "You will be depositing <b>" +
            this.dalasisFormatter(this.customer_deposit_form.amount) +
            "</b> to <b>" +
            this.customer_deposit_form.fullname +
            "</b>",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, deposit it!",
        })
        .then((result1) => {
          if (result1.isConfirmed) {
            // prompt for pin
            const box1 = Swal.mixin({
              customClass: {
                confirmButton: "btn custom-button bg-danger  mr-2",
                cancelButton: "btn custom-button-2 ml-2",
              },
              buttonsStyling: false,
            });

            box1
              .fire({
                title: "Enter your pin",
                text:
                  "To confirm that you want to deposit " +
                  this.dalasisFormatter(this.customer_deposit_form.amount) +
                  " to " +
                  this.customer_deposit_form.text,
                input: "password",
                inputAttributes: {
                  required: true,
                },
                inputValidator: (value) => {
                  if (!value) {
                    return "You need to enter your pin!";
                  }
                },
                showCancelButton: true,
                confirmButtonText: "Submit",
                showLoaderOnConfirm: true,
              })
              .then((result) => {
                if (result.isConfirmed) {
                  this.customer_deposit_form.pin = result.value;
                  this.$isLoading(true);

                  this.customer_deposit_form
                    .post("api/deposit/depositForCustomer", {
                      headers: { "Content-Type": "application/json" },
                    })
                    .then((data) => {
                      this.$isLoading(false);

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button-2",
                        },
                        buttonsStyling: false,
                      });

                      confirmBox2
                        .fire({
                          icon: "success",
                          title: "Money deposited!",
                        })
                        .then((res) => {
                          window.location.reload();
                        });
                    })
                    .catch((err) => {
                      this.$isLoading(false);

                      // show them if that city name is taken
                      let errorText = "Deposit Unsuccessful";

                      if (err.response.data.errors["pin"]) {
                        errorText = err.response.data.errors["pin"];
                      } else if (err.response.data.errors["amount"]) {
                        errorText = err.response.data.errors["amount"];
                      } else if (err.response.data.errors["general"]) {
                        errorText = err.response.data.errors["general"];
                      }

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button bg-danger",
                        },
                        buttonsStyling: true,
                      });

                      confirmBox2.fire({
                        icon: "error",
                        title: "Failed!",
                        text: errorText,
                      });
                    });
                }
              });
          }
        });
    },
    depositValidation() {
      let disabled =
        this.customer_deposit_form.amount == null ||
        this.customer_deposit_form.amount <= 0 ||
        // this.customer_deposit_form.amount > this.broker_balance ||
        this.customer_deposit_form.text == "" ||
        this.customer_deposit_form.id == null ||
        this.customer_deposit_form.branch_id == null;

      return disabled;
    },
  },
};
</script>
