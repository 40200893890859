<template>
    <div>
        <div v-if="$gate.isBroker()" class="card  p-2 m-0">
            <fieldset class="mt-5">
                            <legend class="d-flex justify-content-between flex-wrap">
                                <div class="d-flex align-items-center">
                                <span>Finacial Institutes</span>

                                </div>
                                
                                <router-link to="/create-finacial-institutes" class="nav-link" active-class="active" exact>
                                    <button class="btn btn-outline-info">Create new Finacial Institute <i class="fa fa-plus"></i></button>
                                </router-link>
                            </legend>
                            <div class="card-header">
                  
                            </div>


                        <div>



                        </div>




                        <div  class="card-body p-0 mt-2 table-responsive">
                            <table  id="example2" class="table table-hover border-top">
                                <thead >
                                <tr class="text-center">
                                <th>Sr No.</th>
                                <th>Institute Name</th>
                                <th>Created Date</th>
                                <th>Status</th>
                                <th class="border-left">Action</th>
                                </tr>
                                </thead>
                                <tbody v-if="institutes.length != 0">
                               
                                <tr class='text-center' v-for='(row, index) in institutes' :key='index'>
                                    <td>{{index+1}}</td>
                                    <td>{{row.name}}
                                     </td>
                                    <td>{{row.created_at}}</td>
                                    
                                   
                                    <td v-if="row.status==0"><span class="badge badge-success badge-pill">Active</span></td>
                                    <td v-else><span class="badge badge-danger badge-pill">Inactive</span></td>


                                    <td class="d-flex flex-wrap border-left justify-content-around">


                                        <router-link v-bind:to="'/edit-finacial-institutes/' + row.id"
                                        active-class="active" exact class="btn mb-4 custom-button-purple1" >
                                            <i class="fas fa-edit"></i>
                                        </router-link>

                                        <button v-if="row.status==0" @click="block(row.id)"  class="btn custom-button mb-4 btn-danger" title="lock">
                                            <i class="fas fa-ban"></i>
                                        </button>

                                        <button v-else  @click="unblock(row.id)"   class="btn custom-button mb-4 btn-success" title="unlock">
                                            <i class="fas fa-toggle-on"></i>
                                        </button>
                                      </td>



                                </tr>
                                <tfoot>


                                </tfoot>
                                </tbody>
                                <tbody  v-else>
                                    <tr class='text-center'>
                                       
                                        <td></td>
                                        <td></td>
                                        <td><span><i>You have no institutes.</i></span></td>
                                        <td></td>
                                        <td></td>
                                      
                                    </tr>
                    
                                </tbody>
                            </table>
                            <div class="pt-3">
                                <pagination align="center" :limit="10" :show-disabled="true" :data="pageData" @pagination-change-page="getResults"></pagination>

                            </div>

                        </div>
                    


            </fieldset>
        </div>
    </div>

</template>

    <script>

    export default {
        name: 'institutes',

        components: {


        },
      data() {
        return {
            balance: 0,
            institutes: [],
            pageData: {},
            form: new Form ({
           
        }),
         }
      },
      created(){

          // fetch campaign table
          if(this.$gate.isBroker()){
                this.getResults()
           
            }


      },
  

      methods:{

    
        getResults(page){
                if (typeof page === 'undefined') {
                page = 1;
                }

                this.$isLoading(true)

              
            this.form.page=page;
            this.form.post('/api/broker/finacialInstitute')
                    .then(({data}) => {
                      console.log("institutes", data)
                      this.institutes = data.data;
                      this.pageData = data
                        this.$isLoading(false)


                    }).catch((err)=>{
                      this.institutes = []
                      this.pageData = {}
                    this.$isLoading(false)


                })

            },


            block(id){

const confirmBox1 = Swal.mixin({
customClass: {
    cancelButton: 'btn custom-button-2 mx-2',
    confirmButton: 'btn custom-button bg-danger mx-2'
},
buttonsStyling: false
})

confirmBox1.fire({
title: 'Are you sure?',
text: "You'll be locking this Institute",
icon: 'warning',
showCancelButton: true,
confirmButtonText: 'Yes, lock this Institute!'
}).then((result1) => {
if (result1.isConfirmed) {



const box1 = Swal.mixin({
customClass: {
    confirmButton: 'btn custom-button-2 mx-2',
    denyButton: 'btn custom-button bg-secondary mx-2'
},
buttonsStyling: false
})

box1.fire({
    title: 'Enter your password',
    input: 'password',
    inputAttributes: {
        pattern: "[0-9]+",
        required: true,
    },
    inputValidator: (value) => {
        if (!value) {
        return 'You need to enter your password!'
        }

    },
    showDenyButton: true,
    denyButtonText: `Back`,
    confirmButtonText: 'Submit',
    }).then((result) => {
    if (result.isConfirmed) {

    this.$isLoading(true)

    let del_form =  new FormData();
    del_form.append('id', id)
    del_form.append('status', 1)
    del_form.append('password', result.value)

    axios.post('/api/broker/markInstitute', del_form,{headers:{"Content-Type":"application/json"}})
        .then((data) => {
            this.$isLoading(false)

            const confirmBox2 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button-2',

            },
            buttonsStyling: false
            })

            confirmBox2.fire({
            icon: 'success',
            title: 'Blocked!',
            text: 'Institute locked! ... '  ,

            }).then((res)=>{
                // reloading the page
            // window.location.href = '/agents';
            this.getResults()
            })



        })
        .catch((err) => {
            this.$isLoading(false)
        // show them if that city name is taken
            let errorText = 'Institute Locking Unsuccessful'

            if(err.response.data.errors['password']){
                errorText = err.response.data.errors['password']
            }
            else if(err.response.data.errors['general']){
                errorText = err.response.data.errors['general']
            }

            const confirmBox2 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button bg-danger',

            },
            buttonsStyling: true
            })

            confirmBox2.fire({
            icon: 'error',
            title: 'Failed!',
            text: errorText,

            })
        });




    }
    else if (result.isDenied) {

    }
    })


}
})



},

unblock(id){

const confirmBox1 = Swal.mixin({
customClass: {
cancelButton: 'btn custom-button-2 mx-2',
confirmButton: 'btn custom-button bg-danger mx-2'
},
buttonsStyling: false
})

confirmBox1.fire({
title: 'Are you sure?',
text: "You'll be unlocking this Institute ",
icon: 'warning',
showCancelButton: true,
confirmButtonText: 'Yes, unlock this Institute!'
}).then((result1) => {
if (result1.isConfirmed) {



const box1 = Swal.mixin({
customClass: {
confirmButton: 'btn custom-button-2 mx-2',
denyButton: 'btn custom-button bg-secondary mx-2'
},
buttonsStyling: false
})

box1.fire({
title: 'Enter your password',
input: 'password',
inputAttributes: {
pattern: "[0-9]+",
required: true,
},
inputValidator: (value) => {
if (!value) {
return 'You need to enter your password!'
}

},
showDenyButton: true,
denyButtonText: `Back`,
confirmButtonText: 'Submit',
}).then((result) => {
if (result.isConfirmed) {

this.$isLoading(true)

let del_form =  new FormData();
del_form.append('id', id)
del_form.append('status', 0)
del_form.append('password', result.value)

axios.post('/api/broker/markInstitute', del_form,{headers:{"Content-Type":"application/json"}})
.then((data) => {
    this.$isLoading(false)

    const confirmBox2 = Swal.mixin({
    customClass: {
        confirmButton: 'btn custom-button-2',

    },
    buttonsStyling: false
    })

    confirmBox2.fire({
    icon: 'success',
    title: 'Unlocked!',
    text: 'Institute Unlocked! ... '  ,

    }).then((res)=>{
        // reloading the page
    // window.location.href = '/agents';
    this.getResults()
    })



})
.catch((err) => {
    this.$isLoading(false)
// show them if that city name is taken
    let errorText = 'Institute Unlocking Unsuccessful'

    if(err.response.data.errors['password']){
        errorText = err.response.data.errors['password']
    }
    else if(err.response.data.errors['general']){
        errorText = err.response.data.errors['general']
    }

    const confirmBox2 = Swal.mixin({
    customClass: {
        confirmButton: 'btn custom-button bg-danger',

    },
    buttonsStyling: true
    })

    confirmBox2.fire({
    icon: 'error',
    title: 'Failed!',
    text: errorText,

    })
});




}
else if (result.isDenied) {

}
})


}
})



},  

      },

     

    }
    </script>
