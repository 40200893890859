<template>
  <div v-if="$gate.isCustomer() || $gate.isStore()">
    <div>
      <legend class="d-flex justify-content-center flex-wrap">
        <span class="d-flex align-items-center"
          >{{ form.column }} Transfers</span
        >
      </legend>
      <!-- <div class="text-center"><i>work in progress</i> </div> -->
      <div
        v-show="transfers.length > 0"
        class="d-flex justify-content-end mt-0 pt-0 mb-3"
      >
        <button
          class="btn btn-sm btn-default"
          data-toggle="modal"
          data-target="#filterByModal"
          style="border-color: gray"
        >
          Filter
        </button>
      </div>

      <!-- group by date -->
      <div v-for="(data, index1) in transfers" :key="index1" class="pb-3">
        <div class="pb-2 font-weight-bold text-muted">
          {{ data[0].day_key }}
        </div>

        <div class="transactions">
          <section
            v-for="(d, index2) in data"
            :key="index2"
            class="px-3 pb-md-4 mb-3"
          >
            <p class="py-1 text-secondary">
              Transfer ID: <i class="font-weight-bold">{{ d["unique_id"] }}</i>
            </p>
            <div class="d-flex justify-content-between">
              <div class="font-weight-bold text-md d-flex align-items-center">
                <span>{{ d["status"] }}</span>
              </div>
            </div>
            <div class="item pt-2">
              <div class="detail">
                <img
                  :src="'/images/profiles/' + d['other_pic']"
                  alt="img"
                  class="image-block imaged w48"
                />

                <div>
                  <router-link v-bind:to="'/view-profile/' + d['other_id']">
                    <strong
                      >{{ d["other_name"] }}
                      <img
                        v-if="d['is_verified']"
                        src="/images/verified-badge.png"
                        alt="image"
                        style="width: 16px; height: 16px"
                      />
                    </strong>
                  </router-link>

                  <p>
                    <span class="font-weight-bold">{{
                      d["transaction_type"]
                    }}</span>
                  </p>

                  <p>
                    {{ d["time_updated"] }}
                  </p>
                </div>
              </div>
              <div class="right">
                <div
                  style="text-align: end"
                  :class="[
                    d['status'] == 'Received' ? 'text-emerald' : 'text-red1',
                    'price  font-weight-bold',
                  ]"
                >
                  <span v-if="d['status'] == 'Received'">+</span>
                  <span v-else>-</span>
                  <span v-if="d['status'] == 'Received'"
                    >{{ dalasisFormatter(d["amount"]) }} <br
                  /></span>
                  <span v-else
                    >{{ dalasisFormatter(d["amount"] + d["fee_paid"]) }} <br
                  /></span>
                  <span
                    class="text-red text-sm float-right"
                    v-if="d['status'] == 'Received' && $gate.isStore()"
                  >
                    - {{ dalasisFormatter(d["fee_paid"]) }}
                    <span class="text-gray">( Fee )</span>
                  </span>
                  <br />
                  <span
                    class="text-red text-sm float-right"
                    v-if="d['discount'] != null && $gate.isStore()"
                  >
                    - {{ dalasisFormatter(d["discount"]) }}
                    <span class="text-gray">(Discount)</span>
                  </span>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      <div class="card py-4 text-center" v-if="transfers.length == 0">
        <span>{{ emptyDataText }} </span>
      </div>

      <div class="pt-3">
        <pagination
          align="center"
          :data="pageData"
          @pagination-change-page="getResults"
        ></pagination>
      </div>

      <div
        class="modal fade"
        id="filterByModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="filterByModalTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header border-0">
              <span class="swal2-title">Filter By</span>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <h5 class="text-muted">Select Column</h5>
              <div class="form-group ml-3">
                <div
                  v-for="(column, index) in filter_column_names"
                  :key="index"
                  class="form-check"
                >
                  <input
                    class="form-check-input"
                    type="radio"
                    v-model="filter_by.column"
                    :value="column"
                  />
                  <label class="form-check-label" for="exampleRadios1">
                    {{ column }}
                  </label>
                </div>
              </div>
              <hr />
              <h5 class="text-muted">Order By</h5>
              <div class="form-group ml-3">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    v-model="filter_by.order"
                    value="ascending"
                  />
                  <label class="form-check-label" for="exampleRadios1">
                    Ascending
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    v-model="filter_by.order"
                    value="descending"
                  />
                  <label class="form-check-label" for="exampleRadios1">
                    Descending
                  </label>
                </div>
              </div>
            </div>
            <div class="modal-footer border-0 d-flex justify-content-center">
              <button
                type="submit"
                @click="filterData"
                class="btn custom-button-2"
              >
                Filter
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Send :getUserProfile="getUserProfile" />
    <Footer :getUserProfile="getUserProfile" />
  </div>
  <div v-else>
    <not-found></not-found>
  </div>
</template>

<script>
export default {
  name: "Transfers",
  components: {},
  data() {
    return {
      hasReloaded: false,
      balance: 0,
      pageData: {},
      transfers: [],
      searchForm: null,
      isFound: false,
      form: new Form({
        column: "All",
        order: "descending",
      }),

      emptyDataText: "",
      filter_by: {
        column: "All",
        order: "descending",
      },
      filter_column_names: ["All", "Received", "Sent"],
    };
  },
  created() {
    if (this.$gate.isCustomer() || this.$gate.isStore()) this.getResults();
  },
  watch: {
    getUserProfile: function () {
      if (this.getUserProfile.user_type == 6) {
        document.getElementById("bladeAmount").innerHTML =
          this.dalasisFormatter(+this.getUserProfile.total_transfered_amount);
      } else {
        if((this.getUserProfile.balance ==0 || this.getUserProfile.balance ==0.00) && this.getUserProfile.used_kashlock_credit > 0){
                this.getUserProfile.balance= -this.getUserProfile.used_kashlock_credit;
            }

          document.getElementById("bladeAmount").innerHTML =
            this.dalasisFormatter(this.getUserProfile.balance);
            document.getElementById('bladeCreditAmount').innerHTML = this.dalasisFormatter(this.getUserProfile.kashlock_credit)
            if(this.getUserProfile.kashlock_credit > 0){
                document.getElementById('bladeCreditBlock').style.display = 'block';
            }
      }
      if (this.$gate.isStore())
        document.getElementById("bladeCompanyName").innerHTML =
          this.getUserProfile.company_name;
    },
  },

  mounted() {
    this.$store.dispatch("userProfile");
  },
  computed: {
    getUserProfile() {
      //final output from here
      return this.$store.getters.getUserProfile;
    },
  },
  methods: {
    getResults(page) {
      if (typeof page === "undefined") {
        page = 1;
      }
      let api_route = "";
      if (this.$gate.isCustomer()) {
        api_route = "api/customer/transfers";
      } else if (this.$gate.isStore()) {
        (this.form["location_id"] = this.getUserProfile.balance),
          (api_route = "api/merchant/transfers");
      }

      this.emptyDataText = "";
      if (this.searchForm == null || this.searchForm.search_text == "") {
        this.$isLoading(true);

        this.form
          .post(api_route + "?page=" + page)
          .then(({ data }) => {
            if (data.grouped_data != 0) {
              this.transfers = data.grouped_data;
              this.pageData = data.data;
            } else {
              this.transfers = [];
              this.pageData = {};
              this.emptyDataText = "No transfer record";
            }

            this.$isLoading(false);
          })
          .catch((err) => {
            this.$isLoading(false);
          });
      } else {
        this.$isLoading(true);
        this.emptyDataText = "";

        axios
          .post(api_route + "/makeSearch?page=", {
            headers: { "Content-Type": "application/json" },
          })
          .then(({ data }) => {
            this.isFound = true;

            this.transfers = data.data;
            this.pageData = data.grouped_data;
            this.emptyDataText = "No search results found...";

            this.$isLoading(false);
          })
          .catch((err) => {
            this.transfers = [];
            this.pageData = {};
            this.emptyDataText = "Something went wrong!";

            this.$isLoading(false);
          });
      }
    },

    filterData() {
      $("#filterByModal").modal("toggle");

      this.form.column = this.filter_by.column;
      this.form.order = this.filter_by.order;
      // this.searchQuery = this.selected + '?page='

      this.getResults();
    },
  },
};
</script>

<style scoped>
.strong:hover {
  color: black;
}
</style>
