<template>
    <div>
        <div v-if="$gate.isBroker()" class="card custom-card-1 m-0">
            <fieldset>
                <legend class="d-flex justify-content-center flex-wrap">
                    <span>All Pending Withdrawals</span>
                </legend>

                <SearchFilter
                    :data_length="withdrawals.length"
                    :filters="filters"
                    data_type="incoming"
                    @search-form="makeSearch"
                    :isFound="isFound"
                />
                <!-- /.card-header -->
                <div
                    v-if="withdrawals.length != 0"
                    class="card-body table-responsive"
                >
                    <table
                        id="example2"
                        class="table table-bordered table-hover border-top"
                    >
                        <thead>
                            <tr class="text-center">
                                <th>Collector from</th>
                                <th>Collector current Balance</th>
                                <th>Request Amount</th>
                                <th>Time Sent</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                class="text-center"
                                v-for="(row, index) in withdrawals"
                                :key="index"
                            >
                                <td>
                                    <span>
                                        {{ row.institution_name }} -
                                        {{ row.username }}
                                    </span>
                                    <br />
                                </td>
                                <td>
                                    {{ dalasisFormatter(row.collector_current_balance) }}
                                </td>

                                <td>
                                    {{ dalasisFormatter(row.request_amount) }}
                                </td>

                                <td>{{ row.time_sent }}</td>
                                <td>
                                    <button
                                        @click="acceptRequest(row.withdraw_id)"
                                        class="btn btn-sm custom-button-ogreen1 text-white"
                                    >
                                        Accept
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="pt-3">
                        <pagination
                            align="center"
                            :data="pageData"
                            @pagination-change-page="getResults"
                        ></pagination>
                    </div>
                </div>

                <div class="text-center" v-else>
                    <span
                        ><i>{{ emptyDataText }}</i></span
                    >
                </div>
                <!-- /.card-body -->
            </fieldset>
        </div>
        <!-- /.card -->

        <div v-else>
            <not-found></not-found>
        </div>
    </div>
</template>

<script>
export default {
    name: "PendingWithdrawals",
    components: {
        SearchFilter: () =>
            import(
                "../../../includes/SearchFilter.vue" /* webpackChunkName: "js/search-filter" */
            ),
    },
    data() {
        return {
            isFound: false,
            searchForm: null,
            withdrawals: [],
            pageData: {},
            filters: [
                {
                    id: 1,
                    text: "Collector From",
                    value: "collector_from",
                },
                {
                    id: 2,
                    text: "Amount",
                    value: "amount",
                },
                
            ],
            sort_by: {
                column: "Time Sent",
                order: "descending",
            },
            sort_column_names: [
                "Amount",
                "Time Sent",
            ],
            emptyDataText: "",
        };
    },
    created() {
        // fetch fee table
        if (this.$gate.isBroker()) {
            this.getResults();
        }
    },
    methods: {

        acceptRequest(id) {
            //   get the info from id
            let customer = this.withdrawals.filter(
                (t) => t.withdraw_id == id
            )[0];
            let formData = new FormData();
            console.log("data", customer);
            const confirmBox1 = Swal.mixin({
                customClass: {
                    confirmButton: "btn custom-button-2 mr-2",
                    cancelButton: "btn custom-button bg-danger ml-2",
                },
                buttonsStyling: false,
            });
            var name = customer["username"];
            confirmBox1
                .fire({
                    title: "Are you sure?",
                    text:
                        "You'll be withdrawing D" +
                        (customer["request_amount"]) +
                        " for " +
                        name,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes, accept it!",
                    cancelButtonText: "No, don't!",
                })
                .then((result1) => {
                    if (result1.isConfirmed) {
                        // prompt for pin
                        const box0 = Swal.mixin({
                            customClass: {
                                confirmButton: "btn custom-button-2 mr-2",
                                cancelButton:
                                    "btn custom-button bg-danger ml-2",
                            },
                            buttonsStyling: false,
                        });

                        box0.fire({
                            title: "Enter the withdraw token",
                            input: "text",
                            inputAttributes: {
                                required: true,
                            },
                            inputValidator: (value) => {
                                if (!value) {
                                    return "You need to enter the withdraw token!";
                                }
                            },
                            showCancelButton: true,
                            confirmButtonText: "Submit",
                            showLoaderOnConfirm: true,
                        }).then((result0) => {
                            if (result0.isConfirmed) {
                                formData.append(
                                    "withdraw_token",
                                    result0.value
                                );

                                // prompt for pin
                                const box1 = Swal.mixin({
                                    customClass: {
                                        confirmButton:
                                            "btn custom-button-2 mr-2",
                                        cancelButton:
                                            "btn custom-button bg-danger ml-2",
                                    },
                                    buttonsStyling: false,
                                });

                                box1.fire({
                                    title: 'Enter your password',
                                        input: 'password',
                                        inputAttributes: {
                                            pattern: "[0-9]+",
                                            required: true,
                                        },
                                        inputValidator: (value) => {
                                            if (!value) {
                                            return 'You need to enter your password!'
                                            }
                                    },
                                    showCancelButton: true,
                                    confirmButtonText: "Submit",
                                    showLoaderOnConfirm: true,
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        this.$isLoading(true);

                                        formData.append("withdraw_id", id);

                                        formData.append("password", result.value);

                                        axios
                                            .post(
                                                "api/broker/collector/withdrawal/accept",
                                                formData,
                                                {
                                                    headers: {
                                                        "Content-Type":
                                                            "application/json",
                                                    },
                                                }
                                            )
                                            .then((data) => {
                                                this.$isLoading(false);

                                                const confirmBox2 = Swal.mixin({
                                                    customClass: {
                                                        confirmButton:
                                                            "btn custom-button-2",
                                                    },
                                                    buttonsStyling: false,
                                                });

                                                confirmBox2
                                                    .fire({
                                                        icon: "success",
                                                        title: "Complete!",
                                                        text: "Withdraw successful!",
                                                    })
                                                    .then((res) => {
                                                        // reloading the page
                                                        window.location.reload();
                                                    });
                                            })
                                            .catch((err) => {
                                                this.$isLoading(false);

                                                const confirmBox2 = Swal.mixin({
                                                    customClass: {
                                                        confirmButton:
                                                            "btn custom-button bg-danger",
                                                    },
                                                    buttonsStyling: true,
                                                });

                                                confirmBox2.fire({
                                                    icon: "error",
                                                    title: "Failed!",
                                                    text: err.response.data
                                                        .errors,
                                                });
                                            });
                                    }
                                });
                            }
                        });
                    }
                });
        },



 
        getResults(page) {
            if (typeof page === "undefined") {
                page = 1;
            }
            this.emptyDataText = "";
            if (this.searchForm == null || this.searchForm.search_text == "") {
                this.$isLoading(true);

                axios
                    .get("api/broker/collector/withdrawal/allPending?page=" + page)
                    .then(({ data }) => {
                        if (data.data.length != 0) {
                            this.withdrawals = data.data;
                            this.pageData = data;
                        } else {
                            this.withdrawals = [];
                            this.pageData = {};
                            this.emptyDataText =
                                "You have no pending withdrawals";
                        }

                        this.$isLoading(false);
                    })
                    .catch((err) => {
                        this.$isLoading(false);
                    });
            } else {
                this.$isLoading(true);
                this.emptyDataText = "";

                this.searchForm
                    .post("api/broker/collector/withdrawal/makeSearch?page=", {
                        headers: { "Content-Type": "application/json" },
                    })
                    .then(({ data }) => {
                        this.isFound = true;

                        this.withdrawals = data.data;
                        this.pageData = data;
                        this.emptyDataText = "No search results found...";

                        this.$isLoading(false);
                    })
                    .catch((err) => {
                        this.withdrawals = [];
                        this.pageData = {};
                        this.emptyDataText = "Something went wrong!";

                        this.$isLoading(false);
                    });
            }
        },

        makeSearch(data){
            this.searchForm = data
            this.getResults()
            // emit up
        }
    },
};
</script>
