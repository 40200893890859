<script>
import { Bar } from 'vue-chartjs'

export default {
  extends: Bar,
  props: ['chartData', 'barLabels', 'barColors'],
  data () {

      return {
          dataPoints: null,
          stackedX: false,
          stackedY: false,
          yAxisLabel : 'Dalasis',
          xAxisLabel:'',
          yearLabels: [],

      }
  },
  mounted () {
        this.drawChart()
  },
  watch:{
      chartData: function(){
          this.drawChart()
      }
  },

  methods:{

      drawChart(){

          if(true){
              this.xAxisLabel = this.chartData['x_axis']

              this.renderChart({
                  labels: this.chartData['labels'],
                  datasets: [
            {
              label: this.barLabels[0],
          backgroundColor     : 'rgba(81,139,201,0.6)',
          borderColor         : 'rgba(81,139,201,0.6)',
          data                : this.chartData['incoming'],
          
            }, 
            {
                label: this.barLabels[1],
                backgroundColor     : 'rgba(148,82,165, 0.6)',
                borderColor         : 'rgba(148,82,165, 0.6)',
                data                : this.chartData['outgoing']
            }
          ]

              }, {scales: {
                      yAxes: [{
                          stacked: this.stackedY,
                          scaleLabel:{
                              display: true,
                              labelString: this.yAxisLabel
                          } ,
                          ticks: {
                              beginAtZero: true
                          },
                          gridLines: {
                              display: false
                          }
                      },

                      ],
                      xAxes: this.xAxisTime()
                  },
                  legend: {
                      position: 'bottom',
                      display: true
                  },
                  responsive: true,
                  maintainAspectRatio: false})
          }

      

      },

      xAxisTime(){
              let xAxisData = [{
                          stacked: this.stackedX,
                          gridLines: {
                              display: false
                          },
                          scaleLabel:{
                              display: true,
                              labelString: this.xAxisLabel
                          } ,

                      }
                    ]
                // if(this.xAxisLabel == 'Hour'){
                //     xAxisData = [{
                //           stacked: this.stackedX,
                //           gridLines: {
                //               display: false
                //           },
                //           scaleLabel:{
                //               display: true,
                //               labelString: this.xAxisLabel
                //           } ,
                //         type: 'time',
                //         time: {
                //             unit: 'hour',
                //             displayFormats: {
                //                 hour: 'HH'
                //             },

                //         }
                //       },
                //     ]
                // }
                // else if(this.xAxisLabel == 'Day'){
                //     xAxisData = [{
                //           stacked: this.stackedX,
                //           gridLines: {
                //               display: false
                //           },
                //           scaleLabel:{
                //               display: true,
                //               labelString: this.xAxisLabel
                //           } ,
                //         type: 'time',
                //         time: {
                //             unit: 'day',
                //             displayFormats: {
                //                 hour: 'DD'
                //             }
                //         }
                //       },
                //     ]
                // }
                // else if(this.xAxisLabel == 'Month'){
                //     xAxisData = [{
                //           stacked: this.stackedX,
                //           gridLines: {
                //               display: false
                //           },
                //           scaleLabel:{
                //               display: true,
                //               labelString: this.xAxisLabel
                //           } ,
                //         type: 'time',
                //         time: {
                //             unit: 'month',
                //             displayFormats: {
                //                 hour: 'MM'
                //             }
                //         }
                //       },
                //     ]
                // }

                return xAxisData;
      }
  }
}
</script>