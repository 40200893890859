<template>
    <div>
        <div v-if="$gate.isBroker()" class="card custom-card-1 m-0">
            <fieldset>
                <legend class="d-flex justify-content-center flex-wrap">
                    <span>All Completed Withdrawals</span>
                </legend>

                <SearchFilter
                    :data_length="withdrawals.length"
                    :filters="filters"
                    data_type="completed"
                    @search-form="makeSearch"
                    :isFound="isFound"
                />
                <!-- /.card-header -->
                <div
                    v-if="withdrawals.length != 0"
                    class="card-body table-responsive"
                >
                    <table
                        id="example2"
                        class="table table-bordered table-hover border-top"
                    >
                        <thead>
                            <tr class="text-center">
                                <th>Collector from</th>
                                <th>Collector current Balance</th>
                                <th>Request Amount</th>
                                <th>Time Sent</th>
                                <th>Time Completed</th>
                            </tr>
                           
                        </thead>
                        <tbody>
                            <tr
                                class="text-center"
                                v-for="(row, index) in withdrawals"
                                :key="index"
                            >
                                <td>
                                    <span>
                                        {{ row.institution_name }} -
                                        {{ row.username }}
                                    </span>
                                    <br />
                                </td>
                                <td>
                                    {{ dalasisFormatter(row.collector_current_balance) }}
                                </td>

                                <td>
                                    {{ dalasisFormatter(row.request_amount) }}
                                </td>

                                <td>{{ row.time_sent }}</td>
                                <td>
                                    {{ row.time_updated }} 
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="pt-3">
                        <pagination
                            align="center"
                            :data="pageData"
                            @pagination-change-page="getResults"
                        ></pagination>
                    </div>
                </div>

                <div class="text-center" v-else>
                    <span
                        ><i>{{ emptyDataText }}</i></span
                    >
                </div>
                <!-- /.card-body -->
            </fieldset>
        </div>
        <!-- /.card -->

        <div v-else>
            <not-found></not-found>
        </div>
    </div>
</template>

<script>
export default {
    name: "CompletedWithdrawals",
    components: {
        SearchFilter: () =>
            import(
                "../../../includes/SearchFilter.vue" /* webpackChunkName: "js/search-filter" */
            ),
    },
    data() {
        return {
            isFound: false,
            searchForm: null,
            withdrawals: [],
            pageData: {},
            filters: [
                {
                    id: 1,
                    text: "Collector From",
                    value: "collector_from",
                },
                {
                    id: 2,
                    text: "Amount",
                    value: "amount",
                },
                
            ],
            sort_by: {
                column: "Time Sent",
                order: "descending",
            },
            sort_column_names: [
                "Amount",
                "Time Sent",
            ],
            emptyDataText: "",
        };
    },
    created() {
        // fetch fee table
        if (this.$gate.isBroker()) {
            this.getResults();
        }
    },
    methods: {
   
        getResults(page) {
            if (typeof page === "undefined") {
                page = 1;
            }
            this.emptyDataText = "";
            if (this.searchForm == null || this.searchForm.search_text == "") {
                this.$isLoading(true);

                axios
                    .get("api/broker/collector/withdrawal/allCompleted?page=" + page)
                    .then(({ data }) => {
                        if (data.data.length != 0) {
                            this.withdrawals = data.data;
                            this.pageData = data;
                        } else {
                            this.withdrawals = [];
                            this.pageData = {};
                            this.emptyDataText =
                                "You have no outgoing transactions";
                        }

                        this.$isLoading(false);
                    })
                    .catch((err) => {
                        this.$isLoading(false);
                    });
            } else {
                this.$isLoading(true);
                this.emptyDataText = "";

                this.searchForm
                    .post("api/broker/collector/withdrawal/makeSearch?page=", {
                        headers: { "Content-Type": "application/json" },
                    })
                    .then(({ data }) => {
                        this.isFound = true;

                        this.withdrawals = data.data;
                        this.pageData = data;
                        this.emptyDataText = "No search results found...";

                        this.$isLoading(false);
                    })
                    .catch((err) => {
                        this.withdrawals = [];
                        this.pageData = {};
                        this.emptyDataText = "Something went wrong!";

                        this.$isLoading(false);
                    });
            }
        },

        makeSearch(data){
            this.searchForm = data
            this.getResults()
            // emit up
        }
    },
};
</script>
