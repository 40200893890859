<template>
  <div v-if="$gate.isCustomer() || $gate.isStore()" class="customer">
    <legend class="d-flex justify-content-center flex-wrap">
      <span class="d-flex align-items-center">Profile</span>
    </legend>
    <div v-if="profileInfo != null" class="card custom-card-1 mt-2">
      <div class="row">
        <div class="col-12">
          <div class="d-flex justify-content-end">
            <button
              class="btn btn-dark"
              data-toggle="modal"
              data-target="#logsModal"
            >
              View Logs
            </button>
          </div>
        </div>
        <div class="col-12 pt-3 pt-lg-3 col-lg-4 px-2">
          <div class="image text-center">
            <img
              class="img-fluid pad"
              :src="'/images/profiles/' + profileInfo.picture"
              alt=""
            />
          </div>
        </div>
        <div class="col-12 col-lg-8 pt-3 pt-lg-0 pr-lg-5">
          <div
            v-if="profileInfo.rank == 'Customer'"
            class="row py-3 border-bottom"
          >
            <div class="col-6">First Name</div>
            <div class="col">
              {{ profileInfo.first_name }}
            </div>
          </div>
          <div
            v-if="profileInfo.rank == 'Customer'"
            class="row py-3 border-bottom"
          >
            <div class="col-6">Last Name</div>
            <div class="col">
              {{ profileInfo.last_name }}
            </div>
          </div>

          <div
            v-if="profileInfo.rank == 'Customer'"
            class="row py-3 border-bottom"
          >
            <div class="col-6">Username</div>
            <div class="col">
              {{ profileInfo.username }}
            </div>
          </div>

          <div
            v-if="
              profileInfo.rank == 'Agency' ||
              profileInfo.rank == 'Merchant Business'
            "
            class="row py-3 border-bottom"
          >
            <div class="col-6">Company</div>
            <div class="col">
              {{ profileInfo.company_name }}
            </div>
          </div>
          <div
            v-if="
              profileInfo.rank == 'Agency' ||
              profileInfo.rank == 'Merchant Business'
            "
            class="row py-3 border-bottom"
          >
            <div class="col-6">Company Email</div>
            <div class="col">
              {{ profileInfo.company_email }}
            </div>
          </div>
          <div class="row py-3 border-bottom">
            <div class="col-6">Phone Number</div>
            <div class="col">
              {{ profileInfo.phone_number }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="logsModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="logsModalTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content" style="background: whitesmoke">
          <div class="modal-header border-0">
            <h2 class="swal2-title" id="logsModalTitle">Logs</h2>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div v-for="(data, index1) in logs" :key="index1">
              <div class="pb-2 font-weight-bold text-muted">
                {{ data[0].day_key }}
              </div>

              <div class="transactions">
                <section
                  v-for="(d, index2) in data"
                  :key="index2"
                  class="px-3 mb-3"
                >
                  <div class="item px-0 pt-2">
                    <div class="detail">
                      <i
                        :class="[
                          d['status'] == 'Received'
                            ? 'fas fa-arrow-down text-emerald'
                            : d['status'] == 'Completed'
                            ? 'text-bgreen'
                            : d['status'] == 'Incoming'
                            ? 'text-info'
                            : d['status'] == 'Sent'
                            ? 'fas fa-arrow-up text-red1'
                            : '',
                          'mr-3',
                        ]"
                      ></i>

                      <div>
                        <b
                          :class="[
                            d['status'] == 'Completed'
                              ? 'text-bgreen'
                              : d['status'] == 'Incoming'
                              ? 'text-info'
                              : d['status'] == 'Cancelled'
                              ? 'text-red1'
                              : '',
                            'mr-3',
                          ]"
                          >{{ d["status"] }}</b
                        >

                        <p>
                          <span class="font-weight-bold">{{
                            d["transaction_type"]
                          }}</span>
                        </p>
                        <p>
                          {{ d["time_updated"] }}
                        </p>
                      </div>
                    </div>
                    <div class="right">
                      <div
                        :class="[
                          d['status'] == 'Received'
                            ? 'text-emerald'
                            : d['status'] == 'Completed'
                            ? 'text-bgreen'
                            : d['status'] == 'Incoming'
                            ? 'text-info'
                            : 'text-red1',
                          'price font-weight-bold',
                        ]"
                      >
                        <span v-if="d['status'] == 'Received'">+</span>
                        <span v-else-if="d['status'] == 'Sent'">-</span>
                        {{ dalasisFormatter(d["amount"]) }}
                        <br />
                        <span
                          class="text-red text-sm float-right"
                          v-if="d['status'] == 'Received' && $gate.isStore()"
                        >
                          -
                          {{ dalasisFormatter(d["fee_paid"]) }}
                        </span>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="pt-3">
              <pagination
                align="center"
                :data="logsData"
                @pagination-change-page="getLogs"
              ></pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Send :getUserProfile="getUserProfile" />
    <Footer :getUserProfile="getUserProfile" />
  </div>
  <div v-else-if="!$gate.isCustomer() && !$gate.isStore()">
    <not-found></not-found>
  </div>
</template>

<script>
export default {
  name: "ViewProfile",
  props: {},
  components: {},
  data() {
    return {
      history: [],
      pageData: {},
      profileInfo: null,
      userInfo: {},
      logs: [],
      logsData: {},
    };
  },
  created() {
    // fetch fee table
    this.getProfile();
  },
  watch: {
    getUserProfile: function () {
      document.getElementById("bladeAmount").innerHTML = this.dalasisFormatter(
        this.getUserProfile.balance
      );
    },
  },
  mounted() {
    this.$store.dispatch("userProfile");
  },
  computed: {
    getUserProfile() {
      //final output from here
      return this.$store.getters.getUserProfile;
    },
  },
  methods: {
    getProfile() {
      this.$isLoading(true);

      let temp_form = new Form({
        unique_id: this.$route.params.id,
      });

      temp_form
        .post("/api/profile/getProfile")
        .then(({ data }) => {
          this.profileInfo = data;

          this.getLogs();

          this.$isLoading(false);
        })
        .catch((err) => {
          this.$isLoading(false);
        });
    },

    getLogs(page) {
      if (typeof page === "undefined") {
        page = 1;
      }

      let log_form = new Form({
        id: this.profileInfo.id,
        rank: this.profileInfo.rank,
      });

      log_form
        .post("/api/profile/getProfileLogs?page=" + page)
        .then(({ data }) => {
          if (data.grouped_data != 0) {
            this.logs = data.grouped_data;
            this.logsData = data.data;
          } else {
            this.logs = [];
            this.logsData = {};
          }
        })
        .catch((err) => {});
    },
  },
};
</script>
