<template>
  <div>
    <div v-if="$gate.isBroker()" class="card custom-card-1 m-0">
      <form
        class="form-horizontal"
        action=""
        @submit.prevent="submitChanges"
        method="post"
      >
        <fieldset>
          <legend class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center">
              <span>Configure Free Money Table</span>
            </div>
            <a
              href="#"
              class="nav-link"
              active-class="active"
              onclick="history.go(-1);return false;"
            >
              <button class="btn bg-nyanza font-weight-bold text-gray">
                Go back
              </button>
            </a>
          </legend>
          <div class="card-header"></div>

          <div class="card-body">

            <div class="mb-3 d-flex justify-content-end">

              <button :disabled="form.freeMoney.length > 0" type="button" class="btn btn-sm btn-outline-success" @click="addRow">
              <i  class="fa fa-plus"></i> Add Row
              </button>

              </div>
            <div class="mb-2">
              <div
                class="text-danger"
                v-if="form.errors.has('entries')"
                v-html="form.errors.get('entries')"
              />
            </div>

            <div class="table-responsive">
              <table id="example2" class="table table-bordered table-hover">
                <thead>
                  <tr class="text-center">
                    <th></th>
                    <th>Start Rand</th>
                    <th>End Rand</th>
                    <th>Type</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="text-center"
                    v-for="(row, index) in form.freeMoney"
                    :key="index"
                  >
                    <td>{{ ++index }}</td>

                    <td>
                      <input
                        type="number"
                        id="amount"
                        v-model="row.start_rand"
                        placeholder="Enter start random number"
                        class="form-control"
                      />
                    </td>

                    <td>
                      <input
                        type="number"
                        id="amount"
                        required
                        v-model="row.end_rand"
                        placeholder="Enter end random number"
                        class="form-control"
                      />
                    </td>
                    <td class="text-emerald font-weight-bold">
                      <select id="amount" required v-model="row.type" class="form-control">
                        <option value="verified">Verified</option>
                        <option value="unverified">Unverified</option>
                      </select>
                    </td>
                    <td>
                      <button
                        type="button"
                        @click="deleteRow(row.id)"
                        class="btn mb-4 custom-button btn-danger"
                      >
                        <i class="fas fa-trash" aria-hidden="true"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="mt-3 d-flex justify-content-center">
              <button
                type="submit"
                :disabled="form.busy"
                class="btn custom-button-1"
              >
                SUBMIT
              </button>
            </div>
          </div>
        </fieldset>
      </form>
    </div>

    <div v-else>
      <not-found></not-found>
    </div>
  </div>
</template>


<script>
export default {
  name: "ConfigurefreeMoney",
  props: {},
  components: {},
  data() {
    return {
      form: new Form({
        password: "",
        freeMoney: [],
      }),
      freeMoney: [],
      f_index: 1,
    };
  },
  created() {
    this.$isLoading(true);

    axios
      .get("api/freeMoneyTable")
      .then(({ data }) => {
        this.freeMoney = data;
        this.form.freeMoney = data;
        this.f_index = data[data.length - 1].id; // the last index i think
        console.log("length", this.form.freeMoney.length)
        this.$isLoading(false);
      })
      .catch((err) => {
        this.$isLoading(false);
      });
  },
  mounted() {},
  computed: {},
  watch: {},
  methods: {
    submitChanges() {
      if (this.$gate.isBroker()) {
        const box1 = Swal.mixin({
          customClass: {
            confirmButton: "btn custom-button-2 mr-2",
            denyButton: "btn custom-button bg-secondary ml-2",
          },
          buttonsStyling: false,
        });

        box1
          .fire({
            title: "Enter your password",
            text: "To confirm your changes",
            input: "password",
            inputAttributes: {
              required: true,
            },
            inputValidator: (value) => {
              if (!value) {
                return "You need to enter your password!";
              }
            },
            showDenyButton: true,
            denyButtonText: `Back`,
            confirmButtonText: "Submit",
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.form.password = result.value;

              this.$isLoading(true);

              this.form
                .post("api/freeMoneyTable/configure", {
                  headers: { "Content-Type": "application/json" },
                })
                .then((data) => {
                  this.$isLoading(false);

                  const confirmBox2 = Swal.mixin({
                    customClass: {
                      confirmButton: "btn custom-button-2",
                    },
                    buttonsStyling: false,
                  });

                  confirmBox2
                    .fire({
                      icon: "success",
                      title: "Fee Table Configuration Successful!",
                      text: "The changes have been saved",
                    })
                    .then((res) => {
                      // redirect user to dashboard
                      window.history.back();
                    });
                })
                .catch((err) => {
                  this.$isLoading(false);

                  let errorText = "Fee Table Configuration Unsuccessful";

                  if (err.response.data.errors["password"]) {
                    errorText = err.response.data.errors["password"];
                  }

                  const confirmBox2 = Swal.mixin({
                    customClass: {
                      confirmButton: "btn custom-button bg-danger",
                    },
                    buttonsStyling: true,
                  });

                  confirmBox2.fire({
                    icon: "error",
                    title: "Failed!",
                    text: errorText,
                  });
                });
            }
          });
      }
    },
    addRow() {

      this.form.freeMoney.push({
              id: ++this.f_index,
              start_rand: null,
              end_rand: null,
              type: null

      });
      },

    deleteRow(index) {
      this.form.freeMoney = [...this.form.freeMoney.filter((f) => f.id != index)];
    },
  },
};
</script>