<template>
    <div>

        <div class="card  custom-card-1 m-0">
            <fieldset>
                <legend class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center">
                     <span> Customer Profile ( {{userInfo.first_name}} {{userInfo.last_name}} )</span>

                    </div>
                   <a href="#" class="nav-link" active-class="active" onclick="history.go(-1);return false;">
                       <button class="btn bg-nyanza font-weight-bold text-gray" >Go back</button>
                    </a>
                </legend>


                    <div class="card-header">
                    </div>


            <div>

                <TabNav :tabs="tabs" :selected="selected" @selected="setSelected">
                    <Tab :isSelected="selected === 'General' ">

                        <div class="row mt-5">
                            <div class="col-12">
                                <div class="border-bottom pb-3"><h5>General</h5></div>

                            </div>
                            <div class="col-8 pr-5">
                                <div class="border-bottom font-weight-bold py-3">Profile</div>

                                <div class="row py-3 border-bottom">
                                    <div class="col-6 ">
                                        First Name
                                    </div>
                                    <div class="col">
                                        {{userInfo.first_name}}
                                    </div>
                                </div>
                                <div class="row py-3 border-bottom">
                                    <div class="col-6 ">
                                        Last Name
                                    </div>
                                    <div class="col">
                                        {{userInfo.last_name}}
                                    </div>
                                </div>

                                <div class="row py-3 border-bottom">
                                    <div class="col-6 ">
                                        Username
                                    </div>
                                    <div class="col">
                                        {{userInfo.username}}
                                    </div>
                                </div>

                                <div class="row py-3 border-bottom">
                                    <div class="col-6 ">
                                        Can Transact
                                    </div>
                                    <div class="col-6">
                                        {{userInfo.can_user_transact}}
                                    </div>
                                </div>
                                <div class="row py-3 border-bottom">
                                    <div class="col-6 ">
                                        Mrz Verified
                                    </div>
                                    <div class="col-6">
                                        {{userInfo.is_mrz_verified}}
                                    </div>
                                </div>

                                <div class="row py-3 border-bottom">
                                    <div class="col-6 ">
                                        Email
                                    </div>
                                    <div class="col">
                                        {{userInfo.email}}
                                    </div>
                                    <div class="col">
                                        <button
                                            type="button"
                                            @click="changeAgentInfoModal(userInfo, 'email')"
                                            class="btn btn-sm btn-info" >
                                            Edit <i class="fa fa-edit"></i>
                                        </button>
                                    </div>
                                </div>
                                <div v-if="userInfo.rank != 'Customer'"  class="row py-3 border-bottom">
                                    <div class="col-6 ">
                                        Address
                                    </div>
                                    <div class="col">
                                        {{userInfo.town_name}}
                                    </div>
                                </div>
                                <div class="row py-3 border-bottom">
                                    <div class="col-6 ">
                                        Phone Number
                                    </div>
                                    <div class="col">
                                       {{userInfo.phone_number}}
                                    </div>
                                </div>
                                <div v-if="userInfo.rank != 'Customer'" class="row py-3 border-bottom">
                                    <div class="col-6 ">
                                        Company
                                    </div>
                                    <div class="col">
                                       {{userInfo.company_name}}
                                    </div>
                                </div>
                                <div v-if="userInfo.rank != 'Customer'" class="row py-3 border-bottom">
                                    <div class="col-6 ">
                                        Company branch
                                    </div>
                                    <div class="col">
                                       {{userInfo.branch}}
                                    </div>
                                    <div class="col">
                                        <button
                                            type="button"
                                            @click="changeAgentInfoModal(userInfo, 'branch')"
                                            class="btn btn-sm btn-info" >
                                            Edit <i class="fa fa-edit"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="row py-3 border-bottom">
                                    <div class="col-6 ">
                                        Pin
                                    </div>
                                    <div class="col">
                                       {{userInfo.pin}}
                                    </div>
                                    <div class="col">
                                        <button
                                            type="button"
                                            @click="changeAgentInfoModal(userInfo, 'pin')"
                                            class="btn btn-sm btn-info" >
                                            Edit <i class="fa fa-edit"></i>
                                        </button>
                                    </div>
                                </div>


                            </div>

                            <div class="col-4">
                                <div class="border-bottom font-weight-bold py-3 mb-3">Avatar</div>

                                <div class="image">
                                    <img class="img-fluid pad"
                                    :src="'/images/profiles/' + userInfo.picture"
                                     alt="">
                                </div>
                                <!-- <div v-if="canEdit" class="btn btn-info w-100">
                                    Change profile picture
                                </div> -->
                            </div>
                        </div>
                    </Tab>

                    <Tab v-if="$gate.isBroker() && userInfo.rank === 'Customer'" :isSelected="selected === 'Transfers' ">
                       <CustomerTransfers :customer_id='userInfo.id' />
                    </Tab>

                    <Tab v-if="$gate.isBroker()" :isSelected="selected === 'Deposits' ">
                       <CustomerDeposits :customer_id='userInfo.id' />

                    </Tab>

                    <Tab  v-if="$gate.isBroker()" :isSelected="selected === 'Bank Accounts' ">
                        bban
                    </Tab>

                    <Tab  v-if="$gate.isBroker()" :isSelected="selected === 'Logs' ">
                        <CustomerLogs :customer_id="userInfo.id"/>

                    </Tab>

                </TabNav>

                </div>

            </fieldset>
        </div>

        <div
            class="modal fade"
            id="changeAgentEmailModal"
            role="dialog"
            aria-labelledby="changeAgentEmailTitle"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <form method="post" @submit.prevent="changeAgentEmail">
                        <div class="modal-header border-0 p-0 m-0">
                            <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            >
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <h3 class="swal2-title">Change Agent's Email</h3>
                            <h6>Current Email: <b>{{ this.userInfo.email }}</b></h6>
                            <div class="form-group pt-3">
                                <div
                                    class="text-danger"
                                    v-if="change_agent_email_form.errors.has('email')"
                                    v-html="change_agent_email_form.errors.get('email')" />

                                <label for="inputEmail3">New Email</label>
                                <input
                                    type="email"
                                    class="form-control"
                                    id="inputEmail3"
                                    required
                                    v-model="change_agent_email_form.email" />
                            </div>
                            <div
                            class="modal-footer border-0 d-flex justify-content-center"
                            >
                            <button
                                type="submit"
                                class="btn custom-button-2"
                            >
                                Submit
                            </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div
            class="modal fade"
            id="changeAgentBranchModal"
            role="dialog"
            aria-labelledby="changeAgentBranchTitle"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <form method="post" @submit.prevent="changeAgentBranch">
                        <div class="modal-header border-0 p-0 m-0">
                            <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            >
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <h3 class="swal2-title">Change Agent's Branch</h3>
                            <h6>Current Branch: <b>{{ this.userInfo.branch }}</b></h6>
                            <h3 class="pt-3">Pick Branch</h3>
                            <div class="input-group pt-1">
                            <select
                                v-model="change_agent_branch_form.branch_id"
                                class="form-control"
                                name=""
                                id=""
                            >
                                <option value="" selected disabled>---</option>
                                <option
                                v-for='(ag_br) in this.userInfo.branches'
                                :key='ag_br.id'
                                :value='ag_br.id'
                                >
                                {{ ag_br.town_name }} ({{ ag_br.city_name }})
                                </option>
                            </select>
                            </div>
                            <div
                            class="modal-footer border-0 d-flex justify-content-center"
                            >
                            <button
                                type="submit"
                                class="btn custom-button-2"
                            >
                                Submit
                            </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div
            class="modal fade"
            id="changeAgentPinModal"
            role="dialog"
            aria-labelledby="changeAgentPinTitle"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <form method="post" @submit.prevent="changeAgentPin">
                        <div class="modal-header border-0 p-0 m-0">
                            <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            >
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <h3 class="swal2-title">Change Agent's Pin</h3>
                            <h6>Current Pin Set ? <b>{{ this.userInfo.pin }}</b></h6>
                            <div class="form-group pt-3">
                                <div
                                    class="text-danger"
                                    v-if="change_agent_pin_form.errors.has('pin')"
                                    v-html="change_agent_pin_form.errors.get('pin')" />

                                <label for="inputPin3">New Pin</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    id="inputPin3"
                                    required
                                    v-model="change_agent_pin_form.pin" />
                            </div>
                            <div
                            class="modal-footer border-0 d-flex justify-content-center"
                            >
                            <button
                                type="submit"
                                class="btn custom-button-2"
                            >
                                Submit
                            </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>


<script>



export default {
    name: 'ViewCustomerProfile',
    props:{

    },
  components: {
    AgentProfile: () => import("../../includes/AgentProfile.vue" /* webpackChunkName: "js/agent-profile" */),
    TabNav: () => import("../../includes/tabNav" /* webpackChunkName: "js/tab-nav" */),
    Tab: () => import("../../includes/tabs" /* webpackChunkName: "js/tabs" */),
    CustomerTransfers: () => import("./CustomerTransfers.vue" /* webpackChunkName: "js/customer-transfers" */),
    CustomerDeposits: () => import("./CustomerDeposits.vue" /* webpackChunkName: "js/customer-transfers" */),
    CustomerLogs:     () => import("./CustomerLogs.vue"),
},

  data() {
    return {
        selected: 'General',
        tabs: ['General'],
        logs: [],
        pageData: {},
        userInfo: {},
        change_agent_email_form: new Form({
            agent_id: "",
            email: "",
        }),
        change_agent_branch_form: new Form({
            agent_id: "",
            agent_name: "",
            branch_id: "",
            current_branch: "",
            current_branch_id: "",
        }),
        change_agent_pin_form: new Form({
            agent_id: "",
            pin: "",
        }),
    }
  },
  created(){
      // fetch fee table
    this.getUser()


  },
    watch: {
        selected: function(){



        },

    },
  methods:{
        setSelected(tab){
            this.selected = tab;

        },

        getUser(){
            this.$isLoading(true)

            let temp_form = new Form({
                id:  this.$route.params.id
            })

            temp_form.post('/api/profile/getUserProfile')
                .then(({data}) => {
                    this.userInfo = data
                    if(this.$gate.isBroker()){
                        if(this.userInfo.rank == 'Customer'){
                        this.tabs = ['General', 'Transfers', 'Deposits', 'Bank Accounts', 'Logs']

                        }

                    }

                        this.$isLoading(false)

                }).catch((err)=>{
                        this.$isLoading(false)

            })
        },
        changeAgentInfoModal(info, modal) {
            if (modal == 'email') {
                this.change_agent_email_form.agent_id = info.id;
                $('#changeAgentEmailModal').modal('show');
            }
            else if (modal == 'branch') {
                this.change_agent_branch_form.agent_id = info.id;
                this.change_agent_branch_form.agent_name = info.first_name +" "+ info.last_name;
                this.change_agent_branch_form.branch_id = info.branch_id;
                this.change_agent_branch_form.current_branch_id = info.branch_id;
                this.change_agent_branch_form.current_branch = info.branch;
                $('#changeAgentBranchModal').modal('show');
            }
            else if (modal == 'pin') {
                this.change_agent_pin_form.agent_id = info.id;
                $('#changeAgentPinModal').modal('show');
            }
        },

        changeAgentEmail() {
            const confirmSubmit = Swal.mixin({
                customClass: {
                confirmButton: "btn custom-button bg-danger mr-2",
                cancelButton: "btn custom-button-2 ml-2",
                },
                buttonsStyling: false,
            });
            confirmSubmit.fire({
                title: "Are you sure?",
                text:
                    'You are changing "' +
                    this.userInfo.first_name + ' ' + this.userInfo.last_name +
                    '\'s" email to "' + this.change_agent_email_form.email,
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes, change!",
            })
            .then((resp) => {
                if (resp.isConfirmed) {
                    // prompt for password
                    const confirmPwd = Swal.mixin({
                        customClass: {
                            confirmButton: "btn custom-button bg-danger  mr-2",
                            cancelButton: "btn custom-button-2 ml-2",
                        },
                        buttonsStyling: false,
                    });
                    confirmPwd.fire({
                        title: "Enter your password",
                        text: 'To confirm that you want to change "' +
                        this.userInfo.first_name + ' ' + this.userInfo.last_name +
                            '\'s" email to "' + this.change_agent_email_form.email,
                        input: "password",
                        inputPlaceholder: 'Enter your password',
                        inputAttributes: {
                            required: true,
                        },
                        inputValidator: (value) => {
                            if (!value) {
                                return "You need to enter your password!";
                            }
                        },
                        showCancelButton: true,
                        confirmButtonText: "Submit",
                        showLoaderOnConfirm: true,
                    })
                    .then(((res) => {
                        if (res.isConfirmed) {
                            this.change_agent_email_form.admin_password = res.value;
                            this.$isLoading(true);

                            this.change_agent_email_form
                            .post("/api/profile/changeAgentEmail", {
                                headers: { "Content-Type": "application/json" },
                            })
                            .then((data) => {
                                this.$isLoading(false);

                                const confirmBox2 = Swal.mixin({
                                    customClass: {
                                    confirmButton: "btn custom-button-2",
                                    },
                                    buttonsStyling: false,
                                });
                                confirmBox2.fire({
                                    icon: "success",
                                    title: "Agent Email Changed!",
                                })
                                .then((res) => {
                                    window.location.reload();
                                });
                            })
                            .catch((err) => {
                                this.$isLoading(false);

                                // show them if that city name is taken
                                let errorText = "Agent Email Change Unsuccessful";

                                if (err.response.data.errors["password"]) {
                                    errorText = err.response.data.errors["password"];
                                } else if (err.response.data.errors["general"]) {
                                    errorText = err.response.data.errors["general"];
                                }

                                const confirmBox2 = Swal.mixin({
                                    customClass: {
                                    confirmButton: "btn custom-button bg-danger",
                                    },
                                    buttonsStyling: true,
                                });

                                confirmBox2.fire({
                                    icon: "error",
                                    title: "Failed!",
                                    text: errorText,
                                });
                            });
                        }
                    }));
                }
            });
        },

        changeAgentBranch() {
            let selected_branch = this.userInfo.branches.filter(
                (d) => d.id == this.change_agent_branch_form.branch_id
            )[0];
            let branch_name = selected_branch["town_name"] +" ("+ selected_branch["city_name"]+")";

            const confirmSubmit = Swal.mixin({
                customClass: {
                confirmButton: "btn custom-button bg-danger mr-2",
                cancelButton: "btn custom-button-2 ml-2",
                },
                buttonsStyling: false,
            });
            confirmSubmit.fire({
                title: "Are you sure?",
                text:
                    'You are changing "' +
                    this.change_agent_branch_form.agent_name +
                    '\'s" branch to "' +
                    branch_name,
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes, change!",
            })
            .then((resp) => {
                if (resp.isConfirmed) {
                    // prompt for password
                    const confirmPwd = Swal.mixin({
                        customClass: {
                            confirmButton: "btn custom-button bg-danger  mr-2",
                            cancelButton: "btn custom-button-2 ml-2",
                        },
                        buttonsStyling: false,
                    });
                    confirmPwd.fire({
                        title: "Enter your password",
                        text: 'To confirm that you want to change "' +
                            this.change_agent_branch_form.agent_name +
                            '\'s" branch to "' +
                            branch_name,
                        input: "password",
                        inputPlaceholder: 'Enter your password',
                        inputAttributes: {
                            required: true,
                        },
                        inputValidator: (value) => {
                            if (!value) {
                                return "You need to enter your password!";
                            }
                        },
                        showCancelButton: true,
                        confirmButtonText: "Submit",
                        showLoaderOnConfirm: true,
                    })
                    .then(((res) => {
                        if (res.isConfirmed) {
                            this.change_agent_branch_form.admin_password = res.value;
                            this.$isLoading(true);

                            this.change_agent_branch_form
                            .post("/api/agent/changeAgentBranch", {
                                headers: { "Content-Type": "application/json" },
                            })
                            .then((data) => {
                                this.$isLoading(false);

                                const confirmBox2 = Swal.mixin({
                                    customClass: {
                                    confirmButton: "btn custom-button-2",
                                    },
                                    buttonsStyling: false,
                                });
                                confirmBox2.fire({
                                    icon: "success",
                                    title: "Agent Branch Changed!",
                                })
                                .then((res) => {
                                    window.location.reload();
                                });
                            })
                            .catch((err) => {
                                this.$isLoading(false);

                                // show them if that city name is taken
                                let errorText = "Agent Branch Change Unsuccessful";

                                if (err.response.data.errors["password"]) {
                                    errorText = err.response.data.errors["password"];
                                } else if (err.response.data.errors["general"]) {
                                    errorText = err.response.data.errors["general"];
                                }

                                const confirmBox2 = Swal.mixin({
                                    customClass: {
                                    confirmButton: "btn custom-button bg-danger",
                                    },
                                    buttonsStyling: true,
                                });

                                confirmBox2.fire({
                                    icon: "error",
                                    title: "Failed!",
                                    text: errorText,
                                });
                            });
                        }
                    }));
                }
            });
        },

        changeAgentPin() {
            const confirmSubmit = Swal.mixin({
                customClass: {
                confirmButton: "btn custom-button bg-danger mr-2",
                cancelButton: "btn custom-button-2 ml-2",
                },
                buttonsStyling: false,
            });
            confirmSubmit.fire({
                title: "Are you sure?",
                text:
                    'You are changing "' +
                    this.userInfo.first_name + ' ' + this.userInfo.last_name +
                    '\'s" PIN to "' + this.change_agent_pin_form.pin,
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes, change!",
            })
            .then((resp) => {
                if (resp.isConfirmed) {
                    // prompt for password
                    const confirmPwd = Swal.mixin({
                        customClass: {
                            confirmButton: "btn custom-button bg-danger  mr-2",
                            cancelButton: "btn custom-button-2 ml-2",
                        },
                        buttonsStyling: false,
                    });
                    confirmPwd.fire({
                        title: "Enter your password",
                        text: 'To confirm that you want to change "' +
                        this.userInfo.first_name + ' ' + this.userInfo.last_name +
                            '\'s" PIN to "' + this.change_agent_pin_form.pin,
                        input: "password",
                        inputPlaceholder: 'Enter your password',
                        inputAttributes: {
                            required: true,
                        },
                        inputValidator: (value) => {
                            if (!value) {
                                return "You need to enter your password!";
                            }
                        },
                        showCancelButton: true,
                        confirmButtonText: "Submit",
                        showLoaderOnConfirm: true,
                    })
                    .then(((res) => {
                        if (res.isConfirmed) {
                            this.change_agent_pin_form.admin_password = res.value;
                            this.$isLoading(true);

                            this.change_agent_pin_form
                            .post("/api/profile/changeAgentPin", {
                                headers: { "Content-Type": "application/json" },
                            })
                            .then((data) => {
                                this.$isLoading(false);

                                const confirmBox2 = Swal.mixin({
                                    customClass: {
                                    confirmButton: "btn custom-button-2",
                                    },
                                    buttonsStyling: false,
                                });
                                confirmBox2.fire({
                                    icon: "success",
                                    title: "Agent PIN Changed!",
                                })
                                .then((res) => {
                                    window.location.reload();
                                });
                            })
                            .catch((err) => {
                                this.$isLoading(false);

                                // show them if that city name is taken
                                let errorText = "Agent PIN Change Unsuccessful";

                                if (err.response.data.errors["password"]) {
                                    errorText = err.response.data.errors["password"];
                                } else if (err.response.data.errors["general"]) {
                                    errorText = err.response.data.errors["general"];
                                }

                                const confirmBox2 = Swal.mixin({
                                    customClass: {
                                    confirmButton: "btn custom-button bg-danger",
                                    },
                                    buttonsStyling: true,
                                });

                                confirmBox2.fire({
                                    icon: "error",
                                    title: "Failed!",
                                    text: errorText,
                                });
                            });
                        }
                    }));
                }
            });
        }
  },

}
</script>
