<template>
  <div v-if="$gate.isMerchantAdmin()">
    <div v-if="!$gate.isRegistrationComplete()">
      <div class="card px-4 py-2 border-0" style="box-shadow: none">
        <div class="card-body text-center">
          <h3>Congratulations for joining!</h3>
          <div style="font-size: 1.2rem">
            To be able to send and receive money, you must
            <router-link
              to="/set-pin"
              class="text-info"
              style="text-decoration: underline"
            >
              set your pin</router-link
            >
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="
        getUserProfile.phone_number !== null &&
        getUserProfile.verified_at === null
      "
    >
      <div class="card px-4 py-2 border-0" style="box-shadow: none">
        <div class="card-body text-center">
          <a
            href="sms-verification"
            style="text-decoration: underline"
            class="card-body border-bottom"
          >
            <div class="in">
              <div>Click here to Verify Phone Number</div>
            </div>
          </a>
        </div>
      </div>
    </div>

    <div
      class="customer general"
      v-else-if="$gate.isRegistrationComplete() && $gate.canTransact()"
    >
      <div class="row">
        <div class="col-12">
          <div class="section wallet-card-section pt-1">
            <div class="wallet-card">
              <!-- Balance -->
              <div class="balance">
                <div class="left">
                  <span class="title">Total Balance</span>
                  <h1 class="total">{{ balance }}</h1>
                </div>
                <!-- <div
                                    class="d-flex justify-content-center align-items-center"
                                >
                                    <a
                                        href="#"
                                        class="py-2 py-md-3 px-3 px-md-4 bg-green1 add-cash"
                                        data-toggle="modal"
                                        data-target="#increaseCashOnHandModal"
                                    >
                                        <i class="fas fa-plus text-white"></i>
                                    </a>
                                </div> -->
              </div>
              <!-- * Balance -->
              <!-- Wallet Footer -->
              <div class="wallet-footer">
                <div class="item">
                  <router-link to="/withdraw/0">
                    <div class="icon-wrapper bg-danger">
                      <i
                        role="img"
                        class="fas fa-arrow-down md hydrated"
                        aria-label="add outline"
                      ></i>
                    </div>
                    <strong>Withdraw</strong>
                  </router-link>
                </div>
                <a href="#" data-toggle="modal" data-target="#sendModal">
                  <div class="icon-wrapper bg-ogreen1">
                    <i
                      role="img"
                      class="fas fa-arrow-right md hydrated"
                      aria-label="add outline"
                    ></i>
                  </div>
                  <strong>Send</strong>
                </a>
                <div class="item">
                  <!-- <a class="" href="app-cards.html">
                                        <div class="icon-wrapper bg-info">
                                            <i
                                                role="img"
                                                class="fas fa-credit-card md hydrated"
                                                aria-label="add outline"
                                            ></i>
                                        </div>
                                        <strong>My Bank Accounts</strong>
                                    </a> -->
                </div>
              </div>
              <!-- * Wallet Footer -->
            </div>
          </div>
        </div>
      </div>

      <!-- transaction types graph -->
      <div v-if="years.length > 0" class="row mt-3">
        <div class="col-md-12 col-lg-8">
          <div class="card">
            <div class="row">
              <div class="col-12">
                <h3 class="px-3 py-2 py-lg-3 card-title">Money Traffic</h3>
              </div>
              <div class="col-12 text-muted">
                <TabNav
                  :tabs="years"
                  :selected="selected"
                  @selected="setSelected"
                >
                </TabNav>
              </div>
              <div class="col-12">
                <div class="mt-3 d-flex justify-content-end mr-3">
                  <div class="">
                    <select
                      v-model="selectedStat"
                      id="stats"
                      class="form-control text-sm"
                    >
                      <option value="" disabled>Select Stats Type</option>
                      <option value="Transfers">Transfers</option>
                      <option value="Deposits">Deposits</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="text-center py-3 font-weight-bold">
                  {{ chart1Title }}
                </div>

                <div v-if="selectedStat == 'Transfers'" class="row mb-4 ml-3">
                  <div class="d-flex justify-content-between flex-wrap text-xs">
                    <div class="pr-2">
                      <span class="text-muted">Incoming Total: </span>
                      <span
                        class="font-weight-bold"
                        style="color: rgba(81, 139, 201, 1)"
                      >
                        {{
                          dalasisFormatter(
                            chart1Selection["incoming"].reduce(
                              (total, num) => total + num,
                              0
                            )
                          )
                        }}
                      </span>
                    </div>

                    <div class="pr-2">
                      <span class="text-muted">Outgoing Total: </span>
                      <span
                        class="font-weight-bold"
                        style="color: rgba(148, 82, 165, 1)"
                      >
                        {{
                          dalasisFormatter(
                            chart1Selection["outgoing"].reduce(
                              (total, num) => total + num,
                              0
                            )
                          )
                        }}
                      </span>
                    </div>
                    <div class="pr-2">
                      <span class="text-muted">Fees Total: </span>
                      <span
                        class="font-weight-bold"
                        style="color: rgba(187, 46, 114, 1)"
                      >
                        {{
                          dalasisFormatter(
                            chart1Selection["fees"].reduce(
                              (total, num) => total + num,
                              0
                            )
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  v-else-if="selectedStat == 'Deposits'"
                  class="row mb-4 ml-3"
                >
                  <div class="d-flex justify-content-between flex-wrap text-xs">
                    <div class="pr-2">
                      <span class="text-muted">Completed Total: </span>
                      <span
                        class="font-weight-bold"
                        style="color: rgba(50, 109, 76, 1)"
                      >
                        {{
                          dalasisFormatter(
                            chart1Selection["completed"].reduce(
                              (total, num) => total + num,
                              0
                            )
                          )
                        }}
                      </span>
                    </div>
                    <div class="pr-2">
                      <span class="text-muted">Pending Total: </span>
                      <span
                        class="font-weight-bold"
                        style="color: rgba(240, 147, 57, 0.8)"
                      >
                        {{
                          dalasisFormatter(
                            chart1Selection["pending"].reduce(
                              (total, num) => total + num,
                              0
                            )
                          )
                        }}
                      </span>
                    </div>
                    <div class="">
                      <span class="text-muted">Cancelled Total: </span>
                      <span
                        class="font-weight-bold"
                        style="color: rgba(188, 38, 37, 1)"
                      >
                        {{
                          dalasisFormatter(
                            chart1Selection["cancelled"].reduce(
                              (total, num) => total + num,
                              0
                            )
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>

                <MChart1 :chartData="chart1Selection" />
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 col-lg-4">
          <!-- DIRECT CHAT -->
          <div class="card direct-chat direct-chat-primary">
            <div class="card-header bg-white">
              <h3 class="card-title">Transactions Rankings</h3>

              <div class="card-tools">
                <button
                  type="button"
                  class="btn btn-tool"
                  data-card-widget="collapse"
                >
                  <i class="fas fa-minus"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-tool"
                  data-toggle="tooltip"
                  title="Contacts"
                  data-widget="chat-pane-toggle"
                >
                  <i class="fas fa-medal"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-tool"
                  data-card-widget="remove"
                >
                  <i class="fas fa-times"></i>
                </button>
              </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body">
              <!-- Conversations are loaded here -->
              <div class="direct-chat-messages">
                <div class="card-body table-responsive p-0">
                  <table class="table table-striped table-valign-middle">
                    <thead>
                      <tr>
                        <th>Rank</th>
                        <th>Category</th>
                        <th>{{ rankBy }} Amount</th>
                        <!-- <th>More</th> -->
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(rank, index) in transactionRankings"
                        :key="index"
                      >
                        <td>{{ ++index }}</td>
                        <td>
                          <i
                            v-if="index <= 3"
                            :class="[
                              index == 1
                                ? 'text-orange'
                                : index == 2
                                ? 'text-gray'
                                : index == 3
                                ? 'text-bronze'
                                : '',
                              'fas fa-crown',
                            ]"
                          >
                          </i>

                          {{ rank.type }}
                        </td>
                        <td>
                          {{ dalasisFormatter(rank.amount) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!--/.direct-chat-messages-->

              <!-- Contacts are loaded here -->
              <div class="direct-chat-contacts">
                <ul class="contacts-list">
                  <li><span>Rank by</span></li>
                  <li class="ml-3">
                    <a
                      class="font-weight-bold"
                      @click="setRankBy('Incoming')"
                      href="#"
                      data-toggle="tooltip"
                      title="Contacts"
                      data-widget="chat-pane-toggle"
                    >
                      Incoming
                    </a>
                  </li>
                  <li class="ml-3">
                    <a
                      class="font-weight-bold"
                      @click="setRankBy('Outgoing')"
                      href="#"
                      data-toggle="tooltip"
                      title="Contacts"
                      data-widget="chat-pane-toggle"
                    >
                      Outgoing
                    </a>
                  </li>

                  <!-- End Contact Item -->
                </ul>
                <!-- /.contacts-list -->
              </div>
              <!-- /.direct-chat-pane -->
            </div>
            <!-- /.card-body -->

            <!-- /.card-footer-->
          </div>
          <!--/.direct-chat -->
        </div>
      </div>

      <Send :getUserProfile="getUserProfile" />
      <AgencyFooter :getUserProfile="getUserProfile" />
    </div>
  </div>
  <div v-else>
    <not-found></not-found>
  </div>
</template>

<script>
export default {
  name: "Dashboard",
  components: {
    TabNav: () =>
      import("./../../includes/tabNav" /* webpackChunkName: "js/tab-nav" */),
    Tab: () =>
      import("./../../includes/tabs" /* webpackChunkName: "js/tabs" */),
    MChart1: () =>
      import(
        "./../../charts/merchant/MChart1.vue" /* webpackChunkName: "js/m-chart-1" */
      ),
  },
  data() {
    return {
      selected: "",
      transactionRankings: [],
      userInfo: "",
      transfers: [],
      transfers_count: 0,
      balance: 0,
      years: [],
      chart1Title: "Money Traffic",
      chart1Selection: {
        type: "Transfers",
        incoming: [0],
        outgoing: [0],
        deposits: [0],
        fees: [0],
        labels: [],
      },
      rankBy: "Incoming",
      selectedStat: "Transfers",
    };
  },
  created() {
    if (
      this.$gate.isMerchantAdmin() &&
      this.$gate.isRegistrationComplete() &&
      this.$gate.canTransact()
    ) {
      this.getYears();
      this.rankings();
    }
  },
  watch: {
    getUserProfile: function () {
      this.userInfo = this.getUserProfile;
      this.balance = this.dalasisFormatter(this.userInfo.balance);
      console.log("user info", this.userInfo);
      document.getElementById("bladeAmount").innerHTML = this.balance;
      console.log(this.getUserProfile);
    },
    selected: function () {
      if (this.selected.length != 0) {
        this.getChart1();
      }
    },
    selectedStat: function () {
      if (this.selectedStat.length != 0) {
        this.getChart1();
      }
    },
  },

  mounted() {
    this.$store.dispatch("userProfile");

    console.log(this.$gate.isMerchant());

    // axios.get('/api/merchantAdmins/approvedMerchants').then((data)=>{
    //     console.log("merchants", data)
    // }).catch((error)=>{
    //     console.log(error)
    // })
  },
  computed: {
    getUserProfile() {
      //final output from here
      return this.$store.getters.getUserProfile;
    },
  },
  methods: {
    // openSendModal() {
    //     const confirmBox1 = Swal.mixin({
    //         customClass: {
    //             confirmButton: "btn custom-button bg-danger mr-2",
    //             cancelButton: "btn custom-button-2 ml-2",
    //         },
    //         buttonsStyling: false,
    //     });

    //     confirmBox1
    //         .fire({
    //             title: "Are you sure?",
    //             html: "You want to withdraw to your campaign money or personal?",
    //             icon: "warning",
    //             showCancelButton: true,
    //             confirmButtonText: "Campaign",
    //             cancelButtonText: "Personal",
    //         })
    //         .then((result1) => {
    //             if (result1.isConfirmed) {
    //                 console.log(result1.isConfirmed);
    //                 $("#sendModal").modal("show");
    //                 $("#send_type").val(1);
    //             } else {
    //                 $("#sendModal").modal("show");
    //                 $("#send_type").val(0);
    //             }
    //         });
    // },
    // openWithdrawModal() {
    //     const confirmBox1 = Swal.mixin({
    //         customClass: {
    //             confirmButton: "btn custom-button bg-danger mr-2",
    //             cancelButton: "btn custom-button-2 ml-2",
    //         },
    //         buttonsStyling: false,
    //     });

    //     confirmBox1
    //         .fire({
    //             title: "Are you sure?",
    //             html: "You want to withdraw to your campaign money or personal?",
    //             icon: "warning",
    //             showCancelButton: true,
    //             confirmButtonText: "Campaign",
    //             cancelButtonText: "Personal",
    //         })
    //         .then((result1) => {
    //             if (result1.isConfirmed) {
    //                 window.location.href = "/withdraw/1";
    //             } else {
    //                 window.location.href = "/withdraw/0";
    //             }
    //         });
    // },
    setSelected(tab) {
      this.selected = tab;
    },

    getYears() {
      axios
        .post("api/merchant-admin-dashboard/years")
        .then(({ data }) => {
          data.forEach((element) => {
            element.forEach((e) => {
              if (!this.years.includes(String(e))) {
                this.years.push(String(e));
              }
            });
          });

          if (this.years.length > 0) {
            this.selected = this.years[0];
          }
        })
        .catch((err) => {});
    },
    rankings() {
      let form = new Form({
        rank_by: this.rankBy,
      });
      form
        .post("api/merchant-admin-dashboard/transactionRankings")
        .then(({ data }) => {
          this.transactionRankings = data;
        })
        .catch((err) => {});
    },
    setRankBy(rb) {
      this.rankBy = rb;
      this.transactionRankings = [];
      this.rankings();
    },

    getChart1() {
      this.chart1Title = this.selectedStat + " (" + this.selected + ")";

      let form = new Form({
        year: this.selected,
        type: this.selectedStat,
      });

      this.$isLoading(true);

      form
        .post("api/merchant-admin-dashboard/chart1")
        .then(({ data }) => {
          this.chart1Selection = { ...data };

          this.$isLoading(false);
        })
        .catch((err) => {
          this.$isLoading(false);
        });
    },
  },
};
</script>
