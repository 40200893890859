<template>
    <div>
        <div v-if="$gate.isBroker()" class="card  custom-card-1 m-0">
          <form class="form-horizontal" action="" @submit.prevent='submitChanges' method="post">

            <fieldset>
                <legend class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center">
                     <span>Set Bank Deposite & Withdraw Fee </span>

                    </div>
                   <a href="#" class="nav-link" active-class="active" onclick="history.go(-1);return false;">
                       <button class="btn bg-nyanza font-weight-bold text-gray" >Go back</button>
                    </a>
                </legend>
                    <div class="card-header">
                    </div>


                    <div class="card-body">


                        <div class="mb-2">
                                <div class="text-danger" v-if="form.errors.has('entries')" v-html="form.errors.get('entries')" />
                        </div>


                        <div class="table-responsive">
                            <table id="example2" class="table table-bordered table-hover">
                                <thead>
                                <tr class="text-center">
                                <th></th>
                                <th>Deposite Fee %</th>
                                <th>Withdraw Fee %</th>
                         
                                </tr>
                                </thead>
                                <tbody>
                                <tr class='text-center'>
                                <td>1</td>
                                <td class="text-emerald font-weight-bold">
                                    <input   type="number"  id='amount' required v-model="form.deposite_fee"  placeholder="Enter fee in percentage" class="form-control">
                                </td>
                                <td class="text-emerald font-weight-bold">
                                    <input   type="number"  id='amount' required v-model="form.withdraw_fee"  placeholder="Enter fee in percentage" class="form-control">
                                </td>

                                

                                </tr>
                                </tbody>
                            </table>


                        </div>


                        <div class="mt-3 d-flex justify-content-center">
                            <button type="submit" :disabled="form.busy" class="btn custom-button-1">
                                SUBMIT
                            </button>
                        </div>

                    </div>




            </fieldset>

          </form>







        </div>

        <div v-else>
            <not-found></not-found>

        </div>
    </div>
</template>


<script>

export default {
    name: 'BankFee',
    props:{

    },
  components: {

  },
  data() {
    return {
        form: new Form({
            password: '',
            deposite_fee: '',
            withdraw_fee: '',
          

        }),
        feeTable: [],



    }
  },
  created(){
            this.$isLoading(true)

            axios.get('api/bank-deposite-withdraw-fee')
                .then((response) => {
                  this.form.deposite_fee = response.data.deposite_fee;
              
                  this.form.withdraw_fee = response.data.withdraw_fee;
 
                  this.$isLoading(false)



                }).catch((err)=>{
                  this.$isLoading(false)


                })

  },
  mounted() {
    console.log(this.form.endDate > Date.now());

  },
    computed: {
        expiredDate() {
     if(new Date(this.form.endDate) > Date.now()){
        return false;
     } else{
        return true;
     }
    },


    },
    watch:{

    },
  methods:{

      submitChanges(){
        if(this.$gate.isBroker()){


            const box1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button-2 mr-2',
                denyButton: 'btn custom-button bg-secondary ml-2'
            },
            buttonsStyling: false
            })

            box1.fire({
                title: 'Enter your password',
                text: 'To confirm your changes',
                input: 'password',
                inputAttributes: {
                    required: true,
                },
                inputValidator: (value) => {
                    if (!value) {
                    return 'You need to enter your password!'
                    }

                },
                showDenyButton: true,
                denyButtonText: `Back`,
                confirmButtonText: 'Submit',
                }).then((result) => {
                if (result.isConfirmed) {

                this.form.password = result.value

                  this.$isLoading(true)

                this.form.post('api/bank-deposite-withdraw-fee',{headers:{"Content-Type":"application/json"}})
                    .then((data) => {
                  this.$isLoading(false)

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button-2',

                        },
                        buttonsStyling: false
                        })


                        confirmBox2.fire({
                        icon: 'success',
                        title: 'Bank Fee Configuration Successful!',
                        text: `Bank fee is Successfully saved!`

                        }).then((res)=>{
                            // redirect user to dashboard
                            $this.router.push('/bank-fee')

                        })



                    })
                    .catch((err) => {
                  this.$isLoading(false)

                        let errorText = 'Fee Table Configuration Unsuccessful'

                        if(err.response.data.errors['password']){
                            errorText = err.response.data.errors['password']
                        }

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button bg-danger',

                        },
                        buttonsStyling: true
                        })

                        confirmBox2.fire({
                        icon: 'error',
                        title: 'Failed!',
                        text: errorText,

                        })

                    });


                    }

                })

            }

      },

  },

}
</script>
