<template>
  <div>
    <div class="card custom-card-1 m-0">
      <fieldset>
        <legend class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center">
            <span>
              Merchant Profile ( {{ userInfo.first_name }}
              {{ userInfo.last_name }} )</span
            >
          </div>
          <a
            href="#"
            class="nav-link"
            active-class="active"
            onclick="history.go(-1);return false;"
          >
            <button class="btn bg-nyanza font-weight-bold text-gray">
              Go back
            </button>
          </a>
        </legend>

        <div class="card-header"></div>

        <div>
          <TabNav :tabs="tabs" :selected="selected" @selected="setSelected">
            <Tab :isSelected="selected === 'General'">
              <div class="row mt-5">
                <div class="col-12">
                  <div class="border-bottom pb-3"><h5>General</h5></div>
                </div>
                <div class="col-8 pr-5">
                  <div class="border-bottom font-weight-bold py-3">Profile</div>

                  <div class="row py-3 border-bottom">
                    <div class="col-6">First Name</div>
                    <div class="col">
                      {{ userInfo.first_name }}
                    </div>
                  </div>
                  <div class="row py-3 border-bottom">
                    <div class="col-6">Last Name</div>
                    <div class="col">
                      {{ userInfo.last_name }}
                    </div>
                  </div>

                  <div class="row py-3 border-bottom">
                    <div class="col-6">Username</div>
                    <div class="col">
                      {{ userInfo.username }}
                    </div>
                  </div>

                  <div class="row py-3 border-bottom">
                    <div class="col-6">Can Transact</div>
                    <div class="col-6">
                      {{ userInfo.can_user_transact }}
                    </div>
                  </div>
                  <div class="row py-3 border-bottom">
                    <div class="col-6">Mrz Verified</div>
                    <div class="col-6">
                      {{ userInfo.is_mrz_verified }}
                    </div>
                  </div>

                  <div class="row py-3 border-bottom">
                    <div class="col-6">Email</div>
                    <div class="col">
                      {{ userInfo.email }}
                    </div>
                  </div>
                  <div
                    v-if="userInfo.rank != 'Customer'"
                    class="row py-3 border-bottom"
                  >
                    <div class="col-6">Address</div>
                    <div class="col">
                      {{ userInfo.town_name }}
                    </div>
                  </div>
                  <div class="row py-3 border-bottom">
                    <div class="col-6">Phone Number</div>
                    <div class="col">
                      {{ userInfo.phone_number }}
                    </div>
                  </div>
                  <div
                    v-if="userInfo.rank != 'Customer'"
                    class="row py-3 border-bottom"
                  >
                    <div class="col-6">Company</div>
                    <div class="col">
                      {{ userInfo.company_name }}
                    </div>
                  </div>
                  <div
                    v-if="userInfo.rank != 'Customer'"
                    class="row py-3 border-bottom"
                  >
                    <div class="col-6">Company branch</div>
                    <div class="col">
                      {{ userInfo.branch }}
                    </div>
                  </div>
                  <div class="row py-3 border-bottom">
                    <div class="col-6">Pin</div>
                    <div class="col">
                      {{ userInfo.pin }}
                    </div>
                  </div>
                </div>

                <div class="col-4">
                  <div class="border-bottom font-weight-bold py-3 mb-3">
                    Avatar
                  </div>

                  <div class="image">
                    <img
                      class="img-fluid pad"
                      :src="'/images/profiles/' + userInfo.picture"
                      alt=""
                    />
                  </div>
                  <!-- <div v-if="canEdit" class="btn btn-info w-100">
                                    Change profile picture
                                </div> -->
                </div>
              </div>
            </Tab>
            <Tab :isSelected="selected === 'Transactions'">
              <!--  -->
              <MerchantTransfers :merchant_id="userInfo.id" />
            </Tab>

            <!-- <Tab v-if="$gate.isBroker() && userInfo.rank === 'Customer'" :isSelected="selected === 'Transfers' ">
                       <CustomerTransfers :customer_id='userInfo.id' />
                    </Tab>

                    <Tab v-if="$gate.isBroker()" :isSelected="selected === 'Deposits' ">
                       <CustomerDeposits :customer_id='userInfo.id' />

                    </Tab>

                    <Tab  v-if="$gate.isBroker()" :isSelected="selected === 'Bank Accounts' ">
                        bban
                    </Tab>

                    <Tab  v-if="$gate.isBroker()" :isSelected="selected === 'Logs' ">
                    </Tab> -->
          </TabNav>
        </div>
      </fieldset>
    </div>
  </div>
</template>


<script>
import MerchantTransfers from "./MerchantTransfers.vue";
export default {
  name: "ViewMerchantProfile",

  components: {
    MerchantTransfers,
    AgentProfile: () =>
      import(
        "../../includes/AgentProfile.vue" /* webpackChunkName: "js/agent-profile" */
      ),
    TabNav: () =>
      import("../../includes/tabNav" /* webpackChunkName: "js/tab-nav" */),
    Tab: () => import("../../includes/tabs" /* webpackChunkName: "js/tabs" */),
    // CustomerTransfers:  () => import('./CustomerTransfers.vue' /* webpackChunkName: "js/customer-transfers" */),
    // CustomerDeposits:  () => import('./CustomerDeposits.vue' /* webpackChunkName: "js/customer-transfers" */),
  },
  data() {
    return {
      selected: "General",
      tabs: ["General", "Transactions"],
      logs: [],
      pageData: {},
      userInfo: {},
    };
  },
  created() {
    // fetch fee table
    this.getUser();
  },
  watch: {
    selected: function () {},
  },
  methods: {
    setSelected(tab) {
      this.selected = tab;
    },

    getUser() {
      this.$isLoading(true);

      let temp_form = new Form({
        id: this.$route.params.id,
      });

      temp_form
        .post("/api/profile/getUserProfile")
        .then(({ data }) => {
          this.userInfo = data;

          if (this.$gate.isBroker()) {
            if (this.userInfo.rank == "Customer") {
              this.tabs = ["General"];
            }
          } else if (this.$gate.isMerchantAdmin()) {
            if (this.userInfo.user_type == 5) {
              this.tabs = ["General"];
            }
          }

          this.$isLoading(false);
        })
        .catch((err) => {
          this.$isLoading(false);
        });
    },
  },
};
</script>
