<script>
import { Bar } from "vue-chartjs";

export default {
  extends: Bar,
  props: ["chartData"],
  data() {
    return {
      dataPoints: null,
      stackedX: false,
      stackedY: false,
      yAxisLabel: "Dalasis",
      xAxisLabel: "",
      yearLabels: [],
      barColors: [],
    };
  },
  mounted() {
    this.drawChart();
  },
  watch: {
    chartData: function () {
      this.drawChart();
    },
  },

  methods: {
    drawChart() {
      if (true) {
        this.xAxisLabel = this.chartData['x_axis'];
        this.renderChart(
          {
            labels: this.chartData["labels"],
            datasets: [
              {
                label: "Total investment ",
                backgroundColor: "rgba(15,45,54,0.6)",
                borderColor: "rgba(15,45,54,0.5)",
                pointRadius: false,
                pointColor: "#3b8bba",
                pointStrokeColor: "rgba(15,45,54,1)",
                pointHighlightFill: "#fff",
                pointHighlightStroke: "rgba(15,45,54,1)",
                data: this.chartData["total_investment"],
              },
              {
                label: "Total Fee Earnings",
                backgroundColor: "rgba(63,146,194, 0.6)",
                borderColor: "rgba(63,146,194, 0.6)",
                pointRadius: false,
                pointColor: "rgba(63,146,194, 1)",
                pointStrokeColor: "#c1c7d1",
                pointHighlightFill: "#fff",
                pointHighlightStroke: "rgba(140,80,160,1)",
                data: this.chartData["total_fee"],
              },
            ],
          },
          {
            scales: {
              yAxes: [
                {
                  stacked: this.stackedY,
                  scaleLabel: {
                    display: true,
                    labelString: this.yAxisLabel,
                  },
                  ticks: {
                    beginAtZero: true,
                  },
                  gridLines: {
                    display: false,
                  },
                },
              ],
              xAxes: [
                {
                  stacked: this.stackedX,
                  //   barPercentage: 0.5,
                  gridLines: {
                    display: false,
                  },
                  scaleLabel: {
                    display: true,
                    labelString: this.xAxisLabel,
                  },
                  // type: 'time',
                  // time: {
                  // unit: 'hour',
                  // displayFormats: {
                  //     hour: 'HH:mm'
                  // }
                  // }
                },
              ],
            },
            legend: {
              position: "bottom",
              display: true,
            },
            responsive: true,
            maintainAspectRatio: false,
          }
        );
      }
    },
  },
};
</script>