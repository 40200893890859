<template>
    <div :class="`${(campaign_id) ? '' : 'card p-5'}`">

            <fieldset>
                <legend class="d-flex justify-content-between flex-wrap">
                    <span class='d-flex align-items-center'>Outgoing Deposits</span>

                </legend>

                 <SearchFilter :data_length='deposits.length'
                 :filters='filters' data_type="outgoing" :isFound='isFound'
                 @search-form='makeSearch'
                 />
                <!-- /.card-header -->
                <div v-if="deposits.length != 0" class="card-body table-responsive">
                <table   id="example2" class="table table-bordered table-hover border-top">
                    <thead>
                    <tr class="text-center">
                    <th>ID</th>
                    <!-- <th>Distributor from</th> -->
                    <th>Campaign </th>
                    <th>Customer</th>
                    <th>User Type</th>
                    <th>Amount</th>
                    <!-- <th>Fee Paid</th> -->
                    <th>Time Sent</th>
                    
                    <th v-if="!$gate.isBroker()">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class='text-center' v-for='(row, index) in deposits' :key='index'>
                    <td>{{row.unique_id}}</td>
                    <!-- <td>{{row.distributor_from}}</td> -->
                    <td>

                        {{row.campaign_from}}
                    </td>
                    <td>
                    <router-link v-if="row.user_type === 'Customer'" v-bind:to="'/view-user-profile/' + row.customer_id">
                                {{row.customer_fullname}} <br>
                            <span>
                                username: {{row.customer_username}}
                            </span>
                    </router-link>
                    </td>
                    <td>

                        <i v-if="row.non_register_status" class="text-danger"><i title="not registered" class="fa fa-user-slash"></i></i>
                        <i v-else  class="text-success"> <i title="registered" class="fa fa-user"></i></i>
                    </td>

                    <td>
                        {{ dalasisFormatter(row.amount)}}
                    </td>

                    <!-- <td>
                        {{ dalasisFormatter(row.fee_paid)}}

                    </td> -->

                    <td>{{row.time_sent}}
                    </td>
                    <!-- <td>
                        <label class="d-flex my-2">
                        <input :disabled="$gate.isBroker()" type="checkbox" name="withdrawal_status" value="1" :checked="row.can_withdrawal == 1 ? true: false"  @change="check($event,row.deposit_id)"> 
                        &nbsp; Withdrawal
                        </label>

                        <label class="d-flex  my-2">
                        <input :disabled="$gate.isBroker()" type="checkbox" name="send_status" value="1" :checked="row.can_send == 1 ? true: false"  @change="check($event,row.deposit_id)">
                        &nbsp; Send
                        </label>
                    </td> -->
                    <td v-if="!$gate.isBroker()">
                        <button  @click="cancelDeposit(row.deposit_id)" class="btn btn-sm custom-button btn-danger">

                            Cancel
                        </button>





                    </td>
                    </tr>
                    </tbody>
                </table>

                <div class="pt-3">
                    <pagination align="center" :data="pageData" @pagination-change-page="getResults"></pagination>

                </div>

                </div>

                <div class="text-center"  v-else>
                    <span><i>{{emptyDataText}}</i></span>
                </div>
                <!-- /.card-body -->
            </fieldset>
        </div>
        <!-- /.card -->
        <!-- /.card -->





</template>



<script>
import SearchFilter from './../../../includes/SearchFilter'

export default {
    name: 'OutgoingDeposits',
    props:{
        campaign_id: Number
    },
  components: {
   SearchFilter
  },
  data() {
    return {
        deposits: [],
        pageData: {},
        searchForm: null,
        isFound: false,
        filters: [
            {
                'id': 1,
               'text': 'Customer',
               'value': 'customer'
            },
            {
                'id': 3,
                'text': 'Amount',
                'value': 'amount'
            },
            {
                'id': 4,
                'text': 'Campaign',
                'value': 'campaign'
            },

        ],
        broker_balance: 0,
        companyInfo: {
            admin_branches: []
        },
        branch: 0,
        data_form: new Form({
                branch_id: null
        }),

        emptyDataText: ''
        }
  },
  created(){
      // fetch the results


            //this.getBranches()
             this.getResults()

             console.log(this.campaign_id);


  },
  methods: {
    check (e,id) {
        this.$nextTick(() => {
            if(e.target.checked){
            var status= 1;
            }else{
            var status= 0;
            }
           var name= e.target.name;

            console.log(e.target.value)
            let formData = new FormData();
                formData.append('deposit_id', id);

                formData.append(name, status);


                axios.post('/api/distributor/deposit/mark',formData,{headers:{"Content-Type":"application/json"}})
                    .then((data) => {

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button-2',

                        },
                        buttonsStyling: false
                        })

                        confirmBox2.fire({
                        icon: 'success',
                        title: 'Updation!',
                        text: 'Updation successful!'

                        }).then((res)=>{
                            // reloading the page
                        location.reload();
                        //window.location.href = '/outgoing-deposits';

                        })



                    })
                    .catch((err) => {



                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button bg-danger',

                        },
                        buttonsStyling: true
                        })

                        confirmBox2.fire({
                        icon: 'error',
                        title: 'Failed!',
                        text: err.response.data.errors,

                        })
                    });
        })
    },
      cancelDeposit(id){

        //   get the info from id
            let customer = this.deposits.filter((t)=> t.deposit_id == id)[0]

            const confirmBox1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button-2 mr-2',
                cancelButton: 'btn custom-button bg-danger ml-2'
            },
            buttonsStyling: false
            })

            confirmBox1.fire({
            title: 'Are you sure?',
            text: "You'll be cancelling the D" + customer['amount'] + " to " + customer['customer_fullname'],
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, cancel it!',
            cancelButtonText: "No, don't!"
            }).then((result1) => {
            if (result1.isConfirmed) {



                // prompt for pin
            const box1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button-2 mr-2',
                cancelButton: 'btn custom-button bg-danger ml-2'
            },
            buttonsStyling: false
            })

            box1.fire({
                title: 'Enter your pin',
                input: 'password',
                inputAttributes: {
                    maxlength: 4,
                    minlength: 4,
                    pattern: "[0-9]+",
                    required: true,
                },
                inputValidator: (value) => {
                    if (!value) {
                    return 'You need to enter your 4 digit pin!'
                    }

                },
                showCancelButton: true,
                confirmButtonText: 'Submit',
                showLoaderOnConfirm: true
                }).then((result) => {
                if (result.isConfirmed) {

                let formData = new FormData();
                formData.append('deposit_id', id);

                formData.append('pin', result.value);


                axios.post('/api/distributor/deposit/cancel',formData,{headers:{"Content-Type":"application/json"}})
                    .then((data) => {

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button-2',

                        },
                        buttonsStyling: false
                        })

                        confirmBox2.fire({
                        icon: 'success',
                        title: 'Cancelled!',
                        text: 'Cancellation successful!'

                        }).then((res)=>{
                            // reloading the page
                        location.reload();
                        //window.location.href = '/outgoing-deposits';

                        })



                    })
                    .catch((err) => {



                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button bg-danger',

                        },
                        buttonsStyling: true
                        })

                        confirmBox2.fire({
                        icon: 'error',
                        title: 'Failed!',
                        text: err.response.data.errors,

                        })
                    });



                }
                })




            }
            })
      },

      getResults(page){
        if (typeof page === 'undefined') {
            page = 1;
        }
        this.emptyDataText = ''

        if(this.searchForm == null || this.searchForm.search_text == ''){
                    this.$isLoading(true)
            this.data_form.campaign_id= this.campaign_id ? this.campaign_id : "all"
            this.data_form.post('/api/distributor/deposit/outgoing?page=' + page)
                .then(({data}) => {
                if(data.data.length != 0){
                  this.deposits = data.data;
                  this.pageData = data
                }else{
                  this.deposits = [];
                  this.pageData = {}
                  this.emptyDataText = 'You have no outgoing deposits'
                }

                    this.$isLoading(false)


                }).catch((err)=>{
                    this.$isLoading(false)


                })
        }
        else{
            this.$isLoading(true)
            this.emptyDataText = ''
            this.searchForm.campaign_id= this.campaign_id ? this.campaign_id : "all"
            this.searchForm.post('/api/distributor/deposit/makeSearch?page=',{headers:{"Content-Type":"application/json"}})
                .then(({data}) => {
                    this.isFound = true

                    this.deposits = data.data;
                    this.pageData = data
                    this.emptyDataText = 'No search results found...'

                    this.$isLoading(false)

                }).catch((err)=>{
                  this.deposits = [];
                  this.pageData = {}
                 this.emptyDataText = 'Something went wrong!'

                    this.$isLoading(false)

                })

        }


      },
        getBranches(){

        axios.get('/api/agency')
            .then(({data}) => {
                this.companyInfo = data
                if(data.admin_branches.length > 1 )
                    this.branch = 'all'
                else
                    this.branch = data.admin_branches[0]['id']


            }).catch((err)=>{

        })
        },
        makeSearch(data){
            this.searchForm = data
            this.getResults()
            // emit up
        }
  },
 watch:{
        getUserProfile: function () {
            this.broker_balance = this.getUserProfile.broker_balance
        },

        branch: function(){
            this.searchForm = null
            this.isFound = false
            this.data_form.branch_id = this.branch
            this.getResults()
        }

  },

  mounted() {
        this.$store.dispatch("userProfile")

  },
  computed: {
        getUserProfile(){ //final output from here
            return this.$store.getters.getUserProfile
        },
  }
}
</script>
