<template>
    <div>
        <div v-if="$gate.isBankAdmin()" class="card  custom-card-1 m-0">
            <fieldset>
                <legend class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center">
                     <span>Bank Management</span>

                    </div>
                   <router-link to="/bank-admin-create-bank" class="nav-link" active-class="active" exact>
                       <button class="btn btn-outline-info">Create new Bank <i class="fa fa-plus"></i></button>
                    </router-link>
                </legend>

                <div class="card-header">
                </div>
            <div>

                <!-- <TabNav :tabs="['Approved banks', 'Blocked banks']" :selected="selected" @selected="setSelected">
                    <Tab :isSelected="selected === 'Approved banks' ">

                    </Tab>

                    <Tab :isSelected="selected === 'Blocked banks' ">
                    </Tab>

                </TabNav> -->

            </div>




            <div class="card-body mt-2 table-responsive">
                <table  id="example2" class="table table-hover border-top">
                    <thead >
                    <tr class="text-center">
                    <th>Bank Name</th>
                    <th>Bank IP</th>
                    <!-- <th>Kashma Bank Balance</th>
                    <th>Cash At Bank</th>
                    <th>Fee Cut Percent</th>
                    <th>Total Agent Admins</th>
                    <th>Total Agents</th> -->

                    <th class="border-left">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class='text-center' v-for='(row, index) in banks' :key='index'>
                        <td>{{row.bank_name}}
                            <span v-if="row.status" >
                                <i class="fas fa-ban text-danger"></i>


                            </span>

                        </td>
                        <td>{{row.bank_ip}}</td>
                        <!-- <td>{{dalasisFormatter(row.kashma_bank_balance)}}</td>
                        <td>{{dalasisFormatter(row.cash_at_bank)}}</td>

                        <td>{{row.fee_cut_percent}}%</td>

                        <td>{{row.total_agent_admins}}</td>
                        <td>{{row.total_agents}}</td> -->
                        <td class="d-flex flex-wrap border-left justify-content-between">
                            <router-link v-bind:to="'/bank-admin-view-bank/' + row.id"
                            active-class="active" exact class="btn mb-4 custom-button-purple1" >
                                <i class="fas fa-eye"></i>
                            </router-link>
                            <router-link v-bind:to="'/bank-admin-edit-bank/' + row.id"
                            active-class="active" exact class="btn mb-4 custom-button-purple1" >
                                <i class="fas fa-edit"></i>
                            </router-link>
                            
                            <button v-if="row.status==0" @click="block(row.id)"  class="btn custom-button mb-4 btn-danger" title="block">
                                <i class="fas fa-ban"></i>
                            </button>

                            <button v-else  @click="unblock(row.id)"   class="btn custom-button mb-4 btn-success" title="unblock">
                                <i class="fas fa-toggle-on"></i>
                            </button>

                        </td>



                    </tr>
                    <tfoot>


                    </tfoot>
                    </tbody>
                </table>
                <div class="pt-3">
                    <pagination align="center" :data="pageData" @pagination-change-page="getResults"></pagination>

                </div>

            </div>


            </fieldset>

        </div>
        <div v-else>
            <not-found></not-found>

        </div>
    </div>
</template>

<script>

export default {
    name: 'Banks',
    props:{

    },
  components: {

  },
  data() {
    return {
        searchBy : '',
        searchText: '',

        form: null,

        banks: [],
        bank: [],
        pageData: {}
    }
  },
  created(){
      // fetch fee table
        if(this.$gate.isBankAdmin()){
           this.getResults()
            this.form = new Form({

            agent_id: 0,
            pin: '',
            agent_admin_id: '',

        })
        }

  },
    watch: {


    },
  methods:{

        getResults(page){
            if (typeof page === 'undefined') {
            page = 1;
            }

            this.$isLoading(true)


            axios.get('/api/bank-admin/banks?page=' + page)
                .then(({data}) => {
                  console.log("banks", data)
                  this.banks = data.data;
                  this.pageData = data
                    this.$isLoading(false)


                }).catch((err)=>{
                  this.banks = []
                  this.pageData = {}
                this.$isLoading(false)


            })

        },
        block(id){

            const confirmBox1 = Swal.mixin({
            customClass: {
                cancelButton: 'btn custom-button-2 mx-2',
                confirmButton: 'btn custom-button bg-danger mx-2'
            },
            buttonsStyling: false
            })

            confirmBox1.fire({
            title: 'Are you sure?',
            text: "You'll be blocking this bank's account ",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, block this bank!'
            }).then((result1) => {
            if (result1.isConfirmed) {



            const box1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button-2 mx-2',
                denyButton: 'btn custom-button bg-secondary mx-2'
            },
            buttonsStyling: false
            })

            box1.fire({
                title: 'Enter your password',
                input: 'password',
                inputAttributes: {
                    pattern: "[0-9]+",
                    required: true,
                },
                inputValidator: (value) => {
                    if (!value) {
                    return 'You need to enter your password!'
                    }

                },
                showDenyButton: true,
                denyButtonText: `Back`,
                confirmButtonText: 'Submit',
                }).then((result) => {
                if (result.isConfirmed) {

                this.$isLoading(true)

                let del_form =  new FormData();
                del_form.append('id', id)
                del_form.append('status', 1)
                del_form.append('password', result.value)

                axios.post('/api/bank-admin/mark', del_form,{headers:{"Content-Type":"application/json"}})
                    .then((data) => {
                        this.$isLoading(false)

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button-2',

                        },
                        buttonsStyling: false
                        })

                        confirmBox2.fire({
                        icon: 'success',
                        title: 'Blocked!',
                        text: 'bank Blocked! ... '  ,

                        }).then((res)=>{
                            // reloading the page
                        // window.location.href = '/agents';
                        this.getResults()
                        })



                    })
                    .catch((err) => {
                        this.$isLoading(false)
                       // show them if that city name is taken
                        let errorText = 'Customer Blocking Unsuccessful'

                        if(err.response.data.errors['password']){
                            errorText = err.response.data.errors['password']
                        }
                        else if(err.response.data.errors['general']){
                            errorText = err.response.data.errors['general']
                        }

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button bg-danger',

                        },
                        buttonsStyling: true
                        })

                        confirmBox2.fire({
                        icon: 'error',
                        title: 'Failed!',
                        text: errorText,

                        })
                    });




                }
                else if (result.isDenied) {

                }
                })


            }
            })



        },

        unblock(id){

            const confirmBox1 = Swal.mixin({
            customClass: {
                cancelButton: 'btn custom-button-2 mx-2',
                confirmButton: 'btn custom-button bg-danger mx-2'
            },
            buttonsStyling: false
            })

            confirmBox1.fire({
            title: 'Are you sure?',
            text: "You'll be unblocking this bank's account ",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, unblock this bank!'
            }).then((result1) => {
            if (result1.isConfirmed) {



            const box1 = Swal.mixin({
            customClass: {
                confirmButton: 'btn custom-button-2 mx-2',
                denyButton: 'btn custom-button bg-secondary mx-2'
            },
            buttonsStyling: false
            })

            box1.fire({
                title: 'Enter your password',
                input: 'password',
                inputAttributes: {
                    pattern: "[0-9]+",
                    required: true,
                },
                inputValidator: (value) => {
                    if (!value) {
                    return 'You need to enter your password!'
                    }

                },
                showDenyButton: true,
                denyButtonText: `Back`,
                confirmButtonText: 'Submit',
                }).then((result) => {
                if (result.isConfirmed) {

                this.$isLoading(true)

                let del_form =  new FormData();
                del_form.append('id', id)
                del_form.append('status', 0)
                del_form.append('password', result.value)

                axios.post('/api/bank-admin/mark', del_form,{headers:{"Content-Type":"application/json"}})
                    .then((data) => {
                        this.$isLoading(false)

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button-2',

                        },
                        buttonsStyling: false
                        })

                        confirmBox2.fire({
                        icon: 'success',
                        title: 'Unblocked!',
                        text: 'bank Unblocked! ... '  ,

                        }).then((res)=>{
                            // reloading the page
                        // window.location.href = '/agents';
                        this.getResults()
                        })



                    })
                    .catch((err) => {
                        this.$isLoading(false)
                       // show them if that city name is taken
                        let errorText = 'Customer Blocking Unsuccessful'

                        if(err.response.data.errors['password']){
                            errorText = err.response.data.errors['password']
                        }
                        else if(err.response.data.errors['general']){
                            errorText = err.response.data.errors['general']
                        }

                        const confirmBox2 = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn custom-button bg-danger',

                        },
                        buttonsStyling: true
                        })

                        confirmBox2.fire({
                        icon: 'error',
                        title: 'Failed!',
                        text: errorText,

                        })
                    });




                }
                else if (result.isDenied) {

                }
                })


            }
            })



        },

  },

}
</script>
