<template>
    <div>
        <div v-if="$gate.isBroker()" class="card  custom-card-1 m-0">
            <fieldset>
                <legend class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center">
                     <span>Customer Search</span>

                    </div>
                   <!-- <router-link to="/create-customer" class="nav-link" active-class="active" exact>
                       <button class="btn btn-outline-info">Create new Customer <i class="fa fa-plus"></i></button>
                    </router-link> -->
                </legend>

                <div class="card-header">
                </div>
                <br>
                <!-- //////   dropdown for the search  //////// -->

                <div>
                  <form>
                    <div class="form-group">
                      <!-- <label for="exampleSelect1">Search Customer by:</label> -->
                      <select v-model="selectedOption" class="form-control" id="exampleSelect1">
                        <option value="" disabled>Search Customer by</option>
                        <option value="name">Name</option>
                        <option value="username">Username</option>
                        <option value="telephone">Telephone</option>
                        <option value="promoCode">Promo Code</option>
                      </select>
                    </div>
                  </form>

                    <form @submit.prevent="searchByName" v-if="selectedOption === 'name'">
                    <div class="form-group d-flex justify-content-between">
                        <input type="text" class="form-control mr-2" v-model="customer_name" placeholder="Enter name here to search">
                        <button type="submit" class="btn btn-primary">Submit</button>
                    </div>
                    </form>
                    <form @submit.prevent="searchByUsername" v-if="selectedOption === 'username'">
                    <div class="form-group d-flex justify-content-between">
                        <input type="tel" class="form-control mr-2" v-model="username"  placeholder="Enter username here to search">
                        <button type="submit" class="btn btn-primary">Submit</button>
                    </div>
                    </form>

                    <form @submit.prevent="searchByTelephone" v-if="selectedOption === 'telephone'">
                    <div class="form-group d-flex justify-content-between">
                        <input type="tel" class="form-control mr-2" v-model="customer_telephone"  placeholder="Enter telephone here to search">
                        <button type="submit" class="btn btn-primary">Submit</button>
                    </div>
                    </form>

                    <form @submit.prevent="searchPromoCode" v-if="selectedOption === 'promoCode'">
                    <div class="form-group row">
                        <div class="col-sm-3">
                        <input type="text" v-model="single_promo_code" class="form-control" placeholder="Enter promo code here">
                        </div>
                        <div class="col-sm-3">
                        <input type="date" v-model="start_date" class="form-control">
                        </div>
                        <div class="col-sm-3">
                        <input type="date" v-model="end_date" class="form-control">
                        </div>
                        <div class="col-sm-3">
                        <button type="submit" class="btn btn-primary">Submit</button>
                        </div>
                    </div>
                    </form>
                </div>

                <!-- ///////// dropdown for the search ends here ////////// -->

            <div v-if="customers.length" class="card-body mt-2 table-responsive">
                <table  id="example2" class="table table-hover border-top">
                    <thead >
                    <tr class="text-center">
                    <th>Full Name</th>
                    <th>Broker Balance</th>
                    <th>Can Transact</th>
                    <th>Total Transfers</th>
                    <th>Total Deposits</th>

 
                    <th class="border-left">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class='text-center' v-for='(row, index) in customers' :key='index'>
                        <td>{{row.fullname}}
                                        <img v-if="row['is_mrz_verified']" src="/images/verified-badge.png" 
                                        alt="image" style="width: 16px; height:16px;">

                            <span v-if="row.is_blocked" >
                                <i class="fas fa-ban text-danger"></i>


                            </span>
                        </td>

                        <td>{{dalasisFormatter(row.broker_balance)}}</td>

                        <td>
                        <span v-if="!row.is_blocked" :class="[row.can_user_transact == 'Yes' ? 'text-emerald' : 'text-red1', 'font-weight-bold']">
                        {{row.can_user_transact}}
                        </span>
                        <span v-else class="text-danger font-weight-bold">BLOCKED</span>
                            
                        </td>

                        <td>{{row.total_transfers}}</td>
                        <td>{{row.total_deposits}}</td>
                        <td class="d-flex flex-wrap border-left justify-content-between">
                            <router-link v-bind:to="'/view-user-profile/' + row.id"
                            active-class="active" exact class="btn mb-4 custom-button-purple1" >
                                <i class="fas fa-eye"></i>
                            </router-link>

                            <button v-if="!row.is_blocked" @click="blockUser(row.id)"  class="btn custom-button mb-4 btn-danger"> 
                                <i class="fas fa-user-slash"></i>
                            </button>

                            <button v-else  @click="unblockUser(row.id)"   class="btn custom-button mb-4 btn-danger"> 
                                <i class="fas fa-user"></i>
                            </button>

                        </td>
                        


                    </tr>
                    <tfoot>
                    

                    </tfoot>
                    </tbody>
                </table>
                <div class="pt-3">
                    <pagination align="center" :data="pageData" @pagination-change-page="getResults"></pagination>

                </div>

            </div>

            <div v-if="promocodes.length" class="card-body mt-2 table-responsive">
                <table  id="example2" class="table table-hover border-top">
                    <thead >
                    <tr class="text-center">
                    <th>Promocode</th>
                    <th>Registered Customers</th>
                    <th class="border-left">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class='text-center' v-for='(row, index) in promocodes' :key='index'>
                      <td>{{row.code}}</td>
                      <td>{{row.count}}</td>
                      <td class='text-center'>
                            <!-- view users who registers by the promo code  -->
                          <router-link v-bind:to="'/view-users/' + row.code"
                          active-class="active" exact class="btn mb-4 custom-button-purple1" >
                              <i class="fas fa-eye"></i>
                          </router-link>

                      </td>
                    </tr>
                    <tfoot>


                    </tfoot>
                    </tbody>
                </table>
                <div class="pt-3">
                    <pagination align="center" :data="pageData" @pagination-change-page="getResults"></pagination>

                </div>

            </div>


            </fieldset>
            
        </div>
        <div v-else>
            <not-found></not-found>
            
        </div>
    </div>
</template>

<script>
export default {
  name: "Customers",
  props: {},
  components: {},
  data() {
    return {
      selectedOption: "",
      single_promo_code: "", // this is for the search a single promo code
      start_date: "",
      end_date: "",
      customer_name: "",
      customer_telephone: "",
      username: "",
      searchBy: "",
      searchText: "",

      form: null,

      customers: [],
      promocodes: [],
      company: [],
      pageData: {},
    };
  },

  created() {
    // fetch fee table
    if (this.$gate.isBroker()) {
      this.getCustomerCount();
      //   this.getResults();
      this.form = new Form({
        agent_id: 0,
        pin: "",
        agent_admin_id: "",
      });
    }
  },
  watch: {},
  methods: {
    searchByName() {
      this.getResults(undefined, {
        customer_name: this.customer_name,
      });
    },
    searchByTelephone() {
      this.getResults(undefined, {
        customer_telephone: this.customer_telephone,
      });
    },
    searchByUsername() {
      this.getResults(undefined, {
        username: this.username,
      });
    },
    searchPromoCode() {
      if (this.single_promo_code == "") {
        //then search all promocodes
        this.getResults(undefined, {
          start_date: this.start_date,
          end_date: this.end_date,
          promo_code: true,
        });
      } else {
        // search by single promocode
        this.getResults(undefined, {
          start_date: this.start_date,
          end_date: this.end_date,
          single_promo_code: this.single_promo_code,
          promo_code: true,
        });
      }
    },
    getResults(page, data) {
      if (typeof page === "undefined") {
        page = 1;
      }

      this.$isLoading(true);

      axios
        .post("api/broker/customers?page=" + page, data)
        .then((response) => {
          if (response.data.promocodes) {
            this.promocodes = response.data.promocodes.data;
            this.customers = [];
          } else {
            this.customers = response.data.data;
            this.promocodes = [];
          }
          console.log("promocodes ", this.promocodes.data);
          this.pageData = data;
          this.$isLoading(false);
        })
        .catch((err) => {
          this.customers = [];
          this.pageData = {};
          this.$isLoading(false);
        });
    },

    getCustomerCount() {
      axios
        .get("api/broker/customersCount")
        .then(({ data }) => {
          console.log("customers count", data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    blockUser(id) {
      const confirmBox1 = Swal.mixin({
        customClass: {
          cancelButton: "btn custom-button-2 mx-2",
          confirmButton: "btn custom-button bg-danger mx-2",
        },
        buttonsStyling: false,
      });

      confirmBox1
        .fire({
          title: "Are you sure?",
          text: "You'll be blocking this customer's account ",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, block this customer!",
        })
        .then((result1) => {
          if (result1.isConfirmed) {
            const box1 = Swal.mixin({
              customClass: {
                confirmButton: "btn custom-button-2 mx-2",
                denyButton: "btn custom-button bg-secondary mx-2",
              },
              buttonsStyling: false,
            });

            box1
              .fire({
                title: "Enter your password",
                input: "password",
                inputAttributes: {
                  pattern: "[0-9]+",
                  required: true,
                },
                inputValidator: (value) => {
                  if (!value) {
                    return "You need to enter your password!";
                  }
                },
                showDenyButton: true,
                denyButtonText: `Back`,
                confirmButtonText: "Submit",
              })
              .then((result) => {
                if (result.isConfirmed) {
                  this.$isLoading(true);

                  let del_form = new FormData();
                  del_form.append("user_id", id);
                  del_form.append("password", result.value);

                  axios
                    .post("api/broker/blockUser", del_form, {
                      headers: { "Content-Type": "application/json" },
                    })
                    .then((data) => {
                      this.$isLoading(false);

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button-2",
                        },
                        buttonsStyling: false,
                      });

                      confirmBox2
                        .fire({
                          icon: "success",
                          title: "Blocked!",
                          text: "User Blocked! ... ",
                        })
                        .then((res) => {
                          // reloading the page
                          // window.location.href = '/agents';
                          this.getResults();
                        });
                    })
                    .catch((err) => {
                      this.$isLoading(false);
                      // show them if that city name is taken
                      let errorText = "Customer Blocking Unsuccessful";

                      if (err.response.data.errors["password"]) {
                        errorText = err.response.data.errors["password"];
                      } else if (err.response.data.errors["general"]) {
                        errorText = err.response.data.errors["general"];
                      }

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button bg-danger",
                        },
                        buttonsStyling: true,
                      });

                      confirmBox2.fire({
                        icon: "error",
                        title: "Failed!",
                        text: errorText,
                      });
                    });
                } else if (result.isDenied) {
                }
              });
          }
        });
    },

    unblockUser(id) {
      const confirmBox1 = Swal.mixin({
        customClass: {
          cancelButton: "btn custom-button-2 mx-2",
          confirmButton: "btn custom-button bg-danger mx-2",
        },
        buttonsStyling: false,
      });

      confirmBox1
        .fire({
          title: "Are you sure?",
          text: "You'll be unblocking this customer's account ",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, unblock this customer!",
        })
        .then((result1) => {
          if (result1.isConfirmed) {
            const box1 = Swal.mixin({
              customClass: {
                confirmButton: "btn custom-button-2 mx-2",
                denyButton: "btn custom-button bg-secondary mx-2",
              },
              buttonsStyling: false,
            });

            box1
              .fire({
                title: "Enter your password",
                input: "password",
                inputAttributes: {
                  pattern: "[0-9]+",
                  required: true,
                },
                inputValidator: (value) => {
                  if (!value) {
                    return "You need to enter your password!";
                  }
                },
                showDenyButton: true,
                denyButtonText: `Back`,
                confirmButtonText: "Submit",
              })
              .then((result) => {
                if (result.isConfirmed) {
                  this.$isLoading(true);

                  let del_form = new FormData();
                  del_form.append("user_id", id);
                  del_form.append("password", result.value);

                  axios
                    .post("api/broker/unblockUser", del_form, {
                      headers: { "Content-Type": "application/json" },
                    })
                    .then((data) => {
                      this.$isLoading(false);

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button-2",
                        },
                        buttonsStyling: false,
                      });

                      confirmBox2
                        .fire({
                          icon: "success",
                          title: "Unblocked!",
                          text: "User Unblocked! ... ",
                        })
                        .then((res) => {
                          // reloading the page
                          this.getResults();
                        });
                    })
                    .catch((err) => {
                      this.$isLoading(false);
                      // show them if that city name is taken
                      let errorText = "Customer Blocking Unsuccessful";

                      if (err.response.data.errors["password"]) {
                        errorText = err.response.data.errors["password"];
                      } else if (err.response.data.errors["general"]) {
                        errorText = err.response.data.errors["general"];
                      }

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button bg-danger",
                        },
                        buttonsStyling: true,
                      });

                      confirmBox2.fire({
                        icon: "error",
                        title: "Failed!",
                        text: errorText,
                      });
                    });
                } else if (result.isDenied) {
                }
              });
          }
        });
    },
  },
};
</script>