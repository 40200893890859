<template>
  <div>
    <legend
      v-if="$gate.isCustomer() || $gate.isStore()"
      class="d-flex justify-content-center flex-wrap"
    >
      <span class="d-flex align-items-center">Transaction Defaults</span>
    </legend>

    <div class="card custom-card-1 m-0">
      <fieldset>
        <legend
          v-if="$gate.isBroker() || $gate.isAgency()"
          class="d-flex justify-content-between flex-wrap"
        >
          <div class="d-flex align-items-center">
            <span>Transaction Defaults</span>
          </div>
        </legend>
        <div
          v-if="$gate.isBroker() || $gate.isAgency()"
          class="card-header"
        ></div>
        <!-- /.card-header -->
        <div v-if="defaults.length != 0" class="card-body table-responsive">
          <table id="example2" class="table table-bordered table-hover">
            <thead>
              <tr class="text-center">
                <th v-if="$gate.isBroker()">ID</th>
                <th>Type</th>
                <th>Name</th>

                <th>Amount in Dalasis</th>
                <th v-if="$gate.isBroker()">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="text-center"
                v-for="(row, index) in defaults"
                :key="index"
              >
                <td v-if="$gate.isBroker()">{{ row.id }}</td>

                <td>
                  {{ row.type }}
                </td>
                <td>
                  {{ row.name }}
                </td>
                <td class="text-emerald font-weight-bold">
                  {{ dalasisFormatter(row.amount) }}
                </td>

                <td v-if="$gate.isBroker()">
                  <button
                    class="btn mb-4 custom-button btn-info"
                    @click="openEditModal(row.id)"
                  >
                    <i class="fas fa-edit"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="pt-3">
            <pagination
              align="center"
              :data="pageData"
              @pagination-change-page="getResults"
            ></pagination>
          </div>
        </div>

        <!-- /.card-body -->
      </fieldset>
    </div>
    <!-- /.card -->

    <div
      v-if="edit_form != null && $gate.isBroker()"
      class="modal fade"
      id="editDefaultModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="editDefaultModalTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <form @submit.prevent="editDefault" method="post">
            <div class="modal-header border-0">
              <h5 class="modal-title" id="exampleModalLabel">
                Edit Transaction Default
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="d-flex justify-content-center">
                <h2 class="swal2-title" id="exampleModalLongTitle">
                  {{ edit_form.name }}
                </h2>
              </div>
              <div class="card-body">
                <div class="form-group">
                  <label for="type">Type</label>

                  <select
                    v-model="edit_form.type"
                    class="form-control"
                    id="type"
                  >
                    <option value="Daily" :selected="edit_form.type == 'Daily'">
                      Daily
                    </option>
                    <option
                      value="Monthly"
                      :selected="edit_form.type == 'Monthly'"
                    >
                      Monthly
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="amount">Amount</label>
                  <input
                    type="number"
                    id="amount"
                    v-model="edit_form.amount"
                    placeholder="Enter amount"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer border-0 d-flex justify-content-center">
              <button
                :disabled="edit_form.id == ''"
                type="submit"
                class="btn custom-button-2"
              >
                Save Changes
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <Send :getUserProfile="getUserProfile" />
    <Footer
      v-if="$gate.isCustomer() || $gate.isStore()"
      :getUserProfile="getUserProfile"
    />
    <AgencyFooter
      v-else-if="$gate.isAgency()"
      :getUserProfile="getUserProfile"
    />
  </div>
</template>

<script>
export default {
  name: "TransactionDefaults",
  props: {},
  components: {},
  data() {
    return {
      defaults: [],
      pageData: {},
      edit_form: null,
    };
  },
  created() {
    this.getResults();
    this.edit_form = new Form({
      id: "",
      name: "",
      type: "",
      amount: "",
      password: "",
    });
  },
  watch: {
    getUserProfile: function () {
      if (this.$gate.isCustomer()) {
        document.getElementById("bladeAmount").innerHTML =
          this.dalasisFormatter(this.getUserProfile.balance);
      } else if (this.$gate.isMerchant()) {
        document.getElementById("bladeAmount").innerHTML =
          this.dalasisFormatter(this.getUserProfile.total_transfered_amount);
      }
    },
  },

  mounted() {
    this.$store.dispatch("userProfile");
  },
  computed: {
    getUserProfile() {
      //final output from here
      return this.$store.getters.getUserProfile;
    },
  },
  methods: {
    getResults(page) {
      if (typeof page === "undefined") {
        page = 1;
      }
      this.$isLoading(true);

      axios
        .get("api/transaction-default?page=" + page)
        .then(({ data }) => {
          if (data.data.length != 0) {
            this.defaults = data.data;
            this.pageData = data;
          } else {
            this.defaults = [];
            this.pageData = {};
          }
          this.$isLoading(false);
        })
        .catch((err) => {
          this.defaults = [];
          this.pageData = {};

          this.$isLoading(false);
        });
    },
    openEditModal(id) {
      if (!this.$gate.isBroker()) return;
      let def = this.defaults.filter((c) => c.id == id)[0];
      this.edit_form.id = id;
      this.edit_form.name = def.name;
      this.edit_form.type = def.type;
      this.edit_form.amount = def.amount;

      $("#editDefaultModal").modal("toggle");
    },
    editDefault() {
      if (!this.$gate.isBroker()) return;
      $("#editDefaultModal").modal("toggle");

      const box1 = Swal.mixin({
        customClass: {
          confirmButton: "btn custom-button-2 mr-2",
          denyButton: "btn custom-button bg-secondary ml-2",
        },
        buttonsStyling: false,
      });

      box1
        .fire({
          title: "Enter your password",
          text: "To confirm that your changes",
          input: "password",
          inputAttributes: {
            required: true,
          },
          inputValidator: (value) => {
            if (!value) {
              return "You need to enter your password!";
            }
          },
          showDenyButton: true,
          denyButtonText: `Back`,
          confirmButtonText: "Submit",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.edit_form.password = result.value;

            this.$isLoading(true);

            this.edit_form
              .post("api/transaction-default/edit", {
                headers: { "Content-Type": "application/json" },
              })
              .then((data) => {
                this.$isLoading(false);

                const confirmBox2 = Swal.mixin({
                  customClass: {
                    confirmButton: "btn custom-button-2",
                  },
                  buttonsStyling: false,
                });

                confirmBox2
                  .fire({
                    icon: "success",
                    title: "Changes Saved!",
                  })
                  .then((res) => {
                    window.location.reload();
                  });
              })
              .catch((err) => {
                this.$isLoading(false);

                // show them if that city name is taken
                let errorText = "Transaction Default Edit Unsuccessful";

                if (err.response.data.errors["password"]) {
                  errorText = err.response.data.errors["password"];
                } else if (err.response.data.errors["amount"]) {
                  errorText = err.response.data.errors["amount"];
                } else if (err.response.data.errors["type"]) {
                  errorText = err.response.data.errors["type"];
                }

                const confirmBox2 = Swal.mixin({
                  customClass: {
                    confirmButton: "btn custom-button bg-danger",
                  },
                  buttonsStyling: true,
                });

                confirmBox2.fire({
                  icon: "error",
                  title: "Failed!",
                  text: errorText,
                });
              });
          } else if (result.isDenied) {
            $("#editDefaultModal").modal("show");
          }
        });
    },
  },
};
</script>