<template>
    <div>

        <AgentProfile :canEdit='false' legendTitle="Agent Profile" :tabs='tabs' :userInfo='userInfo' />

    </div>
</template>


<script>


import AgentProfile from './../includes/AgentProfile'

export default {
    name: 'ViewAgent',
    props:{
       
    },
  components: {
        AgentProfile
  },
  data() {
    return {
        selected: 'General',
        tabs: ['General', 'Logs'],
        logs: [],
        pageData: {},
        userInfo: {},

    }
  },
  created(){
      // fetch fee table
    this.getUser()
    

  },  
    watch: {


    },
  methods:{

        getUser(){
                    this.$isLoading(true) 

        axios.get('/api/agent/getAgent/' +  this.$route.params.id)
            .then(({data}) => {
              console.log(data)
                this.userInfo = data
                    this.$isLoading(false) 

            }).catch((err)=>{
                    this.$isLoading(false) 

        })
        }


  },

}
</script>