<template>
    <div>
        <div v-if="($gate.isRegistrationComplete() || $gate.isCustomer())" class="d-flex justify-content-center">
            <form class="form-horizontal" action="" @submit.prevent='updateAddress' method="post">

                <div class="card w-100 custom-card-1 m-0 bg-white">
                <fieldset>
                    <legend class="d-flex justify-content-start flex-wrap">
                        <span>Update User Address</span>
                    </legend>
                    <div class="card-header">
                    </div>

                    <div class="card-body">
                        <div class="row">

                            <div class="col-12 d-flex justify-content-center">
                                <div class="form-group">
                                <label class="text-center">Enter new Address</label>
                                <Select2  required
                                    v-model="form.newAddress"
                                    :options="locations.towns"
                                    :settings = "{width:'400px'}"

                                />
                                </div>

                            </div>
                            <div  class="col-12 mt-3 d-flex justify-content-center">
                                <button type="submit" :disabled="form.busy" class="btn custom-button-1">
                                    Update
                                </button>
                            </div>

                        </div>

                    </div>
                </fieldset>
                </div>

            </form>

            <Footer v-if="$gate.isCustomer() || $gate.isStore()"  :getUserProfile='getUserProfile' />
            <AgencyFooter v-else-if="$gate.isAgency()" :getUserProfile='getUserProfile' />
        </div>
    </div>
</template>

<script>
import Select2 from 'v-select2-component';
export default {
  name: 'ChangeAddress',
  components: {
    Select2
  },
  data() {
    return {
        userInfo: "",
        locations:[],
        form: new Form({
            newAddress: '',
        }),

        isCorrectPassword: false

    }
  },
  created(){
  },
  watch: {
        getUserProfile: function() {
            if (this.$gate.isCustomer()) {
                this.userInfo = this.getUserProfile;
                this.form.newAddress=(this.userInfo.town_id!=null?this.userInfo.town_id:"");
                document.getElementById('bladeAmount').innerHTML = this.dalasisFormatter(this.getUserProfile.balance)

            }
        },
        getLocations: function () {
            this.locations = this.getLocations

        },
    },
  mounted() {
        this.$store.dispatch("userProfile");
        this.$store.dispatch("locations")
    },
  computed: {
        getUserProfile() {
            return this.$store.getters.getUserProfile;
        },
        getLocations(){
            return this.$store.getters.getLocations
        },
    },

  methods: {
    updateAddress(){
            this.form.post('api/profile/changeUserAddress',{headers:{"Content-Type":"application/json"}})
            .then((data) => {

               const confirmBox2 = Swal.mixin({
                customClass: {
                    confirmButton: 'btn custom-button-2',

                },
                buttonsStyling: false
                })

                confirmBox2.fire({
                icon: 'success',
                title: 'Address changed!',

                }).then((res)=>{
                    // redirect user to Profile

                        window.location.href = '/my-profile';

                })

            })
            .catch((err) => {

                const confirmBox2 = Swal.mixin({
                customClass: {
                    confirmButton: 'btn custom-button bg-danger',

                },
                buttonsStyling: true
                })

                confirmBox2.fire({
                icon: 'error',
                title: 'Failed!',
                text: err.response.data.errors.newAddress,

                })


            });

        }

  },


}
</script>

<style scoped>
</style>
